import React from 'react';
import { Grid, Paper } from '@mui/material';
import { BarChart, DoughnutChart } from '../../../../../common/chart';
import ReactEcharts from "echarts-for-react";
import ProgressChart from '../common/ProgressChart/ProgressChart';
import ProgressChartCountry from '../common/ProgressChartCountry/ProgressChartCountry';
import ListData from './components/ListData/ListData';
import CardHeader from '../common/CardHeader/CardHeader';
import AvatarList from './components/AvatarList/AvatarList';
import FallbackWrapper from '../common/FallbackWrapper/FallbackWrapper';
import CustomToggleButtonGroup from '../common/CustomToggleButtonGroup/CustomToggleButtonGroup';
import utils from "../../../../../../utility/oryxUtils/utils";
import { object, string } from 'prop-types';
import CustomPaperCard from '../common/CustomPaperCard/CustomPaperCard';


const AudienceInfo = (props) => {
  const { data, platform } = props;
  // const { followers } = data;

  const [currentTab, setCurrentTab] = React.useState('followers');
  const handleChange = (
    event,
    newTab,
  ) => {
    setCurrentTab(newTab ? newTab : currentTab);
  };

  // render the type of information specific to social platform of influencer
  const renderSectionForPlatform = () => {
    let content = null;
    switch (platform) {

      case "instagram":
        content = (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title={"Top Cities"} />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.cities?.length}>
                    <ProgressChart data={data[currentTab]?.distribution?.cities} chartTitle='Cities' expandButtonVisible wrapperElevation={2} barSxProps={{ height: 6, borderRadius: 12 }} topValuesCount={3} wrapperSxProps={{ mb: "16px", p: "10px" }} />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={4}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title={"Ethinicity"} />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.ethinicity?.length}>
                    <ProgressChart data={data[currentTab]?.distribution?.ethinicity} chartTitle='Ethinicity' expandButtonVisible wrapperElevation={2} barSxProps={{ height: 6, borderRadius: 12 }} topValuesCount={3} wrapperSxProps={{ mb: "16px", p: "10px" }} />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={4}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title={"Top Languages"} />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.languages?.length}>
                    <ProgressChart data={data[currentTab]?.distribution?.languages} chartTitle='Languages' expandButtonVisible wrapperElevation={2} barSxProps={{ height: 6, borderRadius: 12 }} topValuesCount={3} wrapperSxProps={{ mb: "16px", p: "10px" }} />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomPaperCard cardSxProps={{ minHeight: "271px" }}>
                  <FallbackWrapper isError={!(data[currentTab]?.distribution?.aud_brand_affinity?.length > 0)}>
                    <ListData listTitle="Audience Brand Affinity" data={data[currentTab]?.distribution?.aud_brand_affinity} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={6}>
                <CustomPaperCard cardSxProps={{ minHeight: "271px" }}>
                  <FallbackWrapper isError={!(data[currentTab]?.distribution?.aud_interest_affinity?.length > 0)}>
                    <ListData listTitle="Audience Interest Affinity" data={data[currentTab]?.distribution?.aud_interest_affinity} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomPaperCard cardSxProps={{ minHeight: "271px" }}>
                  <CardHeader title="Audience Lookalikes" />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.aud_lookalikes?.length}>
                    <AvatarList listTitle="Audience Lookalikes" data={data[currentTab]?.distribution?.aud_lookalikes} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={6}>
                <CustomPaperCard cardSxProps={{ minHeight: "271px" }}>
                  <CardHeader title="Notable Followers" />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.notable_followers?.length}>
                    <AvatarList listTitle="Notable Followers" data={data[currentTab]?.distribution?.notable_followers} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
            </Grid>
          </>
        );
        break;

      case "tiktok":
        content = (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title={"Languages"} />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.languages?.length}>
                    <ProgressChart data={data[currentTab]?.distribution?.languages} chartTitle='Languages' expandButtonVisible wrapperElevation={2} barSxProps={{ height: 6, borderRadius: 12 }} topValuesCount={3} wrapperSxProps={{ mb: "16px", p: "10px" }} />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={6}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title="Similiar Accounts" />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.aud_lookalikes?.length}>
                    <AvatarList listTitle="Similiar Followers" data={data[currentTab]?.distribution?.aud_lookalikes} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
            </Grid>
          </>
        );
        break;

      case "youtube":
        content = (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title={"Languages"} />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.languages?.length}>
                    <ProgressChart data={data[currentTab]?.distribution?.languages} chartTitle='Languages' expandButtonVisible wrapperElevation={2} barSxProps={{ height: 6, borderRadius: 12 }} topValuesCount={3} wrapperSxProps={{ mb: "16px", p: "10px" }} />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={4}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title="Similiar Followers" />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.aud_lookalikes?.length}>
                    <AvatarList listTitle="Similiar Followers" data={data[currentTab]?.distribution?.aud_lookalikes} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
              <Grid item xs={4}>
                <CustomPaperCard cardSxProps={{ minHeight: "298px" }}>
                  <CardHeader title="Notable Followers" />
                  <FallbackWrapper isError={!data[currentTab]?.distribution?.aud_lookalikes?.length}>
                    <AvatarList listTitle="Notable Followers" data={data[currentTab]?.distribution?.aud_lookalikes} expandButtonVisible />
                  </FallbackWrapper>
                </CustomPaperCard>
              </Grid>
            </Grid>
          </>
        );
        break;
      default: content = <>Unable to show information</>; break;
    }
    return content;
  }

  return (
    <>
      <CustomToggleButtonGroup options={Object.keys(data)?.filter(key => Boolean(data[key]))?.map(key => ({ key, value: key }))} selectedOption={currentTab} handleChange={handleChange} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomPaperCard cardSxProps={{ minHeight: "320px" }}>
            <CardHeader title={"Audience Reachability"} />
            <FallbackWrapper isError={utils.checkIsObjectEmpty(data[currentTab]?.aud_reachability) || !data[currentTab]?.aud_reachability?.range?.length}>
              <ReactEcharts
                option={BarChart(data[currentTab]?.aud_reachability?.range?.map(item => item[0] === "-" ? `0${item}` : item), true, [
                  {
                    type: "bar",
                    color: ["#4299E1"],
                    showBackground: true,
                    backgroundStyle: {
                      borderRadius: [100, 100, 0, 0],
                    },
                    itemStyle: {
                      borderRadius: [100, 100, 0, 0],
                    },
                    data: data[currentTab]?.aud_reachability?.count,
                    tooltip: {
                      formatter: "{b} Followers {c} %",
                      valueFormatter: function (value) {
                        return value + " %";
                      },
                    },
                    label: {
                      show: true,
                      position: "bottom",
                      formatter: function (value) {
                        return value.value + "k \n Followings";
                      },
                    },
                  },
                ], null, null, false, false, 17, false, {
                  axisLabel: {
                    formatter: '{value}%'
                  }
                })}
                style={{ height: "250px", width: "100%" }}
              />
            </FallbackWrapper>
          </CustomPaperCard>
        </Grid>
        <Grid item xs={6}>
          <CustomPaperCard>
            <CardHeader title={"Gender Distribution"} />
            <FallbackWrapper isError={utils.checkIsObjectEmpty(data[currentTab]?.distribution)}>
              <ReactEcharts
                option={DoughnutChart("Followers", ["#1DA1F2", "#F087B1", "#4B6188", "#D6D7D6"], false, 0, 0, data[currentTab]?.distribution?.gender, [
                  "80%",
                  "90%",
                ], true, false,false, {
                  formatter: "{c}%",
                }, {
                  label: {
                    show: true,
                    position: 'outside',
                    formatter: '{b} \n {d}%'
                  },
                  labelLine: {
                    show: true
                  }
                }
                )}
                style={{ height: "250px", width: "100%" }}
              />
            </FallbackWrapper>
          </CustomPaperCard>
        </Grid>
      </Grid>
      <CustomPaperCard>
        <CardHeader title={"Age and Gender Distribution"} />
        <FallbackWrapper isError={utils.checkIsObjectEmpty(data[currentTab]?.distribution?.age_and_gender)}>
          <ReactEcharts
            option={BarChart(data[currentTab]?.distribution?.age_and_gender.ageRange, false, [
              {
                name: "Male",
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  borderRadius: [100, 100, 0, 0],
                },
                color: ["#7244AE"],
                itemStyle: {
                  borderRadius: [100, 100, 0, 0],
                },
                tooltip: {
                  valueFormatter: function (value) {
                    return `${value} %`;
                  },
                },
                data: data[currentTab]?.distribution?.age_and_gender.male,
              },
              {
                name: "Female",
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  borderRadius: [100, 100, 0, 0],
                },
                color: ["#4299E1"],
                itemStyle: {
                  borderRadius: [100, 100, 0, 0],
                },
                tooltip: {
                  valueFormatter: function (value) {
                    return `${value} %`;
                  },
                },
                data: data[currentTab]?.distribution?.age_and_gender.female,
              },
              data[currentTab]?.distribution?.age_and_gender?.unknown?.length && {
                name: "Unknown",
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  borderRadius: [100, 100, 0, 0],
                },
                color: ["#42991F"],
                itemStyle: {
                  borderRadius: [100, 100, 0, 0],
                },
                tooltip: {
                  valueFormatter: function (value) {
                    return `${value} %`;
                  },
                },
                data: data[currentTab]?.distribution?.age_and_gender.female,
              },
            ], null, null, false, true, "17", false)}
            style={{ height: "250px", width: "100%" }}
          />
        </FallbackWrapper>
      </CustomPaperCard>
      <CustomPaperCard cardSxProps={{ minHeight: "378px" }}>
        <CardHeader title={"Top Countries"} hideToolTip />
        <FallbackWrapper isError={!data[currentTab]?.distribution?.countries?.length}>
          <ProgressChartCountry
            data={
              data[currentTab]?.distribution?.countries?.map((item => ({
                title: item.name,
                value: item.percentage,
                percentage: item.percentage,
                color: item.color

              })))}
            mapData={
              data[currentTab]?.distribution?.countries?.map((item => ({
                country: item.code,
                value: item.percentage
              })))}
            expand={true}
          />
        </FallbackWrapper>
      </CustomPaperCard>
      {renderSectionForPlatform()}
    </>
  )
}

AudienceInfo.propTypes = {
  data: object.isRequired,
  platform: string.isRequired
}

export default AudienceInfo