import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import constants from "../../config/constantMessages.json";
import { InstagramEmbed, TwitterEmbed, YouTubeEmbed, TikTokEmbed, LinkedInEmbed, FacebookEmbed } from "react-social-media-embed";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "2em",
          backgroundColor: "unset",
        },
      },
    },
  },
});

const InfoToolTip = ({ text, size }) => {
  return (
    <Tooltip
      title={
        <>
          <h2 style={{ fontSize: 13, whiteSpace: "pre-line" }}>{text}</h2>
        </>
      }
      placement="top-start"
    >
      <InfoOutlined fontSize="small" sx={{
        fontSize: size,
      }} />
    </Tooltip>
  );
};


const UpgradePlanToolTipText = (credit) => {
  return (
    <>
      {" "}
      {credit && <h2 style={{ fontSize: 15, borderRadius: "6px", color: "white" }}>{constants.LIMIT_EXHAUSTED}</h2>}
      {!credit && <h2 style={{ fontSize: 15, borderRadius: "6px", color: "white" }}>{constants.UPGRADE_PLAN}</h2>}
    </>
  );
};

const InfoToolTipPreview = ({ postUrl, platform, postId, status }) => {
  const renderPlatformContent = () => {
    switch (platform) {
      case "instagram":
        return <InstagramEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "twitter":
        return <TwitterEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "youtube":
        return <YouTubeEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "tiktok":
        return <TikTokEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "linkedin":
        return <LinkedInEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "facebook":
        return <FacebookEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      default:
        return null;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        placement="right"
        title={
          <div style={{ background: "transparent", padding: "0" }}>{status !== "Invalid URL" && renderPlatformContent()}</div>
        }
      >
        {status !== "Invalid URL" ? (
          <a href={postUrl} target="_blank" rel="noreferrer">
            <p style={{ marginTop: 5 }}>{postId?.substring(0, 15)}...</p>
          </a>
        ) : (
          <p style={{ marginTop: 5 }}>{postId?.substring(0, 15)}...</p>
        )}
      </Tooltip>
    </ThemeProvider>
  );
};

InfoToolTip.propTypes = {
  text: PropTypes.string,
};
InfoToolTipPreview.propTypes = {
  postUrl: PropTypes.string,
};

export { InfoToolTip, UpgradePlanToolTipText, InfoToolTipPreview };
