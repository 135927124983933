import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StyledMenu from "../StyledMenu";
import utils from "../../../utility/oryxUtils/utils";

import {
  FormControl,
  Grid,
  MenuItem,
  Tooltip,
  InputLabel,
  Select,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const ReelPlays = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [infFromReelPlaysSel, setInfFromReelPlaysSel] = useState("");
  const [infToReelPlaysSel, setInfToReelPlaysSel] = useState("");
  const [toReelPlays] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);
  const [fromReelPlays] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);

  const handleFromChange = (event, type) => {
    let newPayload = payload;
    let reelPlay = event.target.value;
    if (reelPlay === "To" || reelPlay === "From") {
      reelPlay = "";
    }
    if (type === "inf_reel_plays_from") {
      setInfFromReelPlaysSel(reelPlay);
    } else {
      setInfToReelPlaysSel(reelPlay);
    }
    customCols = utils.addObj(customCols, "reelPlays");
    customCols[0]["reelPlays"].push("reelPlays");
    setSelectedCol(customCols);
    setCustomCols(customCols);
    newPayload[type] = reelPlay;
    setPayload(newPayload);
    setReelPlay();
  };

  const setReelPlay = (type, engagements) => {
    let fromEng = payload["inf_reel_plays_from"];
    let toEng = payload["inf_reel_play_to"];
    allFiltersData = utils.addObj(allFiltersData, "inf_reel_plays");
    allFiltersData[0]["inf_reel_plays"][0] = {
      left: utils.getValueByKey(toReelPlays, fromEng, "value", "name"),
      right: utils.getValueByKey(toReelPlays, toEng, "value", "name"),
    };

    setAllFiltersData(allFiltersData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.reelPlay}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                fontWeight: '400'
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              size="large"
              disableElevation
              onClick={handleClick}
              fullWidth
              endIcon={open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
            >
              Reel Plays
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={2} style={{ height: "auto" }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>ReelPlays</FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        From
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="infFromReelPlays"
                        value={
                          typeof allFiltersData[0]["inf_reel_plays"] ===
                          "undefined"
                            ? infFromReelPlaysSel
                            : allFiltersData[0]["inf_reel_plays"][0]["left"] ===
                              ">1m"
                            ? "1000000"
                            : (
                                +allFiltersData[0]["inf_reel_plays"][0][
                                  "left"
                                ].split("k")[0] * 1000
                              ).toString()
                        }
                        label="From"
                        onChange={(e) =>
                          handleFromChange(e, "inf_reel_plays_from")
                        }
                      >
                        {fromReelPlays?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        To
                      </InputLabel>
                      <Select
                        value={
                          typeof allFiltersData[0]["inf_reel_plays"] ===
                          "undefined"
                            ? infFromReelPlaysSel
                            : allFiltersData[0]["inf_reel_plays"][0][
                                "right"
                              ] === ">1m"
                            ? "1000000"
                            : (
                                +allFiltersData[0]["inf_reel_plays"][0][
                                  "right"
                                ].split("k")[0] * 1000
                              ).toString()
                        }
                        onChange={(e) =>
                          handleFromChange(e, "inf_reel_play_to")
                        }
                        label="To"
                      >
                        {toReelPlays?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

ReelPlays.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default ReelPlays;
