import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StyledMenu from "../StyledMenu";
import utils from "../../../utility/oryxUtils/utils";
import { FormControl, Grid, Tooltip, Checkbox, FormGroup } from "@mui/material";
import CustomFormControlLabel from "./common/CustomFormControlLabel";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const formItems = [
  { value: 1, label: "Regular" },
  { value: 2, label: "Business" },
  { value: 3, label: "Creator" },
];

const AccountType = ({
  showFilter,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isChanged,
  setIsChanged,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    let newPayload = payload;
    if (event.target.checked) {
      if (newPayload["inf_accountType"] == null) {
        newPayload["inf_accountType"] = [];
      }

      const item = formItems.find(
        (item) => +item.value === +event.target.value
      );
      let filterSet = { name: item.label, value: item.value };
      allFiltersData = utils.addObj(allFiltersData, "inf_accountType");
      allFiltersData[0]["inf_accountType"].push(filterSet);
      newPayload["inf_accountType"].push(event.target.value);
    } else {
      newPayload["inf_accountType"] = newPayload["inf_accountType"]?.filter(
        (e) => e !== event.target.value
      );
      if (allFiltersData[0]["inf_accountType"]?.length > 1) {
        const filteredData = allFiltersData[0]["inf_accountType"]?.filter(
          (item) => item.value !== +event.target.value
        );
        allFiltersData[0]["inf_accountType"] = filteredData;
      } else {
        delete newPayload["inf_accountType"];
        delete allFiltersData[0]["inf_accountType"];
      }
      setIsChanged(!isChanged);
    }
    setPayload(newPayload);
    setAllFiltersData(allFiltersData);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isChecked = (value) =>
    allFiltersData[0]["inf_accountType"]?.length
      ? allFiltersData[0]["inf_accountType"]?.filter(
          (item) => item.value === value
        )?.length > 0
      : false;
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.accountType}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                fontWeight: '400'
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              size="large"
              disableElevation
              onClick={handleClick}
              fullWidth
              endIcon={open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
            >
              Account Type
            </Button>
          </Tooltip>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} style={{ height: "auto" }}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>Account Type</FormControl>
                  </Grid>
                </Grid>
                <FormControl sx={{ m: 1 }}>
                  <FormGroup>
                    {formItems?.map((item) => (
                      <CustomFormControlLabel
                        value={item.value}
                        control={<Checkbox />}
                        onChange={handleChange}
                        label={item.label}
                        key={`${item.label}-checkbox`}
                        checked={isChecked(item.value)}
                      />
                    ))}
                    {/* <CustomFormControlLabel value={1} control={<Checkbox false />} onChange={handleChange} label="Regular" />
                  <CustomFormControlLabel value={2} control={<Checkbox false />} onChange={handleChange} label="Business" />
                  <CustomFormControlLabel value={3} control={<Checkbox false />} onChange={handleChange} label="Creator" /> */}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

AccountType.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default AccountType;
