import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import CryptoJS, { AES } from "crypto-js";
import notificationsSlice from "./features/notifications/notificationsSlice";
import systemAlertSlice from "./features/system/systemAlert";
import mainConfig from "../config/main.json";
import userSlice from "./features/user/userSlice";
import menuSlice from "./features/menu/menuSlice";

const saveState = (state) => {
  const encryptedState = AES.encrypt(
    JSON.stringify({ state }),
    "Secret_PassPhrase@AIFluence"
  );

  localStorage.setItem("rdxs", encryptedState);
};

const getSavedState = () => {
  let finalState = {};

  const encryptedState = localStorage.getItem("rdxs");
  if (encryptedState) {
    let decryptedInfo = AES.decrypt(
      encryptedState,
      "Secret_PassPhrase@AIFluence"
    );
    decryptedInfo = JSON.parse(decryptedInfo.toString(CryptoJS.enc.Utf8));

    finalState = decryptedInfo.state;
  }

  return finalState;
};

const preloadedState = mainConfig.STORE_STATE_LOCALLY ? getSavedState() : {};

const store = configureStore({
  reducer: {
    notifications: notificationsSlice,
    systemAlert: systemAlertSlice,
    user: userSlice,
    menu: menuSlice,
  },
  preloadedState,
  middleware: [thunkMiddleware],
});

store.subscribe(() => {
  const currentState = store.getState();
  // Save state in local storage
  saveState(currentState);
});

export default store;
