import { Divider, Grid, List, ListItemButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { array, number, object } from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@material-ui/core/styles";
import { UpgradePlanToolTipText } from "../../components/common/toolTip";
import { useSelector } from "react-redux";
import { SubIconMapping } from "../../components/common/StyledComponents";
import { Lock } from "@mui/icons-material";

const useStyles = makeStyles({
  disabled: {
    pointerEvents: "none",
    color: "red",
  },
});

function AppSecondaryNav({ menuItem, drawerWidth, permissionsData }) {
  const [params, setParams] = useState("");
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search).toString();
    setParams(params);
  }, [location.key, menuItem]);
  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "medium",
    bgcolor: theme.palette.navbg.main,
    "&:before": { display: "block" },
  };

  const StyledRouterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
  }));
  const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    width: drawerWidth,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    fontSize: "0.85rem",
    padding: "0 12px",
  }));

  return (
    <List>
      {menuItem?.children
        ?.filter(
          (children) => children.hidden !== true && children.secondary && !(children.name === "Billing" && user.agencyId === 0)
        ) //? check me
        ?.map((child) => {
          let basePath = "";
          if (child.children === null) {
            basePath = child.path;
          } else {
            basePath = child.children[0].path;
          }

          const isActive = location.pathname.startsWith(basePath) && child.children === null;

          const path = basePath + "?" + params;
          if (permissionsData[menuItem.name]?.Fields?.[child.name] != undefined) {
            const { Visible, Editable } = permissionsData[menuItem.name]?.Fields?.[child.name];
            return (
              Visible && (
                <>
                  <Tooltip title={!Editable && UpgradePlanToolTipText()}>
                    <StyledRouterLink
                      key={child.name}
                      to={Editable ? path : `?${params}`}
                      style={{
                        textDecoration: "none",
                        color: !Editable && "#D3D3D3",
                      }}
                      className={!Editable && classes.disabled}
                    >
                      <ListItemStyle sx={{ ...(isActive && activeRootStyle) }}>
                        <Typography sx={{ m: "2px" }}>{SubIconMapping[child.name]?.icon}</Typography>
                        <Typography
                          sx={{
                            ml: "8px",
                            fontSize: "120%", 
                            fontWeight: "bold",
                          }}
                        >
                          {child.name}
                        </Typography>
                      </ListItemStyle>
                    </StyledRouterLink>
                  </Tooltip>
                  {child.children !== null &&
                    child.children?.length > 0 &&
                    child.children?.map((subOption) => {
                      const isActive = location.pathname.startsWith(subOption.path);

                      const path = subOption.path + "?" + params;

                      const info = permissionsData[menuItem.name]?.Fields?.[child.name].Fields?.[subOption.name];

                      let Visible, Editable;
                      if (info !== undefined) {
                        Visible = info.Visible;
                        Editable = info.Editable;
                      }

                      return (
                        Visible && (
                          <Tooltip title={!Editable && UpgradePlanToolTipText()}>
                            <StyledRouterLink
                              key={subOption.name}
                              to={Editable ? path : `?${params}`}
                              style={{
                                textDecoration: "none",
                                color: !Editable && "#D3D3D3",
                              }}
                              className={!Editable && classes.disabled}
                            >
                              <ListItemStyle
                                sx={{
                                  ...(isActive && activeRootStyle),
                                  height: "2vw",
                                }}
                              >
                                <span
                                  style={{
                                    height: "35px",
                                    width: "3px",
                                    backgroundColor: isActive ? "gray" : "lightgray",
                                    margin: "0 12px",
                                  }}
                                />
                                <Typography sx={{ ml: "12px", fontSize: "120%" }}>
                                  <Grid container alignItems="center">
                                    <Grid item xs={10}>
                                      {subOption.name}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {!Editable && <Lock fontSize="small" />}
                                    </Grid>
                                  </Grid>
                                </Typography>
                              </ListItemStyle>
                            </StyledRouterLink>
                          </Tooltip>
                        )
                      );
                    })}
                </>
              )
            );
          }
        })}
    </List>
  );
}

AppSecondaryNav.propTypes = {
  menuItem: array.isRequired,
  drawerWidth: number.isRequired,
  permissionsData: object.isRequired,
};

export default AppSecondaryNav;
