import React from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { Outlet } from "react-router-dom";
import MainFooter from "./MainFooter";
import "../../styles/internal/StaticStyle.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../../styles/campaignBriefTheme";

function StaticPageLayout() {
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar demo={true} />
      <Outlet />
      <MainFooter />
    </ThemeProvider>
  );
}

export default StaticPageLayout;
