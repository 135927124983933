import React from "react";
import { Paper, Typography } from "@mui/material";
import { number, string } from "prop-types";
import { InfoToolTip } from "../../../../../../components/common/toolTip";

const StatsCard = (props) => {
  const { statNumber, statText, color, tooltip } = props;
  return (
    <>
      <Paper sx={{ paddingTop: "3vh", backgroundColor: color, border: "2px solid #9d9d9d", height: "12.5vh" }}>
        <Typography fontSize={"1vw"} textAlign={"center"} whiteSpace={"nowrap"}>
          {statText} {tooltip?.length > 0 && <InfoToolTip text={tooltip} />}
        </Typography>
        <Typography fontSize={"2vw"} fontWeight={"bold"} textAlign={"center"}>
          {statNumber ? statNumber : "No Data"}
        </Typography>
      </Paper>
    </>
  );
};

StatsCard.propTypes = {
  statNumber: number.isRequired,
  statText: string.isRequired,
};

export default StatsCard;
