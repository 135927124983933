import config from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";
const { API_HOST, ORYX_HOSTNAME } = config;

const getPackageList = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/integration/google/getPackages`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const createLink = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/firebase/createLink`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getLinkLists = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/firebase/getAllLinks`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAllDomains = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/firebase/domains`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};


const getFBCampaignUrlsInfo = async (cid, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/firebase/campaignUrls/${cid}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAllApps = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/firebase/getApps`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};


export { getPackageList, createLink, getLinkLists, getAllDomains, getFBCampaignUrlsInfo, getAllApps};

