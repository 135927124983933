import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Input, ListItemButton } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import { Close, Search } from "@mui/icons-material";

function not(a, b) {
  return a?.filter((value) => {
    let flag = true;
    b.forEach((inf) => {
      if (inf.id === value.id) flag = false;
    });
    return flag;
  });
}

function intersection(a, b) {
  return a?.filter((value) => {
    let flag = false;
    b.forEach((inf) => {
      if (inf.id === value.id) flag = true;
    });
    return flag;
  });
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const TransferList = (props) => {
  const [width, setWidth] = useState(0);
  const [checked, setChecked] = useState([]);
  const [leftSearch, setLeftSearch] = useState("");
  const [rightSearch, setRightSearch] = useState("");
  const [leftItems, setLeftItems] = useState(
    props.left?.map((item) => {
      return { ...item, hidden: false };
    })
  );
  const [rightItems, setRightItems] = useState(
    props.right?.map((item) => {
      return { ...item, hidden: false };
    })
  );
  const [leftIconClick, setLeftIconClick] = useState(false);
  const [rightIconClick, setRightIconClick] = useState(false);

  const elementRef = useRef(null);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const leftChecked = intersection(checked, props.left);
  const rightChecked = intersection(checked, props.right);

  const handleToggle = (value) => () => {
    if (value.disabled) return;
    let currentIndex, i;
    for (i = 0; i < checked?.length; i++) {
      if (checked[i].id === value.id) break;
    }
    currentIndex = i === checked?.length ? -1 : i;
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items)?.length;

  const handleToggleAll = (items) => () => {
    let length = 0;
    items.forEach((item) => {
      if (!item.disabled) length++;
    });
    if (numberOfChecked(items) === length) {
      let notValues = not(checked, items);
      notValues = notValues?.filter((value) => !value.disabled);
      setChecked(notValues);
    } else {
      let unionValues = union(checked, items);
      unionValues = unionValues?.filter((value) => !value.disabled);
      setChecked(unionValues);
    }
  };

  const handleCheckedRight = () => {
    props.setRight(leftChecked.concat(props.right));
    props.setLeft(not(props.left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    props.setLeft(rightChecked.concat(props.left));
    props.setRight(not(props.right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const isChecked = (value) => {
    let flag = false;
    checked.forEach((element) => {
      if (element.id === value.id) flag = true;
    });
    return flag;
  };

  useEffect(() => {
    setLeftItems(
      props.left?.map((item) => {
        const hidden = item.label.toLowerCase().indexOf(leftSearch) === -1;
        return { ...item, hidden };
      })
    );
  }, [leftSearch, props.left]);

  useEffect(() => {
    setRightItems(
      props.right?.map((item) => {
        const hidden = item.label.toLowerCase().indexOf(rightSearch) === -1;
        return { ...item, hidden };
      })
    );
  }, [rightSearch, props.right]);

  const customList = (title, items, setSearch, iconClick, setIconClick) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items?.length && items?.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items?.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items?.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        action={
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              paddingTop: "8px",
            }}
          >
            {iconClick && (
              <Input
                style={{
                  width: "60%",
                }}
                disabled={items?.length === 0}
                onChange={(event) => {
                  setSearch(event.target.value.trim().toLowerCase());
                }}
              />
            )}
            {!iconClick && (
              <IconButton onClick={() => setIconClick(true)}>
                <Search />
              </IconButton>
            )}
            {iconClick && (
              <IconButton
                onClick={() => {
                  setSearch("");
                  setIconClick(false);
                }}
              >
                <Close />
              </IconButton>
            )}
          </div>
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items?.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: width * 0.4,
          height: "30vh",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items
          ?.filter((value) => {
            return !value.hidden;
          })
          ?.map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;

            return (
              <ListItemButton
                selected={isChecked(value)}
                onClick={handleToggle(value)}
                disabled={value.disabled}
                key={labelId}
              >
                <ListItemText id={labelId} primary={value.label} />
              </ListItemButton>
            );
          })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <div ref={elementRef}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          {customList(
            "Available",
            leftItems,
            setLeftSearch,
            leftIconClick,
            setLeftIconClick
          )}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked?.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked?.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList(
            "Chosen",
            rightItems,
            setRightSearch,
            rightIconClick,
            setRightIconClick
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default TransferList;
