import React from 'react';
import { bool, string } from "prop-types";
import { Box } from '@mui/material';

const FallbackWrapper = (props) => {
  const { isError, children, fallbackText = "No Information available!" } = props;
  return (
    <>
      {isError ?
        <Box component="p" display="flex" alignItems="center" justifyContent="center">{fallbackText}</Box>
        : children
      }
    </>
  )
}
FallbackWrapper.propType = {
  isError: bool.isRequired,
  fallbackText: string
}

export default FallbackWrapper;