import config from "../../config/main.json";
import { notify } from "../../redux/features/system/systemAlert";
import { logout } from "../../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "../common";


const { API_HOST, ORYX_HOSTNAME } = config;

// fetch user profile data
const getProfileData = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/discovery/profile/analyze`,
      data: JSON.stringify(data)
    };
    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    return response.json();

  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
  }
}

export { getProfileData };
