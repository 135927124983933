import {
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PlatformSelectionBox from "../../../common/platformSelectionBox";
import GroupDropdown from "../../../common/groupDropdown";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import { getAllInfluencerListForCampaign } from "../../../../services/influencer";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import InfluencerDropdown from "../../../common/influencerDropdown";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const CampaignGaFilter = ({
  allSelectedPlatforms,
  allSelectedGroups,
  allSelectedInfluencers,
  selectedStartDate,
  selectedEndDate,
  sendFilterChange,
  disabled,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [selectedPlatforms, setSelectedPlatforms] =
    useState(allSelectedPlatforms);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState(allSelectedGroups);
  const [influencerList, setInfluencerList] = useState(allSelectedInfluencers);
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    getGroupList("all");
  }, [params.get("id")]);


  const sendSelectedPlatforms = (event) => {
    setSelectedPlatforms(event);
    setHasChanged(true);
    if (event?.length > 0) {
      getGroupList(event);
    } else {
      getGroupList("all");
    }
  };
  const sendSelectedGroups = (event) => {
    const ids = [];
    setHasChanged(true);
    if (event?.length > 0) {
      event.forEach((group) => {
        ids.push(group.id);
      });
      getInfluencerList(ids);
    } else {
      getGroupList("all");
    }
    setSelectedGroups(event);
  };
  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(
      params.get("id"),
      platforms,
      user,
      dispatch
    );
    if (groups.status === true) {
      const ids = groups.response?.map((group) => group.id);
      getInfluencerList(ids);
      setGroupList(groups.response);
      setSelectedGroups([]);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const getInfluencerList = async (ids) => {
    const influencers = await getAllInfluencerListForCampaign(
      ids,
      "all",
      user,
      dispatch
    );
    if (influencers.status === true) {
      setInfluencerList(influencers.response);
    }
  };

  const sendSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
    setHasChanged(true); 
  };

  const sendSelectedInfluencer = (event) => {
    setSelectedInfluencers(event);
    setHasChanged(true);
  };


  const applyFilters = () => {
    sendFilterChange({
      startDate,
      endDate,
      selectedGroups,
      selectedInfluencers,
      selectedPlatforms,
    });
    setHasChanged(false);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item md />
        {/* Filters */}
        <Grid item md={3}>
          <DateRangeCustomPicker
            sendSelectedDates={sendSelectedDates}
            parentStartDate={startDate}
            parentEndDate={endDate}
            disabled={disabled}
            needRange={true}
          />
        </Grid>
        <Grid item md={3}>
          <PlatformSelectionBox
            sendSelectedPlatforms={sendSelectedPlatforms}
            parentPlatforms={selectedPlatforms}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={2}>
          <GroupDropdown
            groupList={groupList}
            sendSelectedGroups={sendSelectedGroups}
            parentSelectedGroup={selectedGroups}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={2}>
          <InfluencerDropdown
            influencerList={influencerList}
            sendSelectedInfluencer={sendSelectedInfluencer}
            parentSelectedInfluencer={selectedInfluencers}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
              borderRadius: "8px",
            }}
            onClick={applyFilters}
            disabled={!hasChanged || disabled}
          >
            Go
          </Button>
        </Grid>
      </Grid>
      <br />
    </>
  );
};
CampaignGaFilter.propTypes = {
  allSelectedPlatforms: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  allSelectedInfluencers: PropTypes.array.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  sendFilterChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectedShorturls: PropTypes.array.isRequired,
  setSelectedShorturls: PropTypes.func.isRequired,
};

export default CampaignGaFilter;
