import { colors } from "@mui/material";

const Palette = {
  background: {
    default: "white",
    paper: colors.common.white,
  },
  primary: {
    contrastText: "#ffffff",
    main: "#4a3aff",
  },
  secondary: {
    main: "#2F2F2F",
    light: "#949494",
    dark: "#5c5c5c",
  },
  text: {
    primary: "#4f4f4f",
    secondary: "#6b778c",
  },
  danger: {
    contrastText: "#ffffff",
    main: "#FF505F",
    light: "#FF303F",
    dark: "#FF303F",
  },
};
export default Palette;
