import { ArrowBack, BusinessCenterOutlined, BusinessOutlined, EmailOutlined, PersonOutlineOutlined, TaskAltRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import MuiPhoneInput from "../../pages/Settings/MuiPhoneInput";
import { notify } from "../../redux/features/system/systemAlert";
import { createAccount, createUser, getBusinessTypes } from "../../services/signup";
import styles from "../../styles/internal/SignUp.module.css";
import { RoundedTextField, StyledStaticButtonRoundedGroup, StyledStaticRoundedButton } from "../common/StyledComponents";
import { validatePhone } from "../../utility/regex";

const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, setState] = React.useState({
    dialCode: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    accountName: "",
    businessType: "",
    disabled: false,
    notified: "",
    sent: false,
  });

  const [businessTypes, setBusinessTypes] = React.useState([]);
  useEffect(() => {
    getBusinessTypesForOrganization();

    const email = searchParams.get("email");
    if (email) {
      setState({ ...state, email });
    }
  }, []);

  const getBusinessTypesForOrganization = async () => {
    const response = await getBusinessTypes();
    setBusinessTypes(response?.response);
  };

  const handlePhoneChange = (value, country) => {
    let phoneNumber = value.replace(/[^0-9]/g, "");
    phoneNumber = phoneNumber.slice(country.dialCode?.length);
    setState({ ...state, phoneNumber, dialCode: country.dialCode });
  };

  const signupUser = async () => {
    if (!validatePhone(state.dialCode, state.phoneNumber)) {
      dispatch(
        notify({
          message: "Please enter a valid phone number",
          type: "error",
        })
      );
      return;
    }
    dispatch(
      notify({
        message: "Please wait... We are validating your profile",
        type: "info",
      })
    );
    let id = 0;
    if (state.accountName !== "Aifluence") {
      const accountPayload = {
        name: state.accountName,
        businessType: state.businessType.id,
        membershipPlan: 12,
        addedFrom: "empowr"
      };
      const accountResponse = await createAccount(accountPayload);
      id = accountResponse.response.id;
    }
    const users = [
      {
        name: `${state.firstName} ${state.lastName}`,
        email: state.email,
        role: "Owner",
        roleId: 1,
        phoneNumber: state.phoneNumber,
        dialCode: state.dialCode,
        notified: state.notified,
      },
    ];
    const userPayload = {
      users,
      companyName: state.accountName,
      agencyId: state.accountName === "Aifluence" ? "0" : id.toString(),
      clientId: "0",
      billingAdded: false,
      addedBy: state.email,
    };
    const userResponse = await createUser(userPayload);
    if (userResponse.response?.statusCode === 200) {
      setState({ ...state, sent: true });
      dispatch(
        notify({
          message: "User created successfully",
          type: "success",
        })
      );
      return;
    }
    //code of mysql for duplicate entry
    if (userResponse.response?.message?.includes("1062")) {
      dispatch(
        notify({
          message: "User already exists! Please try logging in with the same email",
          type: "error",
        })
      );
      return;
    } else {
      dispatch(
        notify({
          message: userResponse.response?.message,
          type: "error",
        })
      );
      return;
    }
  };

  const processEmail = (e) => {
    const email = e.target.value;
    if (email.includes("ai-fluence")) {
      setState({ ...state, email, accountName: "Aifluence", businessType: { id: 0, type: "Universal" }, disabled: true });
    } else {
      setState({ ...state, email, accountName: "", businessType: "", disabled: false });
    }
  };
  const card = (
    <>
      <Button sx={{ margin: "1px 0px 0px 1px", fontSize: "15px" }} onClick={() => navigate("/login")}>
        <ArrowBack sx={{ height: "20px", width: "20px" }} />
        &nbsp;&nbsp;Login
      </Button>
      <div className={styles.formTitle}>
        <b>Full Name*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<First Name>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
            value={state.firstName}
            onChange={(e) => setState({ ...state, firstName: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<Last Name>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
            value={state.lastName}
            onChange={(e) => setState({ ...state, lastName: e.target.value })}
            required
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Phone Number*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item>
          <MuiPhoneInput
            required
            inputStyle={{
              borderRadius: "46px",
              border: "1px solid #d9d9dd",
              boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
              height: "45px",
              width: "620px",
            }}
            country="us"
            InputProps={{
              readOnly: false,
              endAdornment: null,
            }}
            specialLabel=""
            value={`+${state.dialCode}${state.phoneNumber}`}
            onChange={handlePhoneChange}
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Work Email*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            size="small"
            type="email"
            sx={RoundedTextField}
            placeholder="<Use an email with your company’s domain.>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
            value={state.email}
            onChange={(e) => processEmail(e)}
            required
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Organization Details*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            size="small"
            disabled={state.disabled}
            sx={RoundedTextField}
            placeholder="<Organization's Name>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <BusinessOutlined />
                </InputAdornment>
              ),
            }}
            value={`${state.accountName}`}
            onChange={(e) => setState({ ...state, accountName: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="size-small-outlined"
            size="small"
            disabled={state.disabled}
            options={businessTypes}
            getOptionLabel={(option) => option.type || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} placeholder="<Organization's Type>" sx={RoundedTextField} />}
            value={state.businessType}
            onChange={(event, newValue) => {
              setState({ ...state, businessType: newValue });
            }}
          />
        </Grid>
      </Grid>
      <br />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={state.notified} onChange={(e) => setState({ ...state, notified: e.target.checked })} />}
          label="I want to be notified about new features and updates to Empowr."
        />
      </FormGroup>
      <br />
      <div
        style={{
          textAlign: "center",
          paddingTop: "5px",
          paddingBottom: "10px",
          width: "80%",
          margin: "auto",
          fontSize: "0.75rem",
        }}
      >
        By clicking the signup button, you agree to our{" "}
        <a href="/page/termsOfService" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{" "}
        and have read and acknowledge our{" "}
        <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </div>
      <div style={{ textAlign: "center" }}>
        <StyledStaticButtonRoundedGroup color="primary" size="small" exclusive aria-label="Signup">
          <StyledStaticRoundedButton
            value="active"
            className={styles.staticButton}
            onClick={signupUser}
            disabled={
              !state.firstName ||
              !state.lastName ||
              !state.phoneNumber ||
              !state.email ||
              !state.accountName ||
              !state.businessType
            }
          >
            <b>Sign up</b>
          </StyledStaticRoundedButton>
        </StyledStaticButtonRoundedGroup>
      </div>
    </>
  );

  const successCard = (
    <>
      <div
        style={{
          textAlign: "center",
          paddingTop: "70px",
          paddingBottom: "40px",
        }}
      >
        <TaskAltRounded sx={{ color: "#1BC5BD", transform: "scale(5)" }} />
      </div>
      <Typography
        sx={{
          fontSize: "3rem",
          textAlign: "center",
          fontWeight: "500",
          paddingTop: "25px",
        }}
        color="primary"
      >
        Email Sent!
      </Typography>
      <Typography
        sx={{
          fontSize: "1.5rem",
          textAlign: "center",
          fontWeight: "500",
          paddingTop: "40px",
          paddingBottom: "25px",
          width: "80%",
          margin: "auto",
        }}
        color="secondary"
      >
        We have sent you an email, click on the link available there to set the password.
      </Typography>
      <div
        style={{
          textAlign: "center",
          paddingTop: "75px",
          paddingBottom: "10px",
          width: "80%",
          margin: "auto",
          fontSize: "0.75rem",
        }}
      >
        By clicking the button, you agree to our{" "}
        <a href="/page/termsOfService" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{" "}
        and have read and acknowledge our{" "}
        <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </div>
    </>
  );
  return (
    <div>
      {!state.sent && card}
      {state.sent && successCard}
    </div>
  );
};

export default SignUpForm;
