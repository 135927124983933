import { useRef } from "react";

/**
 * Hook which can be used to create a ref on an element & can be used to scroll onto that element
 * @param {*} scrollProps - scroll config for the ref element
 * @returns [scrollOntoView, elRef] - a method to scroll to that element & its ref var
 */
const useScroll = (scrollProps) => {
  const elRef = useRef(null);
  const scrollOntoView = () => elRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', ...scrollProps });
  return [scrollOntoView, elRef];
};

export default useScroll;