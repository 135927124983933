import {
  Cancel,
  CheckCircle,
  DeleteForever,
  Edit,
  Facebook,
  Google,
  Instagram,
  KeyboardDoubleArrowUp,
  LinkedIn,
  Publish,
  SettingsSuggest,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { IoLogoTiktok } from "react-icons/io5";
import { PlatformIdMapping } from "../../../../utility/plaformIcons";

const fileExtensions = {
  images: ["jpg", "jpeg", "png", "gif", "webp"],
  video: ["mp4", "avi", "mkv", "webm", "mpeg", "mpeg4", "3gp", "mov"],
  pdf: ["pdf"],
};

/* status to statusId mapping, for passing it to backend */
const draftStatusMapping = {
  "-": 0,
  "Submitted For Review": 1,
  Approved: 2,
  Rejected: 3,
  "Improvement Suggested": 4,
  Deleted: 5,
  "Edit With Suggestions": 6,
  Resubmitted: 7,
};

const statusDraftMapping = {
  0: "-",
  1: "Submitted For Review",
  2: "Approved",
  3: "Rejected",
  4: "Improvement Suggested",
  5: "Deleted",
  6: "Edit With Suggestions",
  7: "Resubmitted",
};

/* based on the status, following icons and descriptions are shown in the post history timeline */
const timelineDetails = {
  "Submitted For Review": {
    icon: <Publish />,
    iconColor: "#14804A",
    chipColor: "#E1FCEF",
    statusDescription: "Post submitted for review",
    textColor: "#3B82F6", 
    backgroundColor: "#E0F2FE", 
  },
  Approved: {
    icon: <CheckCircle />,
    iconColor: "#04CF55",
    chipColor: "#b0c9f5",
    statusDescription: "Post Approved",
    textColor: "#FFFFFF",
    backgroundColor: "#50b153",
  },
  Rejected: {
    icon: <Cancel />,
    iconColor: "red",
    chipColor: "#b0c9f5",
    statusDescription: "Post Rejected",
    textColor: "#FFFFFF",
    backgroundColor: "#FF0000",
  },
  "Improvement Suggested": {
    icon: <SettingsSuggest />,
    iconColor: "#5A6376",
    chipColor: "#E9EDF5",
    statusDescription: "Suggested changes for the Post",
    textColor: "#3B82F6",
    backgroundColor: "#E0F2FE",
  },
  Deleted: {
    icon: <DeleteForever />,
    iconColor: "black",
    chipColor: "#b0c9f5",
    statusDescription: "Post Deleted",
    textColor: "#FFFFFF",
    backgroundColor: "#000000",
  },
  "Edit With Suggestions": {
    icon: <Edit />,
    iconColor: "#588b0a",
    chipColor: "#d1f49a",
    statusDescription: "Edited Content With Suggestions",
    textColor: "#65A30D",
    backgroundColor: "#ECFCCB",
  },
  Resubmitted: {
    icon: <KeyboardDoubleArrowUp />,
    iconColor: "#D1293D",
    chipColor: "#FFEDEF",
    statusDescription: "Resubmitted By Influencer",
    textColor: "#D1293D",
    backgroundColor: "#FFEDEF",
  },
};

const charLengthSupported = {
  instagram: 2200,
  twitter: 280,
  tiktok: 150,
  youtube: 5000,
  linkedin: 63206,
  facebook: 110000,
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  if (typeof name !== "undefined" && name !== "") {
    const [firstName, lastName] = name.split(' ')
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${firstName ? firstName[0].toUpperCase() : ''}${lastName ? lastName[0].toUpperCase() : ''}`
    };
  }

  return null;
};

const platforms = {
  facebook: {
    icon: Facebook,
    color: "#4267B2",
  },
  google: {
    icon: Google,
    color: "#DB4437",
  },
  youtube: {
    icon: YouTube,
    color: "#FF0000",
  },
  twitter: {
    icon: Twitter,
    color: "#1DA1F2",
  },
  tiktok: {
    icon: IoLogoTiktok,
    color: "#010101",
  },
  instagram: {
    icon: Instagram,
    color: "#cd486b",
  },
  linkedin: {
    icon: LinkedIn,
    color: "#0077b5",
  },
};

const getIcon = (platform) =>
  platforms[platform] !== undefined ? platforms[platform].icon : undefined;

const getColor = (platform) =>
  platforms[platform] !== undefined ? platforms[platform].color : "#282828";

const showIcon = (value) => {
  let platform;
  if (typeof value === "number") {
    platform = PlatformIdMapping[value];
  } else {
    platform = value.toLowerCase();
  }
  const IconComponent = getIcon(platform);
  const IconColor = getColor(platform);
  if (IconComponent !== undefined) {
    return (
      <IconComponent
        key={value}
        ctitle={value}
        sx={{ color: IconColor, mr: 1 }}
      />
    );
  }
  return null;
};

const statusMapping = [
  { id: 0, status: "Under Review", color: "#2874f7" },
  { id: 1, status: "Under Review", color: "#2874f7" },
  { id: 2, status: "Approved", color: "#50b153" },
  { id: 3, status: "Rejected", color: "#ff0000" },
  { id: 4, status: "Feedback Provided", color: "#ff9800" },
  { id: 5, status: "Deleted", color: "black" },
  { id: 6, status: "Published", color: "#4BB543" },
];

export {
  fileExtensions,
  draftStatusMapping,
  statusDraftMapping,
  timelineDetails,
  charLengthSupported,
  stringAvatar,
  showIcon,
  statusMapping,
};
