import {
  CheckCircleOutline,
  HighlightOff,
  Visibility,
  WarningAmber,
} from "@mui/icons-material";
import { fileExtensions } from "./CommonAssets";

const RenderCardMedia = (formData) => {
  let attachments = [];
  const allowedFileExtensions = fileExtensions;
  if (formData.url) attachments = formData.url;
  else if (formData.Attachments) attachments = formData.Attachments;
  else if (formData.attachments) attachments = formData.attachments;
  return attachments?.map((item, index) => {
    if (item.url) {
      const fileExtension = item.url.split(".").pop();
      if (allowedFileExtensions.images.includes(fileExtension)) {
        return (
          <div id={index}>
            <img
              height={400}
              width="100%"
              style={{ justifyContent: "center" }}
              src={item.url}
              alt="Trouble loading the content"
            />
          </div>
        );
      }
      if (allowedFileExtensions.video.includes(fileExtension)) {
        return (
          <div id={index}>
            <video width="100%" height={400} controls>
              <source src={item.url} type="video/mp4" />
              <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              />
            </video>
          </div>
        );
      }
      if (allowedFileExtensions.pdf.includes(fileExtension)) {
        return (
          <div id={index}>
            <iframe
              src={item.url}
              type="application/pdf"
              width="100%"
              height={400}
              title={item.url}
            />
          </div>
        );
      }
    } else {
      const fileExtension = item.split(".").pop();
      if (allowedFileExtensions.images.includes(fileExtension)) {
        return (
          <div id={index}>
            <img
              height={350}
              width="100%"
              style={{ justifyContent: "center" }}
              src={item}
              alt="Trouble loading the content"
            />
          </div>
        );
      }
      if (allowedFileExtensions.video.includes(fileExtension)) {
        return (
          <div id={index}>
            <video width="100%" height={350} controls>
              <source src={item} type="video/mp4" />
              <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              />
            </video>
          </div>
        );
      }
    }
    return "";
  });
};

const GetPostText = (formData) => {
  const { currentStatus } = formData;
  let text;
  if (currentStatus === 0 || currentStatus === 1) {
    text = (
      <>
        <Visibility />
        &nbsp;&nbsp;View Draft Post
      </>
    );
  } else if (currentStatus === 2) {
    text = (
      <>
        <CheckCircleOutline />
        &nbsp;&nbsp;View Draft Post
      </>
    );
  } else if (currentStatus === 3) {
    text = (
      <>
        <HighlightOff />
        &nbsp;&nbsp;View Draft & Comment
      </>
    );
  } else if (currentStatus === 4) {
    text = (
      <>
        <WarningAmber />
        &nbsp;&nbsp;View Feedback
      </>
    );
  } else {
    text = (
      <>
        <CheckCircleOutline />
        &nbsp;&nbsp;View Published Post
      </>
    );
  }
  return text;
};
export { RenderCardMedia, GetPostText };
