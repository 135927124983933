import { Grid } from "@mui/material";
import { InfoToolTip } from "../../../common/toolTip";

export const tableHeaders = [
  {
    id: 1,
    label: "Influencer",
    mappedValue: "Influencer",
    field: "name",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 2,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={9}>
          Publications
        </Grid>
        <Grid item xs={2} style={{ margin: "auto" }}>
          <InfoToolTip text="Number of publications by the Influencer." />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Publications",
    field: "postCount",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={8}>
          Followers
        </Grid>
        <Grid item xs={2} style={{ margin: "auto" }}>
          <InfoToolTip text="Number of publications by the Influencer." />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Followers",
    field: "followerCount",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 4,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={9}>
          Engagement
        </Grid>
        <Grid item xs={2} style={{ margin: "auto" }}>
          <InfoToolTip text="Total engagements on all publications by the influencer in this campaign" />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Engagement",
    field: "engagementCount",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: "Group name",
    mappedValue: "Group name",
    field: "groupName",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: false,
  },
  {
    id: 6,
    label: "Last Posted",
    mappedValue: "Last Posted",
    field: "lastPosted",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 7,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={8}>
          Registered
        </Grid>
        <Grid item xs={2} style={{ margin: "auto" }}>
          <InfoToolTip text="Whether influencer is registered on Aifluence." />
        </Grid>
      </Grid>
    ),
    mappedValue: "Registered",
    field: "registered",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 8,
    label: (
      <Grid container>
        <Grid item xs={4.3}>
          Status
        </Grid>
        <Grid item xs={2} style={{  }}>
          <InfoToolTip text="Invitation Status of the influencer." />
        </Grid>
      </Grid>
    ),
    mappedValue: "Status",
    field: "status",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 9,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
];
