import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  FormControl,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { fetchInfluencers,fetchInfluencerIdsByGroupId, updatePost,getTranslatorInfo } from "../../../../services/publication";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const EditPostModal = ({ open, handleClose, postData, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [newPostUrl, setNewPostUrl] = useState("");
  const [newInfluencerId, setNewInfluencerId] = useState("");
  const [postLang, setPostLang] = useState("English");
  const [influencersList, setInfluencersList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPostUrlChanged, setIsPostUrlChanged] = useState(false);

  useEffect(() => {
    if (open && postData) {
      setNewPostUrl(postData.postUrl || "");
      setNewInfluencerId(postData.influencerId || "");
      setPostLang(postData.postLang || "English");
      setIsPostUrlChanged(false);
    //   loadInfluencersByGroupId();
      loadLanguageList();
    }
  }, [open, postData]);

//   const loadInfluencersByGroupId = async () => {
//     const response = await fetchInfluencerIdsByGroupId(
//       postData.groupId,
//       user,
//       dispatch
//     );
  
//     if (Array.isArray(response)) {
//       const iidArray = response.map((influencer) => influencer.iid);
//       const iids = iidArray.join(",");
//       console.log("iid",iids)
//       const influencers = await fetchInfluencers(
//         "",
//         iids,
//         postData.platform,
//         user,
//         dispatch
//       );
  
//       setInfluencersList(influencers);
//     } else {
//       setInfluencersList([]);
//     }
//   };
  
  const loadLanguageList = async () => {
    try {
      const languages = await getTranslatorInfo(user, dispatch); // Pass user and dispatch
      setLangList(languages);
    } catch (error) {
      console.error("Failed to load languages:", error);
    }
  };

  const handleSubmit = async () => {
    if (!newInfluencerId) {
      dispatch(
        notify({
          message: "Please select an influencer.",
          type: "error",
        })
      );
      return;
    }

    setLoading(true);
    const updatedPostUrl = isPostUrlChanged ? newPostUrl.trim() : "";
    const response = await updatePost(
      postData.postId,
      postData.lpId,
      updatedPostUrl,
      newInfluencerId,
      postLang,
      user,
      dispatch
    );

    if (response?.success) {
      onUpdateSuccess(); 
      handleClose();
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="edit-post-modal-title"
      aria-describedby="edit-post-modal-description"
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              id="edit-post-modal-title"
              variant="h6"
              component="h2"
            >
              Edit Post
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mt={2}>
            <TextField
              label="Update Post URL"
              variant="outlined"
              fullWidth
              value={newPostUrl}
              onChange={(e) => {
                setNewPostUrl(e.target.value);
                setIsPostUrlChanged(e.target.value !== (postData.postUrl || ""));
              }}
              margin="normal"
            />
            {/* <Autocomplete
              options={influencersList}
              getOptionLabel={(option) => option.name}
              value={
                influencersList.find((i) => i.id === newInfluencerId) || null
              }
              onChange={(event, newValue) => {
                setNewInfluencerId(newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Influencer"
                  variant="outlined"
                  margin="normal"
                />
              )}
              disabled={influencersList.length === 0}
            /> */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="post-lang-label">Post Language Type</InputLabel>
              <Select
                labelId="post-lang-label"
                value={postLang}
                label="Post Language Type"
                onChange={(e) => setPostLang(e.target.value)}
              >
                {langList.map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Updating..." : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

EditPostModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  postData: PropTypes.shape({
    postId: PropTypes.string.isRequired,
    lpId: PropTypes.number.isRequired,
    postUrl: PropTypes.string.isRequired,
    influencerId: PropTypes.number.isRequired,
    organicIds: PropTypes.string,
    directIds: PropTypes.string,
    platform: PropTypes.string.isRequired,
    postLang: PropTypes.string,
    groupId: PropTypes.number.isRequired,
  }).isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default EditPostModal;
