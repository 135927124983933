import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrandForAccount, getBrandAttributes, getPrimaryCategory, getSecondaryCategory } from "../../../services/campaigns";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
  StyledStaticButtonRoundedGroup,
  StyledStaticRoundedButton,
} from "../../common/StyledComponents";
import styles from "../../../styles/internal/Campaign/CreateNewCampaign.css";
import { notify } from "../../../redux/features/system/systemAlert";
import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";
import ImpersonateDialog from "../../common/ImpersonateDialog";

const CreateBrand = ({ open, setOpen }) => {
  const initialState = {
    brandName: "",
    brandAttribute: [],
    primaryCategory: "",
    secondaryCategory: "",
  };
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [state, setState] = React.useState(initialState);
  const [brandAttributes, setBrandAttributes] = React.useState([]);
  const [primaryCategory, setPrimaryCategory] = React.useState([]);
  const [secondaryCategory, setSecondaryCategory] = React.useState([]);
  const [openImpersonateDialog, setOpenImpersonateDialog] = React.useState(user.isImpersonateSession);
  React.useEffect(() => {
    getAllBrandAttributes();
    getBrandPrimaryCategory();
    getBrandSecondaryCategory();
  }, []);

  const getAllBrandAttributes = async () => {
    const response = await getBrandAttributes(user, dispatch);
    if (response?.response !== null) {
      setBrandAttributes(response?.response);
    }
  };

  const getBrandPrimaryCategory = async () => {
    const response = await getPrimaryCategory(user, dispatch);
    if (response?.response !== null) {
      setPrimaryCategory(response?.response);
    }
  };

  const getBrandSecondaryCategory = async () => {
    const response = await getSecondaryCategory(user, dispatch);
    if (response?.response !== null) {
      setSecondaryCategory(response?.response);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createNewBrand = async () => {
    const brand_ids = state.brandAttribute?.map((attribute) => attribute.id);
    const brandPayload = {
      attributes: brand_ids.join(),
      id: user.agencyId,
      name: state.brandName,
      primaryCat: state.primaryCategory.id,
      secondaryCat: state.secondaryCategory.id,
    };
    setState(initialState);
    dispatch(
      notify({
        message: "Please wait... we are adding your brand for this campaign",
        type: "info",
      })
    );
    const response = await createBrandForAccount(brandPayload, user, dispatch);

    if (response?.response) {
      dispatch(
        notify({
          message: "Brand added successfully",
          type: "success",
        })
      );
      handleClose();
    }
  };
  return (
    <>
      {" "}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 700,
          },
        }}
      >
        <DialogTitle>
          <b style={{ fontSize: "26px" }}>Create your Brand</b>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                variant="filled"
                label="Brand Name"
                placeholder="<Enter Brand Name>"
                type="text"
                required
                value={state.brandName}
                onChange={(e) => setState({ ...state, brandName: e.target.value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                multiple
                limitTags={1}
                disableCloseOnSelect
                id="size-small-filled"
                options={brandAttributes}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} variant="filled" label="Brand Attributes" required />}
                value={state.brandAttribute}
                onChange={(event, newValue) => {
                  setState({ ...state, brandAttribute: newValue });
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                id="size-small-filled"
                options={primaryCategory}
                getOptionLabel={(option) => option.category || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Brand Primary Category"
                    placeholder="<Choose from drop down or type & ⏎ to create>"
                    required
                  />
                )}
                value={state.primaryCategory}
                onChange={(event, newValue) => {
                  setState({ ...state, primaryCategory: newValue });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                id="size-small-filled"
                options={secondaryCategory}
                getOptionLabel={(option) => option.category || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Brand Secondary Category"
                    placeholder="<Choose from drop down or type & ⏎ to create>"
                    required
                  />
                )}
                value={state.secondaryCategory}
                onChange={(event, newValue) => {
                  setState({ ...state, secondaryCategory: newValue });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <StyledStaticButtonRoundedGroup
            color="primary"
            size="small"
            exclusive
            aria-label="Campaign"
            style={{ height: "50px", width: "230px" }}
            disabled={!state.brandName || state.brandAttribute?.length === 0 || !state.primaryCategory || !state.secondaryCategory}
          >
            <StyledStaticRoundedButton value="active" className={styles.staticButton} onClick={createNewBrand}>
              <b>Add this Brand</b>
            </StyledStaticRoundedButton>
          </StyledStaticButtonRoundedGroup>
        </DialogActions>
      </Dialog>
      {openImpersonateDialog && <ImpersonateDialog openDialog={openImpersonateDialog} />}
    </>
  );
};

export default CreateBrand;
