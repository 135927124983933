/* types
  select: always searchable, multiple is options 
*/

const FilterEnum = {
  filterData: {
    "instagram": ["location", "language", "brand", "partership", "interest", "gender", "ethinicity", "age", "audienceType", "lookalikes", "bio", "keywords", "followers", "engagements", "reelPlays", "contact", "lastPost", "accountType", "growth", "verified"],
    "tiktok": ["location", "language", "gender", "age", "lookalikes", "bio", "keywords", "followers", "views", "engagements", "contact", "lastPost", "growth", "verified"],
    "youtube": ["location", "language", "gender", "age", "lookalikes", ,"bio","audienceType", "keywords", "followers", "views", "engagements", "contact", "lastPost", "growth", "verified"]
  },
}

export default FilterEnum;