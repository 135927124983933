/* 
based on this json
https://gist.githubusercontent.com/ssskip/5a94bfcd2835bf1dea52/raw/3b2e5355eb49336f0c6bc0060c05d927c2d1e004/ISO3166-1.alpha2.json

Read this wikipedia article for more details
https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes

*/

const countryCodes = {
  "AF": {
    name: "Afghanistan",
    color: "",
  },
  "AX": {
    name: "Aland Islands",
    color: "",
  },
  "AL": {
    name: "Albania",
    color: "",
  },
  "DZ": {
    name: "Algeria",
    color: "",
  },
  "AS": {
    name: "American Samoa",
    color: "",
  },
  "AD": {
    name: "Andorra",
    color: "",
  },
  "AO": {
    name: "Angola",
    color: "",
  },
  "AI": {
    name: "Anguilla",
    color: "",
  },
  "AQ": {
    name: "Antarctica",
    color: "",
  },
  "AG": {
    name: "Antigua And Barbuda",
    color: "",
  },
  "AR": {
    name: "Argentina",
    color: "",
  },
  "AM": {
    name: "Armenia",
    color: "",
  },
  "AW": {
    name: "Aruba",
    color: "",
  },
  "AU": {
    name: "Australia",
    color: "",
  },
  "AT": {
    name: "Austria",
    color: "",
  },
  "AZ": {
    name: "Azerbaijan",
    color: "",
  },
  "BS": {
    name: "Bahamas",
    color: "",
  },
  "BH": {
    name: "Bahrain",
    color: "",
  },
  "BD": {
    name: "Bangladesh",
    color: "",
  },
  "BB": {
    name: "Barbados",
    color: "",
  },
  "BY": {
    name: "Belarus",
    color: "",
  },
  "BE": {
    name: "Belgium",
    color: "",
  },
  "BZ": {
    name: "Belize",
    color: "",
  },
  "BJ": {
    name: "Benin",
    color: "",
  },
  "BM": {
    name: "Bermuda",
    color: "",
  },
  "BT": {
    name: "Bhutan",
    color: "",
  },
  "BO": {
    name: "Bolivia",
    color: "",
  },
  "BA": {
    name: "Bosnia And Herzegovina",
    color: "",
  },
  "BW": {
    name: "Botswana",
    color: "",
  },
  "BV": {
    name: "Bouvet Island",
    color: "",
  },
  "BR": {
    name: "Brazil",
    color: "",
  },
  "IO": {
    name: "British Indian Ocean Territory",
    color: "",
  },
  "BN": {
    name: "Brunei Darussalam",
    color: "",
  },
  "BG": {
    name: "Bulgaria",
    color: "",
  },
  "BF": {
    name: "Burkina Faso",
    color: "",
  },
  "BI": {
    name: "Burundi",
    color: "",
  },
  "KH": {
    name: "Cambodia",
    color: "",
  },
  "CM": {
    name: "Cameroon",
    color: "",
  },
  "CA": {
    name: "Canada",
    color: "",
  },
  "CV": {
    name: "Cape Verde",
    color: "",
  },
  "KY": {
    name: "Cayman Islands",
    color: "",
  },
  "CF": {
    name: "Central African Republic",
    color: "",
  },
  "TD": {
    name: "Chad",
    color: "",
  },
  "CL": {
    name: "Chile",
    color: "",
  },
  "CN": {
    name: "China",
    color: "",
  },
  "CX": {
    name: "Christmas Island",
    color: "",
  },
  "CC": {
    name: "Cocos (Keeling) Islands",
    color: "",
  },
  "CO": {
    name: "Colombia",
    color: "",
  },
  "KM": {
    name: "Comoros",
    color: "",
  },
  "CG": {
    name: "Congo",
    color: "",
  },
  "CD": {
    name: "Congo, Democratic Republic",
    color: "",
  },
  "CK": {
    name: "Cook Islands",
    color: "",
  },
  "CR": {
    name: "Costa Rica",
    color: "",
  },
  "CI": {
    name: "Cote D\"Ivoire",
    color: "",
  },
  "HR": {
    name: "Croatia",
    color: "",
  },
  "CU": {
    name: "Cuba",
    color: "",
  },
  "CY": {
    name: "Cyprus",
    color: "",
  },
  "CZ": {
    name: "Czech Republic",
    color: "",
  },
  "DK": {
    name: "Denmark",
    color: "",
  },
  "DJ": {
    name: "Djibouti",
    color: "",
  },
  "DM": {
    name: "Dominica",
    color: "",
  },
  "DO": {
    name: "Dominican Republic",
    color: "",
  },
  "EC": {
    name: "Ecuador",
    color: "",
  },
  "EG": {
    name: "Egypt",
    color: "",
  },
  "SV": {
    name: "El Salvador",
    color: "",
  },
  "GQ": {
    name: "Equatorial Guinea",
    color: "",
  },
  "ER": {
    name: "Eritrea",
    color: "",
  },
  "EE": {
    name: "Estonia",
    color: "",
  },
  "ET": {
    name: "Ethiopia",
    color: "",
  },
  "FK": {
    name: "Falkland Islands (Malvinas)",
    color: "",
  },
  "FO": {
    name: "Faroe Islands",
    color: "",
  },
  "FJ": {
    name: "Fiji",
    color: "",
  },
  "FI": {
    name: "Finland",
    color: "",
  },
  "FR": {
    name: "France",
    color: "",
  },
  "GF": {
    name: "French Guiana",
    color: "",
  },
  "PF": {
    name: "French Polynesia",
    color: "",
  },
  "TF": {
    name: "French Southern Territories",
    color: "",
  },
  "GA": {
    name: "Gabon",
    color: "",
  },
  "GM": {
    name: "Gambia",
    color: "",
  },
  "GE": {
    name: "Georgia",
    color: "",
  },
  "DE": {
    name: "Germany",
    color: "",
  },
  "GH": {
    name: "Ghana",
    color: "",
  },
  "GI": {
    name: "Gibraltar",
    color: "",
  },
  "GR": {
    name: "Greece",
    color: "",
  },
  "GL": {
    name: "Greenland",
    color: "",
  },
  "GD": {
    name: "Grenada",
    color: "",
  },
  "GP": {
    name: "Guadeloupe",
    color: "",
  },
  "GU": {
    name: "Guam",
    color: "",
  },
  "GT": {
    name: "Guatemala",
    color: "",
  },
  "GG": {
    name: "Guernsey",
    color: "",
  },
  "GN": {
    name: "Guinea",
    color: "",
  },
  "GW": {
    name: "Guinea-Bissau",
    color: "",
  },
  "GY": {
    name: "Guyana",
    color: "",
  },
  "HT": {
    name: "Haiti",
    color: "",
  },
  "HM": {
    name: "Heard Island & Mcdonald Islands",
    color: "",
  },
  "VA": {
    name: "Holy See (Vatican City State)",
    color: "",
  },
  "HN": {
    name: "Honduras",
    color: "",
  },
  "HK": {
    name: "Hong Kong",
    color: "",
  },
  "HU": {
    name: "Hungary",
    color: "",
  },
  "IS": {
    name: "Iceland",
    color: "",
  },
  "IN": {
    name: "India",
    color: "",
  },
  "ID": {
    name: "Indonesia",
    color: "",
  },
  "IR": {
    name: "Iran, Islamic Republic Of",
    color: "",
  },
  "IQ": {
    name: "Iraq",
    color: "",
  },
  "IE": {
    name: "Ireland",
    color: "",
  },
  "IM": {
    name: "Isle Of Man",
    color: "",
  },
  "IL": {
    name: "Israel",
    color: "",
  },
  "IT": {
    name: "Italy",
    color: "",
  },
  "JM": {
    name: "Jamaica",
    color: "",
  },
  "JP": {
    name: "Japan",
    color: "",
  },
  "JE": {
    name: "Jersey",
    color: "",
  },
  "JO": {
    name: "Jordan",
    color: "",
  },
  "KZ": {
    name: "Kazakhstan",
    color: "",
  },
  "KE": {
    name: "Kenya",
    color: "",
  },
  "KI": {
    name: "Kiribati",
    color: "",
  },
  "KR": {
    name: "Korea",
    color: "",
  },
  "KP": {
    name: "North Korea",
    color: "",
  },
  "KW": {
    name: "Kuwait",
    color: "",
  },
  "KG": {
    name: "Kyrgyzstan",
    color: "",
  },
  "LA": {
    name: "Lao People\"s Democratic Republic",
    color: "",
  },
  "LV": {
    name: "Latvia",
    color: "",
  },
  "LB": {
    name: "Lebanon",
    color: "",
  },
  "LS": {
    name: "Lesotho",
    color: "",
  },
  "LR": {
    name: "Liberia",
    color: "",
  },
  "LY": {
    name: "Libyan Arab Jamahiriya",
    color: "",
  },
  "LI": {
    name: "Liechtenstein",
    color: "",
  },
  "LT": {
    name: "Lithuania",
    color: "",
  },
  "LU": {
    name: "Luxembourg",
    color: "",
  },
  "MO": {
    name: "Macao",
    color: "",
  },
  "MK": {
    name: "Macedonia",
    color: "",
  },
  "MG": {
    name: "Madagascar",
    color: "",
  },
  "MW": {
    name: "Malawi",
    color: "",
  },
  "MY": {
    name: "Malaysia",
    color: "",
  },
  "MV": {
    name: "Maldives",
    color: "",
  },
  "ML": {
    name: "Mali",
    color: "",
  },
  "MT": {
    name: "Malta",
    color: "",
  },
  "MH": {
    name: "Marshall Islands",
    color: "",
  },
  "MQ": {
    name: "Martinique",
    color: "",
  },
  "MR": {
    name: "Mauritania",
    color: "",
  },
  "MU": {
    name: "Mauritius",
    color: "",
  },
  "YT": {
    name: "Mayotte",
    color: "",
  },
  "MX": {
    name: "Mexico",
    color: "",
  },
  "FM": {
    name: "Micronesia, Federated States Of",
    color: "",
  },
  "MD": {
    name: "Moldova",
    color: "",
  },
  "MC": {
    name: "Monaco",
    color: "",
  },
  "MN": {
    name: "Mongolia",
    color: "",
  },
  "ME": {
    name: "Montenegro",
    color: "",
  },
  "MS": {
    name: "Montserrat",
    color: "",
  },
  "MA": {
    name: "Morocco",
    color: "",
  },
  "MZ": {
    name: "Mozambique",
    color: "",
  },
  "MM": {
    name: "Myanmar",
    color: "",
  },
  "NA": {
    name: "Namibia",
    color: "",
  },
  "NR": {
    name: "Nauru",
    color: "",
  },
  "NP": {
    name: "Nepal",
    color: "",
  },
  "NL": {
    name: "Netherlands",
    color: "",
  },
  "AN": {
    name: "Netherlands Antilles",
    color: "",
  },
  "NC": {
    name: "New Caledonia",
    color: "",
  },
  "NZ": {
    name: "New Zealand",
    color: "",
  },
  "NI": {
    name: "Nicaragua",
    color: "",
  },
  "NE": {
    name: "Niger",
    color: "",
  },
  "NG": {
    name: "Nigeria",
    color: "",
  },
  "NU": {
    name: "Niue",
    color: "",
  },
  "NF": {
    name: "Norfolk Island",
    color: "",
  },
  "MP": {
    name: "Northern Mariana Islands",
    color: "",
  },
  "NO": {
    name: "Norway",
    color: "",
  },
  "OM": {
    name: "Oman",
    color: "",
  },
  "PK": {
    name: "Pakistan",
    color: "",
  },
  "PW": {
    name: "Palau",
    color: "",
  },
  "PS": {
    name: "Palestinian Territory, Occupied",
    color: "",
  },
  "PA": {
    name: "Panama",
    color: "",
  },
  "PG": {
    name: "Papua New Guinea",
    color: "",
  },
  "PY": {
    name: "Paraguay",
    color: "",
  },
  "PE": {
    name: "Peru",
    color: "",
  },
  "PH": {
    name: "Philippines",
    color: "",
  },
  "PN": {
    name: "Pitcairn",
    color: "",
  },
  "PL": {
    name: "Poland",
    color: "",
  },
  "PT": {
    name: "Portugal",
    color: "",
  },
  "PR": {
    name: "Puerto Rico",
    color: "",
  },
  "QA": {
    name: "Qatar",
    color: "",
  },
  "RE": {
    name: "Reunion",
    color: "",
  },
  "RO": {
    name: "Romania",
    color: "",
  },
  "RU": {
    name: "Russian Federation",
    color: "",
  },
  "RW": {
    name: "Rwanda",
    color: "",
  },
  "BL": {
    name: "Saint Barthelemy",
    color: "",
  },
  "SH": {
    name: "Saint Helena",
    color: "",
  },
  "KN": {
    name: "Saint Kitts And Nevis",
    color: "",
  },
  "LC": {
    name: "Saint Lucia",
    color: "",
  },
  "MF": {
    name: "Saint Martin",
    color: "",
  },
  "PM": {
    name: "Saint Pierre And Miquelon",
    color: "",
  },
  "VC": {
    name: "Saint Vincent And Grenadines",
    color: "",
  },
  "WS": {
    name: "Samoa",
    color: "",
  },
  "SM": {
    name: "San Marino",
    color: "",
  },
  "ST": {
    name: "Sao Tome And Principe",
    color: "",
  },
  "SA": {
    name: "Saudi Arabia",
    color: "",
  },
  "SN": {
    name: "Senegal",
    color: "",
  },
  "RS": {
    name: "Serbia",
    color: "",
  },
  "SC": {
    name: "Seychelles",
    color: "",
  },
  "SL": {
    name: "Sierra Leone",
    color: "",
  },
  "SG": {
    name: "Singapore",
    color: "",
  },
  "SK": {
    name: "Slovakia",
    color: "",
  },
  "SI": {
    name: "Slovenia",
    color: "",
  },
  "SB": {
    name: "Solomon Islands",
    color: "",
  },
  "SO": {
    name: "Somalia",
    color: "",
  },
  "ZA": {
    name: "South Africa",
    color: "",
  },
  "GS": {
    name: "South Georgia And Sandwich Isl.",
    color: "",
  },
  "ES": {
    name: "Spain",
    color: "",
  },
  "LK": {
    name: "Sri Lanka",
    color: "",
  },
  "SD": {
    name: "Sudan",
    color: "",
  },
  "SR": {
    name: "Suriname",
    color: "",
  },
  "SJ": {
    name: "Svalbard And Jan Mayen",
    color: "",
  },
  "SZ": {
    name: "Swaziland",
    color: "",
  },
  "SE": {
    name: "Sweden",
    color: "",
  },
  "CH": {
    name: "Switzerland",
    color: "",
  },
  "SY": {
    name: "Syrian Arab Republic",
    color: "",
  },
  "TW": {
    name: "Taiwan",
    color: "",
  },
  "TJ": {
    name: "Tajikistan",
    color: "",
  },
  "TZ": {
    name: "Tanzania",
    color: "",
  },
  "TH": {
    name: "Thailand",
    color: "",
  },
  "TL": {
    name: "Timor-Leste",
    color: "",
  },
  "TG": {
    name: "Togo",
    color: "",
  },
  "TK": {
    name: "Tokelau",
    color: "",
  },
  "TO": {
    name: "Tonga",
    color: "",
  },
  "TT": {
    name: "Trinidad And Tobago",
    color: "",
  },
  "TN": {
    name: "Tunisia",
    color: "",
  },
  "TR": {
    name: "Turkey",
    color: "",
  },
  "TM": {
    name: "Turkmenistan",
    color: "",
  },
  "TC": {
    name: "Turks And Caicos Islands",
    color: "",
  },
  "TV": {
    name: "Tuvalu",
    color: "",
  },
  "UG": {
    name: "Uganda",
    color: "",
  },
  "UA": {
    name: "Ukraine",
    color: "",
  },
  "AE": {
    name: "United Arab Emirates",
    color: "",
  },
  "GB": {
    name: "United Kingdom",
    color: "",
  },
  "US": {
    name: "United States",
    color: "",
  },
  "UM": {
    name: "United States Outlying Islands",
    color: "",
  },
  "UY": {
    name: "Uruguay",
    color: "",
  },
  "UZ": {
    name: "Uzbekistan",
    color: "",
  },
  "VU": {
    name: "Vanuatu",
    color: "",
  },
  "VE": {
    name: "Venezuela",
    color: "",
  },
  "VN": {
    name: "Vietnam",
    color: "",
  },
  "VG": {
    name: "Virgin Islands, British",
    color: "",
  },
  "VI": {
    name: "Virgin Islands, U.S.",
    color: "",
  },
  "WF": {
    name: "Wallis And Futuna",
    color: "",
  },
  "EH": {
    name: "Western Sahara",
    color: "",
  },
  "YE": {
    name: "Yemen",
    color: "",
  },
  "ZM": {
    name: "Zambia",
    color: "",
  },
  "ZW": {
    name: "Zimbabwe",
    color: "",
  },
}

const codeToCountry = {
  "Afghanistan": {
    code: "AF",
    color: ""
  },
  "land Islands": {
    code: "AX",
    color: ""
  },
  "Albania": {
    code: "AL",
    color: ""
  },
  "Algeria": {
    code: "DZ",
    color: ""
  },
  "American Samoa": {
    code: "AS",
    color: ""
  },
  "AndorrA": {
    code: "AD",
    color: ""
  },
  "Angola": {
    code: "AO",
    color: ""
  },
  "Anguilla": {
    code: "AI",
    color: ""
  },
  "Antarctica": {
    code: "AQ",
    color: ""
  },
  "Antigua and Barbuda": {
    code: "AG",
    color: ""
  },
  "Argentina": {
    code: "AR",
    color: ""
  },
  "Armenia": {
    code: "AM",
    color: ""
  },
  "Aruba": {
    code: "AW",
    color: ""
  },
  "Australia": {
    code: "AU",
    color: ""
  },
  "Austria": {
    code: "AT",
    color: ""
  },
  "Azerbaijan": {
    code: "AZ",
    color: ""
  },
  "Bahamas": {
    code: "BS",
    color: ""
  },
  "Bahrain": {
    code: "BH",
    color: ""
  },
  "Bangladesh": {
    code: "BD",
    color: ""
  },
  "Barbados": {
    code: "BB",
    color: ""
  },
  "Belarus": {
    code: "BY",
    color: ""
  },
  "Belgium": {
    code: "BE",
    color: ""
  },
  "Belize": {
    code: "BZ",
    color: ""
  },
  "Benin": {
    code: "BJ",
    color: ""
  },
  "Bermuda": {
    code: "BM",
    color: ""
  },
  "Bhutan": {
    code: "BT",
    color: ""
  },
  "Bolivia": {
    code: "BO",
    color: ""
  },
  "Bosnia and Herzegovina": {
    code: "BA",
    color: ""
  },
  "Botswana": {
    code: "BW",
    color: ""
  },
  "Bouvet Island": {
    code: "BV",
    color: ""
  },
  "Brazil": {
    code: "BR",
    color: ""
  },
  "British Indian Ocean Territory": {
    code: "IO",
    color: ""
  },
  "Brunei Darussalam": {
    code: "BN",
    color: ""
  },
  "Bulgaria": {
    code: "BG",
    color: ""
  },
  "Burkina Faso": {
    code: "BF",
    color: ""
  },
  "Burundi": {
    code: "BI",
    color: ""
  },
  "Cambodia": {
    code: "KH",
    color: ""
  },
  "Cameroon": {
    code: "CM",
    color: ""
  },
  "Canada": {
    code: "CA",
    color: ""
  },
  "Cape Verde": {
    code: "CV",
    color: ""
  },
  "Cayman Islands": {
    code: "KY",
    color: ""
  },
  "Central African Republic": {
    code: "CF",
    color: ""
  },
  "Chad": {
    code: "TD",
    color: ""
  },
  "Chile": {
    code: "CL",
    color: ""
  },
  "China": {
    code: "CN",
    color: ""
  },
  "Christmas Island": {
    code: "CX",
    color: ""
  },
  "Cocos (Keeling) Islands": {
    code: "CC",
    color: ""
  },
  "Colombia": {
    code: "CO",
    color: ""
  },
  "Comoros": {
    code: "KM",
    color: ""
  },
  "Congo": {
    code: "CG",
    color: ""
  },
  "Congo, The Democratic Republic of the": {
    code: "CD",
    color: ""
  },
  "Cook Islands": {
    code: "CK",
    color: ""
  },
  "Costa Rica": {
    code: "CR",
    color: ""
  },
  "Cote D\"Ivoire": {
    code: "CI",
    color: ""
  },
  "Croatia": {
    code: "HR",
    color: ""
  },
  "Cuba": {
    code: "CU",
    color: ""
  },
  "Cyprus": {
    code: "CY",
    color: ""
  },
  "Czech Republic": {
    code: "CZ",
    color: ""
  },
  "Denmark": {
    code: "DK",
    color: ""
  },
  "Djibouti": {
    code: "DJ",
    color: ""
  },
  "Dominica": {
    code: "DM",
    color: ""
  },
  "Dominican Republic": {
    code: "DO",
    color: ""
  },
  "Ecuador": {
    code: "EC",
    color: ""
  },
  "Egypt": {
    code: "EG",
    color: ""
  },
  "El Salvador": {
    code: "SV",
    color: ""
  },
  "Equatorial Guinea": {
    code: "GQ",
    color: ""
  },
  "Eritrea": {
    code: "ER",
    color: ""
  },
  "Estonia": {
    code: "EE",
    color: ""
  },
  "Ethiopia": {
    code: "ET",
    color: ""
  },
  "Falkland Islands (Malvinas)": {
    code: "FK",
    color: ""
  },
  "Faroe Islands": {
    code: "FO",
    color: ""
  },
  "Fiji": {
    code: "FJ",
    color: ""
  },
  "Finland": {
    code: "FI",
    color: ""
  },
  "France": {
    code: "FR",
    color: ""
  },
  "French Guiana": {
    code: "GF",
    color: ""
  },
  "French Polynesia": {
    code: "PF",
    color: ""
  },
  "French Southern Territories": {
    code: "TF",
    color: ""
  },
  "Gabon": {
    code: "GA",
    color: ""
  },
  "Gambia": {
    code: "GM",
    color: ""
  },
  "Georgia": {
    code: "GE",
    color: ""
  },
  "Germany": {
    code: "DE",
    color: ""
  },
  "Ghana": {
    code: "GH",
    color: ""
  },
  "Gibraltar": {
    code: "GI",
    color: ""
  },
  "Greece": {
    code: "GR",
    color: ""
  },
  "Greenland": {
    code: "GL",
    color: ""
  },
  "Grenada": {
    code: "GD",
    color: ""
  },
  "Guadeloupe": {
    code: "GP",
    color: ""
  },
  "Guam": {
    code: "GU",
    color: ""
  },
  "Guatemala": {
    code: "GT",
    color: ""
  },
  "Guernsey": {
    code: "GG",
    color: ""
  },
  "Guinea": {
    code: "GN",
    color: ""
  },
  "Guinea-Bissau": {
    code: "GW",
    color: ""
  },
  "Guyana": {
    code: "GY",
    color: ""
  },
  "Haiti": {
    code: "HT",
    color: ""
  },
  "Heard Island and Mcdonald Islands": {
    code: "HM",
    color: ""
  },
  "Holy See (Vatican City State)": {
    code: "VA",
    color: ""
  },
  "Honduras": {
    code: "HN",
    color: ""
  },
  "Hong Kong": {
    code: "HK",
    color: ""
  },
  "Hungary": {
    code: "HU",
    color: ""
  },
  "Iceland": {
    code: "IS",
    color: ""
  },
  "India": {
    code: "IN",
    color: ""
  },
  "Indonesia": {
    code: "ID",
    color: ""
  },
  "Iran, Islamic Republic Of": {
    code: "IR",
    color: ""
  },
  "Iraq": {
    code: "IQ",
    color: ""
  },
  "Ireland": {
    code: "IE",
    color: ""
  },
  "Isle of Man": {
    code: "IM",
    color: ""
  },
  "Israel": {
    code: "IL",
    color: ""
  },
  "Italy": {
    code: "IT",
    color: ""
  },
  "Jamaica": {
    code: "JM",
    color: ""
  },
  "Japan": {
    code: "JP",
    color: ""
  },
  "Jersey": {
    code: "JE",
    color: ""
  },
  "Jordan": {
    code: "JO",
    color: ""
  },
  "Kazakhstan": {
    code: "KZ",
    color: ""
  },
  "Kenya": {
    code: "KE",
    color: ""
  },
  "Kiribati": {
    code: "KI",
    color: ""
  },
  "Korea, Democratic People\"S Republic of": {
    code: "KP",
    color: ""
  },
  "Korea, Republic of": {
    code: "KR",
    color: ""
  },
  "Kuwait": {
    code: "KW",
    color: ""
  },
  "Kyrgyzstan": {
    code: "KG",
    color: ""
  },
  "Lao People\"S Democratic Republic": {
    code: "LA",
    color: ""
  },
  "Latvia": {
    code: "LV",
    color: ""
  },
  "Lebanon": {
    code: "LB",
    color: ""
  },
  "Lesotho": {
    code: "LS",
    color: ""
  },
  "Liberia": {
    code: "LR",
    color: ""
  },
  "Libyan Arab Jamahiriya": {
    code: "LY",
    color: ""
  },
  "Liechtenstein": {
    code: "LI",
    color: ""
  },
  "Lithuania": {
    code: "LT",
    color: ""
  },
  "Luxembourg": {
    code: "LU",
    color: ""
  },
  "Macao": {
    code: "MO",
    color: ""
  },
  "Macedonia, The Former Yugoslav Republic of": {
    code: "MK",
    color: ""
  },
  "Madagascar": {
    code: "MG",
    color: ""
  },
  "Malawi": {
    code: "MW",
    color: ""
  },
  "Malaysia": {
    code: "MY",
    color: ""
  },
  "Maldives": {
    code: "MV",
    color: ""
  },
  "Mali": {
    code: "ML",
    color: ""
  },
  "Malta": {
    code: "MT",
    color: ""
  },
  "Marshall Islands": {
    code: "MH",
    color: ""
  },
  "Martinique": {
    code: "MQ",
    color: ""
  },
  "Mauritania": {
    code: "MR",
    color: ""
  },
  "Mauritius": {
    code: "MU",
    color: ""
  },
  "Mayotte": {
    code: "YT",
    color: ""
  },
  "Mexico": {
    code: "MX",
    color: ""
  },
  "Micronesia, Federated States of": {
    code: "FM",
    color: ""
  },
  "Moldova, Republic of": {
    code: "MD",
    color: ""
  },
  "Monaco": {
    code: "MC",
    color: ""
  },
  "Mongolia": {
    code: "MN",
    color: ""
  },
  "Montenegro": {
    code: "ME",
    color: ""
  },
  "Montserrat": {
    code: "MS",
    color: ""
  },
  "Morocco": {
    code: "MA",
    color: ""
  },
  "Mozambique": {
    code: "MZ",
    color: ""
  },
  "Myanmar": {
    code: "MM",
    color: ""
  },
  "Namibia": {
    code: "NA",
    color: ""
  },
  "Nauru": {
    code: "NR",
    color: ""
  },
  "Nepal": {
    code: "NP",
    color: ""
  },
  "Netherlands": {
    code: "NL",
    color: ""
  },
  "Netherlands Antilles": {
    code: "AN",
    color: ""
  },
  "New Caledonia": {
    code: "NC",
    color: ""
  },
  "New Zealand": {
    code: "NZ",
    color: ""
  },
  "Nicaragua": {
    code: "NI",
    color: ""
  },
  "Niger": {
    code: "NE",
    color: ""
  },
  "Nigeria": {
    code: "NG",
    color: ""
  },
  "Niue": {
    code: "NU",
    color: ""
  },
  "Norfolk Island": {
    code: "NF",
    color: ""
  },
  "Northern Mariana Islands": {
    code: "MP",
    color: ""
  },
  "Norway": {
    code: "NO",
    color: ""
  },
  "Oman": {
    code: "OM",
    color: ""
  },
  "Pakistan": {
    code: "PK",
    color: ""
  },
  "Palau": {
    code: "PW",
    color: ""
  },
  "Palestinian Territory, Occupied": {
    code: "PS",
    color: ""
  },
  "Panama": {
    code: "PA",
    color: ""
  },
  "Papua New Guinea": {
    code: "PG",
    color: ""
  },
  "Paraguay": {
    code: "PY",
    color: ""
  },
  "Peru": {
    code: "PE",
    color: ""
  },
  "Philippines": {
    code: "PH",
    color: ""
  },
  "Pitcairn": {
    code: "PN",
    color: ""
  },
  "Poland": {
    code: "PL",
    color: ""
  },
  "Portugal": {
    code: "PT",
    color: ""
  },
  "Puerto Rico": {
    code: "PR",
    color: ""
  },
  "Qatar": {
    code: "QA",
    color: ""
  },
  "Reunion": {
    code: "RE",
    color: ""
  },
  "Romania": {
    code: "RO",
    color: ""
  },
  "Russian Federation": {
    code: "RU",
    color: ""
  },
  "RWANDA": {
    code: "RW",
    color: ""
  },
  "Saint Helena": {
    code: "SH",
    color: ""
  },
  "Saint Kitts and Nevis": {
    code: "KN",
    color: ""
  },
  "Saint Lucia": {
    code: "LC",
    color: ""
  },
  "Saint Pierre and Miquelon": {
    code: "PM",
    color: ""
  },
  "Saint Vincent and the Grenadines": {
    code: "VC",
    color: ""
  },
  "Samoa": {
    code: "WS",
    color: ""
  },
  "San Marino": {
    code: "SM",
    color: ""
  },
  "Sao Tome and Principe": {
    code: "ST",
    color: ""
  },
  "Saudi Arabia": {
    code: "SA",
    color: ""
  },
  "Senegal": {
    code: "SN",
    color: ""
  },
  "Serbia": {
    code: "RS",
    color: ""
  },
  "Seychelles": {
    code: "SC",
    color: ""
  },
  "Sierra Leone": {
    code: "SL",
    color: ""
  },
  "Singapore": {
    code: "SG",
    color: ""
  },
  "Slovakia": {
    code: "SK",
    color: ""
  },
  "Slovenia": {
    code: "SI",
    color: ""
  },
  "Solomon Islands": {
    code: "SB",
    color: ""
  },
  "Somalia": {
    code: "SO",
    color: ""
  },
  "South Africa": {
    code: "ZA",
    color: ""
  },
  "South Georgia and the South Sandwich Islands": {
    code: "GS",
    color: ""
  },
  "Spain": {
    code: "ES",
    color: ""
  },
  "Sri Lanka": {
    code: "LK",
    color: ""
  },
  "Sudan": {
    code: "SD",
    color: ""
  },
  "Suriname": {
    code: "SR",
    color: ""
  },
  "Svalbard and Jan Mayen": {
    code: "SJ",
    color: ""
  },
  "Swaziland": {
    code: "SZ",
    color: ""
  },
  "Sweden": {
    code: "SE",
    color: ""
  },
  "Switzerland": {
    code: "CH",
    color: ""
  },
  "Syrian Arab Republic": {
    code: "SY",
    color: ""
  },
  "Taiwan, Province of China": {
    code: "TW",
    color: ""
  },
  "Tajikistan": {
    code: "TJ",
    color: ""
  },
  "Tanzania, United Republic of": {
    code: "TZ",
    color: ""
  },
  "Thailand": {
    code: "TH",
    color: ""
  },
  "Timor-Leste": {
    code: "TL",
    color: ""
  },
  "Togo": {
    code: "TG",
    color: ""
  },
  "Tokelau": {
    code: "TK",
    color: ""
  },
  "Tonga": {
    code: "TO",
    color: ""
  },
  "Trinidad and Tobago": {
    code: "TT",
    color: ""
  },
  "Tunisia": {
    code: "TN",
    color: ""
  },
  "Turkey": {
    code: "TR",
    color: ""
  },
  "Turkmenistan": {
    code: "TM",
    color: ""
  },
  "Turks and Caicos Islands": {
    code: "TC",
    color: ""
  },
  "Tuvalu": {
    code: "TV",
    color: ""
  },
  "Uganda": {
    code: "UG",
    color: ""
  },
  "Ukraine": {
    code: "UA",
    color: ""
  },
  "United Arab Emirates": {
    code: "AE",
    color: ""
  },
  "United Kingdom": {
    code: "GB",
    color: ""
  },
  "United States": {
    code: "US",
    color: ""
  },
  "United States Minor Outlying Islands": {
    code: "UM",
    color: ""
  },
  "Uruguay": {
    code: "UY",
    color: ""
  },
  "Uzbekistan": {
    code: "UZ",
    color: ""
  },
  "Vanuatu": {
    code: "VU",
    color: ""
  },
  "Venezuela": {
    code: "VE",
    color: ""
  },
  "Viet Nam": {
    code: "VN",
    color: ""
  },
  "Virgin Islands, British": {
    code: "VG",
    color: ""
  },
  "Virgin Islands, U.S.": {
    code: "VI",
    color: ""
  },
  "Wallis and Futuna": {
    code: "WF",
    color: ""
  },
  "Western Sahara": {
    code: "EH",
    color: ""
  },
  "Yemen": {
    code: "YE",
    color: ""
  },
"Zambia": {
    code: "ZM",
    color: ""
  },
  "Zimbabwe": {
    code: "ZW",
    color: ""
  }
}


export {countryCodes,codeToCountry};