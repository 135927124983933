import { CheckBox, CheckBoxOutlineBlank, Info } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";
import { genderOptions, audienceOptions } from "./FormConstants";
import MultiselectLocations from "./multiselectLocations";
import {
  getAgeCategories,
  getIndustries,
  getLanguages,
} from "../../services/campaignBrief";

function Step3Reach({ setValid, step3Values, setStep3Values }) {
  const [locations, setLocations] = useState(step3Values.locations);
  const [age, setAge] = useState(step3Values.age);
  const [ageOptions, setAgeOptions] = useState([]);
  const [gender, setGender] = useState(step3Values.gender);
  const [industry, setIndustry] = useState(step3Values.industry);
  const [industries, setIndustries] = useState([]);
  const [languages, setLanguages] = useState(step3Values.languages);
  const [allLanguages, setAllLanguages] = useState([]);
  const [religion, setReligion] = useState(step3Values.religion);
  const [audience, setAudience] = useState(step3Values.audience);
  const [social, setSocial] = useState(step3Values.social);

  const getAgeOptionsData = async () => {
    const ageOptionsData = await getAgeCategories();

    if (ageOptionsData.status) {
      setAgeOptions(ageOptionsData.response);
    }
  };

  const getIndustriesData = async () => {
    const industriesData = await getIndustries();

    if (industriesData.status) {
      setIndustries(industriesData.response);
    }
  };

  const getAllLanguagesData = async () => {
    const allLanguagesData = await getLanguages();

    if (allLanguagesData.status) {
      setAllLanguages(allLanguagesData.response);
    }
  };

  useEffect(() => {
    getAgeOptionsData();
    getIndustriesData();
    getAllLanguagesData();
  }, []);

  useEffect(() => {
    let valid = false;
    if (locations?.length > 0) {
      valid = true;
    }
    setValid(valid);
  }, [locations, setValid]);

  useEffect(() => {
    const stepValues = {};
    stepValues.age = age;
    stepValues.gender = gender;
    stepValues.industry = industry;
    stepValues.languages = languages;
    stepValues.religion = religion;
    stepValues.audience = audience;
    stepValues.locations = locations;
    stepValues.social = social;
    setStep3Values(stepValues);
  }, [
    age,
    gender,
    industry,
    languages,
    religion,
    audience,
    locations,
    social,
    setStep3Values,
  ]);

  const handleLocations = (value) => {
    if (value !== "") {
      setLocations((prev) => {
        const locations = [...prev];
        if (!locations.includes(value)) {
          locations.push(value);
        }
        return locations;
      });
    }
  };

  const removeLocation = (value) => {
    if (locations.includes(value)) {
      setLocations((prev) => {
        prev = prev?.filter((item) => item !== value);
        return prev;
      });
    }
  };

  return (
    <>
      <div className={styles.step_title}>Audience/Consumer Targets</div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MultiselectLocations
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "20px",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            locationValues={locations}
            setLocationValues={handleLocations}
            removeLocation={removeLocation}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Age </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={ageOptions}
            disableCloseOnSelect
            value={age}
            onChange={(e, val) => setAge(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Gender </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={genderOptions}
            disableCloseOnSelect
            value={gender}
            onChange={(e, val) => setGender(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Industry </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={industries}
            disableCloseOnSelect
            value={industry}
            onChange={(e, val) => setIndustry(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Languages </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={allLanguages}
            disableCloseOnSelect
            value={languages}
            onChange={(e, val) => setLanguages(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Religion </span>
            <Tooltip title="Does religion impact this campaign?">
              <Info fontSize="small" />
            </Tooltip>
            <span className={styles.step_field_sub}> (optional)</span>
            <br />
            <FormControlLabel
              control={
                <Switch onChange={(val) => setReligion(val.target.checked)} />
              }
              value={religion}
              label={religion ? "Yes" : "No"}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Audience Touch Points </span>
            <Tooltip title="Where would people interact with a typical campaign or project? Are we driving people to an app, a landing page, a hotline, a clinic, or sending SMS etc.?">
              <Info fontSize="small" />
            </Tooltip>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={audienceOptions}
            disableCloseOnSelect
            value={audience}
            onChange={(e, val) => setAudience(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <span className={styles.step_field}>Social Challenges </span>
            <Tooltip title="What social challenges might they be facing? What is their daily experience that might help us understand the target group?">
              <Info fontSize="small" />
            </Tooltip>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <TextField
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "46px",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            value={social}
            onChange={(val) => setSocial(val.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

Step3Reach.propTypes = {
  setValid: PropTypes.func.isRequired,
  step3Values: PropTypes.object.isRequired,
  setStep3Values: PropTypes.func.isRequired,
};

export default Step3Reach;
