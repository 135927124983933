import PropTypes from "prop-types";
import ProgressChart from "./progressChart";
import ProgressChartCountry from "./ProgressChartCountry";

const AudienceProgressChart = ({
  data,
  mapData,
  chartTitle,
  parentStyle,
  country,
  backgroundColor,
  expandButtonVisible,
  tooltip,
  tooltipText
}) => {
  return country
    ? data && (
        <ProgressChartCountry
          data={data}
          mapData={mapData}
          chartTitle={chartTitle}
          parentStyle={parentStyle}
          expand={true}
          backgroundColor={backgroundColor}
        />
      )
    : data && (
        <div style={{ minWidth: "460px" }}>
          <ProgressChart
            data={data}
            chartTitle={chartTitle}
            parentStyle={parentStyle}
            backgroundColor={backgroundColor}
            expandButtonVisible={expandButtonVisible}
            toolTip={tooltip}
            tooltipText={tooltipText}
          />
        </div>
      );
};

AudienceProgressChart.propTypes = {
  data: PropTypes.array.isRequired,
  mapData: PropTypes.array.isRequired,
  chartTitle: PropTypes.string.isRequired,
  parentStyle: PropTypes.object.isRequired,
  country: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  expandButtonVisible: PropTypes.bool,
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string
};

export default AudienceProgressChart;
