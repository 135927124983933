import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import discoveryUtils from "../../../utility/oryxUtils/discoveryUtils";
import StyledMenu from "../StyledMenu";
import utils from "../../../utility/oryxUtils/utils";
import CustomFormControlLabel from "./common/CustomFormControlLabel";
import {
  FormControl,
  Grid,
  RadioGroup,
  Tooltip,
  Radio,
  Box,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Genders = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isInfFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [displayStyle, setDisplayStyle] = useState(false);
  const [range, setRange] = useState(0.5);
  const [audGender, setAudGender] = useState("ANY");
  const [infGender, setInfGender] = useState("ANY");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, type) => {
    let newPayload = payload;
    let gender = "";
    let weight = range;
    let newGender = [];
    if (event.target.value !== "ANY") {
      gender = event.target.value;
      setDisplayStyle(true);
      let genderPayload = { code: gender, weight: weight };
      if (type === "aud") {
        newPayload["aud_gender"] = genderPayload;
        newGender.push(gender);
        customCols = utils.addObj(customCols, "aud_gender");
        customCols[0]["aud_gender"] = newGender;
        allFiltersData = utils.addObj(allFiltersData, "aud_gender");
        allFiltersData[0]["aud_gender"][0] = genderPayload;
      } else {
        newPayload["inf_gender"] = genderPayload;
        allFiltersData = utils.addObj(allFiltersData, "inf_gender");
        allFiltersData[0]["inf_gender"][0] = genderPayload;
      }
    } else {
      delete newPayload[type + "_gender"];
      delete customCols[0][type + "_gender"];
      delete allFiltersData[0][type + "_gender"];
      setDisplayStyle(false);
    }
    setSelectedCol(customCols);
    setCustomCols(customCols);
    setAllFiltersData(allFiltersData);
    setPayload(newPayload);
  };

  const handleRange = (event) => {
    let newPayload = payload;
    newPayload["aud_gender"].weight = event.target.value;
    setPayload(newPayload);
    setRange(event.target.value);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip
            title={
              isInfFilter
                ? FilterTooltips.gender.infTooltipMsg
                : FilterTooltips.gender.audTooltipMsg
            }
          >
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                fontWeight: '400'
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              fullWidth
              size="large"
              endIcon={open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
            >
              {isInfFilter ? "Gender" : "Audience Gender"}
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} style={{ height: "auto" }}>
              {!isInfFilter && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControl sx={{ m: 1 }}>Gender</FormControl>
                    </Grid>
                  </Grid>
                  <FormControl sx={{ m: 1 }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="any"
                      name="radio-buttons-group"
                      size="small"
                      onChange={(e) => handleChange(e, "aud")}
                      value={
                        typeof allFiltersData[0]["aud_gender"] === "undefined"
                          ? audGender
                          : allFiltersData[0]["aud_gender"][0]["code"]
                      }
                    >
                      <CustomFormControlLabel
                        value="ANY"
                        control={<Radio size="small" />}
                        label="Any"
                      />
                      <CustomFormControlLabel
                        value="MALE"
                        control={<Radio size="small" />}
                        label="Male"
                      />
                      <CustomFormControlLabel
                        value="FEMALE"
                        control={<Radio size="small" />}
                        label="Female"
                      />
                    </RadioGroup>
                    {displayStyle && (
                      <Box sx={{ my: "1rem" }}>
                        {discoveryUtils.getRangesFilter(range, "", handleRange)}
                      </Box>
                    )}
                  </FormControl>
                </Grid>
              )}
              {isInfFilter && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControl sx={{ m: 1 }}>Gender</FormControl>
                    </Grid>
                  </Grid>
                  <FormControl sx={{ m: 1 }} size="small">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="any"
                      name="radio-buttons-group"
                      size="small"
                      onChange={(e) => handleChange(e, "inf")}
                      value={
                        typeof allFiltersData[0]["inf_gender"] === "undefined"
                          ? infGender
                          : allFiltersData[0]["inf_gender"][0]["code"]
                      }
                    >
                      <CustomFormControlLabel
                        value="ANY"
                        control={<Radio size="small" />}
                        label="Any"
                      />
                      <CustomFormControlLabel
                        value="MALE"
                        control={<Radio size="small" />}
                        label="Male"
                      />
                      <CustomFormControlLabel
                        value="FEMALE"
                        control={<Radio size="small" />}
                        label="Female"
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <CustomFormControlLabel
                            value="KNOWN"
                            control={<Radio size="small" />}
                            label="Male or Female"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Tooltip
                            title={
                              "Identify influencers by having sponsored posts with genders. We detect sponsored posts either by usage of commercial hashtags or official paid partnership tag. You can add several genders and your search results will be refined with influencers that have sponsored posts for ANY of the specified genders."
                            }
                          >
                            <HelpRoundedIcon
                              fontSize="small"
                              style={{ opacity: 0.3 }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={9}>
                          <CustomFormControlLabel
                            value="UNKNOWN"
                            control={<Radio size="small" />}
                            label="Gender Neutral"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Tooltip
                            title={
                              "Identify influencers by having sponsored posts with genders. We detect sponsored posts either by usage of commercial hashtags or official paid partnership tag. You can add several genders and your search results will be refined with influencers that have sponsored posts for ANY of the specified genders."
                            }
                          >
                            <HelpRoundedIcon
                              fontSize="small"
                              style={{ opacity: 0.3 }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Genders.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Genders;
