import { Grid } from "@mui/material";
import { getFutureDate } from "../../utility/momentManipulations";
import { useLocation } from "react-router-dom";

const PaymentSlip = ({ billingDetails, email,amount, planDetails }) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const planName = params.get("planName");
  const tenure = params.get("tenure")
  const price = tenure === "monthly" ? planDetails?.planPrice : Math.round((planDetails?.planPrice * 6) * 0.8);
  const amountPaid = planName === "Free" ? "$0" : price;
  const nextBillingAmount = planName === "Free" ? "Expires in 7 days" : price;
  const trialValidDate = new Date(getFutureDate(new Date(), 7, "days")).toDateString();
  const planActivationDate = new Date(getFutureDate(new Date(), 8, "days")).toDateString();
  const nextBillingDate = new Date(getFutureDate(new Date(), 37, "days")).toDateString();

  return (
    <div style={styles.paymentSlipContainer}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <h4 style={styles.billHeader}>Amount Paid</h4>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <h4 style={styles.billAmount}>${amountPaid}</h4>
        </Grid>
      </Grid>
      <hr style={styles.separator} />
      <Grid container>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Plan</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{planName === "Free" ? "Trial (7 Days)" : planName}</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Payment Method</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{billingDetails ? `Credit Card (*${billingDetails.cardNumber.slice(-4)})` : "Not available"}</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Trial Valid Till</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{trialValidDate}</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Plan Activation Date</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{planActivationDate}</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Next Billing Date</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{nextBillingDate}</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Next Billing Amount</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{planName !== "Free" && `$`}{nextBillingAmount}</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billHeaders}>Billing e-mail</h1>
        </Grid>
        <Grid item xs={6}>
          <h1 style={styles.billValuesBold}>{email || "finance@company.com"}</h1>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = {
  paymentSlipContainer: {
    backgroundColor: '#f7f9fa',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'left',
  },
  billHeader: {
    fontSize: '18px',
    margin: '10px 0',
    fontWeight: 'normal',
  },
  billAmount: {
    fontSize: '18px',
    margin: '10px 0',
    fontWeight: 'bold',
  },
  separator: {
    width: '100%',
    margin: '20px 0',
  },
  billHeaders: {
    fontSize: '16px',
    color: '#666',
    margin: '10px 0',
    textAlign: 'left',
  },
  billValuesBold: {
    fontSize: '16px',
    color: '#333',
    margin: '10px 0',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  actionButton: {
    margin: '10px',
  },
};

export default PaymentSlip;
