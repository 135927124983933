import React from "react";
import config from "../../config/main.json"
import styles from "../../styles/internal/Landing/Landing.module.css";

const topBrands = `${config.BASE_IMAGE_URL}TopBrands.png`

function TopBrands() {
  return (
    <div className={styles.footer}>
    <div className={styles.footerTitle}>Trusted by Top Brands</div>
    <img 
      src={topBrands} 
      alt="trusted organizations" 
      style={{ 
        marginTop: "70px", 
        marginBottom: "20px", 
        width: "90%",
        height: "auto",
      }} 
    />
  </div>
  

  );
}

export default TopBrands;
