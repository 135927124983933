import { TableCell, TableRow, styled, tableCellClasses } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#9d9d9d",
    color: "#000000",
    whiteSpace: "noWrap",
    border: 0,
    borderBottom: 1,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    whiteSpace: "nowrap",
    borderRadius: 0,
    border: 0,
    borderBottom: "1px solid #f5f5f5",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    borderRadius: 0,
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderTop: 1,
    fontWeight: "1000",
  },
}));

export const StyledTableRowPlain = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    borderRadius: 0,
    border: 0,
  },
}));
