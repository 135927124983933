import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { array } from "prop-types";

const MiniTableCard = (props) => {
  const { tableBodyData, fluidWidth } = props;

  return (
    <div>
      {tableBodyData?.length ? (
        tableBody(tableBodyData, fluidWidth)
      ) : (
        <Paper
          elevation={3}
          sx={{
            minHeight: "100px",
            minWidth: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data available
        </Paper>
      )}
    </div>
  );
};

const tableBody = (rows, fluidWidth) => {
  return (
    <TableContainer
      component={Paper}
      elevation={4}
      sx={{
        width: "100%",
        maxHeight: "200px",
        maxWidth: fluidWidth ? "100%" : "450px",
        border: "none",
      }}
    >
      <Table stickyHeader size="small" aria-label="dense mini table">
        <TableHead>
          <StyledTableRow>
            {Object.keys(rows[0])?.map((rowKey, rowKeyIdx) =>
              rowKeyIdx === 0 ? (
                <StyledTableCell>{rowKey}</StyledTableCell>
              ) : (
                <StyledTableCell align="right">{rowKey}</StyledTableCell>
              )
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, idx) => (
            <StyledTableRow key={idx} sx={{ borderRadius: 0 }}>
              {Object.keys(row)?.map((rowKey, rowKeyIdx) =>
                rowKeyIdx === 0 ? (
                  <StyledTableCell component="th" scope="row">
                    {row[rowKey]}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="right" sx={{ borderRadius: 0 }}>
                    {row[rowKey]}
                  </StyledTableCell>
                )
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#232023",
    color: "#ffffff",
    fontSize: 14,
    border: 0,
    whiteSpace: "nowrap",
    borderRadius: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    whiteSpace: "nowrap",
    fontWeight: "bold",
    border: 0,
    borderRadius: 0,
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

MiniTableCard.propTypes = {
  tableBodyData: array.isRequired,
};

export default MiniTableCard;
