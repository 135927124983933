import utils from "../../utility/oryxUtils/utils";

const BarChart = (
  xAxisData,
  showyAxis,
  yAcisData,
  legend,
  magicType,
  showToolbox = true,
  showXAxis = true,
  barWidth = 30,
  showXAxisLine = true,
  yAxisConfig = {},
  titleConfig,
  xAxisConfig,
  axisTickConfig,
  axisLabelConfig,
  tooltipConfig
) => {
  const option = {
    title: {
      ...titleConfig,
    },
    tooltip: {
      trigger: "item",
      ...tooltipConfig,
    },
    legend: {
      data: legend ? legend : [],
    },
    xAxis: {
      show: showXAxis,
      type: "category",
      data: xAxisData,
      axisLine: {
        show: showXAxisLine,
      },
      axisTick: {
        show: showXAxisLine,
        ...axisTickConfig,
      },
      axisLabel: {
        ...axisLabelConfig,
      },
      ...xAxisConfig,
    },
    yAxis: {
      type: "value",
      show: showyAxis,
      ...yAxisConfig,
    },
    toolbox: {
      show: true,
      feature: {
        magicType: { show: true, type: magicType },
        saveAsImage: { show: true },
      },
    },
    barMaxWidth: barWidth,
    series: yAcisData,
  };

  return option;
};

const DoughnutChart = (
  title,
  color,
  legendOrient,
  left,
  top,
  data,
  radius,
  percentFromatter,
  showLabel = false,
  showToolbox = true,
  tooltipConfig = {},
  seriesConfig = {},
  chartTitle
) => {
  const option = {
    title: {
      show: chartTitle ? true : false,
      text: chartTitle,
      padding: [20, 20, 20, 20],
    },
    tooltip: {
      trigger: "item",
      ...tooltipConfig,
    },
    legend: {
      show: !legendOrient ? false : true,
      type: "scroll",
      orient: legendOrient,
      left: left,
      top: top,
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: title,
        type: "pie",
        radius: radius,
        avoidLabelOverlap: true,
        color: color,
        label: {
          show: showLabel,
          formatter: percentFromatter ? "{c}%" : "{c}",
          position: "outside",
          fontSize: 16,
        },
        labelLine: {
          show: true,
          length: 10, // Adjust length of the label line
          length2: 10, // Adjust length of the label line
        },
        data: data,
        ...seriesConfig,
      },
    ],
  };

  return option;
};

const MixedBarLineChart = (title, legendArray, xAxisData, yAxisData, yAcisData) => {
  const option = {
    title: {
      text: title,
      textStyle: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 24,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: legendArray,
      bottom: 10,
      left: 70,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: yAxisData,
    series: yAcisData,
  };
  return option;
};

const RaceLineChart = (title, xAxisData, yAxisData, ...chartOptions) => {
  const option = {
    animationDuration: 5000,
    title: {
      show: !!title,
      text: title,
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: "value",
      position: "right",
      splitNumber: 4,
      axisLabel: {
        formatter: function (value) {
          return utils.convertToInternationalNumberSystem(value, 0);
        },
      },
    },
    series: [
      {
        data: yAxisData,
        type: "line",
        lineStyle: {
          color: "#EE6002",
        },
        showSymbol: false,
      },
    ],
    ...chartOptions,
  };
  return option;
};

const BasicLineChart = (title, xAxisData, seriesData, yAxisConfig) => {
  const option = {
    title: {
      show: true,
      text: title,
      padding: [20, 20, 20, 20],
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLabel: {
        interval: 0, // Ensure all labels are shown
        rotate: 45,  // Optionally rotate labels for better visibility
      },
    },
    yAxis: {
      type: "value",
      ...yAxisConfig,
    },
    tooltip: {
      show: true,
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        data: seriesData,
        label: {
          show: true,
          position: "top",
        },
        labelLayout: {
          moveOverlap: 'shiftY'
        },
        type: "line",
      },
    ],
  };
  return option;
};

const StackedLineChart = (title, xAxisData, legend, seriesData) => {
  const options = {
    title: {
      show: true,
      text: title,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    legend: {
      data: legend,
    },
    yAxis: {
      type: "value",
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: Object.keys(seriesData)?.map((row) => {
      return {
        name: row,
        type: "line",
        data: seriesData[row],
      };
    }),
  };
  return options;
};

const StackedBarChart = (title, xAxisData, legend, seriesData) => {
  const options = {
    title: {
      show: true,
      text: title,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    legend: {},
    yAxis: {
      type: "value",
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: Object.keys(seriesData)?.map((row) => {
      return {
        name: seriesData[row]["name"],
        type: "bar",
        stack: "Ad",
        data: seriesData[row]["data"],
        emphasis: {
          focus: "series",
        },
      };
    }),
  };
  return options;
};

const HomeSplitChart = (
  title,
  colorArray,
  legendOrient = "horizontal",
  left = "center",
  top = 10,
  data,
  radius = ["30%", "60%"],
  showPercentage = true,
  showToolbox = true,
  tooltipConfig = {},
  seriesConfig = {},
  chartTitle
) => {
  const option = {
    title: {
      show: chartTitle ? true : false,
      text: chartTitle,
      padding: [20, 20, 20, 20],
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
      ...tooltipConfig,
    },
    legend: {
      show: legendOrient !== null,
      orient: legendOrient,
      left: left,
      top: top,
      itemGap: 20, 
      textStyle: {
        fontSize: 12,
        color: '#333' 
      }
    },
    toolbox: {
      show: showToolbox,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: title,
        type: "pie",
        radius: radius, 
        avoidLabelOverlap: true,
        color: colorArray,
        label: {
          show: showPercentage,
          formatter: showPercentage ? "{b}: {d}%" : "{b}: {c}",
          position: "outside",
          fontSize: 10, 
        },
        labelLine: {
          show: true,
          length: 20, 
          length2: 15, 
        },
        data: data,
        ...seriesConfig,
      },
    ],
  };

  return option;
};


export { BarChart, DoughnutChart, MixedBarLineChart, RaceLineChart, BasicLineChart, StackedLineChart, StackedBarChart,HomeSplitChart };
