import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/main.json";
import {
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
} from "./LandingConstants";
import Testimonials from "./Testimonials";
import TopBrands from "./TopBrands";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import "../../styles/internal/Landing/Landing.css";


const caa_1 = `${config.BASE_IMAGE_URL}CAA_1.svg`;
const caa_2 = `${config.BASE_IMAGE_URL}CAA_2.svg`;
const caa_3 = `${config.BASE_IMAGE_URL}CAA_3.svg`;
const caa_4 = `${config.BASE_IMAGE_URL}CAA_4.svg`;
const caa_5 = `${config.BASE_IMAGE_URL}CAA_5.svg`;


function BaseContractsAndActivate() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const allSections = document.querySelectorAll(".section");

    const revealSection = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("section--hidden");
          observer.unobserve(entry.target);
        }
      });
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null, // Use the viewport as the root
      threshold: 0.2, // Adjust as needed
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.headerBox}>
        <Box className={styles.topText}>On-board Influencers Hassle Free</Box>
        <Box className={styles.title} sx={{ fontSize: { xs: "30px !important", md: "40px !important", lg: "60px !important" } }}>
          Legal Contracting to Management, everything in between.
        </Box>
        <Box className={styles.subTitle}>
          Forget the hassles of on-boarding influencers and UGC management.
          <br />
          Manage Influencer easily and create UGC that boost ROI.
        </Box>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "190px",
              marginInline: "auto",
              fontSize: "20px",
              color: "#FFFFFF",
              backgroundColor: "#464E5F",
              "&:hover": {
                backgroundColor: "#2a2f39",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/pricing")}
          >
            GET STARTED
          </Button>
        </div>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Shortlist Right Creators and Invite them to Campaign easily
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    // paddingLeft: "10vw",
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Invite and on-board the right influencers easily. Keep shortlisting influencers, and you can invite them to
                  together to the campaign. Reach-out include automatic link. Once the influencer accepts, see status updated with
                  notifications.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={caa_1} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.leftTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={caa_2} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  Create Contract Templates and Send Them in Bulk
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  Forget the hassle of contract management. Use our integrated contracts feature to create various templates, each
                  with different terms or pricing and send them out in bulk. Track acceptance status as well.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Share Campaign Briefs, Do & Don't, and more..
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Share campaign brief and other details easily with all or subset of the Influencers. Create cohorts and try
                  different approaches.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={caa_3} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.leftTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={caa_4} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  Review and Provide Feedback on Publications
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  Streamline content review and publication. Review drafts, suggest changes and also leverage AI driven auto
                  alerts.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Organize Creators in Groups
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Grouping influencers in Groups (like lists) helps you manage them better. Align campaign strategy per group
                  based on location or demographics.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={caa_5} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Testimonials */}
      <Testimonials />
      {/* Contracts Info */}
      <div style={{ background: "#F5F5F5", padding: "30px" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "700",
            marginTop: "70px",
          }}
        >
          Our all-in-one SaaS solution allows marketers to execute and uplevel every step of the marketing process.
        </div>
        <Box
          style={{
            fontSize: "40px",
            fontWeight: "700",
            width: "90%",
            margin: "70px auto 20px",
          }}
          sx={{ fontSize: { xs: "30px !important", md: "40px !important" } }}
        >
          Avoid unnecessary hassle with automated contracts
        </Box>
        <Box
          style={{
            fontSize: "20px",
            fontWeight: "700",
            width: "90%",
            margin: "auto",
          }}
          sx={{ fontSize: { xs: "12px !important", md: "16px !important" } }}
        >
          Push your contracts through the pipeline faster and more efficiently. Set up standardized, click-to-accept offers that
          auto-fill influencer details or customizable contracts that can be quickly personalized to unlock lucrative
          opportunities.
        </Box>
      </div>
      {/* Top Brands */}
      <TopBrands />
    </div>
  );
}

export default BaseContractsAndActivate;
