const ShareIcon = () => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8555 1.19542C21.6978 0.917032 21.3962 0.75 21.0714 0.75L1.91369 0.763919C1.52859 0.763919 1.19453 1.00055 1.05997 1.35781C0.962538 1.61764 0.985737 1.89603 1.11565 2.12338C1.16669 2.20689 1.23165 2.28577 1.31052 2.35537L8.69243 8.72116L10.4927 18.2838C10.5623 18.6596 10.8499 18.938 11.2304 18.9983C11.6062 19.0586 11.9774 18.8823 12.1723 18.5529L21.8509 2.10018C22.0179 1.81715 22.0179 1.47381 21.8555 1.19542ZM3.21748 2.14194H18.8164L9.42551 7.50089L3.21748 2.14194ZM11.6248 16.7341L10.1122 8.69796L19.5171 3.33436L11.6248 16.7341Z"
      fill="black"
      stroke="black"
      strokeWidth="0.7"
    />
  </svg>
);

export default ShareIcon;
