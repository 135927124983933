import { Grid } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";

export const tableHeaders = [
  {
    id: 1,
    label: "Publication",
    mappedValue: "Publication",
    field: "post",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },

  {
    id: 2,
    label: "Influencer",
    mappedValue: "Influencer",
    field: "influencer",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: "Posted",
    mappedValue: "Posted",
    field: "createdAt",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 4,
    label: "Group Name",
    mappedValue: "Group Name",
    field: "groupName",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} className={styles.bodycell}></Grid>
          <Grid item xs={6}>
            Action
          </Grid>
        </Grid>
      </>
    ),
    mappedValue: "Status",
    field: "status",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 6,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
];
