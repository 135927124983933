import { Avatar, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { number, object } from "prop-types";
import { showIcon, statusMapping, stringAvatar } from "./CommonAssets";
import PostActionButton from "./PostActionButton";
import { renderData } from "./platform-asset-calculator";
import { GetPostText, RenderCardMedia } from "./mediacontent";
import LinkedinLike from "../../../../styles/icons/linkedinlike";
import LinkedinComment from "../../../../styles/icons/linkedincomment";

const LinkedinPost = ({ formData, cid }) => {
  return (
    <>
      <div className="d-flex flex-nowrap example-parent">
        <Avatar
          sx={{ width: 32, height: 32 }}
          {...stringAvatar(formData.influencerName)}
        />
        <span className="order-2 p-2 col-example" style={{ width: "100%" }}>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "10px" }}
            >
              <span className="header">{formData.influencerName}</span>
              <div className="d-flex justify-content-end">
                {showIcon(formData.platform)}
                {formData.preview && (
                  <PostActionButton id={formData.id} cid={cid} />
                )}
              </div>
            </div>
            {!formData.preview && (
              <p>{renderData(formData.postContent, formData.preview)}</p>
            )}
            {formData.preview && formData.postContent?.length > 0 && (
              <p>{renderData(formData.postContent, formData.preview)}</p>
            )}
            {formData.preview && formData.postContent?.length === 0 && (
              <p style={{ color: "transparent" }}>No Content</p>
            )}
            <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
              {RenderCardMedia(formData)}
            </Carousel>
            <div style={{ marginTop: "10px" }}>
              {formData.preview && (
                <Button
                  style={{
                    backgroundColor: statusMapping?.filter(
                      (value) => value.id === formData.currentStatus
                    )[0]?.color,
                    width: "100%",
                    fontSize: "12px",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                >
                  {GetPostText(formData)}
                </Button>
              )}
            </div>
          </div>
        </span>
      </div>
      <div
        className="d-flex justify-content-around"
        style={{ marginBottom: "10px" }}
      >
        <span className="p-2 col-example text-left">
          <LinkedinLike />
          &nbsp;Like
        </span>
        <span className="p-2 col-example text-left">
          <LinkedinComment />
          &nbsp;Comment
        </span>
      </div>
    </>
  );
};

LinkedinPost.propTypes = {
  formData: object.isRequired,
  cid: number.isRequired,
};

export default LinkedinPost;
