import React from 'react'
import SecondaryStyledMenu from './common/SecondaryStyledMenu';
import { Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../../redux/features/system/systemAlert';
import { fetchDataByPlatform } from '../../../services/discovery';
import { LoadingButton } from '@mui/lab';
import { checkForCredit } from '../../../services/credit';
import { Info } from '@mui/icons-material';

export const sortField = { "instagram": "engagements", "tiktok": "followers", "youtube": "followers" }

const ExportData = (props) => {
  const {
    selectedData,
    tableRowsPerPage,
    payload,
    sortCol,
    selectedPlatform,
    tablePageNo,
    customCols,
    unlockedDataCount,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportCount, setExportCount] = React.useState(tableRowsPerPage - selectedData?.data?.accounts?.filter((item) => item?.account?.user_profile?.analyzed));
  const [error, setError] = React.useState(null);
  const [includeContactDetails, setIncludeContactDetails] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [creditValue, setCreditValue] = React.useState(0);
  const [contactCreditValue, setContactCreditValue] = React.useState(0);
  const [showUnlockAlert, setShowUnlockAlert] = React.useState(false);
  const [viewedAccounts, setViewedAccounts] = React.useState([]);


  // reset export count when table rows per page changes
  React.useEffect(() => {
    const viewedAccounts = selectedData?.data?.accounts?.filter((item) => item?.account?.user_profile?.analyzed);
    const count = tableRowsPerPage - viewedAccounts?.length;
    if (count === 0) {
      setExportCount(tableRowsPerPage);
    } else {
      setExportCount(count);
    }
    setViewedAccounts(viewedAccounts);
    if (count - viewedAccounts?.length > unlockedDataCount)
      setShowUnlockAlert(true);
    // disable export button if no credits available
    checkForCreditValue();
  }, [tableRowsPerPage, unlockedDataCount, anchorEl])


  const open = Boolean(anchorEl);

  const user = useSelector(state => state.user);

  const dispatch = useDispatch();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExportCount(creditValue > tableRowsPerPage - unlockedDataCount ? tableRowsPerPage - unlockedDataCount : creditValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExportCount(creditValue > tableRowsPerPage - unlockedDataCount ? tableRowsPerPage - unlockedDataCount : creditValue);
  };

  const checkForCreditValue = async () => {
    const data = {
      name: ["Discovery.Analyze"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    const credits = parseInt(response?.response.message[data.name[0]]);
    if (credits === 0) {
      setError(`No credits Available. Please purchase more credits to export data.`);
    }
    if (credits > 0) {
      setCreditValue(credits);
      if (credits < tableRowsPerPage) {
        setExportCount(creditValue);
      }
    }
    const contactCredits = parseInt(response?.response.message[data.name[0]]);
    if (contactCredits > 0) {
      setContactCreditValue(contactCredits);
    }

  };

  const countChangeHandler = (e) => {
    const { value } = e.target;
    const totalRows = tableRowsPerPage;
    if (value - viewedAccounts?.length > unlockedDataCount) {
      setShowUnlockAlert(true);
    } else {
      setShowUnlockAlert(false);
    }
    if (value > creditValue) {
      setError(`Cannot export more than ${creditValue} influencers. Please purchase more credits.`);
      setExportCount(creditValue);
    } else {
      if (value > totalRows) {
        setError(`Please enter a number less than total rows (${totalRows})`);
        setExportCount(totalRows);
        return;
      } else if (value < 1) {
        setError(`Please enter a number greater than 0`);
        setExportCount(1);
        return;
      } else {
        setError(null);
        setExportCount(value);
      }
    }
  }

  // export data handler
  const exportDataHandler = async () => {
    try {
      setIsLoading(true);
      const { status, response } = await fetchDataByPlatform(
        payload,
        sortCol,
        sortField,
        selectedPlatform,
        tablePageNo,
        tableRowsPerPage,
        customCols,
        dispatch,
        user,
        {
          isExport: 1,
          email: user?.email,
          exportContact: includeContactDetails ? 1 : 0,
        },
        exportCount
      )
      if (status === 200 && response) {
        dispatch(notify({ message: "Exporting data. Please check your email for the file.", type: "success" }));
        setCreditValue((value) => value - exportCount);
        handleClose();
      }
    } catch (error) {
      dispatch(notify({ message: "Unable to export data , please try again later.", type: "error" }));
    }
    setIsLoading(false);
  }

  return (
    <>
      <Button
        sx={{
          color: "black",
          background: "white",
          border: "1px solid lightgrey",
          borderRadius: "12px",
          "&:hover": { bgcolor: "lightgray" },
          whiteSpace: "nowrap",
          px: "12px",
          textTransform: "none",
          justifyContent: "space-between",
        }}
        id="styled-menu"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<FileDownloadIcon />}
      >
        Export Search Results
      </Button>
      <SecondaryStyledMenu
        id="styled-menu"
        MenuListProps={{
          "aria-labelledby": "styled-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box p={2} maxWidth={"450px"}>
          <Typography fontSize={"1rem"} fontWeight={"bold"}>
            {selectedData['data']['total']} influencers found
          </Typography>
          <Box my={1}>
            <TextField
              label="Amount of influencers needed"
              id="outlined-export-discovery-data"
              type="number"
              defaultValue={tableRowsPerPage - viewedAccounts?.length}
              value={exportCount}
              onChange={countChangeHandler}
              disabled={creditValue === 0}
              size="small"
            />
            {error && <Typography color='red'>{error}</Typography>}
            <Typography fontWeight={"bold"} color="steelblue" mt={1}>
              Available Credits: {creditValue} credits
            </Typography>
          </Box>
          {creditValue > 0 &&
            <Typography variant='p' fontWeight={"bold"} color="orange" mt={1}>
              Cost: {tableRowsPerPage - viewedAccounts?.length} credit(s)
            </Typography>
          }
          {showUnlockAlert && unlockedDataCount > 0 &&
            <Box display={"flex"} alignItems={"center"} mt={1}>
              <Box>
                <InfoIcon htmlColor='lightgrey' />
              </Box>
              <Typography variant='p' fontWeight={"bold"} color="grey" ml={1} fontSize={"14px"}>
                Exporting count is more than the viewed influencers. <br /> {unlockedDataCount}  search credit(s) will be used for the influencers & then exported.
              </Typography>
            </Box>
          }
          <Box>

            <Box display={"flex"} alignItems={"center"} my={1}>
              <FormControlLabel control={<Checkbox />} checked={includeContactDetails} label="Include contacts details" onChange={() => setIncludeContactDetails(!includeContactDetails)} disabled={contactCreditValue === 0 || exportCount > contactCreditValue} />
              <Tooltip
                title={"Access to additional information on an influencer but not their audience including gender, location (country, state, and city), language, interests and brand affinities along with an influencers contact details. Please note, the contact details might not be available for some influencers as we only display public contacts that can be found on their Influencer profile."}
                sx={{ ml: "2px" }}>
                <IconButton>
                  <InfoIcon htmlColor='lightgrey' />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box my={2}>
            <LoadingButton color='primary' variant='contained' onClick={exportDataHandler} loading={isLoading} disabled={creditValue > 0 ? creditValue < exportCount : true}>
              Export
            </LoadingButton>
          </Box>
        </Box>
      </SecondaryStyledMenu>
    </>
  )
}

export default ExportData
