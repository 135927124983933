import React, { useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { BarChart } from "../../../../../../components/common/chart";
import { getDataByAgeRange, getFilter } from "../../../../../../services/advanceAnalytics";
import { notify } from "../../../../../../redux/features/system/systemAlert";
import { Spinner } from "react-bootstrap";
import MiniTableCard from "../common/MiniTableCard";
import { logout } from "../../../../../../redux/features/user/userSlice";
import { convertToInternationalNumberSystem } from "../../../../../../utility/stringOperations";
import NoDataFound from "../common/NoDataFound";
import CheckboxDropdownFilter from "../common/CheckboxDropdownFilter";

const ReachByAgeRange = (props) => {
  const { user, dispatch, isEngagementInfo, campaignId, getLastRefreshedTime, getNextRefreshTime } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setAgeRangeData] = useState(null);
  const [weekFilter, setWeekFilter] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    weeks: [],
  });

  // fetch data for a specific campaign
  const getData = async (filters) => {
    setIsLoading(true);
    try {
      const res = await getDataByAgeRange(campaignId, filters, user, dispatch);
      if (res.ok && res.body) {
        const data = await res.json();
        if (data?.AgeRangeSplit) {
          setAgeRangeData(data["AgeRangeSplit"][0]);
          getLastRefreshedTime(data.LastRefreshedTime);
          getNextRefreshTime(data.NextRefreshTime);
        } else setAgeRangeData(null);
      }
    } catch (error) {
      dispatch(
        notify({
          message: error.response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    setIsLoading(false);
  };

  const createMiniTableBody = () => {
    if (!locationData) return null;
    let data = [];
    let newItem = {};
    Object.keys(locationData).forEach((key, idx) => {
      if (key !== "Total") {
        if (isEngagementInfo) {
          newItem = {
            "Age Range": key,
            Influencers: locationData?.[key]?.["NoOfInfluencers"] || 0,
            Posts: locationData?.[key]?.["NoOfPosts"] || 0,
            Engagement: convertToInternationalNumberSystem(locationData?.[key]?.["Engagement"]) || 0,
            "Avg Engagement per post": convertToInternationalNumberSystem(locationData?.[key]?.["AverageEngagementPerPost"]) || 0,
          };
        } else {
          newItem = {
            "Age Range": key,
            Influencers: locationData?.[key]?.["NoOfInfluencers"] || 0,
            Posts: locationData?.[key]?.["NoOfPosts"] || 0,
            Reach: convertToInternationalNumberSystem(locationData?.[key]?.["FollowerCount"]) || 0,
            "Avg Reach / post": convertToInternationalNumberSystem(locationData?.[key]?.["AverageReachPerPost"]) || 0,
          };
        }
        data.push(newItem);
      }
    });
    return data;
  };

  const getFilterData = async () => {
    // week Filter
    const weekData = await getFilter("getWeeks", user, campaignId);
    if (weekData) {
      const data = await weekData.json();
      setWeekFilter(data);
    }
  };

  const resetFilters = () => {
    const filters = {
      weeks: [],
    };
    getData(filters);
    setSelectedFilters(filters);
  };

  useEffect(() => {
    getFilterData();
    resetFilters();
  }, [campaignId, isEngagementInfo]);

  const filtersChangeHandler = (value, key) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
      };
      if (value === "All") {
        selectedFilters[key]?.length === weekFilter?.length ? (newFilters[key] = []) : (newFilters[key] = weekFilter);
      } else {
        if (key === "weeks") {
          newFilters[key] = [];
          newFilters[key].push(value["weekNo"]);
        } else {
          if (prevFilters[key].includes(value)) {
            newFilters[key] = newFilters[key]?.filter((item) => item !== value);
          } else {
            newFilters[key].push(value);
          }
        }
      }
      getData(newFilters);
      return newFilters;
    });
  };

  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Spinner style={{ marginBlock: "10rem" }} />
        </Box>
      );
    } else if (!isLoading && locationData) {
      const data = Object.keys(locationData)?.filter((key) => key !== "Total");
      if (!data) {
        return <>No Data Available.</>;
      }
      content = (
        <>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Paper elevation={3}>
                <ReactEcharts
                  option={BarChart(
                    data?.map((key, idx) => key.split(",")[0]),
                    true,
                    [
                      {
                        data: data?.map((key, idx) => locationData[key][isEngagementInfo ? "Engagement" : "FollowerCount"]),
                        type: "bar",
                        colorBy: "data",
                        label: {
                          show: true,
                          precision: 1,
                          position: "top",
                          valueAnimation: true,
                          fontFamily: "monospace",
                          formatter: (params) => convertToInternationalNumberSystem(params.value),
                        },
                      },
                    ],
                    null,
                    null,
                    true,
                    true,
                    60,
                    true,
                    {
                      axisLabel: {
                        formatter: (val) => convertToInternationalNumberSystem(val),
                      },
                    },
                    {
                      show: true,
                      text: isEngagementInfo ? "Total Engagement" : "Total Reach",
                      padding: [20, 20, 20, 20],
                    },
                    false
                  )}
                  style={{ height: "600px", width: "100%" }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3}>
                <ReactEcharts
                  option={BarChart(
                    data?.map((key, idx) => key.split(",")[0]),
                    true,
                    [
                      {
                        data: data?.map(
                          (key, idx) => locationData[key][isEngagementInfo ? "AverageEngagementPerPost" : "AverageReachPerPost"]
                        ),
                        type: "bar",
                        colorBy: "data",
                        label: {
                          show: true,
                          precision: 1,
                          position: "top",
                          valueAnimation: true,
                          fontFamily: "monospace",
                          formatter: (params) => convertToInternationalNumberSystem(params.value),
                        },
                      },
                    ],
                    null,
                    null,
                    true,
                    true,
                    60,
                    true,
                    {
                      axisLabel: {
                        formatter: (val) => convertToInternationalNumberSystem(val),
                      },
                    },
                    {
                      show: true,
                      text: isEngagementInfo ? "Avg Engagement per post" : "Avg Reach per post",
                      padding: [20, 20, 20, 20],
                      axisLabel: { interval: 0 },
                      axisTick: {
                        alignWithLabel: true,
                      },
                    },
                    null,
                    {
                      alignWithLabel: true,
                    },
                    false
                  )}
                  style={{ height: "600px", width: "100%" }}
                />
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    } else {
      content = <NoDataFound />;
    }
    return content;
  };
  return (
    <>
      <Box my="1rem" display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <CheckboxDropdownFilter
            options={weekFilter}
            setOption={filtersChangeHandler}
            width="350"
            name="Campaign Weeks"
            selectedOption={selectedFilters.weeks}
            filterKey="weeks"
            checkBox={false}
            weekBox={true}
          />
        </Box>
        <Box mb="1rem" display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <MiniTableCard tableBodyData={createMiniTableBody()} fluidWidth />
        </Box>
      </Box>
      {renderContentHandler()}
    </>
  );
};

export default React.memo(ReachByAgeRange);
