import { ThemeProvider } from "@emotion/react";
import React, { useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router";
import TopBrands from "../../components/landing/TopBrands";
import theme from "../../styles/campaignBriefTheme";
import MainFooter from "../App/MainFooter";
import ResponsiveAppBar from "../App/ResponsiveAppBar";

function CampaignBriefLayout() {
  useEffect(() => {
    setBackground();
    window.scrollTo(0, 0);

    return () => {
      document.body.style.background = "rgba(224, 224, 224, 0.55)";
      document.body.style.backgroundAttachment = "fixed";
    };
  }, []);

  const setBackground = () => {
    document.body.style.height = `${document.documentElement.scrollHeight}px`;
    document.body.style.background =
      "linear-gradient(61deg, rgba(237, 234, 228, 1) 0%, rgba(56, 125, 154, 1) 61%, rgba(27, 39, 74, 1) 100%)";
    document.body.style.border = "1px solid #000000";
    document.body.style.boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", setBackground);
    const interval = setInterval(() => {
      setBackground();
    }, 1000);
    return () => {
      window.removeEventListener("resize", setBackground);
      clearInterval(interval);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar demo={true} />
      <Outlet />
      <TopBrands />
      <MainFooter />
    </ThemeProvider>
  );
}

export default CampaignBriefLayout;
