import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { logout } from "../../redux/features/user/userSlice";
import { changeLoginPassword } from "../../services/settings";
import { passwordRegex } from "../../utility/regex";

function ChangePassword({ open, setOpen }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);
  const [doNotMatch, setDoNotMatch] = useState(false);
  const [logoutType, setLogoutType] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleClose = () => {
    setLogoutType(false);
    setOpen(false);
  };

  const handleOldPassword = (event) => {
    setOldPassword(event.target.value);
  };
  const handleNewPassword = (event) => {
    setDoNotMatch(false);
    setNewPassword(event.target.value);
    if (!passwordRegex.test(event.target.value) && event.target.value?.length > 8) {
      setPasswordValidation(true);
      setErrorMessage(
        "Invalid password, should contain one small letter, one capital letter, one numeric and one special character and of minimum 8 in length"
      );
    } else {
      setPasswordValidation(false);
      setErrorMessage("");
    }
  };
  const handleNewPassword2 = (event) => {
    setDoNotMatch(false);
    setNewPassword2(event.target.value);
  };

  const handleChange = async () => {
    const data = {};
    if (newPassword === newPassword2) {
      data.password = newPassword;
    } else {
      setDoNotMatch(true);
      return;
    }
    data.previousPassword = oldPassword;
    data.email = user.email;
    data.accessToken = user.accessToken;

    const response = await changeLoginPassword(data, user, dispatch);
    if (response.status === true) {
      setLogoutType(true);
      dispatch(
        notify({
          message: "Password Changed!",
          type: "success",
        })
      );
    } else {
      setLogoutType(false);
      setOpen(false);
      dispatch(
        notify({
          message: "Could not change your password",
          type: "error",
        })
      );
    }
  };

  const handleLogout = () => {
    setLogoutType(false);
    dispatch(logout());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          height: 450,
        },
      }}
    >
      {!logoutType && <DialogTitle>Change Password</DialogTitle>}
      {logoutType && <DialogTitle>Logout</DialogTitle>}
      {!logoutType && (
        <DialogContent>
          <TextField
            label="Current Password"
            type={showOldPassword ? "text" : "password"}
            value={oldPassword}
            onChange={handleOldPassword}
            variant="filled"
            fullWidth
            sx={{ mt: 2 }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            error={doNotMatch}
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            value={newPassword}
            onChange={handleNewPassword}
            variant="filled"
            fullWidth
            sx={{ mt: 2 }}
            style={{ marginTop: "50px" }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            error={doNotMatch}
            helperText={doNotMatch ? "Password do not match" : ""}
            label="Confirm New Password"
            type={showNewPassword2 ? "text" : "password"}
            value={newPassword2}
            onChange={handleNewPassword2}
            variant="filled"
            fullWidth
            sx={{ mt: 2 }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowNewPassword2(!showNewPassword2)} edge="end">
                  {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
        </DialogContent>
      )}
      {passwordValidation && <div style={{ textAlign: "center", color: "red", padding: "10px" }}>{errorMessage}</div>}

      {logoutType && <DialogContent>Do you want to logout from all the devices?</DialogContent>}
      {!logoutType && (
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleChange} disabled={passwordValidation || newPassword?.length < 8}>
            Change
          </Button>
        </DialogActions>
      )}
      {logoutType && (
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleLogout}>Yes</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ChangePassword;
