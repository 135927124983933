import { Email, PersonOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";

function Step4({ submitForm, step4Values, setStep4Values }) {
  const [name, setName] = useState(step4Values.name);
  const [email, setEmail] = useState(step4Values.email);
  const [position, setPosition] = useState(step4Values.position);
  const [phone, setPhone] = useState(step4Values.phone);
  const [valid, setValid] = useState(false);

  const validEmail = (email) => {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return regex.test(email);
  };

  useEffect(() => {
    let valid = false;
    if (name !== "" && email !== "" && position !== "" && validEmail(email)) {
      valid = true;
    }
    setValid(valid);
  }, [name, email, position, setValid]);

  useEffect(() => {
    const stepValues = {};
    stepValues.name = name;
    stepValues.email = email;
    stepValues.position = position;
    stepValues.phone = phone;
    setStep4Values(stepValues);
  }, [name, email, position, phone, setStep4Values]);

  return (
    <>
      <div className={styles.step_title}>About You</div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={styles.step_field}>Name*</div>
          <TextField
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "46px",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            value={name}
            onChange={(val) => setName(val.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Email*</div>
          <TextField
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "46px",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            value={email}
            onChange={(val) => setEmail(val.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>
            Your position in the company?*
          </div>
          <Select
            size="small"
            sx={{
              width: "100%",
              border: "1px solid #EFF0F7",
              boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
              borderRadius: "46px",
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            value={position}
            onChange={(val) => setPosition(val.target.value)}
          >
            <MenuItem value={"CEO/CMO or another C-Level"}>
              CEO/CMO or another C-Level
            </MenuItem>
            <MenuItem value={"VP or Director"}>VP or Director</MenuItem>
            <MenuItem value={"Marketing/Communication Manager"}>
              Marketing/Communication Manager
            </MenuItem>
            <MenuItem value={"Any Other Role"}>Any Other Role</MenuItem>
            <MenuItem value={"Freelance / self-employed"}>
              Freelance / self-employed
            </MenuItem>
            <MenuItem value={"Starting up a company"}>
              Starting up a company
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Phone</div>
          <PhoneInput
            inputStyle={{
              borderRadius: "46px",
              height: "40px",
              width: "100%",
              // "&.react-tel-input .form-control:focus": {
              //   borderColor: "#4a3aff !important",
              //   boxShadow: "0 0 0 1px #4a3aff",
              // },
            }}
            specialLabel=""
            placeholder=""
            value={phone}
            onChange={(val) => setPhone(val)}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className={styles.step_information}>
              Please review all the information you added in the past steps,
              verify your details and submit the form to receive a response in
              24 - 48 hours.
            </div>
            <Button
              variant="contained"
              className={styles.submit_button}
              disabled={!valid}
              onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

Step4.propTypes = {
  submitForm: PropTypes.func.isRequired,
  step4Values: PropTypes.object.isRequired,
  setStep4Values: PropTypes.func.isRequired,
};

export default Step4;
