import {
  HighlightOffOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { Grid, Menu } from "@mui/material";
import React from "react";
import { array, bool, func, number, object } from "prop-types";

function RoleMenu({ roles, anchorEl, open, handleClose, handleMenuMember}) {
  return (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: "170px",
        },
      }}
    >
      <Grid container item xs={12}>
        <Grid item sm={1} />
        <Grid item sm={9}>
          <div
            style={{
              fontSize: "0.9rem",
              color: "#757575",
            }}
          >
            Select Role
          </div>
        </Grid>
        <Grid item sm={2} onClick={handleClose}>
          <HighlightOffOutlined
            style={{
              color: "#757575",
              transform: "scale(0.7)",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>
      {roles?.map((role, index) => (
        <Grid
          key={role.title}
          container
          onClick={() => handleMenuMember(index)}
          style={{ cursor: "pointer" }}
        >
          <Grid container item spacing={1} xs={12}>
            <Grid item sm={2}>
              <PersonOutlineOutlined
                color="primary"
                style={{ transform: "scale(0.9)" }}
              />
            </Grid>
            <Grid item sm={10}>
              <span style={{ fontSize: "1rem" }}>{role.title}</span>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sm={2} />
            <Grid item sm={10}>
              <div
                style={{
                  fontSize: "10px",
                  color: "#757575",
                  lineHeight: "12px",
                }}
              >
                {role.description}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Menu>
  );
}

RoleMenu.propTypes = {
  roles: array.isRequired,
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleMenuMember: func.isRequired,
};

export default RoleMenu;
