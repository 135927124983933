import config from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const getRolesData = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/getRoles`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getUsersData = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/getUsers`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const createNewUsers = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/users`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const updateUserRole = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/updateRole`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const changeUserStatus = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/changeUserStatus`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const resendUserInvite = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/resendInvite`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getUserActionLogs = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.ORYX_HOSTNAME,
      method: "POST",
      url: `${config.API_HOST}/logs/all`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getUserEmails = async (user, dispatch) => {
  try {
    const request = {
      hostname: config.ORYX_HOSTNAME,
      method: "GET",
      url: `${config.API_HOST}/logs/users`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getUserActions = async (user, dispatch) => {
  try {
    const request = {
      hostname: config.ORYX_HOSTNAME,
      method: "GET",
      url: `${config.API_HOST}/logs/actions`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export {
  getRolesData,
  createNewUsers,
  getUsersData,
  updateUserRole,
  changeUserStatus,
  resendUserInvite,
  getUserActionLogs,
  getUserEmails,
  getUserActions,
};
