import { Alert, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeFreeTrialPopupState } from "../../redux/features/user/userSlice";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const FreeTrialPopup = ({ isFreeTrial }) => {
  const [warning, setWarning] = useState(isFreeTrial);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user)

  const handleClose = () => {
    setWarning(false);
    dispatch(storeFreeTrialPopupState());
  };

  const navigateMe = () => {
    navigate("/app/account/billing");
    dispatch(storeFreeTrialPopupState());
  };

  const getTrialMessage = () => {
    if (user.daysLeft <= 0) {
      return 'Your free trial has expired.';
    } else if (user.daysLeft === 1) {
      return 'Your free trial expires tomorrow.';
    } else {
      return `Your free trial expires in ${user.daysLeft} days.`;
    }
  };
  return (
    warning && (
      <div>
        <Snackbar open={warning} anchorOrigin={{ vertical: "top", horizontal: "center" }}  >
          <Alert
            onClose={handleClose}
            severity="info"
            sx={{ backgroundColor: '#FFC107', color: 'black' }}
            iconMapping={{
              info: <InfoOutlinedIcon style={{ color: 'black' }} />,
            }}
          >
            <Typography>
              {getTrialMessage()} {" "}
              Please{' '}
              <a href="#" style={{ color: '#1576d5', textDecoration: 'underline' }} onClick={navigateMe}>
                upgrade
              </a>{' '}
              your subscription to continue using Empowr.
            </Typography>
          </Alert>
        </Snackbar>
      </div>
    )
  );
};

export default FreeTrialPopup;
