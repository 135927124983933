import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CustomizedTable from "../../../common/customizedTable";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations";
import { TabPanel } from "@mui/lab";
import Loader from "../../../common/loader";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { PlatformIdMapping, snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";

const CampaignPublicationTab = ({
  allSelectedPlatforms,
  selectedPlatform,
  headers,
  bodyColumns,
  totalRows,
  page,
  rows,
  sortOrder,
  sortCol,
  mimeType,
  fileName,
  sendInformation,
  handleTabChange,
  loading,
  dataFound,
  noOfPosts
}) => {
  const [value, setValue] = useState(PlatformIdMapping[allSelectedPlatforms[0]]);
  const PlatformId = {
    instagram: 1,
    twitter: 2,
    tiktok: 3,
    youtube: 4,
    linkedin: 5,
    facebook: 6,
  };

  useEffect(() => {
    setValue(selectedPlatform?.length > 0 ? selectedPlatform[0] : PlatformIdMapping[allSelectedPlatforms[0]]);
  }, [allSelectedPlatforms]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChange(newValue);
  };

  const getPostCount = (platform) => {
    const platformId = PlatformId[platform];
    const post = noOfPosts?.find((p) => p.platformId === platformId);
    return post ? post.count : 0; // Return the count if found, otherwise 0
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ bgcolor: "background.paper" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {allSelectedPlatforms?.map((platform) => {
              return (
                <Tab
                  icon={snapshotHeaderIcons[platform]}
                  sx={{
                    ...textColor[platform],
                    "&.Mui-selected": {
                      color: textColor[platform], // Change to desired color for selected state
                    },
                  }}
                  label={`${capitalizeFirstLetter(platform)} (${getPostCount(platform)})`}
                  value={PlatformIdMapping[platform]}
                />
              );
            })}
          </TabList>
        </Box>
        <TabPanel value={value}>
          {loading ? (
            <Loader />
          ) : dataFound ? (
            <>
              <CustomizedTable
                tableHeaders={headers}
                bodyColumns={bodyColumns}
                totalRows={totalRows}
                pageNo={page}
                rowsPerPageNo={rows}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
                mimeType={mimeType}
                fileName={fileName}
                sendInformation={sendInformation}
              />
            </>
          ) : (
            <div className={styles.container}>
              <div className={styles.child}> No Publications added for this platform</div>
            </div>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

CampaignPublicationTab.propTypes = {
  allSelectedPlatforms: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  bodyColumns: PropTypes.array.isRequired,
  totalRows: PropTypes.number.isRequired,
  pag: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortCol: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  sendInformation: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dataFound: PropTypes.bool.isRequired,
};

export default CampaignPublicationTab;
