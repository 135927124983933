import { Avatar, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { object } from "prop-types";
import {
  BookmarkBorderOutlined,
  FavoriteBorderOutlined,
} from "@mui/icons-material";
import { showIcon, statusMapping, stringAvatar } from "./CommonAssets";
import { GetPostText, RenderCardMedia } from "./mediacontent";
import CommentIcon from "../../../../styles/icons/comment"
import ShareIcon from "../../../../styles/icons/share"
import { renderData } from "./platform-asset-calculator";

const InstagramPost = ({ formData }) => {
  return (
    <>
      <div className="d-flex flex-nowrap example-parent">
        <Avatar
          sx={{ width: 32, height: 32 }}
          {...stringAvatar(formData.influencerName)}
        />
        <span className="order-2 p-2 col-example" style={{ width: "100%" }}>
          <div
            className="d-flex justify-content-between"
            style={{ marginBottom: "10px" }}
          >
            <span className="header">{formData.influencerName}</span>
            {showIcon(formData.platform)}
          </div>
        </span>
      </div>
      <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
        {RenderCardMedia(formData)}
      </Carousel>
      {formData.preview && (
        <Button
          style={{
            backgroundColor: statusMapping?.filter(
              (value) => value.id === formData.currentStatus
            )[0]?.color,
            width: "100%",
            fontSize: "12px",
            borderRadius: "10px",
          }}
          variant="contained"
        >
          {GetPostText(formData)}
        </Button>
      )}
      <br />
      <div>
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "10px" }}
        >
          <span className="col-example text-left">
            <FavoriteBorderOutlined style={{ color: "black" }} />
            &nbsp;
            <CommentIcon />
            &nbsp;
            <ShareIcon />
          </span>
          <span className="col-example text-left">
            <BookmarkBorderOutlined style={{ color: "black" }} />
          </span>
        </div>
        <div className="d-flex" style={{ marginBottom: "10px" }}>
          <b>{formData.influencerName.split(" ")[0]}</b>
          <span>&nbsp;&nbsp;</span>
          {!formData.preview && (
            <p>{renderData(formData.postContent, formData.preview)}</p>
          )}
          {formData.preview && (
            <p>{renderData(formData.postContent, formData.preview)}</p>
          )}
        </div>
      </div>
    </>
  );
};

InstagramPost.propTypes = {
  formData: object.isRequired,
};

export default InstagramPost;
