import PropTypes from "prop-types";
import utils from "../../../utility/oryxUtils//utils";

import { FormControl, Grid } from "@mui/material";

const Bio = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const handleInfBioelection = (latestBio) => {
    if (latestBio !== undefined) {
      let newPayload = payload;
      newPayload["inf_bio"] = latestBio;
      allFiltersData = utils.addObj(allFiltersData, "inf_bio");
      allFiltersData[0]["inf_bio"][0] = latestBio;
      setAllFiltersData(allFiltersData);
      setPayload(newPayload);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInfBioelection(e.target.value);
    }
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <input
              type="text"
              onKeyDown={handleKeyDown}
              placeholder="Search in Bio"
              style={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                padding: "7px 12px",
                justifyContent: "flex-start",
                borderRadius: "6px",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Bio.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Bio;
