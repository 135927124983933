import config from "../config/main.json";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const forgotUserPassword = async (email) => {
  const body = JSON.stringify({ email });
  const response = await fetch(`${config.HEIMDALL_HOST}/forgotPassword`, {
    method: "POST",
    headers,
    body,
  });
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};

export { forgotUserPassword };
