import moment from "moment";

const getDaysLeft = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, "days");
};

const getTodaysDate = () => {
  return moment().format("MMMM Do YYYY");
};

const getFormattedDate = (date, format) => {
  return moment(date).format(format);
};

const getFutureDate = (date, amount, unit) => {
  return moment(date).add(amount, unit).format();
};

const getFullDate = (date) => {
  return moment(date).format();
};

const convertToUserTimezone = (utcTimestamp) => {
  const utcDate = new Date(utcTimestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  const userTimezoneDate = utcDate.toLocaleString('en-US', options);
  return userTimezoneDate;
};

export { getDaysLeft, getTodaysDate, getFormattedDate, getFutureDate, getFullDate, convertToUserTimezone };
