import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../redux/features/system/systemAlert';
import { submitContactUs } from '../../services/help';
import { ArrowForward } from '@mui/icons-material';
import configs from '../../config/main.json';
import styles from '../../styles/internal/Landing/BaseStyling.module.css';

const HelpModal = ({ open, handleClose }) => {
  const user = useSelector((state) => state.user);
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.email) {
      setEmail(user.email);
    }
  }, [user.email]);

  useEffect(() => {
    if (email.trim() && issue.trim()) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [email, issue]);

  const validateEmail = () => {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return regex.test(email);
  };

  const success = (event) => {
    event.preventDefault();
    dispatch(
      notify({
        message: "Please wait while we submit your query",
        type: "info",
      })
    );

    if (!validateEmail()) {
      dispatch(
        notify({
          message: "Invalid Email!",
          type: "error",
        })
      );
      return;
    }

    const notificationMetadata = {
      userId: subject.trim(),
      sourcePlatform: configs.PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "9",
      generatedBy: email.trim(),
      priority: "low",
      consumedBy: "oryx",
      navigateTo: "/bdTools/empowrQueries"
    };

    const body = {
      name: subject.trim(),
      email: email.trim(),
      issue: issue.trim(),
      notificationMetadata
    };

    submitContactUs(body).then((response) => {
      if (response.status) {
        setSubject("");
        setEmail(user.email); // Reset to the user's email
        setIssue("");
        dispatch(
          notify({
            message: "Issue submitted successfully!",
            type: "success",
          })
        );
        handleClose();
      }
    });
  };

  const handleModalClose = () => {
    setSubject("");
    setIssue("");
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#000' }}>
          Talk to us!
        </Typography>
        <Typography variant="body2" style={{ margin: '10px 0', color: '#000', fontWeight: 'bold' }}>
          Do you have any ideas, concerns, or questions?
        </Typography>
        <form onSubmit={success}>
          <div style={{ marginTop: "8px", fontWeight: 'bold', color: '#000' }}>From</div>
          <TextField
            color="info"
            style={{
              width: "100%",
              backgroundColor: '#F6F9FF',
              border: '1px solid #EEF4FF',
              borderRadius: '4px',
              marginTop: '8px',
              height: '40px'
            }}
            required
            value={email}
            disabled
            InputProps={{
              disableUnderline: true,
              style: {
                height: '40px' 
              },
            }}
          />
          <div style={{ marginTop: "8px", fontWeight: 'bold', color: '#000' }}>Subject</div>
          <TextField
            color="info"
            style={{
              width: "100%",
              backgroundColor: '#F6F9FF',
              border: '1px solid #EEF4FF',
              borderRadius: '4px',
              marginTop: '8px',
              height: '40px'
            }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            InputProps={{
              disableUnderline: true,
              style: {
                height: '40px' 
              },
            }}
          />
          <div style={{ marginTop: "8px", fontWeight: 'bold', color: '#000' }}>Share Your Message</div>
          <TextField
            color="info"
            style={{
              width: "100%",
              backgroundColor: '#F6F9FF',
              border: '1px solid #EEF4FF',
              borderRadius: '4px',
              marginTop: '8px'
            }}
            multiline
            rows={4}
            required
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            InputProps={{
              disableUnderline: true,
              style: {
                padding: '10px',
              },
            }}
          />
          <Button
            variant="contained"
            style={{
              backgroundColor: disableSubmit ? "#D3D3D3" : "#0D6EFD",
              width: "50%",
              marginTop: "15px",
              color: '#fff',
              textTransform: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            type="submit"
            disabled={disableSubmit}
            endIcon={<ArrowForward style={{ color: '#fff' }} />}
          >
            Send Message
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

export default HelpModal;
