import { MoreVertOutlined, Visibility } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import PropTypes from "prop-types";

const CampaignLinksActionButton = ({ selectedRow }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <Visibility htmlColor="#B5B5C3" />,
      label: "Preview",
      name: "preview",
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "preview": {
        window.open(selectedRow.destinationUrl);
        break;
      }
    }
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreVertOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map((value) => (
            <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
              {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

CampaignLinksActionButton.propTypes = {
  selectedRow: PropTypes.object.isRequired,
};

export default CampaignLinksActionButton;
