/* eslint-disable jsx-a11y/click-events-have-key-events */
import { EditorState, convertToRaw, ContentState } from "draft-js";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "../../../../styles/internal/PostTextEditor.module.css";

const PostTextEditor = ({
  placeholder,
  maxLength,
  handlePostInput,
  postContent,
  isReadOnly,
  charCount,
  setCharCount,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [textValueLength, setTextValueLength] = useState(charCount);
  const editoRef = useRef(null);

  useEffect(() => {
    async function init() {
      if (postContent) {
        const contentBlock = htmlToDraft(postContent);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks)
          )
        );
      }
    }
    init();
  }, []);

  useEffect(() => {
    setCharCount(textValueLength);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    handlePostInput(markup);
  }, [textValueLength]);

  const handlePastedText = (val, htmlString, state, onChangeHandler) => {
    const existingTextLength = editorState
      .getCurrentContent()
      .getPlainText()?.length;
    const remainingLength = maxLength - existingTextLength;
    const pastedText = val.toString().slice(0, remainingLength);
    const totalLength = pastedText?.length + existingTextLength;
    if (totalLength < maxLength) {
      onChangeHandler(editorState);
      setTextValueLength(totalLength);
    }
    return totalLength >= maxLength + 1;
  };

  const handleInputChange = (event) => {
    const rawContentState = convertToRaw(event.getCurrentContent());
    let totalCharacters = 0;
    totalCharacters = rawContentState.blocks.reduce(
      (acc, curr) => acc + curr.text,
      1
    );
    const textLength =
      totalCharacters?.length - 1 + rawContentState.blocks?.length - 1;
    setEditorState(event);
    setTextValueLength(textLength);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleInputChange}
        handlePastedText={handlePastedText}
        placeholder={placeholder}
        readOnly={isReadOnly}
        spellCheck
        wrapperClassName={styles.textEditor}
        editorClassName={styles.editor}
        ref={editoRef}
        stripPastedStyles
        toolbar={{
          options: ["inline", "emoji"],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"],
          },
        }}
      />
      <div style={{ textAlign: "right", color: "#a5a6a8", fontSize: "14px" }}>
        Character Remaining: &nbsp;
        {maxLength - textValueLength}
        &nbsp; out of &nbsp;
        {maxLength}
      </div>
    </>
  );
};

PostTextEditor.propTypes = {
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  handlePostInput: PropTypes.func.isRequired,
  postContent: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  charCount: PropTypes.number,
  setCharCount: PropTypes.func,
};

export default PostTextEditor;
