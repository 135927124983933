import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Loader from "../../../common/loader";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignUrlsInfo } from "../../../../services/campaignAnalytics";
import { useLocation } from "react-router-dom";
import CustomizedTable from "../../../common/customizedTable";
import {
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import CampaignLinksManageActionButton from "./CampaignLinksManageActionButton";
import CampaignLinksAddDestinationDialog from "./CampaignLinksAddDestinationDialog";
import CampaignLinksCreateShortUrl from "./CampaignLinksCreateShortUrl";

const CampaignLinksManageSnapshot = () => {
  const [refresh, setRefresh] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [urls, setUrls] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortCol, setSortCol] = useState("urlLink");
  const [openAddDestination, setOpenAddDestination] = useState(false);
  const [openShortUrlCreate, setOpenShortUrlCreate] = useState(false);
  const [setselectedUrl, setSetselectedUrl] = useState({});
  const tableHeaders = [
    {
      id: 1,
      label: "URL Information",
      mappedValue: "URL Information",
      field: "urlText",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 2,
      label: "URL",
      mappedValue: "URL",
      field: "urlLink",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "Group",
      mappedValue: "Group",
      field: "group",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "For All",
      mappedValue: "For All",
      field: "forAllValue",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "",
      mappedValue: "",
      field: "action",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: false,
    },
  ];

  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const search = useLocation().search;
  const params = new URLSearchParams(search);

  useEffect(() => {
    initialLoad();
  }, [+params.get("id"), refresh]);

  const initialLoad = async () => {
    const urls = (await getCampaignUrlsData()) || [];
    setUrls(urls);
    setTotalRows(urls?.length);
    createTableData(urls, page, rows, sortOrder, sortCol);
  };

  const getCampaignUrlsData = async () => {
    const urls = await getCampaignUrlsInfo(+params.get("id"), user, dispatch);

    if (urls.status === true) {
      return urls.response;
    }
  };

  const createTableData = (urls, page, rows, sortOrder, sortCol) => {
    let tableData = [...urls];

    tableData.sort((a, b) => {
      let comp;
      switch (sortCol) {
        case "urlText": {
          comp =
            a.urlText.localeCompare(b.urlText) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "urlLink": {
          comp = a.url.localeCompare(b.url) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "group": {
          comp =
            a.listName.localeCompare(b.listName) *
            (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "forAllValue": {
          const aVal = a.forAll ? "Yes" : "No";
          const bVal = a.forAll ? "Yes" : "No";
          comp = aVal.localeCompare(bVal) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        default:
          break;
      }
      return comp;
    });

    tableData = tableData.slice(page * rows, (page + 1) * rows)?.map((url) => {
      return {
        ...url,
        urlLink: (
          <a href={url.url} rel="noreferrer" target="_blank">
            {url.url}
          </a>
        ),
        group: (

          <Grid
            container
            width="100%"
            justifyContent="center"
            style={{ display: 'flex', gap: '8px' , minWidth : "300px"}}
          >
            <Grid
              item
              xs="auto"
              className={styles.bodycell}
            >
              <Typography sx={textColor[url.platform]}>
                {snapshotHeaderIcons[url.platform]}
              </Typography>
            </Grid>
            <Grid
              item
              xs="auto"
              className={styles.bodycell}
              style={{ marginTop: 5}}
            >
              <p>{url.listName}</p>
            </Grid>
          </Grid>
        ),
        forAllValue: (
          <Typography ml="-20px">{url.forAll ? "Yes" : "No"}</Typography>
        ),
        action: (
          <CampaignLinksManageActionButton
            url={url}
            setRefresh={setRefresh}
            setOpenShortUrlCreate={setOpenShortUrlCreate}
            setSetselectedUrl={setSetselectedUrl}
          />
        ),
      };
    });
    setTableData(tableData);
    setTableLoading(false);
  };

  const sendInformation = ({ page, rowsPerPage, order, orderBy }) => {
    setTableLoading(true);
    setPage(page);
    setRows(rowsPerPage);
    setSortOrder(order);
    setSortCol(orderBy);
    createTableData(urls, page, rowsPerPage, order, orderBy);
  };

  return (
    <>
      {!openShortUrlCreate && (
        <Grid container direction="row-reverse" width="100%" mb="16px">
          <Grid item>
            <Button
              variant="contained"
              onClick={() => setOpenAddDestination(true)}
            >
              Add New URL
            </Button>
          </Grid>
          <Grid item width="100%">
            {!tableLoading ? (
              <>
                {tableData?.length > 0 ? (
                  <CustomizedTable
                    tableHeaders={tableHeaders}
                    bodyColumns={tableData}
                    totalRows={totalRows}
                    pageNo={page}
                    rowsPerPageNo={rows}
                    sortOrder={sortOrder}
                    sortOrderBy={sortCol}
                    sendInformation={sendInformation}
                    staticTable={true}
                  />
                ) : (
                  <>
                    <div className={styles.container}>
                      <div className={styles.child}>
                        {" "}
                        No URLs added on this campaign
                      </div>
                    </div>
                  </>
                )}
                {openAddDestination && (
                  <CampaignLinksAddDestinationDialog
                    cid={params.get("id")}
                    setRefresh={setRefresh}
                    open={openAddDestination}
                    setOpen={setOpenAddDestination}
                  />
                )}
              </>
            ) : (
              <Loader />
            )}
          </Grid>
        </Grid>
      )}
      {openShortUrlCreate && (
        <CampaignLinksCreateShortUrl
          urlId={setselectedUrl?.id}
          lid={setselectedUrl?.lid}
          open={openShortUrlCreate}
          setOpen={setOpenShortUrlCreate}
          group={setselectedUrl?.listName}
        />
      )}
    </>
  );
};

export default CampaignLinksManageSnapshot;
