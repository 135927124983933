import configs from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const { API_HOST } = configs;

/* 
  Get Basic Details
*/
const getBasicDetails = async (data, user, dispatch) => {
  try {
    const request = {
      method: "POST",
      url: `${API_HOST}/account/dashboard`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.message || "Failed to get basic details.",
        type: "error",
      })
    );
    dispatch(logout());
  }
};

/* 
  Get Influencer Details
*/
const getInfluencerDetails = async (data, user, dispatch) => {
  try {
    const request = {
      method: "POST",
      url: `${API_HOST}/account/dashboard/influencers`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.message || "Failed to get influencer details.",
        type: "error",
      })
    );
    dispatch(logout());
  }
};

/* 
  Get Publication Details
*/
const getPublicationDetails = async (data, user, dispatch) => {
  try {
    const request = {
      method: "POST",
      url: `${API_HOST}/account/dashboard/publications`, // Confirmed to be the same endpoint as Influencer Details
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.message || "Failed to get publication details.",
        type: "error",
      })
    );
    dispatch(logout());
  }
};

/* 
  Get Analytics
*/
const getAnalytics = async (data, user, dispatch) => {
  try {
    const request = {
      method: "POST",
      url: `${API_HOST}/account/dashboard/analytics`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.message || "Failed to get analytics.",
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getTopPosts = async (agencyId, user, dispatch) => {
  try {
    const request = {
      method: "GET",
      url: `${API_HOST}/topPosts/accountId/${agencyId}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.message || "Failed to get top posts.",
        type: "error",
      })
    );
    dispatch(logout());
  }
};

/* 
  Get Report History
*/
const getReportHistory = async (agencyId, user, dispatch) => {
  try {
    const request = {
      method: "GET",
      url: `${API_HOST}/report/history/account/${agencyId}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.message || "Failed to get report history.",
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export {
  getBasicDetails,
  getInfluencerDetails,
  getPublicationDetails,
  getAnalytics,
  getTopPosts,
  getReportHistory,
};
