import axios from "axios";
import config from '../../../../config/main.json';
import { fetchBrandData, fetchLanguageData, fetchLocationData } from "../../../../services/discovery";

const { IQHOST, UNAUTH_API_HOST } = config;

const getLocationData = async (searchKey,searchType,dispatch,user) => {
  try {

    const {status,response} = await fetchLocationData(searchKey,searchType,dispatch,user);

    if(status === 200 && response?.length > 0) {
      const newLocations = response?.map((loc) => {
        let locData = { "id": loc["id"], "name": loc["name"] };
        return locData;
      });
      return newLocations;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getLanguageData = async (searchKey,dispatch,user) => {
  try {
    const {status,response} = await fetchLanguageData(searchKey,dispatch,user);

    if(status === 200 && response?.length > 0) {
      const newLang = response?.map((lang) => {
        let langData = { "id": lang["code"], "name": lang["name"] };
        return langData;
      });
      return newLang;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getBrandData = async (searchKey,dispatch,user) => {
  try {

    const {status,response} = await fetchBrandData(searchKey, "brands", dispatch,user);

    if(status === 200 && response?.length > 0) {
      const newBrands = response?.map((brd, index) => {
        let brdData = { "id": brd["id"], "name": brd["name"] };
        return brdData;
      });
      return newBrands;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getInterestData = async (searchKey, dispatch, user) => {
  try {
    const {status,response} = await fetchBrandData(searchKey, "interests", dispatch,user);

    if(status === 200 && response?.length > 0) {
      const newBrands = response?.map((brd, index) => {
        let brdData = { "id": brd["id"], "name": brd["name"] };
        return brdData;
      });
      return newBrands;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getLookalikeData = async (searchKey, platform, searchType) => {
  try {
    const res = await axios
      .get(IQHOST + "dict/users/?q=" + searchKey + "&type="+searchType+"&platform=" + platform + "&limit=5");
    let newLookalikes = [];
    res.data.data?.map((look, index) => {
      if (look["username"] != undefined || look["fullname"] != undefined) {
        let lookData = { "username": look["username"], "name": look["fullname"], "pic": look["picture"], "followers": look["followers"] };
        newLookalikes.push(lookData);
      }
    });
    return newLookalikes
  } catch (err) {
    console.log(err);
  }
};

export { getLocationData, getLanguageData, getBrandData, getInterestData, getLookalikeData };