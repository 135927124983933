import { CancelOutlined } from "@mui/icons-material";
import { Box, Skeleton, Typography } from "@mui/material";
import { snapshotHeaderIcons } from "../../../../utility/plaformIcons";

const CampaignProfileModal = ({ closeProfileModal, selectedInfluencer }) => {
  const renderContentHandler = () => {
    let content = (
      <>
        {selectedInfluencer.profileUrl?.map((profile_url, idx) => {
          return (
            <Box
              key={profile_url || `contact-link=${idx}`}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={"1rem"}
            >
              <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Box
                  component={"a"}
                  fontSize={"20px"}
                  sx={{ textDecoration: "underline",  overflow: "hidden", textOverflow: "ellipsis" }}
                  href={profile_url}
                  target="_"
                  color="#6B747A"
                >
                  {profile_url}
                </Box>
              </Box>
            </Box>
          );
        })}
        {/* <Box
          component={"a"}
          href={`mailto:${findEmailList()}}`}
          color="#ffffff"
          sx={{ textDecoration: "none", "&:hover": { color: "#ffffff", textDecoration: "none" } }}
        >
          {findEmailList()?.length && (
            <Tooltip title={`Click to get in touch with ${name}`} placement="bottom">
              <Box
                bgcolor={"#979797"}
                borderRadius={"16px"}
                p={"12px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"383px"}
                mx="auto"
              >
                <Box mr={"1rem"}>
                  <MailOutlineIcon sx={{ width: "40px", height: "40px", color: "#ffffff" }} />
                </Box>
                <div>
                  <Typography color="#FBFDFF" fontSize="20px" fontWeight={"900"} whiteSpace={"nowrap"}>
                    Get in touch
                  </Typography>
                  <Box color="#ffffff" fontSize="14px" sx={{ borderBottom: "1px solid #ffffff" }}>
                    Click to send email
                  </Box>
                </div>
              </Box>
            </Tooltip>
          )}
        </Box> */}
      </>
    );

    return content;
  };

  return (
    <>
      <Box
        bgcolor={"#EAEAEA"}
        border="4px solid #6B747A"
        borderRadius={"32px"}
        p={"3rem"}
        maxWidth={"740px"}
        position={"relative"}
      >
        <Box position={"absolute"} top="4%" right="4%" display={"flex"} alignItems={"center"}>
          {/* <Box mr={"1rem"}><CloudDownloadOutlinedIcon sx={{ cursor: "pointer" }} /></Box> */}
          <Box component={"div"} onClick={() => closeProfileModal(true)}>
            <CancelOutlined sx={{ cursor: "pointer" }} />
          </Box>
        </Box>
        <Box mx="auto" textAlign={"center"} mb={"1rem"}>
          <Box
            component={"img"}
            src={selectedInfluencer.photoUrl}
            sx={{ width: "90px", height: "90px", borderRadius: "32px", border: "4px solid #687076" }}
          />
          <Typography mt="12px" fontSize={"30px"} color="#687076">
            {selectedInfluencer.name}
          </Typography>
        </Box>
        {renderContentHandler()}
      </Box>
    </>
  );
};

export default CampaignProfileModal;
