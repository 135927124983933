import { Avatar, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { ModeCommentOutlined, ThumbUpOutlined } from "@mui/icons-material";
import { number, object } from "prop-types";
import { stringAvatar, showIcon, statusMapping } from "./CommonAssets";
import YouTubeShare from "../../../../styles/icons/youtubeshare";
import PostActionButton from "./PostActionButton";
import { GetPostText, RenderCardMedia } from "./mediacontent";
import { renderData } from "./platform-asset-calculator";

const FacebookPost = ({ formData, cid }) => {
  return (
    <>
      <div className="d-flex flex-nowrap example-parent">
        <Avatar
          sx={{ width: 32, height: 32 }}
          {...stringAvatar(formData.influencerName)}
        />
        <span className="order-2 p-2 col-example" style={{ width: "100%" }}>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "10px" }}
            >
              <span className="header">{formData.influencerName}</span>
              <div className="d-flex justify-content-end">
                {showIcon(formData.platform)}
                {formData.preview && (
                  <PostActionButton id={formData.id} cid={cid} />
                )}
              </div>
            </div>
            {!formData.preview && (
              <p>{renderData(formData.postContent, formData.preview)}</p>
            )}
            {formData.preview && (
              <p>{renderData(formData.postContent, formData.preview)}</p>
            )}
            {formData.preview && formData.postContent?.length === 0 && (
              <p style={{ color: "transparent" }}>No Content</p>
            )}
            <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
              {RenderCardMedia(formData)}
            </Carousel>
            {formData.preview && (
              <Button
                style={{
                  backgroundColor: statusMapping?.filter(
                    (value) => value.id === formData.currentStatus
                  )[0]?.color,
                  width: "100%",
                  fontSize: "12px",
                  borderRadius: "10px",
                }}
                variant="contained"
              >
                {GetPostText(formData)}
              </Button>
            )}
          </div>
        </span>
      </div>
      <div className="d-flex justify-content-around">
        <span className="p-2 col-example text-left">
          <ThumbUpOutlined />
          &nbsp;Like
        </span>
        <span className="p-2 col-example text-left">
          <ModeCommentOutlined />
          &nbsp;Comment
        </span>
        <span className="p-2 col-example text-left">
          <YouTubeShare />
          &nbsp;Share
        </span>
      </div>
    </>
  );
};

FacebookPost.propTypes = {
  formData: object.isRequired,
  cid: number.isRequired,
};

export default FacebookPost;
