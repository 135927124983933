import { PropTypes } from "prop-types";
import AudienceProgressChart from "../../../common/AudienceProgressChart";
import ReactEcharts from "echarts-for-react";
import { BarChart, DoughnutChart } from "../../../common/chart";
import { Card, Grid } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";

const CampaignLinkGraphs = ({ linkClicksY, linkClicksX, devicesData, referresData, locationData, worldData }) => {
  return (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>Total Clicks</b>
          </h4>
          {linkClicksY?.length > 0 && linkClicksX?.length > 0 ? (
            <ReactEcharts
              option={BarChart(linkClicksX, true, [
                {
                  data: linkClicksY,
                  type: "bar",
                  color: "#4FD1C5",
                  barCategoryGap: "60%",
                },
              ])}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>Devices </b>
          </h4>
          {devicesData?.length > 0 ? (
            <ReactEcharts
              option={DoughnutChart(
                "Devices",
                ["#5368F0", "#9D57D5", "#FEAB00"],
                "vertical",
                10,
                50,
                devicesData,
                ["40%", "70%"],
                false
              )}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ height: "480px", width: "100%", padding: 20 }}>
          {referresData?.length > 0 ? (
            <AudienceProgressChart
              chartTitle="Referrers"
              parentStyle={styles}
              data={referresData}
              country={false}
              tooltip={true}
              tooltipText={
                "Referrals show you sites or apps that referred visitors to your destination site by clicking a link."
              }
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
          <br />
          <br />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <h4 style={{ paddingLeft: "20px" }}>
            <b className={styles.title}>Locations</b>
          </h4>{" "}
          {locationData?.length > 0 && worldData?.length > 0 ? (
            <AudienceProgressChart
              data={locationData}
              mapData={worldData}
              chartTitle="Countries"
              parentStyle={styles}
              country={true}
              backgroundColor="white"
              expandButtonVisible={true}
              tooltip={false}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

CampaignLinkGraphs.propTypes = {
  linkClicksY: PropTypes.array.isRequired,
  linkClicksX: PropTypes.array.isRequired,
  devicesData: PropTypes.array.isRequired,
  referresData: PropTypes.array.isRequired,
  locationData: PropTypes.array.isRequired,
  worldData: PropTypes.array.isRequired,
};

export default CampaignLinkGraphs;
