import { useState } from "react";
import PropTypes from "prop-types";
import StyledMenu from "../StyledMenu";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";

import { FormControl, Grid, Checkbox, IconButton } from "@mui/material";
import CustomFormControlLabel from "./common/CustomFormControlLabel";
import utils from "../../../utility/oryxUtils/utils";

const formItems = [
  { value: 1, label: "Only verified accounts", key: "verified" },
  { value: 2, label: "Only credible accounts", key: "credible" },
  { value: 3, label: "Only previously exported", key: "known" },
  { value: 4, label: "Exclude private accounts", key: "hidden" },
  { value: 5, label: "Has Audience Data", key: "has_audience_data" },
];

const Verified = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [verified, setVerified] = useState(false);
  const [credible, setCredible] = useState(false);
  const [exported, setExported] = useState(false);
  const [hasAudience, setHasAudience] = useState(false);
  const [privateAccount, setPrivateAccount] = useState(false);

  const handleChange = (event) => {
    let newPayload = payload;
    let filterSet = {
      name: formItems.find((item) => +item.value === +event.target.value)
        ?.label,
    };
    if (event.target.value == 1) {
      newPayload["inf_verified"] = event.target.checked;
      setVerified(event.target.checked);
      if (event.target.checked) {
        allFiltersData = utils.addObj(allFiltersData, "inf_verified");
        allFiltersData[0]["inf_verified"][0] = filterSet;
      } else {
        delete allFiltersData[0]["inf_verified"];
      }
    } else if (event.target.value == 2) {
      if (event.target.checked) {
        newPayload["inf_credible"] = ["low", "normal", "high", "best"];
      } else {
        newPayload["inf_credible"] = [];
      }
      setCredible(event.target.checked);
      if (event.target.checked) {
        allFiltersData = utils.addObj(allFiltersData, "inf_credible");
        allFiltersData[0]["inf_credible"].push(filterSet);
      } else {
        delete allFiltersData[0]["inf_credible"];
      }
    } else if (event.target.value == 3) {
      if (event.target.checked) {
        newPayload["inf_known"] = "known";
      } else {
        newPayload["inf_known"] = "";
      }
      setExported(event.target.checked);
      if (event.target.checked) {
        allFiltersData = utils.addObj(allFiltersData, "inf_known");
        allFiltersData[0]["inf_known"].push(filterSet);
      } else {
        delete allFiltersData[0]["inf_known"];
      }
    } else if (event.target.value == 4) {
      newPayload["inf_hidden"] = !event.target.checked;
      setPrivateAccount(event.target.checked);
      if (event.target.checked) {
        allFiltersData = utils.addObj(allFiltersData, "inf_hidden");
        allFiltersData[0]["inf_hidden"].push(filterSet);
      } else {
        delete allFiltersData[0]["inf_hidden"];
      }
    } else {
      newPayload["inf_has_audience_data"] = event.target.checked;
      setHasAudience(event.target.checked);
      if (event.target.checked) {
        allFiltersData = utils.addObj(allFiltersData, "inf_has_audience_data");
        allFiltersData[0]["inf_has_audience_data"].push(filterSet);
      } else {
        delete allFiltersData[0]["inf_has_audience_data"];
      }
    }

    setAllFiltersData(allFiltersData);
    setPayload(newPayload);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <IconButton
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            sx={{
              width: "40px",
              height: "40px",
              border: "1px solid lightgrey",
              borderRadius: "50%",
            }}
          >
            <MoreHorizRoundedIcon />
          </IconButton>
          {/* <Button
            style={{ color: "black", background: "unset", border: "1px solid lightgrey"}}
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            ...
          </Button> */}

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} style={{ height: "auto" }}>
              <Grid item xs={6}>
                {/* <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1 }} >
                      Influencer
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} style={{ width: "100px", display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={"Identify influencers by their influencer account type. We determine influencer account type by analyzing account type tags, language and caption of recent posts and text in profile \
                              bio. You can add several influencer account type and specify minimum percentage for each account type, your search results will be refined with influencers that have influencer in ANY of \
                              the specified account type."}
                    >
                      <HelpRoundedIcon fontSize="small" sx={{ opacity: 0.3, ml: "12px" }} />
                    </Tooltip>
                  </Grid>
                </Grid> */}
                <FormControl sx={{ m: 1 }}>
                  {formItems?.map((item) => (
                    <CustomFormControlLabel
                      value={item.value}
                      control={<Checkbox false />}
                      label={item.label}
                      onChange={handleChange}
                      key={`verify-${item.label}`}
                      checked={allFiltersData[0][`inf_${item.key}`]?.length > 0}
                    />
                  ))}
                </FormControl>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Verified.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Verified;

export { formItems };
