import { ExposureTwoTone, FileDownload } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ExportFiles = ({ sendExportSelected }) => {
  const [exportType, setExportType] = React.useState("");
  const createExport = (event) => {
    const {
      target: { value },
    } = event;
    setExportType("");
    sendExportSelected(value);
  };

  return (
    <FormControl fullWidth size="small">
      <Tooltip title="Export Table" placement="top">

        <InputLabel
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            paddingLeft: "60%",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            pointerEvents: 'none',  // Prevents the icon from intercepting clicks
          
          }}
        >
          <IconButton edge="start">
            <FileDownload />
          </IconButton>
        </InputLabel>
        <Select
          sx={{
            minWidth: '2vw',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            '& .MuiSelect-icon': {
              display: 'none', // Hide the default dropdown icon
            
            },
          }}
          value={exportType}
          onChange={createExport}
          // Label is no longer needed here
        >
          <MenuItem value={"csv"} style={{ fontSize: "14px"}}>CSV</MenuItem>
        </Select>
      </Tooltip>
    </FormControl>

  );
};

ExportFiles.propTypes = {
  sendExportSelected: PropTypes.func.isRequired,
};

export default ExportFiles;
