import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import FileSaver from 'file-saver';
import { notify } from "../redux/features/system/systemAlert";

/* 
  get details of all campaigns
*/
const exportPDFFile = async (fileName, formatedData) => {
  html2canvas(formatedData).then((canvas) => {
    let img = canvas.toDataURL("image/jpeg", 1);
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    let doc = new jsPDF("p", "px", [100, 100]);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
    doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
    doc.save(fileName);
  });
};

const exportImageFile = async (fileName, formatedData) => {
  html2canvas(formatedData).then((canvas) => {
    let a = document.createElement("a");
    a.href = canvas
      .toDataURL("image/jpeg")
      .replace("image/jpeg", "image/octet-stream");
    a.download = fileName;
    a.click();
  });
};

const onDownloadHandle = async (fileData,file) => {
  await FileSaver(fileData, file);
};

const downloadNotification = async (dispatch) => {
  dispatch(
    notify({
      message: "Please wait while we prepare your files for download...",
      type: "info",
    })
  );
};

const getResponseType = (isExport) => {
  let responseType = "text"
  if (isExport===1) {
        responseType = "blob"
  }
  return responseType
};
export { exportPDFFile, exportImageFile, onDownloadHandle, downloadNotification, getResponseType };
