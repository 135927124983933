import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import Loader from "../../../common/loader";
import { getSentimentStats } from "../../../../services/sentimentAnalytics";
import ReactEcharts from "echarts-for-react";
import { DoughnutChart, StackedLineChart } from "../../../common/chart";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import DummyCampaign from "../../../common/DummyCampaign";

const SentimentAnalyticsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [startDate, setStartDate] = useState(getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [donutData, setDonutData] = useState([]);
  const universal = params.get("universal");
  useEffect(() => {
    getSentimentAnalytics(params.get("id"), startDate, endDate);
  }, [+params.get("id"), startDate, endDate]);

  const getSentimentAnalytics = async (cid, from, to) => {
    setLoading(true);
    setDataFound(false);

    const sentiments = await getSentimentStats(cid, from, to, user, dispatch);

    setLoading(false);

    if (sentiments.status === true && sentiments.response !== null) {
      const data = sentiments.response;
      let seriesData = { Positive: [], Negative: [], Neutral: [] };
      let xAxisData = [];
      let positive = 0,
        negative = 0,
        neutral = 0;
      const keys = Object.keys(data);
      if (keys?.length > 0) {
        setDataFound(true);
      } else {
        return;
      }

      keys.forEach((val) => {
        xAxisData.push(val);
        seriesData.Positive.push(data[val].positive);
        seriesData.Negative.push(data[val].negative);
        seriesData.Neutral.push(data[val].neutral);
        positive += data[val].positive;
        negative += data[val].negative;
        neutral += data[val].neutral;
      });
      let total = positive + negative + neutral;
      let positiveData = (positive / total) * 100;
      let negativeData = (negative / total) * 100;
      let neutralData = (neutral / total) * 100;

      const allData = [
        { value: positiveData.toFixed(2), name: "Positive(%)" },
        { value: negativeData.toFixed(2), name: "Negative(%)" },
        { value: neutralData.toFixed(2), name: "Neutral(%)" },
      ];

      const donutData = allData?.filter(d => parseFloat(d.value) !== 0);

      setXAxisData(xAxisData);
      setSeriesData(seriesData);
      setDonutData(donutData);
    } else {
      setDataFound(false);
      if (sentiments.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: sentiments.response?.message,
          type: "error",
        })
      );
    }
  };

  const setSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
  };

  return (
    <Box m={3}>
      <DummyCampaign universal={universal} />
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <DateRangeCustomPicker sendSelectedDates={setSelectedDates} parentStartDate={startDate} parentEndDate={endDate} needRange={true} />
        </Grid>
      </Grid>
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <Card sx={{ p: "20px", borderRadius: "10px", mt: "20px" }}>
            <ReactEcharts
              option={{
                ...StackedLineChart("Sentiment Analytics", xAxisData, ["Positive", "Negative", "Neutral"], seriesData),
                series: Object.keys(seriesData)?.map((key) => ({
                  name: key,
                  type: "line",
                  data: seriesData[key],
                  markPoint: {
                    data: [
                      { type: 'max', name: 'Peak' },
                    ],
                    label: {
                      show: true,
                      formatter: '{c}',  
                      fontSize: 8, 
                    },
                    itemStyle: {
                      color: 'white',
                      borderColor: key === 'Positive' ? '#91cc75' : key === 'Negative' ? '#ee6666' : '#fac858',
                      borderWidth: 2,
                    },
                    symbolSize: 35,
                  },
                })),
                color: ["#91cc75", "#ee6666", "#fac858"],
              }}
              style={{ height: "400px", width: "100%" }}
            />
          </Card>
          <Card sx={{ p: "20px", borderRadius: "10px", mt: "20px" }}>
            <ReactEcharts
              option={{
                ...DoughnutChart(
                  "",
                  [],
                  "vertical",
                  "90",
                  "180",
                  donutData,
                  ["40%", "70%"],
                  true,
                  false,
                  true,
                  {},
                  {},
                  "Overall Sentiments"
                ),
                color: ["#91cc75", "#ee6666", "#fac858"],
              }}
              style={{ height: "400px", width: "100%" }}
            />
          </Card>
        </>
      ) : (
        <>
          <Card
            sx={{
              p: "20px",
              borderRadius: "10px",
              mt: "20px",
              height: "440px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              Sentiment Analytics
            </Typography>
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Graph found
            </p>
          </Card>
          <Card
            sx={{
              p: "20px",
              borderRadius: "10px",
              mt: "20px",
              height: "440px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>Overall Sentiments</Typography>
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Graph found
            </p>
          </Card>
        </>
      )}
      <br />
    </Box>
  );
};

export default SentimentAnalyticsSnapshot;
