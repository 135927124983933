import { Card, Grid } from "@mui/material";
import {InfoToolTip} from "../../../common/toolTip";
import styles from "../../../../styles/internal/Campaign/CampaignAnalytics.module.css";
import PropTypes from "prop-types";

import ReactEcharts from "echarts-for-react";
import { BarChart } from "../../../common/chart";
import AudienceProgressChart from "../../../common/AudienceProgressChart";
import AudiencePieChart from "./AudiencePieChart";
import React from "react";
const AudienceInsightsGraph = ({
  totalMale,
  totalFemale,
  maleData,
  femaleData,
  reachabilityData,
  countryData,
  worldMapData,
  ethnicityData,
  cityData,
  interestData,
  languageData,
  brandData,
}) => {
  React.useEffect(() => {}, [
    totalMale,
    totalFemale,
    maleData,
    femaleData,
    reachabilityData,
    countryData,
    worldMapData,
    ethnicityData,
    cityData,
    interestData,
    languageData,
    brandData,
  ]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <Grid container>
            <Grid item xs={8}>
              <h4>
                <b className={styles.title}>Age and Gender Distribution</b>
                &nbsp;&nbsp;
                <InfoToolTip text={"Campaign audience gender distribution as per age brackets."} />
              </h4>
            </Grid>
            <Grid item xs={4}>
              <span className={styles.dotPurple}></span> <span className={styles.distributionText}>Men - {totalMale}%</span>
              <br />
              <span className={styles.dotBlue}></span> <span className={styles.distributionText}>Women - {totalFemale}%</span>
            </Grid>
          </Grid>

          {maleData?.length > 0 && femaleData?.length > 0 ? (
            <>
              <ReactEcharts
                option={BarChart(["13-17", "18-24", "25-34", "35-44", "45-64", "65+"], true, [
                  {
                    name: "Male",
                    type: "bar",
                    showBackground: true,
                    color: ["#7244AE"],
                    itemStyle: {
                      borderRadius: [100, 100, 0, 0],
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value + " %";
                      },
                    },
                    data: maleData,
                  },
                  {
                    name: "Female",
                    type: "bar",
                    showBackground: true,
                    color: ["#4299E1"],
                    itemStyle: {
                      borderRadius: [100, 100, 0, 0],
                    },
                    tooltip: {
                      valueFormatter: function (value) {
                        return value + " %";
                      },
                    },
                    data: femaleData,
                  },
                ])}
                style={{ height: "360px", width: "100%" }}
              />
              <p style={{ color: "#1e2022", fontSize: "13px" }}>*Based on Engagement received on Instagram</p>{" "}
            </>
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <Grid container>
            <Grid item xs={8}>
              <h4>
                <b className={styles.title}>Audience Reachability</b>
                &nbsp;&nbsp;
                <InfoToolTip text="Audience Reachability indicates spread of followers count in the Influencer audience. Audiences who follow less than 1,500 accounts are more likely to see the post in their newsfeed." />
              </h4>
            </Grid>
          </Grid>
          {reachabilityData?.length > 0 ? (
            <ReactEcharts
              option={BarChart(["0-500 Followers", "500-100 Followers", "1000-1500 Followers", "1500+ Followers"], true, [
                {
                  type: "bar",
                  color: ["#4299E1"],
                  itemStyle: {
                    borderRadius: [100, 100, 0, 0],
                  },
                  data: reachabilityData,
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + " %";
                    },
                  },
                  label: {
                    show: true,
                    position: "top",
                    formatter: function (value) {
                      return value.value + " %";
                    },
                  },
                },
              ])}
              style={{ height: "410px", width: "100%" }}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <h4>
            <b className={styles.title}>Countries</b>
            &nbsp;&nbsp;
            <InfoToolTip text="Campaign audience distribution as per countries." />
          </h4>{" "}
          <AudienceProgressChart
            data={countryData}
            mapData={worldMapData}
            chartTitle="Countries"
            parentStyle={styles}
            country={true}
            backgroundColor="white"
            expandButtonVisible={true}
            tooltip={false}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <AudienceProgressChart
            chartTitle="Ethnicity"
            parentStyle={styles}
            data={ethnicityData}
            country={false}
            backgroundColor="white"
            expandButtonVisible={true}
            tooltip={false}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <AudienceProgressChart
            chartTitle="By Cities"
            parentStyle={styles}
            data={cityData}
            country={false}
            backgroundColor="white"
            expandButtonVisible={true}
            tooltip={false}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <AudienceProgressChart
            chartTitle="By Interest"
            parentStyle={styles}
            data={interestData}
            country={false}
            backgroundColor="white"
            expandButtonVisible={true}
            tooltip={false}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <AudienceProgressChart
            chartTitle="By Language"
            parentStyle={styles}
            data={languageData}
            country={false}
            backgroundColor="white"
            expandButtonVisible={true}
            tooltip={false}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: "20px", borderRadius: 10 }}>
          <AudiencePieChart chartTitle="Brand" data={brandData} backgroundColor="white" expandButtonVisible={true} />
        </Card>
      </Grid>
    </Grid>
  );
};

AudienceInsightsGraph.propTypes = {
  totalMale: PropTypes.number.isRequired,
  totalFemale: PropTypes.number.isRequired,
  maleData: PropTypes.array.isRequired,
  femaleData: PropTypes.array.isRequired,
  reachabilityData: PropTypes.array.isRequired,
  countryData: PropTypes.array.isRequired,
  worldMapData: PropTypes.array.isRequired,
  ethnicityData: PropTypes.array.isRequired,
  cityData: PropTypes.array.isRequired,
  interestData: PropTypes.array.isRequired,
  languageData: PropTypes.array.isRequired,
  brandData: PropTypes.array.isRequired,
};

export default AudienceInsightsGraph;
