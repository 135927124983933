import { ChevronRight, Circle, KeyboardArrowDown } from "@mui/icons-material";
import { Divider, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { array } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoToolTip } from "../../components/common/toolTip";
import { getCampaignNameForBreadcrumbs } from "../../services/campaigns";
import { setCampaignName } from "../../utility/sessionData";
import { campaignStatusColors } from "../../utility/status";
import breadcrumbConfig from '../../config/paths.json';

function BreadCrumbs({ menuItemsData }) {
  const user = useSelector((state) => state.user); // get loggedIn user state

  const [show, setShow] = useState(false);
  const [base, setBase] = useState("");
  const [option, setOption] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [campaigns, setCampaigns] = useState({});
  const [possibleCampaigns, setPossibleCampaigns] = useState({});
  const [campaignSearch, setCampaignSearch] = useState("");

  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const search = location.search;
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();

  //Added for the breadcrumb navigation
  const { breadcrumbRoutes } = breadcrumbConfig;

  const campaignStatusOrder = ["Live", "Paused", "Inactive", "Completed", "Scheduled"];

  const getCampaigns = async () => {
    const campaigns = await getCampaignNameForBreadcrumbs(user, dispatch);
    const sortedCampaigns = {};
    campaigns.response.forEach((campaign) => {
      if (!Object.keys(sortedCampaigns).includes(campaign.status)) {
        sortedCampaigns[campaign.status] = [];
      }

      sortedCampaigns[campaign.status].push({
        id: campaign.id,
        name: campaign.name,
      });
    });

    setCampaigns(sortedCampaigns);
    setPossibleCampaigns(sortedCampaigns);
    getName(+params.get("id"), campaigns.response);
  };

  const getName = (id, campaigns) => {
    const name = campaigns?.filter((campaign) => {
      return campaign.id === id;
    })[0]?.name;
    setCampaignName(name);
    setName(name);
  };

  useEffect(() => {
    const handleClick = (event) => {
      setCampaignSearch("");
      setAnchorEl(anchorEl === null ? event.currentTarget : null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    let show = false;
    for (let i = 0; i < menuItemsData?.length; i++) {
      const { children } = menuItemsData[i];
      if (children !== undefined && location.pathname.startsWith(menuItemsData[i].path)) {
        show = true;
        let defaultPath = null;
        // check if parent compoent is not present , hence render default child element
        if (children?.length) {
          defaultPath = children?.filter((child) => child?.default === 1);
        }
        //set path basis on the default element check
        const path = defaultPath?.length ? defaultPath[0]?.path : menuItemsData[i].path;
        const base = (
          <span
            style={{
              color: "#1461CC",
              fontWeight: 600,
              cursor: "pointer",
              fontSize: "16px", 
              padding: "4px", 
            }}
            onClick={() => {
              navigate(path);
            }}
          >
            {menuItemsData[i].name}
          </span>
        );
        setBase(base);

        let subMenu;
        let subMenuChildren;
        for (let j = 0; j < children?.length; j++) {
          if (children[j].path === location.pathname) {
            subMenu = children[j];
            break;
          } else if (children[j].path !== location.pathname && children[j].children !== null) {
            for (let k = 0; k < children[j].children?.length; k++) {
              if (children[j].children[k].path === location.pathname) {
                subMenu = children[j];
                subMenuChildren = children[j].children[k];
                break;
              }
            }
          }
        }
        let option;
        if (!["Campaigns"].includes(menuItemsData[i].name) || params.get("id") === null) {
          for (let j = 0; j < children?.length; j++) {
            if (children[j].path === location.pathname || location.pathname.startsWith(children[j].path)) {
              option = (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer"
                  }}
                  onClick={() => handleBreadcrumbClick(subMenu?.name)}
                >
                  {subMenu?.name}
                </span>
              );
              break;
            }
          }
        } else {
          getCampaigns();
          option = (
            <>
              <span onClick={handleClick} style={{ cursor: "pointer" }}>
                <span style={{ fontWeight: 600, fontSize: "16px" }}>{name}</span>
                <KeyboardArrowDown fontSize="small" style={{ margin: "2px" }} />
              </span>
              <InfoToolTip text="You can jump from one campaign to another from here" />
              <ChevronRight fontSize="small" />
              {subMenuChildren !== null && subMenuChildren !== undefined && subMenu?.path !== "" && (
                <span
                  style={{
                    color: "#1461CC",
                    fontWeight: 600,
                    cursor: "pointer",
                    fontSize: "16px", 
                  }}
                  onClick={() => {
                    navigate(`${subMenu?.path}?id=${params.get("id")}`);
                  }}
                >
                  {subMenu.name}
                </span>
              )}
              {subMenuChildren !== null && subMenu?.path === "" && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer"
                  }}
                  onClick={() => handleBreadcrumbClick(subMenuChildren?.name)}
                >
                  {subMenu.name}
                </span>
              )}
              {(subMenuChildren === null || subMenuChildren === undefined) && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer"
                  }}
                  onClick={() => handleBreadcrumbClick(subMenu?.name)}
                >
                  {subMenu?.name}
                </span>
              )}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "inherit",
                    overflowX: "hidden",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    maxHeight: "304px",
                  },
                }}
                onKeyDownCapture={(e) => {
                  const key = e.key;
                  if (!["ArrowUp", "ArrowDown", "Enter"].includes(key)) {
                    e.stopPropagation();
                    let search = campaignSearch;
                    if (key?.length === 1) {
                      search += key;
                    } else if (key === "Backspace") {
                      search = search.slice(0, search?.length - 1);
                    }
                    const possibleCampaigns = {};
                    for (let key of Object.keys(campaigns)) {
                      const campaignStatus = campaigns[key]?.filter(
                        (campaign) => search === "" || campaign.name.toLowerCase().includes(search.toLowerCase())
                      );
                      if (campaignStatus?.length > 0) {
                        possibleCampaigns[key] = campaignStatus;
                      }
                    }
                    setCampaignSearch(search);
                    setPossibleCampaigns(possibleCampaigns);
                  } else {
                    return;
                  }
                }}
              >
                {campaigns && (
                  <TextField value={campaignSearch} placeholder="Search Campaigns" fullWidth size="small" sx={{ px: "10px" }} />
                )}
                {Object.keys(possibleCampaigns)?.length > 0 &&
                  campaignStatusOrder
                    ?.filter((status) => Object.keys(possibleCampaigns).includes(status))
                    ?.map((status) => {
                      return (
                        <>
                          <Stack direction="row" sx={{ mt: "10px" }}>
                            <Circle
                              htmlColor={campaignStatusColors[status]}
                              sx={{
                                width: "10px",
                                height: "10px",
                                ml: "16px",
                                mt: "5px",
                              }}
                            />
                            <Typography
                              sx={{
                                ml: "10px",
                                fontStyle: "italic",
                                fontSize: "0.9rem",
                              }}
                            >
                              {status}
                            </Typography>
                          </Stack>
                          <Divider sx={{ m: "5px" }} />
                          {possibleCampaigns[status]?.map((campaign) => {
                            const path = `${location.pathname}?id=${campaign.id}`;
                            return (
                              <MenuItem
                                onClick={() => {
                                  setAnchorEl(null);
                                  navigate(path);
                                }}
                              >
                                {campaign.name}
                              </MenuItem>
                            );
                          })}
                        </>
                      );
                    })}
                {Object.keys(possibleCampaigns)?.length === 0 && (
                  <Typography p="10px" ml="10px" justifyContent="center" color="lightgray">
                    No campaigns found...
                  </Typography>
                )}
              </Menu>
              {subMenuChildren !== undefined && (
                <>
                  <ChevronRight fontSize="small" />
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      cursor: "pointer"
                    }}
                    onClick={() => handleBreadcrumbClick(subMenuChildren?.name)}
                  >
                    {subMenuChildren.name}
                  </span>
                </>
              )}
            </>
          );
        }
        setOption(option);
        break;
      }
    }
    setShow(show);
  }, [location.pathname, anchorEl, name, campaignSearch]);

  const handleBreadcrumbClick = (breadcrumb) => {
    const routeTemplate = breadcrumbRoutes[breadcrumb];
    if (routeTemplate) {
      const url = routeTemplate
        .replace("{id}", params.get("id"))
        .replace("{universal}", params.get("universal"));
      navigate(url);
    }
  };

  return (
    show && (
      <div>
        {base}

        {option !== undefined && (
          <>
            <ChevronRight fontSize="small" /> {option} 
          </>
        )}
      </div>
    )
  );
}

BreadCrumbs.propTypes = {
  menuItemsData: array.isRequired,
};

export default BreadCrumbs;
