import { Button, Grid } from "@mui/material";
import React from "react";
import { sendXlsx } from "../../../../services/campaignAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import CampaignLinkGraphs from "./CampaignLinkGraphs";
import Loader from "../../../common/loader";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import CampaignLinksTable from "./CampaignLinksTable";

const CampaignLinksMetricsSnapshot = ({
  selectedShorturls,
  selectedStartDate,
  selectedEndDate,
  loading,
  dataFound,
  linkClicksY,
  linkClicksX,
  devicesData,
  referresData,
  locationData,
  worldData,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const exportXlsx = async () => {
    const exportResponse = await sendXlsx(
      selectedShorturls?.map((value) => value.id),
      getFormattedDate(selectedStartDate, "YYYY-MM-DD"),
      getFormattedDate(selectedEndDate, "YYYY-MM-DD"),
      user,
      dispatch,
      "link/analysis/sendxlsx"
    );

    if (exportResponse.status) {
      dispatch(
        notify({
          message: `The excel file containing link details will be sent to ${user.email} within few mins`,
          type: "success",
        })
      );
    }
  };

  return (
    <Grid container spacing={2} mt="16px">
      <Grid item xs={12}>
        {loading ? (
          <Loader />
        ) : dataFound ? (
          <Grid container spacing={2}>
            <CampaignLinkGraphs
              linkClicksY={linkClicksY}
              linkClicksX={linkClicksX}
              devicesData={devicesData}
              referresData={referresData}
              locationData={locationData}
              worldData={worldData}
            />
            <AdjustCampaignDates />
            {selectedShorturls?.length > 0 && (
              <Grid item xs={12} sx={{ padding: '0px !important', mt: 4 }}>
                <h4 style={{ paddingLeft: "20px" }}>
                    <b className={styles.title}>Link Details</b>
                  </h4>
                  <Button
                    variant="contained"
                    style={{
                      background:
                        "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                      borderRadius: "8px",
                      float: "right",
                    }}
                    onClick={exportXlsx}
                    disabled={selectedShorturls?.length === 0}
                  >
                    Export XLSX
                  </Button>
                <CampaignLinksTable
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  shorturlIds={selectedShorturls?.map((value) => value.id)}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.child}>
                {" "}
                No links added on this campaign
              </div>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CampaignLinksMetricsSnapshot;
