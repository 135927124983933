import config from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const getUserDetails = async (email, user, dispatch) => {
  try {
    const data = { email };

    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/userDetails`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const sendVerificationOtp = async (email, dialCode, phoneNumber, user, dispatch) => {
  try {
    const data = { email, dialCode, phoneNumber };

    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/sendVerificationCode`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const verifyOtp = async (email, dialCode, phoneNumber, verificationCode, user, dispatch) => {
  try {
    const data = { email, dialCode, phoneNumber, verificationCode };

    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/verifyCode`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAccountInformation = async (user, dispatch) => {
  try {
    const request = {
      hostname: config.ORYX_HOSTNAME,
      method: "GET",
      url: `${config.API_HOST}/account/info/${user.agencyId}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getTimezoneData = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: config.ORYX_HOSTNAME,
      method: "GET",
      url: `${config.API_HOST}/timezone/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const saveGeneralChanges = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.API_HOST}/account/infoUpdate`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const changeLoginPassword = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/changePassword`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const updateName = async (name, user, dispatch) => {
  const data = {
    name,
    email: user.email,
  };
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/updatePersonalInfo`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAllCountries = async (user, dispatch) => {
  try {
    const request = {
      hostname: config.ORYX_HOSTNAME,
      method: "GET",
      url: `${config.API_HOST}/countries`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getCountries = async () => {
  const response = await fetch(`${config.UNAUTH_API_HOST}/countries`, {
    method: "GET",
    headers,
  });
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};
const updateCountry = async (country, user, dispatch) => {
  const data = {
    country,
    email: user.email,
  };
  try {
    const request = {
      hostname: config.HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${config.HEIMDALL_HOST}/updatePersonalInfo`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export {
  getUserDetails,
  sendVerificationOtp,
  verifyOtp,
  getAccountInformation,
  getTimezoneData,
  saveGeneralChanges,
  changeLoginPassword,
  updateName,
  getAllCountries,
  updateCountry,
  getCountries
};