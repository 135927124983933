import { FavoriteOutlined, ModeComment, Share } from "@mui/icons-material";
import config from "../../../../config/main.json"
import { textColor } from "../../../../utility/plaformIcons";

const TwitterRetweet = `${config.BASE_IMAGE_URL}twitterRetweet.svg`

export const PlatformAttributeMap = {
  instagram: [
    { attribute: "likes", icon: <FavoriteOutlined sx={textColor.instagram} fontSize="small" /> },
    { attribute: "comment", icon: <ModeComment sx={textColor.instagram} fontSize="small" /> },
  ],
  youtube: [
    { attribute: "likes", icon: <FavoriteOutlined sx={textColor.youtube} fontSize="small" /> },
    { attribute: "comment", icon: <ModeComment sx={textColor.youtube} fontSize="small" /> },
  ],
  tiktok: [
    { attribute: "likes", icon: <FavoriteOutlined sx={textColor.tiktok} fontSize="small" /> },
    { attribute: "comment", icon: <ModeComment sx={textColor.tiktok} fontSize="small" /> },
  ],
  twitter: [
    { attribute: "likes", icon: <FavoriteOutlined sx={textColor.twitter} fontSize="small" /> },
    { attribute: "reply", icon: <ModeComment sx={textColor.twitter} fontSize="small" /> },
    { attribute: "retweet", icon: <img src={TwitterRetweet} sx={textColor.twitter} fontSize="small" /> },
  ],
  facebook: [
    { attribute: "likes", icon: <FavoriteOutlined sx={textColor.facebook} fontSize="small" /> },
    { attribute: "comment", icon: <ModeComment sx={textColor.facebook} fontSize="small" /> },
    { attribute: "shares", icon: <Share sx={textColor.facebook} fontSize="small" /> },
  ],
  linkedin: [
    { attribute: "likes", icon: <FavoriteOutlined sx={textColor.linkedin} fontSize="small" /> },
    { attribute: "comment", icon: <ModeComment sx={textColor.linkedin} fontSize="small" /> },
  ],
};
