import * as React from "react";
import { FacebookEmbed } from "react-social-media-embed";
import { InstagramEmbed } from "react-social-media-embed";
import { PlaceholderEmbed } from "react-social-media-embed";
import { TikTokEmbed } from "react-social-media-embed";
import { TwitterEmbed } from "react-social-media-embed";
import { YouTubeEmbed } from "react-social-media-embed";

export default function TopNPosts(props) {
  let platform = props.Platform.toLowerCase();
  if (platform === "instagram") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <InstagramEmbed url={props.PostUrl} width={328} />
      </div>
    );
  } else if (platform === "facebook") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FacebookEmbed url={props.PostUrl} width={550} />
      </div>
    );
  } else if (platform === "linkedin") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PlaceholderEmbed
          linkText="View post on Linkedin"
          style={{
            height: 320,
            width: 400
          }}
          url={props.PostUrl}
        />
      </div>

    );
  } else if (platform === "tiktok") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "492px",
          width: "325px",
        }}
      >
        <TikTokEmbed url={props.PostUrl} />
      </div>
    );
  } else if (platform === "twitter") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TwitterEmbed url={props.PostUrl} width={325} />
      </div>
    );
  } else if (platform === "youtube") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <YouTubeEmbed url={props.PostUrl} width={325} height={220} />
      </div>
    );
  } else {
    return <></>;
  }
}
