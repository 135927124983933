import { colors } from "@mui/material";

const Palette = {
  background: {
    default: "white",
    paper: colors.common.white,
  },
  primary: {
    contrastText: "#ffffff",
    main: "#6358DC",
  },
  secondary: {
    main: "#2F2F2F",
    light: "#949494",
    dark: "#5c5c5c",
  },
  text: {
    primary: "#4f4f4f",
    secondary: "#6b778c",
  },
  danger: {
    contrastText: "#ffffff",
    main: "#FF505F",
    light: "#FF303F",
    dark: "#FF303F",
  },
  // report: {
  //   main: "#B00020",
  //   light: "#B00020",
  //   dark: "#B00020",
  // },
  // profileTimelineIcon: {
  //   main: "#2F80ED",
  // },
};
export default Palette;
