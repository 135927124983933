import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDraftDetails } from "../../../../../services/publication";
import InstagramPost from "../InstagramPost";
import LinkedinPost from "../LinkedinPost";
import FacebookPost from "../FacebookPost";
import TwitterPost from "../TwitterPost";
import TiktokPost from "../TiktokPost";
import YoutubePost from "../YoutubePost";

const ApprovedDraftDialog = ({ draft, viewApproved, closeApproved }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const [postDetails, setPostDetails] = useState({});

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    const postDetails = await getDraftDetails(draft.id, user, dispatch);

    if (postDetails.status === true) {
      setPostDetails(postDetails.response);
    }
  };

  const renderPost = () => {
    return (
      <Grid item xs={4} sm={8} md={12}>
        <Card
          style={{
            margin: "auto",
            padding: "20px",
            width: "500px",
            height: "auto",
          }}
        >
          {postDetails.platform === "instagram" && (
            <InstagramPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "linkedin" && (
            <LinkedinPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "facebook" && (
            <FacebookPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "twitter" && (
            <TwitterPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "tiktok" && (
            <TiktokPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "youtube" && (
            <YoutubePost formData={postDetails} cid={postDetails.cid} />
          )}
        </Card>
      </Grid>
    );
  };

  return (
    <Dialog
      open={viewApproved}
      onClose={closeApproved}
      PaperProps={{
        style: {
          height: "auto",
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          id="influencerMetadata"
          style={{ paddingTop: "0" }}
        >
          <Grid item xs={2} sm={4} md={6}>
            <b>Campaign</b> :{postDetails.campaignName}
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <b>List</b> :{postDetails.listName}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          id="post"
        >
          {renderPost()}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ApprovedDraftDialog.propTypes = {
  draft: PropTypes.object.isRequired,
  viewApproved: PropTypes.bool.isRequired,
  closeApproved: PropTypes.func.isRequired,
};

export default ApprovedDraftDialog;
