import { Button, Chip, Grid, TextField } from "@mui/material";
import styles from "../../styles/internal/Subscribe.module.css";
import React, { useState } from "react";
import { RoundedTextField, StyledStaticButtonRoundedGroup, StyledStaticRoundedButton } from "../common/StyledComponents";
import { verifyCouponCode } from "../../services/billing";
import { useLocation } from "react-router-dom";

const PaymentForm = ({ planDetails, getCouponCode, getDiscountedAmount }) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const tenure = params.get("tenure");
  const [getCoupon, setGetCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [error, setError] = useState("");
  const [planPrice, setPlanPrice] = useState(planDetails.planPrice);

  React.useEffect(() => {
    if(tenure != "monthly")  setPlanPrice(Math.round((planDetails.planPrice * 6)* 0.8));
    else setPlanPrice(planDetails.planPrice)
  }, [planDetails])
  const redeemCoupon = async () => {
    const response = await verifyCouponCode(couponCode);
    const result = parseInt(response?.response);
    if (parseInt(result)) {
      setError("");
      getCouponCode(couponCode);
      setDiscountedAmount(result);
      getDiscountedAmount(result);
    } else {
      setError(response?.response);
    }
  };

  const removeCouponCode = () => {
    setDiscountedAmount("");
  };
  const card = (
    <>
      <h4 className={styles.subTitle}>Purchase Summary</h4>
      <Grid container>
        <Grid item xs={10} className={styles.paymentData}>
          {planDetails.planName} Plan
        </Grid>
        <Grid item xs={2} className={styles.paymentData}>
          ${planPrice}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10} className={styles.paymentData}>
          Tax
        </Grid>
        <Grid item xs={2} className={styles.paymentData}>
          $0
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10} className={styles.paymentData}>
          <b>Subtotal</b>
        </Grid>
        <Grid item xs={2} className={styles.paymentData}>
          <b>${planPrice}</b>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        {!getCoupon && (
          <Grid item xs={10} className={styles.couponCodeData} onClick={() => setGetCoupon(true)}>
            <a href="#" style={{ textDecoration: "none" }}>
              Have a Coupon Code?
            </a>
          </Grid>
        )}
        {getCoupon && (
          <>
            <Grid item xs={10} className={styles.couponCodeData}>
              <p>Coupon Code</p>
            </Grid>
            <Grid item xs={8} className={styles.couponCodeData}>
              <TextField
                size="small"
                id="outlined-basic"
                sx={RoundedTextField}
                placeholder="<Enter your Coupon Code>"
                variant="outlined"
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} className={styles.couponCodeData}>
              <Button variant="contained" sx={{ borderRadius: "20px" }} onClick={redeemCoupon}>
                <b>Redeem</b>
              </Button>
            </Grid>
            {error != "" && (
              <Grid item xs={8} className={styles.couponCodeData}>
                <p style={{ color: "red" }}>{error}</p>
              </Grid>
            )}
            {error == "" && discountedAmount != "" && (
              <Grid item xs={8} className={styles.couponCodeData}>
                <Chip label={`${couponCode}`} color="success" onDelete={() => removeCouponCode()} />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <hr />
      <Grid container>
        <Grid item xs={10} className={styles.finalpaymentData}>
          <b>Amount Due</b>
        </Grid>
        <Grid item xs={2} className={styles.finalpaymentData}>
          <b>${planPrice}</b>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10} className={styles.finalpaymentData}>
          <b>Discounted Amount </b>
        </Grid>
        <Grid item xs={2} className={styles.finalpaymentData}>
          <b>${discountedAmount}</b>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10} className={styles.finalpaymentData}>
          <b>Total due (After 7-day Trial)</b>
        </Grid>
        <Grid item xs={2} className={styles.finalpaymentData}>
          <b>${planPrice - discountedAmount}</b>
        </Grid>
      </Grid>
      <br />
      <p className={styles.policy}>
        By selecting “Pay Now”, I understand that I am enrolling in a subscription plan and I authorize Empowr to charge my
        payment method each month at the then-current list price (plus applicable taxes) unless I cancel. I can cancel anytime by
        going to my Billing page in the Account Settings.
      </p>
    </>
  );

  return <div>{card}</div>;
};

export default PaymentForm;
