import { TaskAltRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";

const PaymentDoneForm = () => {
  const successCard = (
    <>
      <div
        style={{
          textAlign: "center",
          paddingTop: "70px",
          paddingBottom: "40px",
        }}
      >
        <TaskAltRounded sx={{ color: "#1BC5BD", transform: "scale(5)" }} />
      </div>
      <Typography
        sx={{
          fontSize: "2rem",
          textAlign: "center",
          fontWeight: "500",
          paddingTop: "25px",
        }}
        color="primary"
      >
        Payment Successful
      </Typography>
      <Typography
        sx={{
          fontSize: "1.3rem",
          textAlign: "center",
          fontWeight: "500",
          paddingTop: "40px",
          paddingBottom: "25px",
          width: "80%",
          margin: "auto",
        }}
        color="secondary"
      >
        Thank you for your payment! Your transaction has been successfully processed.
        <br />
        Please check your email for instructions on how to set your password and access your account. 
      </Typography>
      <div
        style={{
          textAlign: "center",
          paddingTop: "75px",
          paddingBottom: "10px",
          width: "80%",
          margin: "auto",
          fontSize: "0.75rem",
        }}
      >
        By clicking the button, you agree to our <a href="/page/termsOfService" target="_blank" rel="noreferrer">Terms of Service</a> and have read and acknowledge
        our{" "}
        <a href="https://www.ai-fluence.com/privacy-policy/" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </div>
    </>
  );
  return <div>{successCard}</div>;
};

export default PaymentDoneForm;
