import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    accessToken: null,
    accesssTokenExpiresAt: 0,
    agencyId: 0,
    clientId: 0,
    userId: 0,
    email: null,
    name: null,
    group: null,
    idToken: null,
    refreshToken: null,
    role: null,
    audienceInsightWarning: true,
    adjustDateWarning: true,
    accessKeyId: null,
    secretKey: null,
    sessionToken: null,
    membershipId: null,
    isImpersonateSession: false,
    oldEmail: null,
  },
  reducers: {
    login: (state, action) => {
      state.loggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.accesssTokenExpiresAt = action.payload.accesssTokenExpiresAt;
      state.agencyId = action.payload.agencyId;
      state.clientId = action.payload.clientId;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.group = action.payload.group;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.role = action.payload.role;
      state.audienceInsightWarning = true;
      state.adjustDateWarning = true;
      state.accessKeyId = action.payload.accessKeyId;
      state.secretKey = action.payload.secretKey;
      state.sessionToken = action.payload.sessionToken;
      state.userId = action.payload.id;
      state.membershipId = action.payload.membershipId;
      state.isLocked = action.payload.isLocked;
      state.freeTrialAccessed = action.payload.freeTrialAccessed;
      state.planStatus = action.payload.planStatus;
      state.freeTrialPopupWarning = action.payload.membershipId === 12 && action.payload.planStatus !== "expired" ? true : false;
      state.trialExpiredPopupWarning = action.payload.planStatus === "expired" ? true : false;
      state.isImpersonateSession = action.payload.impersonateUser || false;
      state.oldEmail = action.payload.oldEmail;
      state.oldRole = action.payload.oldRole;
      state.daysLeft = action.payload.daysLeft;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.accessToken = null;
      state.accesssTokenExpiresAt = 0;
      state.agencyId = 0;
      state.clientId = 0;
      state.email = null;
      state.name = null;
      state.group = null;
      state.idToken = null;
      state.refreshToken = null;
      state.role = 0;
      state.audienceInsightWarning = false;
      state.dummyCampaignWarning = false;
      state.freeTrialPopupWarning = false;
      state.adjustDateWarning = false;
      state.accessKeyId = null;
      state.secretKey = null;
      state.sessionToken = null;
      state.userId = 0;
      state.membershipId = null;
      state.isLocked = null;
      state.freeTrialAccessed = null;
      state.isImpersonateSession = false;
      state.oldEmail = null;
      state.oldRole = null;
      state.daysLeft = null;
    },
    storeAudienceState: (state) => {
      state.audienceInsightWarning = false;
    },
    storeDummyCampaignState: (state) => {
      state.dummyCampaignWarning = false;
    },
    storeFreeTrialPopupState: (state) => {
      state.freeTrialPopupWarning = false;
    },
    storeTrialExpiredPopupState: (state) => {
      state.trialExpiredPopupWarning = false;
    },
    storeAdjustDate: (state) => {
      state.adjustDateWarning = false;
    },
    changeName: (state, action) => {
      state.name = action.payload.name;
    },
    changeBillingAttributes: (state, action) => {
      state.isLocked = action.payload.isLocked;
      state.freeTrialAccessed = action.payload.freeTrialAccessed;
    },
    impersonateUser: (state) => {
      state.isImpersonateSession = true;
    },
    endImpersonationSession: (state) => {
      state.isImpersonateSession = false;
    },
  },
});

export const {
  login,
  logout,
  changeName,
  storeAudienceState,
  storeDummyCampaignState,
  storeAdjustDate,
  changeBillingAttributes,
  impersonateUser,
  endImpersonationSession,
  storeFreeTrialPopupState,
  storeTrialExpiredPopupState
} = UserSlice.actions;

export default UserSlice.reducer;
