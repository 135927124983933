import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { editLinkUrl } from "../../../../services/campaignAnalytics";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";

const CampaignLinksEditDestinationDialog = ({
  urlData,
  setRefresh,
  open,
  setOpen,
}) => {
  const [urlInformation, setUrlInformation] = useState(urlData.urlText);
  const [url, setUrl] = useState(urlData.url);
  const [urlError, setUrlError] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const validateUrl = (value) => {
    if (!value) {
      return "This field is required.";
    }

    const urlPattern =
      /^(http:\/\/|https:\/\/)(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    if (!urlPattern.test(value)) {
      return "Invalid URL.";
    }

    return "";
  };

  const handleSubmit = async () => {
    const response = await editLinkUrl(
      urlData.id,
      urlData.lid,
      urlInformation.trim(),
      url,
      urlData.forAll,
      urlData.listName,
      user,
      dispatch
    );
    setRefresh((refresh) => !refresh);
    setOpen(false);
    if (response.status === true) {
      dispatch(
        notify({
          message: "URL edited successfully",
          type: "success",
        })
      );
    }
    else {
      if (response?.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "auto",
          width: "100%",
        },
      }}
    >
      <DialogTitle>Edit Destination URL</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              label="URL Information"
              type="text"
              value={urlInformation}
              onChange={(e) => setUrlInformation(e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
              required
            />
          </Grid>

          <Grid item md={12}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              label="URL"
              type="url"
              value={url}
              onChange={(e) => {
                const url = e.target.value;
                setUrl(url);
                const urlVallidation = validateUrl(url);
                setUrlError(urlVallidation);
              }}
              error={Boolean(urlError)}
              helperText={urlError}
              fullWidth
              sx={{ mt: 1 }}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          disabled={urlInformation === "" || url === "" || urlError !== ""}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignLinksEditDestinationDialog;
