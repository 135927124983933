import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import BaseLanding from "../../components/landing/BaseLanding";

function Landing(props) {
  const [width, setWidth] = useState(0);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
    if(width < 768) {
      document.body.style.background = "inherit";
      document.body.style.boxShadow = "inherit";
    } else {
      setBackground();
    }
  };

  useEffect(() => {
    setBackground();
    updateWidth();
  }, []);

  const elementRef = useRef(null);

  const setBackground = () => {
      document.body.style.background = "linear-gradient(235.16deg, #19A3C6 -14.93%, rgba(78, 162, 183, 0) 134.24%)";
      document.body.style.boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <div ref={elementRef}>
      <BaseLanding width={width} /> <br />
    </div>
  );
}

export default Landing;
