import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CampaignList from "../../components/campaign/campaignList/CampaignList";
import BlockLoginAccess from "../../components/common/BlockLoginAccess";

function Campaign() {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <div ref={elementRef}>
      {width > 768 && <CampaignList />} {width <= 768 && <BlockLoginAccess openDialog={true} />}
    </div>
  );
}

export default Campaign;
