import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import DateRangeDropdown from "./common/HomeDateRangeDropdown";

const MetricsCard = ({
  icon,
  title,
  data,
  defaultDateRange = "Last 6 Months", 
  onDateChange,
  showDateRange = false,
  labels = {
    total: 'Total',
    active: 'Active',
    contracted: 'Contracted'
  }
}) => {
  return (
    <Card sx={{ backgroundColor: '#fff', borderRadius: '10px', boxShadow: 'none', padding: '16px' }}>
      <CardContent sx={{ padding: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                backgroundColor: '#FFF3E0',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                marginRight: '12px'
              }}
            >
              {icon}
            </Box>
            <Typography variant="h6" fontWeight="500">{title}</Typography>
          </Box>
          {showDateRange && (
            <DateRangeDropdown
              defaultDateRange={defaultDateRange}
              onDateChange={onDateChange}  // Date change will be handled here
            />
          )}
        </Box>

        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Box textAlign="center">
            <Typography variant="h4" fontWeight="600" sx={{ color: '#45464E' }}>{data.total}</Typography>
            <Typography variant="subtitle2" fontWeight="500" color="#6C757D">{labels.total}</Typography>
          </Box>
          {data.active !== undefined && (
            <Box textAlign="center">
              <Typography variant="h4" fontWeight="600" sx={{ color: '#45464E' }}>{data.active}</Typography>
              <Typography variant="subtitle2" fontWeight="500" color="#6C757D">{labels.active}</Typography>
            </Box>
          )}
          {data.contracted !== undefined && (
            <Box textAlign="center">
              <Typography variant="h4" fontWeight="600" sx={{ color: '#45464E' }}>{data.contracted}</Typography>
              <Typography variant="subtitle2" fontWeight="500" color="#6C757D">{labels.contracted}</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MetricsCard;
