import { Grid, Card } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import CampaignGaFilter from "./CampaignGaFilter";
import AudienceProgressChart from "../../../common/AudienceProgressChart";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import {
  sendGoogleXlsx,
  getGaSummary,
} from "../../../../services/campaignAnalytics";
import { codeToCountry, countryCodes } from "../../../../utility/countryCodes";
import {
  snapshotHeaderIcons,
  textColor,
  otherIcons,
} from "../../../../utility/plaformIcons";
import Loader from "../../../common/loader";
import { useLocation } from "react-router-dom";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CampaignGaGraphs from "./CampaignGaGraphs";
import { notify } from "../../../../redux/features/system/systemAlert";
import { getCampaignName } from "../../../../utility/sessionData";
import CampaignGaLinksTable from "./CampaignGaLinksTable";
import CampaignGaConversionTable from "./CampaignGaConversionTable";
import DummyCampaign from "../../../common/DummyCampaign";

const CampaignGaSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const [selectedPlatforms, setSelectedPlatforms] = useState(
    Object.keys(snapshotHeaderIcons)
  );
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")
  ); //select campaign duration for 7 days
  const [selectedEndDate, setSelectedEndDate] = useState(
    getFormattedDate(moment().toDate(), "YYYY-MM-DD")
  );
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [value, setValue] = useState(0);
  const [filterStatus, setFilterStatus] = useState(false);
  const [linkClicksY, setLinkClicksY] = useState([]);
  const [linkClicksX, setLinkClicksX] = useState([]);
  const [conversionsData, setConversionsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [worldData, setWorldData] = useState([]);
  const [referresData, setReferresData] = useState([]);
  const [campaignName, setCampaignName] = React.useState(getCampaignName);
  const [bounceRate, setBounceRate] = useState(0);
  const universal = params.get("universal")
  const exportXlsx = async () => {
    const exportResponse = await sendGoogleXlsx(
      selectedGroups,
      selectedInfluencers,
      selectedPlatforms,
      campaignName,
      getFormattedDate(selectedStartDate, "YYYY-MM-DD"),
      getFormattedDate(selectedEndDate, "YYYY-MM-DD"),
      user,
      dispatch,
      "google/analysis/sendxlsx"
    );

    if (exportResponse.status) {
      dispatch(
        notify({
          message: "Information will be sent to you e-mail address!",
          type: "success",
        })
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setFilterStatus(false)
    } else {
      setFilterStatus(true)
    }
  };

  const createClickGraph = (gaSummary) => {
    const acceptedValues = gaSummary['response']["daily"];
    setLinkClicksX([]);
    setLinkClicksY([]);
    setBounceRate(gaSummary['response']['bouncerate'][0]['value'])
    if (acceptedValues != null) {
      const keys = acceptedValues?.map((value) => value.key);
      const values = acceptedValues?.map((value) => value.value);
      setLinkClicksX(keys);
      setLinkClicksY(values);
    }
  };

  const createConversionsGraph = (conversionSummary) => {
    setConversionsData([]);
    if (conversionSummary['response'] != null) {
      const convValuesAreZero = Object.values(conversionSummary['response']).every(value => value === 0);
      const conversions = [];
      if (!convValuesAreZero) {
        conversionSummary['response'].forEach((val, key) => {
          conversions.push({
            value: conversionSummary['response'][key]["value"],
            name: conversionSummary['response'][key]["key"],
          });
        });
      }
      setConversionsData(conversions);
    }
  };


  const createCountryGraph = (countryData) => {
    setLocationData([]);
    setWorldData([]);
    const locationData = restructureDataForMap(countryData, codeToCountry);
    const locations = restructureDataForMapLoc(countryData, countryCodes);
    const locationDataForTable = remapLocationDataForTable(
      locations
    );
    setLocationData(locationDataForTable);
    setWorldData(locationData);
  };

  const restructureDataForMap = (data, codeToCountry) => {
    if (!data) {
      return [];
    }
    return data?.map((item) => {
      let country = item.value;
      if (codeToCountry[item.value]) {
        country = codeToCountry[item.value].code;
      }

      return {
        country: country,
        value: item.clicks,
        percentage: item.percentage + "%",
      };
    });
  };

  const restructureDataForMapLoc = (data, countryCodes) => {
    if (!data) {
      return [];
    }
    return data?.map((item) => {
      let country = item.value;
      return {
        country: country,
        value: item.clicks,
        percentage: item.percentage + "%",
      };
    });
  };


  const remapLocationDataForTable = (locationData) => {
    if (!locationData) {
      return [];
    }
    let countries = [];
    locationData?.map((item) => {
      const country = item.country;
      countries.push({
        title: country,
        clicks: item.value,
        percentage: item.percentage,
      });

    });
    return countries;
  };

  const createReferrersGraph = (referrersData) => {
    setReferresData([]);
    const referrersArray = [];
    if (referrersData !== null && typeof referrersData === 'object') {
      const keys = Object.keys(referrersData);
      let total = 0;
      keys?.map((key) => (total += referrersData[key].value));
      keys.forEach((key) => {
        referrersArray.push({
          title: referrersData[key].key,
          value: referrersData[key].value,
          percentage: Math.floor((referrersData[key].value / total) * 100),
        });
      });
      referrersArray.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
      setReferresData(referrersArray);
    }
  };


  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };


  useEffect(() => {
    setCampaignName(getCampaignName)
    updateWidth();
    initialLoad();
  }, [params.get("id")]);

  const initialLoad = async () => {
    createGraphs([], [], [], selectedStartDate, selectedEndDate);
  };

  const createGraphs = async (groups, infs, platforms, sDate, eDate) => {
    setLoading(true);
    const startDate = getFormattedDate(sDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(eDate, "YYYY-MM-DD");
    const gaSummary = await getGaSummary(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      params.get("id"),
      user,
      dispatch,
      "google/analysis/summary"
    );
    const conversionSummary = await getGaSummary(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      params.get("id"),
      user,
      dispatch,
      "google/analysis/devices"
    );
    const countrySummary = await getGaSummary(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      params.get("id"),
      user,
      dispatch,
      "google/analysis/countries"
    );

    const referrersSummary = await getGaSummary(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      params.get("id"),
      user,
      dispatch,
      "google/analysis/referrers"
    );


    setLoading(false);
    if (
      gaSummary.status === true
    ) {
      createClickGraph(gaSummary);
      createConversionsGraph(conversionSummary);
      createCountryGraph(countrySummary.response);
      createReferrersGraph(referrersSummary.response);

    }
  };

  const sendFilterChange = (event) => {
    setSelectedStartDate(event.startDate);
    setSelectedEndDate(event.endDate);
    setSelectedGroups(event.selectedGroups?.map((group) => group.name.replace(/ /g, '_').toLowerCase()));
    setSelectedInfluencers(event.selectedInfluencers?.map((inf) => inf.Iid.toString()));
    setSelectedPlatforms(event.selectedPlatforms);
    createGraphs(event.selectedGroups?.map((group) => group.name.replace(/ /g, '_').toLowerCase()), event.selectedInfluencers?.map((inf) => inf.Iid.toString()), event.selectedPlatforms, event.startDate, event.endDate);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <div>
      <DummyCampaign universal={universal} />

      <Grid container spacing={2} ref={elementRef}>
        <Grid item xs={12}>
          <CampaignGaFilter
            allSelectedPlatforms={selectedPlatforms}
            allSelectedGroups={selectedGroups}
            allSelectedInfluencers={selectedInfluencers}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            sendFilterChange={sendFilterChange}
            filterStatus={filterStatus}
          />
          <br />
        </Grid>    </Grid>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ bgcolor: "background.paper" }}>

            <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Tab
                icon={otherIcons["insights"]}
                sx={{
                  ...textColor["twitter"],
                  "&.Mui-selected": {
                    color: textColor["twitter"], // Change to desired color for selected state
                  },
                }}
                label={"Metrics"}
                value={0}
              />
              <Tab disabled={true}
                label={"Data is based on UTM Campaign: " + campaignName.replace(/ /g, '_').toLowerCase()}
                sx={{
                  marginLeft: 'auto',
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                  overflow: 'hidden', // Hide overflow
                  textOverflow: 'ellipsis', // Add ellipsis (...) for overflow
                }}
              />
            </TabList>

          </Box>
        </TabContext>
      </Box>

      <br />
      <Grid item xs={12}>
        <div>
          {loading ? (
            <Loader />
          ) : dataFound ? (

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CampaignGaGraphs
                  linkClicksY={linkClicksY}
                  linkClicksX={linkClicksX}
                  conversionsData={conversionsData}
                  referresData={referresData}
                  bounceRate={bounceRate}
                /></Grid>

              <Grid item xs={12}>
                <Card style={{ padding: 20 }}>
                  <h4 style={{ paddingLeft: "20px" }}>
                    <b className={styles.title}>Locations</b>
                  </h4>{" "}
                  {locationData?.length > 0 && worldData?.length > 0 ? (
                    <AudienceProgressChart
                      data={locationData}
                      mapData={worldData}
                      chartTitle="Countries"
                      parentStyle={styles}
                      country={true}
                      backgroundColor="white"
                      expandButtonVisible={true}
                      tooltip={false}
                    />
                  ) : (
                    <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      No Graph found
                    </p>
                  )}
                </Card>
              </Grid>
                <CampaignGaLinksTable
                  groups={selectedGroups}
                  infs={selectedInfluencers}
                  platforms={selectedPlatforms}
                  campaignName={campaignName}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  exportXlsx={exportXlsx}
                  cid= {params.get("id")}
                />
              <CampaignGaConversionTable
                  groups={selectedGroups}
                  infs={selectedInfluencers}
                  platforms={selectedPlatforms}
                  campaignName={campaignName}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  exportXlsx={exportXlsx}
                  cid= {params.get("id")}
                />
              
            </Grid>
          ) : (
            <>
              <div className={styles.container}>
                <div className={styles.child}>
                  {" "}
                  No links added on this campaign
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default CampaignGaSnapshot;
