import React, { useCallback, useEffect, useState } from "react";
import MyPaper from "../../../../utility/oryxUtils/MyPaper";
import config from "../../../../config/main.json"
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoToolTip } from "../../../common/toolTip";

const svg_icon = `${config.BASE_IMAGE_URL}Mask Group 1.svg`;
const svg_icon2 = `${config.BASE_IMAGE_URL}Group 26779.svg`;
const add_to_list = `${config.BASE_IMAGE_URL}add-to-list.svg`;
const Note_Add = `${config.BASE_IMAGE_URL}Note Add.svg`;
const Search_alt_light = `${config.BASE_IMAGE_URL}Search_alt_light.svg`;


const EmptyCampaign = ({ overallSummary }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const campaignId = params.get("id");
    const universal = params.get("universal");

    const buttonStyle = {
        backgroundColor: "#4285F4",
        borderRadius: "18px",
        width: "180px"
    };

    const iconStyle = {
        height: "20px",
        paddingRight: "5px"
    }

    const paraStyling = {
        margin: "10px 0 0",
        textAlign: "center",
        fontSize: "12px",
        width: "240px"
    }

    const boxStyling = {
        width: "310px",
        height: "110px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }

    const handleAddPublicationButton = () => {
        navigate(`/app/campaigns/publications/published/create?id=${campaignId}&universal=${universal}`, { state: { data: overallSummary.platforms } });
    };

    const handleCreatelistButton = () => {
        navigate(`/app/campaigns/influencers/groups/create?id=${campaignId}&universal=${universal}`);
    }

    const handleDiscoverInfluencerButton = () => {
        navigate(`/app/discovery`);
    }

    const handleAnalyzeInfluencerButton = () => {
        navigate(`/app/discovery/analyze/influencer`);
    }

    return (
        <MyPaper>
            <div style={{ textAlign: "center", width: "50vw", margin: "0 auto", height: "auto", paddingTop: "20px" }}>
                <div style={{ position: "relative", display: "inline-block", paddingBottom: "10px" }}>
                    <img
                        src={svg_icon2}
                        alt="Icon"
                        style={{
                            position: "absolute",
                            top: "-15px",
                            left: "18px",
                            zIndex: 2,
                        }}
                    />
                    <img
                        src={svg_icon}
                        alt="Icon"
                        style={{
                            position: "relative",
                            zIndex: 1,
                            width: '120px',
                            height: '120px'
                        }}
                    />
                </div>
                <p style={{ color: "#34343F", fontSize: "18px" }}><b>Nothing in the Campaign Yet</b></p>
                <p style={{ width: "250px", textAlign: "center", margin: "0 auto", color: "#464665", fontSize: "14px" }}>
                    Start by clicking one of the options below
                </p>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 310px)", gridGap: "10" }}>
                        <div style={boxStyling}>
                            <Button
                                variant="contained"
                                style={buttonStyle}
                                onClick={() => handleCreatelistButton()}
                            >
                                <img
                                    src={add_to_list}
                                    alt="Icon"
                                    style={iconStyle}
                                />
                                Create a Group
                            </Button>
                            <p style={paraStyling}>
                                Create a influencer group to start shortlisting your influencers.
                                <span style={{ marginLeft: "3px" }}>
                                    <InfoToolTip text="Create a group of influencers to easily manage and collaborate with multiple influencers for your campaigns." size="12px" ></InfoToolTip>
                                </span>
                            </p>
                        </div>
                        <div style={boxStyling}>
                            <Button
                                variant="contained"
                                style={buttonStyle}
                                onClick={() => handleAddPublicationButton()}
                            >
                                <img
                                    src={Note_Add}
                                    alt="Icon"
                                    style={iconStyle}
                                />
                                Add a Publication
                            </Button>
                            <p style={paraStyling}>
                                Start tracking performance of a publication already live.
                                <span style={{ marginLeft: "3px" }}>
                                    <InfoToolTip text="Add a publication to track and analyze its performance within your campaign." size="12px" ></InfoToolTip>
                                </span>
                            </p>
                        </div>
                        <div style={boxStyling}>
                            <Button
                                variant="contained"
                                style={buttonStyle}
                                onClick={() => handleDiscoverInfluencerButton()}
                            >
                                <img
                                    src={Search_alt_light}
                                    alt="Icon"
                                    style={iconStyle}
                                />
                                Discover Influencer
                            </Button>
                            <p style={paraStyling}>
                                Search for and identify influencers for your campaign.
                                <span style={{ marginLeft: "3px" }}>
                                    <InfoToolTip text="Find and explore influencers who align with your brand and campaign goals." size="12px" ></InfoToolTip>
                                </span>
                            </p>
                        </div>
                        <div style={boxStyling}>
                            <Button
                                variant="contained"
                                style={buttonStyle}
                                onClick={() => handleAnalyzeInfluencerButton()}
                            >
                                <img
                                    src={Search_alt_light}
                                    alt="Icon"
                                    style={iconStyle}
                                />
                                Analyze Influencer
                            </Button>
                            <p style={paraStyling}>
                                Evaluate influencer performance and insights.
                                <span style={{ marginLeft: "3px" }}>
                                    <InfoToolTip text="Evaluate influencers based on their performance, audience demographics, and engagement to make informed decisions for your campaign." size="12px" ></InfoToolTip>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </MyPaper>
    )
}


export default EmptyCampaign;