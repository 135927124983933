import { Autocomplete, Avatar, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import styles from "../../../../../styles/internal/Campaign/CampaignList.module.css";
import { snapshotHeaderIcons, textColor } from "../../../../../utility/plaformIcons";
import configs from "../../../../../config/main.json";
import { getAllGroupsListForCampaign } from "../../../../../services/group";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { logout } from "../../../../../redux/features/user/userSlice";
import { notify } from "../../../../../redux/features/system/systemAlert";
import { inviteDiscoveryUser, inviteDiscoveryUserViaList } from "../../../../../services/discovery";
import { Button } from "react-bootstrap";
import { stringAvatar } from "../../campaignDrafts/CommonAssets";

const SendInviteDialog = ({ influencerAccount, sendInviteDialog, closeSendInviteDialog, campaignId, campaignName }) => {
  const scroll = "paper";
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [noOfAssignedGroups, setNoOfAssignedGroups] = React.useState(0);
  const [existingGroupList, setExistingGroupList] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  React.useEffect(() => {
    setNoOfAssignedGroups(influencerAccount.listName.split(",")?.length);
    getGroupList("all", influencerAccount.listName.split(",")?.length);
  }, []);
  const handleClose = () => {
    closeSendInviteDialog(false);
  };

  const getGroupList = async (platforms, groupCount) => {
    const groups = await getAllGroupsListForCampaign(campaignId, platforms, user, dispatch);
    if (groups.status === true) {
      if (groupCount == 1) {
        groups.response.forEach((value) => {
          if (value.name === influencerAccount.listName && value.platform === influencerAccount.platform[0]) {
            setSelectedGroup(value);
          }
        });
      } else {
        const listsAssigned = influencerAccount.listName.split(",");
        const arrayOfGroups = [];
        for (const list of listsAssigned) {
          for (const value of groups.response) {
            if (list === value.name) {
              arrayOfGroups.push(value);
            }
          }
        }
        setExistingGroupList(arrayOfGroups);
      }
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const inviteInfluencer = async () => {
    const inviteRequest = {
      campaign_id: parseInt(campaignId),
      audience_id: selectedGroup.id,
      influencer_id: [influencerAccount.iid],
      cname: campaignName,
      medias: influencerAccount.platform[0],
      notificationMetadata: {
        userId: influencerAccount.iid.toString(),
        sourcePlatform: configs.PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "2",
        notificationCategoryId: "5",
        generatedBy: user.email,
        priority: "medium",
        consumedBy: "gazelle",
      },
    };
    const response = await inviteDiscoveryUserViaList(inviteRequest, user, dispatch);
    if (response.status === true) {
      dispatch(
        notify({
          message: `${influencerAccount.name} has been added successfully invited on email ${influencerAccount.email}`,
          type: "success",
        })
      );
      handleClose();
    }
  };
  return (
    <Dialog
      open={sendInviteDialog}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={3}>
          <Grid item >
            <Avatar
              variant="circle"
              src={influencerAccount.photoUrl || ""}
              {...stringAvatar(influencerAccount.name)}
              onError={(e) => {
                e.target.src = configs.PROFILE_IMG;
              }}
            />
          </Grid>
          <Grid item>
            <Grid spacing={1}>
              <Grid item className={styles.profileHeading}>
                {influencerAccount.name}
              </Grid>
              <Grid item className={styles.subbodycell}>
                @ {influencerAccount.userName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item >
            <Typography sx={textColor[influencerAccount?.platform[0].toLowerCase()]}>
              {snapshotHeaderIcons[influencerAccount?.platform[0].toLowerCase()]}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {noOfAssignedGroups > 1 && (
          <Autocomplete
            id="size-small-filled"
            aria-required
            required
            options={existingGroupList}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Group Name"
                placeholder="<Choose from drop down the groups you want to invite this influencer>"
                required
              />
            )}
            defaultValue={selectedGroup}
            value={selectedGroup}
            onChange={(event, newValue) => {
              setSelectedGroup(newValue);
            }}
          />
        )}
        <div className={styles.message}>
          <p>
            Are you sure you want to invite <b>{influencerAccount.name}</b> on email <b>{influencerAccount.email}</b>?
          </p>
          <div className={styles.buttonContainer}>
            <Button className={`${styles.button} ${styles.leftButton}`} onClick={handleClose}>
              No, not now
            </Button>
            <Button
              className={`${styles.button2} ${styles.centerButton}`}
              disabled={influencerAccount.email === ""}
              onClick={() => inviteInfluencer()}
            >
              I confirm
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SendInviteDialog;
