import {
  AccessAlarm,
  AllInclusive,
  CalendarMonth,
  FormatListBulleted,
  MilitaryTech,
  People,
  Person,
  Person2,
  PhotoSizeSelectActual,
  PostAdd,
  Tag,
  Update,
  TrendingUp,
  EmailOutlined,
  Refresh,
  ExpandMore,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Icon,
  LinearProgress,
  linearProgressClasses,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Widget from "../../../common/widget";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import config from "../../../../config/main.json"
import {
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";
import PropTypes from "prop-types";
import { convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import React, { useState } from "react";
import DOMPurify from "dompurify";

const SourceIconURL = `${config.BASE_IMAGE_URL}source.svg`;

const CustomLinearProgress = styled(LinearProgress)(({ theme, barColor }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barColor,
  },
}));

const CampaignWidget = ({
  overallSummary,
  goalsSnapshot,
  recentUpdates,
  emptyCampaign,
}) => {
  React.useEffect(() => { }, [overallSummary, goalsSnapshot, recentUpdates]);
  const {
    conversionsAchieved,
    conversionsAdded,
    engagementAchieved,
    engagementAdded,
    reachAchieved,
    reachAdded,
  } = goalsSnapshot;
  const [showGoalGraph, setGoalGraph] = useState(
    conversionsAdded > 0 || engagementAdded > 0 || reachAdded > 0 ? true : false
  );
  const widgetArray = [
    {
      icon: <Person fontSize="small" />,
      value: overallSummary.influencers,
      name: "Total Accounts",
      info: false,
    },
    {
      icon: <PhotoSizeSelectActual fontSize="small" />,
      value: overallSummary.totalPosts,
      name: "Total Publications",
      info: false,
    },
    {
      icon: <AllInclusive fontSize="small" />,
      value: convertToInternationalNumberSystem(
        overallSummary.totalEngagements
      ),
      name: "Total engagement",
      info: true,
      text: "Total engagement on all publications across all social networks used in this campaign by all the influencers.",
    },
    {
      icon: <People fontSize="small" />,
      value: convertToInternationalNumberSystem(overallSummary.totalReach),
      name: "Total Reach",
      info: false,
    },

    {
      icon: <People fontSize="small" />,
      value: overallSummary.pendingPostApproval,
      name: "Publications to Review",
      info: false,
    },
    {
      icon: <AllInclusive fontSize="small" />,
      value: `${overallSummary.averageEngagementRate.toFixed(2)}%`,
      name: "Avg. ER%",
      info: true,
      text: "Average engagement rate of all publications in this campaign.",
    },

    {
      icon: <AccessAlarm fontSize="small" />,
      value: overallSummary.daysLeft,
      name: "Days Left",
      info: false,
    },
    {
      icon: <TrendingUp fontSize="small" />,
      value:
        Math.floor(overallSummary.averageEngagement).toString()?.length >= 4
          ? convertToInternationalNumberSystem(overallSummary.averageEngagement)
          : `${overallSummary.averageEngagement.toFixed(2)}`,
      name: "Avg engagement",
      info: false,
    },
    {
      icon: <People fontSize="small" />,
      value:
        Math.floor(overallSummary.averageReach).toString()?.length >= 4
          ? convertToInternationalNumberSystem(overallSummary.averageReach)
          : `${overallSummary.averageReach.toFixed(2)}`,
      name: "Avg Reach",
      info: false,
    },
  ];
  const summaryArray = [
    {
      icon: <img src={SourceIconURL} alt="Source Icon" style={{ width: "20px", height: "20px" }} />,
      value: (
        <Grid container spacing={1}>
          {overallSummary.platforms?.map((platform) => {
            if (platform !== "total") {
              return (
                <Grid item xs={2}>
                  <Typography fontSize={textColor[platform.toLowerCase()]}>
                    {snapshotHeaderIcons[platform.toLowerCase()]}
                  </Typography>
                </Grid>
              );
            }
          })}
        </Grid>
      ),
      name: "Channels Used",
      info: false,
    },
    {
      icon: <Update />,
      value: overallSummary.duration,
      name: "Duration",
      info: false,
    },
    {
      icon: <MilitaryTech />,
      value: overallSummary.brand,
      name: "Brand",
      info: false,
    },
    {
      icon: <FormatListBulleted />,
      value: overallSummary.groupCount,
      name: "No. of Groups",
      info: false,
    },
    {
      icon: <Tag />,
      value: (
        <Grid container spacing={1}>
          {overallSummary.campaignKeywords ? (
            (() => {
              const keywords = overallSummary.campaignKeywords.split(",");
              const displayedKeywords = keywords.slice(0, 4);
              const remainingCount = keywords?.length - 4;
              const remainingKeywords = keywords.slice(4).join(", ");
              return (
                <>
                  {displayedKeywords?.map((keyword, index) => (
                    <Grid item key={index}>
                      <Chip label={keyword} />
                    </Grid>
                  ))}
                  {remainingCount > 0 && (
                    <Tooltip title={remainingKeywords}>
                      <Grid item>
                        <Chip label={`+${remainingCount}`} />
                      </Grid>
                    </Tooltip>
                  )}
                </>
              );
            })()
          ) : (
            <Grid item>
              <Typography>No keywords added</Typography>
            </Grid>
          )}
        </Grid>
      ),
      name: "Campaign Keywords",
      info: false,
    },
    {
      icon: <Person2 />,
      // value: overallSummary.accountManager?.name || "Not assigned yet",
      value: (
        <Grid container spacing={1}>
          {overallSummary?.accountManager?.name !== "" ? (
            <>
              <Grid item>
                <Typography>{overallSummary.accountManager?.name}</Typography>
              </Grid>
              <Grid item>
                <Box
                  component={"a"}
                  href={`mailto:${overallSummary.accountManager?.email}}`}
                >
                  {" "}
                  <EmailOutlined style={{ height: "18px" }} />{" "}
                </Box>
              </Grid>
            </>
          ) : (
            <Grid item>
              {" "}
              <Typography>Not assigned yet</Typography>{" "}
            </Grid>
          )}
        </Grid>
      ),
      name: "Account Manager",
      info: false,
    },
  ];

  const GoalItem = ({ label, achieved, added, barColor }) => {
    let percentage = 0;
    added > 0 && achieved > 0
      ? (percentage = ((achieved / added) * 100).toFixed(2))
      : (percentage = "-");
    return {
      value: (
        <CustomLinearProgress
          variant="determinate"
          value={percentage > 0 ? percentage < 100 ? Number(percentage) : 100 : 0}
          barColor={barColor}
        />
      ),
      name: (
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {label}
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {achieved > 0
              ? convertToInternationalNumberSystem(achieved)
              : achieved}{" "}
            / {added > 0 ? convertToInternationalNumberSystem(added) : added}
            {percentage != "-" && <>({percentage}%)</>}
          </Grid>
        </Grid>
      ),
      info: false,
    };
  };
  const goalArray = [
    GoalItem({
      label: "Reach",
      achieved: reachAchieved || "-",
      added: reachAdded || "-",
      barColor: "#54D14D",
    }),
    GoalItem({
      label: "Engagement",
      achieved: engagementAchieved || "-",
      added: engagementAdded || "-",
      barColor: "#EF7E5D",
    }),
    GoalItem({
      label: "Conversions",
      achieved: conversionsAchieved || "-",
      added: conversionsAdded || "-",
      barColor: "#35C2FD",
    }),
  ];

  const getDataFromUpdatesArray = () => {
    const array = [];
    recentUpdates?.slice(0, 3).forEach((item) => {
      array.push({
        icon: <Refresh />,
        value: item.notificationcontent.slice(0, 50),
        datetime: new Date(item.datetime).toDateString(),
        name: item.notificationcategoryname,
        fullContent: item.notificationcontent,
        info: false,
      });
    });
    return array;
  };

  return !emptyCampaign ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            {widgetArray?.map((widget) => (
              <Grid item key={widget} fontSize={12} xs={3.8}>
                <Widget widget={widget} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            background: "white",
            borderRadius: "5px",
            paddingTop: "20px",
            marginTop: "15px",
          }}
        >
          <Grid container>
            {summaryArray?.map((summary) => (
              <Grid item xs={12} key={summary.name}>
                <Grid container style={{ paddingBottom: "15px" }}>
                  <Grid item xs={6} className={styles.heading}>
                    <b>
                      {summary.icon} {summary.name}
                    </b>
                  </Grid>
                  <Grid item xs={6} className={styles.body}>
                    {summary.value}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {showGoalGraph && (
          <Grid
            item
            xs={6.5}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
              paddingTop: "20px",
              marginLeft: "40px",
              marginTop: "65px",
            }}
          >
            <Grid container>
              <Typography sx={{ fontSize: "18px" }}>
                <b>Campaign Goals</b>
              </Typography>
              {goalArray?.map((summary) => (
                <Grid
                  item
                  xs={12}
                  key={summary.name}
                  style={{
                    background: "white",
                    padding: "20px",
                    marginTop: "20px",
                    paddingBottom: "20px",
                    marginRight: "40px",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} className={styles.heading}>
                      <b>{summary.name}</b>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingTop: "10px" }}
                      className={styles.body}
                    >
                      {summary.value}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        <Grid
          item
          xs={showGoalGraph ? 5 : 12}
          style={{
            background: "#D9D9D9",
            borderRadius: "5px",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginLeft: showGoalGraph ? "10px" : "40px",
            marginTop: "65px",
          }}
        >
          <Grid container>
            <Accordion defaultExpanded sx={{ width: "95%" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: "18px" }}>
                  <b>Recent Updates</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {getDataFromUpdatesArray()?.length > 0 ? (
                  getDataFromUpdatesArray()?.map((summary, index) => (
                    <Grid
                      item
                      xs={12}
                      key={summary.datetime}
                      style={{
                        background: "white",
                        padding: "10px",
                        marginRight: "20px",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        borderBottom: index !== getDataFromUpdatesArray().length - 1 ? "1px dotted #ccc" : "none", // Dotted line between items
                      }}
                    >
                      <Grid container>
                        <Grid item xs={1} className={styles.heading}>
                          <b>{summary.icon}</b>
                        </Grid>
                        <Grid item xs={6} className={styles.heading}>
                          <b>{summary.name}</b>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          className={styles.heading}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontWeight: "normal", 
                          }}
                        >
                          {summary.datetime}
                        </Grid>
                        <Grid item xs={1} className={styles.body}></Grid>
                        <Tooltip title={summary.fullContent}>
                          <Grid
                            item
                            xs={11}
                            className={styles.body}
                            style={{ fontSize: "12px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(summary.value),
                              }}
                            />
                          </Grid>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "3%",
                    }}
                  >
                    No recent updates
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : null;
};
CampaignWidget.propTypes = {
  overallSummary: PropTypes.object.isRequired,
};
export default CampaignWidget;
