import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations";
import { FilterList } from "@mui/icons-material";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import PlatformSelectionBox from "../../../common/platformSelectionBox";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import InfluencerDropdown from "../../../common/influencerDropdown";
import GroupDropdown from "../../../common/groupDropdown";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import { getAllInfluencerListForCampaign } from "../../../../services/influencer";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";

const AnalyticsFilter = ({
  recieveSelectedValues,
  selectedStartDate,
  selectedEndDate,
  breakdown,
  platformIds,
  selectedGroupsData,
  selectedInfluencersData,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const breakDownArray = ["day", "week", "month"];

  const [startDate, setStartDate] = React.useState(selectedStartDate);
  const [endDate, setEndDate] = React.useState(selectedEndDate);
  const [selectedBreakdown, setSelectedBreakdown] = React.useState(breakdown);
  const [groupList, setGroupList] = React.useState([]);
  const [influencerList, setInfluencerList] = React.useState([]);
  const [filter, setFilter] = React.useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState(platformIds);
  const [buttonColor, setButtonColor] = React.useState("grey");
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = React.useState([]);

  const selectBreakdown = (event) => {
    const { target: { value } } = event;
    setSelectedBreakdown(value);
    changeColor();
  };

  const addFilters = () => {
    setFilter(!filter);
    changeColor();
  };

  const sendSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
    changeColor();
  };

  const sendSelectedPlatforms = (event) => {
    setSelectedPlatforms(event);
    if (event?.length > 0) {
      getGroupList(event);
    } else {
      getGroupList("all");
    }
    changeColor();
  };

  //change button color on any filter change
  const changeColor = () => {
    setButtonColor(isFilterApplied() ? "#007dff" : "grey");
  };

  const sendSelectedValues = (event) => {
    recieveSelectedValues({
      startDate,
      endDate,
      selectedPlatforms,
      selectedBreakdown,
      selectedGroups: event.groups || selectedGroups,
      selectedInfluencers: event.influencers || selectedInfluencers,
    });
  };

  React.useEffect(() => {
    if (selectedGroupsData?.length > 0 && selectedGroupsData[0] !== -1) {
      setSelectedGroups(selectedGroupsData);
      addFilters();
    }
    if (selectedInfluencersData?.length > 0 && selectedInfluencersData[0] !== -1) {
      setSelectedInfluencers(selectedInfluencersData);
      addFilters();
    }
    getGroupList("all");
  }, []);

  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(params.get("id"), platforms, user, dispatch);

    if (groups.status === true) {
      const ids = groups.response?.map((group) => group.id);
      getInfluencerList(ids);
      setGroupList(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const getInfluencerList = async (ids) => {
    const influencers = await getAllInfluencerListForCampaign(ids, "all", user, dispatch);
    if (influencers.status === true) {
      setInfluencerList(influencers.response);
    }
  };

  const sendSelectedGroups = (event) => {
    const ids = [];
    if (event?.length > 0) {
      event.forEach((group) => {
        ids.push(group.id);
      });
      getInfluencerList(ids);
    } else {
      getGroupList("all");
    }
    setSelectedGroups(event);
    sendSelectedValues({groups: event});
    changeColor();
  };

  const sendSelectedInfluencer = (event) => {
    setSelectedInfluencers(event);
    sendSelectedValues({influencers: event});
    changeColor();
  };

  const isFilterApplied = () => {
    return (
      startDate !== selectedStartDate ||
      endDate !== selectedEndDate ||
      selectedPlatforms?.length !== platformIds?.length ||
      selectedBreakdown !== breakdown ||
      selectedGroups?.length > 0 ||
      selectedInfluencers?.length > 0
    );
  };

  return (
    <Box m={3}>
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid container item xs={12} justifyContent="flex-end" spacing={1}>
        <Grid item>
           {/* Common component for date range picker - sendSelectedDates: send to parent component */}
          <DateRangeCustomPicker sendSelectedDates={sendSelectedDates} parentStartDate={startDate} parentEndDate={endDate} needRange={true} />
        </Grid>
        <Grid item xs={4} sx={{ position: 'relative', zIndex: 0 }}>
            {/* Common component for platform select - sendSelectedPlatforms: send to parent component */}
          <PlatformSelectionBox sendSelectedPlatforms={sendSelectedPlatforms} parentPlatforms={selectedPlatforms} />
        </Grid>
        <Grid item>
          {/* Component for selecting breakdown - did not make component component because was not used in other places. If used in future make this common component */}
          <FormControl size="small">
            <InputLabel id="demo-simple-select-label">Breakdown</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedBreakdown}
              onChange={selectBreakdown}
              label="Breakdown"
              sx={{
                minWidth: "6vw",
                marginRight: "5px",
                borderRadius: "10px",
              }}
            >
              {breakDownArray.map((filter) => (
                <MenuItem value={filter} key={filter}>
                  {capitalizeFirstLetter(filter)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
              borderRadius: "8px",
            }}
            onClick={sendSelectedValues}
          >
            Go
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={addFilters}
            style={{
              background: buttonColor,
              borderRadius: "12px",
              width: '100%'  
            }}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <FilterList sx={{ fontSize: "small" }} /> &nbsp;
            <span>FILTERS</span>
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <br />
    <Grid container spacing={2} justifyContent="flex-end">
      {filter && (
        <>
          {/* Common component for group select - sendSelectedGroups: send to parent component */}
          <Grid item xs={2.5}>
            <GroupDropdown groupList={groupList} sendSelectedGroups={sendSelectedGroups} parentSelectedGroup={selectedGroups} />
          </Grid>
          {/* Common component for influencer select - sendSelectedInfluencer: send to parent component */}
          <Grid item xs={2.5}>
            <InfluencerDropdown influencerList={influencerList} sendSelectedInfluencer={sendSelectedInfluencer} parentSelectedInfluencer={selectedInfluencers} />
          </Grid>
        </>
      )}
    </Grid>
  </Box>
  );
};

AnalyticsFilter.propTypes = {
  recieveSelectedValues: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  breakdown: PropTypes.string.isRequired,
  platformIds: PropTypes.array.isRequired,
  selectedGroupsData: PropTypes.array.isRequired,
  selectedInfluencersData: PropTypes.array.isRequired,
};

export default AnalyticsFilter;