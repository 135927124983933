import { Business, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";
import { brandObjectives } from "./FormConstants";
import {
  getBrandAttibutes,
  getBrandCategories,
} from "../../services/campaignBrief";

function Step2Brand({ setValid, step2Values, setStep2Values }) {
  const [objective, setObjective] = useState(step2Values.objective);
  const [brandName, setBrandName] = useState(step2Values.brandName);
  const [attributes, setAttributes] = useState(step2Values.attributes);
  const [brandAttibutesData, setBrandAttibutesData] = useState([]);
  const [category, setCategory] = useState(step2Values.category);
  const [brandCategoriesData, setBrandCategoriesData] = useState([]);
  const [target, setTarget] = useState(step2Values.target);

  const getBrandAttibutesData = async () => {
    const brandAttibutesData = await getBrandAttibutes();

    if (brandAttibutesData.status) {
      setBrandAttibutesData(
        brandAttibutesData.response?.map((attribute) => attribute.name)
      );
    }
  };

  const getBrandCategoriesData = async () => {
    const brandCategoriesData = await getBrandCategories();

    if (brandCategoriesData.status) {
      setBrandCategoriesData(
        brandCategoriesData.response?.map((category) => category.category)
      );
    }
  };

  useEffect(() => {
    getBrandAttibutesData();
    getBrandCategoriesData();
  }, []);

  useEffect(() => {
    let valid = false;
    if (objective !== "" && brandName !== "") {
      valid = true;
    }
    setValid(valid);
  }, [objective, brandName, setValid]);

  useEffect(() => {
    const stepValues = {};
    stepValues.objective = objective;
    stepValues.brandName = brandName;
    stepValues.attributes = attributes;
    stepValues.category = category;
    stepValues.target = target;
    setStep2Values(stepValues);
  }, [objective, brandName, attributes, category, target, setStep2Values]);

  return (
    <>
      <div className={styles.step_title}>Campaign Brief</div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.step_field}>
            What is the main objective of your campaign?*
          </div>
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          >
            <List
              sx={{
                height: "120px",
                overflow: "auto",
                ".Mui-selected": {
                  background: "#C4C4C4 !important",
                },
                ".Mui-selected:hover": {
                  background: "rgba(0, 0, 0, 0.2) !important",
                },
                "&::-webkit-scrollbar": {
                  width: "12px",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  backgroundColor: "rgba(0,0,0,.08)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,.24)",
                },
              }}
              dense
              component="div"
              role="list"
            >
              {brandObjectives?.map((value) => {
                return (
                  <ListItem
                    key={value}
                    button
                    selected={value === objective}
                    onClick={() => setObjective(value)}
                  >
                    <ListItemText primary={value} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Brand Name*</div>
          <TextField
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "46px",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            value={brandName}
            onChange={(val) => setBrandName(val.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Business />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Brand Attributes</div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={brandAttibutesData}
            getOptionDisabled={option => attributes?.length < 4 ? false : true}
            disableCloseOnSelect
            value={attributes}
            onChange={(e, val) => setAttributes(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Brand Category</div>
          <Autocomplete
            size="small"
            options={brandCategoriesData}
            getOptionLabel={(val) => val}
            value={category}
            onChange={(e, val) => setCategory(val)}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>What is your target audience?</div>
          <FormControl>
            <RadioGroup
              row
              value={target}
              onChange={(val) => setTarget(val.target.value)}
              sx={{
                "& .MuiFormGroup-root": {
                  display: "flex !important",
                  justifyContent: "space-around !important",
                },
              }}
            >
              <FormControlLabel value="B2C" control={<Radio />} label="B2C" />
              <FormControlLabel value="B2B" control={<Radio />} label="B2B" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

Step2Brand.propTypes = {
  setValid: PropTypes.func.isRequired,
  step2Values: PropTypes.object.isRequired,
  setStep2Values: PropTypes.func.isRequired,
};

export default Step2Brand;
