import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SectionSwitcher from "./SectionSwitcher";
import { InfoToolTip } from "../../../../../../components/common/toolTip";

const PageWrapper = (props) => {
  const { title, children, sectionSwtichData, lastRefreshedTime, nextRefreshTime } = props;
  const { dropdownData, currentSection, setCurrentSection, nextClickHandler, previousClickHandler } = sectionSwtichData;

  useEffect(() => {}, [props]);

  return (
    <Box width={"100%"} pb={"60px"} position={"relative"} height={"100%"} paddingX={"3rem"}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb="1rem">
        <Typography variant="h4" fontWeight={"bold"} color={"black"}>
          {title}
        </Typography>
        <Box>
          <Grid container>
            <Grid item xs={7} style={{ textAlign: "center", paddingTop: "30px" }}>
              <Typography fontWeight={"normal"}>
                <b>Last Refreshed At:</b> {lastRefreshedTime}{" "}
                <InfoToolTip
                  text={
                    "The campaign's statistics are updated weekly on Mondays. The 'Last Refresh' date refers to the most recent update, which occurs each Monday of the week."
                  }
                />
                <br />
                <i style={{ fontSize: "14px", textAlign: "left" }}>
                  <b>Next Refresh At:</b> {nextRefreshTime}{" "}
                  <InfoToolTip
                    text={
                      "The campaign's statistics are scheduled to refresh weekly on Mondays. The 'Next Refresh' date indicates the forthcoming update, which will occur on the following Monday."
                    }
                  />
                </i>
              </Typography>
              <br />
            </Grid>
            <Grid item xs={5}>
              <SectionSwitcher
                dropdownData={dropdownData}
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
                nextClickHandler={nextClickHandler}
                previousClickHandler={previousClickHandler}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default PageWrapper;
