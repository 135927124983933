import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import styles from "../../../../../styles/internal/Campaign/CampaignList.module.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteContractTemplate } from "../../../../../services/contracts";
import { notify } from "../../../../../redux/features/system/systemAlert";

const DeleteTemplate = ({ templateDetails, reload, deleteTemplate, closeDeleteTemplateDialog }) => {
  const scroll = "paper";
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const deleteTemplateObject = async () => {
    dispatch(
      notify({
        message: `Deleting Contract template ${templateDetails.title}`,
        type: "info",
      })
    );
    const response = await deleteContractTemplate(templateDetails.id, user, dispatch);
    if (response?.response.status === "success") {
      dispatch(
        notify({
          message: `Contract template ${templateDetails.title} deleted successfully`,
          type: "success",
        })
      );
      reload(true);
    }
  };

  return (
    <Dialog
      open={deleteTemplate}
      onClose={closeDeleteTemplateDialog}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <h3>Delete Template</h3>
      </DialogTitle>
      <DialogContent>
        <div className={styles.message}>
          <p>Are you sure you want to delete template {templateDetails.title} ?</p>
          <div className={styles.buttonContainer}>
            <Button className={`${styles.button} ${styles.leftButton}`} onClick={closeDeleteTemplateDialog}>
              No, not now
            </Button>
            <Button className={`${styles.button2} ${styles.centerButton}`} onClick={() => deleteTemplateObject()}>
              I confirm
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteTemplate;
