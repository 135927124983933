import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ m: 4 }} className="text-block">
      <h1>AIFLUENCE, INC. – Website and&nbsp;Platform Privacy Notice</h1>

      <p>
        AIfluence, Inc. (along with each of AIfluence, Inc.&apos;s Corporate Affiliates
        (as defined below), collectively, &quot;
        <strong>AIfluence</strong>
        &quot;, “
        <strong>we</strong>
        ”, “
        <strong>us</strong>
        ” or “
        <strong>our</strong>
        ”)
        respects your privacy and is committed to protecting your Personal Data.
      </p>

      <p>
        As of the Effective Date, AIfluence, Inc.&apos;s Corporate Affiliates include:
      </p>

      <ol>
        <li>
          AIfluence International Ltd
          <br />
          7th Floor, ABC Towers, ABC Place
          <br />
          Waiyaki Way, Nairobi, Kenya
        </li>
      </ol>

      <h4>Background</h4>

      <p>
        This privacy notice sets out the basis on which any Personal Data we
        collect from or about you, or that you provide to us, including
        through&nbsp;www.aifluence.co&nbsp;and any sub-domain thereof (“
        <strong>our website</strong>
        ”) and this Platform, will be processed by us
        in accordance with applicable Data Protection Laws, provided that this
        privacy notice does not apply to information processed under AIfluence&apos;s
        Influencer Privacy Notice. If you are engaged by us as an influencer
        please refer to the AIFluence Influencer Privacy Notice. Please read the
        following carefully to understand our views and practices regarding your
        Personal Data and how we will treat it.
      </p>

      <p>
        Our website is not intended for children and we do not knowingly collect
        data relating to children. By using this Platform and our website, you
        confirm that you are over the age of 18 (or the age of majority if older)
        as may be described under the relevant laws in your jurisdiction.
      </p>

      <p>
        It is important that you read this privacy notice together with any other
        privacy notice or fair processing notice we may provide on specific
        occasions when we are collecting or processing personal data about you so
        that you are fully aware of how and why we are using your data. This
        privacy notice supplements the other notices and is not intended to
        override them.
      </p>

      <h2>Terms</h2>

      <h4>
        <strong>1. DEFINITIONS</strong>
      </h4>

      <p>
        In this privacy notice the following words and phrases shall have the
        following meanings:
      </p>

      <p>
        “
        <strong>Applicable Law</strong>
        ” means (a) any law, statute, regulation,
        bylaw or subordinate legislation in force from time to time to which we
        are subject; (b) the common law and laws of equity as applicable to us
        from time to time; (c) any court order, judgment or decree which is
        binding on us; (d) any industry code, policy or standard which is
        applicable to us; (e) any applicable direction, policy, rule or order that
        is binding on us and that is made or given by any regulatory body having
        jurisdiction over us;
      </p>

      <p>
        “
        <strong>Data Protection Laws</strong>
        ” means any Applicable Law relating
        to the processing, privacy, and use of Personal Data, including but not
        restricted to (a) the Data Protection Act of Kenya (No. 24 of 2019)
        (&quot;DPA&quot;) and (b) any judicial or administrative interpretation of any such
        Applicable Law, and any guidance, guidelines, codes of practice, approved
        codes of conduct or approved certification mechanisms issued by any
        relevant regulatory or supervisory authority;
      </p>

      <p>
        “
        <strong>Data</strong>
        ” means information which is stored electronically,
        on a computer, or in certain paper-based filing systems;
      </p>

      <p>
        “
        <strong>Personal Data</strong>
        ” means any information relating to an
        identified or identifiable natural person (a “
        <strong>Data Subject</strong>
        ”); an identifiable natural person is one who
        can be identified, directly or indirectly, in particular by reference to
        an identifier such as a name, an identification number, location data, an
        online identifier or to one or more factors specific to the physical,
        physiological, genetic, mental, economic, cultural or social identity of
        that natural person and where referred to in this notice includes
        sensitive personal data; and
      </p>

      <p>
        “
        <strong>Platform</strong>
        ”&nbsp;
      </p>

      <p>
        means our Influencer Relationship Management Platform and Website which
        may be accessed at&nbsp;
        <a href="https://www.aifluence.co/">https://www.aifluence.co</a>, &nbsp;
        <a href="https://www.ai-fluence.com/">https://www.ai-fluence.com</a> and &nbsp;
        <a href="https://www.empowr.ai">https://www.empowr.ai</a>
      </p>

      <h4>
        <strong>2. WHO WE ARE</strong>
      </h4>

      <p>
        Aifluence Inc is Incorporated in the United States of America with its
        registered office at 651 N Broad St, Suite 206, Middletown, DE, 19709.
        AIFluence International Ltd, our Corporate Affiliate, is located at 7
        <sup>th</sup>
        &nbsp;
        Floor, ABC Towers, ABC Place, Waiyaki Way, P.0. Box
        14990-00100, Nairobi, Kenya. We have developed and manage our
        market-research database and our Platform, which allow users to manage,
        expand, validate and scale their networks with influencers.
      </p>

      <h4>
        <strong>3. INFORMATION WE&nbsp;COLLECT&nbsp;FROM&nbsp;YOU</strong>
      </h4>

      <p>We will collect and process the following Data about you:</p>

      <h5>
        <strong>3.1 Information you give us.</strong>
      </h5>

      <p>
        This is information about you that you give us through the use of the
        Platform (e.g. when you correspond with us by telephone, email or
        otherwise, including when you report a problem with our website). The
        information you give us may include your name, email address and social
        media username (or ‘handle’).
      </p>

      <h5>
        <strong>3.2 Information we collect about you.</strong>
      </h5>

      <p>
        With regard to each of your visits to our website and/or Platform we will
        automatically collect, or will collect through a third party acting on our
        behalf, the following information:
      </p>

      <ul>
        <li>
          <strong>3.2.1</strong>
          &nbsp;technical information, including the
          Internet protocol (IP) address used to connect your computer to the
          Internet, your login information, browser type and version, time zone
          setting, browser plug-in types and versions, operating system and your
          platform.
        </li>
        <li>
          <strong>3.2.2</strong>
          &nbsp;information about your visit, including the
          full Uniform Resource Locators (URL), clickstream to, through and from
          our website (including date and time), page response times, download
          errors, length of visits to certain pages, page interaction information
          (such as scrolling, clicks, and mouse-overs), and any phone number used
          to call our customer service number.
        </li>
      </ul>

      <h5>
        <strong>3.3 Information we collect from other sources.</strong>
      </h5>

      <p>
        We are working closely with, and receive Personal Data from third parties
        (such as business partners, sub-contractors in technical, payment and
        delivery services, advertising networks, analytics providers, search
        information providers and credit reference agencies). In such instances,
        the third parties may provide us with your personal data in which case
        these third parties would be the data controller and therefore instruct us
        on how to handle your data as the data processor. For further details
        about these other sources please contact us using the details provided
        below.
      </p>

      <h4>
        <strong>4. COOKIES</strong>
      </h4>

      <p>
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our website. For detailed
        information on the cookies we use and the purposes for which we use them
        see our&nbsp;Cookies Policy below
      </p>

      <h5>
        <strong>4.1 What are cookies?</strong>
      </h5>

      <p>
        Cookies are small data files that are placed on your computer or mobile
        device when you visit a website or use an online service. Cookies are
        widely used by online service providers in order to make their websites or
        services work, or work more efficiently, as well as to provide reporting
        information.
        <br />
        <br />
        Cookies set by the website or Platform owner (in this case, AIfluence) are
        called &quot;first party cookies&quot;. Cookies set by parties other than the
        website or service owner are called &quot;third party cookies&quot;. Third party
        cookies often enable third party features or functionality to be provided
        on the website or service you are using (e.g. like advertising,
        interactive content and analytics). The third parties that set these
        cookies can use them to recognise your computer both when it visits the
        Platform or website in question and also when it uses certain other
        websites or services.
      </p>

      <h5>
        <strong>4.2 Why do we use cookies?</strong>
      </h5>

      <p>
        Some cookies are required for technical reasons in order for our website
        to operate, while other cookies enable us to track and measure website or
        marketing performance. Finally, we may engage third parties to serve
        cookies through our Website for advertising, analytics and other purposes.
      </p>

      <h5>
        <strong>4.3 How can I control cookies?</strong>
      </h5>

      <p>
        You have the right to decide whether to accept or reject cookies. You can
        exercise your cookie preferences by clicking on the appropriate opt-out
        links provided in the cookie table above.
        <br />
        <br />
        You can set or amend your web browser controls to accept or refuse
        cookies. If you choose to reject cookies, you may still use our website
        though your access to some functionality and areas of our website may be
        restricted. As the means by which you can refuse cookies through your web
        browser controls vary from browser-to-browser, you should visit your
        browser&apos;s help menu for more information.
        <br />
      </p>

      <h5>
        <strong>4.4 Where can I get further information?</strong>
      </h5>

      <p>
        If you have any questions about our use of cookies or other technologies,
        please email us at&nbsp;
        <a href="mailto:support@empowr.ai">support@empowr.ai</a>
        .
      </p>

      <h4>
        <strong>5. LEGAL BASIS FOR PROCESSING</strong>
      </h4>

      <p>
        We will only use your Personal Data to the extent that the law allows us
        to do so. Most commonly, we will use your Personal Data in the following
        circumstances:
      </p>

      <ul>
        <li>
          where processing is necessary for the performance of a contract to which
          you are subject or in order to take steps at your request prior to
          entering into a contract;
        </li>
        <li>
          where it is necessary for the purposes of our legitimate interests (or
          those of a third party) and your interests and fundamental rights are
          not harmed or prejudiced by such use. Our legitimate interests are to
          provide our website to you as a part of the service provided to your
          organization;
        </li>
        <li>
          where you have given consent to the processing, which consent may be
          withdrawn at any time without affecting the lawfulness of processing
          based on consent prior to withdrawal; and/or
        </li>
        <li>
          where we need to comply with a legal or regulatory obligation and/or the
          use is necessary for the public interest.
        </li>
      </ul>

      <h4>
        <strong>6. USES MADE OF THE INFORMATION</strong>
      </h4>

      <p>We use information held about you as set out below:</p>

      <h5>
        <strong>6.1 Information you give to us.</strong>
      </h5>

      <p>We will use this information:</p>

      <ul>
        <li>
          <strong>6.1.1</strong>
          &nbsp;when you subscribe to our Platform: to
          manage your account, including to communicate with you regarding your
          account;
        </li>
        <li>
          <strong>6.1.2</strong>
          &nbsp;to operate, maintain, market and improve
          the Platform;
        </li>
        <li>
          <strong>6.1.3</strong>
          &nbsp;to respond to your comments and questions
          and to provide customer service and support (including with respect to
          the Platform);&nbsp;
        </li>
        <li>
          <strong>6.1.4</strong>
          &nbsp;to perform data analytics on the use of our
          website and Platform in order to improve our service offering and to get
          to know our customers better; and/or
        </li>
        <li>
          <strong>6.1.4</strong>
          &nbsp;when you make an enquiry about our services
          or request marketing materials to be sent to you.
        </li>
      </ul>

      <h5>
        <strong>6.2 Information we collect about you.</strong>
      </h5>

      <p>We will use this information:</p>

      <ul>
        <li>
          <strong>6.2.1</strong>
          &nbsp;to administer our website and for internal
          operations, including troubleshooting, data analysis, testing, research,
          statistical and survey purposes;
        </li>
        <li>
          <strong>6.2.2&nbsp;</strong>
          to improve our website to ensure that
          content is presented in the most effective manner for you and for your
          computer or device and to market and improve our Platform; and
        </li>
        <li>
          <strong>6.2.3&nbsp;</strong>
          as part of our efforts to keep our website
          safe and secure.
        </li>
      </ul>

      <h5>
        <strong>6.3 Information we receive from other sources.</strong>
      </h5>

      <p>
        We will combine this information with information you give to us and
        information we collect about you. We will use this information and the
        combined information for the purposes set out above (depending on the
        types of information we receive).
      </p>

      <h4>
        <strong>7. DISCLOSURE&nbsp;OF&nbsp;YOUR&nbsp;INFORMATION</strong>
      </h4>

      <p>
        <strong>7.1</strong>
        &nbsp;You agree that we have the right to share your
        Personal Data with selected third parties including business partners,
        suppliers and sub-contractors in connection with our business, including
        without limitation to allow us to provide access to our website. Without
        limitation of the foregoing, we may share your Personal Data with
        professional advisers including lawyers, bankers, auditors and insurers
        who provide consultancy, banking, legal, insurance and accounting
        services.
      </p>

      <p>
        <strong>7.2</strong>
        &nbsp;In addition to the above, we may disclose your
        Personal Data to third parties in the following ways:
      </p>

      <ul>
        <li>
          <strong>7.2.1</strong>
          &nbsp;in the event that we sell or buy any
          business or assets, in which case we may disclose your Personal Data to
          the prospective seller or buyer of such business or assets and in
          connection with any such transaction;
        </li>
        <li>
          <strong>7.2.2</strong>
          &nbsp;if AIfluence or substantially all of its
          assets is or are acquired by a third party, in which case Personal Data
          held by us about our customers will be one of the transferred assets and
          we may disclose your Personal Data in connection with any such
          transaction;
        </li>
        <li>
          <strong>7.2.3</strong>
          &nbsp;if we discontinue our business, or file a
          petition or have filed against us a petition in bankruptcy,
          reorganization or similar insolvency petition, application or proceeding
          we may disclose your Personal Data to persons dealing with such
          discontinuation or in connection with any such petition, application or
          proceeding;
        </li>
        <li>
          <strong>7.2.4</strong>
          &nbsp;we may disclose your Personal Data to our
          Corporate Affiliates; for the purposes of this privacy notice:
          &quot;Corporate Affiliate&quot; means any person or entity which directly or
          indirectly controls, is controlled by or is under common control with
          AIfluence, Inc., whether by ownership or otherwise; and “control” means
          possessing, directly or indirectly, the power to direct or cause the
          direction of the management, policies or operations of an entity,
          whether through ownership of fifty percent (50%) or more of the voting
          securities, by contract or otherwise;
        </li>
        <li>
          <strong>7.2.5&nbsp;</strong>
          we may disclose your Personal Data to any
          third party to whom we are under a duty to disclose or share your
          Personal Data in order to comply with any legal obligation, including
          without limitation public authorities to meet national security or law
          enforcement requirements, and regulators and other authorities who
          require reporting of processing activities in certain circumstances;
        </li>
        <li>
          <strong>7.2.6</strong>
          &nbsp;we may disclose your Personal Data to third
          parties with whom we may be required to communicate in order to enforce
          or apply our agreements with your organization and other agreements; or
          to protect the rights, property, or safety of AIfluence, our customers,
          or others; and
        </li>
        <li>
          <strong>7.2.7</strong>
          &nbsp;where necessary, we may disclose your
          Personal Data to analytics and search engine providers that assist us in
          the improvement and optimisation of our website and where possible we
          aim to anonymise or pseudonymise such data.
        </li>
      </ul>

      <p>
        In addition to the above, third parties may directly collect personally
        identifiable and non-personally identifiable information about our users&apos;
        online activities over time and across different websites.
      </p>

      <p>
        Where possible, we will anonymise your personal data to the extent
        possible before such disclosure to third parties.
      </p>

      <h4>
        <strong>8. WHERE WE&nbsp;STORE&nbsp;YOUR&nbsp;PERSONAL&nbsp;DATA</strong>
      </h4>

      <p>
        <strong>8.1</strong>
        &nbsp;Unfortunately, the transmission of information
        via the Internet is not completely secure. Although we will do our best to
        protect your Personal Data, we cannot guarantee the security of your Data
        transmitted through our website; any transmission is at your own risk.
        Once we have received your information, we will use strict procedures and
        security features to try to prevent unauthorised access.
      </p>

      <p>
        <strong>8.2</strong>
        &nbsp;Our external third-party providers may be based
        outside your jurisdiction so their processing of your Personal Data will
        involve a transfer of Personal Data outside your jurisdiction.
        Specifically, our servers are based in the United States of America, and
        we use these servers to store the information you provide us with.
      </p>

      <p>
        <strong>8.3&nbsp;</strong>
        Whenever we transfer your Personal Data out of
        your jurisdiction, we ensure a similar degree of protection is afforded to
        it.&nbsp;
      </p>

      <p>
        <strong>8.4&nbsp;</strong>
        We only transfer your Personal Data out of your
        jurisdiction where it is necessary and/or lawful further to the reasons
        stated in (5) above. If we rely on another basis to transfer your Personal
        Data outside of your jurisdiction (such as your consent), we will keep you
        updated or contact you as required.
      </p>

      <p>
        <strong>8.5&nbsp;</strong>
        Whenever we transfer your data out of your
        jurisdiction, we will implement appropriate safeguards for the transfer of
        that data as required by Applicable Law.
      </p>

      <p>
        <strong>8.6&nbsp;</strong>
        Please contact us if you want further
        information on the specific mechanisms used by us and the safeguards
        implemented when transferring your Personal Data out of your jurisdiction.
      </p>

      <h4>
        <strong>9. THIRD&nbsp;PARTY&nbsp;LINKS</strong>
      </h4>

      <p>
        Our website, from time to time, may contain links to and from the websites
        of our partner networks, advertisers and affiliates. If you follow a link
        to any of these websites, please note that these websites have their own
        privacy policies and that we do not accept any responsibility or liability
        for these policies. Please check these policies before you submit any
        Personal Data to these websites.
      </p>

      <h4>
        <strong>10. DATA SECURITY</strong>
      </h4>

      <p>
        <strong>10.1</strong>
        &nbsp;We have put in place appropriate security
        measures to prevent your Personal Data from being accidentally lost, used
        or accessed in an unauthorised way, altered or disclosed. In addition, we
        limit access to your Personal Data to those employees, agents, contractors
        and other third parties who have a business need to know. They will only
        process your Personal Data on our instructions, and they are subject to a
        duty of confidentiality.
      </p>

      <p>
        <strong>10.2</strong>
        &nbsp;We have put in place procedures to deal with
        any suspected Personal Data breach and will notify you and any applicable
        regulator of a breach where we are legally required to do so.
      </p>

      <h4>
        <strong>11. DATA RETENTION</strong>
      </h4>

      <p>
        <strong>11.1</strong>
        &nbsp;We will only retain your Personal Data for as
        long as necessary to fulfill the purposes we collected it for, including
        for the purposes of satisfying any legal, accounting, or reporting
        requirements.
      </p>

      <p>
        <strong>11.2&nbsp;</strong>
        To determine the appropriate retention period
        for Personal Data, we consider the amount, nature, and sensitivity of the
        Personal Data, the potential risk of harm from unauthorised use or
        disclosure of your Personal Data, the purposes for which we process your
        Personal Data and whether we can achieve those purposes through other
        means, and the applicable legal or regulatory requirements.
      </p>

      <h4>
        <strong>12. YOUR RIGHTS</strong>
      </h4>

      <p>
        Under certain circumstances and in compliance with Data Protection Laws,
        you may have the right to:
      </p>

      <ul>
        <li>
          <strong>12.1 Be informed</strong>
          &nbsp;of the use to which your Personal Data
          is to be put, which we have notified you in detail above in (6).
        </li>
        <li>
          <strong>12.2 Request access</strong>
          &nbsp;to your Personal Data
          (commonly known as a ‘data subject access request’). This enables you to
          receive a copy of the Personal Data we hold about you and to check that
          we are lawfully processing it. You will usually not have to pay a fee to
          access your Personal Data (or to exercise any of the other rights).
          However, we may charge a reasonable fee for expenses incurred by us in
          providing such access. We may refuse to comply with your request where
          permitted by applicable law.&nbsp;
        </li>
        <li>
          <strong>12.3 Request correction&nbsp;</strong>
          of the Personal Data that
          we hold about you. This enables you to have any incomplete or inaccurate
          Personal Data we hold about you corrected, though we may need to verify
          the accuracy of the new Personal Data you provide to us.
        </li>
      </ul>

      <ul>
        <li>
          <strong>12.4 Request erasure&nbsp;</strong>
          of your Personal Data. This
          enables you to ask us to delete or remove Personal Data where there is
          no good reason for us continuing to process it or where the data is
          false or misleading. You also have the right to ask us to delete or
          remove your Personal Data where you have successfully exercised your
          right to object to processing (see below), where we may have processed
          your information unlawfully or where we are required to erase your
          Personal Data to comply with local law. Note, however, that we may not
          always be able to comply with your request of erasure for specific legal
          reasons which will be notified to you, if applicable, at the time of
          your request.
        </li>
        <li>
          <strong>12.5 Object to processing&nbsp;</strong>
          of your Personal Data
          where we are relying on a legitimate interest (or those of a third
          party) and there is something about your particular situation which
          makes you want to object to processing on this ground as you feel it
          impacts on your fundamental rights and freedoms. You also have the right
          to object where we are processing your Personal Data for direct
          marketing purposes. In some cases, we may demonstrate that we have
          compelling legitimate grounds to process your information which override
          your rights and freedoms.
        </li>
        <li>
          <strong>12.6 Request restriction of processing&nbsp;</strong>
          of your
          Personal Data. This enables you to ask us to suspend the processing of
          your Personal Data in the following scenarios: (a) if you want us to
          establish the Personal Data’s accuracy; (b) where our use of the
          Personal Data is unlawful but you do not want us to erase it; (c) where
          you need us to hold the Personal Data even if we no longer require it as
          you need it to establish, exercise or defend legal claims; or (d) you
          have objected to our use of your Personal Data but we need to verify
          whether we have overriding legitimate grounds to use it.
        </li>
        <li>
          <strong>12.7 Request the transfer&nbsp;</strong>
          of your Personal Data
          to you or to a third party. We will provide to you, or a third party you
          have chosen, your Personal Data in a structured, commonly used,
          machine-readable format. Note that this right only applies to automated
          information which you initially provided consent for us to use or where
          we used the information to perform a contract with you.
        </li>
      </ul>

      <h4>
        <strong>13. HOW TO EXERCISE YOUR RIGHTS</strong>
      </h4>

      <p>
        In order to exercise any of your rights please contact us using the
        contact details provided in section 21 of this notice.
      </p>

      <h4>
        <strong>14. WHAT WE MAY NEED FROM YOU</strong>
      </h4>

      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your Personal Data (or to
        exercise any of your other rights). This is a security measure to ensure
        that Personal Data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information in
        relation to your request to speed up our response.
      </p>

      <h4>
        <strong>15. TIME LIMIT TO RESPOND</strong>
      </h4>

      <p>
        We try to respond to all legitimate requests within one month.
        Occasionally it may take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this case,
        we will notify you and keep you updated.
      </p>

      <h4>
        <strong>
          16. RIGHT TO LODGE A COMPLAINT WITH A SUPERVISORY AUTHORITY
        </strong>
      </h4>

      <p>
        If you have any complaints about the way in which we process your Personal
        Data please do contact us, as set out below in Section 21. Alternatively,
        you have the right to lodge a formal complaint with your data protection
        supervisory authority.
      </p>

      <h4>
        <strong>17. CHANGE OF PURPOSE</strong>
      </h4>

      <p>
        <strong>17.1</strong>
        &nbsp;We will only use your Personal Data for the
        purposes for which we collected it, unless we reasonably consider that we
        need to use it for another reason and that reason is compatible with the
        original purpose. If you wish to get an explanation as to how the
        processing for the new purpose is compatible with the original purpose,
        please contact us in the manner described in Section 21.
      </p>

      <p>
        <strong>17.2</strong>
        &nbsp;If we need to use your Personal Data for an
        unrelated purpose, we will notify you and we will explain the legal basis
        which allows us to do so.
      </p>

      <p>
        <strong>17.3</strong>
        &nbsp;Please note that we may process your Personal
        Data without your knowledge or consent, in compliance with the above
        rules, where this is required or permitted by law.
      </p>

      <h4>
        <strong>18. DO NOT TRACK</strong>
      </h4>

      <p>
        The term “Do Not Track” refers to a HTTP header offered by certain web
        browsers to request that websites refrain from tracking the user. We take
        no action in response to Do Not Track requests. However, if you wish to
        stop such tracking, please contact us with your request, using our contact
        details provided in Section 21 below.
      </p>

      <h4>
        <strong>19. CHANGES TO OUR PRIVACY NOTICE</strong>
      </h4>

      <p>
        Any changes we make to our privacy notice in the future will be posted to
        this section of our website and, where appropriate, notified to you by
        email. We will not make changes that result in significant additional
        uses or disclosures of your personally identifiable information without
        allowing you to ‘opt in’ to such changes. We may also make non-significant
        changes to this privacy notice that generally will not significantly
        affect our use of your personally identifiable information, for which your
        opt-in is not required. Please check back frequently to see any updates or
        changes to our privacy notice.
      </p>

      <h4>
        <strong>20. PRIVACY NOTICE FOR CALIFORNIA</strong>
      </h4>

      <p>
        This Privacy Notice shall only apply to you if you reside in the state of
        California, USA.
      </p>

      <p>
        <strong>Residents</strong>
      </p>

      <p>
        Notwithstanding any earlier Effective Date applicable to this privacy
        notice generally, this Section 20 shall be effective from and after
        January 1, 2020. This Section 20 shall apply to you only if you are a
        California resident. This Section 20 shall apply only to the extent
        AIfluence is regulated under the CCPA (as defined below) as a business (as
        defined in the CCPA).
      </p>

      <p>
        If an Organization with which you are associated signs up to use our
        Platform, we may receive consumer information (as defined below) about you
        in connection with our provision of the Platform to your Organization. To
        the extent we process such consumer information solely in order to provide
        the Platform to your Organization, under the CCPA, to the extent
        applicable, we will act as a service provider (as defined in the CCPA) on
        behalf of your Organization in respect of that consumer information; this
        privacy notice will not apply to the processing of that consumer
        information and your Organization will act as a business (as defined in
        the CCPA) in respect of that consumer information. The business is
        responsible for obtaining all necessary consents and providing you with
        all requisite information as required by Applicable Law. To the extent we
        process your consumer information for any other lawful business purpose of
        ours, under the CCPA, to the extent applicable, we will act as a business
        with respect to such consumer information and this privacy notice will
        apply to the processing of such consumer information. For clarification
        but not limitation, information we process for our legitimate business
        purposes, such as product development, sales and marketing, is not
        processed solely on behalf of your Organization.
      </p>

      <p>
        As used in this Section 20, “
        <strong>sell</strong>
        ” (including all
        grammatically inflected forms thereof) means selling, renting, releasing,
        disclosing, disseminating, making available, transferring, or otherwise
        communicating orally, in writing, or by electronic or other means,
        consumer information (as defined below) to another business or a third
        party for monetary or other valuable consideration.
      </p>

      <p>
        “Selling” does not include (i) disclosing consumer information to a third
        party at your direction, provided the third party does not sell the
        consumer information except in accordance with the California Consumer
        Privacy Act (the “
        <strong>CCPA</strong>
        ”), (ii) where you intentionally
        interact with a third party through the website, provided the third party
        does not also sell the consumer information, (iii) using or sharing your
        consumer information with a service provider as necessary to perform
        business purposes, provided that such service provider provides its
        services on AIfluence’s behalf and provided that the service provider does
        not further collect, sell or use the consumer information except as
        necessary to perform the business purpose, or (iv) transfers of your
        consumer information to a third party as an asset that is part of a
        merger, acquisition, bankruptcy, or other transaction in which the third
        party assumes control of all or part of AIfluence, provided that
        information is used or shared consistently with the CCPA.
      </p>

      <p>
        <strong>20.1 Consumer Information Collected:&nbsp;</strong>
        We collect
        information that identifies, relates to, describes, references, is capable
        of being associated with, or could reasonably be linked, directly or
        indirectly, with particular California residents, devices or households (&quot;
        <strong>consumer information</strong>
        &quot;). In particular, we have collected
        the following categories of consumer information from California
        residents, households or devices within the last twelve (12) months:
      </p>

      <figure>
        <table border="1" cellSpacing="0" cellPadding="10">
          <tbody>
            <tr>
              <td rowSpan="6" valign="top">
                <strong>A. Identifiers.</strong>
              </td>
              <td rowSpan="6" valign="top">
                Name, online identifier, Internet Protocol address, email address
              </td>
              <td>
                - to manage your account, including to communicate with you
                regarding your account.
              </td>
            </tr>
            <tr>
              <td>- to operate, maintain, market and improve the Platform.</td>
            </tr>
            <tr>
              <td>
                - to respond to your comments and questions and to provide
                customer service and support (including with respect to the
                Platform).
              </td>
            </tr>
            <tr>
              <td>
                - when you make an enquiry about our services or request marketing
                materials to be sent to you
              </td>
            </tr>
            <tr>
              <td>
                - to administer our website and for internal operations, including
                troubleshooting, data analysis, testing, research, statistical and
                survey purposes.
              </td>
            </tr>
            <tr>
              <td>
                - to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer or device
                and to market and improve our Platform.
              </td>
            </tr>
            <tr>
              <td rowSpan="7" valign="top">
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </td>
              <td rowSpan="7" valign="top">
                Name
              </td>
              <td>
                - to manage your account, including to communicate with you
                regarding your account.
              </td>
            </tr>
            <tr>
              <td>- to operate, maintain, market and improve the Platform.</td>
            </tr>
            <tr>
              <td>
                - to respond to your comments and questions and to provide
                customer service and support (including with respect to the
                Platform).
              </td>
            </tr>
            <tr>
              <td>
                - when you make an enquiry about our services or request marketing
                materials to be sent to you
              </td>
            </tr>
            <tr>
              <td>
                - to administer our website and for internal operations, including
                troubleshooting, data analysis, testing, research, statistical and
                survey purposes.
              </td>
            </tr>
            <tr>
              <td>
                - to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer or device
                and to market and improve our Platform.
              </td>
            </tr>
            <tr>
              <td>
                - as part of our efforts to keep our website safe and secure.
              </td>
            </tr>
            <tr>
              <td rowSpan="7" valign="top">
                C. Internet or other similar network activity.
              </td>
              <td rowSpan="7" valign="top">
                Browsing history, search history, information on a consumer&apos;s
                interaction with a website, application, or advertisement.
              </td>
              <td>
                - to manage your account, including to communicate with you
                regarding your account.
              </td>
            </tr>
            <tr>
              <td>- to operate, maintain, market and improve the Platform.</td>
            </tr>
            <tr>
              <td>
                - to respond to your comments and questions and to provide
                customer service and support (including with respect to the
                Platform).
              </td>
            </tr>
            <tr>
              <td>
                - when you make an enquiry about our services or request marketing
                materials to be sent to you
              </td>
            </tr>
            <tr>
              <td>
                - to administer our website and for internal operations, including
                troubleshooting, data analysis, testing, research, statistical and
                survey purposes.
              </td>
            </tr>
            <tr>
              <td>
                - to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer or device
                and to market and improve our Platform.
              </td>
            </tr>
            <tr>
              <td>
                - as part of our efforts to keep our website safe and secure.
              </td>
            </tr>
          </tbody>
        </table>
      </figure>

      <p>
        <strong>‍</strong>
      </p>

      <p>
        <strong>20.2 Use of Consumer Information:</strong>
        &nbsp;We use consumer
        information for the business or commercial purposes described in the table
        above and in the manner described in Section 6 of this privacy notice with
        respect to Personal Data.
      </p>

      <p>
        <strong>
          20.3 Disclosures of Consumer Information for a Business Purpose:
        </strong>
        &nbsp;AIfluence may disclose your consumer information described in the
        table above to a third party for a business purpose, as described in
        Section 7 of this privacy notice with respect to Personal Data. In the
        preceding twelve (12) months, AIfluence has disclosed the following
        categories of consumer information for a business purpose:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.3.1</strong>
        &nbsp;Identifiers.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.3.2</strong>
        &nbsp;Personal information categories listed in the
        California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.3.3</strong>
        &nbsp;Internet or other similar network activity.
      </p>

      <p>
        <strong>20.4 Sales of Consumer Information:</strong>
        &nbsp;In the preceding
        twelve (12) months, AIfluence has not sold, and AIfluence does not and
        will not sell, consumer information that is subject to this privacy
        notice; however,&nbsp; please see&nbsp;
        <Link to="/page/influencerPrivacyPolicy">
          AIfluence&apos;s Influencer Privacy Notice&nbsp;
        </Link>
        &nbsp;for details regarding AIfluence&apos;s collection and use (including
        sale) of publicly available information about influential individuals,
        including sharing of such publicly available information about influential
        individuals with AIfluence&apos;s business customers.
      </p>

      <p>
        <strong>20.5 California Residents’ Rights and Choices:</strong>
        &nbsp;The
        CCPA provides California residents with specific rights regarding their
        consumer information. This Section 20.5 describes your CCPA rights (to the
        extent applicable to you) and explains how to exercise those rights.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>
          20.5.1 Access to Specific Information and Data Portability Rights
        </strong>
        : You may have the right to request that AIfluence disclose certain
        information to you about our collection and use of your consumer
        information over the past 12 months. Once we receive and confirm your
        verifiable consumer request (in the manner described in Section 20.6
        below), to the extent required by the CCPA, we will disclose to you:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.5.1.1 &nbsp; &nbsp;&nbsp;</strong>
        The categories of consumer
        information we collected about you.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.5.1.2</strong>
        &nbsp;&nbsp; &nbsp; The categories of sources for
        the consumer information we collected about you.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.5.1.3</strong>
        &nbsp;&nbsp; &nbsp; Our business or commercial
        purpose for collecting that consumer information.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.5.1.4</strong>
        &nbsp;&nbsp; &nbsp; The categories of third
        parties with whom we share that consumer information.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.5.1.5</strong>
        &nbsp;&nbsp; &nbsp; The specific pieces of
        consumer information we collected about you (also called a data
        portability request).
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.5.1.6</strong>
        &nbsp;&nbsp; &nbsp; If we disclosed your consumer
        information for a business purpose, a list disclosing disclosures for a
        business purpose, identifying the consumer information categories that
        each category of recipient obtained.
      </p>

      <p>
        <strong>20.5.2 Deletion Request Rights:</strong>
        &nbsp;You have the right
        to request that AIfluence delete any of your consumer information that we
        collected from you and retained, subject to certain exceptions. Once we
        receive and confirm a verifiable request from you (if you are a California
        resident) in the manner described in Section 20.6 below (“
        <strong>verifiable consumer request</strong>
        ”), we will delete (and direct
        our service providers to delete) your consumer information from our
        records, unless an exception applies. We may deny your deletion request if
        retaining the information is necessary for us or our service provider(s)
        to:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.1</strong>
        &nbsp;&nbsp; &nbsp; Complete the
        transaction for which we collected the consumer information, provide a
        product or service that you requested, take actions reasonably anticipated
        within the context of our ongoing business relationship with you, or
        otherwise perform our contract with you.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.2</strong>
        &nbsp;&nbsp; &nbsp; Detect security
        incidents, protect against malicious, deceptive, fraudulent, or illegal
        activity, or prosecute those responsible for such activities.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.3</strong>
        &nbsp;&nbsp; &nbsp; Debug products or
        services to identify and repair errors that impair existing intended
        functionality.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.4</strong>
        &nbsp;&nbsp; &nbsp; Exercise free
        speech, ensure the right of another consumer to exercise their free speech
        rights, or exercise another right provided for by law.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.5</strong>
        &nbsp;&nbsp; &nbsp; Comply with the
        California Electronic Communications Privacy Act (Cal. Penal Code §
        1546&nbsp;
        <em>et. seq.</em>
        ).
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.6</strong>
        &nbsp;&nbsp; &nbsp; Engage in public
        or peer-reviewed scientific, historical, or statistical research in the
        public interest that adheres to all other applicable ethics and privacy
        laws, when the information&apos;s deletion may likely render impossible or
        seriously impair the research&apos;s achievement, if you previously provided
        informed consent.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.7</strong>
        &nbsp;&nbsp; &nbsp; Enable solely
        internal uses that are reasonably aligned with consumer expectations based
        on your relationship with us.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.8</strong>
        &nbsp;&nbsp; &nbsp; Comply with a
        legal obligation.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
        <strong>20.5.2.9</strong>
        &nbsp;&nbsp; &nbsp; Make other
        internal and lawful uses of that information that are compatible with the
        context in which you provided it.
      </p>

      <p>
        <strong>
          20.6 Exercising Access, Data Portability, and Deletion Rights.
        </strong>
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.6.1</strong>
        &nbsp;To exercise the access, data portability, and
        deletion rights described in Section 20.5 above, please submit a
        verifiable consumer request to us by contacting us in accordance with
        Section 21. Only you, or a person registered with the California Secretary
        of State that you authorize to act on your behalf, may make a verifiable
        consumer request related to your consumer information. You may also make a
        verifiable consumer request on behalf of your minor child. You may make a
        verifiable consumer request for access or data portability no more than
        twice within a 12-month period. The verifiable consumer request must: (i)
        provide sufficient information that allows us to reasonably verify you are
        the person about whom we collected consumer information or an authorized
        representative; and (ii) describe your request with sufficient detail that
        allows us to properly understand, evaluate, and respond to it. We cannot
        respond to your request or provide you with consumer information if we
        cannot verify your identity or authority to make the request and confirm
        the consumer information relates to you. Making a verifiable consumer
        request does not require you to create an account with us. We will only
        use consumer information provided in a verifiable consumer request to
        verify the requestor&apos;s identity or authority to make the request.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.6.2</strong>
        &nbsp;We endeavour to respond to a verifiable
        consumer request within forty-five (45) days of its receipt. If we require
        more time (up to ninety (90) days), we will inform you of the reason and
        extension period in writing. If you have an account with us, we may
        deliver our written response to that account. If you do not have an
        account with us, we will deliver our written response by mail or
        electronically, at your option. Any disclosures we provide will only cover
        the 12-month period preceding the verifiable consumer request&apos;s receipt.
        The response we provide will also explain the reasons we cannot comply
        with a request, if applicable. For data portability requests, we will
        select a format to provide your consumer information that is readily
        useable and should allow you to transmit the information from one entity
        to another entity without hindrance. If your requests are manifestly
        unfounded or excessive, in particular because of their repetitive
        character, we may either charge a reasonable fee, taking into account the
        administrative costs of providing the information or communication or
        taking the action requested, or refuse to act on the request and notify
        you of the reason for refusing the request.
      </p>

      <p>
        <strong>20.7 Non-Discrimination:</strong>
        &nbsp;We will not discriminate
        against you for exercising any of your CCPA rights, including, unless
        permitted by the CCPA, by:
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.7.1</strong>
        &nbsp;Denying you goods or services.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.7.2</strong>
        &nbsp;Charging you different prices or rates for
        goods or services, including through granting discounts or other benefits,
        or imposing penalties.
      </p>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <strong>20.7.3</strong>
        &nbsp;Providing you a different level or quality of
        goods or services.
      </p>

      <p>
        <strong>20.7.4</strong>
        &nbsp;Suggesting that you may receive a different
        price or rate for goods or services or a different level or quality of
        goods or services.
      </p>

      <p>
        <strong>21. CONTACT US</strong>
      </p>

      <p>
        General questions, comments and requests regarding this privacy notice are
        welcomed and should be addressed to our compliance team using the
        following email address: legal@ai-fluence.com. For opt-out requests only,
        please use the following email address: support@empowr.ai.&nbsp;
      </p>

      <p>
        <strong>22. ADDITIONAL PROVISIONS FOR&nbsp;TRANSFER DATA</strong>
      </p>

      <p>
        <strong>22.1 Privacy Shield Framework.</strong>
        &nbsp;As noted above,
        AIfluence, Inc. complies with the EU-U.S. Privacy Shield Framework and the
        Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of
        Commerce regarding the collection, use, and retention of transfer data.
      </p>

      <p>
        <strong>22.2</strong>
        &nbsp;
        <strong>Types of Transfer Data Collected:</strong>
        &nbsp;The types of
        transfer data that may be collected by AIfluence, Inc. are the types of
        Personal Data described under Sections 3 and 4 above. Additionally,
        Processed Transfer Data includes information relating to social media
        influencers whose Personal Data is entered into AIfluence, Inc.&apos;s business
        customer&apos;s Platform account by such business customer, excluding that
        certain publicly available influencer data for which AIfluence, Inc. is
        the controller and which is subject to Ai-fluence&apos;s Influencer Privacy
        Notice.
      </p>

      <p>
        <strong>
          22.3 Purpose for Which Transfer Data is Collected and Used:
        </strong>
        &nbsp;The purposes for which transfer data is collected and used by
        AIfluence, Inc. are those purposes described under Sections 5 and 6 above.
        AIfluence, Inc. collects and uses Processed Transfer Data only on the
        instructions of the applicable third-party controller.
      </p>

      <p>
        <strong>22.4</strong>
        &nbsp;
        <strong>
          Types of Third Parties to which Transfer Data is Disclosed
        </strong>
        : Transfer data is disclosed with the same types of third parties and for
        the same purposes as are described under Section 7 above.
      </p>

      <p>
        <strong>22.5</strong>
        &nbsp;
        <strong>Access:&nbsp;</strong>
        Your rights to
        access your transfer data processed by us are described in Section 12
        above. With respect to Processed Transfer Data, AIfluence, Inc. will work
        with the applicable third-party controller to facilitate any right of
        access as described in Section 12.
      </p>

      <p>
        <strong>22.6</strong>
        &nbsp;
        <strong>Onward Transfer:</strong>
        <strong>
          <em>&nbsp;</em>
        </strong>
        Before we disclose any of your transfer data to a third party we will
        require that such third party provide the same level of privacy protection
        as is required by the Privacy Shield Principles.&nbsp; AIfluence, Inc.’s
        accountability for transfer data that it receives under the Privacy Shield
        and transfers to a third party is outlined in the Privacy Shield
        Principles.&nbsp; In particular, AIfluence, Inc. remains liable under the
        Privacy Shield Principles if third-party agents that it retains to process
        transfer data on AIfluence, Inc.’s behalf process such transfer data in a
        manner inconsistent with the Privacy Shield Principles, unless AIfluence,
        Inc. can prove that it is not responsible for the event giving rise to the
        damage.
      </p>

      <p>
        <strong>22.7 Choice:</strong>
        &nbsp;If you no longer wish to have your
        transfer data disclosed to third parties, you may choose to &quot;opt out&quot; by
        notifying us.&nbsp; To do so, send an email to support@empowr.ai.
        Please be aware that your transfer data may have been previously disclosed
        to third parties in accordance with the terms of this privacy notice.
        Further, with respect to Processed Transfer Data, AIfluence, Inc. will
        work with the applicable third-party controller to facilitate such
        choice.&nbsp; Also, AIfluence, Inc. will provide you with notice before
        using your transfer data for a purpose other than that for which it was
        originally collected or subsequently authorized by you, and you may choose
        to “opt out” of such use by following the directions provided in the
        notice.&nbsp; However, even after any “opt-out”, your transfer data may be
        used and disclosed to a third party upon a good faith belief that such
        disclosure is required in order to comply with an applicable statute,
        regulation, rule or law, a subpoena, a search warrant, a court or
        regulatory order, lawful requests by public authorities, including to meet
        national security or law enforcement requirements, or other valid legal
        process.&nbsp; Without limitation of any other provision contained herein,
        following your “opt out”, your information that already has been gathered
        may continue to be used and to be disclosed to third parties, provided
        that such information will be anonymized in order to ensure that you
        cannot be identified anymore.
      </p>

      <p>
        <strong>22.8 Data Integrity and Purpose Limitation:&nbsp;</strong>
        Except
        as may be authorized by you, we use your transfer data in a manner that is
        compatible with and relevant for the purpose for which it was
        collected.&nbsp; To the extent necessary for these purposes, we take
        reasonable steps to ensure that transfer data is accurate, complete,
        current, and reliable for its intended use.&nbsp; With respect to
        Processed Transfer Data, AIfluence, Inc.&apos;s obligations under this Section
        22.8 may be limited to working with the applicable third-party controller,
        given AIfluence, Inc.&apos;s role as a processor.
      </p>

      <p>
        <strong>22.9 Recourse, Enforcement and Liability:&nbsp;</strong>
        AIfluence,
        Inc. uses the self-assessment method to verify the attestations and
        assertions made herein and to ensure that its privacy practices have been
        implemented as presented herein. Any questions, complaints, or concerns
        regarding this privacy notice should be directed to AIfluence, Inc. in
        accordance with Section 21 above. AIfluence, Inc. will investigate and
        attempt to resolve any disputes/complaints, including correcting any
        transfer data, using transfer data consistent with the Privacy Shield
        Principles, reversing or correcting the effects of noncompliance, and
        assuring that future processing of transfer data will be in conformity
        with the Privacy Shield Principles. For any complaints regarding transfer
        data that cannot be resolved by AIfluence, Inc. directly, AIfluence, Inc.
        commits to cooperate with the panel established by the EU Data Protection
        Authorities (“
        <strong>DPAs</strong>
        ”) and the Swiss Federal Data
        Protection and Information Commissioner (“
        <strong>Commissioner</strong>
        ”),
        as applicable, to investigate unresolved disputes/complaints and comply
        with the advice given by the panel and/or Commissioner. With respect to
        the application of Privacy Shield Principles to transfer data, the panel
        established by the DPAs and/or the Commissioner, as applicable, is the
        independent dispute resolution body designed to address complaints and
        provide appropriate recourse to you free of charge. As further described
        in the Privacy Shield Principles, a binding arbitration option will be
        made available to you in order to address residual complaints regarding
        transfer data that have not been resolved by other means. See Section C of
        Annex I to the Privacy Shield Principles at&nbsp;
        <a href="https://www.privacyshield.gov/article?id=C-Pre-Arbitration-Requirements">
          https://www.privacyshield.gov/article?id=C-Pre-Arbitration-Requirements
        </a>
        &nbsp;and&nbsp;
        <a href="http://trade.gov/td/services/odsi/swiss-us-privacyshield-framework.pdf">
          http://trade.gov/td/services/odsi/swiss-us-privacyshield-framework.pdf
        </a>
        . The Federal Trade Commission has jurisdiction over AIfluence, Inc.’s
        compliance with the Privacy Shield. Adherence by AIfluence, Inc. to the
        Privacy Shield Principles and the above-set forth provisions regarding
        transfer data may be limited (a) to the extent necessary to meet national
        security, public interest or law enforcement requirements; (b) by statute,
        government regulation, or case law that creates conflicting obligations or
        explicit authorizations; or (c) if the effect of the GDPR or Member State
        law is to allow exceptions or derogations, provided that such exceptions
        or derogations are applied in comparable contexts.
      </p>

      <p>Effective as of May 25, 2020. Last updated on August 31, 2021.</p>
    </Box>
  );
};

export default PrivacyPolicy;
