import React, { useRef, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { createLink, getAllDomains, getFBCampaignUrlsInfo, getAllApps } from "./../../../../services/manageDynamicLinks";
import { getPlatformList } from "./../../../../services/campaigns";
import { getAllGroupsListForCampaign } from "./../../../../services/group";

import { useDispatch } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import CustomizedTable from "../../../../components/common/customizedTable";
import styles from "../../../../styles/internal/Account/AccountUsers.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { logout } from "../../../../redux/features/user/userSlice";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import { getCampaignName } from "../../../../utility/sessionData";

import CampaignFBLinksManageActionButton from "../../campaignAnalytics/campaignLinks/CampaignFBLinksManageActionButton";

function ManageDynamicLinks({
  BlockLoginAccess,
  width,
  user,
  cid,
  openShortUrlCreate,
  setOpenShortUrlCreate,
  setselectedUrl,
  setSetselectedUrl,
}) {
  const elementRef = useRef(null);
  const [link, setLink] = useState("");
  const [domainUriPrefix, setDomainUriPrefix] = useState("");
  const [linkError, setLinkError] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [iosBundleId, setIosBundleId] = useState("");
  const [domainValidation, setDomainValidation] = useState(true);
  const [linkValidation, setLinkValidation] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortCol, setSortCol] = useState("createdOn");
  const [sortOrder, setSortOrder] = useState("desc");
  const [iosAppStoreId, setIosAppStoreId] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("all");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [platforms, setPlatforms] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState("");
  const [forAll, setForAll] = useState(false);
  const [skipPreview, SetSkipPreview] = useState(false);
  const [campaignName] = React.useState(getCampaignName);
  const [urlInformation, setUrlInformation] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [appsData, setAppsData] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedAppIds, setSelectedAppIds] = useState("");

  const handlepackageNameChange = (event) => {
    const value = event.target.value;
    setPackageName(value);
  };

  const handleiosBundleIdChange = (event) => {
    const value = event.target.value;
    setIosBundleId(value);
  };
  const handleOpenCreateLinke = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
    setDomainUriPrefix("");
    setPackageName("");
    setIosBundleId("");
    setIosAppStoreId("");
    setDomainValidation(false);
    setSelectedApp("");
    setUrlInformation("");
    setLink("");
  };

  const handleLinkChange = (event) => {
    const value = event.target.value;
    setLink(value);
    setLinkError(validateUrl(value));
    if (validateUrl(value) === "") {
      setLinkValidation(true);
    } else {
      setLinkValidation(false);
    }
  };

  const handleDomainUriPrefixChange = (event) => {
    const value = event.target.value;
    setDomainUriPrefix(value);
    if (validateUrl(value) === "") {
      setDomainValidation(true);
    } else {
      setDomainValidation(false);
    }
  };

  const handleAppChange = (event) => {
    const value = event.target.value;
    setSelectedApp(value);
    if (value === "all") {
      setDomainUriPrefix("");
      setPackageName("");
      setIosBundleId("");
      setIosAppStoreId("");
      setDomainValidation(false);
      return;
    }
    const selectedAppId = appsData.find((app) => app.apps === value).id;
    setSelectedAppIds(selectedAppId);
    getDomains(selectedAppId);
  };

  const sendInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);
      setRefresh((refresh) => !refresh);
    }
  };

  const getFBCampaignUrlsData = async () => {
    const urls = await getFBCampaignUrlsInfo(cid, user, dispatch);
    if (urls.status === true) {
      return urls.response;
    }
  };

  const handleSubmit = async () => {
    const payload = {
      cid: cid,
      accountId: user.agencyId,
      domainPrefix: domainUriPrefix,
      link: link,
      packageName: packageName,
      iosBundleId: iosBundleId,
      iosAppStoreId: iosAppStoreId,
      forceRedirect: skipPreview,
      utmMedium: selectedPlatform.replace(/ /g, "_").toLowerCase(),
      utmCampaign: campaignName.replace(/ /g, "_").toLowerCase(),
      utmTerm: group.replace(/ /g, "_").toLowerCase(),
      lid: selectedGroup,
      forAll: forAll,
      urlText: urlInformation.trim(),
      appids: selectedAppIds,
    };
    const response = await createLink(payload, user, dispatch);
    setOpen(false);

    if (response?.response.status === "success") {
      dispatch(
        notify({
          message: "Dynamic Link Created!",
          type: "success",
        })
      );
    } else {
      dispatch(
        notify({
          message: "Could not create dynamic link",
          type: "error",
        })
      );
    }
    setRefresh((refresh) => !refresh);
  };

  const validateUrl = (value) => {
    if (!value) {
      return "This field is required.";
    }

    const urlPattern = /^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    if (!urlPattern.test(value)) {
      return "Invalid URL.";
    }

    return "";
  };

  const getDomains = async (ids) => {
    var payload = { id: ids };
    const result = await getAllDomains(payload, user, dispatch);
    if (result !== undefined && result.status === true) {
      setDomainUriPrefix(result.response[0].domain);
      setPackageName(result.response[0].package);
      setIosBundleId(result.response[0].iosBundle);
      setIosAppStoreId(result.response[0].iosAppStoreId);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const urls = (await getFBCampaignUrlsData()) || [];
        setTotalRows(urls?.length);
        createTableData(urls, page, rows, sortOrder, sortCol);
      } catch (error) {
        console.error(error);
      }
    };
    fetchApps();
    fetchData();
    GetPlatforms();
    getGroupList(selectedPlatform);
  }, [refresh]);

  const createTableData = (urls, page, rows, sortOrder, sortCol) => {
    let tableData = [...urls];

    tableData.sort((a, b) => {
      let comp;
      switch (sortCol) {
        case "appName": {
          comp = a.appName.localeCompare(b.appName) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "urlText": {
          comp = a.urlText.localeCompare(b.urlText) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "urlLink": {
          comp = a.url.localeCompare(b.url) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "group": {
          comp = a.listName.localeCompare(b.listName) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        case "forAllValue": {
          const aVal = a.forAll ? "Yes" : "No";
          const bVal = a.forAll ? "Yes" : "No";
          comp = aVal.localeCompare(bVal) * (sortOrder === "asc" ? 1 : -1);
          break;
        }
        default:
          break;
      }
      return comp;
    });

    tableData = tableData.slice(page * rows, (page + 1) * rows)?.map((url) => {
      return {
        ...url,
        urlLink: (
          <a href={url.url} rel="noreferrer" target="_blank">
            {url.url}
          </a>
        ),
        group: (
          <Grid container spacing={2} width="100%" justifyContent="center" direction="row">
            <Grid item className={styles.bodycell}>
              <Typography sx={textColor[url.platform]}>{snapshotHeaderIcons[url.platform]}</Typography>
            </Grid>
            <Grid item className={styles.bodycell} style={{ marginTop: 5 }}>
              <p>{url.listName}</p>
            </Grid>
          </Grid>
        ),
        forAllValue: <Typography ml="-20px">{url.forAll ? "Yes" : "No"}</Typography>,
        action: (
          <CampaignFBLinksManageActionButton
            url={url}
            setRefresh={setRefresh}
            setOpenShortUrlCreate={setOpenShortUrlCreate}
            setSetselectedUrl={setSetselectedUrl}
          />
        ),
      };
    });
    setTableData(tableData);
  };

  const handleGroupChange = (event, groups) => {
    const selectedLid = event.target.value;
    const selectedList = groups.find((list) => list.id === selectedLid);
    setGroup(selectedList["name"]);
    setSelectedGroup(selectedLid);
  };

  const GetPlatforms = async () => {
    const platforms = await getPlatformList(user, dispatch);
    if (platforms.status === true) {
      setPlatforms(platforms.response);
    } else {
      if (platforms.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: platforms.response?.message,
          type: "error",
        })
      );
    }
  };

  const fetchApps = async () => {
    var payload = { id: cid };
    const appData = await getAllApps(payload, user, dispatch);
    if (appData.status === true && appData.response !== null) {
      setAppsData(appData.response);
      return appData.response;
    }
  };

  const getGroupList = async (platform) => {
    const platformList = [];
    if (platform === "all") {
      platformList.push(null);
    } else {
      platformList.push(platform);
    }

    const groups = await getAllGroupsListForCampaign(cid, platformList, user, dispatch);
    if (groups.status === true) {
      setGroups(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };
  const tableHeaders = [
    {
      id: 1,
      label: "App",
      mappedValue: "App",
      field: "appName",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 2,
      label: "URL Description",
      mappedValue: "URL Description",
      field: "urlText",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "URL",
      mappedValue: "URL",
      field: "urlLink",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "Group",
      mappedValue: "Group",
      field: "group",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 5,
      label: "For All",
      mappedValue: "For All",
      field: "forAllValue",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: true,
    },
    {
      id: 6,
      label: "",
      mappedValue: "",
      field: "action",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: false,
    },
  ];

  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          {/* <h4 style={{ padding: 20 }}>
            <b className={styles.title}>Manage Dynamic Links </b>
          </h4> */}

          <Grid
            container
            item
            spacing={1}
            xs={12}
            style={{
              borderRadius: "10px",
              width: "calc(100% - 10px)",
              margin: "auto",
              paddingBottom: "12px",
            }}
          >
            <Grid item sm={10.5} />
            <Grid item sm={1.5}>
              <Button
                style={{
                  background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                  width: "120px",
                  borderRadius: "8px",
                  margin: "auto",
                  marginBottom: "15px",
                  marginLeft: "20px",
                }}
                variant="contained"
                color="success"
                onClick={handleOpenCreateLinke}
              >
                {" "}
                Create Link
              </Button>
            </Grid>
          </Grid>
          {totalRows > 0 ? (
            <Grid
              sm={12}
              style={{
                margin: "auto",
                padding: "12px",
              }}
            >
              <CustomizedTable
                tableHeaders={tableHeaders}
                bodyColumns={tableData}
                totalRows={totalRows}
                pageNo={page}
                rowsPerPageNo={rows}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
                sendInformation={sendInformation}
                staticTable={true}
              />
            </Grid>
          ) : (
            <>
              <div className={styles.container} style={{ textAlign: "center" }}>
                <div className={styles.child}>No dynamic links created for this campaign..</div>
              </div>
            </>
          )}

          <Dialog
            open={open}
            PaperProps={{
              style: {
                height: "auto",
                width: "100%",
              },
            }}
          >
            <DialogTitle>Create Dynamic Link</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                    <InputLabel>Choose application *</InputLabel>
                    <Select onChange={(event) => handleAppChange(event)} value={selectedApp} required>
                      {appsData?.map((app) => (
                        <MenuItem key={app.id} value={app.apps}>
                          {capitalizeFirstLetter(app.apps)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                    <InputLabel>Platform</InputLabel>
                    <Select
                      onChange={(e) => {
                        setSelectedPlatform(e.target.value);
                        setSelectedGroup("");
                        getGroupList(e.target.value);
                      }}
                      value={selectedPlatform}
                      required
                    >
                      <MenuItem key="all" value="all">
                        All Platforms
                      </MenuItem>
                      {platforms?.map((platform) => (
                        <MenuItem key={platform.name} value={platform.name}>
                          {capitalizeFirstLetter(platform.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl variant="filled" style={{ mt: 1, width: "100%" }}>
                    <InputLabel>Group *</InputLabel>
                    <Select onChange={(event) => handleGroupChange(event, groups)} value={selectedGroup} required>
                      {groups?.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          <Stack direction="row" spacing={2}>
                            <Typography sx={textColor[group.platform]}>{snapshotHeaderIcons[group.platform]}</Typography>
                            <Typography>{group.name}</Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="filled"
                    label="URL Description"
                    type="text"
                    value={urlInformation}
                    onChange={(e) => setUrlInformation(e.target.value)}
                    fullWidth
                    sx={{ mt: 1 }}
                    required
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      variant="filled"
                      label="DomainUriPrefix"
                      type="url"
                      id="domainUriPrefix"
                      value={domainUriPrefix}
                      onChange={handleDomainUriPrefixChange}
                      error={Boolean(linkError)}
                      helperText={linkError}
                      fullWidth
                      sx={{ mt: 2 }}
                      required
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12}>
                  <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      variant="filled"
                      label="Link"
                      type="url"
                      id="link"
                      value={link}
                      onChange={handleLinkChange}
                      error={Boolean(linkError)}
                      helperText={linkError}
                      fullWidth
                      sx={{ mt: 2 }}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      variant="filled"
                      label="Android Package"
                      type="text"
                      id="packageName"
                      value={packageName}
                      onChange={handlepackageNameChange}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      variant="filled"
                      label="iOS Bundle Id"
                      type="text"
                      id="iosBundleId"
                      value={iosBundleId}
                      onChange={handleiosBundleIdChange}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={skipPreview} onChange={(e) => SetSkipPreview(e.target.checked)} />}
                      label="Skip the app preview page (not recommended)"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={forAll} onChange={(e) => setForAll(e.target.checked)} />}
                      label="Create short link for all influencers in list"
                    />
                  </FormGroup>
                  <FormHelperText>* This option cannot be changed later</FormHelperText>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => handleCloseDialog()}>Cancel</Button>
              <Button
                disabled={!domainValidation || !linkValidation || !urlInformation || !group || !selectedApp}
                onClick={() => handleSubmit()}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {width <= 768 && <BlockLoginAccess openDialog={true} />}
    </div>
  );
}

export default ManageDynamicLinks;
