import configs from "../config/main.json";
import { PlatformIdMapping } from "../utility/plaformIcons";
import { getResponseType } from "../utility/exportData";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const { API_HOST, ORYX_HOSTNAME } = configs;
/* 
  get details of all campaigns
*/
const getAllGroupsListForCampaign = async (cid, platforms, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/campaign/listByPlatform`,
      data: JSON.stringify({
        cid,
        platforms,
      }),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getDetailedGroupsForCampaign = async (
  cid,
  plid,
  page,
  rows,
  SortCol,
  sortOrder,
  isExport,
  filter,
  startDate,
  endDate,
  isRefreshed,
  mimeType,
  campaignName,
  timezone,
  user,
  dispatch
) => {
  try {
    const platformIds = [];
    plid.forEach((platform) => platformIds.push(PlatformIdMapping[platform]));
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/groups`,
      data: JSON.stringify({
        cid,
        plid: platformIds,
        page,
        rows,
        SortCol,
        sortOrder,
        isExport,
        filter,
        startDate,
        endDate,
        isRefreshed,
        mimeType,
        campaignName,
        account: user.agencyId,
        client: user.clientId,
        path: "Campaigns.Fields.Groups",
        timezone,
        status: 1 
      }),
      responseType: getResponseType(isExport),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    if (!mimeType || mimeType == "") {
      return { status: response.ok, response: await Promise.resolve(response.json()) };
    }
    return { status: response.ok, response: await Promise.resolve(response.text()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAllGroupsForCampaign = async (cid, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/list/acc/id/${cid}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const addNewGroup = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/add`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const editGroup = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/edit`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const updateGroup = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/changeStatus`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export {
  getAllGroupsListForCampaign,
  getDetailedGroupsForCampaign,
  getAllGroupsForCampaign,
  addNewGroup,
  updateGroup,
  editGroup,
};
