import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";
import { genderOptions } from "./FormConstants";
import MultiselectLocations from "./multiselectLocations";
import { getAgeCategories, getIndustries, getInterestCategories, getLanguages } from "../../services/campaignBrief";

function Step3Brand({ setValid, step3Values, setStep3Values }) {
  const [languages, setLanguages] = useState(step3Values.languages);
  const [age, setAge] = useState(step3Values.age);
  const [ageOptions, setAgeOptions] = useState([]);
  const [gender, setGender] = useState(step3Values.gender);
  const [interests, setInterests] = useState(step3Values.interests);
  const [allInterests, setAllInterests] = useState([]);
  const [industry, setIndustry] = useState(step3Values.industry);
  const [industries, setIndustries] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [locations, setLocations] = useState(step3Values.locations);

  const getAgeOptionsData = async () => {
    const ageOptionsData = await getAgeCategories();

    if (ageOptionsData.status) {
      setAgeOptions(ageOptionsData.response);
    }
  };

  const getAllInterestsData = async () => {
    const allInterestsData = await getInterestCategories();

    if (allInterestsData.status) {
      setAllInterests(allInterestsData.response);
    }
  };

  const getIndustriesData = async () => {
    const industriesData = await getIndustries();

    if (industriesData.status) {
      setIndustries(industriesData.response);
    }
  };

  const getAllLanguagesData = async () => {
    const allLanguagesData = await getLanguages();

    if (allLanguagesData.status) {
      setAllLanguages(allLanguagesData.response);
    }
  };

  useEffect(() => {
    getAgeOptionsData();
    getAllInterestsData();
    getIndustriesData();
    getAllLanguagesData();
  }, []);

  useEffect(() => {
    let valid = false;
    if (locations?.length > 0) {
      valid = true;
    }
    setValid(valid);
  }, [locations, setValid]);

  useEffect(() => {
    const stepValues = {};
    stepValues.age = age;
    stepValues.gender = gender;
    stepValues.interests = interests;
    stepValues.industry = industry;
    stepValues.languages = languages;
    stepValues.locations = locations;
    setStep3Values(stepValues);
  }, [age, gender, interests, industry, languages, locations, setStep3Values]);

  const handleLocations = (value) => {
    if (value !== "") {
      setLocations((prev) => {
        const locations = [...prev];
        if (!locations.includes(value)) {
          locations.push(value);
        }
        return locations;
      });
    }
  };

  const removeLocation = (value) => {
    if (locations.includes(value)) {
      setLocations((prev) => {
        prev = prev?.filter((item) => item !== value);
        return prev;
      });
    }
  };

  return (
    <>
      <div className={styles.step_title}>Audience/Consumer Targets</div>
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <MultiselectLocations
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "20px",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            locationValues={locations}
            setLocationValues={handleLocations}
            removeLocation={removeLocation}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Age </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={ageOptions}
            disableCloseOnSelect
            value={age}
            onChange={(e, val) => setAge(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Gender </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={genderOptions}
            disableCloseOnSelect
            value={gender}
            onChange={(e, val) => setGender(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Interests </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={allInterests}
            disableCloseOnSelect
            value={interests}
            onChange={(e, val) => setInterests(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Industry </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={industries}
            disableCloseOnSelect
            value={industry}
            onChange={(e, val) => setIndustry(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <span className={styles.step_field}>Languages </span>
            <span className={styles.step_field_sub}> (optional)</span>
          </div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={allLanguages}
            disableCloseOnSelect
            value={languages}
            onChange={(e, val) => setLanguages(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </>
  );
}

Step3Brand.propTypes = {
  setValid: PropTypes.func.isRequired,
  step3Values: PropTypes.object.isRequired,
  setStep3Values: PropTypes.func.isRequired,
};

export default Step3Brand;
