import { Clear, Warning } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import styles from "../../styles/internal/Campaign/CampaignList.module.css";

const BlockLoginAccess = ({ openDialog }) => {
  const [open, setOpen] = React.useState(openDialog);
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: 700,
          height: 300,
        },
      }}
    >
      <DialogTitle>
        <b>
          <Warning sx={{ color: "#d6d61f" }} /> Desktop Login Recommended
        </b>
      </DialogTitle>
      <DialogContent className={styles.container}>
        <p className={styles.childContent}>
          Logging in via desktop ensures a superior user experience, please use desktop to login.
        </p>
      </DialogContent>
    </Dialog>
  );
};

export default BlockLoginAccess;
