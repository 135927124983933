import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import "../../styles/internal/DraftEditor.css";
import { useRef } from "react";

const MergeTagButton = ({ onChange, editorState }) => {
  const [open, setOpen] = useState(false);
  const dropdown = useRef(null);

  const options = [
    {
      text: "Amount",
      value: "{{amount}}",
    },
    {
      text: "Currency",
      value: "{{currency}}",
    },
    {
      text: "Duration",
      value: "{{duration}}",
    },
  ];

  const addMergeTag = (tag) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      tag,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const closeDropdown = (e) => {
    if (open && !dropdown.current.contains(e.target)) {
      setOpen(false);
    }
  };

  document.addEventListener("mousedown", closeDropdown);

  return (
    <div
      onClick={() => setOpen(!open)}
      className="rdw-block-wrapper"
      aria-label="rdw-block-control"
      role="button"
      tabIndex={0}
      ref={dropdown}
    >
      <div
        className="rdw-dropdown-wrapper rdw-block-dropdown"
        aria-label="rdw-dropdown"
        // style={{ width: 180 }}
      >
        <div className="rdw-dropdown-selectedtext">
          <span>Merge Tags</span>
          <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`} />
        </div>
        {open && (
          <ul className={`rdw-dropdown-optionwrapper ${open ? "" : "placeholder-ul"}`}>
            {options?.map((item) => (
              <li onClick={() => addMergeTag(item.value)} key={item.value} className="rdw-dropdownoption-default placeholder-li">
                {item.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const DraftEditor = ({ readOnly, value, setValue }) => {
  const [editorState, setEditorState] = useState();
  const [defined, setDefined] = useState(false);
  if (!defined) {
    if (value === null || value === undefined || value === "") {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(value);
      setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
    }
    setDefined(true);
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
    const newVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue(newVal);
  };

  return (
    <div className="draft-editor">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        readOnly={readOnly}
        wrapperClassName="draft-wrapper-class"
        editorClassName="draft-editor-class"
        toolbarClassName="draft-toolbar-class"
        toolbar={{
          options: ["inline", "blockType", "fontSize", "list", "textAlign", "link", "history", "remove"],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: {
            inDropdown: true,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"],
          },
          history: { inDropdown: true },
        }}
        toolbarCustomButtons={[<MergeTagButton />]}
      />
    </div>
  );
};

export default DraftEditor;
