import React, { useState, useEffect } from "react";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { Grid, Tooltip, Button } from "@mui/material";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { useDispatch, useSelector } from "react-redux";
import { getGaSummary } from "../../../../services/campaignAnalytics";
import Loader from "../../../common/loader";

const CampaignGaLinksTable = ({ groups, infs, platforms, campaignName, selectedStartDate, selectedEndDate, exportXlsx, cid }) => {
  const [bodyColumns, setBodyColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortCol, setSortCol] = useState("pageViews");
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalRows, setTotalRows] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [linksData, setLinksData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const tableHeadersSingleBase = [
    {
      id: 1,
      label: "Influencer",
      mappedValue: "influencerName",
      field: "influencerName",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: false,
    },
    {
      id: 2,
      label: "Page Views",
      mappedValue: "pageViews",
      field: "pageViews",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "Platform / UTM Medium",
      mappedValue: "platform",
      field: "platform",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "Group / UTM Term",
      mappedValue: "group",
      field: "group",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
  ];

  useEffect(() => {
    getLinkTableDataValues();
  }, []);

  useEffect(() => {
    if (tableData?.length === 0) {
      return;
    }
    createFinalData(linksData, page, rows, sortOrder, sortCol);
  }, [linksData, page, rows, sortOrder, sortCol]);

  const getLinkTableDataValues = async () => {
    setTableLoading(true);
    const startDate = getFormattedDate(selectedStartDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(selectedEndDate, "YYYY-MM-DD");
    const linksData = await getGaSummary(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      cid,
      user,
      dispatch,
      "google/analysis/basic"
    );
    if (linksData.response != null) {
      setLinksData(linksData.response);
      createFinalData(linksData.response, page, rows, sortOrder, sortCol);
    }
    setTableLoading(false);
  };

  const createFinalData = (data, page, rows, sortOrder, sortCol) => {
    if (data) {
      if (sortOrder === "desc") {
        data.sort(function (a, b) {
          if (a[sortCol] > b[sortCol]) return -1;
          if (a[sortCol] < b[sortCol]) return 1;
          return 0;
        });
      } else {
        data.sort(function (a, b) {
          if (a[sortCol] < b[sortCol]) return -1;
          if (a[sortCol] > b[sortCol]) return 1;
          return 0;
        });
      }
      const final_data = [];
      data.forEach((row) => {
        const object = {
          influencerName: (
            <Tooltip title={row.influencerName}>
              <a href={row.profileUrl} rel="noreferrer" target="_blank">
                {row.influencerName}
              </a>
            </Tooltip>
          ),
          pageViews: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.pageViews}
              </Grid>
            </Grid>
          ),
          platform: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.platform}
              </Grid>
            </Grid>
          ),
          group: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.listName}
              </Grid>
            </Grid>
          ),
        };
        final_data.push(object);
      });

      setTableData(final_data);
      setTotalRows(final_data?.length);
      createData(final_data.slice(page * rows, (page + 1) * rows));
    }
  };

  const sendInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);
    }
  };

  const createData = (tableData) => {
    setBodyColumns(tableData);
  };

  return (
    <>
      {!tableLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.background}>
              <h4 style={{ padding: 20, marginLeft: "150px" }}>
                <b>Link Details </b>
                <Button
                  variant="contained"
                  style={{
                    background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                    borderRadius: "8px",
                    float: "right",
                  }}
                  onClick={exportXlsx}
                >
                  Export XLSX
                </Button>
              </h4>
              {totalRows > 0 ? (
                <CustomizedTable
                  tableHeaders={tableHeadersSingleBase}
                  bodyColumns={bodyColumns}
                  totalRows={totalRows}
                  pageNo={page}
                  rowsPerPageNo={rows}
                  sortOrder={sortOrder}
                  sortOrderBy={sortCol}
                  sendInformation={sendInformation}
                  staticTable={true}
                />
              ) : (
                <div className={styles.container}>
                  <div className={styles.child}> No data available. Please expand the filters.</div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </>
  );
};

CampaignGaLinksTable.propTypes = {
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  shorturlIds: PropTypes.array.isRequired,
};

export default CampaignGaLinksTable;
