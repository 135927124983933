/* return a string after capitalizing the first letter of given string */
const capitalizeFirstLetter = (str) => {
  const wordsArray = str?.toLowerCase().split(/\s+/);
  const upperCased = wordsArray?.map(function (word) {
    return word?.charAt(0).toUpperCase() + word?.substr(1);
  });
  return upperCased?.join(" ");
};

const blockedEmails = ["gmail", "yahoo", "hotmail", "rediffmail"];

const getOrganizationNameFromEmail = function (email) {
  if (email) {
    const organisation_domain = email.split("@")[1];
    return capitalizeFirstLetter(organisation_domain.split(".")[0]);
  }
  return "";
};
/* number conversion based on international number system */
const convertToInternationalNumberSystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
};

const postTypeMapping = (postType) => {
  switch (postType) {
    case 1:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return "Post";
    case 2:
      return "Reel";
    case 3:
      return "Story";
    case 9:
      return "Poll";
    case 10:
      return "Space";
    default:
      return "Unknown";
  }
};

export {
  capitalizeFirstLetter,
  convertToInternationalNumberSystem,
  postTypeMapping,
  blockedEmails,
  getOrganizationNameFromEmail,
};
