import React from 'react'
import SecondaryStyledMenu from '../FilterComponents/common/SecondaryStyledMenu';
import { Button, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import utils from '../../../utility/oryxUtils/utils';


const ExportDropdown = (props) => {
  const { dropdownData, selectedValue, setSelectedValue, title } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        sx={{
          color: "black",
          background: "white",
          border: "1px solid lightgrey",
          borderRadius: "12px",
          "&:hover": { bgcolor: "lightgray" },
          whiteSpace: "nowrap",
          px: "12px",
          textTransform: "none",
          mr: "1rem",
          justifyContent: "space-between",
          boxShadow: 3,
          height: "50px",
          fontSize: "16px",
        }}
        id="styled-menu"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      >
        {utils.capitalizeFirstLetter(title)}:&nbsp; {selectedValue === "all" ? "All" : (utils.capitalizeFirstLetter(selectedValue))}
      </Button>
      <SecondaryStyledMenu
        id="styled-menu"
        MenuListProps={{
          "aria-labelledby": "styled-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        {dropdownData?.map((item, index) => (
          <MenuItem
            key={`${item}`}
            selected={item === selectedValue}
            onClick={() => {
              setSelectedValue(item);
              handleClose();
            }}
          >
            <Typography>
              {utils.capitalizeFirstLetter(item)}
            </Typography>
          </MenuItem>
        ))}
      </SecondaryStyledMenu>
    </>
  )
}

export default ExportDropdown
