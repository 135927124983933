import config from "../config/main.json";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const requestDemo = async (body) => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/requestdemo/submitresponse`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: headers,
    }
  );
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

export { requestDemo };
