import DateRangePicker from "react-bootstrap-daterangepicker";
import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect } from "react";

const DateRangeCustomPicker = ({
  sendSelectedDates,
  parentStartDate,
  parentEndDate,
  filterStatus = false,
  disabled = false,
  needRange = false,
}) => {
  const [startDate, setStartDate] = React.useState(moment(parentStartDate));
  const [endDate, setEndDate] = React.useState(moment(parentEndDate));
  const handleCalendarChange = (start, end) => {
    if (disabled) return;
    const startDate = moment(start);
    const endDate = moment(end);
    sendSelectedDates({ startDate: startDate, endDate: endDate });
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const formattedDateRange = `${startDate.format("DD-MMM-YY")} to ${endDate.format("DD-MMM-YY")}`;

  return (
    <DateRangePicker
      initialSettings={{
        startDate: startDate,
        endDate: endDate,
        ranges: needRange && {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [moment().subtract(1, "days").toDate(), moment().subtract(1, "days").toDate()],
          "This Week": [moment().startOf("week").toDate(), moment().toDate()],
          "Last Week": [
            moment().subtract(1, "weeks").startOf("week").toDate(),
            moment().subtract(1, "weeks").endOf("week").toDate(),
          ],
          "Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
          "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
          "This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
          "Last Month": [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
          ],
          "Last 6 Months": [moment().subtract(6, "months").toDate(), moment().toDate()],
        },
      }}
      onCallback={handleCalendarChange}
    >
      <input
        style={{
          lineHeight: 1.6,
          minWidth: "7vw",
          marginRight: "5px",
          borderRadius: "10px",
          background: "#F7F7F7",
        }}
        type="text"
        className="form-control"
        disabled={filterStatus}
        value={formattedDateRange}
      />
    </DateRangePicker>
  );
};

DateRangeCustomPicker.propTypes = {
  sendSelectedDates: PropTypes.func.isRequired,
  parentStartDate: PropTypes.string.isRequired,
  parentEndDate: PropTypes.string.isRequired,
};

export default DateRangeCustomPicker;
