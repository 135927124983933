import config from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const { API_HOST, HEIMDALL_HOSTNAME } = config;

export const getEngagementVsReachData = async (cid, filters, user, dispatch) => {
  try {
    const request = {
      hostname: HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/powerbi/engagementvsreach`,
      data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
    };
    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export const getEngagementVsReachWeeklyData = async (cid, filters, user) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/getWeeklyData`,
    data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};

export const getDataByLocation = async (cid, filters, user) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/reachByLocation`,
    data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};

export const getDataByGender = async (cid, filters, user) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/reachByGender`,
    data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};

export const getDataByAgeRange = async (cid, filters, user) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/reachByAgeRange`,
    data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};

export const getDataByPlatform = async (cid, filters, user) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/reachByPlatform`,
    data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};

export const getDataInfluencerEng = async (cid, filters, user) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/influencers`,
    data: JSON.stringify({ cid, ...filters, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};

export const getFilter = async (filterKey, user, cid) => {
  const request = {
    hostname: HEIMDALL_HOSTNAME,
    method: "POST",
    url: `${API_HOST}/campaign/powerbi/${filterKey}`,
    data: JSON.stringify({ cid, agencyId: user.agencyId }),
  };
  const signedRequest = createSignedRequest(request, user);
  return fetch(signedRequest.url, requestConfig(request, signedRequest));
};
