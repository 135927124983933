const monthArray = [
  "01 - January",
  "02 - February",
  "03 - March",
  "04 - April",
  "05 - May",
  "06 - June",
  "07 - July",
  "08 - August",
  "09 - September",
  "10 - October",
  "11 - November",
  "12 - December",
];

const statusToNum = {
  Live: 1,
  Draft: 2,
  Paused: 3,
  Scheduled: 4,
  Completed: 5,
  Archived: 6,
  Archived_Draft: 7,
  Deleted: 8,
};

export { monthArray, statusToNum };
