import {
  Autocomplete,
  Box,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { snapshotHeaderIcons, textColor } from "../../utility/plaformIcons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const GroupDropdown = ({
  groupList,
  sendSelectedGroups,
  parentSelectedGroup,
  disabled = false,
}) => {
  const [selectedGroup, setSelectedGroup] = React.useState(parentSelectedGroup);

  const selectGroup = (value, event) => {
    const jsonObject = event?.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet)?.map(JSON.parse);
    setSelectedGroup(uniqueArray);
    sendSelectedGroups(uniqueArray);
  };

  React.useEffect(() => {
    setSelectedGroup(parentSelectedGroup);
  }, [parentSelectedGroup, selectedGroup]);

  const checkIfExists = (option) => {
    const ids = selectedGroup?.map((group) => group.id);
    if (ids?.indexOf(option.id) > -1) return true;
    return false;
  };

  return (
    <Stack spacing={2}>
      <Autocomplete
        id="multiple-limit-tags"
        disableCloseOnSelect
        multiple
        limitTags={0}
        disableClearable
        options={groupList}
        onChange={(event, newValue) => {
          selectGroup(event, newValue);
        }}
        value={selectedGroup}
        isOptionEqualToValue={(option, value) => option.id === value.id} // Custom equality test
        getOptionLabel={(option) => option.name}
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={checkIfExists(option) || selected}
            />
            <Typography sx={textColor[option.platform]}>
              {snapshotHeaderIcons[option.platform]}
              &nbsp;&nbsp;
            </Typography>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Groups"
            placeholder="Groups"
            sx={{
              "& .MuiOutlinedInput-root": {
                minWidth: "7vw",
                marginRight: "5px",
                borderRadius: "10px",
              },
            }}
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Stack>
  );
};

GroupDropdown.propTypes = {
  groupList: PropTypes.array.isRequired,
  sendSelectedGroups: PropTypes.func.isRequired,
  parentSelectedGroup: PropTypes.array.isRequired,
};

export default GroupDropdown;
