import config from "../config/main.json";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const getBusinessTypes = async () => {
  const response = await fetch(`${config.UNAUTH_API_HOST}/business/types`);

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const createAccount = async (data) => {
  const response = await fetch(`${config.UNAUTH_API_HOST}/account/add`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const createUser = async (data) => {
  const response = await fetch(`${config.HEIMDALL_HOST}/users`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
}
export { getBusinessTypes, createAccount, createUser };
