import { Close, OpenWith } from "@mui/icons-material";
import { Button, Box, Fade, Grid, IconButton, LinearProgress, Modal, Paper, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import styles from "../../styles/internal/Campaign/CampaignAnalytics.module.css";
const ProgressChart = ({ data, chartTitle, backgroundColor, expandButtonVisible }) => {
  const [expandTable, setExpandTable] = useState(false);

  const renderData = (expandFlag) => {
    const topValues = expandFlag ? data : data.slice(0, 4);

    return topValues?.map((data) => {
      const title = data.title;
      const percentage = parseInt(data.percentage);
      const color = data.color;
      const value = data.value;
      return (
        <>
          <Paper sx={{ width: "100%" }} style={{ padding: "15px" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <span class="progress_chart-title">{title}</span>
              <span class="progress_chart-value">
              {value }
                ({percentage}%)
              </span>
            </Stack>
            <Box style={{ color: color }}>
              <LinearProgress variant="determinate" key={{ title }} value={percentage} color="inherit" />
            </Box>
          </Paper>
          <br />
        </>
      );
    });
  };

  const expandButton = () => {
    return (
      <Stack direction="row" justifyContent="end">
        <Button style={{ backgroundColor: "#BDBDBD" }} variant="contained" onClick={expandChart} endIcon={<OpenWith />}>
          Expand
        </Button>
      </Stack>
    );
  };

  const expandChart = () => {
    setExpandTable(true);
  };

  const handleTableModalClose = () => {
    setExpandTable(false);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, md: 12 }}
        style={{
          backgroundColor: backgroundColor,
          padding: 10,
        }}
      >
        <Grid item xs={4} md={12}>
          <h4>
            <b className={styles.title}>{chartTitle}</b>
          </h4>
        </Grid>
        <Grid item xs={4} md={12}>
          {renderData(false)}
          {expandButtonVisible && expandButton()}
        </Grid>
      </Grid>
      <Modal className={styles.stylesModal} open={expandTable} onClose={handleTableModalClose} closeAfterTransition>
        <Fade in={expandTable}>
          <div className={styles.sylesPaper} style={{ width: "50%", overflowY: "scroll", maxHeight: "500px" }}>
            <div id="modal-expanded_progress" className={styles.stylesModalHeading}>
              <h4>
                <b className={styles.title}>{chartTitle}</b>
              </h4>
              <IconButton onClick={handleTableModalClose}>
                <Close />
              </IconButton>
            </div>
            <hr />
            {renderData(true)}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

ProgressChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartTitle: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  expandButtonVisible: PropTypes.bool.isRequired,
};
export default ProgressChart;
