import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { HistoryOutlined, InfoOutlined } from '@mui/icons-material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const PostActionButton = ({ id, cid, historyEnabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div style={{ pointer: 'cursor' }}>
        <MoreVertIcon onClick={handleClick} size="small" />
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={Link}
          to={`/account/posts/draft/${id}?campaignId=${cid}`}
        >
          <InfoOutlined size="small" sx={{ mr: 2 }} />
          Get Post Details
        </MenuItem>
        {!historyEnabled && (
          <MenuItem component={Link} to={`/account/posts/${id}/history`}>
            <HistoryOutlined size="small" sx={{ mr: 2 }} />
            Get Post History
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

PostActionButton.propTypes = {
  id: PropTypes.number.isRequired,
  cid: PropTypes.number.isRequired,
  historyEnabled: PropTypes.bool.isRequired
};

export default PostActionButton;
