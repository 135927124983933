import { Box } from "@mui/material";
import React, { useEffect } from "react";
import "../../styles/internal/Landing/Landing.css";

function Testimonials() {
  useEffect(() => {
    slider();
  }, []);

  const createDots = function () {
    const slides = document.querySelectorAll(".slide");
    const dotContainer = document.querySelector(".dots");

    slides.forEach(function (_, i) {
      dotContainer.insertAdjacentHTML("beforeend", `<button class="dots__dot" data-slide="${i}"></button>`);
    });
  };

  const slider = function () {
    const slides = document.querySelectorAll(".slide");
    const btnLeft = document.querySelector(".slider__btn--left");
    const btnRight = document.querySelector(".slider__btn--right");
    const dotContainer = document.querySelector(".dots");

    let curSlide = 0;
    const maxSlide = slides?.length;

    const activateDot = function (slide) {
      document.querySelectorAll(".dots__dot").forEach((dot) => dot.classList.remove("dots__dot--active"));
      document.querySelector(`.dots__dot[data-slide="${slide}"]`).classList.add("dots__dot--active");
    };

    const goToSlide = function (slide) {
      slides.forEach((s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`));
    };

    // Next slide
    const nextSlide = function () {
      if (curSlide === maxSlide - 1) {
        curSlide = 0;
      } else {
        curSlide++;
      }

      goToSlide(curSlide);
      activateDot(curSlide);
    };

    // Previous slide
    const prevSlide = function () {
      if (curSlide === 0) {
        curSlide = maxSlide - 1;
      } else {
        curSlide--;
      }
      goToSlide(curSlide);
      activateDot(curSlide);
    };

    // Initialization
    const init = function () {
      if (dotContainer.innerHTML.trim() === "") {
        createDots();
      }
      goToSlide(0);
      activateDot(0);
    };
    init();

    // Event handlers
    btnRight.addEventListener("click", nextSlide);
    btnLeft.addEventListener("click", prevSlide);

    document.addEventListener("keydown", function (e) {
      if (e.key === "ArrowLeft") prevSlide();
      e.key === "ArrowRight" && nextSlide();
    });

    dotContainer.addEventListener("click", function (e) {
      if (e.target.classList.contains("dots__dot")) {
        const { slide } = e.target.dataset;
        goToSlide(slide);
        activateDot(slide);
      }
    });
  };

  return (
    <div style={{ background: "#F5F5F5", padding: "30px" }}>
      <section className="section" id="section--3">
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            color: "#3734A9",
            textTransform: "uppercase",
            letterSpacing: "1px",
          }}
        >
          Testimonials
        </div>
        <Box
          style={{
            textAlign: "center",
            fontWeight: "800",
            marginTop: "20px",
          }}
          sx={{ fontSize: { xs: "30px !important", md: "40px !important" } }}
        >
          Check what our customers are saying
        </Box>
        <div className="slider">
          <div className="slide">
            <div className="testimonial">
              <blockquote className="testimonial__text">
                We are very pleased with the campaigns so far and the insights we are receiving about our customers. We are back
                again 3rd time in a row!
              </blockquote>
              <address className="testimonial__author">
                <img src="img/user-1.jpg" alt="" class="testimonial__photo" />

                <h6 className="testimonial__name">Ken Gathuru</h6>
                <p className="testimonial__location">Sony</p>
              </address>
            </div>
          </div>
          <div className="slide">
            <div className="testimonial">
              <blockquote className="testimonial__text">
                Their unique proposition of identifying authentic voices to drive behavior change has delivered impactful results
                for our campaigns. They leverage AI to identify the right opinion leaders on Social Media…
              </blockquote>
              <address className="testimonial__author">
                <img src="img/user-1.jpg" alt="" class="testimonial__photo" />

                <h6 className="testimonial__name">Peter Muriithi</h6>
                <p className="testimonial__location">ALX</p>
              </address>
            </div>
          </div>
          <button className="slider__btn slider__btn--left">&larr;</button>
          <button className="slider__btn slider__btn--right">&rarr;</button>
          <div className="dots"></div>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
