export const getEngagementBreakup = (data) => {
  return (
    <>
      <b style={{ fontSize: "14px" }}>Engagement Breakup</b>
      {data.like > 0 && (
        <>
          <br />
          <b>{"Likes :"}</b> <span>{data.like}</span>
        </>
      )}
      {data.comment > 0 && (
        <>
          <br />
          <b>{"Comments :"}</b> <span>{data.comment}</span>
        </>
      )}
      {data.profileClick > 0 && (
        <>
          <br />
          <b>{"Profile Clicks :"}</b> <span>{data.profileClick}</span>
        </>
      )}
      {data.reply > 0 && (
        <>
          <br />
          <b>{"Replies :"}</b> <span>{data.reply}</span>
        </>
      )}
      {data.retweet > 0 && (
        <>
          <br />
          <b>{"Re-Tweets :"}</b> <span>{data.retweet}</span>
        </>
      )}
      {data.share > 0 && (
        <>
          <br />
          <b>{"Shares :"}</b> <span>{data.share}</span>
        </>
      )}
    </>
  );
};