import config from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";
const { API_HOST, ORYX_HOSTNAME, HEIMDALL_HOST, HEIMDALL_HOSTNAME } = config;

const checkIntegration = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/integration/checkStatus`,
      data: JSON.stringify(data),
    };
    

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const updateConfig = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/integration/google/updateConfig`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAppApiKey = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/integration/google/getAppKey`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const handleAuthorizationResponse = async (user, dispatch,intgrationType) => {
  try {
    const cid=  localStorage.getItem("googleCid");
    const aid=  parseInt(localStorage.getItem("googleAid"));
    const queryParams = new URLSearchParams(window.location.search);
    let authorizationCode = queryParams.get('code');
    if (authorizationCode == null) {
      authorizationCode = "";
    }
    if (user.agencyId ==0 && authorizationCode=="") {
      return;
    }
    var data = {
      "accountId": aid,
      "cid": cid,
      "code": authorizationCode,
      "type":intgrationType
    }
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/integration/google/accesstoken`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }

    return { status: response.ok, response: await Promise.resolve(response.json()) };

  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};


const getZappierToken = async (data, user, dispatch) => {
  const body = JSON.stringify(data);
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const response = await fetch(`${HEIMDALL_HOST}/generateToken`, {
    method: "POST",
    headers,
    body,
  });
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};

/*const getZappierToken = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: HEIMDALL_HOSTNAME,
      method: "POST",
      url: `${HEIMDALL_HOST}/generateToken`,
      data: JSON.stringify(data),
    };
    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status !== 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
   // dispatch(logout());
  }
};*/

export { checkIntegration, updateConfig, getAppApiKey, handleAuthorizationResponse, getZappierToken };
