import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip } from "@mui/material";

const DiscoveryActionBtn = ({ menuOptions, setActiveMenuItem, activeMenuItem, handleModalOpen, isHidden }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isHidden) {
    return null;
  }
  

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {menuOptions?.map((option) =>
          option.helpText ? (
            <Tooltip title={option.helpText}>
              <MenuItem
                key={option.id}
                onClick={() => {
                  option.action();
                  handleClose();
                }}
                selected={activeMenuItem === option.name}
                disabled={option.isDisabled}
              >
                {option.icon}
                <span style={{ display: "inline-block", marginInline: "6px" }}>{option.name}</span>
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem
              key={option.id}
              onClick={() => {
                option.action();
                handleClose();
              }}
              selected={activeMenuItem === option.name}
              disabled={option.isDisabled}
            >
              {option.icon}
              <span style={{ display: "inline-block", marginInline: "6px" }}>{option.name}</span>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default DiscoveryActionBtn;
