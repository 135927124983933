import { Box, Grid, IconButton } from "@mui/material";
import config from "../../../config/main.json";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Add } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import { InfoToolTip } from "../../common/toolTip";

const DiscoveryIcon = `${config.BASE_IMAGE_URL}image_75.svg`;
const ContractIcon = `${config.BASE_IMAGE_URL}image_77.svg`;
const AnalyticsIcon = `${config.BASE_IMAGE_URL}image_79.svg`;


const CampaignWizard = () => {
  const navigate = useNavigate();

  const selectCampaignType = (eventType) => {
    navigate(`/app/campaigns/create/wizard?eventType=${eventType}`);
  };

  const handleClose = () => navigate(`/app/campaigns`);
  return (
    <Box m={3} sx={{ backgroundColor: "#F7F7F7", padding: "10px", textAlign: "center" }}>
      <Box display="flex" justifyContent="flex-end" style={{ paddingRight: "10px", paddingTop: "10px" }}>
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box m={3} mt={0}>
        <h2 style={{ fontSize: "54px", fontWeight: 600, lineHeight: "72px", textAlign: "center" }}>Campaign Wizard</h2>
        <h3 style={{ fontSize: "24px", fontWeight: 500, lineHeight: "28px", textAlign: "center" }}>
          Launch an Influencer Campaign in minutes following the guided steps.
        </h3>
        <br />
        <br />
        <h3 style={{ fontSize: "28px", fontWeight: 500, lineHeight: "28px", textAlign: "center" }}>Choose a Campaign Type</h3>
        <br />
        <Grid container spacing={4}>
          <Grid item xs={4} style={{ cursor: "pointer" }} onClick={() => selectCampaignType("end-to-end")}>
            <Box
              m={3}
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "10px",
                cursor: "pointer",
                height: "90%",
                transition: "background-color 0.3s", // Add transition for smoother color change
                position: "relative", // To position the info icon absolutely
                "&:hover": {
                  backgroundColor: "#CCCCCC", // Darker gray color on hover
                },
              }}
            >
              <Grid container spacing={2} style={{ justifyContent: "left", paddingTop: "20px" }}>
                <Grid item xs={3}>
                  <Box component={"img"} src={DiscoveryIcon} alt="Empowr" mx="auto" pl={2} sx={{ width: '60px' }} />
                </Grid>{" "}
                <Grid item xs={1} style={{ paddingTop: "30px" }}>
                  <Add pl={5} />
                </Grid>
                <Grid item xs={3}>
                  <Box component={"img"} src={ContractIcon} alt="Empowr" mx="auto" pl={2} sx={{ width: '60px' }} />
                </Grid>
                <Grid item xs={1} style={{ paddingTop: "30px" }}>
                  <Add pl={5} />
                </Grid>
                <Grid item xs={3}>
                  <Box component={"img"} src={AnalyticsIcon} alt="Empowr" mx="auto" pl={2} sx={{ width: '60px' }} />
                </Grid>
              </Grid>
              <h2 style={{ fontSize: "34px", fontWeight: 700, paddingTop: "30px", textAlign: "left" }}>End-to-end Campaign</h2>
              <h3 style={{ fontSize: "20px", fontWeight: 500, paddingTop: "20px", textAlign: "left" }}>
                Includes Influencer Discovery, Contracting & Analytics{" "}
              </h3>
              <div style={{ position: "absolute", bottom: 10, right: 10, }}>
                <InfoToolTip
                  text={
                    "This campaign flow encompasses the entire influencer marketing lifecycle: identifying and selecting the right influencers, conducting outreach and securing contracts, collaborating to create and approve content, launching the campaign, and evaluating performance to enhance future campaigns. It includes every stage from planning and execution to measurement, analysis, and reporting."
                  }
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ cursor: "pointer" }} onClick={() => selectCampaignType("contract")}>
            <Box
              m={3}
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "10px",
                cursor: "pointer",
                height: "90%",
                transition: "background-color 0.3s", // Add transition for smoother color change
                position: "relative",
                "&:hover": {
                  backgroundColor: "#CCCCCC", // Darker gray color on hover
                },
              }}
            >
              <Grid container spacing={3} style={{ justifyContent: "left", paddingTop: "20px" }}>
                <Grid item xs={3}>
                  <Box component={"img"} src={ContractIcon} alt="Empowr" mx="auto" pl={2} sx={{ width: '60px' }} />
                </Grid>
                <Grid item xs={1} style={{ paddingTop: "35px" }}>
                  <Add pl={5} />
                </Grid>
                <Grid item xs={3}>
                  <Box component={"img"} src={AnalyticsIcon} alt="Empowr" mx="auto" pl={2} sx={{ width: '60px' }} />
                </Grid>
              </Grid>
              <h2 style={{ fontSize: "34px", fontWeight: 700, paddingTop: "30px", textAlign: "left" }}>
                Contracting & Analytics
              </h2>
              <h3 style={{ fontSize: "20px", fontWeight: 500, paddingTop: "20px", textAlign: "left" }}>
                Invite influencers directly and analyze their performance.
              </h3>
              <div style={{ position: "absolute", bottom: 10, right: 10, }}>
                <InfoToolTip
                  text={
                    "This campaign flow is perfect for those who want to work with pre-selected influencers, handle contracts through the platform, and manage the rest of the campaign independently. It includes influencer contracting, content approval tools, and campaign performance tracking, covering all stages from execution to measurement, analysis, and reporting." 
                  }
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ cursor: "pointer" }} onClick={() => selectCampaignType("analytics")}>
            <Box
              m={3}
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "10px",
                cursor: "pointer",
                height: "90%",
                transition: "background-color 0.3s", // Add transition for smoother color change
                position: "relative",
                "&:hover": {
                  backgroundColor: "#CCCCCC", // Darker gray color on hover
                },
              }}
            >
              <Grid container spacing={3} style={{ justifyContent: "left", paddingTop: "20px" }}>
                <Grid item xs={3}>
                  <Box component={"img"} src={AnalyticsIcon} alt="Empowr" mx="auto" pl={2} sx={{ width: '60px' }} />
                </Grid>
              </Grid>
              <h2 style={{ fontSize: "34px", fontWeight: 700, paddingTop: "30px", textAlign: "left" }}>
                Analytics Only Campaign
              </h2>
              <h3 style={{ fontSize: "20px", fontWeight: 500, paddingTop: "20px", textAlign: "left" }}>
                Track publications and analyze Influencer performance
              </h3>
              <div style={{ position: "absolute", bottom: 10, right: 10, }}>
                <InfoToolTip
                  text={
                    "This campaign flow is ideal for those who only want to monitor and track a campaign. It focuses solely on publication tracking, analysis, and reporting." 
                  }
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CampaignWizard;
