import { Avatar, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { snapshotHeaderIcons, textColor } from "../../../../../utility/plaformIcons";
import configs from "../../../../../config/main.json";
import PropTypes from "prop-types";
import styles from "../../../../../styles/internal/Campaign/CampaignList.module.css";

const ViewNoteDialog = ({ influencerAccount, viewNoteDialog, closeViewNoteDialog }) => {
  const scroll = "paper";
  const descriptionElementRef = useRef(null);

  React.useEffect(() => {}, [influencerAccount]);
  const handleClose = () => {
    closeViewNoteDialog(false);
  };
  return (
    <Dialog
      open={viewNoteDialog}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Avatar
              variant="circle"
              src={influencerAccount.photoUrl}
              onError={(e) => {
                e.target.src = configs.PROFILE_IMG;
              }}
            />
            
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={styles.profileHeading}>
                {influencerAccount.name}
              </Grid>
              <Grid item xs={12} className={styles.subbodycell}>
                @ {influencerAccount.userName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={textColor[influencerAccount?.platform.toLowerCase()]}>
              {snapshotHeaderIcons[influencerAccount?.platform.toLowerCase()]}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <>
            <h5 className={styles.cardData}>{influencerAccount?.notes ? influencerAccount?.notes : "No note found"}</h5>
          </>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

ViewNoteDialog.propTypes = {
  influencerAccount: PropTypes.object.isRequired,
  viewNoteDialog: PropTypes.bool.isRequired,
  closeViewNoteDialog: PropTypes.func.isRequired,
};

export default ViewNoteDialog;
