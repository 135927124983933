import YouTubeIcon from "@mui/icons-material/YouTube";
import config from "../config/main.json";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Article, Facebook, LinkedIn, List } from "@mui/icons-material";
import InsightsIcon from "@mui/icons-material/Insights";
import AddLinkIcon from "@mui/icons-material/AddLink";

const TikTokIconURL = `${config.BASE_IMAGE_URL}tiktok.svg`;

const commonBoxStyles = {
  minHeight: "50px",
  minWidth: "150px",
  padding: "auto",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const boxStyles = {
  instagram: {
    ...commonBoxStyles,
    backgroundColor: "#E91F63",
    borderRadius: "8px",
  },
  twitter: {
    ...commonBoxStyles,
    backgroundColor: "#1DA1F2",
    borderRadius: "8px",
  },
  tiktok: {
    ...commonBoxStyles,
    backgroundColor: "#010101",
    borderRadius: "8px",
  },
  youtube: {
    ...commonBoxStyles,
    backgroundColor: "#C4302B",
    borderRadius: "8px",
  },
  linkedin: {
    ...commonBoxStyles,
    backgroundColor: "#344767",
    borderRadius: "8px",
  },
  facebook: {
    ...commonBoxStyles,
    backgroundColor: "#4267B2",
    borderRadius: "8px",
  },
};

export const headerStyle = {
  instagram: {
    ...commonBoxStyles,
    border: "2px solid #E91F63",
    borderRadius: "10px",
  },
  twitter: {
    ...commonBoxStyles,
    border: "2px solid #1DA1F2",
    borderRadius: "10px",
  },
  tiktok: {
    ...commonBoxStyles,
    border: "2px solid #010101",
    borderRadius: "10px",
  },
  youtube: {
    ...commonBoxStyles,
    border: "2px solid #C4302B",
    borderRadius: "10px",
  },
  linkedin: {
    ...commonBoxStyles,
    border: "2px solid #344767",
    borderRadius: "10px",
  },
  facebook: {
    ...commonBoxStyles,
    border: "2px solid #4267B2",
    borderRadius: "10px",
  },
};

export const textColor = {
  instagram: {
    color: "#E91F63",
  },
  twitter: {
    color: "#1DA1F2",
  },
  tiktok: {
    color: "#181717",
  },
  youtube: {
    color: "#C4302B",
  },
  linkedin: {
    color: "#344767",
  },
  facebook: {
    color: "#4267B2",
  },
};

export const snapshotHeaderIcons = {
  instagram: <InstagramIcon sx={{ float: "left" }} />,
  twitter: <TwitterIcon sx={{ float: "left" }} />,
  tiktok: <img src={TikTokIconURL} alt="TikTok Icon" style={{ width: "20px", height: "27px", float: "left" }} />,
  youtube: <YouTubeIcon sx={{ float: "left" }} />,
  linkedin: <LinkedIn sx={{ float: "left" }} />,
  facebook: <Facebook sx={{ float: "left" }} />,
};

export const snapshotHeaderIcons2 = {
  instagram: <InstagramIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  twitter: <TwitterIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  tiktok: <img src={TikTokIconURL} alt="TikTok Icon" style={{ width: "35px", height: "35px", float: "left" }} />,
  youtube: <YouTubeIcon sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  linkedin: <LinkedIn sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
  facebook: <Facebook sx={{ float: "left" }} style={{ width: "35px", height: "35px" }} />,
};

export const otherIcons = {
  insights: <InsightsIcon sx={{ float: "left" }} />,
  addLink: <AddLinkIcon sx={{ float: "left" }} />,
  contracts: <List sx={{ float: "left" }} />,
  contractTemplate: <Article sx={{ float: "left" }} />,
};

export const PlatformIdMapping = {
  instagram: 1,
  twitter: 2,
  tiktok: 3,
  youtube: 4,
  linkedin: 5,
  facebook: 6,
};

export const IdPlatformMapping = {
  1: "instagram",
  2: "twitter",
  3: "tiktok",
  4: "youtube",
  5: "linkedin",
  6: "facebook",
};
