import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, Stack, Typography, Fade, Avatar } from '@mui/material';
import CardHeader from '../../../common/CardHeader/CardHeader';
import { Close, OpenWith } from "@mui/icons-material";
import styles from "../../../common/ProgressChart/ProgressChart.module.css";
import { styled } from '@mui/material';


const CustomAvatar = styled(Avatar)(() => ({
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}))

const ListData = (props) => {
  const { listTitle, helperText = "", expandButtonVisible, data } = props;

  const [expandTable, setExpandTable] = useState(false);

  const expandChart = () => {
    setExpandTable(true);
  };

  const handleTableModalClose = () => {
    setExpandTable(false);
  };

  const ExpandButton = () => {
    return (
      <Stack direction="row" justifyContent="end">
        <Button style={{ backgroundColor: "#BDBDBD" }} variant="contained" onClick={expandChart} endIcon={<OpenWith />}>
          Expand
        </Button>
      </Stack>
    );
  };

  const renderListData = (expandFlag) => {
    const topValues = expandFlag ? data : data.slice(0, 5);
    return (
      topValues?.map((item, idx) => (
        <Box display="flex" alignItems="center" justifyContent="space-between" key={`list-item-${idx}`} mb="1rem">
          <Box display="flex" alignItems="center">
            <CustomAvatar
              alt={item.name}
              src={item.logo}
              sx={{ width: 30, height: 30, mr: "12px" }}
            />
            <Typography fontSize={expandFlag ? '16px' : "14px"}>
              {item.name}
            </Typography>
          </Box>
          <Typography fontSize={expandFlag ? '16px' : "14px"}>
            {item.percentage}{!(item.percentage.includes("%") && "%")}
          </Typography>
        </Box>
      ))
    )
  }

  return (
    <>
      <CardHeader title={listTitle} toolTipText={helperText} />
      <Box>
        {renderListData()}
      </Box>
      {expandButtonVisible && <ExpandButton />}
      <Modal className={styles.stylesModal} open={expandTable} onClose={handleTableModalClose} closeAfterTransition>
        <Fade in={expandTable}>
          <div className={styles.sylesPaper} style={{ width: "50%", overflowY: "scroll", maxHeight: "500px" }}>
            <div id="modal-expanded_progress" className={styles.stylesModalHeading}>
              <h4>
                <b className={styles.title}>{listTitle.replace("Top ", "")}</b>
              </h4>
              <IconButton onClick={handleTableModalClose}>
                <Close />
              </IconButton>
            </div>
            <hr />
            {renderListData(true)}
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default ListData