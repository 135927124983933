import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useScroll from "../../../../hooks/useScroll";
import {
  Container,
  Box,
  Typography,
  Skeleton,
  Grid,
  Button,
} from "@mui/material";
import BasicDetails from "./components/BasicDetails/BasicDetails";
import AudienceInfo from "./components/AudienceInfo/AudienceInfo";
import InfluencerInfo from "./components/InfluencerInfo/InfluencerInfo";
import ContentInfo from "./components/ContentInfo/ContentInfo";
import FallbackWrapper from "./components/common/FallbackWrapper/FallbackWrapper";
import CustomToggleButtonGroup from "./components/common/CustomToggleButtonGroup/CustomToggleButtonGroup";
import { getProfileData } from "../../../../services/imai/getProfileData";
import utils from "../../../../utility/oryxUtils/utils";
import ScrollToTop from "../../../common/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import { logout } from "../../../../redux/features/user/userSlice";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Spinner } from "react-bootstrap";
import configs from "../../../../config/main.json";

const tabOptions = [
  {
    key: "influencer",
    value: "Influencer",
  },
  {
    key: "audience",
    value: "Audience",
  },
  {
    key: "content",
    value: "Content",
  },
];

const AnalyzeProfile = () => {
  const { id, platform } = useParams();
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState("influencer");
  const [scrollOntoInfluencer, influencerRef] = useScroll();
  const [scrollOntoAudience, audienceRef] = useScroll();
  const [scrollOntoContent, contentRef] = useScroll();
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrorMsg] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let fromNotifcations = params.get("fromNotifcation");

  const basicRef = useRef(null);

  const user = useSelector((state) => state.user);

  const scrollHandler = () => {
    const yPos = window.scrollY;
    if (yPos < 540) setCurrentSection("influencer");
  };

  useEffect(() => {
    // fetch user profile data
    setIsLoading(true);
    let data;
    if (fromNotifcations === null) {
      data = {
        scid: id,
        platform,
        agencyId: user.agencyId,
        notificationMetadata: {
          userId: `agency/${user.agencyId}`,
          sourcePlatform: configs.PLATFORM_NAME,
          notificationTypeId: "4",
          notificationActionId: "2",
          notificationCategoryId: "2",
          generatedBy: user.email,
          priority: "high",
          consumedBy: "empowr",
          navigateTo: `/discovery/analyze/${platform}/${id}?fromNotifcation=true`,
        },
      };
    } else {
      data = {
        scid: id,
        platform,
        agencyId: user.agencyId,
      };
    }
    getProfileData(data, user, dispatch)
      .then((res) => {
        setIsLoading(false);
        if (res?.status === 403 || res?.status === 401) {
          dispatch(
            notify({
              message: res?.message,
              type: "error",
            })
          );
          dispatch(logout());
        }
        if (res?.status === "success") {
          setProfileData(res.data);
          setErrorMsg(null);
        } else {
          setErrorMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setProfileData(null);
        setErrorMsg(err.toString());
      });

    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [id, platform]);

  const handleChange = (_, newSection) => {
    setCurrentSection(newSection ? newSection : currentSection);
    switch (newSection) {
      case "influencer":
        return scrollOntoInfluencer();
      case "audience":
        return scrollOntoAudience();
      case "content":
        return scrollOntoContent();
      default:
        return scrollOntoInfluencer();
    }
  };

  const downloadPDF = async () => {
    setDownloading(true);
    const fileName = profileData?.["profile_info"]?.["name"] + "-" + id;

    const p1 = basicRef.current;
    const p2 = influencerRef.current;
    const p3 = audienceRef.current;
    const p4 = contentRef.current;

    const w = p1.offsetWidth + 50;
    const h =
      10 +
      p1.offsetHeight +
      10 +
      p2.offsetHeight +
      10 +
      p3.offsetHeight +
      10 +
      p4.offsetHeight;

    const doc = new jsPDF("p", "px", [w, h]);

    await html2canvas(p1, { scale: 3, useCORS: true }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg", 1);
      doc.addImage(img, "JPEG", 25, 10, p1.offsetWidth, p1.offsetHeight);
    });

    await html2canvas(p2, { scale: 3, useCORS: true }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg", 1);
      doc.addImage(
        img,
        "JPEG",
        25,
        10 + p1.offsetHeight + 10,
        p2.offsetWidth,
        p2.offsetHeight
      );
    });

    await html2canvas(p3, { scale: 3, useCORS: true }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg", 1);
      doc.addImage(
        img,
        "JPEG",
        25,
        10 + p1.offsetHeight + 10 + p2.offsetHeight + 10,
        p3.offsetWidth,
        p3.offsetHeight
      );
    });

    await html2canvas(p4, { scale: 3, useCORS: true }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg", 1);
      doc.addImage(
        img,
        "JPEG",
        25,
        10 + p1.offsetHeight + 10 + p2.offsetHeight + 10 + p3.offsetHeight + 10,
        p4.offsetWidth,
        p4.offsetHeight
      );
    });

    doc.save(fileName);
    setDownloading(false);
  };

  const renderContentHandler = () => {
    let content = <LoaderSkeleton />;
    if (isLoading) content = <LoaderSkeleton />;
    else if (!isLoading && !profileData)
      content = (
        <>
          Unable to fetch user's profile
          <br />
          {errMsg && <p>{errMsg}</p>}
        </>
      );
    else
      content = (
        <>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              sx={{ my: "25px" }}
              variant="contained"
              onClick={downloadPDF}
              disabled={downloading}
            >
              {!downloading && "Download PDF"}
              {downloading && (
                <Typography>
                  <Spinner size="sm" style={{ marginRight: "5px" }} />{" "}
                  Downloading...
                </Typography>
              )}
            </Button>
          </Box>
          <Box ref={basicRef}>
            <BasicDetails
              data={profileData?.["profile_info"]}
              platform={profileData?.["platform"]}
            />
          </Box>
          <Box>
            <CustomToggleButtonGroup
              options={tabOptions}
              selectedOption={currentSection}
              handleChange={handleChange}
            />
          </Box>
          <Box component={"section"} ref={influencerRef} sx={{ my: "2rem" }}>
            <FallbackWrapper
              isError={utils.checkIsObjectEmpty(profileData["influencer_data"])}
            >
              <InfluencerInfo
                data={profileData?.["influencer_data"]}
                platform={profileData?.["platform"]}
              />
            </FallbackWrapper>
          </Box>
          <Box component={"section"} ref={audienceRef} sx={{ my: "2rem" }}>
            <Typography variant="h3" component={"h3"}>
              AUDIENCE DATA
            </Typography>
            <FallbackWrapper
              isError={utils.checkIsObjectEmpty(profileData["audience_data"])}
            >
              <AudienceInfo
                data={profileData?.["audience_data"]}
                platform={profileData?.["platform"]}
              />
            </FallbackWrapper>
          </Box>
          <Box component={"section"} ref={contentRef} sx={{ my: "2rem" }}>
            <Typography variant="h3" component={"h3"} sx={{ mb: "2rem" }}>
              CONTENT
            </Typography>
            <FallbackWrapper isError={!profileData["contentData"]?.length}>
              <ContentInfo
                data={profileData?.["contentData"]}
                platform={profileData?.["platform"]}
              />
            </FallbackWrapper>
          </Box>
          <ScrollToTop btnSxProps={{ left: "12%" }} />
        </>
      );
    return content;
  };

  return (
    <>
      <Container>{renderContentHandler()}</Container>
    </>
  );
};

export default AnalyzeProfile;

//loading screen layout
const LoaderSkeleton = () => (
  <>
    <Typography sx={{ my: "1rem" }}>
      Fetching Influencer's Information
    </Typography>
    <Skeleton
      variant="rectangular"
      height={300}
      sx={{ mb: "1rem", borderRadius: "6px" }}
    />
    <Skeleton
      variant="rectangular"
      height={100}
      sx={{ mb: "1rem", borderRadius: "6px" }}
    />
    <Grid container spacing={3}>
      {Array(3)
        .fill(1)
        ?.map((_, idx) => (
          <Grid
            xs
            item
            key={`skel-${idx}`}
            sx={{ mb: "1rem", borderRadius: "6px" }}
          >
            <Skeleton variant="rectangular" height={100} />
          </Grid>
        ))}
    </Grid>
    <Grid container spacing={3}>
      {Array(3)
        .fill(1)
        ?.map((_, idx) => (
          <Grid xs item key={`skel-${idx}`}>
            <Skeleton
              variant="rectangular"
              height={298}
              sx={{ mb: "1rem", borderRadius: "6px" }}
            />
          </Grid>
        ))}
    </Grid>
    <Skeleton
      variant="rectangular"
      height={100}
      sx={{ mb: "1rem", borderRadius: "6px" }}
    />
    <Grid container spacing={3}>
      {Array(2)
        .fill(1)
        ?.map((_, idx) => (
          <Grid xs item key={`skel-${idx}`}>
            <Skeleton
              variant="rectangular"
              height={298}
              sx={{ mb: "1rem", borderRadius: "6px" }}
            />
          </Grid>
        ))}
    </Grid>
    <Skeleton
      variant="rectangular"
      height={200}
      sx={{ mb: "1rem", borderRadius: "6px" }}
    />
    <Grid container spacing={3}>
      {Array(2)
        .fill(1)
        ?.map((_, idx) => (
          <Grid xs item key={`skel-${idx}`}>
            <Skeleton
              variant="rectangular"
              height={298}
              sx={{ mb: "1rem", borderRadius: "6px" }}
            />
          </Grid>
        ))}
    </Grid>
    <Skeleton
      variant="rectangular"
      height={300}
      sx={{ mb: "1rem", borderRadius: "6px" }}
    />
  </>
);
