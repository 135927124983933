import * as React from "react";
import { Autocomplete, Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../common/StyledComponents";
import { Cached } from "@mui/icons-material";
import PropTypes from "prop-types";
import styles from "../../../styles/internal/Campaign/CampaignList.module.css";
import { tableHeaders } from "./CommonAssets";
import SelectColumns from "../../common/selectColumns";
import { refreshCampaignStatus } from "../../../services/campaigns";
import { notify } from "../../../redux/features/system/systemAlert";
import { useDispatch, useSelector } from "react-redux";
import ExportFiles from "../../common/exportfiles";
import SearchItem from "../../common/searchitem";
import { UpgradePlanToolTipText } from "../../common/toolTip";
import { useLocation, useNavigate } from "react-router-dom";
import { checkForCredit } from "../../../services/credit";
import SortBy from "../../common/sortBy";
import { statusToNum } from "../../common/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CampaignFilters = ({
  page,
  rows,
  sortCol,
  sortOrder,
  sendForCampaignDetails,
  changeHeaders,
  changeAlignment,
  alignment,
  activeCampaigns,
  archivedCampaigns,
  user,
  statusFilter,
  status,
  brandList, 
  brandFilter
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sortByColumns = [
    { name: "Campaign Name (A-Z)", value: "name" },
    { name: "Recently Created", value: "createdAt" },
    { name: "Recently Updated", value: "updatedAt" },
  ];
  const [selectedStatus, setSelectedStatus] = React.useState(status);
  const [selectedValue, setSelectedValue] = React.useState("");
  const menuData = useSelector((state) => state.menu);
  const campaignPermissions = menuData.permissions.Campaigns;
  const [credit, setCredit] = React.useState(0);
  React.useEffect(() => {
    checkForCreditValue();
  }, []);

  const checkForCreditValue = async () => {
    const data = {
      name: ["Campaigns"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    const campaignsCount = response?.response.message[data.name[0]];
    setCredit(isNaN(parseInt(campaignsCount)) ? 0 : parseInt(campaignsCount));
  };
  const handleChange = (event, newAlignment) => {
    changeAlignment(newAlignment);
    sendForCampaignDetails({
      alignment: newAlignment,
      export: 0,
      page: 0,
      rows,
      sortCol,
      sortOrder,
      filter: "",
      refresh: false,
    });
  };

  const updateHeaders = (event) => {
    /* This is added for future use , when we will call campaign listing api on column selection */
    sendForCampaignDetails({
      alignment,
      export: 0,
      page: 0,
      rows,
      sortCol,
      sortOrder,
      filter: "",
      refresh: false,
    });
    changeHeaders(event);
  };

  const sendExportSelected = (event) => {
    sendForCampaignDetails({
      alignment,
      export: 1,
      page: page,
      rows,
      sortCol,
      sortOrder,
      filter: "",
      mimeType: event,
      refresh: false,
    });
  };

  const getStatusOption = (type) => {
    let options = Object.keys(statusToNum).filter((status) => !["Archived", "Archived_Draft", "Deleted"].includes(status));
    if (type === 0) options = ["Archived", "Archived_Draft"];
    if (type === 2) options = ["Deleted"];
    return options;
  };


  const sendSearchedValue = (event) => {
    setTimeout(() => {
      sendForCampaignDetails({
        alignment,
        export: 0,
        page: 0,
        rows,
        sortCol,
        sortOrder,
        filter: event,
        refresh: false,
      });
    }, 500);
  };

  const chooseValue = (event) => {
    setSelectedValue(event);
    sendForCampaignDetails({
      alignment,
      export: 0,
      page: 0,
      rows,
      sortCol: event,
      sortOrder: "desc",
      filter: "",
      refresh: false,
    });
  };
  const openNewCampaign = () => {
    navigate(`${location.pathname}/create`);
  };

  const selectStatusFilter = (value) => {
    setSelectedStatus(value !== null ? statusToNum[value].toString() : "");
    statusFilter(value !== null ? statusToNum[value].toString() : "")
  }

  const selectBrandFilter = (value) => {
    brandFilter(value !== null ? value.id.toString() : "")
  }
  return (
    <>
      <Box my={3} sx={{ width: "100%" }}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12} sm={12}>
            {/* Toggle for Archieved and Active Campaign */}
            <StyledToggleButtonGroup
              color="primary"
              size="small"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Campaign"
            >
              <StyledToggleButton value="1" className={styles.toggleButton} disabled={alignment === "1"}>
                Active Campaigns ({activeCampaigns})
              </StyledToggleButton>
              <StyledToggleButton value="0" className={styles.toggleButton} disabled={alignment === "0"}>
                Archived Campaigns ({archivedCampaigns})
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            position={{ md: "absolute" }}
            right={{ md: 25, xs: 0, sm: 0 }}
            paddingBottom={{ md: 0, sm: 5, xs: 5 }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <SearchItem sendSearchedValue={sendSearchedValue} />
              </Grid>
              {campaignPermissions?.NewCampaign?.Visible && (
                
                <Grid item>
                  <Tooltip
                    title={
                      (!campaignPermissions?.NewCampaign?.Editable ||
                      credit === 0 &&
                        user.agencyId !== 0) &&
                        UpgradePlanToolTipText(!campaignPermissions?.NewCampaign?.Editable ? "" : "credit")
                    }
                  >
                    <StyledStaticButtonGroup color="primary" size="small" exclusive aria-label="Campaign">
                      <StyledStaticButton
                        value="active"
                        className={styles.staticButton}
                        disabled={!campaignPermissions?.NewCampaign?.Editable || (credit === 0 && user.agencyId !== 0)}
                        onClick={() => openNewCampaign()}
                      >
                        <b>+ New Campaign</b>
                      </StyledStaticButton>
                    </StyledStaticButtonGroup>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Refresh, Select Column and Export As */}
      </Box>
      {/* Refresh, Select Column and Export As */}
      <Grid container spacing={1}>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start" }}>
          <Grid container>
            <Grid item xs={6} md={6} lg={4}>
              <SortBy sortByColumns={sortByColumns} selectedValue={selectedValue} setSelectedValue={chooseValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid container spacing={2} justifyContent={{ xs: "flex-start", md: "flex-end" }}>
            {brandList.length > 1 && 
              <Grid item xs={4}>
                <Autocomplete
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      fontSize: "0.875rem",
                      borderRadius: "10px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.875rem",
                    },
                  }}
                  disablePortal
                  id="filter-by-brand"
                  options={brandList}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(event, value) => selectBrandFilter(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter by Brand" variant="outlined" size="small" MenuProps={MenuProps} />
                  )}
                />
              </Grid>
            }
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "0.875rem",
                    borderRadius: "10px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.875rem",
                  },
                }}
                disablePortal
                id="filter-by-status"
                options={getStatusOption(alignment)}
                onChange={(event, value) => selectStatusFilter(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Status" variant="outlined" size="small" MenuProps={MenuProps} />
                )}
              />
            </Grid>
            <Grid item>
              <SelectColumns tableHeaders={tableHeaders} updateHeaders={updateHeaders} />
            </Grid>
            <Grid item>
              <ExportFiles sendExportSelected={sendExportSelected} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
    </>
  );
};

CampaignFilters.propTypes = {
  page: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  sortCol: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sendForCampaignDetails: PropTypes.func.isRequired,
  changeHeaders: PropTypes.func.isRequired,
  changeAlignment: PropTypes.func.isRequired,
  alignment: PropTypes.string.isRequired,
  activeCampaigns: PropTypes.number.isRequired,
  archivedCampaigns: PropTypes.number.isRequired,
  account: PropTypes.number.isRequired,
  lastRefreshTime: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default CampaignFilters;
