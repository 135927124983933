import { Button, Grid, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Country } from "country-state-city";
import Testimonials from "../../components/landing/Testimonials";
import TopBrands from "../../components/landing/TopBrands";
import { notify } from "../../redux/features/system/systemAlert";
import { submitContactUs } from "../../services/help";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import configs from "../../config/main.json";

function Help() {
  const user = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (name.trim() && email.trim() && issue.trim() && country && company.trim()) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [name, email, issue, country, company]);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const dispatch = useDispatch();

  const validateEmail = () => {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return regex.test(email);
  };

  const success = (event) => {
    event.preventDefault();
    dispatch(
      notify({
        message: "Please wait while we submit your query",
        type: "info",
      })
    );
    if (disableSubmit) {
      dispatch(
        notify({
          message: "Fields are empty!",
          type: "error",
        })
      );
      return;
    }

    if (!validateEmail()) {
      dispatch(
        notify({
          message: "Invalid Email!",
          type: "error",
        })
      );
      return;
    }
    const notificationMetadata = {
      userId: name.trim(),
      sourcePlatform: configs.PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "9",
      generatedBy: email.trim(),
      priority: "low",
      consumedBy: "oryx",
      navigateTo: "/bdTools/empowrQueries",
    };
    const body = {
      name: name.trim(),
      email: email.trim(),
      issue: issue.trim(),
      country: country.trim(),
      company: company.trim(),
      notificationMetadata,
    };

    submitContactUs(body).then((response) => {
      if (response.status) {
        setName("");
        setEmail("");
        setIssue("");
        setCountry("");
        setCompany("");
        dispatch(
          notify({
            message: "Issue submitted successfully!",
            type: "success",
          })
        );
      }
    });
  };

  return (
    <div>
      <div style={{ backgroundColor: "white" }}>
        <div style={{ width: "90%", margin: "auto", padding: "140px 0 140px" }}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <div style={{ width: "80%", margin: "auto" }}>
                <div className={styles.helpTitle}>Get In Touch</div>
                <div className={styles.helpContent}>
                  Facing an issue or looking for an upgrade? Reach out we'd are eager to help you or hearing about your project.
                </div>
                <div className={styles.helpSubTitle}>Write To Us</div>
                <div style={{ marginTop: "20px" }}>support@empowr.ai</div>
                <div className={styles.helpSubTitle}>Visit Us</div>
                <div style={{ marginTop: "20px", fontWeight: "700" }}>Nairobi, Kenya</div>
                <div style={{ width: "80%" }}>8th Floor, Sanlam Tower, Waiyaki Way, Westlands</div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ width: "80%", margin: "auto" }}>
                <form onSubmit={success}>
                  <div style={{ marginTop: "8px" }}>Name</div>
                  <TextField
                    variant="filled"
                    color="info"
                    style={{ width: "100%" }}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div style={{ marginTop: "8px" }}>Business Email</div>
                  <TextField
                    variant="filled"
                    color="info"
                    style={{ width: "100%" }}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div style={{ marginTop: "8px" }}>Country</div>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <InputLabel>Select Country</InputLabel>
                    <Select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries?.map((country) => (
                        <MenuItem key={country.isoCode} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div style={{ marginTop: "8px" }}>Company</div>
                  <TextField
                    variant="filled"
                    color="info"
                    style={{ width: "100%" }}
                    required
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <div style={{ marginTop: "8px" }}>Describe Your Issue</div>
                  <TextField
                    variant="filled"
                    color="info"
                    style={{ width: "100%" }}
                    multiline
                    rows={4}
                    required
                    value={issue}
                    onChange={(e) => setIssue(e.target.value)}
                  />
                  <Button variant="contained" style={{ backgroundColor: "black", width: "100%" }} type="submit">
                    Submit
                  </Button>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Book a call */}
      <div style={{ backgroundColor: "white", paddingBottom: "60px" }}>
        <div
          style={{
            width: "80%",
            margin: "auto",
            padding: "60px",
            border: "1px solid #DEE1E6",
            borderRadius: "20px",
            fontSize: "34px",
            lineHeight: "46px",
            fontWeight: "800",
            textAlign: "center",
          }}
        >
          If you're looking for something special,{" "}
          <a href="/requestDemo" style={{ textDecoration: "underline" }}>
            book a call
          </a>{" "}
          with us.
        </div>
      </div>
      {/* Testimonials */}
      <Testimonials />
      {/* Top Brands */}
      <TopBrands />
    </div>
  );
}

export default Help;
