import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { deleteLinkUrl } from "../../../../services/campaignAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";

const CampaignLinksDeleteDestinationDialog = ({
  url,
  urlId,
  setRefresh,
  open,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleDelete = async () => {
    await deleteLinkUrl(urlId, user, dispatch);
    setRefresh((refresh) => !refresh);
    setOpen(false);
    dispatch(
      notify({
        message: "URL deleted successfully",
        type: "success",
      })
    );
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "auto",
          width: "100%",
        },
      }}
    >
      <DialogTitle>Delete this URL?</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to permanently delete this destination URL -{" "}
          <a href={url} rel="noreferrer" target="_blank">
            {url}
          </a>
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignLinksDeleteDestinationDialog;
