import { CloudUpload, Info } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { logout } from "../../redux/features/user/userSlice";
import { getAccountInformation, getTimezoneData, saveGeneralChanges } from "../../services/settings";

function General() {
  const [id, setId] = useState();
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [address, setAddress] = useState("");
  const [taxId, setTaxId] = useState("");
  const [currency, setCurrency] = useState(0);
  const [currencyList, setCurrencyList] = useState([]);
  const [timezone, setTimezone] = useState(36);
  const [timezoneList, setTimezoneList] = useState([]);
  const [change, setChange] = useState(false);

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const getCurrencyList = async () => {
    const currencyList = [
      { id: 0, name: "$ USD" },
      { id: 1, name: "₹ INR" },
      { id: 2, name: "€ EUR" },
    ];

    setCurrencyList(currencyList);
  };

  const getTimezoneList = async () => {
    const response = await getTimezoneData("all", user, dispatch);

    if (response.status === true) {
      setTimezoneList(response?.response);
    }
  };

  const getAccountInfo = async () => {
    const response = await getAccountInformation(user, dispatch);

    if (response.status === true) {
      const data = response?.response[0];
      setId(data.id);
      setCompanyName(data.name);
      setCompanyLocation(data.location);
      setAddress(data.address);
      setTaxId(data.taxId);
      setCurrency(data.currency);
      setTimezone(data.timezone);
    } else {
      if (response?.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  const getAllInfo = async () => {
    await getCurrencyList();
    await getTimezoneList();
    await getAccountInfo();
  };

  useEffect(() => {
    getAllInfo();
  }, []);

  const handleLocationChange = (event) => {
    setCompanyLocation(event.target.value);
    setChange(true);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setChange(true);
  };

  const handleTaxIdChange = (event) => {
    setTaxId(event.target.value);
    setChange(true);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    setChange(true);
  };

  const handleTimezoneChange = (event, value) => {
    setTimezone(value.id);
    setChange(true);
  };

  const saveChanges = async () => {
    const data = {
      id: id,
      accountId: user.agencyId,
      location: companyLocation,
      address: address,
      taxId: taxId,
      currency: currency,
      timezone: timezone,
    };

    const response = await saveGeneralChanges(data, user, dispatch);

    if (response.status === true) {
      setChange(false);
      dispatch(
        notify({
          message: "Changes Saved!",
          type: "success",
        })
      );
    } else {
      if (response?.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "#FAFAFB",
          borderRadius: "10px",
          width: "calc(100% - 10px)",
          margin: "auto",
          marginBottom: "25px",
        }}
      >
        <Info color="primary" style={{ margin: "10px" }} />
        <span
          style={{
            fontSize: "1rem",
            fontWeight: 400,
            color: "#757575",
            marginLeft: "15px",
          }}
        >
          Changes will be shared across your Brands in the organization.
        </span>
      </Box>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#757575",
          marginBottom: "12px",
        }}
      >
        Company Details
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "#FAFAFB",
          borderRadius: "10px",
          width: "calc(100% - 10px)",
          margin: "auto",
          paddingBottom: "12px",
        }}
      >
        <Grid container item spacing={3} xs={12}>
          <Grid item sm={3}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              value={companyName}
              label="Company Name"
              helperText="Contact Support to change Company Name"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              value={companyLocation}
              onChange={handleLocationChange}
              label="Company Location"
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              style={{ width: "calc(100% - 20px)" }}
              variant="filled"
              value={address}
              onChange={handleAddressChange}
              label="Address"
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              value={taxId}
              onChange={handleTaxIdChange}
              label="Tax ID (VAT/EIN/TIN/GST)"
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#757575",
          marginTop: "25px",
          marginBottom: "12px",
        }}
      >
        Currency
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "#FAFAFB",
          borderRadius: "10px",
          width: "calc(100% - 10px)",
          margin: "auto",
          paddingBottom: "12px",
        }}
      >
        <Grid container item spacing={2} xs={12}>
          <Grid item sm={3}>
            <Select
              value={currency}
              onChange={handleCurrencyChange}
              style={{
                width: "100%",
              }}
              sx={{
                fieldset: {
                  legend: {
                    width: "unset",
                  },
                },
              }}
            >
              {currencyList?.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sm={0.3} style={{ display: "flex", alignItems: "center" }}>
            <Info style={{ color: "#B5B5C3" }} />
          </Grid>
          <Grid item sm={8.7} style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color: "#B5B5C3" }}>
              Select the currency in which you would like to see your data and billing. This will affect all users in the account.
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#757575",
          marginTop: "25px",
          marginBottom: "12px",
        }}
      >
        Time Zone
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "#FAFAFB",
          borderRadius: "10px",
          width: "calc(100% - 10px)",
          margin: "auto",
          paddingBottom: "12px",
        }}
      >
        <Grid container item spacing={2} xs={12}>
          <Grid item sm={3}>
            {timezoneList?.length > 0 && (
              <Autocomplete
                disableClearable
                options={timezoneList}
                getOptionLabel={(timezone) => timezone.text}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={timezoneList[timezone]}
                onChange={handleTimezoneChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        legend: {
                          width: "unset",
                        },
                      },
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item sm={0.3} style={{ display: "flex", alignItems: "center" }}>
            <Info style={{ color: "#B5B5C3" }} />
          </Grid>
          <Grid item sm={8.7} style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color: "#B5B5C3" }}>
              Select the time zone in which you would like to view your data. This will affect all users in this account.
            </span>
          </Grid>
        </Grid>
      </Grid>
      {false && (
        <>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#757575",
              marginTop: "25px",
              marginBottom: "12px",
            }}
          >
            Company Logo
          </Typography>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "#FAFAFB",
              borderRadius: "10px",
              width: "calc(100% - 10px)",
              margin: "auto",
              paddingBottom: "12px",
            }}
          >
            <Grid container item xs={12}>
              <Grid item sm={3}>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: "220px",
                    borderRadius: "10px",
                    height: "50px",
                    fontSize: "1.3rem",
                  }}
                  variant="contained"
                  color="success"
                  endIcon={<CloudUpload style={{ transform: "scale(1.3)", marginLeft: "30px" }} />}
                >
                  Upload
                </Button>
              </Grid>
              <Grid item sm={0.3} style={{ display: "flex", alignItems: "center" }}>
                <Info style={{ color: "#B5B5C3" }} />
              </Grid>
              <Grid item sm={8.7} style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: "#B5B5C3" }}>Upload company logo to customize your reports, landing pages and portal.</span>
              </Grid>
              <Grid item sm={3}>
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "#7B809A",
                    marginLeft: "25px",
                  }}
                >
                  JPEG, PNG only. Under 2MB File.
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#757575",
              marginTop: "25px",
              marginBottom: "12px",
            }}
          >
            GDPR and Data regulations
          </Typography>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "#FAFAFB",
              borderRadius: "10px",
              width: "calc(100% - 10px)",
              margin: "auto",
              paddingBottom: "12px",
            }}
          >
            <Grid container item xs={12}>
              <FormControl>
                <FormLabel id="radio-buttons">Include influencer contact information in CSV export?</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons"
                  defaultValue="1"
                  name="radio-buttons-group"
                  style={{ marginLeft: "20px" }}
                >
                  <FormControlLabel value="1" control={<Radio />} label="I must not process contact information (GDPR)." />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="It’s okay, I’m allowed to process details like contact info."
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      <br />
      <Grid container>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          {change && (
            <Button variant="contained" onClick={saveChanges} style={{ float: "right", marginRight: "30px" }}>
              Save Changes
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default General;
