import { Box } from "@mui/system";
import { object } from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const MuiPhoneInput = ({ InputProps, ...other }) => {
  const { endAdornment, ...inputProps } = InputProps;

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <PhoneInput
        {...other}
        inputProps={inputProps}
      />
      {endAdornment && <Box sx={{ position: "absolute", top: "50%", right: "15px" }}>{endAdornment}</Box>}
    </Box>
  );
};

MuiPhoneInput.propTypes = {
  InputProps: object,
};

export default MuiPhoneInput;
