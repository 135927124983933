const TwitterCommentIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11.0973C7.27614 11.0973 7.5 11.3287 7.5 11.6142V13.6384C10.2487 11.9785 11.9025 10.8043 12.8999 9.77901C13.4787 9.18403 13.7971 8.67665 13.982 8.19283C14.1679 7.70636 14.25 7.16282 14.25 6.44481C14.25 3.87533 12.2353 1.79235 9.75 1.79235H6.25C3.76472 1.79235 1.75 3.87533 1.75 6.44481C1.75 9.01429 3.76472 11.0973 6.25 11.0973H7ZM7.5 15.1324C14.1641 11.1673 15.5 9.54325 15.5 6.44481C15.5 3.16158 12.9256 0.5 9.75 0.5H6.25C3.07436 0.5 0.5 3.16158 0.5 6.44481C0.5 9.72803 3.07436 12.3896 6.25 12.3896V14.9832C6.25 15.3787 6.66196 15.6275 6.9943 15.4317C7.16618 15.3305 7.33473 15.2307 7.5 15.1324Z"
      fill="#687684"
      stroke="#687684"
    />
  </svg>
);

export default TwitterCommentIcon;
