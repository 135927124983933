import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getPlatformList } from "../../../../services/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations";
import {
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";
import { addLinkUrl } from "../../../../services/campaignAnalytics";

const CampaignLinksAddDestinationDialog = ({
  cid,
  setRefresh,
  open,
  setOpen,
}) => {
  const [platforms, setPlatforms] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("all");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [urlInformation, setUrlInformation] = useState("");
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");
  const [forAll, setForAll] = useState(false);
  const [group, setGroup] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    GetPlatforms();
    getGroupList(selectedPlatform);
  }, []);

  const GetPlatforms = async () => {
    const platforms = await getPlatformList(user, dispatch);
    if (platforms.status === true) {
      setPlatforms(platforms.response);
    } else {
      if (platforms.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: platforms.response?.message,
          type: "error",
        })
      );
    }
  };

  const getGroupList = async (platform) => {
    const platformList = [];
    if (platform === "all") {
      platformList.push(null);
    } else {
      platformList.push(platform);
    }

    const groups = await getAllGroupsListForCampaign(
      cid,
      platformList,
      user,
      dispatch
    );
    if (groups.status === true) {
      setGroups(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const validateUrl = (value) => {
    if (!value) {
      return "This field is required.";
    }

    const urlPattern =
      /^(http:\/\/|https:\/\/)(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    if (!urlPattern.test(value)) {
      return "Invalid URL.";
    }

    return "";
  };

  const handleSubmit = async () => {
    const response = await addLinkUrl(
      selectedGroup,
      urlInformation.trim(),
      url,
      forAll,
      group,
      user,
      dispatch
    );
    setOpen(false);
    setRefresh((refresh) => !refresh);
    if (response.status === true) {
      dispatch(
        notify({
          message: "New URL added successfully",
          type: "success",
        })
      );
    }
    else {
      if (response?.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  const handleGroupChange = (event, groups) => {
    const selectedLid = event.target.value;
    const selectedList = groups.find(list => list.id === selectedLid);
    setGroup(selectedList['name']);
    setSelectedGroup(selectedLid);
  };


  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "auto",
          width: "100%",
        },
      }}
    >
      <DialogTitle>Add Destination URL</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
              <InputLabel>Platform</InputLabel>
              <Select
                onChange={(e) => {
                  setSelectedPlatform(e.target.value);
                  setSelectedGroup("");
                  getGroupList(e.target.value);
                }}
                value={selectedPlatform}
                required
              >
                <MenuItem key="all" value="all">
                  All Platforms
                </MenuItem>
                {platforms?.map((platform) => (
                  <MenuItem key={platform.name} value={platform.name}>
                    {capitalizeFirstLetter(platform.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6}>
            <FormControl variant="filled" style={{ mt: 1, width: "100%" }}>
              <InputLabel>Group</InputLabel>
              <Select
                onChange={(event) => handleGroupChange(event, groups)}
                value={selectedGroup}
                required
              >
                {groups?.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    <Stack direction="row" spacing={2}>
                      <Typography sx={textColor[group.platform]}>
                        {snapshotHeaderIcons[group.platform]}
                      </Typography>
                      <Typography>{group.name}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              label="URL Information"
              type="text"
              value={urlInformation}
              onChange={(e) => setUrlInformation(e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
              required
            />
          </Grid>

          <Grid item md={12}>
            <TextField
              style={{ width: "100%" }}
              variant="filled"
              label="URL"
              type="url"
              value={url}
              onChange={(e) => {
                const url = e.target.value;
                setUrl(url);
                const urlVallidation = validateUrl(url);
                setUrlError(urlVallidation);
              }}
              error={Boolean(urlError)}
              helperText={urlError}
              fullWidth
              sx={{ mt: 1 }}
              required
            />
          </Grid>

          <Grid item md={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={forAll}
                    onChange={(e) => setForAll(e.target.checked)}
                  />
                }
                label="Create short link for all influencers in list"
              />
            </FormGroup>
            <FormHelperText>
              * This option cannot be changed later
            </FormHelperText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          disabled={
            selectedGroup === "" ||
            urlInformation === "" ||
            url === "" ||
            urlError !== ""
          }
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignLinksAddDestinationDialog;
