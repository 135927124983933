import { Chip, TextField } from "@mui/material";
import React, { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import config from "../../config/main.json";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";

function MultiselectLocations({
  sx,
  locationValues,
  setLocationValues,
  removeLocation,
}) {
  const [location, setLocation] = useState("");
  const { ref } = usePlacesWidget({
    apiKey: config.GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      setLocationValues(place.formatted_address);
      setLocation("");
    },
    options: {
      types: ["locality", "administrative_area_level_1", "country"],
    },
  });

  const deleteLocation = (location) => {
    removeLocation(location);
  };

  return (
    <>
      <div>
        <span className={styles.step_field}>Locations* </span>
        {locationValues?.map((location) => (
          <Chip
            label={location}
            key={location}
            onDelete={() => deleteLocation(location)}
            size="small"
            style={{ margin: "3px" }}
          />
        ))}
      </div>
      <TextField
        size="small"
        inputRef={ref}
        variant="outlined"
        name="location"
        sx={sx}
        value={location}
        placeholder="Add multiple locations to your target."
        onChange={(val) => setLocation(val.target.value)}
      />
    </>
  );
}

MultiselectLocations.propTypes = {
  sx: PropTypes.object.isRequired,
  locationValues: PropTypes.array.isRequired,
  setLocationValues: PropTypes.func.isRequired,
  removeLocation: PropTypes.func.isRequired,
};

export default MultiselectLocations;
