import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../../utility/oryxUtils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MailIcon from '@mui/icons-material/Mail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { notify } from '../../../redux/features/system/systemAlert';
import { getInfluencerContactLinks } from '../../../services/discovery';
import { useParams } from 'react-router-dom';

const LinktreeIcon = () => {
  return (
    <svg fill="#25B574" width="35px" height="35px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><path d="M7.953 15.066c-.08.163-.08.324-.08.486.08.517.528.897 1.052.89h1.294v4.776c0 .486-.404.89-.89.89H6.577a.898.898 0 0 1-.889-.891v-4.774H.992c-.728 0-1.214-.729-.89-1.377l6.96-12.627a1.065 1.065 0 0 1 1.863 0l2.913 5.585-3.885 7.042zm15.945 0-6.96-12.627a1.065 1.065 0 0 0-1.862 0l-2.995 5.586 3.885 7.04c.081.164.081.326.081.487-.08.517-.529.897-1.052.89h-1.296v4.776c.005.49.4.887.89.89h2.914a.9.9 0 0 0 .892-.89v-4.775h4.612c.73 0 1.214-.729.89-1.377z" /></svg>
  )
}

const socialIcons = {
  instagram: <InstagramIcon sx={{ color: "#BC2A8D", width: "35px", height: "35px" }} />,
  twitter: <TwitterIcon sx={{ color: "#00ACEE", width: "35px", height: "35px" }} />,
  tiktok: (
    <TikTokIcon
      style={{ width: "24px", height: "24px" }}
      sx={{ color: "#000000" }}
    />
  ),
  youtube: <YouTubeIcon sx={{ color: "#FF0100", width: "35px", height: "35px" }} />,
  linkedin: <LinkedInIcon sx={{ color: "#0a66c2", width: "35px", height: "35px" }} />,
  facebook: <FacebookIcon sx={{ color: "#1b74e4", width: "35px", height: "35px" }} />,
  email: <MailIcon sx={{ color: "#D44637", width: "35px", height: "35px" }} />,
  whatsapp: <WhatsAppIcon sx={{ color: "#25D366", width: "35px", height: "35px" }} />,
  linktree: <LinktreeIcon />,
};



const ContactCard = (props) => {
  const { avatarInfo, handleModalClose, platform,setCredit } = props;
  const [contactData, setContactData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { picUrl, name, userId } = avatarInfo;

  const dispatch = useDispatch();

  const { id } = useParams();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getInfluencerContactLinks(id || userId, platform, user, dispatch).then((res) => {
      const { status, data } = res?.response;
      if (status === "success") {
        setContactData(data);
        setCredit((prev) => {
          if(prev === 0) return 0;
          return prev - 1;
        });
      } else {
        setContactData([]);
      }
      setIsLoading(false);
    })
  }, [])

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      //keeping this in case of old browsers or no clipboard support
      return document.execCommand('copy', true, text);
    }

  }

  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = (
        <>
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
        </>
      );
    } else if (!isLoading && contactData?.length > 0) {
      const findEmailList = () => {
        const emailList = contactData?.filter((contact) => contact.type === "email")?.map(contact => contact.formatted_value);
        if (emailList?.length === 0)
          return null;
        if (emailList?.length === 1) {
          return emailList[0];
        } else {
          return emailList.join(',');
        }
      }
      content = (
        <>
          {contactData?.map((contact, idx) => {
            return (
              <Box key={contact?.value || `contact-link=${idx}`} display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={"1rem"}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Box mr={"1rem"}>{socialIcons[contact.type]}</Box>
                  <Box component={"a"} fontSize={"20px"} sx={{ textDecoration: "underline", maxWidth: "350px", overflow: "hidden", textOverflow: "ellipsis" }} href={contact.type === "email" ? `mailto:${contact.formatted_value}` : contact.formatted_value} target='_' color="#6B747A">{contact.formatted_value}</Box>
                </Box>
                <Box sx={{ cursor: "pointer", ml: "1rem" }} onClick={() => copyTextToClipboard(contact.formatted_value).then(() => dispatch(notify({ message: 'Copied to clipboard', type: 'success' })))}><ContentCopyIcon /></Box>
              </Box>
            )
          })}
          <Box component={"a"} href={`mailto:${findEmailList()}}`} color="#ffffff" sx={{ textDecoration: "none", "&:hover": { color: "#ffffff", textDecoration: "none" } }}>
            {
              findEmailList()?.length &&
              <Tooltip title={`Click to get in touch with ${name}`} placement="bottom">
                <Box bgcolor={"#979797"} borderRadius={"16px"} p={"12px"} display={"flex"} alignItems={"center"} justifyContent={"center"} width={"383px"} mx="auto">
                  <Box mr={"1rem"}>
                    <MailOutlineIcon sx={{ width: "40px", height: "40px", color: "#ffffff" }} />
                  </Box>
                  <div>
                    <Typography color="#FBFDFF" fontSize="20px" fontWeight={"900"} whiteSpace={"nowrap"}>
                      Get in touch
                    </Typography>
                    <Box color="#ffffff" fontSize="14px" sx={{ borderBottom: "1px solid #ffffff" }}>
                      Click to send email
                    </Box>
                  </div>
                </Box>
              </Tooltip>
            }
          </Box>
        </>
      )
    } else {
      content = (<Typography fontSize={"20px"} mb={"1rem"} textAlign={"center"}>No contacts information available.</Typography>);
    }
    return content;
  }

  return (
    <>
      <Box bgcolor={"#EAEAEA"} border="4px solid #6B747A" borderRadius={"32px"} p={"3rem"} maxWidth={"740px"} position={"relative"}>
        <Box position={"absolute"} top="4%" right="4%" display={"flex"} alignItems={"center"}>
          {/* <Box mr={"1rem"}><CloudDownloadOutlinedIcon sx={{ cursor: "pointer" }} /></Box> */}
          <Box component={"div"} onClick={handleModalClose}>
            <CancelOutlinedIcon sx={{ cursor: "pointer" }} />
          </Box>
        </Box>
        <Box mx="auto" textAlign={'center'} mb={"1rem"}>
          <Box component={"img"} src={picUrl} sx={{ width: "90px", height: "90px", borderRadius: "32px", border: "4px solid #687076" }} />
          <Typography mt="12px" fontSize={"30px"} color="#687076">{name}</Typography>
        </Box>
        {renderContentHandler()}
      </Box>
    </>
  )

}

export default ContactCard;
