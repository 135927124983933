import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from "@mui/material";
import styles from "../../styles/internal/Billing/Billing.module.css";
import { RoundedTextField } from "../../components/common/StyledComponents";
import { EmailOutlined } from "@mui/icons-material";
import React from "react";
import { updateBillingEmailForAccount } from "../../services/billing";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";

const EditEmailInformation = ({ user, planDetails, openEmailCard, handleEmailCardClose }) => {
  const scroll = "paper";
  const [state, setState] = React.useState({
    email: planDetails.billingEmail,
  });
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const dispatch = useDispatch();

  const updateEmailInformation = async () => {
    dispatch(notify({ message: "Please wait... we are updating your billing email", type: "info" }));
    const body = {
      id: user.agencyId,
      billingEmail: state.email,
    };
    const response = await updateBillingEmailForAccount(body, user, dispatch);
    if (response?.response.status === "success") {
      dispatch(notify({ message: "Billing Email Updated Successfully!", type: "success" }));
    }
    handleEmailCardClose({ email: state.email });
  };
  const card = (
    <>
      <Dialog
        open={openEmailCard}
        onClose={() => handleEmailCardClose({ email: planDetails.billingEmail })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <h4 className={styles.subTitle}>Email Information</h4>
          <div className={styles.formTitle}>
            <b>Billing Email Address</b>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                sx={RoundedTextField}
                style={{ width: "500px" }}
                placeholder="<Billing Email Address>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                required
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Button className={styles.closeButton} onClick={() => handleEmailCardClose({ email: planDetails.billingEmail })}>
                <b>Close</b>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={styles.staticButton}
                  onClick={updateEmailInformation}
                  disabled={!state.email || !state.email.match(emailPattern)}
                >
                  <b>Update Email Information</b>
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

  return <div>{card}</div>;
};

export default EditEmailInformation;
