// To convert our custom keys to IQ Data accepted keys

const KeyEnum = {
  inf_ads_brands: "ads_brands",
  inf_age: "age",
  aud_age: "audience_age_range",
  aud_brand: "audience_brand",
  aud_brand_category: "audience_brand_category",
  aud_gender: "audience_gender",
  aud_location: "audience_geo",
  aud_language: "audience_lang",
  aud_ethinicity: "audience_race",
  inf_brand: "brand",
  inf_brand_category: "brand_category",
  inf_er: "engagement_rate",
  inf_engagements: "engagements",
  inf_followers: "followers",
  inf_reel_plays: "reels_plays",
  inf_gender: "gender",
  inf_location: "geo",
  inf_keyword: "keywords",
  inf_language: "lang",
  aud_lastPosted: "last_posted",
  inf_lookalikes: "relevance",
  inf_bio: "text",
  inf_mention: "text_tags",
  inf_views: "views",
  aud_contact: "with_contact",
  inf_accountType: "account_type",
  aud_lookalikes: "audience_relevance",
  inf_followers_growth: "followers_growth",
  inf_verified: "is_verified",
  inf_hidden: "is_hidden",
  inf_credible: "audience_credibility_class",
  inf_known: "only",
  inf_has_audience_data: "has_audience_data",
};

export default KeyEnum;
