import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  ButtonGroup
} from "@mui/material";
import React, { useEffect } from "react";
import { getLocationData } from "../../imaiDiscovery/FilterComponents/service/fetchDataByFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Wc,
  Man,
  Woman,
} from "@mui/icons-material";
import { fetchBrandData } from "../../../services/discovery";
import { notify } from "../../../redux/features/system/systemAlert";
import Age from "../../imaiDiscovery/FilterComponents/Age";
import {
  createCampaignAudience,
  editCampaignAudience,
  getDetailedCampaignAudience,
} from "../../../services/campaigns";
import { disabledStyle } from "./staticStyling";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../common/StyledComponents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "../../common/loader";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const CampaignWizardStep2 = ({
  goToNextStep,
  campaignId,
  campaignAudience,
  setAudienceState,
  getAudienceId,
  editModeActive,
}) => {
  const classes = disabledStyle();
  const [state, setState] = React.useState({
    location: { name: "" },
    ageGroup: "",
    audienceGroup: "",
    interest: [],
    gender: "",
    audienceSelected: "",
    cid: campaignId,
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [allLocations, setAllLocations] = React.useState([]);
  const [allInterests, setAllInterests] = React.useState([]);
  const [ageFilterData, setAgeFiltersData] = React.useState([
    { inf_hidden: [{ name: "Exclude private accounts" }] },
  ]);
  const [agePayload, setAgePayload] = React.useState({ inf_hidden: false });
  const [editMode, setEditMode] = React.useState(editModeActive);
  const [loading, setLoading] = React.useState(false);
  // const [showAgeValue, setShowAgeValue] =  React.useState(false);
  // const [ageValue, setAgeValue] = React.useState(state.ageGroup)

  const genders = [
    {
      icon: <Man style={{ fontSize: "18px", marginBottom: "6px" }} />,
      name: "Male",
    },
    {
      icon: <Woman style={{ fontSize: "18px", marginBottom: "6px" }} />,
      name: "Female",
    },
    {
      icon: (
        <Wc
          style={{ fontSize: "18px", marginBottom: "5px", marginRight: "2px" }}
        />
      ),
      name: "Unisex",
    },
  ];

  const audienceGroup = [
    { id: "Nano", name: "1K - 10K followers (Nano)" },
    { id: "Micro", name: "10K - 50K followers (Micro)" },
    { id: "Mid", name: "50K - 500K followers (Mid-Tier)" },
    { id: "Macro", name: "500K - 1M followers (Macro)" },
    { id: "Mega", name: "1M+ followers (Mega)" },
  ];

  useEffect(() => {
    getLocations("KE");
    getInterests("");
  }, []);
  
  const getLocations = async (value) => {
    const locationResponse = await getLocationData(
      value,
      "country",
      dispatch,
      user
    );
    setAllLocations(locationResponse);
  };
  const getInterests = async (value) => {
    const interestsResponse = await fetchBrandData(
      value,
      "interests",
      dispatch,
      user
    );
    if (!interestsResponse.status) {
      setAllInterests([]);
    }
    if (interestsResponse.response)
      setAllInterests(interestsResponse.response || []);
  };

  React.useEffect(() => {
    setLoading(true);
    if (campaignAudience) {
      setState(campaignAudience);
      setAgePayload(campaignAudience.ageGroup);
    }

    if (editModeActive) {
      dispatch(
        notify({
          message: "Please wait... We are loading your campaign's audience",
          type: "info",
        })
      );
      setEditMode(true);
      getCampaignAudienceDetails();
    } else {
      setLoading(false);
    }
  }, []);

  const getCampaignAudienceDetails = async () => {
    const campaignResponse = await getDetailedCampaignAudience(
      campaignId,
      user,
      dispatch
    );
    if (campaignResponse?.response == null) {
      setEditMode(false);
      setLoading(false);
    }
    const {
      gender,
      age_range,
      influencer_size,
      interests,
      country,
      countryIds,
      interestIds,
    } = campaignResponse?.response[0];
    let audience;
    if (influencer_size === "1000-10000") {
      audience = "Nano";
    } else if (influencer_size === `10000-50000`) {
      audience = "Micro";
    } else if (influencer_size === `50000-500000`) {
      audience = "Mid";
    } else if (influencer_size === `500000-1000000`) {
      audience = "Macro";
    } else if (influencer_size === `1000000-`) {
      audience = "Mega";
    }
    const interestNameArray = interests.split(",");
    const interestIdsArray =
      interestIds?.length > 0 ? interestIds.split(",") : [];
    let interestArray = [];
    for (let i = 0; i < interestIdsArray?.length; i++) {
      interestArray.push({
        id: interestIdsArray[i],
        name: interestNameArray[i],
      });
    }

    const locationNameArray = country.split(",");
    const locationIdsArray = countryIds.split(",");

    setState({
      location: { id: locationIdsArray[0], name: locationNameArray[0] },
      ageGroup: "",
      audienceGroup: audience,
      interest: interestArray,
      gender: gender,
      audienceSelected: influencer_size,
      cid: campaignId,
    });
    if (age_range?.length > 0) {
      const ageGroup = age_range.split("-");
      const ageFilter = [
        {
          inf_hidden: { name: "Exclude private accounts" },
          inf_age: [{ left: ageGroup[0], right: ageGroup[1], weight: 0.05 }],
        },
      ];
      const agePayload = {
        inf_hidden: false,
        inf_age_from: ageGroup[0],
        inf_age: { right_number: ageGroup[1], left_number: ageGroup[0] },
        inf_age_to: ageGroup[1],
      };
      setAgeFiltersData(ageFilter);
      setAgePayload(agePayload);
    }
    setLoading(false);
  };

  const createAudience = async () => {
    // goToNextStep(2);
    // getAudienceId(4);
    state.ageGroup = agePayload;
    // setShowAgeValue(true)
    // setAgeValue(agePayload)
    let aud;
    if (state.audienceGroup.toLowerCase() === "nano") {
      aud = `1000-10000`;
    } else if (state.audienceGroup.toLowerCase() === "micro") {
      aud = `10000-50000`;
    } else if (state.audienceGroup.toLowerCase() === "mid") {
      aud = `50000-500000`;
    } else if (state.audienceGroup.toLowerCase() === "macro") {
      aud = `500000-1000000`;
    } else if (state.audienceGroup.toLowerCase() === "mega") {
      aud = `1000000-`;
    }
    dispatch(
      notify({
        message: "Please wait... We are processing your campaign's audience",
        type: "info",
      })
    );
    const campaignAudienceData = {
      cid: campaignId,
      country: state.location.id.toString(),
      countryName: state.location.name,
      age_range: `${agePayload.inf_age_from}-${agePayload.inf_age_to}`,
      influencer_size: aud,
      interests: state.interest?.map((interest) => interest.id).join(","),
      gender: state.gender,
    };
    if (editMode) {
      const data = await editCampaignAudience(
        campaignAudienceData,
        user,
        dispatch
      );
      if (data.response.status === "success") {
        goToNextStep(2);
        getAudienceId(data.response.id);
        setAudienceState(state);
        dispatch(
          notify({
            message: "Campaign's Audience created successfully",
            type: "success",
          })
        );
      }
    } else {
      const data = await createCampaignAudience(
        campaignAudienceData,
        user,
        dispatch
      );
      if (data.response.status === "success") {
        goToNextStep(2);
        getAudienceId(data.response.id);
        setAudienceState(state);
        dispatch(
          notify({
            message: "Campaign's Audience created successfully",
            type: "success",
          })
        );
      }
    }
  };

  const goToPreviousStep = () => {
    goToNextStep(0);
    setAudienceState(state);
  };

  //unimplemented method - ignore
  const setAllFiltersData = (data) => {
    setAgeFiltersData(data);
  };
  const setPayload = (data) => {
    setState({ ...state, ageGroup: data });
    setAgePayload(data);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box m={5}>
          <h2 style={{ fontSize: "32px", fontWeight: 500, lineHeight: "28px" }}>
            Target Audience
          </h2>
          <Grid container item spacing={4} xs={12} style={{ paddingTop: 20 }}>
            <Grid item md={6} xs={12}>
              <Typography style={{ fontSize: "20px", fontWeight: 600, marginBottom: "8px" }}>
                Country *
              </Typography>
              <Autocomplete
                options={allLocations}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Choose countries this campaign would be targeting."
                    required
                    style={{ width: "100%" }} // Full width input
                  />
                )}
                value={state.location}
                onKeyUp={(event, newValue) => getLocations(event.target.value)}
                renderOption={(props, option, { selected }) => <li {...props}>{option.name}</li>}
                onChange={(event, newValue) => setState({ ...state, location: newValue })}
                noOptionsText="Please type the location to get the list of the relevant locations"
              />
            </Grid>
            {/* Age Groups Field */}
            <Grid item md={6} xs={12}>
              <Typography style={{ fontSize: "20px", fontWeight: 600, marginBottom: "8px" }}>
                Age Groups *
              </Typography>
              <FormControl fullWidth>
                <Age
                  showFilter={true}
                  payload={agePayload}
                  setPayload={setPayload}
                  allFiltersData={ageFilterData}
                  setAllFiltersData={setAllFiltersData}
                  isInfFilter={true}
                  state={campaignAudience}
                  createCampaign={true}
                  style={{ width: "100%" }}
                />
              </FormControl>
            </Grid>
            {/* Gender Field */}
            <Grid item md={6} xs={12}>
              <Typography style={{ fontSize: "20px", fontWeight: 600, marginBottom: "8px" }}>
                Gender *
              </Typography>
              <ButtonGroup
                variant="outlined"
                sx={{
                  width: "100%", // Full width for the button group
                }}
              >
                {genders?.map((gender) => (
                  <Button
                    key={gender.name}
                    sx={{
                      flex: 1, 
                      padding: "12px",
                      fontSize: "16px",
                      color: gender.name === state.gender ? "white" : "gray",
                      borderColor: gender.name === state.gender ? "#367BF5" : "gray",
                      backgroundColor: gender.name === state.gender ? "#367BF5" : "transparent",
                      "&:hover": {
                        borderColor: gender.name === state.gender ? "#367BF5" : "gray",
                        backgroundColor: gender.name === state.gender ? "#367BF5" : "transparent",
                      },
                    }}
                    onClick={() => setState({ ...state, gender: gender.name })}
                  >
                    <Typography>{gender.icon}</Typography>
                    <Typography>{gender.name}</Typography>
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
            {/* Influencer Size Field */}
            <Grid item md={6} xs={12}>
              <Typography style={{ fontSize: "20px", fontWeight: 600, marginBottom: "8px" }}>
                Influencer Size *
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Audience Size</InputLabel>
                <Select
                  required={true}
                  variant="outlined"
                  label="Audience Size"
                  value={state.audienceGroup}
                  onChange={(e) => setState({ ...state, audienceGroup: e.target.value })}
                  style={{ width: "100%" }} 
                >
                  {audienceGroup?.map((audience) => (
                    <MenuItem key={audience.id} value={audience.id}>
                      {audience.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Campaign Categories or Influencer Interest Area Field */}
            <Grid item xs={12}>
              <Typography style={{ fontSize: "20px", fontWeight: 600, marginBottom: "8px" }}>
                Campaign Categories or Influencer Interest Area
              </Typography>
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                options={Array.isArray(allInterests) ? allInterests : []}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Choose influencer categories or interest area, you can choose more than one."
                    required
                    style={{ width: "100%" }} // Full width input
                  />
                )}
                value={state.interest}
                onKeyUp={(event) => getInterests(event.target.value)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.name}
                  </li>
                )}
                onChange={(event, newValue, reason) => {
                  if (reason === "selectOption") {
                    // Allow selection from dropdown
                    setState({ ...state, interest: newValue });
                  } else if (reason === "createOption") {
                    // Check if the entered value exists in options
                    const exists = allInterests.some(
                      (interest) =>
                        interest.name.toLowerCase() === newValue.toLowerCase()
                    );
                    if (exists) {
                      setState({ ...state, interest: newValue });
                    }
                  }
                }}
              />
            </Grid>

            {/* Button Alignment */}
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginTop: 4 }}>
              <Grid item>
                <Box sx={{ mt: 4, ml: 3.5 }}>
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "38px",
                      color: "#474747",
                      borderColor: "#474747",
                      borderRadius: "11px",
                    }}
                    onClick={goToPreviousStep}
                  >
                    <ArrowBackIcon style={{ marginRight: "8px", fontSize: "18px" }} />
                    <b>Previous</b>
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ mt: 4, mr: 3.5, textAlign: 'center' }}>
                  <StyledStaticButtonGroup
                    color="primary"
                    size="small"
                    exclusive
                    aria-label="Campaign"
                  >
                    <StyledStaticButton
                      value="active"
                      onClick={createAudience}
                      disabled={!state.audienceGroup || !state.gender || !state.location}
                      classes={{ disabled: classes.disabledButton }}
                      style={{
                        width: "180px",
                        height: "38px",
                        borderRadius: "11px",
                      }}
                    >
                      <b>Save and Proceed</b>
                      <ArrowForwardIcon style={{ marginLeft: "8px", fontSize: "18px" }} />
                    </StyledStaticButton>
                  </StyledStaticButtonGroup>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#6b6b6b",
                      marginTop: "4px",
                    }}
                  >
                    (Next: Goals)
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CampaignWizardStep2;
