import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import styles from "../../styles/internal/Billing/Billing.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { cancelSubscriptionForAccount, getBillingDetailsForAccount, getPlanDetailsForAccount } from "../../services/billing";
import EditCardInformation from "./EditCardInformation";
import EditEmailInformation from "./EditEmailInformation";
import { notify } from "../../redux/features/system/systemAlert";
import { EditOutlined, EmailOutlined } from "@mui/icons-material";
import PricingCustomized from "./PricingCustomized";
import Loader from "../../components/common/loader";
import { changeBillingAttributes } from "../../redux/features/user/userSlice";

const BillingInformation = () => {
  const user = useSelector((state) => state.user);
  const [state, setState] = useState({
    cardDetails: {},
    planDetails: {},
  });
  const [loading, setLoading] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [openEmailCard, setOpenEmailCard] = useState(false);
  const [openUpgradeCard, setOpenUpgradeCard] = useState(false);
  const [typeOfRequest, setTypeOfRequest] = useState("upgrade");
  const [openCancelSubscriptionCard, setCancelSubscriptionCard] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    const cardResponse = await getBillingDetailsForAccount(user, dispatch);
    const planResponse = await getPlanDetailsForAccount(user, dispatch);
    setLoading(false);
    setState({ ...state, cardDetails: cardResponse.response, planDetails: planResponse.response });
  };

  const cancelSubscription = async () => {
    dispatch(notify({ message: "Please wait... We are cancelling your subscription", type: "info" }));
    const data = {
      agencyId: user.agencyId.toString(),
      email: user.email,
    };
    changeBillingAttributes({
      isLocked: true,
    });
    const subscriptionResponse = await cancelSubscriptionForAccount(data, user, dispatch);
    if (subscriptionResponse.response === "success") {
      state.planDetails.status = "canceled";
      setCancelSubscriptionCard(false);
      setState({ ...state, planDetails: state.planDetails });

      dispatch(notify({ message: "Subscription Cancelled Successfully!", type: "success" }));
    }
  };

  const handleClickOpen = () => {
    setOpenCard(true);
  };

  const handleClickEmailOpen = () => {
    setOpenEmailCard(true);
  };

  const handleUpgradeCardClose = () => {
    setOpenUpgradeCard(false);
    getDetails();
  };
  const handleCancelSubscriptionCardClose = () => {
    setCancelSubscriptionCard(false);
    getDetails();
  };

  const handleClose = (event) => {
    setOpenCard(false);
    if (event.informationUpdated) {
      getDetails();
    }
  };

  const handleEmailCardClose = (event) => {
    setOpenEmailCard(false);
    state.planDetails.billingEmail = event.email;
    setState({ ...state, planDetails: state.planDetails });
  };

  const changeSubscription = () => {
    setOpenUpgradeCard(true);
    setTypeOfRequest("upgrade");
  };

  const reSubscribe = () => {
    setOpenUpgradeCard(true);
    setTypeOfRequest("subscribe");
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className={styles.title}>Subscription</h4>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#EBEBEB",
              color: "#464E5F",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Grid container>
              <Grid item xs={8}>
                <Grid container className={styles.subTitle}>
                  <Grid item xs={4}>
                    My Current Plan
                  </Grid>
                  <Grid item xs={4}>
                    My Plan Status
                  </Grid>
                  <Grid item xs={4}>
                    {state.planDetails?.planName?.toLowerCase() === "free" &&
                      state.planDetails?.status?.toLowerCase() === "active" ? (
                      <span>Trial Ends On</span>
                    ) : state.planDetails?.planName?.toLowerCase() === "starter" &&
                      state.planDetails?.status?.toLowerCase() === "active" ? (
                      <span>Next Billing Date</span>
                    ) : state.planDetails?.status?.toLowerCase() === "active" ? (
                      <span>Subscription Valid Till</span>
                    ) : (
                      <span>Trial Ended on</span>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <span className={styles.planNameHeading}> {state.planDetails.planName}</span>{" "}
                    <span className={styles.planPrice}> ${state.planDetails.planPrice}/{state.planDetails.tenure > 1 ? "6 months" : "month"}</span>
                  </Grid>

                  <Grid item xs={4}>
                    {state.planDetails?.status?.toLowerCase() === "active" ? (
                      <Chip label={state.planDetails?.status?.toUpperCase()} color="primary" size="small" />
                    ) : (
                      <Chip label={state.planDetails?.status?.toUpperCase()} color="secondary" size="small" />
                    )}
                  </Grid>
                  <Grid item xs={4} className={styles.planDate}>
                    {state.planDetails?.status?.toLowerCase() === "active" || state.planDetails?.status?.toLowerCase() === "expired"
                      ? new Intl.DateTimeFormat("en-UK").format(new Date(state.planDetails.billingDate))
                      : new Intl.DateTimeFormat("en-UK").format(new Date())}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item spacing={2} xs={12} sm={6} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {(state.planDetails?.planName?.toLowerCase() === "free" ||
                      state.planDetails?.planName?.toLowerCase() === "starter" ||
                      state.planDetails?.planName?.toLowerCase() === "professional" ||
                      state.planDetails?.planName?.toLowerCase() === "business") && (
                        <>
                          {state.planDetails?.status === "active" ? (
                            <Button
                              className={styles.outlinedButton}
                              variant="outlined"
                              onClick={() => setCancelSubscriptionCard(true)}
                            >
                              Cancel Subscription
                            </Button>
                          ) : (
                            <Button className={styles.containedButton} variant="contained" onClick={reSubscribe}>
                              Subscribe
                            </Button>
                          )}{" "}
                        </>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      className={styles.containedButton}
                      variant="contained"
                      onClick={changeSubscription}
                      disabled={
                        state.planDetails.status === "active" &&
                          (state.planDetails?.planName?.toLowerCase() === "free" ||
                            state.planDetails?.planName?.toLowerCase() === "starter" ||
                            state.planDetails?.planName?.toLowerCase() === "professional" ||
                            state.planDetails?.planName?.toLowerCase() === "business"
                          )
                          ? false
                          : true
                      }
                    >
                      Upgrade
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {Object.keys(state.cardDetails)?.length > 0 && (
              <Dialog
                open={openUpgradeCard}
                onClose={handleUpgradeCardClose}
                scroll="paper"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                  sx: {
                    maxWidth: "xl",
                    width: "180vw",
                  },
                }}
              >
                <DialogContent>
                  <IconButton onClick={handleUpgradeCardClose} style={{ position: 'absolute', right: 40 }}>
                    <Close />
                  </IconButton>
                  <PricingCustomized
                    planDetails={state.planDetails}
                    user={user}
                    typeOfRequest={typeOfRequest}
                    cardDetails={state.cardDetails}
                    handleUpgradeCardClose={handleUpgradeCardClose}
                  />
                </DialogContent>
              </Dialog>
            )}
            {
              <Dialog
                open={openCancelSubscriptionCard}
                onClose={handleCancelSubscriptionCardClose}
                scroll="paper"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                  sx: {
                    width: "150vw",
                  },
                }}
              >
                <DialogTitle dividers>Cancel Subscription</DialogTitle>
                <DialogContent dividers>Do you want to cancel the current subscription?</DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                  <Button className={styles.outlinedButton} variant="outlined" onClick={cancelSubscription}>
                    Yes, I confirm
                  </Button>
                  <Button className={styles.dangerButton} variant="contained" onClick={handleCancelSubscriptionCardClose}>
                    No, not now
                  </Button>
                </DialogActions>
              </Dialog>
            }
            {
              <Dialog
                open={openCancelSubscriptionCard}
                onClose={handleCancelSubscriptionCardClose}
                scroll="paper"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                  sx: {
                    width: "150vw",
                  },
                }}
              >
                <DialogTitle dividers>Cancel Subscription</DialogTitle>
                <DialogContent dividers>Do you want to cancel the current subscription?</DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                  <Button className={styles.outlinedButton} variant="outlined" onClick={cancelSubscription}>
                    Yes, I confirm
                  </Button>
                  <Button className={styles.dangerButton} variant="contained" onClick={handleCancelSubscriptionCardClose}>
                    No, not now
                  </Button>
                </DialogActions>
              </Dialog>
            }
          </div>

          <h4 className={styles.title}>Billing Email</h4>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#EBEBEB",
              color: "#464E5F",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <Grid container className={styles.billDetails}>
              <Grid item xs={8}>
                {state.planDetails.billingEmail !== "" ? (
                  <Grid container sx={{ paddingTop: "10px" }}>
                    <Grid item xs={6}>
                      <EmailOutlined /> {state.planDetails.billingEmail}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container sx={{ paddingTop: "10px" }}>
                    <Grid item xs={6}>
                      <EmailOutlined /> No Details Available
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Button className={styles.containedButton} variant="contained" onClick={handleClickEmailOpen}>
                      {state.planDetails.billingEmail === "" ? <span>Add</span> : <span>Edit</span>}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              <EditEmailInformation
                openEmailCard={openEmailCard}
                planDetails={state.planDetails}
                user={user}
                handleEmailCardClose={handleEmailCardClose}
              />
            }
          </div>

          <h4 className={styles.title}>Current Card</h4>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: "#EBEBEB",
              color: "#464E5F",
              padding: "20px",
              textAlign: "center",
            }}
          >
            {Object.keys(state.cardDetails)?.length > 0 && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={1.4} sx={{ textAlign: "right" }}>
                    <EditOutlined sx={{ height: "77px" }} />
                  </Grid>
                  <Grid item xs={6.6} sx={{ textAlign: "left" }}>
                    {state.cardDetails.first_name !== "" ? (
                      <>
                        <Grid container className={styles.cardDetails}>
                          <Grid item xs={6}>
                            {state.cardDetails.first_name} {state.cardDetails.last_name}
                          </Grid>
                        </Grid>
                        <Grid container className={styles.cardData}>
                          <Grid item xs={6}>
                            **** **** **** {state.cardDetails.payment_method?.last_four}
                          </Grid>
                        </Grid>
                        <Grid container className={styles.cardData}>
                          <Grid item xs={6}>
                            {state.cardDetails.payment_method?.exp_month}/{state.cardDetails.payment_method?.exp_year}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Grid container className={styles.cardData}>
                          <Grid item xs={6}>
                            <br />
                            No Details available
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={4} sx={{ paddingTop: "2%" }}>
                    <Grid container>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <Button className={styles.containedButton} variant="contained" onClick={handleClickOpen}>
                          {state.cardDetails.first_name === "" ? <span>Add</span> : <span>Edit</span>}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <EditCardInformation openCard={openCard} user={user} cardDetails={state.cardDetails} handleClose={handleClose} />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BillingInformation;
