const LandingTags = [
  { name: "Influencer Discovery", tooltip: "Search and contact influencers at scale" },
  { name: "Influencer Analysis", tooltip: "Analyze Influencer's data-rich profiles before you invite them into a campaign" },
  { name: "RT Campaign Analytics", tooltip: "Get in-depth performance metrics for each influencer and each publication in the campaign in real-time" },
  { name: "Influencer Contracting", tooltip: "Outreach, Onboarding and Legal Contracts - All in one place" },
  { name: "Influencer Management", tooltip: "Assign tasks to Influencers, create milestones" },
  { name: "Advanced Reporting", tooltip: "Dashboard provides results on awareness, engagement, and conversion events" },
  { name: "Campaign Management", tooltip: "Approve content before going live" },
  { name: "Attribution", tooltip: "Use custom automatically generated short-links to track sales" },
  { name: "Data Insights", tooltip: "Rich dashboard that lets you slice and dice the data the way you want" },
  { name: "Integrations", tooltip: "Connect with the popular analytics platforms to get a 360 view of your campaigns" }
];
const commonStyleLeftImageHeader = {
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "40px",
  lineHeight: "120%",
  letterSpacing: "-0.01em",
  color: "#211F25",
};

const commonStyleLeftTextPara = {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "155%",
  letterSpacing: "0.02em",
  color: "#3D3A41",
};

const commonStyleRightImageHeader = {
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "40px",
  lineHeight: "120%",
  letterSpacing: "-0.01em",
  color: "#211F25",
  paddingLeft: "18px",
};

const commonStyleRightTextPara = {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "155%",
  letterSpacing: "0.02em",
  paddingLeft: "20px",
  color: "#3D3A41",
};

const readMore = {
  borderRadius: "56px",
  height: "50px",
  width: "145px",
  fontSize: "15px",
  color: "#37ADCA",
  border: "1px solid #37ADCA",
  "&:hover": {
    borderColor: "#37ADCA",
    backgroundColor: "rgba(55, 173, 202, 0.04)",
  },
};
export {
  LandingTags,
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
  readMore,
};
