import configs from "../config/main.json";
import KeyEnum from "../components/imaiDiscovery/KeyEnum";
import config from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import fetchSignedRequest from "../utility/fetchSignedRequest";
import { createSignedRequest, requestConfig } from "./common";

const { API_HOST, ORYX_HOSTNAME } = configs;

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const fetchDataByPlatform = async (
  payload,
  sortCol,
  sortField,
  selectedPlatform,
  tablePageNo,
  tableRowsPerPage,
  customCols,
  dispatch,
  user,
  exportDetails = {},
  exportLimit,
  searchResultIds
) => {
  let reqPayload = {
    request: {
      audience_source: payload["aud_audience_type"],
      sort: {
        field: sortCol?.field ? KeyEnum[sortCol?.field] : payload["sort"] || sortField[selectedPlatform],
        ...(sortCol?.id && { id: sortCol?.id }),
        direction: "desc",
      },
      filter: {
        ads_brands: payload["inf_ads_brands"],
        age: payload["inf_age"],
        audience_age_range: payload["aud_age"],
        audience_brand: payload["aud_brand"],
        audience_brand_category: payload["aud_brand_category"],
        audience_gender: payload["aud_gender"],
        audience_geo: payload["aud_location"],
        audience_lang: payload["aud_language"],
        audience_race: payload["aud_ethinicity"],
        brand: payload["inf_brand"],
        brand_category: payload["inf_brand_category"],
        engagement_rate: {
          operator: "gte",
          value: payload["inf_er_from"],
        },
        engagements: {
          left_number: payload["inf_engagements_from"],
          right_number: payload["inf_engagements_to"],
        },
        followers: {
          left_number: payload["inf_followers_from"],
          right_number: payload["inf_followers_to"],
        },
        reels_plays: {
          left_number: payload["inf_reel_plays_from"],
          right_number: payload["inf_reel_play_to"],
        },
        gender: payload["inf_gender"],
        geo: payload["inf_location"],
        keywords: payload["inf_keyword"],
        lang: payload["inf_language"],
        last_posted: payload["aud_lastPosted"],
        relevance: payload["inf_lookalikes"],
        text: payload["inf_bio"],
        text_tags: payload["inf_mention"],
        views: {
          left_number: payload["inf_views_from"],
          right_number: payload["inf_views_to"],
        },
        with_contact: payload["aud_contact"],
        account_type: payload["inf_accountType"],
        audience_relevance: payload["aud_lookalikes"],
        followers_growth: payload["inf_followers_growth"],
        is_verified: payload["inf_verified"],
        is_hidden: payload["inf_hidden"],
        audience_credibility_class: payload["inf_credible"],
        only: payload["inf_known"],
        has_audience_data: payload["inf_has_audience_data"],
      },
      paging: {
        skip: tablePageNo * tableRowsPerPage,
        limit: exportLimit ? +exportLimit : tableRowsPerPage,
      },
    },
    custom_cols: customCols,
    companyId: user.agencyId,
    search_result_ids: searchResultIds?.length ? searchResultIds : [],
    ...exportDetails,
    notificationMetadata: {
      userId: `agency/${user.agencyId}`,
      sourcePlatform: configs.PLATFORM_NAME,
      notificationTypeId: "1",
      notificationActionId: "1",
      notificationCategoryId: "3",
      generatedBy: user.email,
      priority: "high",
      consumedBy: "empowr",
    },
  };

  try {
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "POST",
        url: searchResultIds?.length
          ? "discovery/unhide/platform/" + selectedPlatform
          : "discovery/search/platform/" + selectedPlatform,
        data: reqPayload,
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const fetchHistoryDataOnType = async (type, platform, dispatch, user) => {
  try {
    const reqPayload = { type, agencyId: user.agencyId, platform };
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "POST",
        url: "discovery/history",
        data: reqPayload,
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const fetchLocationData = async (searchKey, type, dispatch, user) => {
  try {
    let searchType = "";
    if(type != "") {
      searchType += "&type="+type
    }
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "GET",
        url: "discovery/getlocation/?location=" + searchKey+searchType,
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const fetchLocationDataByIds = async (id, dispatch, user) => {
  try {
    const reqPayload = { id };
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "POST",
        url: "discovery/getLocationsById",
        data: reqPayload,
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};
const fetchBrandData = async (searchKey, param, dispatch, user) => {
  try {
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "GET",
        url: "discovery/getBrandsInt/?key=" + searchKey + "&type="+ param,
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const fetchLanguageData = async (searchKey, dispatch, user) => {
  try {
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "GET",
        url: "discovery/getIqLangauages?key=" + searchKey,
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const fetchLookalikeData = async (searchKey, selectedPlatform, searchType) => {
  try {
    const res = await (
      await fetch(
        config.IQHOST + "dict/users/?q=" + searchKey + "&type=" + searchType + "&platform=" + selectedPlatform + "&limit=5"
      )
    ).json();
    let newLookalikes = [];
    if (res?.success) {
      res.data.forEach((look) => {
        if (look["username"] !== undefined || look["fullname"] !== undefined) {
          let lookData = {
            username: look["username"],
            name: look["fullname"],
            pic: look["picture"],
            followers: look["followers"],
          };
          newLookalikes.push(lookData);
        }
      });
    }
    return newLookalikes;
  } catch (err) {
    console.log(err);
  }
};

const getInfluencerContactLinks = async (scid, platform, user, dispatch) => {
  try {
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "POST",
        url: "discovery/profile/contact",
        data: { scid, platform, companyId: user.companyId > 0 ? user.companyId : user.agencyId, agencyId: user.agencyId },
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const exportList = async (payload, user, dispatch) => {
  try {
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "POST",
        url: "discovery/export/list",
        data: { ...payload, companyId: user.companyId > 0 ? user.companyId : user.agencyId },
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const fetchHiddenList = async (searchResultsIds, selectedPlatform, user, dispatch) => {
  try {
    const { status, response } = await fetchSignedRequest(
      {
        hostname: config.ORYX_HOSTNAME,
        method: "POST",
        url: "discovery/unhide/platform/" + selectedPlatform,
        data: { search_result_ids: searchResultsIds },
      },
      user
    );
    if (!status) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status, response };
  } catch (err) {
    dispatch(
      notify({
        message: err.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const addDiscoveryUserToGroup = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/influencers/add`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    if (!data.mimeType || data.mimeType == "") {
      return { status: response.ok, response: await Promise.resolve(response.json()) };
    }
    return { status: response.ok, response: await Promise.resolve(response.text()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const inviteDiscoveryUserViaEmail = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/influencers/invite`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const inviteDiscoveryUserViaList = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/influencer/invite`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response?.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const analyzeInfluencerFromIQData = async (searchKey, searchType, selectedPlatform, dispatch) => {
  const response = await fetch(
    `${config.IQHOST}dict/users/?q=${searchKey}&type=${searchType}&platform=${selectedPlatform}&limit=5`,
    {
      method: "GET",
      headers,
    }
  );
  if (response?.status === 403) {
    dispatch(
      notify({
        message: response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};
export {
  fetchDataByPlatform,
  fetchBrandData,
  fetchLanguageData,
  fetchLocationData,
  fetchLookalikeData,
  getInfluencerContactLinks,
  exportList,
  fetchHiddenList,
  addDiscoveryUserToGroup,
  inviteDiscoveryUserViaEmail,
  inviteDiscoveryUserViaList,
  analyzeInfluencerFromIQData,
  fetchHistoryDataOnType,
  fetchLocationDataByIds
};
