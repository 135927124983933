import { useRoutes } from "react-router-dom";
import ReactGA from "react-ga4";
import routes from "./navigation/routes";
import GlobalStyles from "./styles/GlobalStyles";
import "bootstrap-daterangepicker/daterangepicker.css";
import config from "./config/main.json";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const chatWidgetStyle = {
  visibility: {
    desktop: {
      xOffset: 10,
      yOffset: 5,
      position: "bl",
    },

    mobile: {
      xOffset: 10,
      yOffset: 5,
      position: "bl",
    },
  },
};
function App() {
  const currentComponent = useRoutes(routes);

  const TRACKING_ID = config.GA_TRACKING_CODE;
  ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <GlobalStyles />
      <TawkMessengerReact
        customStyle={chatWidgetStyle}
        propertyId={config.TWAK_TO_PROPERTY_ID}
        widgetId={config.TWAK_TO_WIDGET_ID}
      />
      {currentComponent}
    </>
  );
}

export default App;
