import { Close, OpenWith } from "@mui/icons-material";
import {
  Button,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Modal,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import WorldMap from "react-svg-worldmap";
import styles from "../ProgressChart/ProgressChart.module.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    opacity: 1,
  },
}));

const ProgressChartCountry = ({ data, mapData, chartTitle, parentStyle, country, backgroundColor }) => {
  const [expandTable, setExpandTable] = useState(false);
  const renderData = (expandFlag) => {
    const topValues = expandFlag ? data : data.slice(0, 4);
    return topValues?.map((data) => {
      const title = data.title;
      const percentage = parseInt(data.percentage);
      const value = data.value;
      return (
        <>
          <Paper sx={{ width: "100%" }} style={{ padding: "15px" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <span class="progress_chart-title">{title}</span>
              <span class="progress_chart-value">
                {percentage}
                {"%"}
              </span>
            </Stack>
            <BorderLinearProgress
              sx={{
                height: 12,
              }}
              variant="determinate"
              key={{ title }}
              value={percentage}
            />
          </Paper>
          <br />
        </>
      );
    });
  };

  const expandButton = () => {
    return (
      <Stack direction="row" justifyContent="end">
        <Button style={{ backgroundColor: "#BDBDBD" }} variant="contained" onClick={expandChart} endIcon={<OpenWith />}>
          Expand
        </Button>
      </Stack>
    );
  };

  const expandChart = () => {
    setExpandTable(true);
  };

  const handleTableModalClose = () => {
    setExpandTable(false);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, md: 12 }}
        style={{
          marginTop: "20px",
          backgroundColor: backgroundColor,
          padding: "10px",
        }}
      >
        <Grid item xs={4} md={6}>
          {" "}
          <WorldMap color="#4299E1" value-suffix="people" size="lg" data={mapData} borderColor={"black"} strokeOpacity={0.2} />
        </Grid>
        <Grid item xs={4} md={6}>
          {renderData(false)}
          {expandButton()}
        </Grid>
      </Grid>
      <Modal className={styles.stylesModal} open={expandTable} onClose={handleTableModalClose} closeAfterTransition>
        <Fade in={expandTable}>
          <div className={styles.sylesPaper} style={{ width: "50%", overflowY: "scroll", maxHeight: "500px" }}>
            <div id="modal-expanded_progress" className={styles.stylesModalHeading}>
              <h3>Countries</h3>
              <IconButton onClick={handleTableModalClose}>
                <Close />
              </IconButton>
            </div>
            <hr />
            {renderData(true)}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

ProgressChartCountry.propTypes = {
  data: PropTypes.array.isRequired,
  mapData: PropTypes.array.isRequired,
  chartTitle: PropTypes.string.isRequired,
  parentStyle: PropTypes.object.isRequired,
  country: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
};
export default ProgressChartCountry;
