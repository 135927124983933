import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {InfoToolTip} from "../../../common/toolTip";

const AudienceWidget = ({ widget }) => {
  /**
   * widgets that are boxed and un-boxed
   *
   * check me: Make this common component
   */
  React.useEffect(() => {}, [widget]);
  return widget.boxed ? (
    <Grid
      container
      spacing={2}
      direction="row"
      style={{
        border: "1px solid #344767",
        borderRadius: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
      }}
    >
      <Grid
        item
        xs={0.5}
        direction="column"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {widget.icon}
      </Grid>
      <Grid item xs={10.8} direction="column">
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "150%",
              /* or 20px */
              textAlign: "right",

              /* Linear/Dark Blue */
              color: "#344767",
            }}
          >
            <b>{widget.title}</b>
            &nbsp;
            {widget.info && <InfoToolTip text={widget.text} />}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "140%",
              /* or 34px */
              textAlign: "right",

              /* Linear/Dark Blue */
              color: "#344767",
            }}
          >
            <b>{widget.value}</b>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1} direction="row">
      <Grid
        item
        xs={1}
        direction="column"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {widget.icon}
      </Grid>
      <Grid item xs={6} direction="column">
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "150%",
              /* or 20px */
              textAlign: "right",

              /* Linear/Dark Blue */
              color: "#344767",
            }}
          >
            <b>{widget.title}</b>
            {widget.info && <InfoToolTip text={widget.text} />}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "140%",
              /* or 34px */
              textAlign: "right",

              /* Linear/Dark Blue */
              color: "#344767",
            }}
          >
            <b>{widget.value}</b>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

AudienceWidget.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default AudienceWidget;
