import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../../common/StyledComponents";
import { Close } from "@mui/icons-material";
import { addNewGroup, editGroup } from "../../../../../services/group";
import { useNavigate, useLocation } from "react-router-dom";
import { notify } from "../../../../../redux/features/system/systemAlert";
import constants from "../../../../../config/constantMessages.json";
import styles from "../../../../../styles/internal/Campaign/CreateNewCampaign.css";
import { PlatformIdMapping, snapshotHeaderIcons, textColor } from "../../../../../utility/plaformIcons";
import { useStyles } from "../../../newCampaign/staticStyling";
import { getCampaignOverview } from "../../../../../services/campaignAnalytics";
import Loader from "../../../../common/loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const AddCampaignGroup = (props) => {
  const { closeModal, campaignId: propsCampaignId, universal: propsUniversal, group, allAllowedPlatforms } = props;
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isNewGroup, setIsNewGroup] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlCampaignId = params.get("id"); // Get the "id" parameter from the URL
  const campaignId = propsCampaignId || urlCampaignId; // Use propsCampaignId if available, otherwise URL parameter
  const universal = propsUniversal || params.get("universal");
  const [isLoading, setIsLoading] = useState();
  const [state, setState] = useState({
    id: 0,
    platform: "",
    groupName: "",
    groupDescription: "",
    country: "",
    state: "",
    city: "",
  });
  const [platforms, setPlatforms] = useState([]); // to do: replace with platforms allowed
  const groupNameCount = state.groupName?.length;
  const groupDescriptionCount = state.groupDescription?.length;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!allAllowedPlatforms) {
        const campaignDetails = await getCampaignOverview(urlCampaignId, user, dispatch);
        if (campaignDetails.response) {
          const { platforms } = campaignDetails.response.overallSummary[0];
          setPlatforms(platforms);
        }
      }
      else {
        setPlatforms(allAllowedPlatforms)
      }

      setCountries(Country.getAllCountries());

      if (group !== null && group !== undefined) {
        let selectedCountry, selectedState, selectedCity;
        const { id, platform, groupName, groupDescription, country, state, city } = group;
        if (country) {
          selectedCountry = Country.getAllCountries().filter((coun) => coun.name === country)[0];
          setStates(State.getStatesOfCountry(selectedCountry?.isoCode));
          if (state) {
            selectedState = State.getStatesOfCountry(selectedCountry?.isoCode).filter((st) => st.name === state)[0];
            setCities(City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode));
            if (city) {
              selectedCity = City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode).filter(
                (ct) => ct.name === city
              )[0];
            }
          }
        }
        setState({
          ...state,
          id,
          platform,
          groupName,
          groupDescription,
          country: selectedCountry || "",
          state: selectedState || "",
          city: selectedCity?.name || "",
        });
        setIsNewGroup(false);
      }

      setIsLoading(false);
    };

    setIsLoading(true);
    fetchData();
  }, []);

  const createGroup = async () => {
    const { platform, groupName, groupDescription, country, city } = state;
    const groupDetails = {
      name: groupName,
      cid: Number(campaignId),
      platform: PlatformIdMapping[platform],
      description: groupDescription,
      tags: "",
      notes: "",
      tz: 0,
      geoType: 2,
      country: country.name,
      state: state.state,
      city,
      currency: "",
      infBudget: 0,
      paymentTerm: "",
      minFollowers: 0,
      maxFollowers: 0,
      noOfDays: 0,
      paymentDate: "",
      postContent: "",
      dos: constants.GROUP_DOS,
      donts: constants.GROUP_DONTS,
      status: 1,
      addedFrom: "empowr",
      faqs: "[]",
      isSaas: true,
      agencyId: user.agencyId,
    };
    dispatch(
      notify({
        message: "We are adding your group for this campaign",
        type: "info",
      })
    );
    const response = await addNewGroup(groupDetails, user, dispatch);
    dispatch(
      notify({
        message: "Group created successfully",
        type: "success",
      })
    );
    if (closeModal) {
      closeModal();
    } else {
      navigate(`/app/campaigns/influencers/groups?id=${campaignId}&universal=${universal}`);
    }
  };

  const editThisGroup = async () => {
    const { id, platform, groupName, groupDescription, country, state: st, city } = state;
    console.log(city);
    const groupDetails = {
      id,
      name: groupName,
      cid: Number(campaignId),
      platform: PlatformIdMapping[platform],
      description: groupDescription,
      tags: "",
      notes: "",
      tz: 0,
      geoType: 2,
      country: country.name || "",
      state: st.name || "",
      city: city || "",
      currency: "",
      infBudget: 0,
      paymentTerm: "",
      minFollowers: 0,
      maxFollowers: 0,
      noOfDays: 0,
      paymentDate: "",
      postContent: "",
      dos: constants.GROUP_DOS,
      donts: constants.GROUP_DONTS,
      status: 1,
      addedFrom: "empowr",
      faqs: "[]",
      isSaas: true,
      agencyId: user.agencyId,
    };
    dispatch(
      notify({
        message: "We are adding your group for this campaign",
        type: "info",
      })
    );
    const response = await editGroup(groupDetails, user, dispatch);
    dispatch(
      notify({
        message: "Group edited successfully",
        type: "success",
      })
    );
    closeModal();
    navigate(`/app/campaigns/influencers/groups?id=${campaignId}&universal=${universal}`);
  };

  const addPlatform = (platform) => {
    if (platform === "facebook" || platform === "linkedin") {
      setState({ ...state, platform, hint: `Please add public posts only for ${platform}` });
    } else {
      setState({ ...state, platform });
    }
  };

  const selectCountry = (event) => {
    const selectedCountry = event.target.value;
    console.log(selectedCountry);
    setState({ ...state, country: selectedCountry });
    setStates(State.getStatesOfCountry(selectedCountry.isoCode));
  };

  const selectState = (event) => {
    const selectedState = event.target.value;
    const countryCode = state.country.isoCode;
    const stateCode = selectedState.isoCode;
    setState({ ...state, state: selectedState });
    setCities(City.getCitiesOfState(countryCode, stateCode));
  };

  const handleCancel = () => {
    if (state.groupName || state.groupDescription || state.country || state.state || state.city) {
      const confirmCancel = window.confirm("You have unsaved changes. Do you really want to cancel?");
      if (confirmCancel) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  const handleBack = () => {
    navigate(`/app/campaigns/overview?id=${campaignId}&universal=${universal}`);
  };

  return (
    <Box
      sx={{
        width: closeModal ? "60%" : "90%",
        margin: "auto",
        backgroundColor: "#FAFAFB",
        padding: "20px",
        borderRadius: "8px",
        marginTop: "20px",
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent={closeModal ? "space-between" : "center"}
            alignItems="center"
            position={!closeModal ? "relative" : "static"}
          >
            <Typography
              variant="h5"
              className={styles.header}
              sx={{
                position: !closeModal ? "absolute" : "static",
                left: !closeModal ? "50%" : "auto",
                transform: !closeModal ? "translateX(-50%)" : "none",
                marginLeft: closeModal ? "29px" : "auto",
              }}
            >
              {isNewGroup ? <b>Create A Group</b> : <b>Edit {state.groupName}</b>}
            </Typography>
            <IconButton onClick={closeModal ? handleCancel : handleBack} sx={{ marginLeft: "auto" }}>
              <Close />
            </IconButton>
          </Box>


          <Box m={4} style={{ backgroundColor: "#FAFAFB" }}>
            {isNewGroup && (
              <>
                <Typography variant="h6" className={styles.header}>
                  <b>Choose Social Network</b>
                </Typography>
                <Grid container spacing={2} style={{ paddingTop: 10 }}>
                  {platforms?.map((platform) => (
                    <Grid item key={platform}>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: state.platform === platform ? textColor[platform].color : "gray",
                          color: state.platform === platform ? textColor[platform].color : "gray",
                          padding: "10px",
                          width: "40px",
                          height: "50px",
                        }}
                        onClick={() => addPlatform(platform)}
                        className={state.platform !== platform && "snapshot-header-button"}
                      >
                        <Typography>{snapshotHeaderIcons[platform]}</Typography>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <br />
            <Typography variant="h6" className={styles.header}>
              <b>Group Details</b>
            </Typography>
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Group Name"
                  placeholder="<Enter Group Name>"
                  type="text"
                  required
                  helperText="Max 48 Chars"
                  value={state.groupName}
                  onChange={(e) => setState({ ...state, groupName: e.target.value })}
                  InputProps={{
                    endAdornment: <div className={classes.counter}>{groupNameCount}/48</div>,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Group Description"
                  placeholder="<Enter Group Description>"
                  type="text"
                  required
                  helperText="Max 256 Chars"
                  value={state.groupDescription}
                  onChange={(e) => setState({ ...state, groupDescription: e.target.value })}
                  InputProps={{
                    endAdornment: <div className={classes.counter}>{groupDescriptionCount}/256</div>,
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Typography variant="h6" className={styles.header}>
              <u>Country Details</u>
            </Typography>
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid item xs={4}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Select a country *</InputLabel>
                  <Select onChange={selectCountry} value={state.country} MenuProps={MenuProps}>
                    {countries?.map((country) => (
                      <MenuItem key={country.id} value={country}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Select a state</InputLabel>
                  <Select onChange={selectState} value={state.state} MenuProps={MenuProps}>
                    {states?.map((state) => (
                      <MenuItem key={state.id} value={state}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Select a city</InputLabel>
                  <Select onChange={(e) => setState({ ...state, city: e.target.value })} value={state.city} MenuProps={MenuProps}>
                    {cities?.map((city) => (
                      <MenuItem key={city.id} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "38px",
                      color: "#474747",
                      borderColor: "#474747",
                      borderRadius: "11px",
                    }}
                    onClick={closeModal ? handleCancel : handleBack}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ mt: 4 }}>
                  <StyledStaticButtonGroup
                    color="primary"
                    size="small"
                    exclusive
                    aria-label="Campaign"
                    disabled={!state.platform || !state.groupName || !state.groupDescription || !state.country}
                  >
                    {isNewGroup ? (
                      <StyledStaticButton value="active" className={styles.staticButton} onClick={createGroup}>
                        <b>Add</b>
                      </StyledStaticButton>
                    ) : (
                      <StyledStaticButton value="active" className={styles.staticButton} onClick={editThisGroup}>
                        <b>Save</b>
                      </StyledStaticButton>
                    )}
                  </StyledStaticButtonGroup>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );

};

export default AddCampaignGroup;
