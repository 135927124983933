import React, { useState } from "react";
import {
  Delete,
  Edit,
  MoreHorizOutlined,
  PersonAdd,
  Preview,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import CampaignLinksEditDestinationDialog from "./CampaignLinksEditDestinationDialog";
import CampaignLinksDeleteDestinationDialog from "./CampaignLinksDeleteDestinationDialog";
import CampaignFBLinksShortUrlsDialog from "./CampaignFBLinksShortUrlsDialog";

const CampaignFBLinksManageActionButton = ({
  url,
  setRefresh,
  setOpenShortUrlCreate,
  setSetselectedUrl,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditUrl, setOpenEditUrl] = useState(false);
  const [openDeleteUrl, setOpenDeleteUrl] = useState(false);
  const [openShortUrl, setOpenShortUrl] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <Preview htmlColor="#B5B5C3" />,
      label: "Short URLs",
      name: "shorturls",
    },
    {
      id: 2,
      icon: <PersonAdd htmlColor="#B5B5C3" />,
      label: "Create Short URLs",
      name: "createshorturls",
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "shorturls": {
        setOpenShortUrl(true);
        break;
      }
      case "createshorturls": {
        setOpenShortUrlCreate(true);
        setSetselectedUrl(url);
        break;
      }
      default:
        break;
    }
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={styles.menuitem}
        >
          {arrayOfOptions?.map((value) => (
            <div>
              <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < arrayOfOptions?.length && (
                <Divider key={value.id} sx={{ my: 0.5 }} />
              )}
            </div>
          ))}
        </Menu>
      </Box>
      {openEditUrl && (
        <CampaignLinksEditDestinationDialog
          urlData={url}
          setRefresh={setRefresh}
          open={openEditUrl}
          setOpen={setOpenEditUrl}
        />
      )}
      {openDeleteUrl && (
        <CampaignLinksDeleteDestinationDialog
          url={url.url}
          urlId={url.id}
          setRefresh={setRefresh}
          open={openDeleteUrl}
          setOpen={setOpenDeleteUrl}
        />
      )}
      {openShortUrl && (
        <CampaignFBLinksShortUrlsDialog
          urlId={url.id}
          open={openShortUrl}
          setOpen={setOpenShortUrl}
        />
      )}
    </>
  );
};

export default CampaignFBLinksManageActionButton;
