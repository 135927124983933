import { Edit, LabelTwoTone, MoreHorizOutlined, Refresh, RemoveRedEye, ViewAgenda } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem, Modal } from "@mui/material";
import { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import AddCampaignGroup from "./add/AddCampaignGroup";

const CampaignGroupsActionButton = ({ group, setReload }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = +params.get("id");
  const universal = params.get("universal");
  const open = Boolean(anchorEl);
  const [editGroupModalOpen, setEditGroupModalOpen] = useState(false);
  const [handleCloseModal, setHandleCloseButton] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <RemoveRedEye htmlColor="#B5B5C3" />,
      label: "View Influencers",
      name: "viewinfluencers",
    },
    {
      id: 2,
      icon: <ViewAgenda htmlColor="#B5B5C3" />,
      label: "View Publications",
      name: "viewpublications",
    },
    // {
    //   id: 1,
    //   icon: <Refresh htmlColor="#B5B5C3" />,
    //   label: "Update Group Stats",
    //   name: "updategroupstats",
    // },
    {
      id: 3,
      icon: <Edit htmlColor="#B5B5C3" />,
      label: "Edit Group",
      name: "editgroup",
    },
    // {
    //   id: 3,
    //   icon: <Email htmlColor="#B5B5C3" />,
    //   label: "Invite All in Group",
    //   name: "inviteall",
    // },
    // {
    //   id: 4,
    //   icon: <LabelTwoTone htmlColor="#B5B5C3" />,
    //   label: "View/Edit Tag",
    //   name: "viewedittag",
    // },
    // {
    //   id: 5,
    //   icon: <OpenInNew htmlColor="#B5B5C3" />,
    //   label: "Send Contract",
    //   name: "sendcontract",
    // },
    // {
    //   id: 6,
    //   icon: <ContentPasteSearch htmlColor="#B5B5C3" />,
    //   label: "Review Drafts",
    //   name: "reviewdrafts",
    // },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "viewinfluencers": {
        navigate(`/app/campaigns/influencers/all?id=${campaignId}&universal=${universal}`, {
          state: { data: group },
          replace: true,
        });
        break;
      }
      case "viewpublications": {
        navigate(`/app/campaigns/publications/published?id=${campaignId}&universal=${universal}`, {
          state: { data: group },
          replace: true,
        });
        break;
      }
      case "editgroup": {
        setEditGroupModalOpen(true);
        break;
      }
      default:
        break;
    }
    handleClose();
  };

  const closeModal = () => {
    setReload(true);
    setEditGroupModalOpen(false);
    handleClose();
  };
  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map((value) => (
            <div>
              <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < arrayOfOptions?.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
            </div>
          ))}
        </Menu>
      </Box>
      <Modal open={editGroupModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
            width: "100%",
          }}
        >
          <AddCampaignGroup
            group={group}
            handleCloseModal={handleCloseModal}
            closeModal={closeModal}
            campaignId={campaignId}
            universal={universal}
          />
        </Box>
      </Modal>
    </>
  );
};

CampaignGroupsActionButton.propTypes = {};

export default CampaignGroupsActionButton;
