import configs from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const {
  API_HOST,
  PAYMENT_HOST,
  PAYMENT_HOSTNAME,
  ORYX_HOSTNAME,
  UNAUTH_PAYMENT_HOST,
  UNAUTH_API_HOST,
} = configs;

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const subscribeToPlan = async (data) => {
  const response = await fetch(`${UNAUTH_PAYMENT_HOST}/plan/subscribe`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const addBillingDetailsToAccount = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: PAYMENT_HOSTNAME,
      method: "POST",
      url: `${PAYMENT_HOST}/plan/billing/add`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const addcardDetailsToAccount = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: PAYMENT_HOSTNAME,
      method: "POST",
      url: `${PAYMENT_HOST}/plan/billing/information/add`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const addUnauthBillingDetailsToAccount = async (data) => {
  const response = await fetch(`${UNAUTH_PAYMENT_HOST}/plan/billing/add`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: headers,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const verifyCouponCode = async (couponCode) => {
  const response = await fetch(
    `${UNAUTH_PAYMENT_HOST}/plan/verifyCoupon/${couponCode}`,
    {
      method: "GET",
      headers: headers,
    }
  );
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getBillingDetailsForAccount = async (user, dispatch) => {
  try {
    const request = {
      hostname: PAYMENT_HOSTNAME,
      method: "GET",
      url: `${PAYMENT_HOST}/plan/billing/details/${user.agencyId}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getPlanDetailsForAccount = async (user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/account/plan/${user.agencyId}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const updateBillingEmailForAccount = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/account/edit`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const cancelSubscriptionForAccount = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: PAYMENT_HOSTNAME,
      method: "POST",
      url: `${PAYMENT_HOST}/plan/subscription/cancel`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const changeSubscriptionForAccount = async (data, user, dispatch) => {
  const request = {
    hostname: PAYMENT_HOSTNAME,
    method: "POST",
    url: `${PAYMENT_HOST}/plan/subscription/change`,
    data: JSON.stringify(data),
  };

  const signedRequest = createSignedRequest(request, user);
  const response = await fetch(
    signedRequest.url,
    requestConfig(request, signedRequest)
  );
  if (response.status === 403) {
    dispatch(
      notify({
        message: response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const reactivateSubscriptionForAccount = async (user, dispatch) => {
  try {
    const request = {
      hostname: PAYMENT_HOSTNAME,
      method: "GET",
      url: `${PAYMENT_HOST}/plan/subscription/reactivate/${user.agencyId}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const reactivateSubscriptionForUnauthAccount = async (agencyId) => {
  const response = await fetch(
    `${UNAUTH_PAYMENT_HOST}/plan/subscription/reactivate/${agencyId}`,
    {
      method: "GET",
      headers: headers,
    }
  );
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getDetailsForPlan = async (planName) => {
  const response = await fetch(`${UNAUTH_API_HOST}/plan/${planName}`, {
    method: "GET",
    headers: headers,
  });
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

export {
  subscribeToPlan,
  addBillingDetailsToAccount,
  getBillingDetailsForAccount,
  getPlanDetailsForAccount,
  updateBillingEmailForAccount,
  cancelSubscriptionForAccount,
  changeSubscriptionForAccount,
  getDetailsForPlan,
  reactivateSubscriptionForAccount,
  addUnauthBillingDetailsToAccount,
  reactivateSubscriptionForUnauthAccount,
  verifyCouponCode,
  addcardDetailsToAccount
};
