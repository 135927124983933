import { Clear, Warning } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { StyledStaticButton, StyledStaticButtonGroup } from "./StyledComponents";
import styles from "../../styles/internal/Campaign/CampaignList.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignOverview } from "../../services/campaignAnalytics";
import { storeAdjustDate } from "../../redux/features/user/userSlice";

const AdjustCampaignDates = () => {
  const [open, setOpen] = React.useState(false);
  const user = useSelector((state) => state.user); //get loggedIn user state

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = +params.get("id");
  const universal = params.get("universal");
  const handleClose = () => {
    dispatch(storeAdjustDate());
    setOpen(false);
  };

  useEffect(() => {
    getCampaignData();
  }, []);

  const getCampaignData = async () => {
    const campaignDetails = await getCampaignOverview(campaignId, user, dispatch);
    const { minDate } = campaignDetails.response.overallSummary[0];
    if (minDate < 0 && user.adjustDateWarning) {
      setOpen(true);
    }
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: 700,
          height: 300,
        },
      }}
    >
      <DialogTitle>
        <b>
          <Warning sx={{ color: "#d6d61f" }} /> Adjust Campaign Dates
        </b>
        <IconButton style={{ float: "right" }} onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.container}>
        <p className={styles.childContent}>
          We noticed some of the publications added in this campaign are from before campaign's start date. Please adjust the date
          of the campaign to see proper stats.
        </p>
      </DialogContent>
      <DialogActions>
        <a
          className={styles.linkButton}
          onClick={() =>
            navigate(`/app/campaigns/publications/published?id=${campaignId}&universal=${universal}`, { replace: true })
          }
        >
          See the posts out of order
        </a>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <StyledStaticButtonGroup size="small" exclusive aria-label="Campaign">
          <StyledStaticButton
            value="active"
            className={styles.staticButton}
            onClick={() => navigate(`/app/campaigns/create?id=${campaignId}&universal=${universal}`, { replace: true })}
          >
            <b>Edit the Campaign Dates</b>
          </StyledStaticButton>
        </StyledStaticButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default AdjustCampaignDates;
