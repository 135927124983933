import { Approval, FactCheck, History, MoreHorizOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import PropTypes from "prop-types";
import ApprovalDialog from "./draftActions/ApprovalDialog";
import HistoryDialog from "./draftActions/HistoryDialog";
import ApprovedDraftDialog from "./draftActions/ApprovedDraftDialog";

const CampaignDraftsActionButtons = ({ refreshDrafts, iid, draft, approval }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewApproval, setViewApproval] = useState(false);
  const [viewApproved, setViewApproved] = useState(false);
  const [ViewHistory, setViewHistory] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    ...(approval
      ? [
          {
            id: 1,
            icon: <Approval htmlColor="#B5B5C3" />,
            label: "Approval",
            name: "approval",
          },
        ]
      : []),
    ...(!approval
      ? [
          {
            id: 2,
            icon: <FactCheck htmlColor="#B5B5C3" />,
            label: "Approved Draft",
            name: "approvedDraft",
          },
        ]
      : []),
    {
      id: 3,
      icon: <History htmlColor="#B5B5C3" />,
      label: "History",
      name: "history",
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "approval": {
        setViewApproval(true);
        break;
      }
      case "approvedDraft": {
        setViewApproved(true);
        break;
      }
      case "history": {
        setViewHistory(true);
        break;
      }
      default:
        break;
    }
    handleClose();
  };

  const closeApproval = (update) => {
    setViewApproval(false);
    if (update) {
      refreshDrafts();
    }
  };

  const closeApproved = () => {
    setViewApproved(false);
  };

  const closeHistory = () => {
    setViewHistory(false);
  };

  return (
    <>
      {viewApproval && <ApprovalDialog iid={iid} draft={draft} viewApproval={viewApproval} closeApproval={closeApproval} />}
      {viewApproved && <ApprovedDraftDialog draft={draft} viewApproved={viewApproved} closeApproved={closeApproved} />}
      {ViewHistory && <HistoryDialog draft={draft} viewHistory={ViewHistory} closeHistory={closeHistory} />}
      {arrayOfOptions?.map((value) => (
        <IconButton onClick={() => invokeAction(value.name)} size="small" sx={{ ml: 2 }}>
          {value.icon}
        </IconButton>
      ))}
    </>
  );
};

CampaignDraftsActionButtons.propTypes = {
  draft: PropTypes.object.isRequired,
  approval: PropTypes.bool.isRequired,
};

export default CampaignDraftsActionButtons;
