import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
        fontFamily: "Roboto",
        backgroundColor: "rgba(224, 224, 224, 0.15)",
      },
      a: {
        color: "primary",
        textDecoration: "none",
      },
      "a:hover": {
        textDecoration: "underline",
      },
      Button: {
        textTransform: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(224, 224, 224, 0.15)",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
