import { Alert, Box, CssBaseline, Snackbar, ThemeProvider, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import theme from "../../styles/theme";
import AppNav from "./AppNav";
import AppToolBar from "./AppToolBar";
import AppSecondaryNav from "./AppSecondaryNav";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, notify } from "../../redux/features/system/systemAlert";
import Loader from "../../components/common/loader";
import FreeTrialPopup from "../../components/common/freeTrialPopup";

const Root = styled("div")({
  display: "flex",
  width: "100%",
});

function AppLayout() {
  const menuInfo = useSelector((state) => state.menu); //get loggedIn menu state
  const permissionInfo = useSelector((state) => state.menu); //get loggedIn menu state

  const [menuItemsData, setMenuItemsData] = useState(menuInfo.data);
  const [permissionData, setPermissionData] = useState(permissionInfo.permissions);
  const [secondaryOpen, setSecondaryOpen] = useState(true);
  const [secondaryNav, setSecondaryNav] = useState({});
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const location = useLocation();
  const navigate = useNavigate();

  const systemAlert = useSelector((state) => state.systemAlert);
  const dispatch = useDispatch();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  };

  const defineMenuItems = () => {
    const menuData = menuInfo.data;
    const primaryKeys = Object.keys(menuData);
    if (primaryKeys?.length > 0) {
      const menuOptions = menuData?.map((primary) => {
        const result = { ...primary };
        if (primary.name === "Divider") {
          result.hidden = false;
          return result;
        }
        if (primaryKeys.includes(result.name)) {
          result.hidden = false;
          if (result.children !== undefined) {
            const resOptions = [...primary.children];
            resOptions.forEach((secondary, index) => {
              if (menuData[result.name].includes(secondary.name)) {
                result.children[index].hidden = false;
              } else {
                result.children[index].hidden = true;
              }
            });
            result.children = resOptions;
          }
        } else {
          result.hidden = true;
        }

        return result;
      });
      setMenuItemsData(menuOptions);
      setLoading(false);
    }
  };

  useEffect(() => {
    defineMenuItems();
  }, []);

  useEffect(() => {
    if (!user.loggedIn) {
      if (location.pathname !== "/login" && location.pathname !== "/pricing") {
        setTimeout(() => {
          dispatch(
            notify({
              message: "Invalid login or Session expired, please login again.",
              type: "error",
              top: "15%",
            })
          );
        }, 500);
      }
      navigate("/login");
      window.location.reload();
      return;
    }
    if (location.pathname === "/app") {
      navigate("/app/dashboard");
    }

    const findPath = (element, path) => {
      if (element.path === path) {
        return true;
      } else if (element.children !== undefined && element.children !== null) {
        for (let i = 0; i < element.children?.length; i++) {
          if (findPath(element.children[i], path)) {
            return true;
          }
        }
      }
      return false;
    };

    if (!loading) {
      let secondaryOpen = false;
      let secondaryNav = [];

      for (let i = 0; i < menuItemsData?.length; i++) {
        const { children, path, secondary } = menuItemsData[i];
        const exists = children?.find((element) => {
          if (element.children === undefined || element.children === null) {
            return element.path === location.pathname;
          } else {
            return findPath(element, location.pathname);
          }
        });
        if (!secondary && exists === undefined) {
          secondaryOpen = false;
        } else if (children !== undefined && exists?.secondary !== false && path !== location.pathname) {
          secondaryOpen = true;
          secondaryNav = menuItemsData[i];
          break;
        }
      }
      setSecondaryOpen(secondaryOpen);
      setSecondaryNav(secondaryNav);
    }
  }, [location.pathname, navigate, user.loggedIn, loading]);

  const PRIMARY_DRAWER_WIDTH = 85;
  const SECONDARY_DRAWER_WIDTH = 160;

  return (
    <ThemeProvider theme={theme}>
      {!loading && (
        <Root sx={{ minHeight: "100%", background: "#f2f2f4" }}>
          <CssBaseline />
          <Box sx={{ display: "flex" }}>
            <AppToolBar drawerWidth={PRIMARY_DRAWER_WIDTH} menuItemsData={menuItemsData} />
          </Box>
          <Box component="nav" sx={{ width: { md: PRIMARY_DRAWER_WIDTH }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
            <AppNav menuItemsData={menuItemsData} drawerWidth={PRIMARY_DRAWER_WIDTH} permissionsData={permissionData} />
            {secondaryOpen && (
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#EEEEEE",
                  width: `${SECONDARY_DRAWER_WIDTH}px`,
                  position: "fixed",
                  height: `calc(100% - 64px)`,
                  left: `${PRIMARY_DRAWER_WIDTH}px`,
                  bottom: "0px",
                  zIndex: "10",
                }}
              >
                <AppSecondaryNav menuItem={secondaryNav} drawerWidth={SECONDARY_DRAWER_WIDTH} permissionsData={permissionData} />
              </Box>
            )}
          </Box>
          <Box
            component="main"
            sx={{
              position: "relative",
              flexGrow: 1,
              p: 3,
            }}
          >
            <Toolbar />
            <div
              style={{
                ...(secondaryOpen && {
                  paddingLeft: `${SECONDARY_DRAWER_WIDTH}px`,
                }),
              }}
            >
              <Outlet />
            </div>
          </Box>
        </Root>
      )}
      {loading && <Loader />}
      {systemAlert !== undefined && systemAlert.message && (
        <Snackbar
          open={systemAlert.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={systemAlert.type}
            variant="filled"
            sx={{
              width: "100%",
              fontSize: "1rem", // Adjust the font size as needed
            }}
          >
            {systemAlert.message}
          </Alert>
        </Snackbar>
      )}
    </ThemeProvider>
  );
}

export default AppLayout;
