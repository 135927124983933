import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

const TermsOfService = () => {
  const refundRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#refundPolicies") {
      refundRef.current.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  return (
    <Box sx={{ m: 4 }} className="text-block">
      <h1>
        <strong>Terms of Service</strong>
      </h1>
      <p>
        <strong>EMPOWR.AI&nbsp; TERMS OF USE</strong>
      </p>
      <p>
        <strong>Last Updated: February 24, 2023</strong>
      </p>
      <p>
        <strong>
          PLEASE READ THIS AGREEMENT CAREFULLY. BY ACCESSING OR USING THIS SITE,
          APP OR SERVICES OR OTHERWISE AGREEING TO THIS AGREEMENT, YOU
          UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT AND RECOGNIZE THAT
          YOU MAY BE WAIVING CERTAIN RIGHTS.
        </strong>
      </p>
      <p>
        <strong>
          These Terms of Use apply to the Users, as defined below, and their use
          of this site or app and the services offered on or through it,
          provided by Aifluence Inc. (&ldquo;Aifluence&rdquo;,
          &ldquo;EMPOWR.ai&rdquo;&ldquo;Company&rdquo;, &ldquo;we&rdquo;,
          &ldquo;us&rdquo;, or &ldquo;our&rdquo;).
        </strong>
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>THIS AGREEMENT </strong>
          <strong>
            <em>
              CONTAINS A BINDING ARBITRATION AGREEMENT WHICH LIMITS YOUR RIGHTS
              TO BRING AN ACTION IN COURT, BRING A CLASS ACTION, AND HAVE
              DISPUTES DECIDED BY A JUDGE OR JURY
            </em>
          </strong>
          <strong>
            , AS WELL AS PROVISIONS THAT LIMIT OUR LIABILITY TO YOU.
          </strong>
        </span>
        <strong>
          <br />
        </strong>
      </p>
      <p>
        <strong>
          Some terms may not apply to you, depending on where you are in the
          world, so please look out for any notices explaining terms that are
          relevant to particular countries only.
        </strong>
      </p>
      <p>
        <span>This is an Agreement between you and </span>
        <strong>Aifluence</strong>
        <span>
          {" "}
          Inc.., having registered address at 651 N Broad St Suite 206, Suite
          206, Middletown, Delaware, 19709 (&ldquo;
        </span>
        <strong>Aifluence</strong>
        <span>&rdquo;, "</span>
        <strong>Aifluence</strong>
        <span>
          ", &ldquo;ai-fluence.com&rdquo;, &ldquo;EMPOWR&rdquo;,
          &ldquo;Empowr.ai&rdquo;, "we", "us" or "our") that describes the Terms
          of Service (&ldquo;Terms&rdquo;) with respect to your access to and
          use of content, reports, documents, products, and online services
          (referred to collectively as the &ldquo;Services&rdquo;) we make
          available through EMPOWR.ai (the "Website", &ldquo;Our site&rdquo;,
          &ldquo;Service&rdquo;, &ldquo;Site&rdquo;). Please take the time to
          read the Agreement carefully as it governs your use of the Site and
          Services.
        </span>
        <span>
          <br />
        </span>
      </p>
      <p>
        <span>
          By using our Website, including but not limited to signing in EMPOWR
          service, you agree to fully comply with and be bound by our Legal
          Terms. Please review them carefully. Installing the code on your site
          with the further collection of subscribers is a confirmation of the
          fact that you assume the responsibility for all actions on the Website
          and the consequences of using the TrendHero service associated with
          the work of its scripts and functions within the framework of your
          site. If you do not accept our Legal Terms, do not access and use our
          Website. If you have already accessed our Website and do not accept
          our Legal Terms, you should immediately discontinue use of our
          Website.&nbsp;
        </span>
      </p>
      <h2>AMENDMENT TO THE TERMS OF USE</h2>
      <p>
        <span>
          EMPOWR reserves the right to amend, modify, update or remove, in whole
          or in part, at any time, without prior notice and with immediate
          effect these Terms of Use.
        </span>
      </p>
      <p>
        <span>
          You should review these Terms of Use from time to time in order to
          confirm if any updates or changes were made hereto.
        </span>
      </p>
      <p>
        <span>
          If EMPOWR amends the Terms of Use, it will post the relevant changes
          on this page and will indicate at the bottom of this page the date
          these terms were last updated. Your continued use of the Platform
          after any such changes constitutes your acceptance of the Terms of Use
          as amended. If you do not agree to abide by these or any future Terms
          of Use, you should not use or access (or continue to use or access)
          the Platform. It is your responsibility to regularly check our Website
          to determine if there have been changes to these Terms of Use and to
          review such changes.
        </span>
      </p>
      <p>
        <span>
          From time to time, we will also be sending you certain notifications
          about EMPOWR, including about amendments to these Terms of Use. Upon
          registration at the Platform we will ask you whether you wish to
          receive these notifications by email or through push notifications
          (in case this option is active on your mobile phone definitions).
        </span>
      </p>
      <h2>ACCESS TO THE PLATFORM</h2>
      <p>
        <span>
          Access to the Platform does not require any registration. However, in
          order to use the Platform, you will be required to register and
          provide to EMPOWR certain personal data, as foreseen under the Privacy
          Policy.
        </span>
      </p>
      <p>
        <span>
          You will also be required to select the social media profiles that you
          wish to be connected to your EMPOWR account. In turn, the social media
          platforms you choose will be providing EMPOWR with information
          previously provided by you (and that is made public by you on your
          profile of said social media platforms) and which will be used by
          EMPOWR in accordance with and for the purposes set out in the Privacy
          Policy.
        </span>
      </p>
      <p>
        <span>
          Once your account has been created, you will also be able to sign in
          to the Platform via certain third party social media platforms and be
          required by EMPOWR to authenticate, register for or sign into social
          media websites on or through those websites. In turn, the social media
          websites will be providing EMPOWR with information previously provided
          by you to such websites and which will be used by EMPOWR in accordance
          with our Privacy Policy.
        </span>
      </p>
      <p>
        <span>
          EMPOWR has the exclusive right to, at any time, suspend, block,
          interrupt or terminate your account or in whole or in part, your
          access to or use of the Platform, particularly in management,
          maintenance, repair, modification or update operations or to
          terminate, temporarily or definitively, in whole or in part, at its
          discretion and without notice the Platform.
        </span>
      </p>
      <p>
        <span>
          You agree and acknowledge that EMPOWR shall not be liable before you
          or any third party for any termination of your access to and use of
          the Platform.
        </span>
      </p>
      <p>
        <span>
          You may also terminate the access to and use of the Platform by
          deleting your account, ceasing to use the Platform and deleting all
          copies, icons, shortcuts, or files related thereto.
        </span>
      </p>
      <p>
        <span>
          By using or attempting to use our Services, you acknowledge, represent
          and warrant that:
        </span>
      </p>
      <ul>
        <li aria-level="1">
          <span>
            You shall not violate, and shall comply with, all applicable
            statutes, orders, regulations, rules and other laws;
          </span>
        </li>
        <li aria-level="1">
          <span>You shall not violate the rights of any third party;</span>
        </li>
        <li aria-level="1">
          <span>
            You are the legal age of maturity in your jurisdiction, and that you
            have the power to form a binding contract with us and are not barred
            under any applicable laws from doing so. Regardless of the legal age
            of majority in your jurisdiction, in no instance may you use or
            attempt to use our Services in the event you are under the age of
            eighteen (18);
          </span>
        </li>
        <li aria-level="1">
          <span>
            EMPOWR is not providing any legal services and shall not advise User
            regarding any legal matters. In the event User desires to obtain
            legal counsel, User shall seek an independent legal counsel licensed
            to practice law in User's jurisdiction. User shall not rely on
            EMPOWR for any such counsel;
          </span>
        </li>
        <li aria-level="1">
          <span>
            You shall not utilize the Services in any manner violative of any
            agreement made with us or an Advertiser or Content Creator,
            including Campaign Agreements, through our Services.
          </span>
        </li>
      </ul>
      <h2>Payments</h2>
      <p>
        <span>
          Payment Terms.&nbsp; Customers will pay the subscription fees
          (&ldquo;Subscription Fees&rdquo;) and any other fees specified in each
          Service Order (collectively, the &ldquo;Fees&rdquo;) in accordance
          with the payment terms detailed in the Service Order.&nbsp; If no
          payment terms are provided in the Service Order, Fees for the EMPOWR
          Service are due and payable in advance, net thirty days from the
          invoice date.&nbsp; All Fees are non-refundable.&nbsp; All past due
          invoices not subject to a good faith dispute will be subject to a 2%
          fee per month.&nbsp; If EMPOWR pursues collection of any overdue Fees
          payable hereunder, Customer will reimburse all reasonable third party
          costs and fees incurred by EMPOWR in connection with those collection
          activities.
        </span>
      </p>
      <p>
        <span>
          Expenses. If the parties agree to the performance of any Services on
          site, Customer will pay all of EMPOWR&rsquo;s reasonable travel
          expenses. EMPOWR will obtain prior approval of such expenses and will
          provide standard supporting documentation with any reimbursement
          request.
        </span>
      </p>
      <p>
        <span>
          Taxes. If EMPOWR has the legal obligation (under applicable law) to
          pay or collect taxes for which the Customer is responsible, including
          but not limited to, sales, use, transfer, privilege, excise, and all
          other taxes and duties that are levied or imposed by reason of
          performance of the Services by EMPOWR under this Agreement, the
          appropriate amount shall be invoiced to and paid by the Customer,
          unless Customer provides EMPOWR with a valid tax exemption certificate
          authorized by the appropriate taxing authority. All amounts payable to
          EMPOWR under this Agreement shall be without set- off and without
          deduction of any taxes, levies, imposts, charges, withholdings and/or
          duties of any nature which may be levied or imposed, including without
          limitation, value added tax, customs duty and withholding tax.
        </span>
      </p>
      <h2>Disclaimer</h2>
      <p>
        <span>
          Except as expressly provided in this agreement, the EMPOWR service and
          all related services are provided &ldquo;as is&rdquo; and &ldquo;as
          available&rdquo; and any promises contained in this agreement are in
          lieu of all other warranties, representations or conditions, express,
          implied, statutory or otherwise, including implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement, all of which are expressly disclaimed.&nbsp; EMPOWR
          does not represent that the EMPOWR service will be uninterrupted or
          error-free or meet customer&rsquo;s requirements. Customer
          acknowledges that, as a saas-based service, the functionality and
          interfaces of the EMPOWR service may change over time, provided that
          such change will not materially degrade the EMPOWR service.
        </span>
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        <span>
          Except in the event of a party&rsquo;s indemnification obligations, a
          party&rsquo;s total cumulative liability for any and all claims and
          damages arising from or in connection with this agreement and the
          subscription, under any contract, tort, strict liability or other
          theory, will not exceed the total fees paid or payable to EMPOWR by
          customer&nbsp; under the service order corresponding to the
          subscriptions or services in respect of which the liability arose and
          limited to the amount of such fee corresponding to the 12 month period
          immediately preceding the date the first claim giving rise to the
          liability arose (the &ldquo;
        </span>
        <strong>general cap</strong>
        <span>&rdquo;).&nbsp;&nbsp;</span>
      </p>
      <p>
        <span>
          Notwithstanding anything the contrary set forth herein, neither party
          will be liable to the other or any third party for lost profits, or
          any special, indirect, incidental, consequential or exemplary damages,
          including lost profits and costs in connection with the performance of
          services or the performance of any obligations under this agreement
          even if it is aware of the possibility of the occurrence of such
          damages.
        </span>
      </p>
      <h2>Term and Termination</h2>
      <p>
        <span>
          Term. This Agreement shall commence on the Effective Date and shall
          continue for the Subscription Term and any Renewed Subscription Term
          (as defined within the applicable Service Order).&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span>
          Termination.&nbsp; Either party may terminate this Agreement
          immediately on giving notice in writing to the other party if the
          other party commits any material breach of any term of this Agreement
          and has not cured such breach within fifteen days of its receipt of
          written notice of the breach.&nbsp; In addition, either party may
          terminate this Agreement immediately on giving notice in writing to
          the other party if the other party files for bankruptcy or
          liquidation; becomes or is declared insolvent, or is the subject of
          any involuntary proceedings related to its liquidation, insolvency or
          the appointment of a receiver or similar officer for it, which
          proceedings are not dismissed within sixty days of filing; makes an
          assignment for the benefit of all or substantially all of its
          creditors; or enters into an agreement for the cancellation,
          extension, or readjustment of substantially all of its obligations.
        </span>
      </p>
      <p>
        <span>
          Suspension of EMPOWR Service.&nbsp; Notwithstanding any provision
          herein to the contrary, EMPOWR may suspend the EMPOWR Service in the
          event of any activity by Customer or any of its Users, if such
          activity has, or in EMPOWR&rsquo;s reasonable assessment is likely to
          have, an adverse effect on the EMPOWR Service.
        </span>
      </p>
      <div ref={refundRef}>
        <h2>Billing, Cancellation, and Refund Policies</h2>
      </div>
      <p>
        <span>
          EMPOWR charges and collects in advance for use of the SaaS service.
          All services rendered are non-refundable. Once a customer selects a
          subscription plan and provides billing information, EMPOWR starts a
          monthly subscription from that first billing date.
        </span>
      </p>
      <p>
        <span>
          All subscriptions monthly and yearly renew automatically on their due
          renewal date according to date of purchase until officially canceled
          in writing or from the Empowr dashboard. Customers may also cancel
          anytime by emailing a notice to: support@empowr.ai
        </span>
      </p>
      <p>
        <span>
          Customers will receive an email from EMPOWR confirming that their
          subscription to the SaaS service has been canceled.
        </span>
      </p>
      <p>
        <span>
          Important: No refunds or credits for partial months, quarters or years
          of service will be refunded to a customer upon cancellation.
        </span>
      </p>
      <p>
        <span>
          All fees are exclusive of all taxes, levies, or duties imposed by
          taxing authorities, and the customer is responsible for payments of
          all such taxes, levies, or duties.
        </span>
      </p>
      <p>
        <span>
          The fees that EMPOWR charges for the monthly, or yearly services
          exclude phone, and Internet access charges, as well as other data
          transmission charges. Any currency exchange settlements are based on
          the customer&rsquo;s agreement with the payment method provider. To be
          clear: these charges are the customer&rsquo;s responsibility.
        </span>
      </p>
      <p>
        <span>
          Customers have the ability to upgrade or downgrade their subscription
          plan for the SaaS services at any time. For any kind of upgrade or
          downgrade in subscription, customers have to write to
          support@empowr.ai or make the changes on the EMPOWR dashboard.
        </span>
      </p>
      <p>
        <span>
          The chosen method of billing will automatically be charged the new
          subscription rate on the next billing cycle.
        </span>
      </p>
      <p>
        <span>
          With regards to downgrades on a subscription, EMPOWR does not issue
          refunds or credits for partial months of service.
        </span>
      </p>
      <p>
        <span>
          EMPOWR reserves the right to refuse/cancel a subscription to any of
          the SaaS services bought from Empowr.ai, If EMPOWR refuses a new or
          renewing subscription/plan, registrants will be offered a refund
          on prorata basis of pending days in subscription plan bought.
        </span>
      </p>
      <p>
        <span>
          These above policies apply to all the SaaS services listed on EMPOWR
          unless otherwise noted in the corresponding program materials.
        </span>
      </p>
      <h2>Contact Us</h2>
      <p>
        <span>
          If you have questions about the cancelation and refund policy, please
          contact us on{" "}
        </span>
        <a href="mailto:support@empowr.ai">
          <span>support@empowr.ai</span>
        </a>
      </p>
      <h2>General</h2>
      <p>
        <span>
          Ownership. EMPOWR and its third party licensors will retain all
          ownership interest in and to the EMPOWR Service and its underlying
          systems and Customer&rsquo;s rights are limited to those expressly
          stated in this Agreement. Notwithstanding any provision herein to the
          contrary, nothing in this Agreement is intended to limit
          Customer&rsquo;s liability in the event of Customer&rsquo;s violation
          of the intellectual property rights of EMPOWR and any claim with
          respect to such violation will not be deemed governed by this
          Agreement.
        </span>
      </p>
      <p>
        <span>
          Force Majeure. Except with regard to payments due to EMPOWR, neither
          party will be liable under this Agreement for non-performance caused
          by events or conditions beyond that party's control if the party makes
          reasonable efforts to perform.
        </span>
      </p>
      <p>
        <span>
          Notices. Marketing and business-related notices may be delivered by
          email.&nbsp; Any legal notices relating to this Agreement must be in
          writing and delivered by hand or by pre-paid first class mail or other
          next working day delivery service to each party at the address set
          forth in the applicable Service Order or such other address provided
          by the recipient.&nbsp; All notices will be sent by major commercial
          delivery courier service or mailed in a manner that requires a
          signature by the recipient.
        </span>
      </p>
      <p>
        <span>
          Governing Law This Agreement and any disputes hereunder will be
          governed by the laws of the State of Delaware, without regard to its
          conflict of law principles, and any litigation concerning this
          Agreement will be submitted to and resolved by a court of competent
          jurisdiction in Delaware, USA.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span>
          Entire Agreement. This Agreement constitutes the entire agreement
          between the parties concerning the subject matter herein and
          supersedes any and all prior communications and agreements whether
          written or oral concerning the subject matter hereof.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span>
          Modifications; Severability. Except as expressly provided herein, any
          modification to this Agreement must be made in writing and signed by
          an authorized representative of each party.&nbsp; If any provision of
          this Agreement is held to be unenforceable, such provision will be
          reformed to the extent necessary to make it enforceable, and such
          holding will not impair the enforceability of the remaining
          provisions.
        </span>
      </p>
      <p>
        <span>
          Waiver. The failure by a party to exercise any right hereunder or to
          insist upon or enforce strict performance of any provision of this
          Agreement will not waive such party's right to exercise that or any
          other right in the future.
        </span>
      </p>
      <p>
        <span>
          No Agency. Nothing herein will be construed to create a partnership,
          joint venture or any type of agency relationship between EMPOWR and
          Customer or any User.
        </span>
      </p>
      <p>
        <span>
          Assignment. Neither party shall assign this Agreement without the
          prior written consent of the other party; provided that either party
          may assign this Agreement without the need for such prior written
          consent in the event of a sale of all or substantially all of such
          party&rsquo;s assets, business or a majority of such party&rsquo;s
          voting securities or in the event of any merger or other change of
          control with respect to such party.
        </span>
      </p>
      <p>
        <span>
          No Third-Party Beneficiary. Nothing expressed or implied in this
          Agreement is intended to, or does, confer upon any person other than
          EMPOWR, Customer and their respective successors or assigns (to the
          extent permitted under this Agreement), any rights, remedies,
          obligations or liabilities whatsoever.
        </span>
      </p>
      <p>
        <span>
          YOU AGREE THAT YOUR USE OF THIRD-PARTY WEBSITES, APPLICATIONS,
          SERVICES AND RESOURCES, INCLUDING WITHOUT LIMITATION YOUR USE OF ANY
          CONTENT, INFORMATION, DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS
          ON OR AVAILABLE THROUGH SUCH THIRD PARTIES, IS AT YOUR OWN RISK AND IS
          SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH SITES
          AND RESOURCES.
        </span>
      </p>
    </Box>
  );
};

export default TermsOfService;
