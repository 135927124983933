import { Clear, Warning } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import styles from "../../styles/internal/Campaign/CampaignList.module.css";
import { useSelector } from "react-redux";

const ImpersonateDialog = ({ openDialog }) => {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(openDialog);
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: 500,
          height: 300,
        },
      }}
    >
      <DialogTitle>
        <b>
          <Warning sx={{ color: "#d6d61f" }} /> You are in impersonate mode
        </b>
      </DialogTitle>
      <DialogContent className={styles.container}>
        <p className={styles.childContent}>
          Hello {user.oldEmail},
          <br />
          Remember you are currently logged in {user.email}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{
            width: "100%",
            height: "40px",
            background: "#474747",
            color: "white",
            borderColor: "#474747",
            borderRadius: "b6px",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          {" "}
          I acknowledge
        </Button>{" "}
      </DialogActions>
    </Dialog>
  );
};

export default ImpersonateDialog;
