import { Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import ExportData from '../FilterComponents/ExportData'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoadingButton from '@mui/lab/LoadingButton';

const UnlockDataCard = (props) => {
  const { selectedData, tableRowsPerPage, payload, sortCol, selectedPlatform, tablePageNo, customCols, unlockResultHandler, isUnlockingResults, totalTableRows, unlockedDataCount, discoverCredits } = props;
  useEffect(() => {
  }, [isUnlockingResults])

  return (
    <Box bgcolor="rgb(54,123,245,0.2)" p={"1rem"} borderRadius={"12px"} my={1} width={"450px"}>
      <Typography textAlign={"center"} color={"rgba(0,0,0,0.8)"} mb={3}>
        {totalTableRows} Influencers found
      </Typography>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <LoadingButton
          variant="contained"
          sx={{ background: "rgb(54,123,245)", color: '#ffffff', '&:hover,&:active': { background: "rgba(54,123,245,0.8)" }, borderRadius: "12px", mr: "6px" }}
          disableElevation
          onClick={unlockResultHandler}
          startIcon={<LockOpenIcon />}
          loading={isUnlockingResults}
          disabled={discoverCredits === 0 || isUnlockingResults}
        >
          View {unlockedDataCount} results
        </LoadingButton>
        <ExportData
          selectedData={selectedData}
          tableRowsPerPage={tableRowsPerPage}
          payload={payload}
          sortCol={sortCol}
          selectedPlatform={selectedPlatform}
          tablePageNo={tablePageNo}
          customCols={customCols}
          unlockedDataCount={unlockedDataCount}
        />
      </Box>


    </Box>
  )
}

export default UnlockDataCard
