import { ThemeProvider } from "@emotion/react";
import React, { useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import theme from "../styles/loginTheme";
import styles from "../styles/internal/MainLayout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AppBar, CssBaseline, Snackbar, Toolbar } from "@mui/material";
import { closeAlert } from "../redux/features/system/systemAlert";
import TopBrands from "../components/landing/TopBrands";
import MainFooter from "./App/MainFooter";
import ResponsiveAppBar from "./App/ResponsiveAppBar";

function MainLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const systemAlert = useSelector((state) => state.systemAlert);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.loggedIn) {
      navigate("/app/dashboard");
    }

    if (!user.loggedIn && location.pathname?.length === 1) {
      navigate("/");
    }

    // setBackground();

    // return () => {
    //   document.body.style.background = "rgba(224, 224, 224, 0.55)";
    //   document.body.style.backgroundAttachment = "fixed";
    // };
  }, []);

  // const setBackground = () => {
  //   document.body.style.background =
  //     "linear-gradient(61deg, rgba(237, 234, 228, 1) 0%, rgba(56, 125, 154, 1) 61%, rgba(27, 39, 74, 1) 100%)";
  //   document.body.style.backgroundAttachment = "fixed";
  // };

  // useLayoutEffect(() => {
  //   window.addEventListener("resize", setBackground);
  //   return () => window.removeEventListener("resize", setBackground);
  // }, []);

  // setTimeout(() => {
  //   setBackground();
  // }, 500);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.background}>
        <ResponsiveAppBar demo={true} />
        <Outlet />
        <MainFooter />
      </div>
      {systemAlert !== undefined && systemAlert.message && (
        <Snackbar
          open={systemAlert.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ top: systemAlert.top }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={systemAlert.type}
            variant="filled"
            sx={{
              width: "100%",
              fontSize: "1rem", // Adjust the font size as needed
            }}
          >
            {systemAlert.message}
          </Alert>
        </Snackbar>
      )}
    </ThemeProvider>
  );
}

export default MainLayout;
