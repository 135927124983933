import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Platforms from "./Platforms";
import DiscoveryTable from "./DiscoveryTable";
import DiscoveryAppliedFilters from "./FilterComponents/DiscoveryAppliedFilters";
import { Alert, Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { checkForCredit } from "../../services/credit";
import BlockLoginAccess from "../common/BlockLoginAccess";
import SortByColumns from "./FilterComponents/SortByColumns";
import EditColumns from "./FilterComponents/EditColumns";
import KeyEnum from "./KeyEnum";
import ExportData, { sortField } from "./FilterComponents/ExportData";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./components/AlertDialog";
import { notify } from "../../redux/features/system/systemAlert";
import { fetchDataByPlatform, fetchLocationDataByIds } from "../../services/discovery";
import MoreFilters from "./FilterComponents/MoreFilters";
import { useData } from "../../redux/features/data/DataContext";
import MyPaper from "../../utility/oryxUtils/MyPaper";
import { History } from "@mui/icons-material";

const Discovery = ({ sortOrderChanged, props }) => {
  const [appliedFilters, setAppliedFilters] = useState({});
  const [resetPageNo, setResetPageNo] = useState(false);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [tablePageNo, setTablePageNo] = useState(0); // page no starting from 1, as opposed to mui table (which starts from 0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const [isChanged, setIsChanged] = useState(false);
  const [tableRowHeaders, setTableRowHeaders] = useState([]);
  const [finalTableRowHeaders, setFinalTableRowHeaders] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("instagram");
  const [tableDataUpdated, setTableDataUpdated] = useState(false);

  const [sortCol, setSortCol] = useState({
    field: "inf_followers",
    name: KeyEnum["inf_followers"],
  }); //sorted by default by lastposted date
  // const [sortOrder, setSortOrder] = useState("desc"); //sorted in descending order by default
  const [customCols, setCustomCols] = useState([{}]);
  const [allFiltersData, setAllFiltersData] = useState([{ inf_hidden: [{ name: "Exclude private accounts" }] }]);
  const [selectedCol, setSelectedCol] = useState([{}]);
  const [creditValue, setCreditValue] = useState(0);
  const [credit, setCredit] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [payload, setPayload] = useState({ inf_hidden: false });
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const [discoverCredits, setDiscoverCredits] = useState(0);
  // states for unlock results
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [unlockPayload, setUnlockPayload] = useState(null);
  const [hiddenResults, setHiddenResults] = useState(true);
  const [isUnlockingResults, setIsUnlockingResults] = useState(false);
  const [unlockedDataCount, setUnlockedDataCount] = useState(0);
  const [unlockConfirmation, setUnlockConfirmation] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [lockedResults, setLockedResults] = useState([]);
  const [isMoreFiltersExpanded, setIsMoreFiltersExpanded] = useState(false);
  const [fromCampaignCreation, setFromCampaignCreation] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useData();
  const tablePageChanged = (pageNo) => {
    setTablePageNo(pageNo * tableRowsPerPage);
    setResetPageNo(false);
    setIsChanged(!isChanged);
  };

  useEffect(() => {
    const hasFilters = Object.keys(payload).some(key => {
      // Exclude 'inf_hidden' and check if the value is not empty, null, or false
      return key !== 'inf_hidden' && 
             payload[key] !== false && 
             payload[key] !== undefined && 
             payload[key] !== null && 
             payload[key] !== '' &&
             (
               (Array.isArray(payload[key]) && payload[key]?.length > 0) || 
               (typeof payload[key] === 'object' && Object.keys(payload[key])?.length > 0) ||
               (typeof payload[key] !== 'object')
             );
    });
  
    setFiltersApplied(hasFilters);
  }, [payload]);

  const tableRowsPerPageChanged = (rowsPerPage) => {
    setTableRowsPerPage(rowsPerPage);
    setResetPageNo(false);
    setIsChanged(!isChanged);
  };

  const handleInformationChange = (newPage) => {
    if (tablePageNo !== newPage.page && newPage.page > tablePageNo && unlockedDataCount > 0 && discoverCredits > 0) {
      setUnlockPayload(newPage);
      alertDialogOpenHandler();
      return;
    }
    setTablePageNo(newPage.page);
    setResetPageNo(false);
    setIsChanged(!isChanged);
    // setUnlockedDataCount(tableRowsPerPage);
    if (newPage.rowsPerPage !== tableRowsPerPage);
    setTableRowsPerPage(newPage.rowsPerPage);
  };

  const unlockHandler = () => {
    setTablePageNo(unlockPayload.page);
    setResetPageNo(false);
    setIsChanged(!isChanged);
    // if (unlockPayload.rowsPerPage !== tableRowsPerPage);
    // setTableRowsPerPage(unlockPayload.rowsPerPage);
    setOpenAlertDialog(false);
  };

  const clearAllFilter = () => {
    setPayload({});
    setCustomCols([{}]);
    setSelectedCol([{}]);
    setAllFiltersData([{}]);
    setIsChanged(!isChanged);
  };

  const alertDialogOpenHandler = () => {
    setOpenAlertDialog(true);
  };

  const alertDialogCloseHandler = (userDecision) => {
    if (userDecision) {
      setUnlockConfirmation(true);
      unlockResultsHandler(true);
    } else {
      setUnlockConfirmation(true);
    }
    setOpenAlertDialog(false);
  };

  const unlockResultsHandler = async (isPageChange = false) => {
    // unlock results & change page
    setIsUnlockingResults(true);
    try {
      const { status, response } = await fetchDataByPlatform(
        payload,
        sortCol,
        sortField,
        selectedPlatform,
        tablePageNo,
        tableRowsPerPage,
        customCols,
        dispatch,
        user,
        null,
        null,
        lockedResults?.map((item) => item?.account?.search_result_id),
        selectedPlatform,
        user,
        dispatch
      );
      if (status === 200) {
        if (response?.status === "success") {
          setHiddenColumns([]);
          setUnlockedDataCount(0);
          if (discoverCredits > unlockedDataCount) {
            setDiscoverCredits(discoverCredits - unlockedDataCount);
          } else {
            setDiscoverCredits(0);
          }
          if (isPageChange) {
            unlockHandler();
          }
          setTimeout(() => {
            dispatch(
              notify({
                type: "success",
                message: "Influencers unlocked successfully",
              })
            );
          }, 1000);
          setIsUnlockingResults(false);
          return response?.data?.accounts;
        } else return [];
      }
    } catch (error) {
      console.log(error);
    }
    setIsUnlockingResults(false);
  };

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const extractData = async (data) => {
    let newPayload, customCols, filterData;
    if (data.setFrom === "campaign") {
      setFromCampaignCreation(true);
      const followerArray = data.audienceSelected?.split("-");
      newPayload = {
        aud_age: {
          operator: "gte",
          weight: 0.01,
          right_number: data.ageGroup?.inf_age_to,
          left_number: data.ageGroup?.inf_age_from,
        },
        inf_followers_from: followerArray?.length > 0 ? followerArray[0] : "",
        inf_followers_to: followerArray?.length > 0 ? followerArray[1] : "",
        inf_hidden: false,
        aud_gender: { code: data.gender?.toUpperCase(), weight: 0.5 },
        aud_location: { id: Number(data.location?.id), weight: 0.05 },
        audience_brand_category: data.interest?.map((interest) => {
          return { id: Number(interest.id), weight: 0.05 };
        }),
      };
      customCols = [
        {
          aud_location: data.location?.name,
          aud_gender: data.gender?.toUpperCase(),
          aud_brand_category: data.interest?.map((interest) => interest.name),
        },
      ];
      if (customCols[0].aud_brand_category?.length === 0) delete customCols[0].aud_brand_category;
      filterData = [
        {
          aud_age: [
            {
              operator: "gte",
              weight: 0.01,
              right: data.ageGroup?.inf_age_to,
              left: data.ageGroup?.inf_age_from,
            },
          ],
          aud_gender: [{ code: data.gender?.toUpperCase(), weight: 0.5 }],
          aud_location: [{ id: Number(data.location?.id), name: data.location?.name }],
        },
      ];
    } else {
      setFromCampaignCreation(true);
      const { aud_age, inf_followers_from, inf_followers_to, aud_gender, aud_location, aud_brand_category } = data;
      const idArray = [];
      const locations = [];
      if (aud_location) {
        aud_location.forEach((value) => idArray.push(value.id));
        const resp = await fetchLocationDataByIds(idArray, dispatch, user);
        const { commonData } = resp.response;
        const response = Object.keys(commonData);
        response?.map((value) => {
          locations.push({ id: value, name: commonData[value] });
        });
      }
      newPayload = {
        aud_age: {
          operator: "gte",
          weight: 0.01,
          right_number: aud_age?.rightnumber,
          left_number: aud_age?.leftnumber,
        },
        inf_followers_from,
        inf_followers_to,
        inf_hidden: false,
        aud_gender,
        aud_location,
        aud_brand_category,
      };
      customCols = [
        {
          aud_location: locations?.map((value) => value.name),
          aud_gender: aud_gender?.code,
        },
      ];
      if (customCols[0].aud_brand_category?.length === 0) delete customCols[0].aud_brand_category;
      filterData = [
        {
          aud_age: [
            {
              operator: "gte",
              weight: 0.01,
              right: aud_age?.rightnumber,
              left: aud_age?.leftnumber,
            },
          ],
          aud_gender: [aud_gender],
          aud_location: locations,
        },
      ];
    }

    setAllFiltersData(filterData);
    setPayload(newPayload);
    setCustomCols(customCols);
  };

  useEffect(() => {
    const handleEffect = async () => {
      if (data) {
        extractData(data);
      }
      updateWidth();
      await checkForCreditValue();
      window.scrollTo(0, 0);
    };
  
    handleEffect(); 
  }, []); 

  const checkForCreditValue = async () => {
    const data = {
      name: ["Discovery.Analyze", "Discovery.Discover"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    const credits = parseInt(response?.response.message[data.name[0]]);
    const discoverCredits = parseInt(response?.response.message[data.name[1]]);
    if (discoverCredits > 0) setDiscoverCredits(discoverCredits);
    else setDiscoverCredits(0);
    if (credits > 0) {
      setCredit(credits);
      setCreditValue(credits);
    }
  };

  const updateCreditValue = (event) => {
    setCreditValue(event);
    setTableDataUpdated(true);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleExpandMoreFilters = (panel) => (isExpanded) => {
    setIsMoreFiltersExpanded(isExpanded ? panel : false);
  };

  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          <MyPaper>
            <Platforms
              setSelectedData={setSelectedData}
              setTableDataUpdated={setTableDataUpdated}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setTableDataLoaded={setTableDataLoaded}
              tablePageChanged={tablePageChanged}
              tableRowsPerPageChanged={tableRowsPerPageChanged}
              tableRowsPerPage={tableRowsPerPage}
              tablePageNo={tablePageNo}
              setTablePageNo={setTablePageNo}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              resetPageNo={resetPageNo}
              setResetPageNo={setResetPageNo}
              payload={payload}
              setPayload={setPayload}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              selectedCol={selectedCol}
              setSelectedCol={setSelectedCol}
              sortCol={sortCol}
              selectedPlatform={selectedPlatform}
              setSelectedPlatform={setSelectedPlatform}
              clearAllFilter={clearAllFilter}
              expandMoreFilters={handleExpandMoreFilters}
              isMoreFiltersExpanded={isMoreFiltersExpanded}
            />
          </MyPaper>
          {appliedFilters && Object.keys(appliedFilters)?.length > 0 && (
            <DiscoveryAppliedFilters
              appliedFilters={appliedFilters}
              payload={payload}
              setPayload={setPayload}
              customCols={customCols}
              setCustomCols={setCustomCols}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              setSelectedCol={setSelectedCol}
            />
          )}
          <MoreFilters
            expanded={isMoreFiltersExpanded}
            setExpanded={handleExpandMoreFilters}
            setSelectedData={setSelectedData}
            setTableDataUpdated={setTableDataUpdated}
            selectedPlatform={selectedPlatform}
            customCols={customCols}
            setCustomCols={setCustomCols}
            tableDataLoaded={tableDataLoaded}
            setTableDataLoaded={setTableDataLoaded}
            tablePageChanged={tablePageChanged}
            tableRowsPerPageChanged={tableRowsPerPageChanged}
            tableRowsPerPage={tableRowsPerPage}
            tablePageNo={tablePageNo}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            resetPageNo={resetPageNo}
            setTablePageNo={setTablePageNo}
            payload={payload}
            setPayload={setPayload}
            allFiltersData={allFiltersData}
            setAllFiltersData={setAllFiltersData}
            selectedCol={selectedCol}
            setSelectedCol={setSelectedCol}
            sortCol={sortCol}
            clearAllFilter={clearAllFilter}
          />

          <Box
            sx={{
              my: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <SortByColumns columnValue={sortCol} columnHandler={setSortCol} allFiltersData={allFiltersData} />

              <EditColumns tableRowHeaders={tableRowHeaders} setFinalTableRowHeaders={setFinalTableRowHeaders} />
            </Box>
            {/* <Alert
            severity={credit > 0 ? "info" : "warning"}
            sx={{ boxShadow:1 }}
          >
            {
              credit > 0 ?
                <>Analysis of <b>{credit}</b> influencers is available based on your subscription. Upgrade to discover more.</> :
                "Upgrade your plan to discover more influencers this month, as you have reached your limit."
            }
          </Alert> */}
            {selectedData && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    color: "black",
                    background: "white",
                    border: "1px solid lightgrey",
                    borderRadius: "12px",
                    "&:hover": { bgcolor: "lightgray" },
                    whiteSpace: "nowrap",
                    px: "12px",
                    textTransform: "none",
                    justifyContent: "space-between",
                    mr: "1rem",
                  }}
                  variant="contained"
                  disableElevation
                  onClick={() => navigate(`/app/discovery/history/discovery`)}
                  startIcon={<History />}
                >
                  Show Search History
                </Button>
                <Button
                  sx={{
                    color: "black",
                    background: "white",
                    border: "1px solid lightgrey",
                    borderRadius: "12px",
                    "&:hover": { bgcolor: "lightgray" },
                    whiteSpace: "nowrap",
                    px: "12px",
                    textTransform: "none",
                    justifyContent: "space-between",
                    mr: "1rem",
                  }}
                  variant="contained"
                  disableElevation
                  onClick={() => navigate("/app/discovery/exports")}
                  startIcon={<DownloadingRoundedIcon />}
                >
                  My Exports
                </Button>
                <ExportData
                  selectedData={selectedData}
                  tableRowsPerPage={tableRowsPerPage}
                  payload={payload}
                  sortCol={sortCol}
                  selectedPlatform={selectedPlatform}
                  tablePageNo={tablePageNo}
                  customCols={customCols}
                  unlockedDataCount={unlockedDataCount}
                />
              </Box>
            )}
          </Box>
          <DiscoveryTable
            platform={selectedPlatform}
            filtersApplied={filtersApplied}
            tableDataLoaded={tableDataLoaded}
            tableData={selectedData}
            tableDataUpdated={tableDataUpdated}
            setTableDataUpdated={setTableDataUpdated}
            setCreditValue={updateCreditValue}
            setCredit={setCredit}
            credit={credit}
            tablePageChanged={tablePageChanged}
            tableRowsPerPageChanged={tableRowsPerPageChanged}
            sortOrderChanged={sortOrderChanged}
            updatePageNo={resetPageNo}
            tableRowHeaders={tableRowHeaders}
            finalTableRowHeaders={finalTableRowHeaders}
            selectedData={selectedData}
            customCols={customCols}
            setTableRowHeaders={setTableRowHeaders}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            allFiltersData={allFiltersData}
            setAllFiltersData={setAllFiltersData}
            tableRowsPerPage={tableRowsPerPage}
            tablePageNo={tablePageNo}
            setTablePageNo={setTablePageNo}
            sortCol={sortCol}
            handleInformationChange={handleInformationChange}
            payload={payload}
            hiddenResults={hiddenResults}
            setHiddenResults={setHiddenResults}
            isUnlockingResults={isUnlockingResults}
            setIsUnlockingResults={setIsUnlockingResults}
            unlockedDataCount={unlockedDataCount}
            setUnlockedDataCount={setUnlockedDataCount}
            unlockConfirmation={unlockConfirmation}
            unlockHandler={unlockHandler}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
            lockedResults={lockedResults}
            setLockedResults={setLockedResults}
            unlockResultsHandler={unlockResultsHandler}
            discoverCredits={discoverCredits}
          />
          <AlertDialog
            isOpen={openAlertDialog}
            alertDialogOpenHandler={alertDialogOpenHandler}
            alertDialogCloseHandler={alertDialogCloseHandler}
            unlockCount={unlockedDataCount}
          />
        </>
      )}
      {width <= 768 && <BlockLoginAccess openDialog={true} />}
    </div>
  );
};
export default Discovery;
