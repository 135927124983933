import React, { useEffect } from "react";
import BaseContractsAndActivate from "../../components/landing/BaseContractsAndActivate";

function ContractsAndActivate(props) {
  useEffect(() => {
    setBackground();
  }, []);

  const setBackground = () => {
    document.body.style.background = "white";
  };

  return (
    <div>
      <BaseContractsAndActivate /> <br />
    </div>
  );
}

export default ContractsAndActivate;
