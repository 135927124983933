import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CampaignDynamicLinksFilter = ({
  selectedStartDate,
  selectedEndDate,
  sendFilterChange,
  allurls,
  selectedShorturls,
  setSelectedShorturls,
  filterStatus,
}) => {
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [linkSelectAll, setLinkSelectAll] = useState(true);
  const [hasChanged, setHasChanged] = useState(false); 

  const sendSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
    setHasChanged(true); 
  };

  const selecturls = (value) => {
    let selectAllPresent = false;
    for (let i = 0; i < value?.length; i++) {
      if (value[i].id === -1) {
        selectAllPresent = true;
        break;
      }
    }
    if (selectAllPresent) {
      if (linkSelectAll) {
        setLinkSelectAll(false);
        setSelectedShorturls([]);
      } else {
        const values = allurls?.filter((val) => val.id !== -1);
        setSelectedShorturls(values);
        setLinkSelectAll(true);
      }
      setHasChanged(true); 
      return;
    }
    let selectAll = false;
    if (value?.length === allurls?.length) {
      selectAll = true;
    }
    setSelectedShorturls(value);
    setLinkSelectAll(selectAll);
    setHasChanged(true);
  };

  const applyFilters = () => {
    sendFilterChange({
      startDate,
      endDate,
      selectedShorturls,
    });
    setHasChanged(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={2} />
        <Grid item md={1} />
        <Grid item md={2} />
        <Grid item md={3}>
          <Autocomplete
            id="free-solo-2-demo"
            disableCloseOnSelect
            multiple
            limitTags={0}
            disableClearable
            options={[{ id: -1, shortUrl: "Select All" }, ...allurls]}
            value={selectedShorturls}
            disabled={allurls?.length === 0 || filterStatus}
            onChange={(_, newValue) => {
              selecturls(newValue);
            }}
            getOptionLabel={(option) => option.shortUrl}
            renderTags={(selected, _, state) =>
              state.popupOpen ? "" : `+${selected?.length}`
            }
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={option.id === -1 ? linkSelectAll : selected}
                />
                {option.shortUrl}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selected Short urls"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    minWidth: "7vw",
                    marginRight: "5px",
                    borderRadius: "10px",
                  },
                }}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                
              />
            )}
          />
        </Grid>
        <Grid item md={3}>
          {" "}
          <DateRangeCustomPicker
            sendSelectedDates={sendSelectedDates}
            parentStartDate={startDate}
            parentEndDate={endDate}
            filterStatus={filterStatus}
            needRange={true}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
              borderRadius: "8px",
            }}
            onClick={applyFilters}
            disabled={!hasChanged || allurls?.length === 0 || filterStatus}
          >
            Go
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Grid container spacing={2}></Grid>
        </Grid>
      </Grid>
    </>
  );
};
CampaignDynamicLinksFilter.propTypes = {
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  sendFilterChange: PropTypes.func.isRequired,
  allurls: PropTypes.array.isRequired,
  selectedShorturls: PropTypes.array.isRequired,
  setSelectedShorturls: PropTypes.func.isRequired,
  filterStatus: PropTypes.bool.isRequired,
};

export default CampaignDynamicLinksFilter;
