import React, { useState } from "react";
import { Done } from "@mui/icons-material";
import { Box, Button, Grid, styled, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import { changeSubscriptionForAccount, reactivateSubscriptionForAccount } from "../../services/billing";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { changeBillingAttributes } from "../../redux/features/user/userSlice";
import BasePricing from "../../components/landing/BasePricing";

const PricingToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:first-of-type)": {
      borderRadius: "0px 10px 10px 0px",
      width: "180px",
      color: "black",
      borderColor: "black",
    },
    "&:first-of-type": {
      borderRadius: "10px 0px 0px 10px",
      width: "150px",
      marginRight: "0",
      color: "black",
      borderColor: "black",
    },
    "&.Mui-selected": {
      backgroundColor: "black",
      color: "white",
      "&:hover": {
        backgroundColor: "#333", // Slightly lighter shade on hover
      },
    },
  },
}));

function PricingCustomized({ planDetails, user, typeOfRequest, cardDetails, handleUpgradeCardClose }) {
  const [pricingType, setPricingType] = useState("monthly");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { value } = event.target;
    setPricingType(value);
  };

  const upgradeAccount = async (planChangeTo, planName, activity) => {
    dispatch(
      notify({
        message:
          typeOfRequest === "upgrade"
            ? `Please wait... We will ${activity}  your subscription`
            : `Please wait... We are reactivating  your subscription`,
        type: "info",
      })
    );
    if ([2,3,6].includes(planChangeTo) && cardDetails.first_name === "") {
      dispatch(
        notify({
          message: "Please add your card details to upgrade to a starter plan",
          type: "error",
        })
      );
      handleUpgradeCardClose(false);
      return;
    }

    if (typeOfRequest !== "upgrade") {
      changeBillingAttributes({
        isLocked: true,
      });
      const response = await reactivateSubscriptionForAccount(user, dispatch);
      if (response?.response === "success") {
        dispatch(notify({ message: `Your subscription has been reactivated  successfully`, type: "success" }));
      } else {
        dispatch(notify({ message: response?.response, type: "error" }));
      }
    } else {
      const data = {
        agencyId: user.agencyId.toString(),
        newPlanCode: planChangeTo.toString(),
        email: user.email,
        newPlanName: planName,
      };
      const response = await changeSubscriptionForAccount(data, user, dispatch);
      if (response?.response === "success") {
        dispatch(notify({ message: `Your subscription has been ${activity}d  successfully`, type: "success" }));
      } else {
        dispatch(notify({ message: response?.response, type: "error" }));
      }
    }

    handleUpgradeCardClose(false);
  };

  return (
    <>
      {/* Header */}
      <div className={styles.headerBox} style={{ padding: "25px 25px 1px", backgroundColor: "white", height: "60%" }}>
        <div className={styles.title} style={{ fontSize: "40px", margin: "0px auto" }}>
          Influencer Marketing Plans and Pricing
        </div>
        <div className={styles.subTitle} style={{ fontSize: "18px" }}>
          Every plan lets you partner with unlimited ambassadors, influencers and affiliates to support your brand.
          <br />
          Choose the option to fit your sales volume and growth goals.
        </div>

        <div>
          {/* <div style={{ textAlign: "center", fontSize: "18px" }}>
            <b>How often do you want to pay?</b>
            <br />
            <PricingToggleGroup
              sx={{ paddingTop: "20px" }}
              value={pricingType}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="monthly">Monthly</ToggleButton>
              <ToggleButton value="6-months">
                6 Months &nbsp;
                <span
                  style={{
                    backgroundColor: "#F9D783",
                    padding: "2px",
                    borderRadius: "2px",
                    pointerEvents: "none",
                    color: "black",
                  }}
                >
                  Save 20%
                </span>
              </ToggleButton>
            </PricingToggleGroup>
          </div> */}
          <div style={{ display: "flex", justifyContent: "flex-end", fontSize: "14px", marginRight: "100px" }}>
            <i>*Prices displayed in USD</i>
          </div>
        </div>


        {/* Plans */}

        <BasePricing
          customized={true}
          customizedPlanDetails={planDetails}
          typeOfRequest={typeOfRequest}
          upgradeAccount={upgradeAccount}
        />


 {/* // remove this ******************************************/}
        {/* <div style={{ background: "white", paddingBottom: "50px" }}>
          <Grid container spacing={3} style={{ width: "80%", margin: "auto" }}>
            <Grid item md={4} className={planDetails.planName === "Free" && styles.selectedBox}>
              {planDetails.planName === "Free" && <h4>Current Plan</h4>}
              <div className={styles.card}>
                <Box sx={{ padding: { xs: "32px 6px", lg: "32px" } }}>
                  <div className={styles.cardTitle}>
                    Free <br />
                    $0/mo
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#211F25",
                      maxWidth: "280px",
                      margin: "auto",
                    }}
                  >
                    Basic influencer discovery, analysis and campaign tracking
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    1 Team Member
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    1 Active Campaign
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Basic Influencer Discovery
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    10 Influencer Analysis
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Default Groups
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done style={{ transform: "translateY(10px)" }} />
                    </span>
                    3 Channels
                    <br />
                    <span style={{ width: "40px", display: "inline-block" }} />
                    IG, YT & TT
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    5 Influencers
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    50 Total Publications
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Email Support
                  </div>
                </Box>
                <div style={{ padding: "32px" }}>
                  {user.freeTrialAccessed && typeOfRequest === "upgrade" && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "32px",
                        height: "60px",
                        width: "calc(100% - 64px)",
                        borderColor: "#FF7900",
                        color: "#FF7900",
                        "&:hover": {
                          borderColor: "#FF7900",
                          backgroundColor: "rgba(255, 121, 0, 0.04)",
                        },
                        position: "absolute",
                        top: "750px",
                        fontSize: "20px",
                      }}
                      disabled={true}
                    >
                      Already availed
                    </Button>
                  )}
                  {planDetails.planName !== "Free" && typeOfRequest === "upgrade" && !user.freeTrialAccessed && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "32px",
                        height: "60px",
                        width: "calc(100% - 64px)",
                        borderColor: "#FF7900",
                        color: "#FF7900",
                        "&:hover": {
                          borderColor: "#FF7900",
                          backgroundColor: "rgba(255, 121, 0, 0.04)",
                        },
                        position: "absolute",
                        top: "750px",
                        fontSize: "20px",
                      }}
                      onClick={() => upgradeAccount(12, "Free", "downgrad")}
                    >
                      Downgrade
                    </Button>
                  )}
                  {planDetails.planName === "Free" && typeOfRequest !== "upgrade" && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "32px",
                        height: "60px",
                        width: "calc(100% - 64px)",
                        borderColor: "#FF7900",
                        color: "#FF7900",
                        "&:hover": {
                          borderColor: "#FF7900",
                          backgroundColor: "rgba(255, 121, 0, 0.04)",
                        },
                        position: "absolute",
                        top: "750px",
                        fontSize: "20px",
                      }}
                      onClick={() => upgradeAccount(12, "Free", "downgrad")}
                    >
                      Subscribe
                    </Button>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item md={4} className={planDetails.planName === "Starter" && styles.selectedBox}>
              {planDetails.planName === "Starter" && <h4>Current Plan</h4>}
              <div className={styles.card}>
                <Box sx={{ padding: { xs: "32px 6px", lg: "32px" } }}>
                  <div className={styles.cardTitle}>
                    Starter <br />
                    $299/mo
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#211F25",
                      maxWidth: "280px",
                      margin: "auto",
                    }}
                  >
                    or $299 yearly
                    <br />
                    <br />
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    3 Team Member
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    3 Active Campaign
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Advanced Influencer Discovery
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    200 Influencer Analysis
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Default Groups
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Audience Analysis
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done style={{ transform: "translateY(10px)" }} />
                    </span>
                    5 Channels
                    <br />
                    <span style={{ width: "40px", display: "inline-block" }} />
                    IG, FB, YT, TT & TW
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Email & Chat Support
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Support & Onboarding
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    100 Influencers
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    1000 Total Publications
                  </div>
                </Box>
                <div style={{ padding: "32px" }}>
                  {planDetails.planName !== "Starter" && typeOfRequest === "upgrade" && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "32px",
                        height: "60px",
                        width: "calc(100% - 64px)",
                        borderColor: "#FF7900",
                        color: "#FF7900",
                        "&:hover": {
                          borderColor: "#FF7900",
                          backgroundColor: "rgba(255, 121, 0, 0.04)",
                        },
                        position: "absolute",
                        top: "750px",
                        fontSize: "20px",
                      }}
                      onClick={() => upgradeAccount(6, "Starter", "upgrad")}
                    >
                      Upgrade
                    </Button>
                  )}
                  {planDetails.planName === "Starter" && typeOfRequest !== "upgrade" && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "32px",
                        height: "60px",
                        width: "calc(100% - 64px)",
                        borderColor: "#FF7900",
                        color: "#FF7900",
                        "&:hover": {
                          borderColor: "#FF7900",
                          backgroundColor: "rgba(255, 121, 0, 0.04)",
                        },
                        position: "absolute",
                        top: "750px",
                        fontSize: "20px",
                      }}
                      onClick={() => upgradeAccount(6, "Starter", "upgrad")}
                    >
                      Subscribe
                    </Button>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item md={4} className={planDetails.planName === "Enterprise" && styles.selectedBox}>
              {planDetails.planName === "Enterprise" && <h4>Current Plan</h4>}

              <div className={styles.card}>
                <Box sx={{ padding: { xs: "32px 6px", lg: "32px" } }}>
                  <div className={styles.cardTitle}>
                    Enterprise <br />
                    Contact Sales
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#211F25",
                      maxWidth: "280px",
                      margin: "auto",
                    }}
                  >
                    <br />
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Unlimited Team Members
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Unlimited Campaigns
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Advanced Influencer Discovery
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Unlimited Influencer Analysis*
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Unlimited Groups
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Audience Analysis
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done style={{ transform: "translateY(10px)" }} />
                    </span>
                    6 Channels
                    <br />
                    <span style={{ width: "40px", display: "inline-block" }} />
                    IG, FB, YT, TT, TW & LI
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done style={{ transform: "translateY(10px)" }} />
                    </span>
                    Influencer Outreach & Integrated
                    <br />
                    <span style={{ width: "40px", display: "inline-block" }} />
                    Contracts
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Content Approval Suite
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done style={{ transform: "translateY(10px)" }} />
                    </span>
                    Dedicated Account Manager,
                    <br />
                    <span style={{ width: "40px", display: "inline-block" }} />
                    Onboarding & Consulting
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    White label (Add On)
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    API Access (Add On)
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Unlimited Influencers*
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ width: "40px", display: "inline-block" }}>
                      <Done />
                    </span>
                    Unlimited Posts*
                  </div>
                </Box>
                <div style={{ padding: "32px" }}>
                  {planDetails.planName !== "Enterprise" && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "32px",
                        height: "60px",
                        width: "calc(100% - 64px)",
                        borderColor: "#FF7900",
                        color: "#FF7900",
                        "&:hover": {
                          color: "#FF7900",
                          borderColor: "#FF7900",
                          backgroundColor: "rgba(255, 121, 0, 0.04)",
                        },
                        position: "absolute",
                        top: "750px",
                        fontSize: "20px",
                      }}
                      component={Link}
                      to="/contact"
                    >
                      Contact Us
                    </Button>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: "50px", textAlign: "center" }}>All prices are in USD.</div>
        </div> */}
      </div>

      {/* Book a call */}
      <div style={{ backgroundColor: "white"}}>
        <div
          style={{
            width: "80%",
            margin: "auto 9% auto 11%",
            padding: "60px",
            border: "1px solid #DEE1E6",
            borderRadius: "20px",
            fontSize: "34px",
            lineHeight: "46px",
            fontWeight: "800",
            textAlign: "center",
          }}
        >
          If you're looking for something special,{" "}
          <a href="/requestDemo" style={{ textDecoration: "underline" }}>
            book a call
          </a>{" "}
          with us.
        </div>
      </div>
    </>
  );
}

export default PricingCustomized;
