import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import styles from "../../../../../styles/internal/Campaign/CampaignList.module.css";
import { getContractContent, getContractInput, getContractResponse } from "../../../../../services/contracts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DraftEditor from "../../../../common/DraftEditor";
import Loader from "../../../../common/loader";
import { Close } from "@mui/icons-material";

const PreviewContract = ({ contractDetails, campaignId, campaignName, preview, closePreviewContractDialog }) => {
  const scroll = "paper";
  const user = useSelector((state) => state.user); //get loggedIn user state
  const [place, setPlace] = React.useState("");
  const [acceptedOn, setAcceptedOn] = React.useState("");
  const [kycRequired, setKycRequired] = React.useState("");
  const [responses, setResponses] = React.useState("");
  const [previewModalType, setPreviewModalType] = React.useState("");
  const [contractInputFieldsData, setContractInputFieldsData] = React.useState([]);
  const [autocompleteData, setAutocompleteData] = React.useState([]);
  const [previewModalContent, setPreviewModalContent] = React.useState(null);
  const [previewContent, setPreviewContent] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    handleContractPreview();
  }, []);

  const handleClose = () => {
    closePreviewContractDialog(false);
  };

  const handleContractPreview = async () => {
    setLoader(true);
    const contractInput = await getContractInput(user, dispatch);
    const contractId = contractDetails.contractId;
    setContractInputFieldsData(contractInput.response);
    const contractResponse = await getContractContent(contractId, user, dispatch);
    const response = contractResponse.response[0];
    if (contractDetails.accepted === true) {
      const place = response.place;
      const acceptedOn = moment(response.acceptedOn).format("M/D/YYYY, h:mm:ss A");
      const kycRequired = response.kycRequired;
      setPlace(place);
      setAcceptedOn(acceptedOn);
      setKycRequired(kycRequired);
      const contractResponse = await getContractResponse(contractId, user, dispatch);
      const contractResponses = contractResponse.response !== null ? contractResponse.response : [];
      setResponses(contractResponses);
      setPreviewModalType("accepted");
      setLoader(false);
    } else {
      const autocompleteData = contractInput.response?.filter((value) => {
        const inputFields = response.inputFields.split(",");
        if (inputFields?.length > 0) {
          return inputFields?.map((v) => +v).includes(value.id);
        }
        return [];
      });
      const kycRequired = response.kycRequired;
      setKycRequired(kycRequired);
      setAutocompleteData(autocompleteData);
      setPreviewModalType("other");
      setLoader(false);
    }
    setPreviewModalContent(response);
    setPreviewContent(true);
  };
  return (
    <Dialog
      open={preview}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          height: "auto",
          padding: "15px",
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={11} className={styles.profileHeading}>
            <b style={{ fontSize: "28px" }}>
              {campaignName} - {contractDetails?.title}
            </b>
          </Grid>
          <Grid item xs={1} className={styles.profileHeading}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <hr />
      {loader ? (
        <Loader />
      ) : (
        <DialogContent>
          <div id="modal-preview_contract-body" style={{ padding: "10px" }}>
            {previewModalType === "accepted" && (
              <Grid item xs={12} className="hide-toolbar-accepted">
                <hr style={{ margin: "0" }} />
                <DraftEditor value={previewModalContent && previewModalContent.content} readOnly={true} />
              </Grid>
            )}
            {previewModalType === "other" && (
              <Grid item xs={12} className="hide-toolbar">
                <hr style={{ margin: "0" }} />
                <DraftEditor value={previewModalContent && previewModalContent.content} readOnly={true} />
              </Grid>
            )}
            <br />
            <Grid container spacing={2}>
              {previewModalType === "accepted" &&
                responses?.map((response) => {
                  const label = contractInputFieldsData[response?.responseId - 1].input;
                  const value = response?.response;
                  return (
                    <Grid item xs={12} sm={6}>
                      <TextField fullWidth inputProps={{ readOnly: true }} value={value} label={label} />
                    </Grid>
                  );
                })}
              {previewModalType === "accepted" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth inputProps={{ readOnly: true }} value={acceptedOn} label="Acceptence Time" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth inputProps={{ readOnly: true }} value={place} label="Place" />
                  </Grid>
                </>
              )}
              {previewModalType === "other" && (
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    readOnly
                    id="checkboxes-tags-demo"
                    options={contractInputFieldsData}
                    getOptionLabel={(option) => option.input}
                    value={autocompleteData}
                    freeSolo
                    disabled={autocompleteData?.length === 0}
                    renderInput={(params) => (
                      <>
                        {autocompleteData?.length > 0 && <TextField {...params} label="Input Fields" />}
                        {autocompleteData?.length === 0 && <TextField {...params} label="Input Fields (None Selected)" />}
                      </>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormControlLabel label="Mandatory KYC" control={<Checkbox checked={kycRequired} />} />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default PreviewContract;
