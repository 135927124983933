import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from "prop-types";

const CardHeader = (props) => {
  const { title, hideToolTip, toolTipText, wrapperSxProps } = props;
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mb: "1rem", ...wrapperSxProps }}>
        <Typography variant='h5' component={"h5"} color={"black"} fontSize={"14px"}>
          {title}
        </Typography>
        {!hideToolTip &&
          <Tooltip title={toolTipText} sx={{ ml: "8px", fontSize: "14px" }}>
            <IconButton>
              <HelpOutlineIcon fontSize='14px' />
            </IconButton>
          </Tooltip>
        }
      </Box>
    </>
  )
}

CardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  hideToolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
  wrapperSxProps: PropTypes.object
};

export default CardHeader