import { Grid } from "@mui/material";
import { InfoToolTip } from "../../../common/toolTip";
export const tableHeaders = [
  {
    id: 1,
    label: "Group Name",
    mappedValue: "Group Name",
    field: "groupname",
    headeralignment: "center",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },

  {
    id: 2,
    label: "Influencers",
    mappedValue: "Influencers",
    field: "influencers",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: "Total Publication",
    mappedValue: "Total Publication",
    field: "totalpost",
    headeralignment: "left",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 4,
    label: "Last Publication",
    mappedValue: "Last Publication",
    field: "lastpost",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          Total Engagement &nbsp;&nbsp;
          <InfoToolTip text="Displays the engagement of the publication over time as per the applied filters if any" />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Engagement",
    field: "totalengagement",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 6,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          Total Reach &nbsp;&nbsp;
          <InfoToolTip text="Displays the total reach of all the influencers over time as per the applied filters if any" />
        </Grid>
      </Grid>
    ),
    mappedValue: "Total Reach",
    field: "totalreach",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 7,
    label: "Status",
    mappedValue: "Status",
    field: "status",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 8,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
];
