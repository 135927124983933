import { Alert, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { storeTrialExpiredPopupState } from "../../redux/features/user/userSlice";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TrialExpiredPopup = ({ trialExpiredPopupWarning }) => {
  const [warning, setWarning] = useState(trialExpiredPopupWarning);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setWarning(false);
    dispatch(storeTrialExpiredPopupState());
  };

  const navigateMe = () => {
    navigate("/app/account/billing");
    dispatch(storeTrialExpiredPopupState());
  };

  return (
    warning && (
      <div>
        <Snackbar open={warning} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={handleClose}
            severity="error"
            iconMapping={{
              info: <InfoOutlinedIcon style={{ color: 'black' }} />,
            }}
          >
            <Typography>
              Your free trial has expired. &nbsp;
              Please{' '}
              <a href="#" style={{ color: "#d53515", textDecoration: 'underline' }} onClick={navigateMe}>
                upgrade
              </a>{' '}
              your subscription to continue using Empowr.
            </Typography>
          </Alert>
        </Snackbar>
      </div>
    )
  );
};

export default TrialExpiredPopup;
