import {
  AccountCircleRounded,
  ArrowDropDownRounded,
  Block,
  ControlPointDuplicate,
  Done,
  Edit,
  EmailOutlined,
  MoreVertOutlined,
  Search,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyledToggleButton, StyledToggleButtonGroup } from "../../components/common/StyledComponents";
import CustomizedTable from "../../components/common/customizedTable";
import RoleMenu from "./RoleMenu";
import styles from "../../styles/internal/Account/AccountUsers.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getRolesData, getUsersData, updateUserRole } from "../../services/account";
import Loader from "../../components/common/loader";
import { useNavigate } from "react-router-dom";
import { notify } from "../../redux/features/system/systemAlert";
import { changeUserStatus } from "../../services/account";
import { resendUserInvite } from "../../services/account";
import { impersonateUser, login, logout } from "../../redux/features/user/userSlice";
import { checkForCredit } from "../../services/credit";
import { UpgradePlanToolTipText } from "../../components/common/toolTip";
import BlockLoginAccess from "../../components/common/BlockLoginAccess";
import { passwordRegex } from "../../utility/regex";
import { getMenuItemsData, getUserDetails, verifyUser } from "../../services/login";
import { defineMenu, definePermissions } from "../../redux/features/menu/menuSlice";

function Users() {
  const [bodyColumns, setBodyColumns] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [type, setType] = useState("active");
  const [userData, setUserData] = useState([]);
  const [active, setActive] = useState(0);
  const [disabled, setDisabled] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [selectedRowForRole, setSelectedRowForRole] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(false);

  const [selectedRowForImpersonation, setSelectedRowForImpersonation] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [openImpersonateDialog, setOpenImpersonateDialog] = useState(false);

  const [disableRow, setDisableRow] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState([]);

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortOrderBy, setSortOrderBy] = useState("createdAt");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const arrayOfOptions = [
    {
      id: 1,
      icon: <EmailOutlined fontSize="small" />,
      label: "Resend Email",
      name: "resend",
      status: ["invited"],
      role: "all",
    },
    {
      id: 2,
      icon: <Block fontSize="small" />,
      label: "Block User",
      name: "block",
      status: ["invited", "active"],
      role: "all",
    },
    {
      id: 3,
      icon: <Done fontSize="small" />,
      label: "Enable User",
      name: "enable",
      status: ["disabled"],
      role: "all",
    },
    {
      id: 4,
      icon: <ControlPointDuplicate fontSize="small" />,
      label: "Impersonate User",
      name: "impersonate",
      status: ["active"],
      role: 0,
    },
  ];

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getRoles = async () => {
    const data = {
      email: user.email,
      role: user.role,
      agencyId: user.agencyId.toString(),
    };
    const result = await getRolesData(data, user, dispatch);
    if (result.status === true) {
      const rolesData = result.response.roles;
      setRolesData(rolesData);
      getUsers(pageNo, rowsPerPage, sortOrder, sortOrderBy, type);
    }
  };

  const handleMember = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null; //setting the opened anchor to null
    if (newAnchorEl[index] === null) {
      newAnchorEl[index] = event.currentTarget;
    } else {
      newAnchorEl[index] = null;
    }
    setAnchorEl(newAnchorEl);
  };

  const handleMenuMember = (i) => {
    return (index) => {
      setSelectedRowForRole(i);
      setSelectedRole(index);
      setOpenRoleDialog(true);
      handleClose();
    };
  };

  const handleChangeRole = async () => {
    const data = {
      email: userData[selectedRowForRole].email,
      role: rolesData[selectedRole].id.toString(),
    };
    const response = await updateUserRole(data, user, dispatch);
    if (response.status === true) {
      if (userData[selectedRowForRole].email === user.email) {
        dispatch(logout());
        return;
      }
      const newUserData = [];
      for (let i = 0; i < userData?.length; i++) {
        const user = { ...userData[i] };
        if (user.email === userData[selectedRowForRole].email) {
          user.role = rolesData[selectedRole].id;
        }
        newUserData.push(user);
      }
      setUserData(newUserData);
      dispatch(
        notify({
          message: "User Role Updated!",
          type: "success",
        })
      );
    }
    setOpenRoleDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenRoleDialog(false);
  };

  const handleImpersonateCloseDialog = () => {
    setOpenImpersonateDialog(false);
  };

  const handleClose = () => {
    const anchorEl = [];
    for (let i = 0; i < 4; i++) {
      anchorEl.push(null);
    }
    setAnchorEl(anchorEl);
  };

  const handleCheckbox = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];

    let count = 0;
    newSelectedRows.forEach((row) => {
      if (row) count++;
    });

    setSelectedRows(newSelectedRows);
    setSelectedCount(count);
  };

  const handleStatus = async (index) => {
    const users = [{ email: userData[index].email }];
    const data = {
      users,
      status: type === "active" ? "disable" : "enable",
    };
    const response = await changeUserStatus(data, user, dispatch);
    if (response.status === true) {
      getUsers(pageNo, rowsPerPage, sortOrder, sortOrderBy, type);
    }
    dispatch(
      notify({
        message: `User has been ${type === "active" ? "blocked" : "enabled"} successfully`,
        type: "success",
      })
    );
  };

  const handleMultipleStatus = async () => {
    const users = [];

    for (let i = 0; i < userData?.length; i++) {
      if (selectedRows[i]) {
        const user = {
          email: userData[i].email,
        };
        users.push(user);
      }
    }

    const data = {
      users,
      status: type === "active" ? "disable" : "enable",
    };

    const response = await changeUserStatus(data, user, dispatch);
    if (response.status === true) {
      setSelectedCount(0);
      getUsers(pageNo, rowsPerPage, sortOrder, sortOrderBy, type);
    }
  };

  const tableHeaders = [
    {
      id: 1,
      label: <span style={{ marginLeft: "100px" }}>Name</span>,
      field: "name",
      headeralignment: "left",
      bodyalignment: "left",
      sortable: true,
      show: true,
    },
    {
      id: 2,
      label: <span style={{ marginLeft: "20px" }}>Status</span>,
      field: "status",
      headeralignment: "left",
      bodyalignment: "left",
      sortable: true,
      show: true,
    },
    {
      id: 3,
      label: "Role",
      field: "role",
      headeralignment: "center",
      bodyalignment: "center",
      sortable: true,
      show: true,
    },
    {
      id: 4,
      label: "",
      field: "actions",
      headeralignment: "center",
      bodyalignment: "right",
      sortable: false,
      show: true,
    },
  ];
  const getUsers = async (page, rowsPerPage, order, orderBy, type = "active", search = "") => {
    const data = {
      role: {
        email: user.email,
        role: user.role,
      },
      agencyId: user.agencyId,
      clientId: user.clientId,
      type: type,
      page: page,
      rows: rowsPerPage,
      sortCol: orderBy,
      sortOrder: order,
      search: search,
    };

    setPageNo(page);
    setRowsPerPage(rowsPerPage);
    setSortOrder(order);
    setSortOrderBy(orderBy);

    setLoaded(false);

    const result = await getUsersData(data, user, dispatch);

    setSearchInProgress(false);

    if (result.status === true) {
      const data = result.response.data;
      setActive(data.active);
      setDisabled(data.disabled);
      if (data.users !== null) {
        setEmpty(false);
        const selectedRows = [];
        const anchorEl = [];
        for (let i = 0; i < data.users?.length; i++) {
          selectedRows.push(false);
          anchorEl.push(null);
          data.users[i].id = data.users[i].email;
        }
        setUserData(data.users);
        setTotalRows(type === "active" ? data.active : data.disabled);
        setSelectedCount(0);
        setSelectedRows(selectedRows);
        setAnchorEl(anchorEl);
        setLoaded(true);
      } else {
        setEmpty(true);
        setLoaded(true);
        if (result.response?.message?.includes("expired")) {
          dispatch(logout());
        }
        dispatch(
          notify({
            message: result.response?.message,
            type: "error",
          })
        );
      }
    }
  };

  const handleToggle = (event, value) => {
    setSelectedCount(0);
    setPageNo(0);
    if (value !== null) {
      setType(value);
    }
  };

  const handleSearch = (event) => {
    if (searchInProgress) {
      return;
    }

    setSearch(event.target.value);

    if (searchTimeout !== null) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setSearchTimeout(null);
        setSearchInProgress(true);
        getUsers(pageNo, rowsPerPage, sortOrder, sortOrderBy, type, event.target.value);
      }, 1000)
    );
  };

  useEffect(() => {
    getRoles();
    checkForCreditValue();
  }, [type]);

  const checkForCreditValue = async () => {
    const data = {
      name: ["Account.Invite New Users"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    const inviteCredits = response?.response.message[data.name[0]];

    if (isNaN(parseInt(inviteCredits))) {
      setDisableRow(false);
    } else if (parseInt(inviteCredits) > 0) {
      setDisableRow(false);
    } else {
      setDisableRow(true);
    }
  };

  const handleResend = (name, email) => {
    return async () => {
      const data = { name, email };

      const response = await resendUserInvite(data, user, dispatch);

      if (response.status === true) {
        dispatch(
          notify({
            message: "Invite Sent!",
            type: "success",
          })
        );
      }
    };
  };

  const invokeAction = (name, row, i) => {
    switch (name) {
      case "resend": {
        const { name, email } = row;
        handleResend(name, email);
        break;
      }
      case "block": {
        handleStatus(i);
        break;
      }
      case "enable": {
        handleStatus(i);
        break;
      }
      case "impersonate": {
        setSelectedRowForImpersonation(row);
        setOpenImpersonateDialog(true);
        break;
      }
    }
    handleMenuClose();
  };

  useEffect(() => {
    if (loaded) {
      const open = anchorEl?.map((val) => val !== null);
      const openMenu = menuAnchorEl?.map((val) => val !== null);
      const createData = (users) => {
        const columns = [];
        users?.forEach((row, i) => {
          let object = {
            id: row.id,
            name: (
              <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
                <Checkbox
                  sx={{
                    color: "#605bff",
                    "&.Mui-checked": {
                      color: "#605bff",
                    },
                  }}
                  disabled={user.email === row.email}
                  checked={selectedRows[i]}
                  onChange={() => handleCheckbox(i)}
                  style={{ marginRight: "10px" }}
                />
                <AccountCircleRounded
                  fontSize="large"
                  style={{
                    transform: "scale(1.4)",
                    marginRight: "15px",
                    color: "gray",
                  }}
                />
                <Grid item>
                  <Grid item xs={12} className={styles.bodycell}>
                    <span>{row.name}</span>
                  </Grid>

                  <Grid item xs={12} className={styles.subbodycell}>
                    <span>{row.email}</span>
                  </Grid>
                </Grid>
              </Grid>
            ),
            status: (
              <div>
                {row.status === "active" && (
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                      backgroundColor: "#26DE81",
                      transform: "translateY(5px)",
                    }}
                  />
                )}
                {row.status === "invited" && (
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                      backgroundColor: "#FED330",
                      transform: "translateY(5px)",
                    }}
                  />
                )}
                {row.status === "disabled" && (
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "inline-block",
                      backgroundColor: "#F08995",
                      transform: "translateY(5px)",
                    }}
                  />
                )}
                &nbsp;&nbsp;
                <span style={{ transform: "translateY(-40px)" }}>
                  {row.status === "active" && "Active"}
                  {row.status === "invited" && "Invited"}
                  {row.status === "disabled" && "Disabled"}
                </span>
              </div>
            ),
            role: (
              <>
                <Button
                  style={{
                    width: "150px",
                    borderRadius: "10px",
                    backgroundColor: "#E7E8EA",
                    color: "#464E5F",
                    height: "50px",
                  }}
                  variant="contained"
                  disabled={row.email === user.email}
                  onClick={(event) => handleMember(event, i)}
                  endIcon={row.email !== user.email && <ArrowDropDownRounded style={{ transform: "scale(1.5)" }} />}
                >
                  {rolesData?.filter((role) => role.id == row.role)[0]?.title}
                </Button>
                <RoleMenu
                  roles={rolesData}
                  anchorEl={anchorEl[i]}
                  open={open[i]}
                  handleClose={handleClose}
                  handleMenuMember={handleMenuMember(i)}
                />
              </>
            ),
            actions: (
              <div>
                {row.email !== user.email && (
                  <>
                    <IconButton onClick={(event) => handleClick(event, i)} size="small" sx={{ ml: 2 }}>
                      <MoreVertOutlined />
                    </IconButton>
                    <Box style={{ borderRadius: "30px" }}>
                      <Menu anchorEl={menuAnchorEl[i]} open={openMenu[i]} onClose={handleMenuClose} className={styles.menuitem}>
                        {arrayOfOptions?.map((value) => (
                          <>
                            {value.role == "all" && value.status.includes(row.status) && (
                              <>
                                <MenuItem key={value.id} onClick={() => invokeAction(value.name, row, i)}>
                                  {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                                </MenuItem>
                              </>
                            )}
                            {value.role != "all" &&
                              value.role == user.agencyId &&
                              row.agencyId != 0 &&
                              value.status.includes(row.status) && (
                                <>
                                  <MenuItem key={value.id} onClick={() => invokeAction(value.name, row, i)}>
                                    {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                                  </MenuItem>
                                </>
                              )}
                          </>
                        ))}
                      </Menu>
                    </Box>
                  </>
                )}
              </div>
            ),
          };
          columns.push(object);
        });
        setBodyColumns(columns);
      };

      createData(userData);
    }
  }, [anchorEl, menuAnchorEl, selectedCount, loaded, userData, openRoleDialog]);

  const handleClick = (event, index) => {
    const newAnchorEl = [...menuAnchorEl];
    newAnchorEl[index] = null; //setting the opened anchor to null
    if (newAnchorEl[index] === null) {
      newAnchorEl[index] = event.currentTarget;
    } else {
      newAnchorEl[index] = null;
    }
    setMenuAnchorEl(newAnchorEl);
  };

  const handleMenuClose = () => {
    const menuAnchorEl = [];
    for (let i = 0; i < 4; i++) {
      menuAnchorEl.push(null);
    }
    setMenuAnchorEl(menuAnchorEl);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!passwordRegex.test(event.target.value)) {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };

  const verifyPassword = async () => {
    const { email } = user;
    const { encryptedRole, email: userEmail, agencyId, clientId, name } = selectedRowForImpersonation;
    const response = await verifyUser(email, password);
    if (response?.response.statusCode === 200) {
      const menuResponse = await getMenuItemsData(
        {
          role: encryptedRole,
          email: userEmail,
          agencyId: agencyId.toString(),
          clientId: clientId.toString(),
        },
        user
      );
      dispatch(
        notify({
          message: `You are now logged in as ${name}`,
          type: "success",
        })
      );

      const getUserAssets = await getUserDetails(userEmail);
      const { response } = getUserAssets;
      const payload = {
        email: userEmail,
        oldEmail: email,
        accessToken: user.accessToken,
        accesssTokenExpiresAt: user.accesssTokenExpiresAt,
        agencyId: response.agencyId,
        clientId: response.clientId,
        name: name,
        group: response.groupName,
        idToken: user.idToken,
        refreshToken: user.refreshToken,
        role: response.role,
        accessKeyId: user.accessKeyId,
        secretKey: user.secretKey,
        sessionToken: user.sessionToken,
        userId: response.id,
        membershipId: response.membershipId,
        isLocked: response.isLocked,
        freeTrialAccessed: response.freeTrialAccessed,
        freeTrialPopupWarning: false,
        isImpersonateSession: true,
        oldRole: user.role,
      };
      dispatch(login(payload));
      dispatch(impersonateUser());
      dispatch(defineMenu(menuResponse.response.AccountMenu.Menu));
      dispatch(definePermissions(menuResponse.response.AccountPermissions));
      window.location.reload();
    } else {
      dispatch(
        notify({
          message: "Invalid credentials. Your session has been terminated",
          type: "error",
        })
      );
      dispatch(logout());
    }
  };
  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          <Box style={{ marginBottom: "25px" }}>
            <Grid container spacing={1}>
              <Grid item sm={7}>
                <StyledToggleButtonGroup color="primary" value={type} exclusive onChange={handleToggle} aria-label="Campaign">
                  <StyledToggleButton value="active" className={styles.toggleButton}>
                    Active Users ({active})
                  </StyledToggleButton>
                  <StyledToggleButton value="disabled" className={styles.toggleButton}>
                    Disabled Users ({disabled})
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                {selectedCount > 0 && (
                  <>
                    <span style={{ marginLeft: "20px", fontWeight: "bold" }}>{selectedCount} users selected</span>
                    <IconButton onClick={handleMultipleStatus}>
                      {type === "active" && (
                        <Tooltip title={"Disable Selected Users"}>
                          <Block fontSize="medium" />
                        </Tooltip>
                      )}
                      {type === "disabled" && (
                        <Tooltip title={"Enable Selected Users"}>
                          <Done fontSize="medium" />
                        </Tooltip>
                      )}
                    </IconButton>
                  </>
                )}
              </Grid>
              <Grid item sm={2.5}>
                <TextField
                  label="Search"
                  value={search}
                  onChange={handleSearch}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              {user.agencyId > 0 && (
                <Tooltip title={disableRow && UpgradePlanToolTipText("credit")}>
                  <Grid item sm={2.5}>
                    <Button
                      variant="contained"
                      className={styles.staticButton}
                      style={{
                        borderRadius: "10px",
                        boxShadow: "none",
                        backgroundColor: "#605bff",
                      }}
                      disabled={disableRow}
                      onClick={() => navigate("/app/account/inviteNewUsers")}
                    >
                      + Invite New User
                    </Button>
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </Box>

          {loaded && (
            <div className={styles.background}>
              {!empty && (
                <>
                  <CustomizedTable
                    tableHeaders={tableHeaders}
                    bodyColumns={bodyColumns}
                    expand={false}
                    selectedRow={{}}
                    sendInformation={({ page, rowsPerPage, order, orderBy }) => getUsers(page, rowsPerPage, order, orderBy, type)}
                    totalRows={totalRows}
                    pageNo={pageNo}
                    rowsPerPageNo={rowsPerPage}
                    sortOrder={sortOrder}
                    sortOrderBy={sortOrderBy}
                  />
                  {openRoleDialog && (
                    <Dialog open={openRoleDialog} onClose={handleCloseDialog}>
                      <DialogTitle>Change Role</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {userData[selectedRowForRole].email !== user.email && (
                            <span>
                              Are you sure you want to change the role of <b>{userData[selectedRowForRole].name}</b>
                              <br />
                              <b>
                                {rolesData[rolesData.findIndex((value) => value.id === userData[selectedRowForRole].role)].title}{" "}
                              </b>
                              to <b>{rolesData[selectedRole].title}</b> ?
                            </span>
                          )}
                          {userData[selectedRowForRole].email === user.email && (
                            <>
                              <span>Are you sure you want to change your role?</span>
                              <br />
                              <br />
                              <span>Note - By changing your role, your account will be logged out</span>
                            </>
                          )}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button onClick={handleChangeRole}>Yes</Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {openImpersonateDialog && (
                    <Dialog open={openImpersonateDialog} onClose={handleImpersonateCloseDialog}>
                      <DialogTitle>Impersonate {selectedRowForImpersonation.name}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {selectedRowForImpersonation.email !== user.email && (
                            <span>Please verify your password to continue</span>
                          )}
                          <TextField
                            id="filled-search"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            variant="filled"
                            color="secondary"
                            sx={{ width: { xs: "300px", lg: "400px" }, borderBottom: "none", height: "75px" }}
                            style={{ height: "75px" }}
                            onChange={handlePassword}
                            InputProps={{
                              endAdornment: (
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              ),
                            }}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleImpersonateCloseDialog}>Cancel</Button>
                        <Button onClick={verifyPassword}>Verify Password</Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </>
              )}
              {empty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px",
                  }}
                >
                  Table is empty
                </div>
              )}
            </div>
          )}
          {!loaded && <Loader />}
        </>
      )}{" "}
      {width <= 768 && <BlockLoginAccess openDialog={true} />}
    </div>
  );
}

export default Users;
