import { ThemeProvider } from "@emotion/react";
import { Alert, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { closeAlert } from "../../../redux/features/system/systemAlert";
import theme from "../../../styles/campaignBriefTheme";
import MainFooter from "../MainFooter";
import ResponsiveAppBar from "../ResponsiveAppBar";

function LandingLayout() {
  const location = useLocation();
  const systemAlert = useSelector((state) => state.systemAlert);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar demo={true} />
      <Outlet />
      <MainFooter />
      {systemAlert !== undefined && systemAlert.message && (
        <Snackbar
          open={systemAlert.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            marginTop: "3%", // Adjust the margin top value as needed
          }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={systemAlert.type}
            variant="filled"
            sx={{
              width: "100%",
              fontSize: "1.2rem", // Adjust the font size as needed
            }}
          >
            {systemAlert.message}
          </Alert>
        </Snackbar>
      )}
    </ThemeProvider>
  );
}

export default LandingLayout;
