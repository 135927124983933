import { VerifiedUser } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Box } from "@mui/material";
import "react-phone-input-2/lib/material.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { sendVerificationOtp, verifyOtp } from "../../services/settings";
import MuiPhoneInput from "./MuiPhoneInput";
import { logout } from "../../redux/features/user/userSlice";
import { validatePhone } from "../../utility/regex";
import { styled } from "@mui/system";

const CustomPhoneInput = styled(Box)({
  '.react-tel-input .special-label': {
    position: 'absolute',
    zIndex: 1,
    top: '2px',
    left: '5px',
    display: 'block',
    background: '#e5e5e6',
    padding: '0 5px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  '.react-tel-input .form-control': {
    backgroundColor: '#E5E5E6',
    borderRadius: '2px 2px 0 0',
    border: '1px solid #e0e0e0',
  },
  '.react-tel-input .flag-dropdown': {
    backgroundColor: '#E5E5E6',
    borderRadius: '2px 2px 0 0',
    border: '1px solid #e0e0e0',
  },
});

function PhoneComponent({ dialCode, setDialCode, phoneNumber, setPhoneNumber, verificationCode, setVerificationCode }) {
  const [dialCodeOriginal, setDialCodeOriginal] = useState();
  const [phoneNumberOriginal, setPhoneNumberOriginal] = useState();
  const [openVerification, setOpenVerification] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [verificationOTP, setVerificationOTP] = useState("");

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    setDialCodeOriginal(dialCode);
    setPhoneNumberOriginal(phoneNumber);
  }, []);

  const handlePhoneChange = (value, country) => {
    let phoneNumber = value.replace(/[^0-9]/g, "");
    phoneNumber = phoneNumber.slice(country.dialCode?.length);
    setPhoneNumber(phoneNumber);
    setDialCode(country.dialCode);
  };

  const sendOtp = async () => {
    const response = await sendVerificationOtp(user.email, dialCode, phoneNumber, user, dispatch);

    if (response.status === true) {
      setOpenVerification(true);
    } else {
      if (response?.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  const verify = async (e) => { // Make the function async
    e.preventDefault(); // Prevent the default form submission behavior
    const response = await verifyOtp(user.email, dialCode, phoneNumber, verificationOTP, user, dispatch);

    if (response.status === true) {
      handleClose();
      dispatch(
        notify({
          message: "Phone Number Verified!",
          type: "success",
        })
      );
      setVerificationCode(verificationOTP);
      setDialCodeOriginal(dialCode);
      setPhoneNumberOriginal(phoneNumber);
    } else {
      setVerificationError(true);
    }
  };

  const handleClose = () => {
    setOpenVerification(false);
    setVerificationError(false);
  };

  return (
    <Grid container spacing={3} style={{ width: "100%" }}>
      <Grid item style={{ width: "52%" }}>
        <CustomPhoneInput sx={{ position: 'relative' }}>
          <MuiPhoneInput
            required
            inputStyle={{
              width: "100%",
              backgroundColor: "#E5E5E6",
              borderRadius: "2px 2px 0 0", 
              border: "1px solid #e0e0e0",
              boxSizing: "border-box",
            }}
            country="us"
            onChange={handlePhoneChange}
            name="phone_number[]"
            variant="filled"
            label="Phone number"
            InputProps={{
              readOnly: false,
              endAdornment: null,
            }}
            value={`+${dialCode}${phoneNumber}`}
          />
        </CustomPhoneInput>
      </Grid>
      <Grid item style={{ display: "flex", alignItems: "center" }}>
        {(dialCode !== dialCodeOriginal || phoneNumber !== phoneNumberOriginal) && !validatePhone(dialCode, phoneNumber) && (
          <span style={{ color: "#B5B5C3" }}>Verify</span>
        )}
        {(dialCode !== dialCodeOriginal || phoneNumber !== phoneNumberOriginal || verificationCode === "") &&
          validatePhone(dialCode, phoneNumber) && (
            <span style={{ color: "#007DFF", cursor: "pointer" }} onClick={sendOtp}>
              Verify
            </span>
          )}
        {dialCode === dialCodeOriginal &&
          phoneNumber === phoneNumberOriginal &&
          verificationCode !== "" &&
          validatePhone(dialCode, phoneNumber) && (
            <>
              <VerifiedUser style={{ color: "#1BC5BD" }} />
              &nbsp;
              <span style={{ color: "#B5B5C3" }}>Verified</span>
            </>
          )}
        <Dialog open={openVerification} onClose={handleClose}>
          <form onSubmit={verify}>
            <DialogTitle>Verify Phone Number</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the verification code sent to the following mobile number:&nbsp;
                {`+${dialCode}${phoneNumber}`}
              </DialogContentText>
              <TextField
                error={verificationError}
                helperText={verificationError ? "Verification failed. Please try again." : ""}
                label="Verification code"
                type="number"
                fullWidth
                required
                variant="standard"
                onInput={(e) => {
                  setVerificationError(false);
                  setVerificationOTP(e.target.value);
                }}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Verify</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default PhoneComponent;
