const CommentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2959 18.8165L18.2351 14.8602C18.1743 14.6385 18.2047 14.3994 18.309 14.1907C19.2351 12.3342 19.5438 10.117 18.9742 7.80402C18.2003 4.67374 15.757 2.16081 12.6354 1.33042C11.7833 1.10869 10.9442 1 10.1312 1C4.29665 1 -0.259655 6.47365 1.31418 12.5647C2.04458 15.3819 5.05314 18.2992 7.88344 18.9861C8.64862 19.173 9.40076 19.26 10.1312 19.26C11.7006 19.26 13.1701 18.8557 14.4614 18.1601C14.6049 18.0818 14.7657 18.0383 14.9222 18.0383C15.0005 18.0383 15.0787 18.047 15.157 18.0688L19.009 19.0991C19.0307 19.1035 19.0525 19.1078 19.0699 19.1078C19.2177 19.1078 19.3351 18.9687 19.2959 18.8165ZM17.0178 15.1863L17.6178 17.4253L15.4831 16.8558C15.3005 16.8079 15.1135 16.7819 14.9222 16.7819C14.557 16.7819 14.1875 16.8775 13.8571 17.0558C12.6963 17.6818 11.4441 17.9992 10.1312 17.9992C9.48336 17.9992 8.82687 17.9166 8.17907 17.7601C5.78354 17.1775 3.14453 14.6037 2.53586 12.2473C1.90111 9.78654 2.40109 7.26057 3.90536 5.31719C5.40964 3.3738 7.6791 2.26081 10.1312 2.26081C10.8529 2.26081 11.5876 2.35646 12.3137 2.5521C14.9961 3.26511 17.0786 5.39544 17.7525 8.10836C18.2264 10.0213 18.0308 11.9299 17.183 13.6298C16.9395 14.1168 16.8787 14.6689 17.0178 15.1863Z"
      fill="black"
      stroke="black"
      strokeWidth="0.7"
    />
  </svg>
);

export default CommentIcon;
