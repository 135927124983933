import config from "../config/main.json";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const accountVerify = async (email) => {
  const body = JSON.stringify({ email });
  const response = await fetch(`${config.HEIMDALL_HOST}/resendInvite`, {
    method: "POST",
    headers,
    body,
  });

  return { status: response.ok, response: await Promise.resolve(response.json()) };
};

export { accountVerify };
