import { Box, Skeleton } from "@mui/material";

const Loader = () => {
  return (
    <>
      <Box>
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
        <Skeleton variant="rectangular" animation="wave" />
        <br />
      </Box>
    </>
  );
};

export default Loader;
