import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/main.json"
import {
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
} from "./LandingConstants";
import Testimonials from "./Testimonials";
import TopBrands from "./TopBrands";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import "../../styles/internal/Landing/Landing.css";

const tamc_1 = `${config.BASE_IMAGE_URL}TAMC_1.svg`;
const tamc_2 = `${config.BASE_IMAGE_URL}TAMC_2.svg`;
const tamc_3 = `${config.BASE_IMAGE_URL}TAMC_3.svg`;
const tamc_4 = `${config.BASE_IMAGE_URL}TAMC_4.svg`;


function BaseTrackAndManageCampaigns() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const allSections = document.querySelectorAll(".section");

    const revealSection = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("section--hidden");
          observer.unobserve(entry.target);
        }
      });
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null, // Use the viewport as the root
      threshold: 0.2, // Adjust as needed
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.headerBox}>
        <Box className={styles.topText}>Powerful ROI measurement</Box>
        <Box className={styles.title} sx={{ fontSize: { xs: "30px !important", md: "40px !important", lg: "60px !important" } }}>
          Don't Settle for Incomplete & Inaccurate Data
        </Box>
        <div className={styles.subTitle}>
          Take guesswork out and Accurately track and measure the success of your creator campaigns!
          <br />
          Take your marketing efforts to the next level!
        </div>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "190px",
              marginInline: "auto",
              fontSize: "20px",
              color: "#FFFFFF",
              backgroundColor: "#464E5F",
              "&:hover": {
                backgroundColor: "#2a2f39",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/pricing")}
          >
            GET STARTED
          </Button>
        </div>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Your entire campaign in one place
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Invite and on-board the right influencers easily. Keep shortlisting influencers and you can invite them to
                  together to the campaign.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={tamc_1} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.leftTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={tamc_2} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  Measure Real Performance in Real Time
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  Forget the hassle of contract management. Use our integrated contracts feature to create various templates, each
                  with different terms or pricing and send them out in bulk. Track acceptance status as well.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                    // paddingLeft: "10vw",
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Comprehensive Campaign Reports
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    // paddingLeft: "10vw",
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Get a consolidated view of reach, engagement, and more across all social media platforms to understand what
                  types of content best resonate with your potential customers. Build and export custom reports to simplify KPI
                  tracking and information sharing within your organization.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={tamc_3} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.leftTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={tamc_4} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  Augment Data
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  There can be cases where you want to tinker with data, add posts and edit KPIs manually.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Testimonials */}
      <Testimonials />
      {/* Top Brands */}
      <TopBrands />
    </div>
  );
}

export default BaseTrackAndManageCampaigns;
