import { Paper } from "@mui/material";

const MyPaper = ({ children, elevation, fitHeight, sx }) => (
  <Paper
    elevation={elevation}
    sx={{
      p: 3,
      borderRadius: 2,
      border: 1,
      borderColor: "#fdfdfd",
      boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
      height: fitHeight ? "100%" : "auto",
      ...sx,
      padding: "1rem",
    }}
  >
    {children}
  </Paper>
);

export default MyPaper;
