import { Cancel, Search } from "@mui/icons-material";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const SearchItem = ({ sendSearchedValue }) => {
  const [search, setSearch] = React.useState("");
  const handleSearch = (event) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
    sendSearchedValue(value);
  };

  React.useEffect(() => {}, [search]);
  const clearInput = () => {
    setSearch("");
    sendSearchedValue("");
  };
  return (
    <Autocomplete
      size="small"
      freeSolo
      disableClearable
      options={[]}
      value={search}
      renderInput={(params) => (
        <TextField
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              minWidth: "7vw",
              marginRight: "5px",
              borderRadius: "10px",
            },
          }}
          {...params}
          label="Search"
          value={search}
          onInput={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {search?.length === 0 ? (
                  <Search htmlColor="#B5B5C3" />
                ) : (
                  <Cancel fontSize="small" htmlColor="#B5B5C3" onClick={clearInput} />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

SearchItem.propTypes = {
  sendSearchedValue: PropTypes.func.isRequired,
};

export default SearchItem;
