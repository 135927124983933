import { Drawer, List, ListItemButton, ListItemIcon, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { array, number, object } from "prop-types";
import { alpha, styled } from "@mui/system";
import config from "../../config/main.json";
import { IconMapping } from "../../components/common/StyledComponents";
import { useSelector } from "react-redux";

const betaLogo = `${config.BASE_IMAGE_URL}EmpowrLogo.svg`

const Logo = styled("img")({});

const Nav = styled("nav")({});

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 80,
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1rem",
}));

const ListItemIconStyle = styled(ListItemIcon)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

function AppNav({ menuItemsData, drawerWidth, permissionsData }) {
  const theme = useTheme();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState(menuItemsData);
  const [drawer, setDrawer] = useState();
  const [first, setFirst] = useState(true);
  const user = useSelector((state) => state.user); //get loggedIn user state
  const navigate = useNavigate();

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "medium",
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    "&:before": { display: "block" },
  };

  const createDrawer = (menuItems) => {
    const drawer = (
      <div style={{ minHeight: "100%" }}>
        <Link
          to="/app/dashboard"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo
            src={betaLogo}
            alt="EMPOWR"
            sx={{
              height: "32px",
              width: "100%",
              margin: {
                xs: "14px 0px",
                md: "16px",
              },
            }}
          />
        </Link>
        <List>
          {menuItems
            ?.filter((item) => item.hidden !== true)
            ?.map((item) => {
              if (item.name === "Divider") {
                return (
                  <div
                    style={{
                      marginTop: "12px",
                      marginBottom: "12px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ width: "80%", display: "inline-block" }}>
                      <hr style={{ color: "#B5B5C3" }} />
                    </span>
                  </div>
                );
              }
              const isActive = location.pathname === item.path || location.pathname.startsWith(item.path);
              const defaultValue = item?.children?.find((o) => o.default === 1);
              const path = IconMapping[item.name]?.default ? defaultValue?.path : item.path;
              return (
                permissionsData[item.name]?.Visible && (
                  <ListItemStyle
                    sx={{ ...(isActive && activeRootStyle) }}
                    disabled={item.name !== "Account" && user.isLocked}
                    onClick={() => navigate(path)}
                  >
                    <ListItemIconStyle
                      sx={{
                        ...(isActive && {
                          color: "primary.main",
                        }),
                        transform: "scale(1.2)",
                      }}
                    >
                      {IconMapping[item.name]?.icon}
                      <div
                        style={{
                          fontSize: "0.70rem",
                          maxWidth: drawerWidth,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {item.name}
                      </div>
                    </ListItemIconStyle>
                  </ListItemStyle>
                )
              );
            })}
        </List>
      </div>
    );

    setDrawer(drawer);
  };

  useEffect(() => {
    if (first) {
      const newMenu = [...menuItemsData];
      setMenuItems(newMenu);
      createDrawer(newMenu);
      setFirst(false);
    } else {
      createDrawer(menuItems);
    }
  }, [location.pathname]);

  return (
    <Nav
      aria-label="sidebar"
      sx={{
        flexShrink: {
          md: 0,
        },
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        variant="permanent"
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Nav>
  );
}

AppNav.propTypes = {
  menuItemsData: array.isRequired,
  drawerWidth: number.isRequired,
  permissionsData: object.isRequired,
};

export default AppNav;
