import { Autocomplete, Box, Checkbox, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const InfluencerDropdown = ({
  influencerList,
  sendSelectedInfluencer,
  parentSelectedInfluencer,
  disabled = false,
}) => {
  const [selectedInfluencer, setSelectedInfluencer] = React.useState(
    parentSelectedInfluencer
  );

  useEffect(() => {
    setSelectedInfluencer(parentSelectedInfluencer);
  }, [parentSelectedInfluencer]);

  const selectInfluencer = (event) => {
    setSelectedInfluencer(event);
    sendSelectedInfluencer(event);
  };

  return (
    <Stack spacing={2}>
      <Autocomplete
        id="multiple-limit-tags"
        disableCloseOnSelect
        multiple
        limitTags={0}
        disableClearable
        onChange={(event, newValue) => {
          selectInfluencer(newValue);
        }}
        value={selectedInfluencer}
        options={influencerList ? influencerList : []}
        getOptionLabel={(option) => option?.Name}
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?.Name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Influencers"
            placeholder="Influencers"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                minWidth: "7vw",
                marginRight: "5px",
                borderRadius: "10px",
              },
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Stack>
  );
};
InfluencerDropdown.propTypes = {
  influencerList: PropTypes.array.isRequired,
  sendSelectedInfluencer: PropTypes.func.isRequired,
  parentSelectedInfluencer: PropTypes.array.isRequired,
};

export default InfluencerDropdown;
