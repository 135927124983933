import {
  Autocomplete,
  Box,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { snapshotHeaderIcons, textColor } from "../../utility/plaformIcons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const TaskDropdown = ({
  taskList,
  sendSelectedTasks,
  parentSelectedTask,
  disabled = false,
}) => {
  const [selectedTask, setSelectedTask] = React.useState(parentSelectedTask);

  const selectTask = (value, event) => {
    const jsonObject = event?.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet)?.map(JSON.parse);
    setSelectedTask(uniqueArray);
    sendSelectedTasks(uniqueArray);
  };

  React.useEffect(() => {
    setSelectedTask(parentSelectedTask);
  }, [parentSelectedTask, selectedTask]);

  const checkIfExists = (option) => {
    const ids = selectedTask?.map((task) => task.id);
    if (ids?.indexOf(option.id) > -1) return true;
    return false;
  };

  return (
    <Stack spacing={2}>
      
      <Autocomplete
        id="multiple-limit-tags"
        disableCloseOnSelect
        multiple
        limitTags={0}
        disableClearable
        options={taskList || []}
        onChange={(event, newValue) => {
          selectTask(event, newValue);
        }}
        value={selectedTask}
        noOptionsText="No tasks available" // Custom message when taskList is empty
        isOptionEqualToValue={(option, value) => option.id === value.id} // Custom equality test
        getOptionLabel={(option) => option.title}
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={checkIfExists(option) || selected}
            />
            <Typography sx={textColor[option.platform]}>
              {snapshotHeaderIcons[option.platform]}
              &nbsp;&nbsp;
            </Typography>
            {option.title}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Tasks"
            placeholder="Tasks"
            sx={{
              "& .MuiOutlinedInput-root": {
                minWidth: "7vw",
                marginRight: "5px",
                borderRadius: "10px",
              },
            }}
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      
    </Stack>
  );
};

TaskDropdown.propTypes = {
  taskList: PropTypes.array.isRequired,
  sendSelectedTasks: PropTypes.func.isRequired,
  parentSelectedTask: PropTypes.array.isRequired,
};

export default TaskDropdown;
