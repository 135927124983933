// To convert our custom keys to their proper string names

const FilterNameEnum = {
  inf_er: "Engagement Rate",
  inf_engagements: "Engagements",
  inf_followers: "Followers",
  inf_reel_plays: "Reel Plays",
  inf_views: "Views",
  aud_lookalikes: "Audience Lookalikes",
};

export default FilterNameEnum;
