import {
  Autocomplete,
  Box,
  Checkbox,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignUrlsFilter } from "../../../../services/campaignAnalytics";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { useLocation } from "react-router-dom";
import {
  PlatformIdMapping,
  snapshotHeaderIcons,
  textColor,
} from "../../../../utility/plaformIcons";

const styles = (theme) => ({
  popper: {
    maxWidth: "fit-content",
  },
});

const CustomPopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom" />;
};

const CampaignLinksDestination = ({
  platforms,
  groups,
  selectedDestinationIds,
  sendSelectedDestinationIds,
  disabled = false,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const [urlIds, setUrlIds] = useState([]);
  const [linkSelectAll, setLinkSelectAll] = useState(true);

  useEffect(() => {
    getAllCampaignURLs(
      platforms?.map((value) => PlatformIdMapping[value]),
      groups?.map((value) => value.id)
    );
  }, [platforms, groups]);

  const getAllCampaignURLs = async (platforms, groups) => {
    const campaignUrls = await getCampaignUrlsFilter(
      +params.get("id"),
      platforms,
      groups,
      user,
      dispatch
    );
    if (campaignUrls.status === true) {
      setUrlIds(campaignUrls.response || []);
      sendSelectedDestinationIds(campaignUrls.response || []);
    } else {
      if (campaignUrls.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const selectUrlIds = (value) => {
    let selectAllPresent = false;
    for (let i = 0; i < value?.length; i++) {
      if (value[i].urlId === -1) {
        selectAllPresent = true;
        break;
      }
    }
    if (selectAllPresent) {
      if (linkSelectAll) {
        setLinkSelectAll(false);
        sendSelectedDestinationIds([]);
      } else {
        const values = urlIds?.filter((val) => val.urlId !== -1);
        sendSelectedDestinationIds(values);
        setLinkSelectAll(true);
      }
      return;
    }
    let selectAll = false;
    if (value?.length === urlIds?.length) {
      selectAll = true;
    }
    sendSelectedDestinationIds(value);
    setLinkSelectAll(selectAll);
  };

  return (
    <Stack spacing={2}>
      <Autocomplete
        id="multiple-limit-tags"
        disableCloseOnSelect
        multiple
        limitTags={0}
        disableClearable
        options={[
          {
            url: "Select All",
            urlText: "Select All",
            urlId: -1,
          },
          ...urlIds,
        ]}
        onChange={(_, newValue) => {
          selectUrlIds(newValue);
        }}
        PopperComponent={CustomPopper}
        value={selectedDestinationIds}
        getOptionLabel={(option) => option.url}
        renderTags={(selected, _, state) =>
          state.popupOpen ? "" : `+${selected?.length}`
        }
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.urlId === -1 && (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={linkSelectAll}
                />
                &nbsp;{option.url}
              </>
            )}
            {option.urlId !== -1 && (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                <Typography sx={textColor[option.platform]}>
                  {snapshotHeaderIcons[option.platform]}
                </Typography>
                &nbsp;
                <span style={{ display: "inline-block" }}>
                  <div>{option.url}</div>
                  <div style={{ fontSize: "12px" }}>{option.listName}</div>
                </span>
              </>
            )}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Destination URLs"
            placeholder="Destination URLs"
            sx={{
              "& .MuiOutlinedInput-root": {
                minWidth: "7vw",
                marginRight: "5px",
                borderRadius: "10px",
              },
            }}
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Stack>
  );
};

CampaignLinksDestination.propTypes = {
  platforms: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  selectedDestinationIds: PropTypes.array.isRequired,
  sendSelectedDestinationIds: PropTypes.func.isRequired,
};

export default CampaignLinksDestination;
