import configs from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const { API_HOST, ORYX_HOSTNAME } = configs;
const getTasksForCampaign = async (cid, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/taskList/cid/${cid}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getMilestonesForCampaign = async (cid, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/milestones/cid/${cid}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

// const getTasksForMilestone = async (milestoneIds, user, dispatch) => {
//   try {
//     const request = {
//       hostname: ORYX_HOSTNAME,
//       method: "POST",
//       url: `${API_HOST}/taskList/milestone`,
//       data: JSON.stringify(milestoneIds),
//     };

//     const signedRequest = createSignedRequest(request, user);
//     const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
//     if (response.status === 403) {
//       dispatch(
//         notify({
//           message: response?.message,
//           type: "error",
//         })
//       );
//       dispatch(logout());
//     }
//     return { status: response.ok, response: await Promise.resolve(response.json()) };
//   } catch (error) {
//     dispatch(
//       notify({
//         message: error.response?.message,
//         type: "error",
//       })
//     );
//     dispatch(logout());
//   }
// };


export {
  getTasksForCampaign,
  getMilestonesForCampaign,
  // getTasksForMilestone,
};
