import React, { useState } from 'react'
import { Avatar, Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material'
import { Fade } from '@mui/material';
import { Close, OpenWith } from '@mui/icons-material';
import styles from "../../../common/ProgressChart/ProgressChart.module.css";

const AvataInfo = (props) => {
  const { imageUrl, handleName, handleUserName, link, isExpanded } = props;
  return (
    <Box display="flex" alignItems="center" mb="12px">
      <Avatar
        alt={handleName}
        src={imageUrl}
        sx={{ width: isExpanded ? 30 : 30, height: isExpanded ? 30 : 30 }}
      />
      <Box ml="8px">
        <a href={link} target="_blank" rel="noreferrer">
          <Typography component="h5" color="#1271CD" fontSize={isExpanded ? "16px" : "14px"}>
            @{handleUserName}
          </Typography>
          <Typography component="h6" color="#7B809A" fontSize={isExpanded ? "14px" : "12px"}>
            {handleName}
          </Typography>
        </a>
      </Box>

    </Box>
  )
}

const AvatarList = (props) => {

  const { data, expandButtonVisible, listTitle } = props;

  const [expandTable, setExpandTable] = useState(false);

  const expandChart = () => {
    setExpandTable(true);
  };

  const handleTableModalClose = () => {
    setExpandTable(false);
  };

  const ExpandButton = () => {
    return (
      <Stack direction="row" justifyContent="end">
        <Button style={{ backgroundColor: "#BDBDBD" }} variant="contained" onClick={expandChart} endIcon={<OpenWith />}>
          Expand
        </Button>
      </Stack>
    );
  };

  const renderListData = (expandFlag) => {
    const topValues = expandFlag ? data : data.slice(0, 5);
    return (
      topValues?.map((item, idx) => (
        <AvataInfo key={`inf-account-${idx}`} {...item} isExpanded={expandFlag} />
      ))
    )
  }

  return (
    <>
      {renderListData()}
      {expandButtonVisible && <ExpandButton />}
      <Modal className={styles.stylesModal} open={expandTable} onClose={handleTableModalClose} closeAfterTransition>
        <Fade in={expandTable}>
          <div className={styles.sylesPaper} style={{ width: "50%", overflowY: "scroll", maxHeight: "500px" }}>
            <div id="modal-expanded_progress" className={styles.stylesModalHeading}>
              <h4>
                <b className={styles.title}>{listTitle.replace("Top ", "")}</b>
              </h4>
              <IconButton onClick={handleTableModalClose}>
                <Close />
              </IconButton>
            </div>
            <hr />
            {renderListData(true)}
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default AvatarList