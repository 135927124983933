import React, { useEffect } from "react";
import BasePricing from "../../components/landing/BasePricing";

function Pricing(props) {
  useEffect(() => {
    setBackground();
  }, []);

  const setBackground = () => {
    document.body.style.background = "white";
  };

  return (
    <div>
      <BasePricing />
    </div>
  );
}

export default Pricing;
