import React from 'react';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Box, Typography } from '@mui/material';

const NoDataFound = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} my={10}>
      <ErrorRoundedIcon sx={{ fontSize: 40, mr: "1rem" }} />
      <Typography variant={"h5"}>No data found for selected filter(s)</Typography>
    </Box>
  )
}

export default NoDataFound
