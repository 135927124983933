import { createSlice } from "@reduxjs/toolkit";

export const systemAlertSlice = createSlice({
  name: "systemAlert",
  initialState: {
    open: false,
    message: "",
    top: "0%",
    type: null,
  },
  reducers: {
    notify: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.top = action.payload.top;
      state.type = action.payload.type;
    },
    closeAlert: (state) => {
      state.open = false;
      state.message = "";
      state.top = "0%";
      state.type = null;
    },
  },
});

export const { notify, closeAlert } = systemAlertSlice.actions;

export default systemAlertSlice.reducer;
