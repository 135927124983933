import { AccountCircleRounded } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/user/userSlice";
import { HeadsetOutlined } from "@mui/icons-material";
import config from "../../config/main.json";
import { stringAvatar } from "../../components/campaign/campaignAnalytics/campaignDrafts/CommonAssets";

const crown = `${config.BASE_IMAGE_URL}crown.svg`;
const fastMail = `${config.BASE_IMAGE_URL}u_fast-mail.svg`


function UserButtons() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const contactSupport = () => {
    const subject = "Query related to empowr";
    const footer = `Regards, %0D%0A ${user.name}`;
    document.location = `mailto:${config.SUPPORT_EMAIL}?subject=${subject}&body=${footer}`;
  };

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyProfile = () => {
    navigate("/app/settings/myProfile");
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        {/* <AccountCircleRounded fontSize="large" /> */}
        <Avatar variant="circle" {...stringAvatar(name)} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ padding: "0px 25px", margin: "12px 0px" }}>
          <Typography variant="subtitle1" color="primary">
            <b>{name}</b>
          </Typography>
          <Typography variant="caption">{email}</Typography>
        </Box>
        <Divider />
        <Box onClick={handleMyProfile}>
          <MenuItem>
            <ListItemIcon>
              <img src={fastMail} alt="img" style={{ height: "24px" }} />
            </ListItemIcon>
            My Profile
          </MenuItem>
        </Box>
        <Box onClick={contactSupport}>
          <MenuItem>
            <ListItemIcon>
              <HeadsetOutlined />
            </ListItemIcon>
            Contact Support
          </MenuItem>
        </Box>
        <Box onClick={handleLogout}>
          <MenuItem>
            <ListItemIcon>
              <img src={crown} alt="img" style={{ height: "24px" }} />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
}

export default UserButtons;
