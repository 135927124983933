import axios from "axios";
import configs from "../config/main.json";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const { UNAUTH_API_HOST, API_HOST, ORYX_HOSTNAME } = configs;

const checkListCountries = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/lists/checkCountries/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAllReportFields = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/report/fields/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getAllReportHistory = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/report/history/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const createPptReport = async (data) => {
  const response = await axios.post(UNAUTH_API_HOST + "/report/create", data);
  return response.data;
};

const downloadPptReport = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/report/download/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, {
      ...requestConfig(request, signedRequest),
      responseType: "blob",
    });
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.blob()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export {
  checkListCountries,
  getAllReportFields,
  getAllReportHistory,
  createPptReport,
  downloadPptReport,
};
