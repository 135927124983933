import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import BillingForm from "./BillingForm";
import ContactForm from "./ContactForm";

const SubscribeForm = ({ handleStepSkipped, planDetails, couponCode }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [email, setEmail] = React.useState("");
  const [otherDetails, setOtherDetails] = React.useState({});
  const goToNextStep = (value) => {
    if (value.go == true) {
      setEmail(value.email);
      setOtherDetails(value);
      handleNext();
    }
  };
  const stepSkipped = (event) => {
    handleStepSkipped({ ...event, email });
  };
  const steps = [
    { title: "Add Contact Details", component: <ContactForm goToNextStep={goToNextStep} planDetails={planDetails} /> },
    { title: planDetails?.planName?.toLowerCase() === "starter" ? "Add Billing Information" : "Add Payment Method", component: <BillingForm couponCode={couponCode} email={email} otherDetails={otherDetails} stepSkipped={stepSkipped} planDetails={planDetails} /> },
  ];
  const isStepOptional = (step) => {
    // if (planDetails?.planName?.toLowerCase() === "starter") {
    //   return false;
    // }
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps?.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label.title} {...stepProps}>
              <StepLabel {...labelProps}>{label.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1 }}>{steps[activeStep].component}</Typography>
      </React.Fragment>
    </Box>
  );
};

export default SubscribeForm;
