import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  root: {
    position: "relative",
    top: -7,
  },
  label: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#464e5f",
    padding: 10,
  },
  counter: {
    fontSize: '0.75rem',
    color: 'gray',
  },
});

const disabledStyle = makeStyles({
  disabledButton: {
    backgroundColor: 'grey !important',
    color: 'white',
    opacity: 0.5,
  },
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(5px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(38px)",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 28,
    height: 28,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#367BF5" : "#aab4be",
    borderRadius: 24 / 2,
  },
}));

export { useStyles, StyledSwitch, disabledStyle };
