import { Grid, Button } from "@mui/material";
import { getFutureDate } from "../../utility/momentManipulations";
import { useLocation } from "react-router-dom";
import config from "../../config/main.json";

const successIcon = `${config.BASE_IMAGE_URL}success_icon.svg`;

const SubscriptionSuccessful = ({ billingDetails, amount, planDetails }) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const planName = params.get("planName");
  const tenure = params.get("tenure")
  const price = tenure === "monthly" ? planDetails?.planPrice : Math.round((planDetails?.planPrice * 6) * 0.8);
  const nextMonth = tenure === "monthly"
    ? new Date(getFutureDate(new Date(), 30, "days"))
    : new Date(getFutureDate(new Date(), 180, "days"));
  
  const trialEndDate = new Date(getFutureDate(new Date(), 7, "days")).toDateString();
  const nextBillingDate = new Date(getFutureDate(new Date(), 30, "days")).toDateString();
  
  const starterAmount = price - amount;
  
  const handleDashboardClick = () => {
    window.open(`${config.BASE_URL}/login`, '_blank');
  };

  return (
    <div style={styles.subscriptionSuccessContainer}>
      <Grid container spacing={3} justifyContent="center" alignItems="center" textAlign="center">
        <Grid item xs={12}>
          <img src={successIcon} alt="success icon" style={styles.successIcon} />
        </Grid>
        <Grid item xs={12}>
          <h1 style={styles.successMessage}>Thank you for subscribing!</h1>
        </Grid>
        <Grid item xs={12}>
          {billingDetails === undefined && planName !== "Free" ? (
            <p style={styles.successDetails}>
              Successfully subscribed to the {planName} Plan (7-Day Trial).<br />
              Your trial expires on {trialEndDate}.<br />
              And your billing period starts after that.<br />
              Make sure to add your billing details before that.
            </p>
          ) : planName === "Free" ? (
            <p style={styles.successDetails}>
              Your plan will expire after the 7-day trial. To continue using our services, please enroll in a paid plan (Starter, Business, or Professional).
            </p>
          ) : (
            <p style={styles.successDetails}>
              Your {planName} monthly plan is now active. Your next billing will be ${starterAmount} on {nextBillingDate}.<br />
              You can downgrade or cancel anytime.
            </p>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDashboardClick}
            className={styles.dashboardButton}
          >
            Take me to Dashboard
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = {
  subscriptionSuccessContainer: {
    backgroundColor: '#f7f9fa',
    borderRadius: '8px',
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  successIcon: {
    height: '90px',
  },
  successMessage: {
    fontSize: '24px',
    color: '#4caf50',
  },
  successSubtitle: {
    fontSize: '20px',
    color: '#333',
  },
  successDetails: {
    fontSize: '16px',
    color: '#666',
    margin: '10px 0',
  },
};

export default SubscriptionSuccessful;
