import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDraftsList } from "../../../../services/publication";
import moment from "moment";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import configs from "../../../../config/main.json";
import { OpenInNew } from "@mui/icons-material";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import CampaignDraftsActionButtons from "./CampaignDraftsActionButtons";
import Loader from "../../../common/loader";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { stringAvatar } from "./CommonAssets";

const CampaignDraftsApproved = ({ allSelectedPlatforms, allSelectedGroups, allSelectedInfluencers, allSelectedTasks }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const search = useLocation().search;
  const params = new URLSearchParams(search);

  const [loading, setLoading] = useState(true);
  const [bodyColumns, setBodyColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortCol, setSortCol] = useState("submitted");
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalRows, setTotalRows] = useState(0);
  const tableHeaders = [
    {
      id: 1,
      label: "Influencer",
      mappedValue: "Influencer",
      field: "name",
      headeralignment: "center",
      bodyalignment: "center",
      sortable: false,
      show: true,
    },
    {
      id: 2,
      label: "Task",
      mappedValue: "Task",
      field: "taskName",
      headeralignment: "center",
      bodyalignment: "center",
      sortable: true,
      show: true,
    },
    {
      id: 3,
      label: "Milestone",
      mappedValue: "Milestone",
      field: "milestone",
      headeralignment: "center",
      bodyalignment: "center",
      sortable: true,
      show: true,
    },
    {
      id: 4,
      label: "Group Name",
      mappedValue: "Group Name",
      field: "group",
      headeralignment: "center",
      bodyalignment: "center",
      sortable: true,
      show: true,
    },
    {
      id: 5,
      label: "Approved On",
      mappedValue: "Approved At",
      field: "approved",
      headeralignment: "center",
      bodyalignment: "center",
      sortable: true,
      show: true,
    },
    {
      id: 6,
      label: " ",
      mappedValue: " ",
      field: "actions",
      headeralignment: "center",
      bodyalignment: "left",
      sortable: false,
      show: true,
    },
  ];

  useEffect(() => {
    initialLoad();
  }, [params.get("id"), allSelectedPlatforms, allSelectedGroups, allSelectedInfluencers]);

  const initialLoad = () => {
    setLoading(true);
    getDrafts(page, rows, sortCol, sortOrder);
  };

  const getDrafts = async (page, rows, sortCol, sortOrder) => {
    const data = {
      cid: +params.get("id"),
      platform: allSelectedPlatforms,
      lid: allSelectedGroups?.map((value) => value.id),
      iid: allSelectedInfluencers?.map((value) => value.Iid),
      status: [2],
      page: page,
      rows: rows,
      sortCol: sortCol,
      sortOrder: sortOrder,
      "taskIds": allSelectedTasks?.map((value) => value.id),
    };

    const drafts = await getDraftsList(data, user, dispatch);
    if (drafts.status === true) {
      const totalRows = drafts.response.total;
      setTotalRows(totalRows);

      if (totalRows > 0) {
        const draftPosts = drafts.response.draftPosts;
        const currentTime = moment(drafts.response.currentTime);

        const bodyColumns = draftPosts?.map((draft) => {
          const createdAt = moment(draft.createdAt);
          const createdDiff = moment.duration(currentTime.diff(createdAt)).asHours();
          const createdDiffMin = moment.duration(currentTime.diff(createdAt)).asMinutes();
          const submitted =
            Math.trunc(createdDiff) < 24
              ? Math.trunc(createdDiff) === 0
                ? `${Math.trunc(createdDiffMin)} minutes ago`
                : `${Math.trunc(createdDiff)} hour${Math.trunc(createdDiff) === 1 ? "" : "s"} ago`
              : createdAt.format("ll");
          const updatedAt = moment(draft.updatedAt);
          const updatedDiff = moment.duration(currentTime.diff(updatedAt)).asHours();
          const updatedDiffMin = moment.duration(currentTime.diff(updatedAt)).asMinutes();

          const statusTime =
            Math.trunc(updatedDiff) < 24
              ? Math.trunc(updatedDiff) === 0
                ? `${Math.trunc(updatedDiffMin)} minutes ago`
                : `${Math.trunc(updatedDiff)} hour${Math.trunc(updatedDiff) === 1 ? "" : "s"} ago`
              : updatedAt.format("ll");
          const object = {
            id: draft.iid,
            name: (
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                {/* <Checkbox
                    sx={{
                      color: "#605bff",
                      "&.Mui-checked": {
                        color: "#605bff",
                      },
                    }}
                    //   checked={selectedRows[i]}
                    //   onChange={() => handleCheckbox(i)}
                    style={{ marginRight: "10px" }}
                  /> */}
                <Avatar
                  variant="circle"
                  src={draft.photoUrl || ""}
                  {...stringAvatar(draft.influencer)}
                  onError={(e) => {
                    e.target.src = configs.PROFILE_IMG;
                  }}
                />
                <Typography sx={{ minWidth: "100px" }}>
                  {draft.influencer}
                  {draft.profileUrl !== "" && draft.profileUrl !== "-" && (
                    <a href={draft.profileUrl} target="_blank" rel="noreferrer" style={{ marginLeft: "10px" }}>
                      <OpenInNew fontSize="small" />
                    </a>
                  )}
                </Typography>
                <Typography sx={textColor[draft.platform]} style={{ marginTop: 10 }}>
                  {snapshotHeaderIcons[draft.platform]}
                </Typography>
              </Stack>
            ),
            taskName: draft.taskName,
            milestone: draft.milestone,
            group: draft.list,
            submitted: submitted,
            approved: statusTime,
            actions: <CampaignDraftsActionButtons iid={draft.iid} refreshDrafts={refreshDrafts} draft={draft} approval={false} />,
          };

          return object;
        });
        setBodyColumns(bodyColumns);
      }
      setLoading(false);
    }
  };

  const refreshDrafts = () => {
    getDrafts(page, rows, sortCol, sortOrder);
  };

  const sendInformation = ({ page, rowsPerPage, order, orderBy }) => {
    setPage(page);
    setRows(rowsPerPage);
    setSortCol(orderBy);
    setSortOrder(order);

    setLoading(true);
    getDrafts(page, rowsPerPage, orderBy, order);
  };

  return loading ? (
    <Loader />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        {totalRows > 0 && (
          <CustomizedTable
            tableHeaders={tableHeaders}
            bodyColumns={bodyColumns}
            totalRows={totalRows}
            pageNo={page}
            rowsPerPageNo={rows}
            sortOrder={sortOrder}
            sortOrderBy={sortCol}
            sendInformation={sendInformation}
          />
        )}
        {totalRows === 0 && (
          <div className={styles.container}>
            <div className={styles.child}>No draft posts found.</div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

CampaignDraftsApproved.propTypes = {
  allSelectedPlatforms: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  allSelectedInfluencers: PropTypes.array.isRequired,
};

export default CampaignDraftsApproved;
