import configs from "../config/main.json";
import { getResponseType } from "../utility/exportData";
import { notify } from "../redux/features/system/systemAlert";
import { logout } from "../redux/features/user/userSlice";
import { createSignedRequest, requestConfig } from "./common";

const { API_HOST, ORYX_HOSTNAME,INFLUENCER_HOST } = configs;
/* 
  get details of all campaigns
*/
const getAllPublicationsForCampaign = async (
  cid,
  platformIds,
  lids,
  page,
  rows,
  SortCol,
  sortOrder,
  isExport,
  filter,
  startDate,
  endDate,
  mimeType,
  campaignName,
  timeZone,
  taskIds,
  user,
  dispatch
) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/post`,
      data: JSON.stringify({
        cid,
        plid: platformIds,
        lids,
        page,
        rows,
        SortCol,
        sortOrder,
        isExport,
        filter,
        startDate,
        endDate,
        mimeType,
        campaignName,
        account: user.agencyId,
        client: user.clientId,
        path: "Campaigns.Fields.Publications",
        timeZone,
        taskIds,
      }),
      responseType: getResponseType(isExport),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    if (!mimeType || mimeType == "") {
      return {
        status: response.ok,
        response: await Promise.resolve(response.json()),
      };
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.text()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

/*
  update a post
*/
const refreshSinglePost = async (
  cid,
  lid,
  iid,
  platform,
  postType,
  postIds,
  postUrl,
  user,
  dispatch
) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/orca/refresh/post`,
      data: JSON.stringify({
        cid,
        lid,
        iid,
        platform,
        postType,
        postIds,
        postUrl,
      }),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const removePublicationFromCampaign = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/posts/remove`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const checkRefreshStatusSource = (cid) => {
  if (!!window.EventSource) {
    return new EventSource(`${API_HOST}/orca/postpendingstatus/cid/${cid}`);
  }
};

const addPublications = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/post/add`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getPublicationCount = async (cid, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/post/created/count/${cid}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getDraftsList = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/posts/drafts`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getDraftDetails = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/post/details/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getDraftHistory = async (id, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/post/history/${id}`,
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );

    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const draftPostAction = async (data, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/posts/review`,
      data: JSON.stringify(data),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.json()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};


const getIdentPublicationsForCampaign = async (
  cid,
  platformIds,
  lids,
  page,
  rows,
  SortCol,
  sortOrder,
  isExport,
  filter,
  startDate,
  endDate,
  mimeType,
  campaignName,
  timeZone,
  user,
  dispatch
) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/identified/post`,
      data: JSON.stringify({
        cid,
        plid: platformIds,
        lids,
        page,
        rows,
        SortCol,
        sortOrder,
        isExport,
        filter,
        startDate,
        endDate,
        mimeType,
        campaignName,
        account: user.agencyId,
        client: user.clientId,
        path: "Campaigns.Fields.Publications",
        timeZone,
      }),
      responseType: getResponseType(isExport),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    if (!mimeType || mimeType == "") {
      return {
        status: response.ok,
        response: await Promise.resolve(response.json()),
      };
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.text()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const postAction = async (
  data,
  user,
  dispatch
) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/identified/action`,
      data: JSON.stringify(data),
    };
    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return {
      status: response.ok,
      response: await Promise.resolve(response.text()),
    };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const updatePost = async (
  listPostMapId,
  lpId,
  updatedPostUrl,
  updatedInfluencerId,
  postLang,
  user,
  dispatch
) => {
  try {
    const payload = {
      listPostMapId: listPostMapId,
      lpId: lpId,
      updatedInfluencerId: updatedInfluencerId,
      postLang: postLang,
    };

    if (updatedPostUrl) {
      payload.updatedPostUrl = updatedPostUrl;
    }

    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/postId/update`,
      data: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const signedRequest = createSignedRequest(request, user);

    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );

    const data = await response.json();

    if (response.ok && data.StatusCode === 1) { 
      dispatch(
        notify({
          message: data.Msg || "Post updated successfully!",
          type: "success",
        })
      );
      return { success: true, message: data.Msg };
    } else {
      dispatch(
        notify({
          message: data.Msg || "Failed to update post.",
          type: "error",
        })
      );
      return { success: false, message: data.Msg };
    }
  } catch (error) {
    dispatch(
      notify({
        message: "An error occurred while updating the post.",
        type: "error",
      })
    );
    return { success: false, message: "An error occurred." };
  }
};


const fetchInfluencers = async (
  organicIds,
  directIds,
  platform,
  user,
  dispatch
) => {
  try {
    const payload = {
      organicIds: organicIds,
      directIds: directIds,
      platform: platform,
    };

    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${INFLUENCER_HOST}/influencer/specific/list`,
      data: JSON.stringify(payload),
    };

    const signedRequest = createSignedRequest(request, user);

    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );

    // Handle 404 and stop further execution for debugging
    if (response.status === 404) {
      return [];
    }

    if (response.status === 403) {
      dispatch(
        notify({
          message: "Unauthorized access. Please log in again.",
          type: "error",
        })
      );
      dispatch(logout());
      return [];
    }

    const data = await response.json();

    if (response.ok) {
      return data; // Assuming data is an array of influencers
    } else {
      dispatch(
        notify({
          message: data.message || "Failed to fetch influencers.",
          type: "error",
        })
      );
      return [];
    }
  } catch (error) {
    dispatch(
      notify({
        message: "An error occurred while fetching influencers.",
        type: "error",
      })
    );
    dispatch(logout());
    return [];
  }
};



const fetchInfluencerIdsByGroupId = async (groupId, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/list/influencers/id/${groupId}`,
    };

    const signedRequest = createSignedRequest(request, user);

    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));

    if (response.status === 403) {
      dispatch(
        notify({
          message: "Unauthorized access. Please log in again.",
          type: "error",
        })
      );
      dispatch(logout());
      return [];
    }

    const data = await response.json();

    if (response.ok) {
      return data; // Assuming data is an array of influencer objects
    } else {
      dispatch(
        notify({
          message: data.message || "Failed to fetch influencer IDs.",
          type: "error",
        })
      );
      return [];
    }
  } catch (error) {
    dispatch(
      notify({
        message: "An error occurred while fetching influencer IDs.",
        type: "error",
      })
    );
    dispatch(logout());
    return [];
  }
};

const getTranslatorInfo = async (user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "GET",
      url: `${API_HOST}/translator/info`,
    };

    const signedRequest = createSignedRequest(request, user);

    const response = await fetch(
      signedRequest.url,
      requestConfig(request, signedRequest)
    );

    if (response.status === 403) {
      dispatch(
        notify({
          message: "Unauthorized access. Please log in again.",
          type: "error",
        })
      );
      dispatch(logout());
      return [];
    }

    const data = await response.json();

    if (response.ok && data?.body?.listing?.all) {
      return data.body.listing.all; // Return the array of languages
    } else {
      dispatch(
        notify({
          message: data.message || "Failed to fetch languages.",
          type: "error",
        })
      );
      return [];
    }
  } catch (error) {
    dispatch(
      notify({
        message: "An error occurred while fetching languages.",
        type: "error",
      })
    );
    return [];
  }
};


export {
  getAllPublicationsForCampaign,
  refreshSinglePost,
  checkRefreshStatusSource,
  addPublications,
  getPublicationCount,
  removePublicationFromCampaign,
  getDraftsList,
  getDraftDetails,
  getDraftHistory,
  draftPostAction,
  getIdentPublicationsForCampaign,
  postAction,
  updatePost,
  fetchInfluencers,
  fetchInfluencerIdsByGroupId,
  getTranslatorInfo
};
