import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import config from "../../config/main.json"
import { useLocation, useNavigate } from "react-router-dom";
import ResponsiveAppBarMenu from "./ResponsiveAppBarMenu";

const empowrBetaLogo = `${config.BASE_IMAGE_URL}EmpowrLogo.svg`

const pages = [
  {
    title: "Platform",
    expandable: true,
    options: [
      { title: "Discover & Analyze", link: "/discoverAndAnalyze" },
      { title: "Contract & On-board", link: "/contractsAndActivate" },
      {
        title: "Track & Manage Campaigns",
        link: "/trackAndManageCampaigns",
      },
      { title: "Monitor UGC", link: "/monitorAndPromoteUGC" },
      { title: "Influencer Attribution", link: "/attribution" },
    ],
  },
  {
    title: "For Creators",
    expandable: false,
    external: true,
    link: "https://www.aifluence.co/",
  },
  {
    title: "Solutions",
    expandable: true,
    options: [
      // { title: "Managed Campaign", link: "" },
      { title: "Submit a Campaign Brief", link: "/campaignBrief" },
    ],
  },
  {
    title: "Managed Service",
    expandable: false,
    external: false,
    link: "/services",
  },
  {
    title: "Pricing",
    expandable: false,
    external: false,
    link: "/pricing",
  },
  {
    title: "Blog",
    expandable: false,
    external: true,
    link: "https://blog.empowr.ai",
  },
];

function ResponsiveAppBar({ demo }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [hideLogin, setHideLogin] = useState(true);

  useEffect(() => {
    const list = ["/login", "/passwordReset", "/forgotPassword"];
    if (list.includes(location.pathname)) {
      setHideLogin(true);
    } else {
      setHideLogin(false);
    }
  }, [location.pathname]);

  return (
    <AppBar position="static" style={{ background: "white", boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ height: "100px" }}>
          <Box
            component={"img"}
            src={empowrBetaLogo}
            alt="Empowr"
            onClick={() => navigate("/")}
            sx={{ height: { xs: "40%", sm: "50%", md: "auto" }, cursor: "pointer" }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} style={{ paddingLeft: "80px" }}>
            {pages?.map((page) => (
              <ResponsiveAppBarMenu key={page.title} page={page} />
            ))}
          </Box>

          <Box sx={{ marginLeft: { xs: "auto", lg: "unset" } }}>
            {demo && (
              <>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "50px",
                    height: { xs: "35px", lg: "50px" },
                    width: "165px",
                    borderColor: "#37ADCA",
                    color: "#37ADCA",
                    "&:hover": {
                      borderColor: "#37ADCA",
                      backgroundColor: "rgba(55, 173, 202, 0.04)",
                    },
                    float: { xs: "right", lg: "left" },
                    ml: { sm: "4px", lg: 0 },
                    display: { xs: "none", md: "block" },
                  }}
                  onClick={() => navigate("/requestDemo")}
                >
                  Request Demo
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "50px",
                    height: { xs: "35px", lg: "50px" },
                    width: "165px",
                    borderColor: "#37ADCA",
                    color: "#37ADCA",
                    "&:hover": {
                      borderColor: "#37ADCA",
                      backgroundColor: "rgba(55, 173, 202, 0.04)",
                    },
                    float: { xs: "right", lg: "none" },
                    ml: { sm: "4px", lg: 0 },
                    display: { xs: "block", md: "none" },
                  }}
                  onClick={() => navigate("/pricing")}
                >
                  See Pricing
                </Button>
              </>
            )}
            {!demo && (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "50px",
                  height: { xs: "35px", lg: "50px" },
                  width: "165px",
                  borderColor: "#37ADCA",
                  color: "#37ADCA",
                  "&:hover": {
                    borderColor: "#37ADCA",
                    backgroundColor: "rgba(55, 173, 202, 0.04)",
                  },
                  float: { xs: "right", lg: "none" },
                }}
                onClick={() => navigate("/campaignBrief")}
              >
                Submit Brief
              </Button>
            )}
            {!hideLogin && (
              <Button
                variant="contained"
                sx={{
                  borderRadius: "56px",
                  height: { xs: "35px", lg: "50px" },
                  width: "165px",
                  marginLeft: "25px",
                  backgroundColor: "#37ADCA",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#3991a7",
                    boxShadow: "none",
                  },
                  float: { xs: "right", sm: "right", lg: "none" },
                  mt: { xs: "8px", sm: 0, md: 0, lg: 0 },
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
