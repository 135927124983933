import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TransferList from "../../../common/TransferList";
import {
  createFBAddonUrls,
  getFBLinksStatus,
} from "../../../../services/campaignAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import Loader from "../../../common/loader";

const CampaignDynamicLinksCreateShortUrl = ({ urlId, lid, open, setOpen, group }) => {
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [showList, setShowList] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    const shortLinksStatus = await getFBLinksStatus(
      urlId,
      lid,
      user,
      dispatch
    );
    if (shortLinksStatus.status === true) {
      divideInfluencers(shortLinksStatus.response);
    } else {
      if (shortLinksStatus.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: shortLinksStatus.response?.message,
          type: "error",
        })
      );
    }
  };

  const divideInfluencers = (influencers) => {
    const left = [];
    const right = [];

    influencers.forEach((influencer) => {
      if (influencer.status) {
        right.push({
          id: influencer.iid,
          label: influencer.influencerName,
          disabled: true,
        });
      } else {
        left.push({
          id: influencer.iid,
          label: influencer.influencerName,
          disabled: false,
        });
      }
    });

    setLeft(left);
    setRight(right);
    setShowList(true);
  };

  const handleCreate = async () => {
    const iid = right?.filter((inf) => !inf.disabled)?.map((inf) => inf.id);
    if (iid?.length === 0) {
      return;
    }

    const response = await createFBAddonUrls(iid, lid, urlId, group, user, dispatch);
    setOpen(false);
    if (response.status !== true) {
      if (response?.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  return showList ? (
    <Box sx={{ bgcolor: "white", p: "16px", borderRadius: "10px" }}>
      <Typography sx={{ mb: "16px", fontWeight: "bold" }}>
        Select influencers from left box
      </Typography>
      <Divider sx={{ my: "16px" }} />
      <TransferList
        left={left}
        setLeft={setLeft}
        right={right}
        setRight={setRight}
      />

      <Stack direction="row" justifyContent="end" spacing={2} mt="16px">
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleCreate}
          disabled={right?.filter((val) => !val.disabled)?.length === 0}
        >
          {`Create (${right?.filter((val) => !val.disabled)?.length})`}
        </Button>
      </Stack>
    </Box>
  ) : (
    <Loader />
  );
};

export default CampaignDynamicLinksCreateShortUrl;
