import { Circle, OpenInNew } from "@mui/icons-material";
import React, { useEffect } from "react";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import configs from "../../../../config/main.json";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import { InfStatusColors, InfStatusNameColors } from "../../../../utility/status";
import { Avatar, Backdrop, Box, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import CampaignInfluencerFilters from "./CampaignInfluencerFilters";
import CampaignInfluencersActionButton from "./CampaignInfluencerActionButtons";
import { getDetailedInfluencerList } from "../../../../services/influencer";
import { useLocation } from "react-router-dom";
import { convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import Loader from "../../../common/loader";
import { tableHeaders } from "./CommonAssets";
import { InfoToolTip } from "../../../common/toolTip";
import { onDownloadHandle, downloadNotification } from "../../../../utility/exportData";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignName } from "../../../../utility/sessionData";
import { notify } from "../../../../redux/features/system/systemAlert";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";
import { getTimeZone } from "../../../../services/common";
import DummyCampaign from "../../../common/DummyCampaign";
import { stringAvatar } from "../campaignDrafts/CommonAssets";
import CampaignProfileModal from "./CampaignProfileModal";

const CampaignInfluencersSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const data = location.state;
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);

  const [selectedPlatforms, setSelectedPlatforms] = React.useState(Object.keys(snapshotHeaderIcons)); //all the platforms are selected by default
  const [selectedGroup, setSelectedGroup] = React.useState([]);
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("lastPosted"); //sorted by default by lastposted date
  const [sortOrder, setSortOrder] = React.useState("desc"); //sorted in descending order by default
  const [totalRows, setTotalRows] = React.useState(10);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [headers, setHeaders] = React.useState(tableHeaders);
  const [lastRefreshed, setLastRefreshed] = React.useState("");
  const [lastPostedStatus, setLastPostedStatus] = React.useState("All");
  const [mimeType, setMimeType] = React.useState("");
  const [campaignName] = React.useState(getCampaignName());
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = React.useState({});
  const [fileName, setFileName] = React.useState("");
  const dispatch = useDispatch();
  const timeZone = getTimeZone();
  const universal = params.get("universal");
  useEffect(() => {
    const groups = [];
    if (data !== null) {
      groups.push(data?.data?.id);
      const groupObject = {
        id: data?.data?.id,
        name: data?.data?.groupName,
        platform: data?.data?.platform,
      };
      handleFilterChange({ selectedGroups: [groupObject], selectedPlatforms: selectedPlatforms });
    }
    getInfluencerDetails(
      +params.get("id"),
      selectedPlatforms,
      groups?.length > 0 ? groups : [-1],
      page,
      rows,
      sortCol,
      sortOrder,
      0,
      false,
      mimeType,
      fileName,
      timeZone,
      lastPostedStatus
    );
  }, [params.get("id")]);

  //getting influencer details
  const getInfluencerDetails = async (
    id,
    selectedPlatforms,
    selectedGroup,
    page,
    rows,
    sortCol,
    sortOrder,
    exportValue,
    isRefresh,
    mimeType,
    fileName,
    timeZone,
    lastPostedStatus
  ) => {
    if (exportValue === 1) {
      downloadNotification(dispatch);
      const fileName = `Influencers_${campaignName}`;
      setFileName(fileName);
      const downloadData = await getDetailedInfluencerList(
        id,
        selectedPlatforms,
        selectedGroup,
        page + 1,
        rows,
        sortCol,
        sortOrder,
        exportValue,
        isRefresh,
        mimeType,
        fileName,
        timeZone,
        lastPostedStatus,
        user,
        dispatch
      );
      if (downloadData.response !== undefined) {
        onDownloadHandle(new Blob([downloadData.response]), fileName + "." + mimeType);
      } else {
        dispatch(
          notify({
            message: "No data to download",
            type: "error",
          })
        );
      }
    } else {
      setLoading(true);
      const influencers = await getDetailedInfluencerList(
        id,
        selectedPlatforms,
        selectedGroup,
        page + 1,
        rows,
        sortCol,
        sortOrder,
        exportValue,
        isRefresh,
        mimeType,
        fileName,
        timeZone,
        lastPostedStatus,
        user,
        dispatch
      );

      setLoading(false);
      if (influencers?.response?.records?.length > 0) {
        const { lastRefreshed, total_records, records, total_influencers } = influencers.response;
        setLastRefreshed(lastRefreshed);
        setTotalRows(total_records);
        setTotalRecords(total_influencers);
        createData(records);
        setDataFound(true);
      }
    }
  };

  //handle change in filter like groups or selected platfrorms
  const handleFilterChange = (event) => {
    let groupIds = [-1];
    if (event.selectedPlatforms != null && event.selectedPlatforms?.length > 0) {
      setSelectedPlatforms(event.selectedPlatforms);
    }
    if (event.selectedGroups?.length > 0) {
      groupIds = event.selectedGroups?.map((group) => group.id);
      setSelectedGroup(event.selectedGroups);
    } else {
      setSelectedGroup([]);
    }
    getInfluencerDetails(
      +params.get("id"),
      event.selectedPlatforms?.length ? event.selectedPlatforms : selectedPlatforms,
      groupIds,
      page,
      rows,
      sortCol,
      sortOrder,
      0,
      false,
      mimeType,
      fileName,
      timeZone,
      event.lastPostedStatus
    );
  };

  const openProfileUrl = (influencer) => {
    if (influencer.profileUrl?.length === 1) {
      window.open(influencer.profileUrl, "_blank");
    } else {
      setSelectedInfluencer(influencer);
      setOpenProfileModal(true);
    }
  };
  //create table data from influencer apis
  const createData = (records) => {
    const columns = [];
    records.forEach((data) => {
      const listNameArray = data.listName.split(",");
      const firstItem = listNameArray[0];
      const remainingItems = listNameArray.slice(1);
      const remainingCount = remainingItems?.length;
      let object = {
        id: data.Iid,
        name: (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} style={{ marginBlock: "auto", marginRight: "1vw" }}>
              <Avatar
                variant="circle"
                src={data.photoUrl || ""}
                {...stringAvatar(data.name)}
                onError={(e) => {
                  e.target.src = configs.PROFILE_IMG;
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <p className={styles.bodycell}>
                <span>{data.name}</span>
                {data.profileUrl !== "" && (
                  // <a href={data.profileUrl} target="_blank" rel="noreferrer" style={{ marginLeft: "10px" }}>
                  //   <OpenInNew fontSize="small" />
                  // </a>
                  <IconButton onClick={() => openProfileUrl(data)}>
                    <OpenInNew fontSize="small" />
                  </IconButton>
                )}
              </p>
            </Grid>
            <Grid item xs={2}>
              <Grid container spacing={3}>
                {data.platform?.map((platform) => (
                  <Grid item xs={1} className={styles.bodycell}>
                    <Typography sx={textColor[platform]} style={{ marginTop: 10 }}>
                      {snapshotHeaderIcons[platform]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ),
        postCount: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={3} className={styles.bodycell}>
              {data.posts}
            </Grid>
          </Grid>
        ),
        followerCount: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={3} className={styles.bodycell}>
              {convertToInternationalNumberSystem(data.followerCount)}
            </Grid>
          </Grid>
        ),
        groupName: (
          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.bodycell}>
              {remainingCount > 0 ? (
                <Tooltip
                  title={
                    <div>
                      {remainingItems?.map((item, index) => (
                        <h6 style={{ color: "white" }} key={index}>
                          {item}
                        </h6>
                      ))}
                    </div>
                  }
                >
                  <span>
                    {firstItem}
                    {remainingCount > 0 && ` +${remainingCount}`}
                  </span>
                </Tooltip>
              ) : (
                <span>{firstItem}</span>
              )}
            </Grid>
          </Grid>
        ),
        engagementCount: (
          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.bodycell}>
              {data.engagement > 0 && convertToInternationalNumberSystem(data.engagement)}
              {data.engagement === 0 && data.lastPosted === "" && data.posts === 0 && <span>-</span>}
              &nbsp;&nbsp;
              {data.engagement > 0 && (
                <InfoToolTip
                  text={
                    <>
                      <b>{"Followers :"}</b> <span>{convertToInternationalNumberSystem(data.followerCount)}</span>
                      <br />
                      <b>{"ER% :"}</b> <span>{convertToInternationalNumberSystem(data.engRate)}%</span>
                    </>
                  }
                />
              )}
            </Grid>
          </Grid>
        ),
        lastPosted: (
          <Grid container>
            <Grid item xs={12}>
              <p className={styles.bodycell}>{data.lastPosted !== "" ? data.lastPosted.split(" at ")[0] : "Not posted yet"}</p>
            </Grid>
            <Grid item xs={12} className={styles.subbodycell}>
              {data.lastPosted.split(" at ")[1]}
            </Grid>
          </Grid>
        ),
        registered: (
          <Grid container spacing={2.5}>
            <Grid item xs={2}>
              {data.type === "direct" ? (
                <Circle htmlColor={InfStatusColors[data.type]} style={{ width: "20px", height: "20px" }} />
              ) : (
                <Circle htmlColor={InfStatusColors[data.type]} style={{ width: "20px", height: "20px" }} />
              )}
            </Grid>
            <Grid item xs={9} className={styles.bodycell} style={{ marginTop: 3 }}>
              {data.type === "direct" ? "NO" : "YES"}
            </Grid>
          </Grid>
        ),
        status: (
          <Grid container spacing={2.5}>
            <Grid item xs={2}>
              <Circle htmlColor={InfStatusNameColors[data.status_name.toLowerCase()]} style={{ width: "20px", height: "20px" }} />
            </Grid>
            <Grid item xs={9} className={styles.bodycell} style={{ marginTop: 3 }}>
              {data.status_name === ""
                ? "Invited"
                : data.status_name.toLowerCase() === "signup / onboarded"
                ? "Onboarded"
                : data.status_name}
            </Grid>
          </Grid>
        ),
        action: (
          <Grid container spacing={1}>
            {/* <Grid item xs={4}>
              <IconButton>
                <EmailOutlined sx={{ color: "#007DFF" }} />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton>
                <LabelTwoTone sx={{ color: "#FFB440" }} />
              </IconButton>
            </Grid> */}
            <Grid item xs={6}>
              <CampaignInfluencersActionButton
                influencerAccount={data}
                campaignId={params.get("id")}
                campaignName={campaignName}
                user={user}
              />
            </Grid>
          </Grid>
        ),
      };
      columns.push(object);
    });

    setBodyColumns(columns);
  };

  const closeProfileModal = () => {
    setOpenProfileModal(false);
  };

  //add or remove table headers
  const changeHeaders = (event) => {
    setHeaders(event);
  };

  const sendRefresh = (event) => {
    let groupIds = [-1];
    if (selectedGroup?.length > 0) {
      groupIds = selectedGroup?.map((group) => group.id);
    }
    getInfluencerDetails(
      +params.get("id"),
      selectedPlatforms,
      groupIds,
      page,
      rows,
      sortCol,
      sortOrder,
      0,
      event,
      mimeType,
      fileName,
      timeZone,
      lastPostedStatus
    );
  };

  //change the page or no of rows
  const sendInformation = (event) => {
    let groupIds = [];
    const { page, lastPostedStatus, rowsPerPage, order, orderBy, mimeType, fileName } = event;
    setPage(page);
    setRows(rowsPerPage);
    setSortOrder(order);
    setSortCol(orderBy);
    setMimeType(mimeType);
    setFileName(fileName);
    if (selectedGroup?.length > 0) {
      groupIds = selectedGroup?.map((group) => group.id);
    }
    getInfluencerDetails(
      +params.get("id"),
      selectedPlatforms,
      groupIds?.length > 0 ? groupIds : [-1],
      page,
      rowsPerPage,
      orderBy,
      order,
      event.export,
      false,
      mimeType,
      fileName,
      timeZone,
      lastPostedStatus
    );
  };

  const start = page * rows + 1;
  const end = Math.min((page + 1) * rows, totalRecords);

  return (
    <Box>
      <DummyCampaign universal={universal} />
      <CampaignInfluencerFilters
        sendInformation={sendInformation}
        pageNo={page}
        rowsPerPageNo={rows}
        sortOrderBy={sortCol}
        sortOrder={sortOrder}
        mimeType={mimeType}
        fileName={fileName}
        changeHeaders={changeHeaders}
        tableHeaders={tableHeaders}
        allSelectedPlatforms={selectedPlatforms}
        handleFilterChange={handleFilterChange}
        allSelectedGroups={selectedGroup}
        lastRefreshed={lastRefreshed}
        sendRefresh={sendRefresh}
      />
      <br />
      {openProfileModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openProfileModal}
          onClose={() => setOpenProfileModal(true)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <CampaignProfileModal closeProfileModal={closeProfileModal} selectedInfluencer={selectedInfluencer} />
        </Modal>
      )}
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <AdjustCampaignDates />
          <div className={styles.background}>
            <p>
              Showing {start} to {end} of {totalRecords} influencers
            </p>
            <CustomizedTable
              tableHeaders={headers}
              bodyColumns={bodyColumns}
              totalRows={totalRows}
              pageNo={page}
              rowsPerPageNo={rows}
              sortOrder={sortOrder}
              sortOrderBy={sortCol}
              mimeType={mimeType}
              fileName={fileName}
              sendInformation={sendInformation}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.child}> No Influencers added on this campaign</div>
          </div>
        </>
      )}
    </Box>
  );
};

export default CampaignInfluencersSnapshot;
