import { MoreHorizOutlined, PermContactCalendarOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { PropTypes } from "prop-types";

const CampaignPublicationsActionButton = ({ influencerAccount }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <PermContactCalendarOutlined htmlColor="#B5B5C3" />,
      label: "View Profile",
      name: "viewprofile",
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "viewprofile": {
        viewProfile();
        break;
      }
      default:
        break;
    }
    handleClose();
  };
  const viewProfile = async () => {
    let url = influencerAccount.profileUrl;
    window.open(url, '_blank');
  };
  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map((value) => (
            <div>
              <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < arrayOfOptions?.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
            </div>
          ))}
        </Menu>
      </Box>
    </>
  );
};

CampaignPublicationsActionButton.propTypes = {
  influencerAccount: PropTypes.object.isRequired,
  setDataRefreshed: PropTypes.func,
};

export default CampaignPublicationsActionButton;
