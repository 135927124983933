import styles from "../../styles/internal/Subscribe.module.css";
import config from "../../config/main.json"
import { Card, Grid } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { reactivateSubscriptionForUnauthAccount } from "../../services/billing";

const successIcon = `${config.BASE_IMAGE_URL}success_icon.svg`;

const SubscriptionReactivated = ({ billingDetails }) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const agencyId = params.get("id");

  useEffect(() => {
    reactivateSubscription();
  }, []);

  const reactivateSubscription = async () => {
    const response = await reactivateSubscriptionForUnauthAccount(agencyId);
  };
  const card = (
    <Grid container style={{ paddingTop: "40px", paddingBottom: "40px" }} spacing={2}>
      <Grid item xs={12}>
        <div className={styles.card2}>
          <Card sx={{ width: 800, minHeight: 300, padding: 5, borderRadius: "8px", backgroundColor: "#F7F9FA" }}>
            <div>
              <Grid container sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                  <img src={successIcon} alt="logo" height="90%" />
                </Grid>
                <Grid item xs={12}>
                  <h1 className={styles.successMessage}>Subscription Reactivated</h1>
                </Grid>

                <Grid item xs={12}>
                  <h1 className={styles.successTitle}>
                    We are pleased to inform you that your subscription has been successfully reactivated! Thank you for choosing
                    our service once again.
                    <br />
                    <br />
                    As a valued subscriber, you now have access to all the benefits and features of your chosen subscription plan.
                    Whether it's exclusive content, premium services, or enhanced functionality, we're thrilled to have you back
                    on board.
                    <br />
                    <br />
                    If you have any questions or need assistance with your subscription, please don't hesitate to reach out to our
                    support team. We're here to help!
                  </h1>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Grid>
    </Grid>
  );

  return <>{card}</>;
};

export default SubscriptionReactivated;
