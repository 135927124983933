import React from 'react';
import { Paper } from '@mui/material';
import PropTypes from "prop-types";

const CustomPaperCard = (props) => {
  const { cardSxProps } = props;
  return (
    <Paper elevation={4} sx={{
      py: "12px",
      px: "24px",
      borderRadius: "12px",
      minHeight: "225px",
      my: "1rem",
      ...cardSxProps
    }}>
      {props.children}
    </Paper>
  )
}

CustomPaperCard.propTypes = {
  cardSxProps: PropTypes.object
}

export default CustomPaperCard