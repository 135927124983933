import { EmailOutlined, LabelTwoTone, MoreHorizOutlined, PermContactCalendarOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import ViewNoteDialog from "./influencerActions/ViewNoteDialog";
import PropTypes from "prop-types";
import SendInviteDialog from "./influencerActions/SendInviteDialog";
import ViewDetailedProfileDialog from "./influencerActions/ViewDetailedProfileDialog";

const CampaignInfluencersActionButton = ({ influencerAccount, campaignId, campaignName, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewNote, setViewNote] = useState(false);
  const [sendInvite, setSendInvite] = useState(false);
  const [viewProfile, setViewProfile] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    // {
    //   id: 1,
    //   icon: <PersonAddOutlined htmlColor="#B5B5C3" />,
    //   label: "Add To New List",
    //   name: "addtolist",
    // },
    // {
    //   id: 2,
    //   icon: <CachedOutlined htmlColor="#B5B5C3" />,
    //   label: "Update Campaign Stats",
    //   name: "updatecampaignstats",
    // },
    {
      id: 3,
      icon: <EmailOutlined htmlColor="#B5B5C3" />,
      label: "Send Invite",
      name: "sendinvite",
      visible: influencerAccount.status_name.toLowerCase() === "signup / onboarded" ? true : false,
    },
    {
      id: 4,
      icon: <LabelTwoTone htmlColor="#B5B5C3" />,
      label: "View Note",
      name: "addviewnote",
    },
    // {
    //   id: 5,
    //   icon: <PhotoOutlined htmlColor="#B5B5C3" />,
    //   label: "Add Post",
    //   name: "addpost",
    // },
    // {
    //   id: 6,
    //   icon: <OpenInNewOutlined htmlColor="#B5B5C3" />,
    //   label: "Send Contract",
    //   name: "sendcontract",
    // },
    {
      id: 7,
      icon: <PermContactCalendarOutlined htmlColor="#B5B5C3" />,
      label: "View Contact Details",
      name: "viewcontactdetails",
      visible: true,
    },
    // {
    //   id: 8,
    //   icon: <BuildOutlined htmlColor="#B5B5C3" />,
    //   label: "Edit Profile Details",
    //   name: "editprofiledetails",
    // },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "sendinvite": {
        setSendInvite(true);
        break;
      }
      case "addviewnote": {
        setViewNote(true);
        break;
      }
      case "viewcontactdetails": {
        setViewProfile(true);
        break;
      }
    }
    handleClose();
  };

  const closeSendInviteDialog = () => {
    setSendInvite(false);
  };
  const closeViewNoteDialog = () => {
    setViewNote(false);
  };
  const closeViewProfileDialog = () => {
    setViewProfile(false);
  };
  return (
    <>
      {viewProfile && (
        <ViewDetailedProfileDialog
          influencerAccount={influencerAccount}
          viewProfile={viewProfile}
          closeViewProfileDialog={closeViewProfileDialog}
          user={user}
        />
      )}
      {sendInvite && (
        <SendInviteDialog
          influencerAccount={influencerAccount}
          campaignId={campaignId}
          campaignName={campaignName}
          sendInviteDialog={sendInvite}
          closeSendInviteDialog={closeSendInviteDialog}
        />
      )}
      {viewNote && (
        <ViewNoteDialog
          influencerAccount={influencerAccount}
          viewNoteDialog={viewNote}
          closeViewNoteDialog={closeViewNoteDialog}
        />
      )}
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map(
            (value) =>
              value.visible && (
                <div>
                  {value.name === "addviewnote" && influencerAccount.notes !== "" && (
                    <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                      {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                    </MenuItem>
                  )}
                  {value.name === "sendinvite" && (
                    <Tooltip
                      title={
                        influencerAccount.email === "" && (
                          <h2 style={{ fontSize: 15, borderRadius: "6px", color: "white" }}>
                            {"Email not available for this influencer"}
                          </h2>
                        )
                      }
                    >
                      <MenuItem
                        style={{
                          opacity: influencerAccount.email === "" ? 0.5 : 1,
                        }}
                        key={value.id}
                        onClick={() => invokeAction(value.name)}
                      >
                        {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  {value.name !== "addviewnote" && value.name !== "sendinvite" && (
                    <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                      {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                    </MenuItem>
                  )}
                  {value.id < arrayOfOptions?.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
                </div>
              )
          )}
        </Menu>
      </Box>
    </>
  );
};

CampaignInfluencersActionButton.propTypes = {
  influencerAccount: PropTypes.object.isRequired,
};

export default CampaignInfluencersActionButton;
