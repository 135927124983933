import { AppBar, Grid, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { array, number } from "prop-types";
import UserButtons from "./UserButtons";
import BreadCrumbs from "./BreadCrumbs";
import Notification from "../../pages/Notification";
import FreeTrialPopup from "../../components/common/freeTrialPopup";
import { useSelector } from "react-redux";
import ImpersonatePopup from "../../components/common/impersonatePopup";
import HelpModal from "./HelpModal";
import { HelpOutline } from "@mui/icons-material";
import { StyledStaticButton } from "../../components/common/StyledComponents";
import TrialExpiredPopup from "../../components/common/trialExpiredPopup";

function AppToolBar({ drawerWidth, menuItemsData }) {
  const user = useSelector((state) => state.user);
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const handleHelpOpen = () => setHelpModalOpen(true);
  const handleHelpClose = () => setHelpModalOpen(false);

  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        height: "64px", // Adjusted height
        ml: { md: `${drawerWidth}px` },
        backgroundColor: "white",
        color: "white",
      }}
    >
      <Toolbar sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography variant="h5" component="div" style={{ color: "black" }}>
              <BreadCrumbs menuItemsData={menuItemsData} />
            </Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={2} alignItems="center">
              {!user.isImpersonateSession && user.freeTrialPopupWarning && (
                <Grid item>
                  <FreeTrialPopup isFreeTrial={true} user={user} />
                </Grid>
              )}
               {!user.isImpersonateSession && !user.freeTrialPopupWarning && user.planStatus == "expired" && (
                <Grid item>
                  <TrialExpiredPopup trialExpiredPopupWarning={true} user={user} />
                </Grid>
              )}
              {user.isImpersonateSession && (
                <Grid item>
                  <ImpersonatePopup impersonateUser={true} user={user} />
                </Grid>
              )}

              <Grid item>
                <Notification />
              </Grid>

              <Grid item>
                <StyledStaticButton
                  onClick={handleHelpOpen}
                  style={{
                    backgroundColor: "#1976d2",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    padding: "6px 12px",
                    textTransform: "none",
                    border: "none",
                  }}
                >
                  <HelpOutline style={{ marginRight: "8px" }} />
                  Help
                </StyledStaticButton>
              </Grid>

              <Grid item>
                <UserButtons />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <HelpModal open={helpModalOpen} handleClose={handleHelpClose} />
    </AppBar>
  );
}

AppToolBar.propTypes = {
  drawerWidth: number.isRequired,
  menuItemsData: array.isRequired,
};

export default AppToolBar;
