import { Avatar } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Button from "@mui/material/Button";
import { number, object } from "prop-types";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import TwitterCommentIcon from "../../../../styles/icons/twittercomment";
import TwitterRetweetIcon from "../../../../styles/icons/twitterretweet";
import TwitterShare from "../../../../styles/icons/twittershare";
import { showIcon, statusMapping, stringAvatar } from "./CommonAssets";
import PostActionButton from "./PostActionButton";
import { renderData } from "./platform-asset-calculator";
import { GetPostText, RenderCardMedia } from "./mediacontent";

const TwitterPost = ({ formData, cid }) => {
  return (
    <div className="d-flex flex-nowrap example-parent">
      <Avatar
        sx={{ width: 55, height: 55 }}
        {...stringAvatar(formData.influencerName)}
      />
      <span className="order-2 p-2 col-example" style={{ width: "100%" }}>
        <div style={{ marginBottom: "10px" }}>
          <div
            className="d-flex justify-content-between"
            style={{ marginBottom: "10px" }}
          >
            <span className="header">{formData.influencerName}</span>
            <div className="d-flex justify-content-end">
              {showIcon(formData.platform)}
              {formData.preview && (
                <PostActionButton id={formData.id} cid={cid} />
              )}
            </div>
          </div>
          {!formData.preview && (
            <p>{renderData(formData.postContent, formData.preview)}</p>
          )}
          {formData.preview && (
            <p>{renderData(formData.postContent, formData.preview)}</p>
          )}
          {formData.preview && formData.postContent?.length === 0 && (
            <p style={{ color: "transparent" }}>No Content</p>
          )}
          <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
            {RenderCardMedia(formData)}
          </Carousel>
          <br />
          {formData.preview && (
            <Button
              style={{
                backgroundColor: statusMapping?.filter(
                  (value) => value.id === formData.currentStatus
                )[0]?.color,
                width: "100%",
                fontSize: "12px",
                borderRadius: "10px",
              }}
              variant="contained"
            >
              {GetPostText(formData)}
            </Button>
          )}
        </div>
        <div className="d-flex example-parent justify-content-between">
          <div className="p-2 flex-fill col-example text-left">
            <TwitterCommentIcon />
          </div>
          <div className="p-2 flex-fill col-example text-left">
            <TwitterRetweetIcon />
          </div>
          <div className="p-2 flex-fill col-example text-center">
            <FavoriteBorderOutlined style={{ color: "#687684" }} />
          </div>
          <div className="p-2 flex-fill col-example text-right">
            <TwitterShare />
          </div>
        </div>
      </span>
    </div>
  );
};

TwitterPost.propTypes = {
  formData: object.isRequired,
  cid: number.isRequired,
};

export default TwitterPost;
