import config from "../config/main.json";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};
const setNewPassword = async (token, password) => {
  const body = JSON.stringify({ token, password });
  const response = await fetch(`${config.HEIMDALL_HOST}/setPassword`, {
    method: "POST",
    headers,
    body,
  });
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};

const verifyWorkEmail = async (token, email) => {
  const body = JSON.stringify({ token, email });
  const response = await fetch(`${config.HEIMDALL_HOST}/verifyEmail`, {
    method: "POST",
    headers,
    body,
  });
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};

const resetNewPassword = async (token, password) => {
  const body = JSON.stringify({ token, password });

  const response = await fetch(`${config.HEIMDALL_HOST}/resetPassword`, {
    method: "POST",
    headers,
    body,
  });
  return { status: response.ok, response: await Promise.resolve(response.json()) };
};

export { setNewPassword, resetNewPassword, verifyWorkEmail };
