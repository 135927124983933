import { Download, EmailOutlined, LabelTwoTone, MoreHorizOutlined, Notifications, Preview } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import PropTypes from "prop-types";
import PreviewContract from "./contractActions/PreviewContract";
import { downloadContractForAPI, resendContract, sendContractReminder } from "../../../../services/contracts";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import FileSaver from "file-saver";

const CampaignContractsActionButton = ({ contractDetails, campaignId, campaignName }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [preview, setPreview] = useState(false);
  const [status, setStatus] = React.useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (contractDetails.accepted) {
      setStatus("accepted");
    } else if (!contractDetails.accepted && contractDetails.timeDiff > 0) {
      setStatus("pending");
    } else {
      setStatus("expired");
    }
  }, []);

  const arrayOfOptions = [
    {
      id: 1,
      icon: <Preview htmlColor="#B5B5C3" />,
      label: "Preview Contract",
      name: "preview",
      status: ["accepted", "expired", "pending"],
    },
    {
      id: 2,
      icon: <EmailOutlined htmlColor="#B5B5C3" />,
      label: "Resend Email",
      name: "resendEmail",
      status: ["pending"],
    },
    {
      id: 3,
      icon: <Download htmlColor="#B5B5C3" />,
      label: "Download Contract",
      name: "downloadcontract",
      status: ["accepted"],
    },
    {
      id: 4,
      icon: <Notifications htmlColor="#B5B5C3" />,
      label: "Send Reminder",
      name: "sendreminder",
      status: ["pending"],
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "preview": {
        setPreview(true);
        break;
      }
      case "resendEmail": {
        resendEmail();
        break;
      }
      case "downloadcontract": {
        downloadContract();
        break;
      }
      case "sendreminder": {
        sendReminder();
        break;
      }
    }
    handleClose();
  };

  const resendEmail = async () => {
    dispatch(
      notify({
        message: `Sending Contract to ${contractDetails.infName} on ${contractDetails.email}`,
        type: "info",
      })
    );
    const response = await resendContract(contractDetails.contractId, user, dispatch);
    if (response?.response.status === "success") {
      dispatch(
        notify({
          message: `Contract has been resend to ${contractDetails.infName} on ${contractDetails.email} successfully`,
          type: "success",
        })
      );
    }
  };

  const downloadContract = async () => {
    dispatch(
      notify({
        message: `Downloading Contract for ${contractDetails.infName}`,
        type: "info",
      })
    );
    const data = await downloadContractForAPI(contractDetails.contractId, user, dispatch);
    await FileSaver(data.response, `${campaignName}-${contractDetails.contractId}.pdf`);
    dispatch(
      notify({
        message: `${campaignName}-${contractDetails.contractId}.pdf Contract downloaded successfully`,
        type: "success",
      })
    );
  };

  const sendReminder = async () => {
    dispatch(
      notify({
        message: `Sending Contract reminder to ${contractDetails.infName} on ${contractDetails.email}`,
        type: "info",
      })
    );
    const response = await sendContractReminder(contractDetails.contractId, user, dispatch);
    if (response?.response.status === "success") {
      dispatch(
        notify({
          message: `Contract has been resend to ${contractDetails.infName} on ${contractDetails.email} successfully`,
          type: "success",
        })
      );
    }
  }
  const closePreviewContractDialog = () => {
    setPreview(false);
  };

  return (
    <>
      {preview && (
        <PreviewContract
          contractDetails={contractDetails}
          campaignId={campaignId}
          campaignName={campaignName}
          preview={preview}
          closePreviewContractDialog={closePreviewContractDialog}
        />
      )}
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map((value) => (
            <div>
              {value.status.includes(status) && (
                <>
                  <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                    {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                  </MenuItem>
                  {value.id < arrayOfOptions?.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
                </>
              )}
            </div>
          ))}
        </Menu>
      </Box>
    </>
  );
};

CampaignContractsActionButton.propTypes = {
  contractDetails: PropTypes.object.isRequired,
};

export default CampaignContractsActionButton;
