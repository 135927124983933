import {
  Cake,
  DonutLarge,
  Groups2,
  HealthAndSafety,
  Language,
  PanTool,
  Person2,
  Person2Outlined,
  PersonPinCircle,
  VolumeUp,
} from "@mui/icons-material";

export const widgetData = [
  {
    icon: <VolumeUp fontSize="medium" />,
    title: "Total Reach",
    field: "reach",
    value: 0,
    boxed: true,
    info: false,
  },
  {
    icon: <Groups2 fontSize="medium" />,
    title: "Reach",
    field: "reach",
    value: 0,
    boxed: true,
    info: false,
  },
  {
    icon: <PanTool fontSize="medium" />,
    title: "Unique Engagers",
    value: 0,
    field: "uniqueengagers",
    boxed: true,
    info: true,
    text: "Unique number of Individuals who have engaged with publication in this campaign so far.",
  },
  {
    icon: <DonutLarge fontSize="medium" />,
    title: "Micro Followers",
    field: "massfollowers",
    value: 0,
    boxed: true,
    info: true,
    text: "Percentage of audience that follow a large number of people",
  },
  {
    icon: <Person2 fontSize="medium" />,
    title: "Total Followers",
    value: 0,
    field: "totalfollowers",
    boxed: true,
    info: true,
    text: "Sum total of followers of Audience that engaged with publications in this campaign.",
  },
  {
    icon: <HealthAndSafety fontSize="medium" />,
    title: "Aud. Credibility",
    value: 0,
    field: "audienceCredibility",
    boxed: true,
    info: true,
    text: "Denotes overall quality of campaign audience. Reflects the percentage of Audience that we can certainly define as real people.",
  },
  {
    icon: <Language fontSize="medium" />,
    title: "Top Country",
    value: "",
    field: "topcountry",
    boxed: false,
    info: false,
  },
  {
    icon: <PersonPinCircle fontSize="medium" />,
    title: "Top City",
    value: "",
    field: "topcity",
    boxed: false,
    info: false,
  },
  {
    icon: <Person2Outlined fontSize="medium" />,
    title: "Male",
    value: "",
    field: "gender",
    boxed: false,
    info: false,
  },
  {
    icon: <Cake fontSize="medium" />,
    title: "Avg. Age",
    value: "24",
    field: "age",
    boxed: false,
    info: false,
  },
];

export const progressColors1 = ["#54D14D", "#EF7E5D", "#35C2FD", "#FF0000", "#77838F"];
export const progressColors2 = ["#4FD1C5", "#548CFC", "#E91F63", "#92E48E", "#77838F"];
