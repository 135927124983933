import React from 'react'
import AnalysisSelector from './AnalysisSelector'
import { Box, IconButton, Typography } from '@mui/material'
import { KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';

const SectionSwitcher = (props) => {
  const { dropdownData, currentSection, setCurrentSection, previousClickHandler, nextClickHandler } = props;
  return (
    <>
      <Box width="100%" display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ height: "100px", padding: "1rem", zIndex: 5 }} >
        <IconButton sx={{ mx: "1rem", color: 'black' }} onClick={previousClickHandler} disabled={dropdownData.find((item) => currentSection.id === item.id).id === 1}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <AnalysisSelector dropdownData={dropdownData} dropdownItemClickHandler={setCurrentSection} selectedItem={currentSection} />
        <IconButton sx={{ mx: "1rem", color: 'black' }} onClick={nextClickHandler} disabled={dropdownData.find((item) => currentSection.id === item.id).id === dropdownData?.length}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
      <Typography textAlign={"center"} fontWeight={"bold"} fontSize={"12px"}>
        Page: {currentSection.id}/{dropdownData?.length}
      </Typography>
    </>
  )
}

export default SectionSwitcher
