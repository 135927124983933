import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import StyledMenu from "../StyledMenu";
import utils from "../../../utility/oryxUtils/utils";

import {
  FormControl,
  Grid,
  MenuItem,
  Tooltip,
  InputLabel,
  Select,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Engagements = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isInfFilter,
}) => {
  const [infFromEngagementsSel, setInfFromEngagementsSel] = useState("");
  const [infToEngagementsSel, setInfToEngagementsSel] = useState("");
  const [toEngagements] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);
  const [fromEngagements] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);
  const [engagementRate] = useState([
    { value: "0.01", name: "≥ 1.0%" },
    { value: "0.02", name: "≥ 2.0%" },
    { value: "0.03", name: "≥ 3.0%" },
    { value: "0.04", name: "≥ 4.0%" },
    { value: "0.05", name: "≥ 5.0%" },
    { value: "0.06", name: "≥ 6.0%" },
    { value: "0.07", name: "≥ 7.0%" },
    { value: "0.08", name: "≥ 8.0%" },
    { value: "0.09", name: "≥ 9.0%" },
    { value: "0.10", name: "≥ 10.0%" },
    { value: "0.11", name: "≥ 11.0%" },
    { value: "0.12", name: "≥ 12.0%" },
    { value: "0.13", name: "≥ 13.0%" },
    { value: "0.14", name: "≥ 14.0%" },
    { value: "0.15", name: "≥ 15.0%" },
    { value: "0.16", name: "≥ 16.0%" },
    { value: "0.17", name: "≥ 17.0%" },
    { value: "0.18", name: "≥ 18.0%" },
    { value: "0.19", name: "≥ 19.0%" },
    { value: "0.20", name: "≥ 20.0%" },
  ]);
  const [infFromEr, setInfFromEr] = useState("");

  const handleFromChange = (event, type) => {
    let newPayload = payload;
    let engagements = event.target.value;
    if (type === "inf_er_from") {
      setInfFromEr(engagements);
    } else {
      if (type === "inf_engagements_from") {
        setInfFromEngagementsSel(engagements);
      } else {
        setInfToEngagementsSel(engagements);
      }
    }
    newPayload[type] = engagements;
    setPayload(newPayload);
    setEng(type, engagements);
  };

  const setEng = (type, engagements) => {
    if (type === "inf_er_from") {
      allFiltersData = utils.addObj(allFiltersData, "inf_er");
      allFiltersData[0]["inf_er"][0] = utils.getValueByKey(
        engagementRate,
        engagements,
        "value",
        "name"
      );
    } else {
      let fromEng = payload["inf_engagements_from"];
      let toEng = payload["inf_engagements_to"];
      allFiltersData = utils.addObj(allFiltersData, "inf_engagements");
      allFiltersData[0]["inf_engagements"][0] = {
        left: utils.getValueByKey(toEngagements, fromEng, "value", "name"),
        right: utils.getValueByKey(toEngagements, toEng, "value", "name"),
      };
    }
    setAllFiltersData(allFiltersData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip
            title={
              isInfFilter
                ? FilterTooltips.engagement.infTooltipMsg
                : FilterTooltips.engagement.audTooltipMsg
            }
          >
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                fontWeight: '400'
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              size="large"
              onClick={handleClick}
              fullWidth
              endIcon={
                open ? (
                  <ArrowDropUp fontSize="large" />
                ) : (
                  <ArrowDropDown fontSize="large" />
                )
              }
            >
              Engagements
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={2} style={{ height: "auto" }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1 }}>Engagements</FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={FilterTooltips.engagement.audTooltipMsg}>
                      <HelpRoundedIcon
                        fontSize="small"
                        style={{ opacity: 0.3 }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        From
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="infFromEngagements"
                        value={
                          typeof allFiltersData[0]["inf_engagements"] ===
                          "undefined"
                            ? infFromEngagementsSel
                            : utils.getValueByKey(
                                toEngagements,
                                allFiltersData[0]["inf_engagements"][0]["left"],
                                "name",
                                "value"
                              )
                        }
                        label="From"
                        onChange={(e) =>
                          handleFromChange(e, "inf_engagements_from")
                        }
                      >
                        {fromEngagements?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        To
                      </InputLabel>
                      <Select
                        value={
                          typeof allFiltersData[0]["inf_engagements"] ===
                          "undefined"
                            ? infToEngagementsSel
                            : utils.getValueByKey(
                                toEngagements,
                                allFiltersData[0]["inf_engagements"][0][
                                  "right"
                                ],
                                "name",
                                "value"
                              )
                        }
                        onChange={(e) =>
                          handleFromChange(e, "inf_engagements_to")
                        }
                        label="To"
                      >
                        {toEngagements?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ height: "auto" }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1 }}>Engagement Rate</FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={FilterTooltips.engagement.infTooltipMsg}>
                      <HelpRoundedIcon
                        fontSize="small"
                        style={{ opacity: 0.3 }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        More than
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="infFromEr"
                        value={
                          typeof allFiltersData[0]["inf_er"] === "undefined"
                            ? infFromEr
                            : utils.getValueByKey(
                                engagementRate,
                                allFiltersData[0]["inf_er"][0],
                                "name",
                                "value"
                              )
                        }
                        label="More than"
                        onChange={(e) => handleFromChange(e, "inf_er_from")}
                      >
                        {engagementRate?.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Engagements.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Engagements;
