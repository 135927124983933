import React from "react";
import configs from "../config/main.json";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
} from "@mui/material";
import { Notifications, Refresh } from "@mui/icons-material";
import styles from "../styles/theme/notification.module.css";
import DOMPurify from "dompurify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getNotificationCount,
  listNotifications,
  markAsRead,
} from "../services/notification";
import { notify } from "../redux/features/system/systemAlert";
import { withStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

const { PLATFORM_NAME } = configs;

const styles2 = (theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
  },
});

const CustomTooltip = withStyles(styles2)(Tooltip);

const Notification = () => {
  const user = useSelector((state) => state.user);
  const [notifications, setNotifications] = React.useState([]);
  const [notificationLoaded, setNotificationLoaded] = React.useState(false);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [notificationEl, setNotificationEl] = React.useState(null);
  const [firstId, setFirstId] = React.useState(0);
  const [lastId, setLastId] = React.useState(10);
  const openNotification = Boolean(notificationEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user.initialized && !notificationLoaded) {
      initNotificationList();
    }
  }, [user.initialized]);

  const initNotificationList = async () => {
    const count = await getNotificationCount(
      PLATFORM_NAME.toLowerCase(),
      user,
      dispatch
    );
    setNotificationCount(count.response.NoOfNotifications);
    // Start long polling to update the notification count every 1 minute
    pollNotificationCount();
  };

  const pollNotificationCount = () => {
    const pollInterval = 10000; // 1 minute in milliseconds

    const pollTimer = setInterval(async () => {
      const count = await getNotificationCount(
        PLATFORM_NAME.toLowerCase(),
        user,
        dispatch
      );
      setNotificationCount(count.response.NoOfNotifications);
    }, pollInterval);

    // To stop the long polling when needed (e.g., when the component unmounts)
    return () => clearInterval(pollTimer);
  };

  const handleNotificationClick = (event) => {
    getNotificationDetails();
    setNotificationEl(notificationEl === null ? event.currentTarget : null);
  };

  const getNotificationDetails = async () => {
    setFirstId(0);
    setLastId(10);
    const influencerNotifications = await listNotifications(
      PLATFORM_NAME.toLowerCase(),
      0,
      firstId,
      lastId,
      user,
      dispatch
    );
    if (influencerNotifications.response !== null) {
      setNotifications(influencerNotifications.response);
      setNotificationLoaded(true);
    }
  };

  const handleNotificationClose = () => {
    setNotificationEl(null);
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const notificationAvatar = (name) => {
    if (typeof name !== "undefined" && name !== "") {
      let initials = "X";
      if (name.includes(" ")) {
        initials = `${name.split(" ")[0][0]}`;
      } else if (name?.length > 1) {
        initials = `${name[0]}`;
      } else {
        initials = `${name}}`;
      }
      initials = initials.toUpperCase();

      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: initials,
      };
    }

    return null;
  };

  const getTimePassed = (from_date, to_date) => {
    from_date = moment(from_date);
    to_date = moment(to_date);
    return to_date.from(from_date);
  };

  const renderData = (rawContent, preview) => {
    const shortenContent = rawContent.substring(0, 45);
    let content = "";
    if (rawContent?.length > 45 && preview) content = `${shortenContent}...`;
    else content = rawContent;
    const clean = DOMPurify.sanitize(content);
    return (
      <p
        style={{ marginBottom: "-10px" }}
        dangerouslySetInnerHTML={{ __html: clean }}
      />
    );
  };

  const renderMoreNotifications = async (event) => {
    event.stopPropagation();
    const influencerNotifications = await listNotifications(
      PLATFORM_NAME.toLowerCase(),
      0,
      firstId,
      lastId,
      user,
      dispatch
    );
    setFirstId(lastId);
    setLastId((prevState) => prevState + 10);
    if (influencerNotifications.response) {
      setNotifications(influencerNotifications.response);
      setNotificationLoaded(true);
    } else {
      dispatch(
        notify({
          message: "No more new notifications.",
          severity: "info",
        })
      );
    }
  };

  const SideActionNotification = (notificationData) => {
    let returnBlock;
    if (
      notificationData.notificationactionid === 1 &&
      notificationData.filepath !== ""
    ) {
      returnBlock = (
        <Grid
          item
          xs={4}
          className={styles.sideAction}
          style={{ color: "#50b153" }}
        >
          <span className={styles.wordWrap}> Download Now </span>
        </Grid>
      );
    }
    return returnBlock;
  };

  const readNotification = async (item) => {
    const response = await markAsRead(item._id, user, dispatch);
    if (item.notificationactionid === 2) {
      navigate(item.navigateto);
    }
  };

  const markAllRead = async () => {
    const response = await markAsRead("all", user, dispatch);
  };
  return (
    user.loggedIn && (
      <>
        <Tooltip title="Notification" placement="bottom-end">
          <IconButton
            onClick={handleNotificationClick}
            size="small"
            sx={{ ml: 2 }}
          >
            <Notifications sx={{ width: 32, height: 32 }} />
            {notificationCount > 0 && (
              <span className={styles.iconButtonBadge}>
                {notificationCount}
              </span>
            )}
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={notificationEl}
          open={openNotification}
          onClose={handleNotificationClose}
          onClick={handleNotificationClick}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuList>
            <div className={styles.dropdownHeader}>
              <div className="d-flex justify-content-between">
                <span className="p-2 col-example text-left">Notifications</span>
                {notificationCount > 0 && (
                  <span className="p-2 col-example text-left">
                    <Button
                      style={{ color: "#747f8b", fontSize: "0.7em" }}
                      onClick={markAllRead}
                    >
                      Mark all as Read
                    </Button>
                  </span>
                )}
              </div>
              <div className={styles.dropdownBody}>
                {notifications?.map((item) => (
                  <div
                    className={styles.notification}
                    style={{ background: item.isread && "white" }}
                  >
                    <CustomTooltip
                      title={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              item.notificationcontent
                            ),
                          }}
                        />
                      }
                      placement="top"
                    >
                      <MenuItem
                        key={item._id}
                        data-my-value={item._id}
                        onClick={() => readNotification(item)}
                      >
                        <Grid container>
                          <Grid item xs={1} sx={{ marginTop: "10px" }}>
                            <Icon>{item.notificationcategoryicon}</Icon>
                          </Grid>
                          <Grid item xs={11}>
                            <Grid container>
                              <Grid
                                item
                                xs={8}
                                className={styles.notificationText}
                              >
                                {renderData(item.notificationcontent, true)}
                              </Grid>
                              <br />
                              <Grid item xs={8}>
                                <Grid container>
                                  <Grid item xs={12} className={styles.time}>
                                    {getTimePassed(new Date(), item.datetime)}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {SideActionNotification(item)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </CustomTooltip>
                  </div>
                ))}
              </div>
              {notifications?.length <= 0 && (
                <h6 style={{ textAlign: "center", marginTop: "10px" }}>
                  No new notifications
                </h6>
              )}
              {notifications?.length > 0 && (
                <div>
                  <Button
                    variant="outlined"
                    sx={{
                      mr: 2,
                    }}
                    style={{ width: "100%", border: "none", color: "black" }}
                    onClick={renderMoreNotifications}
                  >
                    View More
                  </Button>
                </div>
              )}
            </div>
          </MenuList>
        </Menu>
      </>
    )
  );
};

export default Notification;
