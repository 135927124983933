import { VerifiedUser } from "@mui/icons-material";
import { FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { changeName } from "../../redux/features/user/userSlice";
import { getUserDetails, updateCountry, updateName } from "../../services/settings";
import ChangePassword from "./ChangePassword";
import PhoneComponent from "./PhoneComponent";
import { getAllCountries } from "../../services/settings";
import BlockLoginAccess from "../../components/common/BlockLoginAccess";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MyProfile() {
  const user = useSelector((state) => state.user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countries, setCountries] = useState([]);
  const [verificationCode, setVerificationCode] = useState("");
  const [country, setCountry] = useState("");
  const [dialCode, setDialCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [loadPhone, setLoadPhone] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const dispatch = useDispatch();

  const getDetails = async () => {
    const result = await getUserDetails(user.email, user, dispatch);
    if (result.status === true) {
      const { country, dialCode, phoneNumber, verificationCode } = result.response.data;
      setCountry(country);
      setDialCode(dialCode);
      setPhoneNumber(phoneNumber);
      setVerificationCode(verificationCode);
      setLoadPhone(true);
    }
  };

  const getCountries = async () => {
    const result = await getAllCountries(user, dispatch);
    if (result.status === true) {
      setCountries(result.response);
    }
  };

  useEffect(() => {
    const nameSplit = user.name.split(" ");
    setFirstName(nameSplit[0]);
    nameSplit.shift();
    setLastName(nameSplit.join(" "));
    getDetails();
    getCountries();
  }, []);

  const options = [
    {
      title: "Weekly Reports",
      description: "Receive consolidated campaign reports in a weekly email digest.",
    },
    {
      title: "Onboarding Emails",
      description: "Receive a short series of helpful tips as you kickstart your Aifleunce Journey.",
    },
    {
      title: "Workspace Membership Notifications",
      description: "Get notified when new users auto-join workspaces you own or administer.",
    },
    {
      title: "Product Updates",
      description: "We'll send you regular updates with the latest Aifluence news and features.",
    },
  ];

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCountryChange = async (event) => {
    setCountry(event.target.value);
    const response = await updateCountry(event.target.value, user, dispatch);
    if (response.status === true) {
      dispatch(
        notify({
          message: "Information updated successfully!",
          type: "success",
        })
      );
    }
  };

  const saveName = async () => {
    const name = `${firstName} ${lastName}`;
    const response = await updateName(name, user, dispatch);

    if (response.status === true) {
      dispatch(
        notify({
          message: "Information updated successfully!",
          type: "success",
        })
      );
      dispatch(
        changeName({
          name: name,
        })
      );
    }
  };

  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#757575",
              marginBottom: "12px",
            }}
          >
            Your Info
          </Typography>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "#FAFAFB",
              borderRadius: "10px",
              width: "calc(100% - 10px)",
              margin: "auto",
              paddingBottom: "12px",
            }}
          >
            <Grid container item spacing={3} xs={12}>
              <Grid item sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  variant="filled"
                  onChange={handleFirstNameChange}
                  label="First Name"
                  value={firstName}
                  type="text"
                  required
                />
              </Grid>
              <Grid item sm={3} style={{ display: "flex", alignItems: "center" }}></Grid>
              <Grid item sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  variant="filled"
                  onChange={handleLastNameChange}
                  label="Last Name"
                  value={lastName}
                  type="text"
                  required
                />
              </Grid>
              <Grid item sm={3} style={{ display: "flex", alignItems: "center" }}>
                {user.name !== `${firstName} ${lastName}` && (
                  <span onClick={saveName} style={{ color: "#007DFF", cursor: "pointer" }}>
                    Save
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  variant="filled"
                  value={user.email}
                  label="Email"
                  type="email"
                  inputProps={{ readOnly: true }}
                  required
                />
              </Grid>
              <Grid item sm={3} style={{ display: "flex", alignItems: "center" }}>
                <VerifiedUser style={{ color: "#1BC5BD" }} />
                &nbsp;
                <span style={{ color: "#B5B5C3" }}>Verified</span>
              </Grid>
              <Grid item sm={6}>
                {loadPhone && (
                  <PhoneComponent
                    dialCode={dialCode}
                    setDialCode={setDialCode}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  variant="filled"
                  label="Password"
                  value="Password"
                  type="password"
                  required
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item sm={3} style={{ display: "flex", alignItems: "center" }}>
                <span onClick={() => setOpenChangePassword(true)} style={{ color: "#007DFF", cursor: "pointer" }}>
                  Change Password
                </span>
                <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} />
              </Grid>
              <Grid item sm={3}>
                <FormControl variant="filled" style={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-filled-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    MenuProps={MenuProps}
                    onChange={handleCountryChange}
                    value={country}
                  >
                    {countries?.map((country) => (
                      <MenuItem key={country.id} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {false && (
            <>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#757575",
                  marginTop: "25px",
                  marginBottom: "12px",
                }}
              >
                Email Settings
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  backgroundColor: "#FAFAFB",
                  borderRadius: "10px",
                  width: "calc(100% - 10px)",
                  margin: "auto",
                  paddingBottom: "12px",
                }}
              >
                {options?.map((option) => (
                  <React.Fragment key={option.title}>
                    <Grid container item xs={12}>
                      <Grid item sm={10}>
                        <Grid container item xs={12}>
                          <span
                            style={{
                              color: "#464E5F",
                              fontWeight: 500,
                              fontSize: "1.25rem",
                            }}
                          >
                            {option.title}
                          </span>
                        </Grid>
                        <Grid container item xs={12}>
                          <span style={{ color: "#757575", fontSize: "1rem" }}>{option.description}</span>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Switch />
                      </Grid>
                    </Grid>
                    <div style={{ width: "100%", marginTop: "10px" }}>
                      <hr style={{ margin: 0 }} />
                    </div>
                  </React.Fragment>
                ))}
                <Grid item sm={12}>
                  <span style={{ color: "#007DFF", cursor: "pointer" }}>Unsubscribe from All Marketing Emails</span>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {width <= 768 && <BlockLoginAccess openDialog={true} />}
    </div>
  );
}

export default MyProfile;
