import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const IPrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ m: 4 }} className="text-block">
      <h1>AIFLUENCE, INC. – Privacy Notice – Influencers</h1>
      <p>
        Welcome to AIfluence, Inc.’s (along with each of AIfluence, Inc.’s
        Corporate Affiliates (as defined below), collectively, “
        <strong>AIfluence</strong>
        ”, “
        <strong>we</strong>
        ”, “
        <strong>us</strong>
        ” or “
        <strong>our</strong>
        ” in this privacy notice) privacy notice for
        influencers. If you are not an influencer, please refer to this&nbsp;
        <Link
          to="/page/privacyPolicy"
          rel="noopener noreferrer"
        >
          privacy notice
        </Link>
        .
      </p>
      <p>
        As of the Effective Date, AIfluence, Inc.&apos;s Corporate Affiliates include:
      </p>
      <ol>
        <li>
          AIfluence Limited
          <br />
          7th Floor, ABC Towers, ABC Place
          <br />
          Waiyaki Way, Nairobi, Kenya
        </li>
      </ol>

      <p>
        We collate publicly available information about influential individuals (“
        <strong>you</strong>
        ” or “
        <strong>your</strong>
        ” in this privacy notice if
        we have obtained information about you). This pooled information, or
        ‘personal data’, is made available on AIfluence’s market-research database
        via AIfluence’s influencer relationship management platform (the “
        <strong>platform</strong>
        ”) for its users to discover, evaluate and manage
        their engagement with influential people. ‘Personal data’ means any
        iinformation relating to an identified or identifiable natural person, in
        this case – you.
      </p>
      <p>
        AIfluence respects your privacy and is committed to protecting your
        personal data. This privacy notice will inform you as to how we look after
        your personal data when it is used on our market-research database, your
        privacy rights and how the law protects you.
      </p>
      <p>
        When a business customer of AIfluence uses our platform, that customer may
        enter information relating to influencers into its AIfluence account. To
        the extent that information constitutes personal data under the Data
        Protection Act (No. 24 of 2019 of the Laws of Kenya) (
        <strong>DPA</strong>
        ), that information (excluding that certain publicly available influencer
        data for which AIfluence is the controller (as defined in the DPA)) is
        referred to in this privacy notice as “
        <strong>CRM-Stored Influencer Data</strong>
        ”). To the extent we process
        CRM-Stored Influencer Data solely in order to provide the platform to a
        particular customer, under the DPA, to the extent applicable, we will act
        as a processor (as defined in the DPA) on behalf of that customer in
        respect of that CRM-Stored Influencer Data but not as a data controller.
        The applicable business customer will act as a controller in respect of
        that CRM-Stored Influencer Data and is responsible for obtaining all
        necessary consents and providing you with all requisite information as
        required by applicable law.
      </p>

      <h3>Purpose of this privacy notice</h3>
      <p>
        It is important that you read this privacy notice together with any other
        privacy notice or fair processing notice we may provide on specific
        occasions when we are collecting or processing personal data about you so
        that you are fully aware of how and why we are using your personal data.
        This privacy notice supplements the other notices and is not intended to
        supersede them.
      </p>

      <h3>Who we are</h3>
      <p>
        AIfluence is headquartered in the United States of America. Our registered
        office is 651 N Broad St, Suite 206, Middletown, DE, 19709, USA United
        States of America. We have developed and manage our market-research
        database, known as our ‘Influencer Relationship Management Platform’,
        which allows its users to manage, expand, validate and scale their
        networks with influencers. We also have operating subsidiaries outside the
        USA, and may share your data within the group for the purposes of
        fulfilling a legitimate interest as defined by the DPA and as set out in
        this privacy notice. The term “we” applies to all of the AIfluence group
        entities as the context so allows.
      </p>

      <h3>Controller</h3>
      <p>
        AIfluence is responsible for the protection of personal data that it
        collates from publicly available information and adds to its
        market-research database. As such, except in respect of CRM-Stored
        Influence Data (where we act as a data processor as outlined above), we
        are a controller under applicable data protection legislation for personal
        data which we obtain about you (excluding CRM-Stored Influencer Data).
      </p>

      <h3>Further help</h3>
      <p>
        If you have any questions about this privacy notice, including any
        requests to exercise your legal rights referred to in ‘Your legal rights’
        (paragraph 7 below), please contact our compliance team using the
        following email address: privacy@ai-fluence.com].
        <br />
      </p>
      <p>
        Depending on where you are located, you may have a right to make a
        complaint at any time to the applicable supervisory authority which
        regulates the processing of personal data in the country in which you are
        located. We would, however, appreciate the chance to deal with your
        concerns before you approach your supervisory authority, so please contact
        us by emailing privacy@ai-fluence.com in the first instance.
      </p>

      <h2>
        <strong>1. The data we process and how it is obtained</strong>
      </h2>
      <p>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity or any other personal identifiers have been
        removed (anonymous data).
      </p>
      <p>
        We collate, use, store and transfer different kinds of publicly available
        personal data about you which is obtained directly or indirectly via
        secondary sources such as social media networks and other Internet
        sources. This personal data may include the following:
      </p>
      <p>
        a. your name, age and affiliated organization (if applicable);
        <br />
        b. your social media handle;
        <br />
        c. messages which you have shared publicly on social media; and
        <br />
        d. any other information you manifestly make public.
      </p>
      <p>
        We may combine the above referenced personal data with information we
        receive from third parties relating to your social media audience to
        fulfil our contract with you. Such combined personal data, while anonymous
        with respect to your social media followers, is identifiable of you.
      </p>
      <p>
        We are permitted under the DPA to indirectly collect personal data about
        you where:
      </p>

      <ol type="a">
        <li>the data is contained in a public record;</li>
        <li>you have deliberately made the data public;</li>
        <li>
          you have, or in the event you are incapacitated your guardian has,
          consented to the collection from another source; or
        </li>
        <li>
          the collection from another source would not prejudice your interest.
        </li>
      </ol>

      <h2>
        <strong>2. How we use your personal data</strong>
      </h2>
      <p>
        We will only use your personal data to the extent that the law allows us
        to do so. We will use your personal data where it is necessary to pursue
        our contractual interests with you, or any other legitimate interests and
        your interests and where your fundamental rights do not override those
        interests.
      </p>
      <p>
        As part of these legitimate interests, the platform may benefit you as an
        influencer by facilitating your connection with companies (and their
        brands) to enable those companies to expand your presence in the
        influencer marketing ecosystem and to enhance your subject-matter
        expertise. Influencers often reach out to us and request to be included on
        the platform. The platform also affords a mutual benefit to both brands
        and influencers by empowering brands and influencers to build meaningful
        relationships for their shared gain. Additionally, the platform affords a
        greater public benefit by facilitating the growth and development of, and
        insight into, the wider influencer marketing ecosystem.
      </p>

      <h4>
        <strong>Purposes for which we will use your personal data</strong>
      </h4>
      <p>
        We process your personal data for the purposes of allowing users of our
        platform and associated market-research database to discover, evaluate and
        manage their engagement with influential people. In addition to allowing
        users to conduct market research, as noted above, we will use your
        personal data to connect you through our platform with companies (and
        their brands).
      </p>
      <p>
        In particular, we would like you to be aware that the AIfluence platform
        uses algorithms to construct profiles about individual influencers and
        therefore we may use the personal data described in Section 1 of this
        privacy notice so that an evaluation can be made about your behaviour,
        preferences and interests (among other things). Under some Data Protection
        Laws (to the extent applicable), this usage may be considered profiling.
        In particular, these algorithms assess your reach (e.g. size of your
        social media audience), relevance (e.g. the contextual affinity of your
        content to AIfluence&apos;s customers&apos; areas of interest) and resonance (e.g.
        audience engagement with your social media content) in various areas.
        &quot;Profiling&quot; in this case is taken to mean any form of automated processing
        of personal data consisting of the use of personal data to evaluate
        certain personal aspects relating to an individual, in particular to
        analyse or predict aspects concerning that individual&apos;s race, sex,
        pregnancy, marital status, health status, ethnic social origin, skin type,
        age, disability, religion, conscience, belief, culture, dress, language or
        birth; personal preferences, interests, behaviour, location or movements&quot;.
        To the extent AIfluence&apos;s processing constitutes profiling under the DPA
        (to the extent applicable), you have the right to specifically object to
        the processing of your personal data for the purpose of profiling in
        accordance with paragraph 7 below. To learn more, please reach out to
        privacyl@ai-fluence.com.
      </p>
      <p>
        We may also use your personal data where such use is required to fulfil
        our legal or regulatory obligations, and/or where such use is necessary to
        provide the services in connection with our agreement.
      </p>

      <h4>
        <strong>Opting out</strong>
      </h4>
      <p>
        You can ask us or third parties to stop processing your personal data. To
        do this, email&nbsp;
        <a href="mailto:support@empowr.ai">support@empowr.ai</a>
        .
      </p>

      <h2>
        <strong>3. Disclosures of your personal data</strong>
      </h2>
      <p>
        We may share your personal data with the persons and in the contexts set
        out below:
      </p>
      <p>
        a. users of our platform and associated market-research database;
        <br />
        b. service providers who provide us with IT, system administration, and
        other services;
        <br />
        c. professional advisers including lawyers, bankers, auditors and insurers
        who provide consultancy, banking, legal, insurance and accounting
        services;
        <br />
        d. persons to whom we are under a duty to disclose or share your personal
        data in order to comply with any legal obligation, including without
        limitation public authorities who may need to see your personal data to
        meet national security or law enforcement requirements, and regulators and
        other authorities who require reporting of processing activities in
        certain circumstances;
        <br />
        e. third parties to whom we may choose to sell, transfer, or merge parts
        of our business or our assets. Alternatively, we may seek to acquire other
        businesses or merge with them. If a change happens to our business, then
        the new owners may use your personal data in the same way as set out in
        this privacy notice;
        <br />
        f. third parties with whom we may be required to communicate if we
        discontinue our business, or file a petition or have filed against us a
        petition in bankruptcy, reorganization or similar insolvency petition,
        application or proceeding;
        <br />
        g. our Corporate Affiliates; for the purposes of this privacy notice:
        &quot;Corporate Affiliate&quot; means any person or entity which directly or
        indirectly controls, is controlled by or is under common control with
        AIfluence, whether by ownership or otherwise; and “control” means
        possessing, directly or indirectly, the power to direct or cause the
        direction of the management, policies or operations of an entity, whether
        through ownership of fifty percent (50%) or more of the voting securities,
        by contract or otherwise; and
        <br />
        h. third parties with whom we may be required to communicate in order to
        enforce or apply the terms of our agreements; or to protect the rights,
        property, or safety of AIfluence, our customers, or others.
      </p>
      <p>
        We use reasonable efforts to ensure that all third parties respect the
        security of your personal data and to treat it in accordance with the law.
        Apart from users of our market-research database, who will separately be
        required to adhere to privacy laws when dealing with your personal data,
        we do not allow our third-party service providers to use your personal
        data for their own purposes and only permit them to process your personal
        data for specified purposes and in accordance with our documented
        instructions.
      </p>

      <h2>
        <strong>4. International transfers</strong>
      </h2>
      <p>
        Where we use certain service providers in circumstances requiring a
        transfer of your personal data outside your jurisdiction, we ensure a
        similar degree of protection is afforded to it from a technical,
        organisational and legal perspective. We only transfer your Personal Data
        out of your jurisdiction where it is necessary and lawful to do so. If we
        rely on another basis to transfer your Personal Data outside of your
        jurisdiction (such as your consent), we will keep you updated or contact
        you as required.
      </p>
      <p>
        Please contact us by emailing&nbsp;privacy@ai-fluence.com&nbsp;if you want
        further information on the specific mechanism used and the safeguards
        implemented by us when transferring your personal data outside your
        jurisdiction.
      </p>

      <h2>
        <strong>5. Data security</strong>
      </h2>
      <p>
        We have put in place appropriate security measures intended to prevent
        your personal data from being accidentally lost, used or accessed in an
        unauthorised way, altered or disclosed. In addition, we limit access to
        your personal data to those employees, agents, contractors and other third
        parties who have a business need to know. Processors of your personal data
        acting on our documented instructions will only process your personal data
        in accordance with our instructions and are subject to a duty of
        confidentiality.
      </p>

      <h2>
        <strong>6. Data retention</strong>
      </h2>
      <p>
        We will only retain your personal data for as long as necessary to fulfil
        the purposes for which it was obtained. To determine the appropriate
        retention period for personal data, we consider the amount, nature, and
        sensitivity of the personal data, the potential risk of harm from
        unauthorised use or disclosure of your personal data, the purposes for
        which we process your personal data and whether we can achieve those
        purposes through other means, and the applicable legal requirements. Once
        your AIfluence influencer account expires or is deleted, we will either
        delete or anonymise your personal data. We may retain your personal data
        where it is required to fulfil a legal obligation. We may also retain your
        data for analytical/statistical reasons, but will anonymise your data in
        this case.
      </p>

      <h2>
        <strong>7. Your legal rights</strong>
      </h2>
      <p>
        Under certain circumstances, you may have certain rights under data
        protection laws in relation to your personal data, including to:
      </p>
      <p>
        <strong>Be informed</strong>
        of the use to which your personal data is to
        be put, which we have duly notified you in this Privacy Notice.
      </p>
      <p>
        <strong>Request access&nbsp;</strong>
        to your personal data. This enables
        you to receive a copy of the personal data we hold about you and to check
        that we are lawfully processing it.
      </p>
      <p>
        <strong>Request correction&nbsp;</strong>
        of the personal data that we hold
        about you. This enables you to have any incomplete or inaccurate data we
        hold about you corrected, though we may need to verify the accuracy of any
        new data you provide to us.
      </p>
      <p>
        <strong>Request erasure&nbsp;</strong>
        of your personal data. This enables
        you to ask us to delete or remove personal data where there is no good
        reason for us continuing to process it. You also have the right to ask us
        to delete or remove your personal data where you have successfully
        exercised your right to object to processing (see below), where we may
        have processed your personal data unlawfully or where we are required to
        erase your personal data to comply with local law. Note, however, that we
        may not always be able to comply with your request for erasure for
        specific legal reasons which will be notified to you, if applicable, at
        the time of your request.
      </p>
      <p>
        <strong>Object to processing&nbsp;</strong>
        of your personal data where we
        are relying on a legitimate interest (or those of a third party) and there
        is something about your particular situation which makes you want to
        object to processing on this ground as you feel it impacts on your
        fundamental rights and freedoms. You also have the right to object where
        we are processing your personal data for direct marketing purposes.&nbsp;
        <em>
          <strong>
            In particular, as set out above to the extent AIfluence&apos;s processing
            constitutes profiling under applicable Data Protection Laws, you have
            the right to specifically object to the processing of your personal
            data for the purpose of profiling.
          </strong>
        </em>
        &nbsp;In some cases, we may demonstrate that we have compelling legitimate
        grounds to process your information which override your rights and
        freedoms.
      </p>
      <p>
        <strong>Request restriction of processing&nbsp;</strong>
        of your personal
        data. This enables you to ask us to suspend the processing of your
        personal data in the following scenarios: (a) if you want us to establish
        the data&apos;s accuracy; (b) where our use of the data is unlawful but you do
        not want us to erase it; (c) where you need us to hold the data even if we
        no longer require it as you need it to establish, exercise or defend legal
        claims; or (d) you have objected to our use of your data but we need to
        verify whether we have overriding legitimate grounds to use it.
      </p>
      <p>
        If you wish to exercise any of the rights set out above, please make a
        request in the prescribed form under applicable data protection laws. To
        opt-out, you may also send an email to&nbsp;
        <a href="mailto:support@empowr.ai">support@empowr.ai</a>
        . Please
        note that AIfluence reserves the right to refuse any request to exercise
        such rights to the extent permitted by applicable law.
      </p>

      <h4>
        <strong>No fee usually required</strong>
      </h4>
      <p>
        You generally will not have to pay a fee to access your personal data (or
        to exercise any of the other rights). However, we may charge a reasonable
        fee for expenses incurred by us in providing such access. We may refuse to
        comply with your request as permitted by applicable law.
      </p>

      <h4>
        <strong>What we may need from you</strong>
      </h4>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information in
        relation to your request to speed up our response.
      </p>

      <h4>
        <strong>Time limit to respond</strong>
      </h4>
      <p>
        We try to respond to all legitimate requests within one month.
        Occasionally it may take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this case,
        we will notify you and keep you updated.
      </p>

      <h2>
        <strong>8. Changes of purpose</strong>
      </h2>
      <p>
        We will only use your personal data for the purposes for which it was
        obtained, unless we reasonably consider that we need to use it for another
        reason and that reason is compatible with the original purpose. If you
        wish to get an explanation as to how the processing for the new purpose is
        compatible with the original purpose, please contact us by emailing&nbsp;
        privacy@ai-fluence.com.
      </p>
      <p>
        If we need to use your personal data for an unrelated purpose, we will
        update this privacy notice and explain the legal basis which allows us to
        do so. If we do so, the latest version of our privacy notice will always
        be posted on our website.
      </p>
      <p>
        Please note that we may process your personal data without your knowledge
        or consent, in compliance with the above rules as regards safeguarding the
        data, where this is required or permitted by law.
      </p>

      <h2>
        <strong>9. Changes to our privacy notice</strong>
      </h2>
      <p>
        Any changes we make to this privacy notice in the future will be posted to
        our website. Please check back frequently to see any updates or changes to
        this privacy notice.
      </p>

      <h2>
        <strong>10. Privacy Notice for California Residents</strong>
      </h2>
      <p>
        This Privacy Notice shall only apply to you if you are resident in the
        state of California, USA.
      </p>
      <p>
        Notwithstanding any earlier Effective Date applicable to this privacy
        notice generally, this paragraph 10 shall be effective from and after
        January 1, 2020. This paragraph 10 shall apply to you only if you are a
        California resident. This paragraph 10 shall apply only to the extent
        AIfluence is regulated under the CCPA (as defined below) as a business (as
        defined in the CCPA).
      </p>
      <p>
        When a business customer of AIfluence uses our platform, that customer may
        enter information relating to influencers into its AIfluence account. To
        the extent that information constitutes consumer information (as defined
        below) under the CCPA (as defined below), that information (excluding that
        certain publicly available influencer data for which AIfluence is the
        business (as defined in the CCPA)) is referred to in this privacy notice
        as &quot;California CRM-Stored Influencer Data&quot;). To the extent we process
        California CRM-Stored Influencer Data solely in order to provide the
        platform to a particular customer, under the CCPA, to the extent
        applicable, we will act as a service provider (as defined in the CCPA) on
        behalf of that customer in respect of that California CRM-Stored
        Influencer Data; this privacy notice will not apply to the processing of
        that California CRM-Stored Influencer Data and the applicable customer
        will act as the business in respect of that California CRM-Stored
        Influencer Data and is responsible for obtaining all necessary consents
        and providing you with all requisite information as required by applicable
        law.
      </p>
      <p>
        As used in this paragraph 10, “sell” (including all grammatically
        inflected forms thereof) means selling, renting, releasing, disclosing,
        disseminating, making available, transferring, or otherwise communicating
        orally, in writing, or by electronic or other means, consumer information
        (as defined below) to another business or a third party for monetary or
        other valuable consideration.
      </p>
      <p>
        “Selling” does not include (i) disclosing consumer information to a third
        party at your direction, provided the third party does not sell the
        consumer information except in accordance with the California Consumer
        Privacy Act (the “CCPA”), (ii) where you intentionally interact with a
        third party through the platform, provided the third party does not also
        sell the consumer information, (iii) where you have opted out in
        accordance with paragraph 10(g), disclosures to third parties for the
        purposes of alerting such third parties that you have opted out of the
        sale of your consumer information, (iv) using or sharing your consumer
        information with a service provider as necessary to perform business
        purposes, provided that such service provider provides its services on
        AIfluence’s behalf and provided that the service provider does not further
        collect, sell or use the consumer information except as necessary to
        perform the business purpose, or (v) transfers of your consumer
        information to a third party as an asset that is part of a merger,
        acquisition, bankruptcy, or other transaction in which the third party
        assumes control of all or part of AIfluence, provided that information is
        used or shared consistently with the CCPA.
      </p>
      <p>
        (a)&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>Consumer Information Collected:&nbsp;</strong>
        We collect
        information that identifies, relates to, describes, references, is capable
        of being associated with, or could reasonably be linked, directly or
        indirectly, with particular California residents, devices or households (&quot;
        <strong>consumer information</strong>
        &quot;). In particular, we have collected
        the following categories of consumer information from California
        residents, households or devices within the last twelve (12) months:
      </p>
      <figure>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Category</strong>
              </td>
              <td>
                <strong>Examples</strong>
              </td>
              <td>
                <strong>
                  Business or commercial purposes for which we use consumer
                  information
                </strong>
              </td>
            </tr>
            <tr>
              <td>A. Identifiers.</td>
              <td>
                Any identifiers you have manifestly made public, including your
                real name, date of birth, age, alias, postal address, unique
                personal identifier, online identifier, Internet Protocol address,
                email address, account name, social media handle or other similar
                identifiers.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </td>
              <td>
                Any personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)) that you
                have manifestly made public, including your name, physical
                characteristics or description, address, telephone number,
                education, employment, employment history or any medical
                information.Some personal information included in this category
                may overlap with other categories.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>
                C. Protected classification characteristics under California or
                federal law.
              </td>
              <td>
                Any protected classification characteristics under California or
                federal law that you have manifestly made public, including your
                age (if you are 40 years or older), race, color, ancestry,
                national origin, citizenship, religion or creed, marital status,
                medical condition, physical or mental disability, sex (including
                gender, gender identity, gender expression, pregnancy or
                childbirth and related medical conditions), sexual orientation,
                veteran or military status, genetic information (including
                familial genetic information).
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>D. Commercial information.</td>
              <td>
                Any commercial information you have manifestly made public,
                including products or services purchased, obtained, or considered,
                or other purchasing or consuming histories or tendencies.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>E. Biometric information.</td>
              <td>
                Any biometric information you have manifestly made public,
                including genetic, physiological, behavioral, and biological
                characteristics or other physical patterns, and sleep, health, or
                exercise data.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity.</td>
              <td>
                Any Internet or other similar network activity you have manifestly
                made public, including your interaction with a website or
                application (such as your social media posting history).
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>G. Sensory data.</td>
              <td>
                Any sensory data you have manifestly made public, including audio,
                electronic, visual or similar information.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>H. Professional or employment-related information.</td>
              <td>
                Any professional or employment-related information you have
                manifestly made public, including your current or past job
                history.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
            <tr>
              <td>I. Inferences drawn from other personal information.</td>
              <td>
                Metrics reflecting characteristics and aptitudes, such as the
                relevance, reach and resonance of influencers, as well as
                demographic information of influencers&apos; audiences.
              </td>
              <td>
                For the purposes of allowing users of our platform and associated
                market-research database to discover, evaluate and manage their
                engagement with influential people. In addition to allowing users
                to conduct market research, as noted above, we will use your
                consumer information to connect you through our platform with
                companies (and their brands).
              </td>
            </tr>
          </tbody>
        </table>
      </figure>

      <p>
        (b)&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>Use of Consumer Information:&nbsp;</strong>
        We use consumer
        information for the business or commercial purposes described in the table
        above and in the manner described in paragraphs 2 and 3 of this privacy
        notice with respect to personal data.
      </p>
      <p>
        (c) &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>
          Disclosures of Consumer Information for a Business Purpose
        </strong>
        : AIfluence may disclose your consumer information described in the table
        above to a third party for a business purpose, as described in paragraph
        3. In the preceding twelve (12) months, AIfluence has disclosed the
        following categories of consumer information (as described in the table
        above) for a business purpose:
      </p>
      <p>(i) Identifiers.</p>
      <p>
        (ii) Personal information categories listed in the California Customer
        Records statute (Cal. Civ. Code § 1798.80(e)).
      </p>
      <p>
        (iii) Protected classification characteristics under California or federal
        law.
      </p>
      <p>(iv) Commercial information.</p>
      <p>(v) Biometric information.</p>
      <p>(vi) Internet or other similar network activity.</p>
      <p>(vii) Sensory data.</p>
      <p>(viii) Professional or employment-related information.</p>
      <p>(ix) Inferences drawn from other personal information.</p>
      <p>
        (d)&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>Sales of Consumer Information</strong>
        :&nbsp; Your consumer
        information (as described in the table above) may be sold (as defined
        above) for the business purpose of allowing users of our platform and
        associated market-research database to discover, evaluate and manage their
        engagement with influential people. In addition to allowing users to
        conduct market research, as noted above, we will use your consumer
        information to connect you through our platform with companies (and their
        brands). In the preceding twelve (12) months, AIfluence has sold the
        following categories of consumer information (as described in the table
        above):
      </p>
      <p>(i) Identifiers.</p>
      <p>
        (ii) Personal information categories listed in the California Customer
        Records statute (Cal. Civ. Code § 1798.80(e)).
      </p>
      <p>
        (iii) Protected classification characteristics under California or federal
        law.
      </p>
      <p>(iv)&nbsp;Commercial information.</p>
      <p>(v) Biometric information.</p>
      <p>(vi) Internet or other similar network activity.</p>
      <p>(vii) Sensory data.</p>
      <p>(viii) Professional or employment-related information.</p>
      <p>(ix) Inferences drawn from other personal information.</p>
      <p>
        (e)&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>California Residents’ Rights and Choices:</strong>
        &nbsp;The CCPA
        provides California residents with specific rights regarding their
        consumer information. This paragraph describes your CCPA rights (to the
        extent applicable to you) and explains how to exercise those rights.
      </p>
      <p>
        (i)&nbsp;
        <strong>
          Access to Specific Information and Data Portability Rights:&nbsp;
        </strong>
        You may have the right to request that AIfluence disclose certain
        information to you about our collection and use of your consumer
        information over the past 12 months. Once we receive and confirm your
        verifiable consumer request (in the manner described in paragraph 10(f)
        below), to the extent required by the CCPA, we will disclose to you:
      </p>
      <p>
        (A)&nbsp;The categories of consumer information we collected about you.
      </p>
      <p>
        (B)&nbsp;The categories of sources for the consumer information we
        collected about you.
      </p>
      <p>
        (C)&nbsp;Our business or commercial purpose for collecting or selling that
        consumer information.
      </p>
      <p>
        (D)&nbsp;The categories of third parties with whom we share that consumer
        information.
      </p>
      <p>
        (E)&nbsp;The specific pieces of consumer information we collected about
        you (also called a data portability request).
      </p>
      <p>
        (F)&nbsp;If we sold or disclosed your consumer information for a business
        purpose, two separate lists disclosing (i) sales, identifying the consumer
        information categories that each category of buyer purchased; and (ii)
        disclosures for a business purpose, identifying the consumer information
        categories that each category of recipient obtained.
      </p>
      <p>
        (ii)&nbsp;
        <strong>Deletion Request Rights:</strong>
        &nbsp; You have the
        right to request that AIfluence delete any of your consumer information
        that we collected from you and retained, subject to certain exceptions.
        Once we receive and confirm a verifiable request from you (if you are a
        California resident) in the manner described in paragraph 10(f) below (“
        <strong>verifiable consumer request</strong>
        ”), we will delete (and direct
        our service providers to delete) your consumer information from our
        records, unless an exception applies. We may deny your deletion request if
        retaining the information is necessary for us or our service provider(s)
        to:
      </p>
      <p>
        (A) Complete the transaction for which we collected the consumer
        information, provide a product or service that you requested, take actions
        reasonably anticipated within the context of our ongoing business
        relationship with you, or otherwise perform our contract with you.
      </p>
      <p>
        (B) Detect security incidents, protect against malicious, deceptive,
        fraudulent, or illegal activity, or prosecute those responsible for such
        activities.
      </p>
      <p>
        (C) Debug products or services to identify and repair errors that impair
        existing intended functionality.
      </p>
      <p>
        (D) Exercise free speech, ensure the right of another consumer to exercise
        their free speech rights, or exercise another right provided for by law.
      </p>
      <p>
        (E) Comply with the California Electronic Communications Privacy Act (Cal.
        Penal Code § 1546&nbsp;
        <em>et seq.</em>
        ).
      </p>
      <p>
        (F)&nbsp;Engage in public or peer-reviewed scientific, historical, or
        statistical research in the public interest that adheres to all other
        applicable ethics and privacy laws, when the information&apos;s deletion may
        likely render impossible or seriously impair the research&apos;s achievement,
        if you previously provided informed consent.
      </p>
      <p>
        (G) Enable solely internal uses that are reasonably aligned with consumer
        expectations based on your relationship with us.
      </p>
      <p>(H) Comply with a legal obligation.</p>
      <p>
        (I)&nbsp;Make other internal and lawful uses of that information that are
        compatible with the context in which you provided it.
      </p>
      <p>
        (f) &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>Exercising Access, Data Portability, and Deletion Rights</strong>
        :
      </p>
      <p>
        (i)&nbsp;To exercise the access, data portability, and deletion rights
        described in paragraph 10(e) above, please submit a verifiable consumer
        request to us by either: (1) calling us at&nbsp; +1(855) AIFLUENCE; (2)
        visiting&nbsp;
        <em>
          <u>https://www.ai-fluence.com/data-opt-out</u>
        </em>
        ; or (3) contacting us in accordance with the paragraph above titled
        &quot;Further help&quot;. Only you, or a person registered with the California
        Secretary of State that you authorize to act on your behalf, may make a
        verifiable consumer request related to your consumer information. You may
        also make a verifiable consumer request on behalf of your minor child. You
        may make a verifiable consumer request for access or data portability no
        more than twice within a 12-month period. The verifiable consumer request
        must: (i) provide sufficient information that allows us to reasonably
        verify you are the person about whom we collected consumer information or
        an authorized representative; and (ii) describe your request with
        sufficient detail that allows us to properly understand, evaluate, and
        respond to it. We cannot respond to your request or provide you with
        consumer information if we cannot verify your identity or authority to
        make the request and confirm the consumer information relates to you.
        Making a verifiable consumer request does not require you to create an
        account with us. We will only use consumer information provided in a
        verifiable consumer request to verify the requestor&apos;s identity or
        authority to make the request.&nbsp; For instructions on exercising sale
        opt-out rights, see paragraph 10(g) below.
      </p>
      <p>
        (ii) We endeavour to respond to a verifiable consumer request within
        forty-five (45) days of its receipt. If we require more time (up to ninety
        (90) days), we will inform you of the reason and extension period in
        writing. If you have an account with us, we may deliver our written
        response to that account. If you do not have an account with us, we will
        deliver our written response by mail or electronically, at your option.
        Any disclosures we provide will only cover the 12-month period preceding
        the verifiable consumer request&apos;s receipt. The response we provide will
        also explain the reasons we cannot comply with a request, if applicable.
        For data portability requests, we will select a format to provide your
        consumer information that is readily useable and should allow you to
        transmit the information from one entity to another entity without
        hindrance. If your requests are manifestly unfounded or excessive, in
        particular because of their repetitive character, we may either charge a
        reasonable fee, taking into account the administrative costs of providing
        the information or communication or taking the action requested, or refuse
        to act on the request and notify you of the reason for refusing the
        request.
      </p>
      <p>
        (g)&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>Consumer Information Sales Opt-Out and Opt-In Rights</strong>
        :
      </p>
      <p>
        (i) If you are 16 years of age or older, you have the right to direct us
        to not sell your consumer information at any time (the &quot;
        <strong>right to opt-out</strong>
        &quot;). We do not sell the consumer
        information of California residents we actually know are less than 16
        years of age, unless we receive affirmative authorization (the &quot;
        <strong>right to opt-in</strong>
        &quot;) from either the California resident who
        is at least 13 years of age and less than 16 years of age, or the parent
        or guardian of a California resident less than 13 years of age. California
        residents who opt in to consumer information sales may opt out of future
        sales at any time.
      </p>
      <p>
        (ii) To exercise the right to opt-out, you (or your authorized
        representative) may submit a request to us by contacting us in the manner
        described in Section 10(f) above or by visiting the following Internet Web
        page link:
      </p>
      <p>
        <a href="https://www.traackr.com/data-opt-out">
          <strong>Do Not Sell My Personal Information</strong>
        </a>
      </p>
      <p>
        (iii)&nbsp;Once you make an opt-out request, we will wait at least twelve
        (12) months before asking you to reauthorize consumer information sales.
        However, you may change your mind and opt back in to consumer information
        sales at any time by emailing privacy@ai-fluence.com.
      </p>
      <p>
        (iv) You do not need to create an account with us to exercise your opt-out
        rights. We will only use consumer information provided in an opt-out
        request to review and comply with the request.
      </p>
      <p>
        (h)&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <strong>Non-Discrimination</strong>
        :
        We will not discriminate against you for exercising any of your CCPA
        rights, including, unless permitted by the CCPA, by:
      </p>
      <p>(i) Denying you goods or services.</p>
      <p>
        (ii) Charging you different prices or rates for goods or services,
        including through granting discounts or other benefits, or imposing
        penalties.
      </p>
      <p>
        (iii)&nbsp;Providing you a different level or quality of goods or
        services.
      </p>
      <p>
        (iv) Suggesting that you may receive a different price or rate for goods
        or services or a different level or quality of goods or services.
      </p>
      <p>
        This version of this privacy notice is effective as of, and was last
        updated on, March 16th, 2021 (the &quot;Effective Date&quot;).
      </p>
      <p>
        <strong>AIfluence, Inc.</strong>
      </p>
    </Box>
  );
};

export default IPrivacyPolicy;
