import * as React from "react";
import PropTypes from "prop-types";
import CustomizedTable from "../../common/customizedTable";
import styles from "../../../styles/internal/Campaign/CampaignList.module.css";


const CampaignTable = ({
  tableHeaders,
  getInformation,
  totalRows,
  bodyColumns,
  selectedRow,
  page,
  rowsPerPage,
  sortOrderValue,
  sortOrderBy,
}) => {
  const sendInformation = (event) => {
    getInformation(event);
  };

  React.useEffect(() => {}, [bodyColumns, tableHeaders]);

  return (
    <div className={styles.campaignListTable}>
      <CustomizedTable
        tableHeaders={tableHeaders}
        bodyColumns={bodyColumns}
        selectedRow={selectedRow}
        sendInformation={sendInformation}
        totalRows={totalRows}
        pageNo={page}
        rowsPerPageNo={rowsPerPage}
        sortOrder={sortOrderValue}
        sortOrderBy={sortOrderBy}
      />
    </div>
  );
};

CampaignTable.propTypes = {
  tableHeaders: PropTypes.array.isRequired,
  getInformation: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  bodyColumns: PropTypes.array.isRequired,
  selectedRow: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortOrderValue: PropTypes.string.isRequired,
  sortOrderBy: PropTypes.string.isRequired,
};

export default CampaignTable;
