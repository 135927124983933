import React, { useState, useEffect } from "react";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { Grid, Button, Dialog, DialogContent, FormControl, Select, MenuItem, InputLabel, Card } from "@mui/material";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { useDispatch, useSelector } from "react-redux";
import { getGaSummary, getCountryWiseDist } from "../../../../services/campaignAnalytics";
import Loader from "../../../common/loader";

const CampaignGaConversionTable = ({
  groups,
  infs,
  platforms,
  campaignName,
  selectedStartDate,
  selectedEndDate,
  exportXlsx,
  cid,
}) => {
  const [bodyColumns, setBodyColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortCol, setSortCol] = useState("conversions");
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalRows, setTotalRows] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const [linksData, setLinksData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [event, setEvent] = useState("all");
  const [countryRows, setCountryRows] = useState(0);
  const [countryTableData, setCountryTableData] = useState([]);
  const [countryBodyClumn, setCountryBodyClumn] = useState([]);
  const [cpage, setCPage] = useState(0);
  const [crows, setCRows] = useState(10);
  const [clinksData, setCLinksData] = useState([]);

  const tableHeadersSingleBase = [
    {
      id: 1,
      label: "Event",
      mappedValue: "eventname",
      field: "eventname",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: false,
    },
    {
      id: 2,
      label: "Conversion",
      mappedValue: "conversions",
      field: "conversions",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "Campaign Conversion",
      mappedValue: "aiConversion",
      field: "aiConversion",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "Total Users",
      mappedValue: "totalusers",
      field: "totalusers",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 5,
      label: "Campaign Users",
      mappedValue: "aiTotalusers",
      field: "aiTotalusers",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
  ];

  const tableHeadersCountryBase = [
    {
      id: 1,
      label: "Country",
      mappedValue: "country",
      field: "country",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: false,
    },
    {
      id: 2,
      label: "Conversion",
      mappedValue: "conversions",
      field: "conversions",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "Campaign Conversion",
      mappedValue: "aiConversion",
      field: "aiConversion",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "Total Users",
      mappedValue: "totalusers",
      field: "totalusers",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
    {
      id: 5,
      label: "Campaign Users",
      mappedValue: "aiTotalusers",
      field: "aiTotalusers",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
      sortable: true,
    },
  ];

  useEffect(() => {
    getConvTableDataValues();
    getCountryDistDataValues();
  }, []);

  useEffect(() => {
    if (tableData?.length === 0) {
      return;
    }
    createFinalData(linksData, page, rows, sortOrder, sortCol);
  }, [linksData, page, rows, sortOrder, sortCol]);

  useEffect(() => {
    getCountryDistDataValues();
  }, [event]);

  useEffect(() => {
    if (countryTableData?.length === 0) {
      return;
    }
    createCountryDist(clinksData, cpage, crows, sortOrder, sortCol);
  }, [clinksData, cpage, crows, clinksData, sortOrder, sortCol]);

  const getConvTableDataValues = async () => {
    setTableLoading(true);
    const startDate = getFormattedDate(selectedStartDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(selectedEndDate, "YYYY-MM-DD");
    const linksData = await getGaSummary(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      cid,
      user,
      dispatch,
      "google/analysis/conversions"
    );
    if (linksData.response != null) {
      setLinksData(linksData.response);
      createFinalData(linksData.response, page, rows, sortOrder, sortCol);
    }
    setTableLoading(false);
  };

  const getCountryDistDataValues = async () => {
    setTableLoading(true);
    let eventName = "";
    if (event !== "all") {
      eventName = event;
    }
    const startDate = getFormattedDate(selectedStartDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(selectedEndDate, "YYYY-MM-DD");
    const clinksData = await getCountryWiseDist(
      groups,
      infs,
      platforms,
      campaignName,
      startDate,
      endDate,
      cid,
      eventName,
      user,
      dispatch,
      "google/analysis/countryDist"
    );
    if (clinksData.response != null) {
      setCLinksData(clinksData.response);
      createCountryDist(clinksData.response, cpage, crows, sortOrder, sortCol);
    }
    setTableLoading(false);
  };

  const createCountryDist = (data, page, rows, sortOrder, sortCol) => {
    if (data) {
      if (sortOrder === "desc") {
        data.sort(function (a, b) {
          if (a[sortCol] > b[sortCol]) return -1;
          if (a[sortCol] < b[sortCol]) return 1;
          return 0;
        });
      } else {
        data.sort(function (a, b) {
          if (a[sortCol] < b[sortCol]) return -1;
          if (a[sortCol] > b[sortCol]) return 1;
          return 0;
        });
      }
      const final_data = [];
      data.forEach((row) => {
        const object = {
          country: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.country}
              </Grid>
            </Grid>
          ),
          conversions: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.conversions}
              </Grid>
            </Grid>
          ),
          aiConversion: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.aiConversion || 0}
              </Grid>
            </Grid>
          ),
          totalusers: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.totalusers}
              </Grid>
            </Grid>
          ),
          aiTotalusers: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.aiTotalusers || 0}
              </Grid>
            </Grid>
          ),
        };
        final_data.push(object);
      });
      setCountryTableData(final_data);
      setCountryRows(final_data?.length);
      createCountryData(final_data.slice(page * rows, (page + 1) * rows));
    }
  };

  const createFinalData = (data, page, rows, sortOrder, sortCol) => {
    if (data) {
      if (sortOrder === "desc") {
        data.sort(function (a, b) {
          if (a[sortCol] > b[sortCol]) return -1;
          if (a[sortCol] < b[sortCol]) return 1;
          return 0;
        });
      } else {
        data.sort(function (a, b) {
          if (a[sortCol] < b[sortCol]) return -1;
          if (a[sortCol] > b[sortCol]) return 1;
          return 0;
        });
      }
      const final_data = [];
      const event_data = [];

      data.forEach((row) => {
        event_data.push(row.eventname);
        const object = {
          eventname: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.eventname}
              </Grid>
            </Grid>
          ),
          conversions: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.conversions}
              </Grid>
            </Grid>
          ),
          aiConversion: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.aiConversion || 0}
              </Grid>
            </Grid>
          ),
          totalusers: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.totalusers}
              </Grid>
            </Grid>
          ),
          aiTotalusers: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.aiTotalusers || 0}
              </Grid>
            </Grid>
          ),
          totalrevenue: (
            <Grid container spacing={1}>
              <Grid item xs={1} className={styles.bodycell}></Grid>
              <Grid item xs={3} className={styles.bodycell}>
                {row.totalrevenue}
              </Grid>
            </Grid>
          ),
        };
        final_data.push(object);
      });
      setEventList(event_data);
      setTableData(final_data);
      createData(final_data.slice(page * rows, (page + 1) * rows));
    }
  };

  const sendInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);
    }
  };

  const sendCInformation = (event) => {
    if (event) {
      setCPage(event.page);
      setCRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);
    }
  };

  const createCountryData = (tableData) => {
    setCountryBodyClumn(tableData);
  };

  const createData = (tableData) => {
    setTotalRows(tableData?.length);
    setBodyColumns(tableData);
  };
  const openCountryDist = async (id) => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const handleEventChange = (e) => {
    const event = e.target.value;
    setEvent(event);
  };
  return (
    <>
      <Dialog
        open={openModal}
        onClose={closeModal}
        PaperProps={{
          style: {
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <h4>Countrywise Distribution</h4>
          <form>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <InputLabel>Conversion Events</InputLabel>
                  <Select onChange={handleEventChange} value={event} required>
                    <MenuItem key="all" value="all" selected>
                      All Conversion Events
                    </MenuItem>
                    {eventList?.map((event) => (
                      <MenuItem key={event} value={event}>
                        {event}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12}>
            <Card style={{ marginTop: 30 }}>
              {countryRows > 0 ? (
                <CustomizedTable
                  tableHeaders={tableHeadersCountryBase}
                  bodyColumns={countryBodyClumn}
                  totalRows={countryRows}
                  pageNo={cpage}
                  rowsPerPageNo={crows}
                  sortOrder={sortOrder}
                  sortOrderBy={sortCol}
                  sendInformation={sendCInformation}
                  staticTable={true}
                />
              ) : (
                <div className={styles.container}>
                  <div className={styles.child}> No data available. Please expand the filters.</div>
                </div>
              )}
            </Card>
          </Grid>
        </DialogContent>
      </Dialog>
      {!tableLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.background}>
              <h4 style={{ paddingLeft: "20px" }}>
                <b className={styles.title}>Conversion Details</b>
              </h4>

              <Button
                variant="contained"
                style={{
                  background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                  borderRadius: "8px",
                  float: "right",
                }}
                onClick={openCountryDist}
              >
                Countrywise Distribution
              </Button>
              {totalRows > 0 ? (
                <CustomizedTable
                  tableHeaders={tableHeadersSingleBase}
                  bodyColumns={bodyColumns}
                  totalRows={totalRows}
                  pageNo={page}
                  rowsPerPageNo={rows}
                  sortOrder={sortOrder}
                  sortOrderBy={sortCol}
                  sendInformation={sendInformation}
                  staticTable={true}
                />
              ) : (
                <div className={styles.container}>
                  <div className={styles.child}> No data available. Please expand the filters.</div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </>
  );
};

CampaignGaConversionTable.propTypes = {
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  shorturlIds: PropTypes.array.isRequired,
};

export default CampaignGaConversionTable;
