import { Chip, IconButton } from '@mui/material'
import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from "prop-types";

const CustomChip = (props) => {
  const { chipId, chipLabel, clickHandler } = props;
  return (
    <>
      <Chip
        key={chipId}
        icon={
          <IconButton onClick={clickHandler}>
            <CloseRoundedIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        }
        label={chipLabel}
        style={{ backgroundColor: "white", marginRight: "10px" }}
      />
    </>
  )
}

CustomChip.propTypes = {
  chipId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  chipLabel: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired
}

export default CustomChip