import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utility/oryxUtils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Paper,
  Button,
} from "@mui/material";
import { analyzeInfluencerFromIQData, fetchHistoryDataOnType } from "../../services/discovery";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../utility/oryxUtils/utils";
import Common from "./Common";
import MyPaper from "../../utility/oryxUtils/MyPaper";
import "../../styles/oryxStyles/Discover.css";
import { History } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { snapshotHeaderIcons, textColor } from "../../utility/plaformIcons";
const Analyze = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("instagram");
  const [shouldRenderUser, setShouldRenderUser] = useState(false);
  const navigate = useNavigate();
  const [allFiltersData, setAllFiltersData] = useState([{ inf_hidden: [{ name: "Exclude private accounts" }] }]);
  const [allInfanalyzes, setAllInfanalyzes] = useState([]);
  const [username, setUserName] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState("");
  const handleAnalyzeSelection = (selectedValues) => {
    setNameValue(selectedValues["name"])
    let username = selectedValues["username"];
    if (username !== undefined) {
      setUserName(username);
    }
  };

  useEffect(() => {
    getHistory("analyze", selectedPlatform);
  }, []);

  const getHistory = async (type, socialNetwork) => {
    const response = await fetchHistoryDataOnType(type, socialNetwork.toLowerCase(), dispatch, user);
    const { status, data } = response?.response;
    if (status == "success") {
      if (data === null) {
        setAllInfanalyzes([]);
      } else {
        let newanalyzes = [];
        data?.map((value, index) => {
          const look = value.request;
          if (look["username"] != undefined || look["fullname"] != undefined) {
            let lookData = {
              username: look["username"] ? look["username"] : look["handle"],
              name: look["name"],
              pic: look["photourl"],
              followers: 0,
            };
            newanalyzes.push(lookData);
          }
        });
        setAllInfanalyzes(newanalyzes);
      }
    }
  };

  const handlerInfanalyze = (event) => {
    if (event.target.value != "") {
      getInfluencerData(event.target.value, "search");
    }
  };

  const getInfluencerData = async (searchKey, searchType) => {
    try {
      const res = await analyzeInfluencerFromIQData(searchKey, searchType, selectedPlatform, dispatch);
      const {
        response: { data },
      } = res;
      let newanalyzes = [];
      data?.map((look, index) => {
        if (look["username"] != undefined || look["fullname"] != undefined) {
          let lookData = {
            username: look["username"] ? look["username"] : look["handle"],
            name: look["fullname"],
            pic: look["picture"],
            followers: look["followers"],
          };
          newanalyzes.push(lookData);
        }
      });
      setAllInfanalyzes(newanalyzes);
    } catch (err) {
      console.log(err);
    }
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const filterOptions = (options, state) => {
    sleep(500);
    return options;
  };

  const [allPlatforms] = useState(["instagram", "tiktok", "youtube"]);
  const socialIcons = {
    instagram: <InstagramIcon sx={{ color: "#BC2A8D", width: "35px", height: "35px" }} />,
    twitter: <TwitterIcon sx={{ color: "#00ACEE", width: "35px", height: "35px" }} />,
    tiktok: <TikTokIcon style={{ width: "24px", height: "24px" }} sx={{ color: "#000000" }} />,
    youtube: <YouTubeIcon sx={{ color: "#FF0100", width: "35px", height: "35px" }} />,
    linkedin: <LinkedInIcon sx={{ color: "#0a66c2", width: "35px", height: "35px" }} />,
    facebook: <FacebookIcon sx={{ color: "#1b74e4", width: "35px", height: "35px" }} />,
  };

  const handlePlatformSelection = (event) => {
    setUserName("");
    setSelectedPlatform(event.target.value);
  };

  useEffect(() => {
    if (username !== "" && selectedPlatform !== "") {
      setShouldRenderUser(true);
    } else {
      setShouldRenderUser(false);
    }
  }, [username, selectedPlatform]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        {" "}
        <Button
          sx={{
            color: "black",
            background: "white",
            border: "1px solid lightgrey",
            borderRadius: "12px",
            "&:hover": { bgcolor: "lightgray" },
            whiteSpace: "nowrap",
            px: "12px",
            textTransform: "none",
            justifyContent: "space-between",
            mr: "1rem",
          }}
          variant="contained"
          disableElevation
          onClick={() => navigate("/app/discovery/history/analyze")}
          startIcon={<History />}
        >
          Show All History
        </Button>
      </Box>
      <MyPaper>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl sx={{ width: "100%", height: "56px" }} size="small">
              <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
              <Select
                onChange={handlePlatformSelection}
                variant="outlined"
                label="Platform"
                value={selectedPlatform}
                required
                size="small"
                sx={{ height: "100%" }}
              >
                {allPlatforms?.map((platform) => (
                  <MenuItem key={platform} value={platform}>
                    <Grid container spacing={1}>
                      <Grid item xs={2.5}>
                        <Typography sx={textColor[platform]}>{snapshotHeaderIcons[platform]}</Typography>
                      </Grid>
                      <Grid item xs={9.5}>
                        <Typography style={{ paddingTop: "3px" }}>{utils.capitalizeFirstLetter(platform)}</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              size="medium"
              id="filter-influencer-analyze"
              options={allInfanalyzes}
              popupIcon={""}
              limitTags={0}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.name || ""}
              value={
                typeof allFiltersData[0]["inf_analyzes"] === "undefined" ? "" : allFiltersData[0]["inf_analyzes"][0]["value"]
              }
              onChange={(_e, v) => handleAnalyzeSelection(v)}
              // onKeyPress={(e) => handlerInfanalyze(e)}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  <span className="mat-option-text">
                    <div className="autocomplete-item">
                      <img
                        loading="lazy"
                        className="round-image autocomplete-item__picture"
                        src={option.pic}
                        srcSet={option.pic}
                      />
                      <div className="autocomplete-item__info">
                        <div className="autocomplete-item__username">{"@" + option.username}</div>
                        <div className="autocomplete-item__fullname">{option.name}</div>
                      </div>
                      {option.followers > 0 && (
                        <div className="autocomplete-item__followers">
                          {utils.convertToInternationalNumberSystem(option.followers)}
                        </div>
                      )}
                    </div>
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Influencer profile URL, user ID or @handle"
                  placeholder="Influencer profile URL, user ID or @handle"
                  variant="outlined"
                  onChange={handlerInfanalyze}
                  sx={{ height: "100%" }}
                />
              )}
            />
          </Grid>
          {shouldRenderUser && <Common id={username} nameValue={nameValue} platform={selectedPlatform} />}
        </Grid>
      </MyPaper>
    </>
  );
};

export default Analyze;
