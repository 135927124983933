import React, { useEffect } from "react";
import Services from "../../components/landing/Services";

function BaseServices(props) {
  useEffect(() => {
    setBackground();
  }, []);

  const setBackground = () => {
    document.body.style.background = "white";
  };

  return (
    <div>
      <Services /> <br />
    </div>
  );
}

export default BaseServices;
