import { Box, Grid, Tab } from "@mui/material";
import CampaignLinksFilter from "./CampaignLinksFilter";
import React, { useState } from "react";
import {
  getClicksSummary,
  getDeviceSummary,
  getLinkCountrySummary,
  getLinkFilters,
  getReferrersSummary,
} from "../../../../services/campaignAnalytics";
import { countryCodes } from "../../../../utility/countryCodes";
import { PlatformIdMapping, snapshotHeaderIcons } from "../../../../utility/plaformIcons";
import { useLocation } from "react-router-dom";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AddLink, Insights } from "@mui/icons-material";
import CampaignLinksManageSnapshot from "./CampaignLinksManageSnapshot";
import CampaignLinksMetricsSnapshot from "./CampaignLinksMetricsSnapshot";
import { useEffect } from "react";
import DummyCampaign from "../../../common/DummyCampaign";

const CampaignLinksSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [clickGraph, setClickGraph] = useState("daily");
  const [shorturls, setShorturls] = useState([]);
  const [selectedShorturls, setSelectedShorturls] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState(Object.keys(snapshotHeaderIcons));
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedDestinationIds, setSelectedDestinationIds] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")
  ); //select campaign duration for 7 days
  const [selectedEndDate, setSelectedEndDate] = useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [linkClicksY, setLinkClicksY] = useState([]);
  const [linkClicksX, setLinkClicksX] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [referresData, setReferresData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [worldData, setWorldData] = useState([]);
  const [tabValue, setTabValue] = useState("metrics");
  const universal = params.get("universal");
  useEffect(() => {
    initialLoad();
  }, [+params.get("id")]);

  const initialLoad = async () => {
    resetState();
    const shorturlIds = await getLinksData(
      selectedGroups?.map((value) => value.id),
      selectedPlatforms?.map((value) => PlatformIdMapping[value]),
      selectedDestinationIds?.map((value) => value.urlId),
      selectedInfluencers?.map((value) => value.Iid)
    );
    createGraphs(shorturlIds?.map((value) => value.id));
  };

  const resetState = () => {
    setDataFound(false);
    setShorturls([]);
    setSelectedShorturls([]);
    setLinkClicksY([]);
    setLinkClicksX([]);
    setDevicesData([]);
    setReferresData([]);
    setLocationData([]);
    setWorldData([]);
  };

  const getLinksData = async (selectedGroups, selectedPlatforms, selectedDestinationIds, selectedInfluencers) => {
    const shorturls = await getLinkFilters(
      +params.get("id"),
      selectedGroups,
      selectedPlatforms,
      selectedDestinationIds,
      selectedInfluencers,
      user,
      dispatch
    );

    if (shorturls.status === true) {
      setDataFound(true);
      setShorturls(shorturls.response || []);
      setSelectedShorturls(shorturls.response || []);
      return shorturls.response || [];
    }
  };

  const createGraphs = async (ids) => {
    setLoading(true);
    const startDate = getFormattedDate(selectedStartDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(selectedEndDate, "YYYY-MM-DD");
    const clickSummary = await getClicksSummary(ids, startDate, endDate, user, dispatch);
    const deviceSummary = await getDeviceSummary(ids, startDate, endDate, user, dispatch);
    const referrersSummary = await getReferrersSummary(ids, startDate, endDate, user, dispatch);
    const countrySummary = await getLinkCountrySummary(ids, startDate, endDate, user, dispatch);
    setLoading(false);
    if (
      clickSummary.status === true ||
      (deviceSummary.status === true && deviceSummary.response != null) ||
      referrersSummary.status === true ||
      countrySummary.status === true
    ) {
      createClickGraph(clickSummary.response);
      createDeviceGraph(deviceSummary.response);
      createReferrersGraph(referrersSummary.response);
      createCountryGraph(countrySummary.response);
    }
  };

  const createClickGraph = (clickSummary) => {
    const acceptedValues = clickSummary[clickGraph]?.filter((value) => value.value > 0);
    const keys = acceptedValues?.map((value) => value.key);
    const values = acceptedValues?.map((value) => value.value);
    setLinkClicksX(keys);
    setLinkClicksY(values);
  };

  const createDeviceGraph = (deviceSummary) => {
    if (deviceSummary) {
      const keys = Object.keys(deviceSummary);
      const devices = [];
      keys.forEach((key) => {
        devices.push({
          value: deviceSummary[key].value,
          name: deviceSummary[key].key,
        });
      });
      setDevicesData(devices);
    }
  };

  const createReferrersGraph = (referrersData) => {
    if (referrersData) {
      const referrersArray = [];
      const keys = Object.keys(referrersData);
      let total = 0;
      keys?.map((key) => (total += referrersData[key].value));
      keys.forEach((key) => {
        referrersArray.push({
          title: referrersData[key].key,
          value: referrersData[key].value,
          percentage: Math.floor((referrersData[key].value / total) * 100),
        });
      });
      referrersArray.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
      setReferresData(referrersArray);
    }
  };

  const createCountryGraph = (countryData) => {
    const locationData = restructureDataForMap(countryData);
    const locationDataForTable = remapLocationDataForTable(locationData, countryCodes);
    setLocationData(locationDataForTable);
    setWorldData(locationData);
  };

  const restructureDataForMap = (data) => {
    if (!data) {
      return [];
    }
    return data?.map((item) => {
      return {
        country: item.value,
        value: item.clicks,
        percentage: item.percentage + "%",
      };
    });
  };

  const remapLocationDataForTable = (locationData, countryCodes) => {
    if (!locationData) {
      return [];
    }
    let countries = [];
    let unknownCountry_clicks = 0;
    let unknownCountry_percentage = 0;
    locationData?.map((item) => {
      if (countryCodes[item.country]) {
        const country = countryCodes[item.country].name;
        countries.push({
          title: country,
          clicks: item.value,
          percentage: item.percentage,
        });
      } else {
        unknownCountry_clicks += item.value;
        const percentage_str = item.percentage;
        const percentage_num = percentage_str.slice(0, percentage_str?.length - 1);
        unknownCountry_percentage += parseFloat(percentage_num);
      }
    });
    if (unknownCountry_clicks > 0) {
      countries.push({
        title: "Other",
        clicks: unknownCountry_clicks,
        percentage: unknownCountry_percentage + "%",
      });
    }
    return countries;
  };

  const sendFilterChange = (event) => {
    setSelectedStartDate(event.startDate);
    setSelectedEndDate(event.endDate);
    setSelectedGroups(event.selectedGroups);
    setSelectedDestinationIds(event.selectedDestinationIds);
    setSelectedInfluencers(event.selectedInfluencers);
    setSelectedPlatforms(event.selectedPlatforms);
    createGraphs(event.selectedShorturls?.map((url) => url.id));
  };

  const updateDates = (event) => {
    setSelectedStartDate(event.startDate);
    setSelectedEndDate(event.endDate);
  };

  const handleTabChange = (_, value) => {
    setTabValue(value);
  };

  return (
    <>
      <DummyCampaign universal={universal} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {tabValue === "metrics" && (
          <CampaignLinksFilter
            allSelectedPlatforms={selectedPlatforms}
            allSelectedGroups={selectedGroups}
            allSelectedDestinationIds={selectedDestinationIds}
            allSelectedInfluencers={selectedInfluencers}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            sendFilterChange={sendFilterChange}
            allurls={shorturls}
            getLinksData={getLinksData}
            selectedShorturls={selectedShorturls}
            setSelectedShorturls={setSelectedShorturls}
            updateDates={updateDates}
            disabled={tabValue === "manage"}
          />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <TabContext value={tabValue}>
              <Box sx={{ bgcolor: "background.paper" }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab icon={<Insights />} label="Metrics" value="metrics" />
                  <Tab icon={<AddLink />} label="Manage URLs" value="manage" />
                </TabList>
              </Box>
              <TabPanel value={tabValue}>
                {tabValue === "metrics" && (
                  <CampaignLinksMetricsSnapshot
                    selectedShorturls={selectedShorturls}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    loading={loading}
                    dataFound={dataFound}
                    linkClicksY={linkClicksY}
                    linkClicksX={linkClicksX}
                    devicesData={devicesData}
                    referresData={referresData}
                    locationData={locationData}
                    worldData={worldData}
                  />
                )}
                {tabValue === "manage" && <CampaignLinksManageSnapshot />}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignLinksSnapshot;
