import PropTypes from "prop-types";
import React, { useState } from "react";
import utils from "../../../utility/oryxUtils/utils";
import { Grid, Typography, Button, Chip, Select, MenuItem,Tooltip, IconButton, Box } from "@mui/material";
import { People, RecordVoiceOver, WarningAmberRounded } from "@mui/icons-material";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoIcon from "@mui/icons-material/Info";

const DiscoveryAppliedFilters = ({
  appliedFilters,
  payload,
  setPayload,
  customCols,
  setCustomCols,
  isChanged,
  setIsChanged,
  allFiltersData,
  setAllFiltersData,
  setSelectedCol,
}) => {
  const [ranges, setRanges] = useState({}); // Store ranges for each chip
  let total = 0;

  const filterKeys = {
    audience_type: "Audience Type",
    ads_brands: "Partnership",
    brand_category: "Interests",
    reel_plays: "Reel Plays",
    lastPosted: "Last Posted",
  };
  const labelWithoutKeys = [
    "contact",
    "verified",
    "credible",
    "known",
    "hidden",
    "has_audience_data",
  ];

  const clearAllFilters = () => {
    setPayload({});
    setCustomCols([{}]);
    setAllFiltersData([{}]);
    setRanges({});
    setIsChanged(!isChanged);
  };

  const handleDelete = (filterType, appliedFilters, label, id, name, filtername) => {
    let newPayload = { ...payload };
    let newFilters = { ...allFiltersData[0] };
    let newCustomCols = [...customCols];
    let newRanges = { ...ranges };
  
    // Remove the range for the deleted filter
    delete newRanges[id];
  
    // Remove the specific filter from the payload and filters
    if (Array.isArray(newPayload[appliedFilters])) {
      newPayload[appliedFilters] = newPayload[appliedFilters].filter(
        (item) => item.id !== id
      );
      if (newPayload[appliedFilters].length === 0) {
        delete newPayload[appliedFilters];
      }
    } else {
      delete newPayload[appliedFilters];
    }
  
    if (Array.isArray(newFilters[appliedFilters])) {
      newFilters[appliedFilters] = newFilters[appliedFilters].filter(
        (item) => item.id !== id
      );
      if (newFilters[appliedFilters].length === 0) {
        delete newFilters[appliedFilters];
      }
    } else {
      delete newFilters[appliedFilters];
    }
  
    // Remove from customCols
    if (Array.isArray(newCustomCols[0][appliedFilters])) {
      newCustomCols[0][appliedFilters] = newCustomCols[0][appliedFilters].filter(
        (item) => item !== name
      );
      if (newCustomCols[0][appliedFilters].length === 0) {
        delete newCustomCols[0][appliedFilters];
      }
    }
    if (filterType === "aud" && label === "age") {
      delete newPayload["aud_age_from"];
      delete newPayload["aud_age_to"];
    } else if (filterType === "inf" && label === "age") {
      delete newPayload["inf_age_from"];
      delete newPayload["inf_age_to"];
    }
  
    // Update state
    setRanges(newRanges);
    setPayload(newPayload);
    setAllFiltersData([newFilters]);
    setCustomCols(newCustomCols);
    setSelectedCol(newCustomCols);
    setIsChanged(!isChanged);
  };
  const handleRangeChange = (event, id) => {
    const newRange = event.target.value;
    setRanges((prevRanges) => ({
      ...prevRanges,
      [id]: newRange,
    }));

    let newPayload = { ...payload };

    Object.keys(newPayload).forEach((key) => {
      if (Array.isArray(newPayload[key])) {
        newPayload[key] = newPayload[key].map((item) =>
          item.id === id ? { ...item, weight: newRange } : item
        );
      }
    });

    setPayload(newPayload);

    setAllFiltersData((prevData) => {
      let updatedData = [...prevData];
      updatedData.forEach((filterGroup) => {
        Object.keys(filterGroup).forEach((key) => {
          if (Array.isArray(filterGroup[key])) {
            filterGroup[key] = filterGroup[key].map((item) =>
              item.id === id ? { ...item, weight: newRange } : item
            );
          }
        });
      });
      return updatedData;
    });
  };

  const getFilterKey = (label) => {
    if (filterKeys[label]) {
      return filterKeys[label];
    }
    return utils.capitalizeFirstLetter(label);
  };

  const chipForSingleFilter = (value) => {
    if (!value) return null;
    
    total += 1;
    return Object.keys(value).flatMap((appliedFilter) => {
      if (typeof value[appliedFilter] === "object") {
        return Object.keys(value[appliedFilter])?.map((index) => {
          let FilterData = appliedFilter.split("_");
          let filterType = FilterData[0];
          let label = FilterData.splice(1).join("_");
          let filterValue = "";
          const id = value[appliedFilter][index].id;
          const currentRange = ranges[id] || value[appliedFilter][index].weight || "0.05";
    
          if (label == "age") {
            filterValue =
              value[appliedFilter][index].left +
              "-" +
              value[appliedFilter][index].right +
              " Y.O";
          } else if (
            label == "followers" ||
            label == "engagements" ||
            label === "reel_plays"
          ) {
            let followersRange =
              value[appliedFilter][index].left +
              "-" +
              value[appliedFilter][index].right;
            if (
              value[appliedFilter][index].right === undefined ||
              value[appliedFilter][index].right == ""
            ) {
              followersRange = value[appliedFilter][index].left + "+";
            }
            if (
              value[appliedFilter][index].left === undefined ||
              value[appliedFilter][index].left == ""
            ) {
              followersRange = "up to " + value[appliedFilter][index].right;
            }
            filterValue = followersRange;
          } else if (
            label === "audience_type" ||
            label === "bio" ||
            label === "keyword" ||
            label === "er"
          ) {
            filterValue = value[appliedFilter][index];
          } else if (label === "lookalikes" || label === "mention") {
            filterValue = value[appliedFilter][index].value;
          } else {
            filterValue =
              value[appliedFilter][index].name ||
              value[appliedFilter][index].code;
          }
          let icon = "";
          if (labelWithoutKeys.includes(label)) {
            icon = <CampaignIcon />;
          } else if (filterType === "aud") {
            icon = <People />;
          } else {
            icon = <RecordVoiceOver />;
          }
          const filterLabel = labelWithoutKeys.includes(label)
            ? `${filterValue}`
            : `${getFilterKey(label)}: ${filterValue}`;
    
          // Exclude range dropdown for specific filters like "gender" or "aud_gender"
          const shouldShowRangeDropdown = !["hidden", "verified", "credible", "known", "gender", "aud_gender"].includes(label);
    
          return (
            <Chip
              key={`${appliedFilter}-${label}`}
              icon={icon}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>{filterLabel}</span>
                  {shouldShowRangeDropdown && (
                    <>
                      <Select
                        value={currentRange}
                        onChange={(e) => handleRangeChange(e, id)}
                        size="small"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224, // height for 10 items in the dropdown
                              width: 100,
                            },
                          },
                          MenuListProps: {
                            style: {
                              maxHeight: 'inherit',
                              overflowY: 'auto',
                            },
                          },
                        }}
                        sx={{
                          ml: 1,
                          backgroundColor: "none",
                          border: "none", // Remove the border
                          height: "auto", // Ensure height is auto
                          padding: 0, // Remove any padding
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none', // Ensure no border on the outline
                          },
                        }}
                      >
                        {[
                          "0.05",
                          "0.1",
                          "0.15",
                          "0.2",
                          "0.25",
                          "0.3",
                          "0.35",
                          "0.4",
                          "0.45",
                          "0.5",
                          "0.55",
                          "0.6",
                          "0.65",
                          "0.7",
                          "0.75",
                          "0.8",
                          "0.85",
                          "0.9",
                          "0.95",
                        ].map((value, index) => (
                          <MenuItem key={index} value={parseFloat(value)}>
                            {`>${Math.round(parseFloat(value) * 100)}%`}
                          </MenuItem>
                        ))}
                      </Select>
                      <Tooltip title={`Select a weight range to fine-tune your ${getFilterKey(label)} filter.`}>
                        <IconButton
                          size="small"
                          sx={{
                            ml: 0.5,
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              }
              style={{ backgroundColor: "white", margin: "2px 5px" }}
              onDelete={() =>
                handleDelete(
                  filterType,
                  appliedFilter,
                  label,
                  id,
                  filterValue,
                  label
                )
              }
              deleteIcon={<CloseRoundedIcon />}
            />
          );
        });
      }
    });
  };
  
  const createFilterChips = (appliedFilters) => {
    return Object.keys(appliedFilters)?.map((appliedFilterName) => {
      const values = appliedFilters[appliedFilterName];
      if (Array.isArray(values)) {
        // multiple values
        return values?.map((value) => {
          return chipForSingleFilter(value);
        });
      } else {
        // single values only
        return chipForSingleFilter(values);
      }
    });
  };

  const renderFilters = () => {
    total = 0;
    if (!appliedFilters || Object.keys(appliedFilters[0])?.length === 0) {
      return <></>;
    }
    return (
      <Grid
        container
        columns={{ xs: 4, sm: 6, md: 12 }}
        style={{ marginTop: "1rem" }}
      >
        <Grid
          item
          xs={10}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Typography
            className="appliedFilters-label"
            id="appliedFilters-searchParams-label"
            sx={{ p: 0, pr: "1rem", display: "inline-flex", my: "5px" }}
          >
            Filters:
          </Typography>
          {createFilterChips(appliedFilters)}
        </Grid>
        <Grid item xs={2} textAlign="right">
          <WarningAmberRounded sx={{ color: "#FED4A3" }} /> 
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={clearAllFilters}
            startIcon={<CloseRoundedIcon />}
          >
            Clear Filters
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
      <Grid item xs={4} sm={6} md={12}>
        {renderFilters()}
      </Grid>
    </Grid>
  );
};

DiscoveryAppliedFilters.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  customCols: PropTypes.array.isRequired,
  setCustomCols: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  setIsChanged: PropTypes.func.isRequired,
  allFiltersData: PropTypes.array.isRequired,
  setAllFiltersData: PropTypes.func.isRequired,
  setSelectedCol: PropTypes.func.isRequired,
};
export default DiscoveryAppliedFilters;
