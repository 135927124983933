import PropTypes from "prop-types";
import utils from "../../../utility/oryxUtils/utils";

import { FormControl, Grid } from "@mui/material";

const Keyword = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isHashtag,
}) => {
  const handleInfKeywordelection = (latestKeyword, textType) => {
    if (latestKeyword !== undefined) {
      let newPayload = payload;
      if (textType === "keyword") {
        newPayload["inf_keyword"] = latestKeyword;
        allFiltersData = utils.addObj(allFiltersData, "inf_keyword");
        allFiltersData[0]["inf_keyword"][0] = latestKeyword;
      } else {
        if (newPayload["inf_mention"] == null) {
          newPayload["inf_mention"] = [];
        }
        let mentionPayload = newPayload["inf_mention"];
        let mention = {
          value: latestKeyword,
          type: "mention",
          action: "should",
        };
        mentionPayload.push(mention);
        newPayload["inf_mention"] = mentionPayload;
        allFiltersData = utils.addObj(allFiltersData, "inf_mention");
        allFiltersData[0]["inf_mention"].push(mention);
      }
      setAllFiltersData(allFiltersData);
      setPayload(newPayload);
    }
  };

  const handleKeyDown = (e, value) => {
    if (e.key === "Enter") {
      handleInfKeywordelection(e.target.value, value);
    }
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <input
              type="text"
              onKeyDown={(e) =>
                handleKeyDown(e, isHashtag ? "mention" : "keyword")
              }
              placeholder={`Search in ${isHashtag ? "Hastags/mentions" : "keywords"}`}
              style={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                padding: "7px 12px",
                justifyContent: "flex-start",
                borderRadius: "6px",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Keyword.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Keyword;
