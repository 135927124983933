import { Grid, Paper, styled } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/internal/SignUp.module.css";
import PaymentDoneForm from "../components/account/PaymentDoneForm";
import TopBrands from "../components/landing/TopBrands";
import config from "../config/main.json"

const image = `${config.BASE_IMAGE_URL}manWithSpeaker.svg`;
const footerLogo = `${config.BASE_IMAGE_URL}Logos.svg`;


const Logo = styled("img")({});

function PaymentDone() {
  const [width, setWidth] = useState(0);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    updateWidth();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const card = (
    <>
      <br />
      <br />
      <div className={styles.card}>
        <Paper sx={{ width: 700, minHeight: 500, padding: 10, borderRadius: "8px" }}>
          <PaymentDoneForm />
        </Paper>
      </div>
    </>
  );

  return (
    <>
      {" "}
      <div ref={elementRef}>
        {width >= 1300 && (
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              {card}
            </Grid>
            <Grid item xs={3}>
              <div className={styles.image}>
                <img src={image} alt="img" />
              </div>
            </Grid>
          </Grid>
        )}
        {width < 1300 && card}
      </div>
      <TopBrands />
    </>
  );
}

export default PaymentDone;
