import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchHistoryDataOnType } from "../../../services/discovery";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Grid, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import ExportDropdown from "../components/ExportDropdown";
import { KeyboardBackspaceOutlined } from "@mui/icons-material";
import { snapshotHeaderIcons, textColor } from "../../../utility/plaformIcons";
import utils from "../../../utility/oryxUtils/utils";
import { useData } from "../../../redux/features/data/DataContext";
import { transformDiscoveryData } from "../../../utility/transformDiscoveryData";

const HistoryData = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const platforms = ["all", "instagram", "titkok", "youtube"];
  const [socialNetwork, setSocialNetwork] = useState("all");
  const [analyzeHistory, setAnalyzeHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getHistory(type, socialNetwork);
  }, [socialNetwork]);

  useEffect(() => {}, [analyzeHistory]);
  const getHistory = async (type, socialNetwork) => {
    setIsLoading(true);
    const response = await fetchHistoryDataOnType(type, socialNetwork.toLowerCase(), dispatch, user);
    const { status, data } = response?.response;
    if (status == "success") {
      if (data === null) {
        setIsLoading(false);
        setAnalyzeHistory([]);
      } else {
        setIsLoading(false);
        setAnalyzeHistory(data);
      }
    }
  };

  const reloadContent = (event) => {
    getHistory(type, socialNetwork);
  };

  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = (
        <Box width={"100%"}>
          {new Array(8).fill(0)?.map((item, index) => (
            <Skeleton key={index} variant="rectangular" height={50} width={"100%"} sx={{ mb: "1rem", borderRadius: "6px" }} />
          ))}
        </Box>
      );
    } else if (analyzeHistory?.length > 0) {
      content = (
        <Box>
          {analyzeHistory?.map((item, index) => (
            <DataRow key={`analyzeHistory-row-${index}`} data={item} type={type} reloadContent={reloadContent} />
          ))}
        </Box>
      );
    } else {
      content = (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            No Records Found
          </Typography>
        </Box>
      );
    }
    return content;
  };
  return (
    <>
      {" "}
      <Box sx={{ display: "flex", alignItems: "start" }} maxWidth={"1200px"} mx="auto">
        <Tooltip title="Go Back" placement="right">
          <IconButton
            sx={{
              color: "black",
              background: "white",
              border: "1px solid lightgrey",
              borderRadius: "9999px",
              "&:hover": { boxShadow: 2, background: "white" },
              whiteSpace: "nowrap",
              p: 1,
              textTransform: "none",
              justifyContent: "space-between",
              mr: "2rem",
              my: 1,
            }}
            onClick={() => {
              navigate(`/app/discovery?aligment=${type === "discovery" ? 1 : 0}`);
            }}
          >
            <KeyboardBackspaceOutlined />
          </IconButton>
        </Tooltip>
        <Box p={1} width={"100%"}>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              My History
            </Typography>
            <Box sx={{ display: "flex", aligItems: "center" }}>
              <ExportDropdown
                dropdownData={platforms}
                selectedValue={socialNetwork}
                setSelectedValue={setSocialNetwork}
                title={"Social Networks"}
              />
            </Box>
            <Box my={3}>{renderContentHandler()}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HistoryData;

const DataRow = ({ data, type, reloadContent }) => {
  const { setGlobalData } = useData();
  const navigate = useNavigate();
  const handleClick = async (data) => {
    if (type !== "discovery") {
      const { username, platform } = data;
      window.open(`/app/discovery/analyze/${platform?.toLowerCase()}/${username}`, "_blank");
    }
    // else {
    //   data.rawrequest["setFrom"] = "discovery"
    //   setGlobalData(transformDiscoveryData(data.rawrequest));
    //   navigate(`/app/discovery?aligment=1`);
    // }

    reloadContent(true);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 2,
          pb: 2,
          borderBottom: "1px solid black",
          cursor: `${type !== "discovery" && "pointer"}`,
        }}
        onClick={() => handleClick(data.request)}
      >
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Box ml="2px" fontSize={"10px"} sx={{ paddingTop: "10px" }}>
              <Typography sx={textColor[data.request?.platform]}>{snapshotHeaderIcons[data.request?.platform]}</Typography>
            </Box>{" "}
          </Grid>
          {type !== "discovery" && (
            <>
              <Grid item xs={1}>
                <Avatar sx={{ width: 56, height: 56 }} alt={data.request.name} src={data.request.photourl} />
              </Grid>

              <Grid item xs={9}>
                <Box ml="2px" fontSize={"18px"} sx={{ paddingTop: "10px" }}>
                  {data.request.name}
                </Box>
              </Grid>
            </>
          )}
          {type === "discovery" && (
            <Grid item xs={10}>
              <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", maxWidth: "45vw", ml: 14 }}>
                {data?.request.searchrequest && (
                  <>
                    {data?.request.searchrequest
                      .split(";")
                      ?.filter((item) => item !== " ")
                      ?.map((item, index) => (
                        <>
                          <Box
                            sx={{ mr: 1, background: "#ffffff", borderRadius: "9999px", p: "8px", my: "4px", boxShadow: 2 }}
                            key={`filter-text-${index}`}
                          >
                            <Typography sx={{ color: "#000000", whiteSpace: "nowrap", fontSize: "12px" }}>{item}</Typography>
                          </Box>
                        </>
                      ))}
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        <br />

        <Box width={"300px"}>
          <Typography sx={{ mr: 5, color: "#000000", whiteSpace: "nowrap" }}>
            {utils.convertDate(data.request?.dateofrequest)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}></Box>
        </Box>
      </Box>
    </>
  );
};
