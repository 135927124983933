import moment from "moment";

const utils = {
  /* number conversion based on international number system */
  convertToInternationalNumberSystem(labelValue, fixedPointOffset = 2) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(fixedPointOffset) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(fixedPointOffset) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(fixedPointOffset) + "K"
      : Math.abs(Number(labelValue));
  },
  /* return a string after capitalizing the first letter of given string */
  capitalizeFirstLetter(str, keepRemainingLowerCase = true) {
    const wordsArray = keepRemainingLowerCase
      ? str?.toLowerCase().split(/\s+/)
      : str?.split(/\s+/);
    const upperCased = wordsArray?.map(function (word) {
      return word?.charAt(0).toUpperCase() + word?.substr(1);
    });
    return upperCased?.join(" ");
  },
  checkIsObjectEmpty: (obj) => {
    for (let i in obj) return false;
    return true;
  },
  addObj: (exitsingObj, newObj) => {
    return exitsingObj?.map((obj) => {
      if (!obj.hasOwnProperty(newObj)) {
        return { ...obj, [newObj]: [] };
      }
      return obj;
    });
  },
  removeElement: (array, element) => {
    let index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  },
  getValueByKey: (data, value, match, key) => {
    if (value === undefined || value === "") {
      return "";
    }
    return data[Object.keys(data).find((key) => data[key][match] === value)][
      key
    ];
  },
  getKeyByValue: (data, value) => {
    return Object.keys(data).find((key) => data[key] === value);
  },
  // convert timestamp to date & time string (e.g --> 11 Jan 2023, 12:00 AM)
  convertDate: (timestamp) => {
    const date = new Date(timestamp);
    const locale = navigator.language;
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat(locale, options);
    const formattedDate = formatter.format(date);
    return formattedDate;
  },
  convertDateMoment: (date, type) => {
    let m = moment(date, "YYYY-MM-DD HH:mm:ss");
    if (date === "") {
      return "";
    }
    if (type === "withtime") {
      return m.format("ddd MMM D YYYY, HH:mm:ss");
    } else if (type === "datetime") {
      return moment(date).format("MMM D YYYY, HH:mm:ss");
    } else if (type === "onlydate") {
      return moment(date).format("MMM D YYYY");
    } else if (type === "normal") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return m.format("ddd MMM D YYYY");
  },

};




export default utils;
