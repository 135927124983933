import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";
import { reachObjectives } from "./FormConstants";
import { getFocusAreas } from "../../services/campaignBrief";

function Step2Reach({ setValid, step2Values, setStep2Values }) {
  const [objective, setObjective] = useState(step2Values.objective);
  const [focus, setFocus] = useState(step2Values.focus);
  const [focusAreas, setFocusAreas] = useState([]);
  const [goals, setGoals] = useState(step2Values.goals);

  const getFocusAreaData = async () => {
    const focusAreasData = await getFocusAreas();

    if (focusAreasData.status) {
      setFocusAreas(focusAreasData.response);
    }
  };

  useEffect(() => {
    getFocusAreaData();
  }, []);

  useEffect(() => {
    let valid = false;
    if (objective !== "" && focus?.length > 0) {
      valid = true;
    }
    setValid(valid);
  }, [objective, focus, setValid]);

  useEffect(() => {
    const stepValues = {};
    stepValues.objective = objective;
    stepValues.focus = focus;
    stepValues.goals = goals;
    setStep2Values(stepValues);
  }, [objective, focus, goals, setStep2Values]);

  return (
    <>
      <div className={styles.step_title}>Campaign Brief</div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.step_field}>
            What is the main objective of your campaign?*
          </div>
          <Box
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          >
            <List
              sx={{
                height: "120px",
                overflow: "auto",
                ".Mui-selected": {
                  background: "#C4C4C4 !important",
                },
                ".Mui-selected:hover": {
                  background: "rgba(0, 0, 0, 0.2) !important",
                },
                "&::-webkit-scrollbar": {
                  width: "12px",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  backgroundColor: "rgba(0,0,0,.08)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,.24)",
                },
              }}
              dense
              component="div"
              role="list"
            >
              {reachObjectives?.map((value) => {
                return (
                  <ListItem
                    key={value}
                    button
                    selected={value === objective}
                    onClick={() => setObjective(value)}
                  >
                    <ListItemText primary={value} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Area of Focus*</div>
          <Autocomplete
            size="small"
            multiple
            limitTags={1}
            options={focusAreas}
            disableCloseOnSelect
            value={focus}
            onChange={(e, val) => setFocus(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "20px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <div className={styles.step_field}>Program/Organizational Goals</div>
          <TextField
            size="small"
            multiline
            rows={4}
            inputProps={{
              maxLength: 140,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "46px",
              },
              "& .MuiInputBase-input": {
                margin: "15px",
              },
              "& .MuiFormHelperText-root": {
                textAlign: "right",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            value={goals}
            onChange={(val) => setGoals(val.target.value)}
            helperText={`${goals?.length}/${140}`}
          />
        </Grid>
      </Grid>
    </>
  );
}

Step2Reach.propTypes = {
  setValid: PropTypes.func.isRequired,
  step2Values: PropTypes.object.isRequired,
  setStep2Values: PropTypes.func.isRequired,
};

export default Step2Reach;
