import { Card, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { capitalizeFirstLetter, convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import { PlatformAttributeMap } from "./PlatformAttributeMap";
import React from "react";

const SummaryCard = ({ platformCount, platform }) => {
  return (
    <Card style={{ padding: "30px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={textColor[platform]} variant="h6" component="div" display="flex" alignItems="center">
            {snapshotHeaderIcons[platform]}
            <span style={{ marginLeft: "8px" }}>{capitalizeFirstLetter(platform)}</span>
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {PlatformAttributeMap[platform]?.map((attribute, index) => (
            <Grid item xs={4} key={index} style={{ textAlign: "center" }}>
              <Typography className={styles.engagementCount} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {convertToInternationalNumberSystem(platformCount[attribute?.attribute])}
                <span style={{ marginLeft: "4px" }}>{attribute?.icon}</span>
              </Typography>
              <Typography className={styles.engagementText} style={{ marginTop: "4px",marginRight:'20px' }}>
                {capitalizeFirstLetter(attribute?.attribute)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

SummaryCard.propTypes = {
  platformCount: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
};

export default SummaryCard;
