import { Cached } from "@mui/icons-material";
import { Autocomplete, Box, Button, Grid, Stack, TextField, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { useLocation } from "react-router-dom";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import ExportFiles from "../../../common/exportfiles";
import GroupDropdown from "../../../common/groupDropdown";
import PlatformSelectionBox from "../../../common/platformSelectionBox";
import SearchItem from "../../../common/searchitem";
import SelectColumns from "../../../common/selectColumns";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../common/StyledComponents";
import { UpgradePlanToolTipText } from "../../../common/toolTip";

const CampaignInfluencerFilters = ({
  changeHeaders,
  tableHeaders,
  allSelectedPlatforms,
  allSelectedGroups,
  handleFilterChange,
  lastRefreshed,
  sendRefresh,
  sendInformation,
  fileName,
  pageNo,
  rowsPerPageNo,
  sortOrderBy,
  sortOrder,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const menu = useSelector((state) => state.menu); //get loggedIn menu state
  const influencerPermissions = menu.permissions?.Campaigns?.Fields?.Influencers;

  const dispatch = useDispatch();

  const [selectedGroups, setSelectedGroups] = React.useState(allSelectedGroups);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState(allSelectedPlatforms);
  const [groupList, setGroupList] = React.useState([]);
  const [lastPostedStatus, setLastPostedStatus] = React.useState("All");
  const lastPostedStatusList = ["All", "Posted", "Not Posted"];
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  React.useEffect(() => {
    setSelectedPlatforms(allSelectedPlatforms);
    setSelectedGroups(allSelectedGroups);
    getGroupList("all");
  }, [lastRefreshed]);

  React.useEffect(() => {}, [allSelectedGroups]);

  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(params.get("id"), platforms, user, dispatch);
    if (groups.status === true) {
      setGroupList(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const updateHeaders = (event) => {
    changeHeaders(event);
  };

  const sendSearchedValue = (event) => {};

  const sendSelectedGroups = (event) => {
    setSelectedGroups(event);
  };
  const sendSelectedPlatforms = (event) => {
    if (event?.length > 0) {
      getGroupList(event);
    } else {
      getGroupList("all");
    }
    setSelectedPlatforms(event);
  };
  const sendExportSelected = (event) => {
    sendInformation({
      export: 1,
      filter: "",
      mimeType: event,
      fileName: fileName,
      page: pageNo,
      rowsPerPage: rowsPerPageNo,
      orderBy: sortOrderBy,
      order: sortOrder,
      lastPostedStatus
    });
  };

  const sendToParent = () => {
    handleFilterChange({ selectedPlatforms, selectedGroups, lastPostedStatus });
  };

  const refreshInfluencers = () => {
    sendRefresh(true);
  };
  return (
    <Box my={3} sx={{ width: "100%" }}>
      {influencerPermissions.AddInfluencer.Visible && (
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Tooltip title={UpgradePlanToolTipText()}>
              <StyledStaticButtonGroup
                color="primary"
                exclusive
                size="small"
                aria-label="Campaign"
              >
                <StyledStaticButton
                  value="active"
                  className={styles.staticButton}
                  disabled={true}
                >
                  <b>+ Add Influencer</b>
                </StyledStaticButton>
              </StyledStaticButtonGroup>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Box mt={2}>
      <Grid
          item
          container
          spacing={2}
          justifyContent= "flex-end"
          sx={{ flexGrow: 1 }}
        >
          <Grid item md={4}>
            <PlatformSelectionBox
              sendSelectedPlatforms={sendSelectedPlatforms}
              parentPlatforms={selectedPlatforms}
            />
          </Grid>
          <Grid item md={3}>
            <GroupDropdown
              groupList={groupList}
              sendSelectedGroups={sendSelectedGroups}
              parentSelectedGroup={selectedGroups}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue) => {
                setLastPostedStatus(newValue);
              }}
              value={lastPostedStatus}
              options={lastPostedStatusList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Select Posted Status"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      minWidth: "150px",
                      borderRadius: "10px",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                background:
                  "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                borderRadius: "8px",
              }}
              onClick={sendToParent}
            >
              Go
            </Button>
          </Grid>
          <Grid item>
            <SelectColumns
              tableHeaders={tableHeaders}
              updateHeaders={updateHeaders}
            />
          </Grid>
          <Grid item>
            <ExportFiles sendExportSelected={sendExportSelected} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

CampaignInfluencerFilters.propTypes = {
  changeHeaders: PropTypes.func.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  allSelectedPlatforms: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  lastRefreshed: PropTypes.string.isRequired,
  sendRefresh: PropTypes.bool.isRequired,
  sendInformation: PropTypes.func.isRequired,
  mimeType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default CampaignInfluencerFilters;
