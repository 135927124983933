export const FilterTooltips = {
  location: {
    audTooltipMsg: "Find influencers by audience location. Add locations and specify minimum percentages for targeted results.",
    infTooltipMsg: "Identify influencers by their location using tags, language, and captions. Add locations for refined results."
  },
  language: {
    audTooltipMsg: "Find influencers by audience language, determined through recent post captions.",
    infTooltipMsg: "Identify influencers by location through tags and language in their posts."
  },
  brand: {
    audTooltipMsg: "Filter influencers by audience brand affinities, analyzing captions and hashtags. Add brands for targeted results.",
    infTooltipMsg: "Find influencers with brand affinities, refining results by analyzing post content."
  },
  parntership: "Identify influencers with sponsored posts using commercial hashtags or paid partnership tags.",
  interests: {
    audTooltipMsg: "Target influencers by audience interests from post content. Add interests for refined search.",
    infTooltipMsg: "Find influencers by their interests using post analysis."
  },
  gender: {
    audTooltipMsg: "Determine influencers by audience gender via profile and post analysis.",
    infTooltipMsg: "Identify influencers by gender through profile and post details."
  },
  ethinicity: "Filter influencers by audience ethnicity using profile photos.",
  age: {
    audTooltipMsg: "Identify influencers by audience age, analyzed through profile photos.",
    infTooltipMsg: "Find influencers by age through recent post analysis."
  },
  audienceType: "Switch to look at a specific audience segment.",
  lookalikes: {
    audTooltipMsg: "Find influencers with similar audiences using Audience Lookalikes.",
    infTooltipMsg: "Discover influencers with similar content using TopicTensor® technology."
  },
  bio: "Identify influencers by bio keywords.",
  keyword: {
    keyword: "Find influencers by specific keywords in their posts.",
    mention: "Search influencers by the mentions in their posts."
  },
  follower: "Filter influencers by follower count.",
  view: {},
  engagement: {
    audTooltipMsg: "Identify influencers by engagement numbers.",
    infTooltipMsg: "Filter by engagement rate, calculated from recent likes and followers."
  },
  reelPlay: "Find influencers by average reel play count.",
  contact: "Refine searches to influencers with contact details.",
  lastPost: "Identify influencers based on their last post date.",
  accountType: "Filter influencers by Instagram account type (Business, Creator, or regular).",
  views: "Target influencers by audience age and views from posts.",
  followersGrowth: {}
};
