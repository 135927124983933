import { PropTypes } from "prop-types";
import ReactEcharts from "echarts-for-react";
import { StackedBarChart, DoughnutChart, BasicLineChart } from "../../../common/chart";
import { Card, Grid } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";

const CampaignDynamicLinkGraphs = ({ linkClicksY, linkClicksX, conversionsData, appInstallX, appInstallY}) => {
  return (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>Total Engagement </b>
          </h4>
          {linkClicksY?.length > 0 && linkClicksX?.length > 0 ? (
            <ReactEcharts
              option={StackedBarChart("",linkClicksX, true, linkClicksY)}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>Clicks & Conversions </b>
          </h4>
          {conversionsData?.length > 0 ? (
            <ReactEcharts
              option={DoughnutChart(
                "Clicks & Conversions",
                ["#5368F0", "#007AFF", "#00FF00", "#FFD700", "#87CEFA"],
                "vertical",
                10,
                50,
                conversionsData,
                ["40%", "70%"],
                false,
                false,
                true
              )}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card style={{ padding: 20 }}>
          <h4>
            <b className={styles.title}>App Installs </b>
          </h4>
          {appInstallX?.length > 0 && appInstallY?.length > 0 ? (
            <ReactEcharts
              option={BasicLineChart(
                "App Installs",
                appInstallX,
                appInstallY,
                "vertical"
              )}
              style={{ height: "400px", width: "100%" }}
            />
          ) : (
            <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              No Graph found
            </p>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

CampaignDynamicLinkGraphs.propTypes = {
  linkClicksY: PropTypes.array.isRequired,
  linkClicksX: PropTypes.array.isRequired,
  conversionsData: PropTypes.array.isRequired
};

export default CampaignDynamicLinkGraphs;
