import { Checkbox, FormControl, IconButton, ListItemText, MenuItem, Select, Tooltip } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import PropTypes from "prop-types";
import React from "react";

const SelectColumns = ({ tableHeaders, updateHeaders }) => {
  const [selectedColumns, setSelectedColumns] = React.useState(
    tableHeaders?.map((value) => {
      if (value.show) {
        return value?.mappedValue;
      }
      return null;
    }).filter(Boolean)
  );

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const chooseColumn = (event) => {
    const {
      target: { value },
    } = event;
    const headers = tableHeaders?.filter((header) => {
      header.show = value.includes(header?.mappedValue);
      return value.includes(header?.mappedValue);
    });
    updateHeaders(headers);
    setSelectedColumns(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormControl fullWidth size="small">
      <Tooltip title="Select Columns" placement="top">
        <IconButton
          onClick={handleToggle}
          sx={{
            border: "1px solid",  // Border around the button
            borderColor: "rgba(0, 0, 0, 0.23)",  // Border color
            borderRadius: "8px",  // Border radius to keep it square
            padding: "8px",       // Padding inside the button
            width: "48px",        // Square dimensions
            height: "40px",       // Square dimensions
          }}
        >
          <TuneIcon />
        </IconButton>
      </Tooltip>
      <Select
        id="demo-simple-select"
        sx={{
          display: "none", // Hide the Select dropdown trigger
        }}
        value={selectedColumns}
        onChange={chooseColumn}
        open={open}
        onClose={handleClose}
        MenuProps={{
          anchorEl: anchorEl,
          open: open,
          onClose: handleClose,
          PaperProps: {
            style: {
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            },
          },
        }}
        multiple
        renderValue={(selected) => selected.join(", ")}
      >
        {tableHeaders?.map((header) => (
          header?.mappedValue?.length > 0 && (
            <MenuItem value={header?.mappedValue} key={header?.mappedValue}>
              <Checkbox checked={selectedColumns.indexOf(header?.mappedValue) > -1} />
              <ListItemText primary={header?.mappedValue} />
            </MenuItem>
          )
        ))}
      </Select>
    </FormControl>
  );
};

SelectColumns.propTypes = {
  tableHeaders: PropTypes.array.isRequired,
  updateHeaders: PropTypes.func.isRequired,
};

export default SelectColumns;
