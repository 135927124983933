import {
  Autocomplete,
  Box,
  Backdrop,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "../../../../../styles/internal/Campaign/CreateNewCampaign.css";
import React from "react";
import {
  createContract,
  getAllContractTemplates,
  getContractInfluencers,
  getListsForTemplate,
} from "../../../../../services/contracts";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { snapshotHeaderIcons, textColor } from "../../../../../utility/plaformIcons";
import TransferList from "../../../../common/TransferList";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../../common/StyledComponents";
import { notify } from "../../../../../redux/features/system/systemAlert";
import configs from "../../../../../config/main.json";
import { useData } from "../../../../../redux/features/data/DataContext";
import { Close } from "@mui/icons-material";
import CreateTemplate from "./CreateTemplate";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

const SendContract = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const [contractTemplates, setContractTemplates] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);

  const [state, setState] = React.useState({
    template: "",
    list: { name: "" },
    showTransfer: false,
    selectedCount: 0,
  });

  //adding notification comment
  const notificationMetadata = {
    userId: "",
    sourcePlatform: configs.PLATFORM_NAME,
    notificationTypeId: "4",
    notificationActionId: "2",
    notificationCategoryId: "7",
    generatedBy: user.email,
    priority: "medium",
    consumedBy: "gazelle",
    navigateTo: "/account/contracts",
  };
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, setGlobalData } = useData();
  const [fromCampaignCreation, setFromCampaignCreation] = React.useState(false);

  React.useEffect(() => {
    getTemplates();
    getLists();
  }, []);

  React.useEffect(() => {
    if (data) {
      setFromCampaignCreation(true);
    }
    setState({
      ...state,
      selectedCount: right?.filter((inf) => !inf.disabled)?.map((inf) => inf.id)?.length,
    });
  }, [right]);

  const getTemplates = async () => {
    const response = await getAllContractTemplates(campaignId, user, dispatch);
    setContractTemplates(response?.response);
  };

  const getLists = async () => {
    const response = await getListsForTemplate(campaignId, user, dispatch);
    setLists(response?.response);
  };

  const getInfluencers = async (campaignId, template, listId) => {
    const response = await getContractInfluencers(campaignId, template, listId, user, dispatch);
    const influencers = response?.response?.length > 0 ? response?.response : [];
    const left = [];
    const right = [];
    influencers.forEach((influencer) => {
      const inf = {};
      inf["id"] = influencer.id;
      if (influencer.infName === "") {
        return;
      }
      inf["label"] = influencer.infName;
      inf["disabled"] = false;
      if (influencer.status === "") {
        left.push(inf);
      } else if (influencer.status === "Expired") {
        inf["label"] += " (Expired)";
        left.push(inf);
      } else if (["Pending", "Accepted"].includes(influencer.status)) {
        inf["label"] += ` (${influencer.status})`;
        inf["disabled"] = true;
        right.push(inf);
      }
    });

    setLeft(left);
    setRight(right);
  };

  const sendContract = async () => {
    dispatch(
      notify({
        message: `Sending contract to ${state.selectedCount} influencer${state.selectedCount > 1 ? "s" : ""}`,
        type: "info",
      })
    );
    const selectedInfluencers = right?.filter((inf) => !inf.disabled);

    if (selectedInfluencers?.length === 0) return;
    const body = {};
    body["cid"] = +campaignId;
    body["tid"] = state.template;
    body["lid"] = state.list.id;
    body["inf"] = selectedInfluencers?.map((inf) => {
      const res = {};
      notificationMetadata.userId = inf.id.toString();
      res.iid = inf.id;
      res.tags = {};
      return res;
    });
    body["notificationMetadata"] = notificationMetadata;

    const response = await createContract(body, user, dispatch);
    if (response?.response.status === "success") {
      dispatch(
        notify({
          message: `Contract sent to ${state.selectedCount} influencer${state.selectedCount > 1 ? "s" : ""}`,
          type: "success",
        })
      );
      navigate(`/app/campaigns/influencers/contracts?id=${campaignId}`);
    }
  };
  const goToPreviousStep = () => {
    //this will change to edit
    navigate(`/app/discovery`);
  };

  const goToAnalytics = () => {
    if (data) {
      setGlobalData(null);
    }
    navigate(`/app/campaigns/overview?id=${campaignId}&universal=false`);
  };

  const handleTemplate = (e) => {
    if (e.target.value === "create-template") {
      setCreateTemplateModal(true);
    } else {
      setState({ ...state, template: e.target.value });
    }
  };

  const handleCloseModal = () => {
    setCreateTemplateModal(false)
  }

  const handleClose = () => navigate(`/app/campaigns/influencers/contracts?id=${campaignId}&tabId=0`)
  return (
    <>
      {/* {fromCampaignCreation && (
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item md={6}>
            <Grid container item spacing={4}>
              <Grid item md={6}>
                <Button
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: "40px",
                    color: "#474747",
                    borderColor: "#474747",
                    borderRadius: "b6px",
                  }}
                  onClick={(e) => goToPreviousStep()}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    height: "40px",
                    background: "#474747",
                    color: "white",
                    borderColor: "#474747",
                    borderRadius: "b6px",
                  }}
                  onClick={goToAnalytics}
                  // disabled={!state.count || state.kpi?.length === 0 || state.kpiValue?.length === 0}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )} */}
      <Box style={{ backgroundColor: "#FAFAFB", padding: "10px" }}>
        <Box display="flex" justifyContent="flex-end" style={{ paddingRight: "10px" }}>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box m={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ padding: "0px" }}>
                <h4 className={styles.header}>Select contract recipients from left box</h4>
                <Divider />

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="template">Select or Create Template</InputLabel>
                      <Select
                        required={true}
                        variant="outlined"
                        label="Select or Create Template"
                        id="template"
                        value={state.template}
                        onChange={handleTemplate}
                      >
                        <MenuItem value="create-template">
                          <b>+ Create Template</b>
                        </MenuItem>
                        {contractTemplates?.map((template) => (
                          <MenuItem key={template.id} value={template.id}>
                            {template.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.name}
                        options={lists ? lists : []}
                        value={state.list}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                            &nbsp;
                            {option.name}
                          </Box>
                        )}
                        onChange={(e, value) => {
                          getInfluencers(campaignId, state.template, value.id);
                          setState({ ...state, list: value, showTransfer: true });
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Group" />}
                      />
                    </FormControl>
                  </Grid>
                  {state.showTransfer && (
                    <Grid item xs={12} sm={12} style={{ marginTop: "1rem" }}>
                      <FormControl fullWidth>
                        <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} />
                      </FormControl>
                      <FormHelperText style={{ marginTop: "1rem", marginLeft: "5.3rem" }}>
                        * Only influencers registered on the aifluence.co platform will be included in the available list.                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>

            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box sx={{ mt: 4, ml: 2 }}>
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "38px",
                      color: "#474747",
                      borderColor: "#474747",
                      borderRadius: "11px",
                    }}
                    onClick={handleClose}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ mt: 4 }}>
                  <Tooltip title={state.selectedCount === 0 ? "Select at least 1 influencer" : ""}>
                    <StyledStaticButtonGroup color="primary" size="small" exclusive aria-label="Campaign">
                      <StyledStaticButton value="active" className={styles.staticButton} onClick={sendContract} disabled={!state.selectedCount}>
                        <b>Send Contract</b>
                      </StyledStaticButton>
                    </StyledStaticButtonGroup>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={createTemplateModal}
        // closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Fade in={createTemplateModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 800,
              // minWidth: 400,
              bgcolor: "background.paper",
              borderRadius: "15px",
              boxShadow: 24,
              p: 0,
            }}
            style={{
              maxWidth: "900px",
              width: '100%',
              maxHeight: '90%',
              overflowY: 'auto'
            }}
          >
            <CreateTemplate
              fromSendContract={true}
              handleCloseModal={handleCloseModal}
              getTemplates={getTemplates}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SendContract;
