import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress, Card, Typography, Button, Tooltip } from "@mui/material";
import { getBasicDetails, getInfluencerDetails, getPublicationDetails, getAnalytics, getTopPosts, getReportHistory } from "../../services/dashboardService";
import { getCampaignList } from "../../services/campaigns";
import { getTimeZone } from "../../services/common.js";
import DashboardMetrics from "./DashboardMetrics";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import CustomIcon from './common/customIcon.js';
import { checkForCredit } from "../../services/credit.js";
import { UpgradePlanToolTipText } from "../common/toolTip.js";
import moment from 'moment';


const CustomTypography = styled(Typography)({
    fontSize: "1rem",
    fontWeight: 700,
    color: "#000",
    textAlign: "center",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const CustomButton = styled(Button)({
    marginTop: "10px",
    width: "181px",
    borderRadius: "10px",
    height: '32px'
});

const StyledCard = styled(Card)({
    width: "294.02px",
    height: "110px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    textAlign: "center",
});

const Home = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [basicDetails, setBasicDetails] = useState({});
    const [influencerDetails, setInfluencerDetails] = useState({});
    const [publicationDetails, setPublicationDetails] = useState({});
    const [analytics, setAnalytics] = useState({});
    const [campaignList, setCampaignList] = useState([]);
    const [demoCampaign, setDemoCampaign] = useState(null);
    const [topPosts, setTopPosts] = useState([]);
    const [reportHistory, setReportHistory] = useState([]);
    const [demoReport, setDemoReport] = useState(null);
    const [selectedInfluencerDateRange, setSelectedInfluencerDateRange] = useState("Last 6 Months");
    const [selectedPublicationDateRange, setSelectedPublicationDateRange] = useState("Last 6 Months");
    const [selectedAnalyticsDateRange, setSelectedAnalyticsDateRange] = useState("Last 6 Months");
    const [credit, setCredit] = useState(0);
    const menuData = useSelector((state) => state.menu);
    const campaignPermissions = menuData.permissions.Campaigns;
    const [userInviteCredit, setUserInviteCredit] = useState(0);


    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const startDate = moment().subtract(6, "months").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    const fetchBasicDetails = async () => {
        const data = { id: String(user.agencyId) };
        const response = await getBasicDetails(data, user, dispatch);
        return response;
    };

    const fetchInfluencerDetails = async (dateRange) => {
        const influencerData = {
            id: String(user.agencyId),
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        };
        const response = await getInfluencerDetails(influencerData, user, dispatch);
        return response;
    };

    const fetchPublicationDetails = async (dateRange) => {
        const publicationData = {
            id: String(user.agencyId),
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        };
        const response = await getPublicationDetails(publicationData, user, dispatch);
        return response;
    };

    const fetchAnalytics = async (dateRange) => {
        const analyticsData = {
            id: String(user.agencyId),
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        };
        const response = await getAnalytics(analyticsData, user, dispatch);
        return response;
    };

    const fetchCampaignList = async () => {
        const campaignData = {
            account: user.agencyId,
            client: user.clientId,
            path: "Campaigns",
            type: 1,
            isExport: 0,
            page: 1,
            rows: 10,
            SortCol: "createdAt",
            SortOrder: "desc",
            filter: "",
            mimeType: "",
            timeZone: getTimeZone(),
        };
        const response = await getCampaignList(campaignData, user, dispatch);
        return response;
    };

    const fetchTopPosts = async () => {
        const response = await getTopPosts(String(user.agencyId), user, dispatch);
        return response;
    };

    const fetchReportHistory = async () => {
        const response = await getReportHistory(String(user.agencyId), user, dispatch);
        return response;
    };

    useEffect(() => {
        const fetchData = async () => {
            setInitialLoading(true);
            try {
                const [
                    basicRes,
                    topPostsRes,
                    campaignListRes,
                    reportHistoryRes,
                    influencerRes,
                    publicationRes,
                    analyticsRes
                ] = await Promise.all([
                    fetchBasicDetails(),
                    fetchTopPosts(),
                    fetchCampaignList(),
                    fetchReportHistory(),
                    fetchInfluencerDetails({ startDate, endDate }),
                    fetchPublicationDetails({ startDate, endDate }),
                    fetchAnalytics({ startDate, endDate })
                ]);

                setBasicDetails(basicRes.response);
                setTopPosts(topPostsRes.response);
                setCampaignList(campaignListRes.response?.campaignList?.slice(1, 6));
                setDemoCampaign(campaignListRes.response?.campaignList?.[0]);
                setReportHistory(reportHistoryRes.response?.filter(report => !report.universal));
                setDemoReport(reportHistoryRes.response?.find(report => report.universal));
                setInfluencerDetails(influencerRes?.response);
                setPublicationDetails(publicationRes?.response);
                setAnalytics(analyticsRes?.response);
            } catch (error) {
                console.error("Error during initial data fetching:", error);
            } finally {
                setInitialLoading(false);
            }
        };

        fetchData();
    }, [user, dispatch]);
    useEffect(() => {
        const checkForCreditValue = async () => {
            const data = {
                name: ["Campaigns", "Account.Invite New Users"],
                accountId: user.agencyId,
                clientId: user.clientId,
            };
            const response = await checkForCredit(data, user, dispatch);
            const message = response?.response.message;

            const campaignsCount = message["Campaigns"];
            const inviteCredits = message["Account.Invite New Users"];

            setCredit(isNaN(parseInt(campaignsCount)) ? 0 : parseInt(campaignsCount));
            setUserInviteCredit(isNaN(parseInt(inviteCredits)) ? 0 : parseInt(inviteCredits));
        };

        checkForCreditValue();
    }, [user, dispatch]);




    const handleInfluencerDateChange = async (newDateRange) => {
        setSelectedInfluencerDateRange(newDateRange);
        const response = await fetchInfluencerDetails(newDateRange);
        setInfluencerDetails(response.response);
    };

    const handlePublicationDateChange = async (newDateRange) => {
        setSelectedPublicationDateRange(newDateRange);
        const response = await fetchPublicationDetails(newDateRange);
        setPublicationDetails(response.response);
    };

    const handleAnalyticsDateChange = async (newDateRange) => {
        setSelectedAnalyticsDateRange(newDateRange);
        const response = await fetchAnalytics(newDateRange);
        setAnalytics(response.response);
    };

    if (initialLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Box padding={3}>
            <Grid container spacing={6} alignItems="center" justifyContent="center">
                {/* Top Buttons */}
                <Grid item xs={12} md={3}>
                    <StyledCard>
                        <CustomTypography variant="subtitle1">Find The Right Influencers</CustomTypography>
                        <CustomButton variant="contained" color="primary" onClick={() => navigate('/app/discovery')}>
                            Find Influencer
                        </CustomButton>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={3}>
                    <StyledCard>
                        <CustomTypography variant="subtitle1">Analyze Influencers</CustomTypography>
                        <CustomButton variant="contained" color="primary" onClick={() => navigate(`/app/discovery?aligment=${0}`)}>
                            Analyze Influencer
                        </CustomButton>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={3}>
                    <StyledCard>
                        <CustomTypography variant="subtitle1">Measure Campaign Performance</CustomTypography>
                        <Tooltip
                            title={
                                (!campaignPermissions?.NewCampaign?.Editable ||
                                    credit === 0 &&
                                    user.agencyId !== 0) &&
                                UpgradePlanToolTipText(!campaignPermissions?.NewCampaign?.Editable ? "" : "credit")
                            }
                        >
                            <span>
                                <CustomButton variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/app/campaigns/create')}
                                    disabled={!campaignPermissions?.NewCampaign?.Editable || (credit === 0 && user.agencyId !== 0)}
                                >
                                    Create Campaign
                                </CustomButton>
                            </span>
                        </Tooltip>
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={3}>
                    <StyledCard>
                        <CustomTypography variant="subtitle1">Collaborate with your Team</CustomTypography>
                        <Tooltip
                            title={
                                (userInviteCredit === 0 && user.agencyId !== 0)
                                    ? UpgradePlanToolTipText('credit')
                                    : ''
                            }
                        >
                            <span>
                                <CustomButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/app/account/inviteNewUsers')}
                                    disabled={userInviteCredit === 0 && user.agencyId !== 0}
                                >
                                    Onboard Users
                                </CustomButton>
                            </span>
                        </Tooltip>
                    </StyledCard>
                </Grid>
            </Grid>

            <Box mt={3} display="flex" alignItems="center" bgcolor="#fff" padding="12px" borderRadius="8px">
                <CustomIcon sx={{ marginRight: '10px' }} />
                <Box>
                    <Typography variant="h6" fontWeight={800} color="#7C7C7C" style={{ fontSize: '14px', display: 'inline' }}>
                        {" "} Welcome, {user.name}!
                    </Typography>
                    <Typography variant="h6" fontWeight={600} color="#7C7C7C" style={{ fontSize: '14px', display: 'inline' }}>
                        {" "} - your personalized home for daily real-time updates on your Campaign Performance, Key Metrics, Top Performers, and much more.
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <DashboardMetrics
                    basicDetails={basicDetails}
                    influencerDetails={influencerDetails}
                    publicationDetails={publicationDetails}
                    analytics={analytics}
                    campaignList={campaignList}
                    demoCampaign={demoCampaign}
                    topPosts={topPosts}
                    reportHistory={reportHistory}
                    demoReport={demoReport}
                    selectedInfluencerDateRange={selectedInfluencerDateRange}
                    onInfluencerDateChange={handleInfluencerDateChange}
                    selectedPublicationDateRange={selectedPublicationDateRange}
                    onPublicationDateChange={handlePublicationDateChange}
                    selectedAnalyticsDateRange={selectedAnalyticsDateRange}
                    onAnalyticsDateChange={handleAnalyticsDateChange}
                />
            </Grid>
        </Box>
    );
};

export default Home;
