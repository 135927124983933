import { Business } from "@mui/icons-material";
import { Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../styles/internal/CampaignBrief/CampaignBriefForm.module.css";
import PropTypes from "prop-types";
import { getFutureDate } from "../../utility/momentManipulations";

function Step1({ setValid, step1Values, setStep1Values, isReadOnly }) {
  const [organisation, setOrganisation] = useState(step1Values.organisation);
  const [organisationType, setOrganisationType] = useState(step1Values.organisationType);
  const [start, setStart] = useState(step1Values.start);
  const [startDate, setStartDate] = useState(step1Values.startDate);
  const [summary, setSummary] = useState(step1Values.summary);

  const minDate = getFutureDate(new Date(), 1, "months").split("T")[0];
  const maxDate = getFutureDate(new Date(), 3, "months").split("T")[0];

  useEffect(() => {
    let valid = false;
    if (organisation !== "") {
      valid = true;
    }
    setValid(valid);
  }, [organisation, setValid]);

  useEffect(() => {
    const stepValues = {};
    stepValues.organisation = organisation;
    stepValues.organisationType = organisationType;
    stepValues.start = start;
    if (start === "In 1 to 3 months") {
      stepValues.startDate = startDate;
    }
    stepValues.summary = summary;
    setStep1Values(stepValues);
  }, [organisation, organisationType, start, startDate, summary, setStep1Values]);

  return (
    <>
      <div className={styles.step_title}>Campaign Overview</div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={styles.step_field}>Organisation*</div>
          {isReadOnly && (
            <TextField
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #EFF0F7",
                  boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                  borderRadius: "46px",
                },
                fieldset: {
                  legend: {
                    width: "unset",
                  },
                },
                width: "100%",
              }}
              value={organisation}
              onChange={(val) => setOrganisation(val.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Business />
                  </InputAdornment>
                ),
              }}
              disabled={true}
            />
          )}
          {!isReadOnly && (
            <TextField
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #EFF0F7",
                  boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                  borderRadius: "46px",
                },
                fieldset: {
                  legend: {
                    width: "unset",
                  },
                },
                width: "100%",
              }}
              value={organisation}
              onChange={(val) => setOrganisation(val.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Business />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>Organisation Type*</div>
          <Select
            size="small"
            sx={{
              width: "100%",
              border: "1px solid #EFF0F7",
              boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
              borderRadius: "46px",
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            value={organisationType}
            onChange={(val) => setOrganisationType(val.target.value)}
          >
            <MenuItem value={"Product/Service or Brand(s)"}>Product/Service or Brand(s)</MenuItem>
            <MenuItem value={"Marketing Agency"}>Marketing Agency</MenuItem>
            <MenuItem value={"NGO/NPO, or a Donor"}>NGO, UN Agency or a Donor</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <div className={styles.step_field}>When do you want to start?*</div>
          <Select
            size="small"
            sx={{
              width: "100%",
              border: "1px solid #EFF0F7",
              boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
              borderRadius: "46px",
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
            }}
            value={start}
            onChange={(val) => setStart(val.target.value)}
          >
            <MenuItem value={"As soon as possible"}>As soon as possible</MenuItem>
            <MenuItem value={"In 1 to 3 months"}>In 1 to 3 months</MenuItem>
            <MenuItem value={"In more than 3 months"}>In more than 3 months</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          {start === "In 1 to 3 months" && (
            <>
              <div className={styles.step_field}>Target Start Date</div>
              <TextField
                size="small"
                type="date"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #EFF0F7",
                    boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                    borderRadius: "46px",
                  },
                  fieldset: {
                    legend: {
                      width: "unset",
                    },
                  },
                  width: "100%",
                }}
                inputProps={{
                  min: minDate,
                  max: maxDate,
                }}
                value={startDate}
                onChange={(val) => setStartDate(val.target.value)}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <div className={styles.step_field}>Campaign Summary</div>
          <TextField
            size="small"
            multiline
            rows={4}
            inputProps={{
              maxLength: 255,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #EFF0F7",
                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                borderRadius: "46px",
              },
              "& .MuiInputBase-input": {
                margin: "15px",
              },
              "& .MuiFormHelperText-root": {
                textAlign: "right",
              },
              fieldset: {
                legend: {
                  width: "unset",
                },
              },
              width: "100%",
            }}
            value={summary}
            onChange={(val) => setSummary(val.target.value)}
            helperText={`${summary?.length}/${255}`}
          />
        </Grid>
      </Grid>
    </>
  );
}

Step1.propTypes = {
  setValid: PropTypes.func.isRequired,
  step1Values: PropTypes.object.isRequired,
  setStep1Values: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default Step1;
