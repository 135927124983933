import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router";

function ResponsiveAppBarMenu({ page }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (page.expandable) {
      setAnchorEl(anchorEl === null ? event.currentTarget : null);
    } else {
      if (page.external) {
        window.open(page.link, '_blank');
      } else {
        navigate(page.link);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        key={page.title}
        sx={{ my: 2, color: "black", display: "block" }}
        onClick={handleClick}
      >
        {page.title}
        {page.expandable && (
          <span>
            &nbsp;
            <KeyboardArrowDown style={{ color: "#313131" }} />
          </span>
        )}
      </Button>
      {page.expandable && (
        <Box>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {page.options?.map((option) => (
              <MenuItem key={option.link} onClick={handleClose}>
                {option.link.startsWith('http') ? (
                  <a
                    href={option.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {option.title}
                  </a>
                ) : (
                  <a
                    href={option.link}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(option.link); 
                    }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {option.title}
                  </a>
                )}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </>
  );
}

export default ResponsiveAppBarMenu;
