import { Box, Grid } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyledToggleButton, StyledToggleButtonGroup } from "../common/StyledComponents";
import styles from "../../styles/internal/Campaign/CampaignList.module.css";
import Discovery from "./Discovery";
import Analyze from "./AnalyzeInfluencer";
import BlockLoginAccess from "../common/BlockLoginAccess";

const DiscoveryBase = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [alignment, setAlignment] = useState(params.get("aligment") || "1"); //1 for active and 0 for archieved campaigns

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          <Box my={3} sx={{ width: "100%" }}>
            <Grid container spacing={5}>
              <Grid item md={10} xs={12} sm={12}>
                {/* Toggle for Archieved and Active Campaign */}
                <StyledToggleButtonGroup
                  color="primary"
                  size="small"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Discovery"
                >
                  <StyledToggleButton value="1" className={styles.toggleButton} disabled={alignment === "1"}>
                    Discover Influencers
                  </StyledToggleButton>
                  <StyledToggleButton value="0" className={styles.toggleButton} disabled={alignment === "0"}>
                    Analyze Influencers
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </Grid>
            </Grid>
            {/* Refresh, Select Column and Export As */}
          </Box>
          {alignment === "1" && <Discovery />}
          {alignment === "0" && <Analyze />}
        </>
      )}
      {width <= 768 && <BlockLoginAccess openDialog={true} />}
    </div>
  );
};

export default DiscoveryBase;
