import { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import {
  FormControl,
  Grid,
  Autocomplete,
  Box,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import utils from "../../../utility/oryxUtils/utils";

const FollowerGrowth = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  isChanged,
  setIsChanged,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [allFollowerGrowth] = useState([
    { value: "i1month", name: "1 month" },
    { value: "i2months", name: "2 months" },
    { value: "i3months", name: "3 months" },
    { value: "i4months", name: "4 months" },
    { value: "i5months", name: "5 months" },
    { value: "i6months", name: "6 months" },
  ]);
  const [percentage] = useState([
    { value: ">-0.1", name: ">10%" },
    { value: ">-0.2", name: ">20%" },
    { value: ">-0.3", name: ">30%" },
    { value: ">-0.4", name: ">40%" },
    { value: ">-0.5", name: ">50%" },
    { value: ">-0.6", name: ">60%" },
    { value: ">-0.7", name: ">70%" },
    { value: ">-0.8", name: ">80%" },
    { value: ">-0.9", name: ">90%" },
    { value: ">-1", name: ">100%" },
    { value: ">-1.1", name: ">110%" },
    { value: ">-1.2", name: ">120%" },
    { value: ">-1.3", name: ">130%" },
    { value: ">-1.4", name: ">140%" },
    { value: ">-1.5", name: ">150%" },
    { value: ">-1.6", name: ">160%" },
    { value: ">-1.7", name: ">170%" },
    { value: ">-1.8", name: ">180%" },
    { value: ">-1.9", name: ">190%" },
    { value: ">-1", name: ">200%" },
    { value: ">-2.1", name: ">210%" },
    { value: ">-2.2", name: ">220%" },
    { value: ">-2.3", name: ">230%" },
    { value: ">-2.4", name: ">240%" },
    { value: ">-2.5", name: ">250%" },
    { value: ">-2.6", name: ">260%" },
    { value: ">-2.7", name: ">270%" },
    { value: ">-2.8", name: ">280%" },
    { value: ">-2.9", name: ">290%" },
    { value: ">-3", name: ">300%" },
    { value: ">-3.1", name: ">310%" },
    { value: ">-3.2", name: ">320%" },
    { value: ">-3.3", name: ">330%" },
    { value: ">-3.4", name: ">340%" },
    { value: ">-3.5", name: ">350%" },
    { value: ">-3.6", name: ">360%" },
    { value: ">-3.7", name: ">370%" },
    { value: ">-3.8", name: ">380%" },
    { value: ">-3.9", name: ">390%" },
    { value: ">-4", name: ">400%" },
    { value: "<-1.1", name: "<110%" },
    { value: "<-1.2", name: "<120%" },
    { value: "<-1.3", name: "<130%" },
    { value: "<-1.4", name: "<140%" },
    { value: "<-1.5", name: "<150%" },
    { value: "<-1.6", name: "<160%" },
    { value: "<-1.7", name: "<170%" },
    { value: "<-1.8", name: "<180%" },
    { value: "<-1.9", name: "<190%" },
    { value: "<-2", name: "<200%" },
    { value: "<-2.1", name: "<210%" },
    { value: "<-2.2", name: "<220%" },
    { value: "<-2.3", name: "<230%" },
    { value: "<-2.4", name: "<240%" },
    { value: "<-2.5", name: "<250%" },
    { value: "<-2.6", name: "<260%" },
    { value: "<-2.7", name: "<270%" },
    { value: "<-2.8", name: "<280%" },
    { value: "<-2.9", name: "<290%" },
    { value: "<-3", name: "<300%" },
    { value: "<-3.1", name: "<310%" },
    { value: "<-3.2", name: "<320%" },
    { value: "<-3.3", name: "<330%" },
    { value: "<-3.4", name: "<340%" },
    { value: "<-3.5", name: "<350%" },
    { value: "<-3.6", name: "<360%" },
    { value: "<-3.7", name: "<370%" },
    { value: "<-3.8", name: "<380%" },
    { value: "<-3.9", name: "<390%" },
    { value: "<-4", name: "<400%" },
    { value: "<-4.1", name: "<410%" },
    { value: "<-4.2", name: "<420%" },
    { value: "<-4.3", name: "<430%" },
    { value: "<-4.4", name: "<440%" },
    { value: "<-4.5", name: "<450%" },
    { value: "<-4.6", name: "<460%" },
    { value: "<-4.7", name: "<470%" },
    { value: "<-4.8", name: "<480%" },
    { value: "<-4.9", name: "<490%" },
    { value: "<-5", name: "<500%" },
  ]);

  const handleInfFollowerGrowthelection = (event, selectedValues) => {
    if (!selectedValues) return;
    const newSelection = {
      name: selectedValues.name,
      value: selectedValues.value,
      range: ">-1.5",
      operator: "gte",
    };

    const updatedAllFiltersData = utils.addObj(allFiltersData, "inf_followers_growth");
    updatedAllFiltersData[0]["inf_followers_growth"] = [
      ...(updatedAllFiltersData[0]["inf_followers_growth"] || []),
      newSelection,
    ];
    setAllFiltersData(updatedAllFiltersData);
    const newPayload = { ...payload, inf_followers_growth: updatedAllFiltersData[0]["inf_followers_growth"] };
    setPayload(newPayload);
    let newCustomCols = utils.addObj(customCols, "inf_followers_growth");
    newCustomCols[0]["inf_followers_growth"] = updatedAllFiltersData[0]["inf_followers_growth"]?.map((item) => item.name);
    setSelectedCol(newCustomCols);
    setCustomCols(newCustomCols);

    setIsChanged(!isChanged);
  };

  const handleRange = (event, index) => {
    const dataArray = event.target.value.split("-");
    let operator = dataArray[0];
    let value = dataArray[1];
    let operatorString = operator === ">" ? "gte" : "lte";
    const updatedSelections = allFiltersData[0]["inf_followers_growth"]?.map((item, idx) => {
      if (idx === index) {
        return { ...item, range: event.target.value, operator: operatorString };
      }
      return item;
    });

    const updatedAllFiltersData = [...allFiltersData];
    updatedAllFiltersData[0]["inf_followers_growth"] = updatedSelections;
    setAllFiltersData(updatedAllFiltersData);
    const newPayload = { ...payload, inf_followers_growth: updatedSelections };
    setPayload(newPayload);
  };

  const handleCancelFollowerGrowth = (name) => {
    const filteredData = allFiltersData[0]["inf_followers_growth"]?.filter(
      (item) => item.name !== name
    );

    const updatedAllFiltersData = [...allFiltersData];
    updatedAllFiltersData[0]["inf_followers_growth"] = filteredData;
    setAllFiltersData(updatedAllFiltersData);
    const newPayload = { ...payload, inf_followers_growth: filteredData };
    setPayload(newPayload);

    setIsChanged(!isChanged);
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple={false}
              disableClearable
              id="filter-FollowerGrowth"
              size="small"
              options={allFollowerGrowth}
              getOptionLabel={(option) => option.name}
              onChange={handleInfFollowerGrowthelection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Follower Growth"
                  placeholder="Choose the interval"
                  variant="outlined"
                  fullWidth
                />
              )}
              style={{
                borderRadius: ".775rem",
              }}
            />
          </FormControl>
          {/* {allFiltersData[0]?.inf_followers_growth?.length > 0 && (
          <Grid item xs={12}>
            {allFiltersData[0]["inf_followers_growth"]?.map((fg, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  padding: "8px 16px",
                  borderRadius: "5px",
                  marginBottom: "8px",
                  border: "1px solid #ddd",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  onClick={() => handleCancelFollowerGrowth(fg.name)}
                  edge="start"
                  aria-label="delete"
                  sx={{
                    padding: "4px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  <ClearIcon />
                </IconButton>
                <Box
                  sx={{
                    flexGrow: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginRight: "10px",
                  }}
                >
                  {fg.name}
                </Box>
                <Select
                  value={fg.range}
                  onChange={(e) => handleRange(e, index)}
                  size="small"
                  sx={{ minWidth: 80, backgroundColor: "#fff", marginLeft: "8px" }}
                >
                  {percentage?.map((option, idx) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ))}
          </Grid>
        )} */}
        </Grid>
      </Grid>
    </>
  ) : null;
};

FollowerGrowth.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  setCustomCols: PropTypes.func.isRequired,
  setSelectedCol: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  setIsChanged: PropTypes.func.isRequired,
  allFiltersData: PropTypes.array.isRequired,
  setAllFiltersData: PropTypes.func.isRequired,
};

export default FollowerGrowth;
