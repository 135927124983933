const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

const brandObjectives = [
  "Generate traffic to website/App Downloads",
  "Working on my brand awareness",
  "Generating leads",
  "Make more sales",
];

const reachObjectives = [
  "Connection with Digital Resources (link to website, app download, online registration, etc)",
  "Awareness Raising or Advocacy",
  "Generating Demand",
  "Social Behavior Change",
];

const genderOptions = ["Male", "Female", "Other"];

const audienceOptions = [
  "An app",
  "A website or a landing page",
  "SMS/Text Messages/Hotline",
  "Specific Social Media Groups",
  "A physical location e.g. a clinic",
];

export {
  steps,
  brandObjectives,
  reachObjectives,
  genderOptions,
  audienceOptions,
};
