import React, { useEffect, useRef } from "react";
import $ from "jquery";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PropTypes from "prop-types";
import moment from "moment";

const FormDateRangeCustomPicker = React.memo(
  ({ sendSelectedDates, parentStartDate, parentEndDate, filterStatus = false, disabled = false, needRange = false }) => {
    const dateRangePickerRef = useRef(null);
    const [localStartDate, setLocalStartDate] = React.useState(parentEndDate);
    const [localEndDate, setLocalEndDate] = React.useState(parentEndDate);

    const handleCalendarChange = (start, end) => {
      if (disabled) return;
      const startDate = moment(start);
      const endDate = moment(end);
      sendSelectedDates({ startDate, endDate });
      setLocalStartDate(startDate);
      setLocalEndDate(endDate);
    };

    useEffect(() => {
      // Update local state when parent state changes
      setLocalStartDate(moment(parentStartDate));
      setLocalEndDate(moment(parentEndDate));
      // Call updateDateRangePicker when local state changes
      updateDateRangePicker();
      initializeDateRangePicker(parentStartDate, parentEndDate);
    }, [parentStartDate, parentEndDate]);

    const updateDateRangePicker = () => {
      // Check if DateRangePicker has been initialized
      if (dateRangePickerRef.current) {
        // Destroy the existing DateRangePicker instance
        dateRangePickerRef.current.data("daterangepicker").remove();

        // Reinitialize the DateRangePicker
        initializeDateRangePicker();
      }
    };

    const initializeDateRangePicker = (startDate, endDate) => {
      // Initialize the DateRangePicker
      dateRangePickerRef.current = $(`.form-control`).daterangepicker(
        {
          startDate,
          endDate,
          // ... other settings
        },
        handleCalendarChange
      );

      // Optionally, you can set additional event listeners or configurations here
    };

    return (
      <DateRangePicker>
        <input
          style={{
            lineHeight: 1.6,
            minWidth: "7vw",
            height: "55px",
            marginRight: "5px",
            background: "#F7F7F7",
            borderColor: "#c0c0c0",
          }}
          type="text"
          className="form-control"
          disabled={filterStatus}
        />
      </DateRangePicker>
    );
  },
  (prevProps, nextProps) => {
    return (
      moment(prevProps.parentStartDate).isSame(nextProps.parentStartDate) &&
      moment(prevProps.parentEndDate).isSame(nextProps.parentEndDate)
    );
  }
);

export default FormDateRangeCustomPicker;
