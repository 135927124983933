import { Alert, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { endImpersonationSession, login, storeFreeTrialPopupState } from "../../redux/features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { getMenuItemsData, getUserDetails } from "../../services/login";
import { defineMenu, definePermissions } from "../../redux/features/menu/menuSlice";
import { notify } from "../../redux/features/system/systemAlert";

const ImpersonatePopup = ({ impersonateUser, user }) => {
  const [warning, setWarning] = useState(impersonateUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateMe = async () => {
    console.log(user)
    const { oldEmail } = user;
    const getUserAssets = await getUserDetails(oldEmail);
    dispatch(
      notify({
        message: `You are logged back as ${oldEmail}`,
        type: "success",
      })
    );
    const { response } = getUserAssets;
    const payload = {
      email: oldEmail,
      oldEmail: null,
      accessToken: user.accessToken,
      accesssTokenExpiresAt: user.accesssTokenExpiresAt,
      agencyId: response.agencyId,
      clientId: response.clientId,
      name: response.name,
      group: response.groupName,
      idToken: user.idToken,
      refreshToken: user.refreshToken,
      role: response.role,
      accessKeyId: user.accessKeyId,
      secretKey: user.secretKey,
      sessionToken: user.sessionToken,
      userId: response.id,
      membershipId: response.membershipId,
      isLocked: response.isLocked,
      freeTrialAccessed: response.freeTrialAccessed,
      freeTrialPopupWarning: false,
      isImpersonateSession: false,
    };

    const menuResponse = await getMenuItemsData(
      {
        role: user.oldRole,
        email: user.oldEmail,
        agencyId: 0,
        clientId: 0,
      },
      user
    );
    dispatch(login(payload));
    dispatch(defineMenu(menuResponse.response.AccountMenu.Menu));
    dispatch(definePermissions(menuResponse.response.AccountPermissions));
    dispatch(endImpersonationSession());
    navigate("/app/account/users")
  };

  return (
    warning && (
      <div>
        <Snackbar open={warning} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="warning">
            <Typography>
              You are now logged in as {user.email} &nbsp;&nbsp;
              <a href="#" style={{ color: "#1576d5" }} onClick={() => navigateMe()}>
                End Session
              </a>
            </Typography>
          </Alert>
        </Snackbar>
      </div>
    )
  );
};

export default ImpersonatePopup;
