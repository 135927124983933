import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import {
  draftPostAction,
  getDraftDetails,
} from "../../../../../services/publication";
import { charLengthSupported, draftStatusMapping } from "../CommonAssets";
import InstagramPost from "../InstagramPost";
import LinkedinPost from "../LinkedinPost";
import FacebookPost from "../FacebookPost";
import TwitterPost from "../TwitterPost";
import TiktokPost from "../TiktokPost";
import YoutubePost from "../YoutubePost";
import PostTextEditor from "../PostTextEditor";
import {
  AccessTime,
  Cancel,
  CheckCircle,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { notify } from "../../../../../redux/features/system/systemAlert";
import configs from "../../../../../config/main.json";
import { useLocation } from "react-router-dom";

const ApprovalDialog = ({ draft, iid, viewApproval, closeApproval }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const [postDetails, setPostDetails] = useState({});
  const [lastCommentHidden, setLastCommentHidden] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [editContent, setEditContent] = useState(false);
  const [content, setContent] = useState("");
  const [contentLength, setContentLength] = useState(0);
  const [maxLength, setMaxLength] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");
  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    const feedback = document.getElementById("improvement_comment")?.value;
    if (feedback) {
      if (contentLength > maxLength || feedback.trim() === "") {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(false);
      }
    }
  }, [contentLength, feedback]);

  const initialLoad = async () => {
    const postDetails = await getDraftDetails(draft.id, user, dispatch);

    if (postDetails.status === true) {
      setPostDetails(postDetails.response);
      setContent(postDetails.response.postContent);
      setContentLength(postDetails.response.postContent?.length);
      setMaxLength(charLengthSupported[postDetails.response.platform]);
    }
  };

  const HandleActionButtonClick = async (action) => {
    const data = {
      postId: draft.id,
      status: draftStatusMapping[action],
      comment: feedback,
      postContent: content,
      platform: "empowr",
      email: user.email,
      notificationMetadata: {
        userId: iid.toString(),
        sourcePlatform: configs.PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "2",
        notificationCategoryId: "6",
        generatedBy: user.email,
        priority: "medium",
        consumedBy: "gazelle",
        navigateTo: `/account/posts/draft/${draft.id}?campaignId=${campaignId}`
      }
    };

    const result = await draftPostAction(data, user, action);
    if (result.status === true) {
      dispatch(
        notify({
          message: result.response.Msg,
          type: "success",
        })
      );

      closeApproval(true);
    }
  };

  const renderPost = () => {
    return (
      <Grid item xs={4} sm={8} md={12}>
        <Card
          style={{
            margin: "auto",
            padding: "20px",
            width: "500px",
            height: "auto",
          }}
        >
          {postDetails.platform === "instagram" && (
            <InstagramPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "linkedin" && (
            <LinkedinPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "facebook" && (
            <FacebookPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "twitter" && (
            <TwitterPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "tiktok" && (
            <TiktokPost formData={postDetails} cid={postDetails.cid} />
          )}
          {postDetails.platform === "youtube" && (
            <YoutubePost formData={postDetails} cid={postDetails.cid} />
          )}
        </Card>
      </Grid>
    );
  };

  const editPost = () => {
    return (
      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <PostTextEditor
          isReadOnly={false}
          placeholder={"placeholder"}
          maxLength={maxLength}
          handlePostInput={setContent}
          postContent={content}
          charCount={contentLength}
          setCharCount={setContentLength}
        />
      </FormControl>
    );
  };

  const toggleLastComment = () => {
    setLastCommentHidden(!lastCommentHidden);
  };

  const getLastCommentToggleIcon = () => {
    if (lastCommentHidden) {
      return (
        <IconButton
          id="last_comment-toggle_button"
          onClick={() => {
            toggleLastComment();
          }}
        >
          <ExpandMore fontSize="small" />
        </IconButton>
      );
    }
    return (
      <IconButton
        id="last_comment-toggle_button"
        onClick={() => {
          toggleLastComment();
        }}
      >
        <ExpandLess fontSize="small" />
      </IconButton>
    );
  };

  const getTopComments = (topComment) => {
    if (lastCommentHidden) {
      return;
    }

    return topComment?.map((detail) => (
      <>
        <span id="last_comment">{detail.comment}</span> <br />
        {{ detail } && (
          <span
            id="last_commented_at"
            style={{ color: "rgb(113, 118, 123)", fontSize: "10px" }}
          >
            <IconButton>
              <AccessTime sx={{ fontSize: 12 }} />
            </IconButton>
            {new Date(detail.timestamp).toLocaleString()}
          </span>
        )}
        <br />
      </>
    ));
  };

  const loadLastComment = () => {
    if (postDetails && postDetails.topComments) {
      const topComments = postDetails.topComments;
      if (topComments === "") {
        return;
      }
      /* show last comment and last comment at similar to "Attachments" */
      return (
        <div className="lastComment">
          <div className="styles_modal_footer">
            <b>Latest feedback</b>
            {getLastCommentToggleIcon()}
          </div>
          <div id="last_comment_div">{getTopComments(topComments)}</div>
        </div>
      );
    }
  };

  return (
    <Dialog
      open={viewApproval}
      onClose={closeApproval}
      PaperProps={{
        style: {
          height: "auto",
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          id="influencerMetadata"
          style={{ paddingTop: "0" }}
        >
          <Grid item xs={2} sm={4} md={6}>
            <b>Campaign</b> :{postDetails.campaignName}
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <b>List</b> :{postDetails.listName}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!editContent && (
          <div className="post">
            {/* post content */}
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              id="post"
            >
              {renderPost()}
            </Grid>
          </div>
        )}
        {editContent && <div>{editPost()}</div>}

        <hr style={{ marginBottom: "5px", marginTop: "5px" }} />
        {!editContent && loadLastComment()}

        <Grid
          container
          spacing={{ xs: 2, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={12}>
            <TextareaAutosize
              id="improvement_comment"
              variant="outlined"
              placeholder="Feedback"
              style={{ width: "100%", borderRadius: "4px" }}
              minRows={3}
              value={feedback}
              onChange={(e) => {
                setFeedback(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <br />

        {!editContent && (
          <Stack
            direction="row"
            justifyContent="end"
            spacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Button
              sx={{
                marginRight: "auto",
                backgroundColor: "#EFB701",
                ":hover": { backgroundColor: "#8B8000" },
              }}
              variant="contained"
              size="large"
              startIcon={<Edit />}
              className="postReview-btn"
              id="postReview-reject-btn"
              onClick={() => {
                setEditContent(true);
              }}
            >
              Edit Content
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<CheckCircle />}
              className="postReview-btn"
              id="postReview-approve-btn"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to APPROVE this post?")
                ) {
                  HandleActionButtonClick("Approved");
                }
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              size="large"
              className="postReview-btn"
              id="postReview-proideFeedback-btn"
              color="secondary"
              onClick={() => {
                const feedback = document.getElementById(
                  "improvement_comment"
                ).value;
                if (feedback.trim() === "") {
                  window.alert("Please provide a feedback for improvisation.");
                } else {
                  HandleActionButtonClick("Improvement Suggested");
                }
              }}
            >
              Provide Feedback
            </Button>
          </Stack>
        )}

        {editContent && (
          <Stack
            direction="row"
            justifyContent="end"
            spacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Button
              sx={{ marginRight: "auto" }}
              variant="contained"
              size="large"
              startIcon={<Cancel />}
              className="postReview-btn"
              id="postReview-reject-btn"
              color="secondary"
              onClick={() => {
                setEditContent(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<CheckCircle />}
              className="postReview-btn"
              id="postReview-approve-btn"
              disabled={disableSubmit}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to EDIT this post with the following suggestions?"
                  )
                ) {
                  HandleActionButtonClick("Edit With Suggestions");
                }
              }}
            >
              Submit
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

ApprovalDialog.propTypes = {
  draft: PropTypes.object.isRequired,
  viewApproval: PropTypes.bool.isRequired,
  closeApproval: PropTypes.func.isRequired,
};

export default ApprovalDialog;
