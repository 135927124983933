import { Grid } from "@mui/material";
import { InfoToolTip } from "../../../common/toolTip";

export const tableHeaders = [
  {
    id: 1,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Influencer
        </Grid>
      </Grid>
    ),
    mappedValue: "Influencer",
    field: "name",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 2,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Template
        </Grid>
      </Grid>
    ),
    mappedValue: "Template",
    field: "template",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Sent On
        </Grid>
      </Grid>
    ),
    mappedValue: "Sent On",
    field: "sentOn",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 4,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Status
        </Grid>
      </Grid>
    ),
    mappedValue: "Status",
    field: "status",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
];

export const templatetableHeaders = [
  {
    id: 1,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Template Name
        </Grid>
      </Grid>
    ),
    mappedValue: "Template Name",
    field: "name",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 2,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Template Description
        </Grid>
      </Grid>
    ),
    mappedValue: "Template Description",
    field: "description",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Last Updated
        </Grid>
      </Grid>
    ),
    mappedValue: "Last Updated",
    field: "lastUpdated",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 4,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Used
        </Grid>
      </Grid>
    ),
    mappedValue: "Used",
    field: "used",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },

  {
    id: 5,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
];
