import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import { Alert, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { storeAudienceState } from "../../../../redux/features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

const AudienceInsightsFilter = ({ weekData, sendSelectedWeek }) => {
  const account = useSelector((state) => state.user); //get loggedIn user state

  const [selectedPlatform, setSelectedPlatform] = React.useState(0);
  const [selectedWeek, setSelectedWeek] = React.useState(0);
  const [warning, setWarning] = React.useState(account.audienceInsightWarning);
  const dispatch = useDispatch();

  const showWarnings = () => {
    return (
      <div>
        <Alert
          sx={{
            width: "70%",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "#FEAB00",
            borderRadius: "10px",
          }}
          onClose={handleWarning}
          severity="warning"
          variant="filled"
        >
          Audience Analysis is currently only available for Instagram
        </Alert>
        <br />
      </div>
    );
  };

  const handleWarning = () => {
    setWarning(false);
    dispatch(storeAudienceState());
  };

  const selectWeek = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedWeek(value);
    sendSelectedWeek(value);
  };

  return (
    <>
      {" "}
      <Stack direction="row" justifyContent="end" sx={{ marginBottom: "20px" }}>
        {/* Not using common component for platform here because we support just one platform at the moment and we don't need to show all the options here */}
        <FormControl size="small" sx={{ marginRight: "5px" }}>
          <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
          <Select
            sx={{
              height: "40px",
              minWidth: "10vw",
              borderRadius: "10px",
            }}
            style={{ color: "black" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            disabled
            value={selectedPlatform}
            onChange={(event) => setSelectedPlatform(event.target.value)}
            label="Platform"
            variant="outlined"
          >
            <MenuItem key={0} value={0}>
              <Typography sx={textColor["instagram"]}>
                {snapshotHeaderIcons["instagram"]}
                &nbsp;&nbsp;Instagram
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: "5px" }} size="small">
          <InputLabel id="demo-simple-select-standard-label">Week</InputLabel>
          <Select
            sx={{
              minWidth: "7vw",
              marginRight: "5px",
              borderRadius: "10px",
            }}
            style={{ color: "black" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedWeek}
            label="Week"
            onChange={(event) => selectWeek(event)}
            variant="outlined"
          >
            {/* get weeks in dropdown for that campaign */}
            {weekData?.map((data) => {
              return (
                <MenuItem value={data.value} key={data.value}>
                  {"Week "}
                  {data.id + 1}
                  {" - "} {data.value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
      {warning && showWarnings()}
      <br />
    </>
  );
};

AudienceInsightsFilter.propTypes = {
  weekData: PropTypes.array.isRequired,
  sendSelectedWeek: PropTypes.func.isRequired,
};

export default AudienceInsightsFilter;
