import config from "../config/main.json";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const getRequestData = async (uuid) => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/requestdata/${uuid}`
  );

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getBrandAttibutes = async () => {
  const response = await fetch(`${config.UNAUTH_API_HOST}/brand/attrs`);

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getBrandCategories = async () => {
  const response = await fetch(`${config.UNAUTH_API_HOST}/brand/scat`);

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const submitCampaignBrief = async (body) => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/submitresponse`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: headers,
    }
  );
  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getFocusAreas = async () => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/focusareas`
  );

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getAgeCategories = async () => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/agecattegories`
  );

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getInterestCategories = async () => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/interestcategories`
  );

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getIndustries = async () => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/industries`
  );

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

const getLanguages = async () => {
  const response = await fetch(
    `${config.UNAUTH_API_HOST}/campaignbrief/languages`
  );

  return {
    status: response.ok,
    response: await Promise.resolve(response.json()),
  };
};

export {
  getRequestData,
  getBrandAttibutes,
  getBrandCategories,
  submitCampaignBrief,
  getFocusAreas,
  getAgeCategories,
  getInterestCategories,
  getIndustries,
  getLanguages,
};
