import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Restore } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { draftStatusMapping, timelineDetails } from "../CommonAssets";
import { useDispatch, useSelector } from "react-redux";
import { getDraftHistory } from "../../../../../services/publication";

const HistoryDialog = ({ draft, viewHistory, closeHistory }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const [postHistory, setPostHistory] = useState([]);

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    const postHistory = await getDraftHistory(draft.id, user, dispatch);

    if (postHistory.status === true) {
      setPostHistory(postHistory.response);
    }
  };

  const renderPostHistoryTimeline = () => {
    return postHistory?.map((r, i) => {
      let status = "";
      for (let x in draftStatusMapping) {
        if (draftStatusMapping[x] === r.status) {
          status = x;
          break;
        }
      }

      if (status === "" || status === "-" || !status) {
        return "";
      }

      return (
        <TimelineItem key={i}>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {new Date(r.createdAt).toDateString()}
            <br />
            {new Date(r.createdAt).toLocaleTimeString()}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              style={{ backgroundColor: timelineDetails[status]["iconColor"] }}
            >
              {timelineDetails[status]["icon"]}
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography component="span">
              {timelineDetails[status]["statusDescription"]}
            </Typography>
            <br />
            {r.userName !== "" && <Typography sx={{ pl: "5px" }}>by {r.userName}</Typography>}
          </TimelineContent>
        </TimelineItem>
      );
    });
  };

  const drawTimeline = () => {
    if (postHistory && postHistory?.length > 0) {
      return (
        <Timeline align="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: "#CC00FF" }}>
                <Restore />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent></TimelineContent>
          </TimelineItem>
          {renderPostHistoryTimeline()}
        </Timeline>
      );
    } else {
      return (
        <span style={{ textAlign: "center" }}>Nothing to show here!!</span>
      );
    }
  };

  return (
    <Dialog
      open={viewHistory}
      onClose={closeHistory}
      PaperProps={{
        style: {
          height: "auto",
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Post Timeline</DialogTitle>
      <DialogContent>{drawTimeline()}</DialogContent>
    </Dialog>
  );
};

HistoryDialog.propTypes = {
  draft: PropTypes.object.isRequired,
  viewHistory: PropTypes.bool.isRequired,
  closeHistory: PropTypes.func.isRequired,
};

export default HistoryDialog;
