import { AllInclusive, Favorite, Group, Groups2, PhotoSizeSelectActual, VolumeUp } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { boxStyles, headerStyle, snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import { capitalizeFirstLetter, convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import PropTypes from "prop-types";

const Campaigndatashot = ({ snapshot, platforms }) => {
  return (
    <>
      <Grid style={{ background: "#E2EDFC", borderRadius: "2px", padding: "20px" }}>
        <p className={styles.snapshotTitle}>
          <b>Campaign Snapshot</b>
        </p>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 14 }}>
          {platforms?.map((platform) => {
            if (platform.toLowerCase() !== "total") {
              return (
                <>
                  <Grid key={"header"} item xs={1.5} sm={2.3} md={2.3}>
                    <div style={headerStyle[platform]}>
                      <Grid container>
                        <Grid item xs={5} style={{ paddingLeft: 10 }}>
                          <Typography sx={textColor[platform]}>{snapshotHeaderIcons[platform]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={textColor[platform]}>{capitalizeFirstLetter(platform)}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid key={"influencers"} item xs={1.5} sm={2.3} md={2.3}>
                    <Box sx={boxStyles[platform]}>
                      <Grid item xs={2.5} columns={6} direction="column" style={{ padding: "10px" }}>
                        <Group sx="small" />
                      </Grid>
                      <Grid item xs container spacing={2} columns={6} direction="column">
                        <Grid item xs={12} style={{ textAlign: "center", paddingRight: "10px" }}>
                          <Typography>
                            &nbsp;&nbsp;{snapshot[platform].influencers} of {snapshot[platform].totalInfluencers} Posted
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid key={"posts"} item xs={1.5} sm={2.3} md={2.3}>
                    <Box sx={boxStyles[platform]}>
                      <Grid item xs={3} columns={6} direction="column" style={{ padding: "10px" }}>
                        <PhotoSizeSelectActual sx="small" />
                      </Grid>
                      <Grid item xs container spacing={2} columns={6} direction="column">
                        <Grid item xs={12} style={{ textAlign: "right", paddingRight: "30px" }}>
                          <Typography>&nbsp;&nbsp;{snapshot[platform].posts} Posts</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {/* <Grid key={"infReach"} item xs={1.5} sm={2.3} md={2.3}>
                    <Box sx={boxStyles[platform]}>
                      <Grid item xs={2.5} columns={6} direction="column" style={{ padding: "10px" }}>
                        <VolumeUp sx="small" />
                      </Grid>
                      <Grid item xs container spacing={2} columns={6} direction="column">
                        <Grid item xs={12} style={{ textAlign: "center", paddingRight: "10px" }}>
                          <Typography>
                            &nbsp;&nbsp;
                            {convertToInternationalNumberSystem(snapshot[platform].followers)} Followers
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid> */}
                  <Grid key={"postReach"} item xs={1.5} sm={2.3} md={2.3}>
                    <Box sx={boxStyles[platform]}>
                      <Grid item xs={3} columns={6} direction="column" style={{ padding: "10px" }}>
                        <Groups2 sx="small" />
                      </Grid>
                      <Grid item xs container spacing={2} columns={6} direction="column">
                        <Grid item xs={12} style={{ textAlign: "center", paddingRight: "20px" }}>
                          <Typography>
                            &nbsp;&nbsp;
                            {convertToInternationalNumberSystem(snapshot[platform].views)} &nbsp; Reach
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid key={"engagement"} item xs={1.5} sm={2.3} md={2.3}>
                    <Box sx={boxStyles[platform]}>
                      <Grid item xs={2.5} columns={6} direction="column" style={{ padding: "10px" }}>
                        <Favorite sx="small" />
                      </Grid>
                      <Grid item xs container spacing={2} columns={6} direction="column">
                        <Grid item xs={12} style={{ textAlign: "center", paddingRight: "10px" }}>
                          <Typography>
                            &nbsp;&nbsp;
                            {convertToInternationalNumberSystem(snapshot[platform].engagement)} Engagement
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid key={"engRate"} item xs={1.5} sm={2.3} md={2.3}>
                    <Box sx={boxStyles[platform]}>
                      <Grid item xs={3} columns={6} direction="column" style={{ padding: "10px" }}>
                        <AllInclusive sx="small" />
                      </Grid>
                      <Grid item xs container spacing={2} columns={6} direction="column">
                        <Grid item xs={12} style={{ textAlign: "center", paddingRight: "20px" }}>
                          <Typography>
                            &nbsp;&nbsp;
                            {convertToInternationalNumberSystem(snapshot[platform].engRate.toFixed(2))} Avg. ER
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              );
            }
          })}
        </Grid>
      </Grid>
      <p style={{ fontSize: "14px", paddingTop: "10px", paddingLeft: "10px" }}><i>* does not include unresolved posts</i></p>
    </>
  );
};

Campaigndatashot.propTypes = {
  snapshot: PropTypes.object.isRequired,
  platforms: PropTypes.array.isRequired,
};

export default Campaigndatashot;
