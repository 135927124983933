const YouTubeShare = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6V0L20 9L12 18V12C6.44 12 2.78 14.03 0 18C1.11 12.33 4.22 7.13 12 6Z"
      stroke="black"
    />
  </svg>
);

export default YouTubeShare;
