import { Box, Paper, Stack, Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TopNPosts from '../../../TopNPosts'
import { object, string } from 'prop-types';
import CardHeader from '../common/CardHeader/CardHeader';
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';
import Carousel from 'react-bootstrap/Carousel';

const ContentInfo = (props) => {
  const { data, platform } = props;

  const [itemIndex, setItemIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(1);

  const cardStyle = {
    height: '380px',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    overflowY: 'auto',
  };

  useEffect(() => {
    if (data?.length > 7)
      setTotalSlides(3);
    else if (data?.length > 4)
      setTotalSlides(2);
  }, [data?.length])


  const handleBackClick = () => {
    if (itemIndex === 0) {
      return;
    }
    setItemIndex(itemIndex - 1);
  }
  const handleForwardClick = () => {
    if (itemIndex + 1 >= totalSlides) {
      return;
    }
    setItemIndex(itemIndex + 1);
  }

  return (
    <Paper sx={{ minHeight: "200px", height: "580px", position: "relative"}} >
      <Box sx={{ pt: 2, ml: 2}}>
        <CardHeader title="Top Posts" hideToolTip />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="end">
        <Box mr="10px">
          {(3 * itemIndex) + 1} - {((itemIndex + 1) * totalSlides)} of {data.slice(0, 9)?.length}
        </Box>
        <ArrowBackIosNew onClick={handleBackClick} fontSize='small' sx={{ mr: "10px", cursor: "pointer" }} />
        <ArrowForwardIos onClick={handleForwardClick} fontSize='small' sx={{mr: "40px",  cursor: "pointer" }} />
      </Box>

      <Carousel activeIndex={itemIndex} indicators={false} controls={false} interval={null} style={{ marginTop: '80px' }}>
        <Carousel.Item>
          <Stack direction="row" className="align-items-center" gap={1} justifyContent="center">
            {data.slice(0, 3)?.map((item, idx) => (
              <Card key={`post-${idx}`} style={cardStyle}>
                <TopNPosts PostUrl={item} Platform={platform} />
              </Card>
            ))}
          </Stack>
        </Carousel.Item>
        {data?.length > 4 && (
          <Carousel.Item>
            <Stack direction="row" className="align-items-center" gap={1} justifyContent="center">
              {data.slice(3, 6)?.map((item, idx) => (
                <Card key={`post-${idx}`} style={cardStyle}>
                  <TopNPosts PostUrl={item} Platform={platform} />
                </Card>
              ))}
            </Stack>
          </Carousel.Item>
        )}
        {data?.length > 7 && (
          <Carousel.Item>
            <Stack direction="row" className="align-items-center" gap={1} justifyContent="center">
              {data.slice(6, 9)?.map((item, idx) => (
                <Card key={`post-${idx}`} style={cardStyle}>
                  <TopNPosts PostUrl={item} Platform={platform} />
                </Card>
              ))}
            </Stack>
          </Carousel.Item>
        )}
      </Carousel>
    </Paper>
  )
}

ContentInfo.propTypes = {
  data: object.isRequired,
  platform: string.isRequired
}

export default ContentInfo