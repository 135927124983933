import { Box, Grid } from "@mui/material";
import AudienceWidget from "./AudienceWidget";
import styles from "../../../../styles/internal/Campaign/CampaignAnalytics.module.css";
import styles2 from "../../../../styles/internal/Campaign/CampaignList.module.css";
import React from "react";
import AudienceInsightsFilter from "./AudienceInsightsFilter";
import { getAudienceInsightsReport } from "../../../../services/campaignAnalytics";
import { useLocation } from "react-router-dom";
import { convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import AudienceInsightsGraph from "./AudienceInsightsGraph";
import { widgetData, progressColors1, progressColors2 } from "./CommonAsset";
import Loader from "../../../common/loader";
import { useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { useDispatch } from "react-redux";

const AudienceInsightsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);

  const [weekData, setWeekData] = React.useState([]); //weeks in a campaign
  const [worldMapData, setWorldMapData] = React.useState([]); //world map data for graph
  const [countryData, setCountryData] = React.useState([]); //highlighted countries
  const [cityData, setCityData] = React.useState([]); //highlighted cities
  const [maleData, setMaleData] = React.useState([]); //males for graph
  const [femaleData, setFemaleData] = React.useState([]); //female for graph
  const [totalMale, setTotalMale] = React.useState(0); //total male in all age groups
  const [totalFemale, setTotalFemale] = React.useState(0); //total female in all age groups
  const [reachabilityData, setReachabilityData] = React.useState([]); //reachability data
  const [ethnicityData, setEthinicityData] = React.useState([]);
  const [interestData, setInterestData] = React.useState([]);
  const [languageData, setLanguageData] = React.useState([]);
  const [brandData, setBrandData] = React.useState([]);
  const [widgetArray, setWidgetArray] = React.useState(widgetData); //snapshot widgets - to be passed to child component
  const [week, setWeek] = React.useState(0);
  React.useEffect(() => {
    getAudienceReport();
  }, [+params.get("id")]);

  const getAudienceReport = async () => {
    setLoading(true);
    const report = await getAudienceInsightsReport(params.get("id"), user, dispatch);
    setLoading(false);
    if (report.status === true && report.response !== null) {
      setDataFound(true);
      createWeeks(report.response);
      createWidgetArray(report.response);
      processCountryData(report.response[week].audiencelikers.data.audience_geo);
      processAgeGenderData(report.response[week].audiencelikers.data);
      processReachabilityData(report.response[week].audiencelikers.data.audience_reachability);
      processEthinicityData(report.response[week].audiencelikers.data.audience_ethnicities);
      processInterestData(report.response[week].audiencelikers.data.audience_interests);
      processLangugageData(report.response[week].audiencelikers.data.audience_languages);
      processBrand(report.response[week].audiencelikers.data.audience_brand_affinity);
    } else {
      setDataFound(false);
      if (report.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: report.response?.message,
          type: "error",
        })
      );
    }
  };

  //create week array for a campaign
  const createWeeks = (data) => {
    const reportsCreatedDate = [];
    data.forEach((stat, index) => {
      reportsCreatedDate.push({
        id: index,
        value: stat.reportinfo.created.substring(0, 10),
      });
    });
    setWeekData(reportsCreatedDate);
  };

  //fill up the widget array
  const createWidgetArray = (data) => {
    widgetArray?.map((widget) => {
      if (widget.field === "reach") widget.value = convertToInternationalNumberSystem(data[week].postsprofile.potential_reach);
      else if (widget.field === "uniqueengagers")
        widget.value = convertToInternationalNumberSystem(data[week].postsprofile.unique_audience);
      else if (widget.field === "massfollowers")
        widget.value =
          (data[week].audiencelikers.data.audience_types.find((stat) => stat.code === "mass_followers").weight * 100).toFixed(2) +
          "%";
      else if (widget.field === "totalfollowers")
        widget.value = convertToInternationalNumberSystem(data[week].postsprofile.neu_total_followers);
      else if (widget.field === "audienceCredibility")
        widget.value = data[week].audiencelikers.data.audience_credibility.toFixed(2) * 100 + "%";
    });
    setWidgetArray(widgetArray);
  };

  //process country data and extract top city and country for widgets
  const processCountryData = (data) => {
    const worldMapData = [];
    const countryData = [];
    const cityData = [];
    data.countries.forEach((stat) => {
      worldMapData.push({
        country: stat.code,
        value: stat.weight * 100,
      });
      countryData.push({
        title: stat.name,
        value: stat.weight.toFixed(2),
        percentage: stat.weight * 100,
        color: "",
      });
    });

    data.cities.forEach((stat, index) => {
      cityData.push({
        title: stat.name,
        value: stat.weight.toFixed(2) * 100,
        percentage: stat.weight * 100,
        color: progressColors2[index],
      });
    });
    setWorldMapData(worldMapData);
    setCountryData(countryData);
    setCityData(cityData);
    widgetArray?.map((widget) => {
      if (widget.field === "topcountry") widget.value = countryData[0] ? countryData[0].title : "Not found";
      else if (widget.field === "topcity") widget.value = cityData[0] ? cityData[0].title : "Not found";
    });
    setWidgetArray(widgetArray);
  };

  //process age gender data and extract top gender and it's count for widgets
  const processAgeGenderData = (data) => {
    const maleAges = [];
    const femaleAges = [];
    let totalmale = 0;
    let totalfemale = 0;
    for (let i = 0; i < data.audience_genders?.length; i++) {
      if (data.audience_genders[i].code.toLowerCase() === "male") {
        totalmale = (data.audience_genders[i].weight * 100).toFixed(2);
        setTotalMale(totalmale);
      } else if (data.audience_genders[i].code.toLowerCase() === "female") {
        totalfemale = (data.audience_genders[i].weight * 100).toFixed(2);
        setTotalFemale(totalfemale);
      }
    }
    for (let i = 0; i < data.audience_genders_per_age?.length; i++) {
      maleAges.push((data.audience_genders_per_age[i].male * 100).toFixed(2));
      femaleAges.push((data.audience_genders_per_age[i].female * 100).toFixed(2));
    }
    let ageMean = 0;
    data.audience_ages.forEach((age) => {
      const midpoint = (parseInt(age.code.split("-")[0]) + parseInt(age.code.split("-")[1])) / 2;
      if (!isNaN(midpoint)) {
        ageMean += midpoint * age.weight;
      }
    });
    widgetArray?.map((widget) => {
      if (widget.field === "age") widget.value = Math.floor(ageMean);
      if (widget.field === "gender") {
        if (totalmale > totalfemale) {
          widget.value = `${totalmale}%`;
          widget.title = "Male";
        } else {
          widget.value = `${totalfemale}%`;
          widget.title = "Female";
        }
      }
    });
    setWidgetArray(widgetArray);

    setMaleData(maleAges);
    setFemaleData(femaleAges);
  };

  //process reacability data
  const processReachabilityData = (data) => {
    if (data) {
      const reachData = [];
      data.forEach((stat) => {
        reachData.push(Math.floor(stat.weight * 100));
      });
      setReachabilityData(reachData);
    }
  };

  //process ethinicity graphs
  const processEthinicityData = (data) => {
    const ethnicityData = [];
    if (data) {
      data.forEach((stat, index) => {
        ethnicityData.push({
          title: stat.name,
          value: stat.weight.toFixed(2),
          percentage: stat.weight * 100,
          color: progressColors1[index],
        });
      });
    }
    setEthinicityData(ethnicityData);
  };

  //process interest graphs
  const processInterestData = (data) => {
    const interestData = [];
    data.forEach((stat, index) => {
      interestData.push({
        title: stat.name,
        value: stat.weight.toFixed(2) * 100,
        percentage: stat.weight * 100,
        color: progressColors1[index],
      });
    });
    setInterestData(interestData);
  };

  //process language graphs
  const processLangugageData = (data) => {
    const languageData = [];
    data.forEach((stat, index) => {
      languageData.push({
        title: stat.name,
        value: stat.weight.toFixed(2) * 100,
        percentage: stat.weight * 100,
        color: progressColors2[index],
      });
    });
    setLanguageData(languageData);
  };

  //process brand graphs
  const processBrand = (data) => {
    const brandData = [];
    if (data) {
      data.forEach((stat, index) => {
        brandData.push({
          name: stat.name,
          value: Math.floor(stat.weight * 100),
        });
      });
    }
    setBrandData(brandData);
  };

  //change week
  const sendSelectedWeek = (event) => {
    setWeek(event);
  };

  return (
    <Box>
      <AudienceInsightsFilter weekData={weekData} sendSelectedWeek={sendSelectedWeek} />
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <Grid container spacing={3} style={{ background: "#E2EDFC", padding: "15px" }}>
            {widgetArray?.map((widgetData) => (
              <Grid item xs={2}>
                <AudienceWidget widget={widgetData} />
              </Grid>
            ))}
          </Grid>
          <br />
          <h4>
            <b className={styles.insightTitle}>Audience Demographics</b>{" "}
          </h4>
          <hr />
          <AudienceInsightsGraph
            totalMale={totalMale}
            totalFemale={totalFemale}
            maleData={maleData}
            femaleData={femaleData}
            reachabilityData={reachabilityData}
            countryData={countryData}
            worldMapData={worldMapData}
            ethnicityData={ethnicityData}
            cityData={cityData}
            interestData={interestData}
            languageData={languageData}
            brandData={brandData}
          />
        </>
      ) : (
        <div className={styles2.container}>
          <div className={styles2.child}> Not enough Instagram posts on this campaign yet for this report. Stories are not supported for this report right now.</div>
        </div>
      )}
    </Box>
  );
};

export default AudienceInsightsSnapshot;
