import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import Carousel from "react-material-ui-carousel";
import { number, object } from "prop-types";
import { ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";
import styles from "../../../../styles/internal/youtube.module.css";
import YouTubeShare from "../../../../styles/icons/youtubeshare";
import { showIcon, statusMapping, stringAvatar } from "./CommonAssets";
import PostActionButton from "./PostActionButton";
import { GetPostText, RenderCardMedia } from "./mediacontent";
import { renderData } from "./platform-asset-calculator";

const YoutubePost = ({ formData, cid }) => {
  return (
    <div className="d-flex flex-nowrap example-parent">
      {!formData.attachments && (
        <Avatar
          sx={{ width: 55, height: 55 }}
          {...stringAvatar(formData.influencerName)}
        />
      )}
      <span className="order-2 p-2 col-example" style={{ width: "100%" }}>
        <div style={{ marginBottom: "10px" }}>
          <div
            className="d-flex justify-content-between"
            style={{ marginBottom: "10px" }}
          >
            <span className="header">{formData.influencerName}</span>
            <div className="d-flex justify-content-end">
              {showIcon(formData.platform)}
              {formData.preview && (
                <PostActionButton id={formData.id} cid={cid} />
              )}
            </div>
          </div>
          {formData.preview && (
            <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
              {RenderCardMedia(formData)}
            </Carousel>
          )}
          {!formData.preview && (
            <p>{renderData(formData.postContent, formData.preview)}</p>
          )}
          {formData.preview && (
            <p>{renderData(formData.postContent, formData.preview)}</p>
          )}
          {formData.preview && formData.postContent?.length === 0 && (
            <p style={{ color: "transparent" }}>No content</p>
          )}
          <span className={styles.views}>
            No Views &nbsp;&nbsp;
            {new Date().toDateString()}
          </span>
          <div style={{ marginTop: "5px" }}>
            {!formData?.attachments && (
              <Button
                style={{
                  backgroundColor: statusMapping?.filter(
                    (value) => value.id === formData.currentStatus
                  )[0]?.color,
                  width: "100%",
                  fontSize: "12px",
                  borderRadius: "10px",
                }}
                variant="contained"
              >
                {GetPostText(formData)}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex align-items-end example-parent justify-content-between">
          <div className="p-2 flex-fill col-example text-left">
            <ThumbUpOutlined />
            &nbsp; Like
          </div>
          <div className="p-2 flex-fill col-example text-left">
            <ThumbDownOutlined />
            &nbsp; Dislike
          </div>
          <div className="p-2 flex-fill col-example text-left">
            <YouTubeShare />
            &nbsp; Share
          </div>
        </div>
      </span>
    </div>
  );
};

YoutubePost.propTypes = {
  formData: object.isRequired,
  cid: number.isRequired,
};

export default YoutubePost;
