import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getShortLinks } from "../../../../services/campaignAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import Loader from "../../../common/loader";

const CampaignLinksShortUrlsDialog = ({ urlId, open, setOpen }) => {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const tableHeaders = [
    {
      id: 1,
      label: "Influencer",
      mappedValue: "Influencer",
      field: "influencer",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: false,
    },
    {
      id: 2,
      label: "URL",
      mappedValue: "URL",
      field: "url",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: false,
    },
    {
      id: 3,
      label: "Created On",
      mappedValue: "Created On",
      field: "createdAt",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
      sortable: false,
    },
  ];

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    const shortLinks = await getShortLinks(urlId, user, dispatch);
    if (shortLinks.status === true) {
      createTableData(shortLinks.response);
    } else {
      if (shortLinks.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: shortLinks.response?.message,
          type: "error",
        })
      );
    }
  };

  const createTableData = (tableDataResponse) => {
    if (tableDataResponse?.length > 0) {
      const tableData = tableDataResponse?.map((row) => {
        const obj = { ...row };
        obj.influencer = row.influencerName || "-";
        obj.url = (
          <a href={row.shortUrl} rel="noreferrer" target="_blank">
            {row.shortUrl}
          </a>
        );
        return obj;
      });
      setTableData(tableData);
    }
    setTableLoading(false);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "auto",
          width: "100%",
        },
      }}
    >
      <DialogTitle>Short URLs</DialogTitle>
      <DialogContent>
        {!tableLoading ? (
          <>
            {tableData?.length > 0 ? (
              <CustomizedTable
                tableHeaders={tableHeaders}
                bodyColumns={tableData}
                pagination={false}
                nested={true}
              />
            ) : (
              <div className={styles.container}>
                <div className={styles.child}>
                  {" "}
                  No Short URLs created for this URL.
                </div>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignLinksShortUrlsDialog;
