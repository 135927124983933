import React, { useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PropTypes from "prop-types";


const StyledBtn = styled(Button)({
  position: 'fixed',
  bottom: '10%',
  transition: "all 0.3s ease-in-out",
  borderRadius: "50%",
  zIndex: 10,
  width: "60px",
  height: "60px"
})

const ScrollToTop = (props) => {
  const { verticalScrollOffset = 900, btnSxProps } = props;

  const [isVisible, setIsVisible] = useState(false);

  const visibilityHandler = () => {
    const scrolledOffset = document.documentElement.scrollTop;
    if (scrolledOffset > verticalScrollOffset)
      setIsVisible(true);
    else
      setIsVisible(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", visibilityHandler);
    return () => {
      window.removeEventListener("scroll", visibilityHandler);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <StyledBtn
        variant='contained'
        sx={{ opacity: isVisible ? "1" : "0", left: "15%", transform: isVisible ? "translateX(100%)" : "translateX(-100%)", ...btnSxProps }}
        onClick={scrollToTop}
      >
        <ArrowUpwardIcon />
      </StyledBtn>
    </>
  )
}

ScrollToTop.propTypes = {
  verticalScrollOffset: PropTypes.number,
  btnSxProps: PropTypes.object
}

export default ScrollToTop