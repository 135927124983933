import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  OutlinedInput,
  Radio,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";
import { InfoToolTip } from "../../../../../../components/common/toolTip";
import { getFormattedDate } from "../../../../../../utility/momentManipulations";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CheckboxDropdownFilter = (props) => {
  const { options, setOption, selectedOption, name, filterKey, helperText, width, checkBox, weekBox } = props;
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(checkBox !== undefined ? checkBox : true);
  const anchorRef = React.useRef(null);

  const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  const selectOption = (name) => {
    if (!checked) {
      handleClose();
    }
    setOption(name, filterKey);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: parseInt(width) }}>
        <InputLabel id="demo-multiple-checkbox-label">{name}</InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedOption}
          sx={{ background: "white" }}
          input={<OutlinedInput id="select-multiple-chip" label={name} />}
          renderValue={(selected) => {
            if (selected?.length <= 1) {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected?.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              );
            } else {
              const visibleChips = selected.slice(0, 1);
              const remainingCount = selected?.length - 1;
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {visibleChips?.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                  <span style={{ paddingTop: "5px" }}>{`+${remainingCount} more`}</span>
                </Box>
              );
            }
          }}
          MenuProps={MenuProps}
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
        >
          <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
            <MenuItem onClick={() => selectOption("All")}>
              {checked && <Checkbox checked={selectedOption?.length === options?.length} />}
              {/* {!checked && <Radio checked={selectedOption?.length === options?.length} />} */}
              <ListItemText component={"span"}>{"All"}</ListItemText>
            </MenuItem>

            {options?.map((name) => (
              <MenuItem key={name} value={name} onClick={() => selectOption(name)}>
                {checked && <Checkbox checked={selectedOption.indexOf(name) > -1} />}
                {/* {!checked && <Radio checked={selectedOption.indexOf(name) > -1} />} */}
                {!weekBox && (
                  <Tooltip title={name} placement="top">
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{
                        style: {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '200px',
                        },
                      }}
                    />
                  </Tooltip>
                )}
                {weekBox && (
                  <ListItemText
                    primary={`${name["weekNo"]} (${getFormattedDate(name["startDate"], "DD MMM YY")} to ${getFormattedDate(
                      name["endDate"],
                      "DD MMM YY"
                    )})`}
                  />
                )}
              </MenuItem>
            ))}
          </MenuList>
        </Select>
        {helperText?.length > 0 && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default CheckboxDropdownFilter;
