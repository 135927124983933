import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const { isOpen, alertDialogCloseHandler, unlockCount } = props;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => alertDialogCloseHandler(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          View the locked influencers?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Use {unlockCount} credits to view {unlockCount} influencer(s)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => alertDialogCloseHandler(false)}>Cancel</Button>
          <Button onClick={() => alertDialogCloseHandler(true)} autoFocus>
            Agree & View
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}