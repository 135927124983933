import DOMPurify from "dompurify";

const renderData = (postContent, preview) => {
  const shortenContent = postContent.substring(0, 20);
  let content = "";
  if (postContent?.length > 20 && preview) content = `${shortenContent}...`;
  else content = postContent;
  const clean = DOMPurify.sanitize(content);
  return <p dangerouslySetInnerHTML={{ __html: clean }} />;
};

export { renderData };
