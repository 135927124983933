import React from 'react';
import { Card, CardContent, Grid, Typography, Link } from '@mui/material';
import moment from 'moment';

const ReportList = ({ data, title = "Reports" }) => {
    const commonTextStyle = { fontWeight: '500', fontSize: '12px', color: '#030229' };
    const linkStyle = {
        color: '#1a73e8',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    };

    const getShortLinkText = (url) => {
        try {
            const urlObj = new URL(url);
            return urlObj.hostname.replace('www.', '');
        } catch (error) {
            return "Click here";
        }
    };

    return (
        <Card sx={{ borderRadius: '10px', mt: 3 }}>
            <CardContent>
                <Typography variant="h6" sx={{ ...commonTextStyle, fontWeight: 'bold', mb: 2, fontSize: '16px' }}>
                    {title}
                </Typography>
                <Grid container spacing={1}>
                    {['Title', 'Link', 'Date', 'Data Update Time']?.map((header, index) => (
                        <Grid item xs={3} key={index}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>{header}</Typography>
                        </Grid>
                    ))}
                </Grid>
                <hr style={{ border: '0.5px solid #E0E0E0', margin: '8px 0' }} />
                {data?.map((item, index) => (
                    <Grid container spacing={2} key={index} sx={{ mt: 1 }}>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {item.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {item.link ? (
                                    <Link 
                                      href={item.link} 
                                      target="_blank" 
                                      rel="noopener noreferrer" 
                                      sx={linkStyle}
                                    >
                                        {getShortLinkText(item.link)}
                                    </Link>
                                ) : (
                                    "No Link Available"
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {moment(item.date).format('MMMM D, YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {moment(item.dataUpdateTime).format('MMMM D, YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
};

export default ReportList;
