
/* 
  get session storge data
*/
const getCampaignName = () => {
  const cname = sessionStorage.getItem("campaignName");
  return cname
};

const setCampaignName = (name) =>{
  sessionStorage.setItem("campaignName", name);
}

export { getCampaignName, setCampaignName};
