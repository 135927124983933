import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem, styled } from '@mui/material';
import { array, func, shape, string } from 'prop-types';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "#000000",
    backgroundColor: "#ffffff",
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: "#fffff3",
      },
    },
  },
}));

const AnalysisSelector = (props) => {
  const { dropdownData, dropdownItemClickHandler, selectedItem } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemClickHandler = (item) => {
    dropdownItemClickHandler(item);
    handleClose();
  }

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        sx={{ background: "rgb(54,123,245)", color: '#ffffff', '&:hover,&:active': { background: "rgba(54,123,245,0.8)" }, minWidth: "200px", borderRadius: "9999px" }}
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {selectedItem?.name}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {dropdownData?.map((item, idx) => (
          <MenuItem onClick={() => itemClickHandler(item)} selected={item.id === selectedItem.id} disableRipple key={`anlaytic-item-${idx}`}>
            {item.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

AnalysisSelector.propTypes = {
  dropdownData: shape({ type: string.isRequired, items: array.isRequired }).isRequired,
  dropdownItemClickHandler: func,
  selectedItem: string
}

export default AnalysisSelector
