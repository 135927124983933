import { CancelOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Country } from "country-state-city";
import React from "react";
import styles from "../../../../styles/internal/Discovery/Discovery.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignNameForBreadcrumbs } from "../../../../services/campaigns";
import { addNewGroup, getAllGroupsListForCampaign } from "../../../../services/group";
import { PlatformIdMapping, snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import { addDiscoveryUserToGroup } from "../../../../services/discovery";
import { notify } from "../../../../redux/features/system/systemAlert";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../common/StyledComponents";
import { useStyles } from "../../../campaign/newCampaign/staticStyling";
import constants from "../../../../config/constantMessages.json";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const AddToListDialog = (props) => {
  const { avatarInfo, handleListToDialogModalClose } = props;
  const classes = useStyles();

  const user = useSelector((state) => state.user); //get loggedIn user state
  const [state, setState] = React.useState({
    groupName: "",
    groupDescription: "",
    country: "",
  });
  const groupNameCount = state.groupName?.length;
  const groupDescriptionCount = state.groupDescription?.length;
  const [campaign, setCampaign] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isGroupLoading, setIsGroupLoading] = React.useState(false);

  const [campaignList, setCampaignList] = React.useState([]);
  const [groupList, setGroupList] = React.useState([]);
  const [showMessage, setShowMessage] = React.useState(false);
  const [createGroup, setCreateGroup] = React.useState(false);
  const [countries, setCountries] = React.useState([]);

  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const { picUrl, name, userId, platform } = avatarInfo;

  const dispatch = useDispatch();

  React.useEffect(() => {
    setCountries(Country.getAllCountries());
    setIsLoading(true);
    getCampaigns();
  }, []);

  const getCampaigns = async () => {
    const response = await getCampaignNameForBreadcrumbs(user, dispatch);
    setIsLoading(false);
    setCampaignList(response?.response);
  };

  const getGroups = async (newValue) => {
    setIsGroupLoading(true);
    setCampaign(newValue);
    const groups = await getAllGroupsListForCampaign(newValue.id.toString(), [platform], user, dispatch);
    setGroupList(groups.response);
    setIsGroupLoading(false);
  };

  const selectGroup = (group) => {
    // Toggle the showMessage state to display the message
    setShowMessage(!showMessage);
    setSelectedGroup(group);
  };

  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = (
        <>
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
        </>
      );
    } else if (!isLoading && campaignList?.length > 0) {
      content = (
        <>
          <Autocomplete
            id="size-small-filled"
            aria-required
            required
            options={campaignList}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Campaign Name"
                placeholder="<Choose from drop down the campaign you want to add this influencer>"
                required
              />
            )}
            defaultValue={campaign}
            value={campaign}
            onChange={(event, newValue) => {
              getGroups(newValue);
            }}
          />
          {groupList?.length > 0 && campaign !== null && (
            <h4 style={{ textAlign: "center", paddingTop: "10px" }}>
              <b>Select a Group</b>
            </h4>
          )}{" "}
          <div id={styles.listOfLists}>
            {groupList?.length === 0 && campaign === null && (
              <p style={{ fontSize: "14px" }}>
                <i>Please select the campaign in which you want to add {name}</i>
              </p>
            )}
            {isGroupLoading ? (
              <>
                <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
                <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
                <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
                <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
              </>
            ) : (
              <>
                <ul id={styles.searchUL}>
                  {/* <li>Create a group</li> */}
                  {groupList?.map((group) => (
                    <li
                      onClick={() => selectGroup(group)}
                      key={group.name}
                      value={group.id}
                      style={{ margin: "10px", cursor: "pointer" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography sx={{ ...textColor[group.platform] }}>
                            {snapshotHeaderIcons[group.platform]}
                            <span
                              style={{
                                color: "black",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "14px",
                                display: "inline-block",
                                marginLeft: "8px",
                              }}
                            >
                              {group.name}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            <span
                              style={{
                                color: "black",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "12px",
                                display: "inline-block",
                              }}
                            >
                              <i>({group.noOfInfluencers} Influencers)</i>
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          {campaign !== null && groupList?.length > 0 && (
            <div style={{ margin: "10px", textAlign: "center" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <hr style={{ flexGrow: 1, borderTop: "2px solid black" }} />
                <span style={{ margin: "0 10px", color: "black", fontWeight: "bold" }}>OR</span>
                <hr style={{ flexGrow: 1, borderTop: "2px solid black" }} />
              </div>
            </div>
          )}{" "}
          {campaign !== null && groupList?.length === 0 && (
            <p style={{ textAlign: "center" }}>
              {" "}
              No {platform} groups found for {campaign?.name} campaign
            </p>
          )}
          {campaign !== null && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StyledStaticButtonGroup color="primary" exclusive aria-label="Campaign" size="small">
                  <StyledStaticButton value="active" className={styles.staticButton} onClick={createNewGroup}>
                    <b>Create Group</b>
                  </StyledStaticButton>
                </StyledStaticButtonGroup>
              </div>
            </>
          )}
        </>
      );
    } else {
      content = (
        <Typography fontSize={"20px"} mb={"1rem"} textAlign={"center"}>
          No campaigns information available.
        </Typography>
      );
    }
    return content;
  };

  const addToGroup = async () => {
    dispatch(
      notify({
        message: `Please wait... we are adding ${name} to ${selectedGroup.name}`,
        type: "info",
      })
    );
    const groupData = {
      lid: selectedGroup.id,
      iid: [parseInt(userId)],
      status:[2],
      type: 1,
      platform: platform,
      group:selectedGroup.name,
    };
    const response = await addDiscoveryUserToGroup(groupData, user, dispatch);
    if (response?.response.status === "success") {
      dispatch(
        notify({
          message: `${name} has been added successfully in ${selectedGroup.name}`,
          type: "success",
        })
      );
      handleListToDialogModalClose();
    }
  };

  const createNewGroup = async () => {
    setCreateGroup(true);
  };

  const selectCountry = (event) => {
    const selectedCountry = event.target.value;
    setState({ ...state, country: selectedCountry });
  };

  const addGroup = async () => {
    const { groupName, groupDescription, country } = state;
    const groupDetails = {
      name: groupName,
      cid: campaign.id,
      platform: PlatformIdMapping[platform],
      description: groupDescription,
      tags: "",
      notes: "",
      tz: 0,
      geoType: 2,
      country,
      state: "",
      city: "",
      currency: "",
      infBudget: 0,
      paymentTerm: "",
      minFollowers: 0,
      maxFollowers: 0,
      noOfDays: 0,
      paymentDate: "",
      postContent: "",
      dos: constants.GROUP_DOS,
      donts: constants.GROUP_DONTS,
      status: 1,
      addedFrom: "empowr",
      faqs: "[]",
    };
    dispatch(
      notify({
        message: "We are adding your group for this campaign",
        type: "info",
      })
    );
    const response = await addNewGroup(groupDetails, user, dispatch);
    if (response?.response.status === "success") {
      const groupData = {
        lid: response?.response.id,
        iid: [parseInt(userId)],
        type: 1,
        platform: platform,
        group:response.name,
      };
      const gorupResponse = await addDiscoveryUserToGroup(groupData, user, dispatch);
      if (gorupResponse.response.status === "success") {
        dispatch(
          notify({
            message: `Group created successfully and ${name} has been added to the group `,
            type: "success",
          })
        );
        handleListToDialogModalClose();
      }
    }
  };
  return (
    <>
      <Box
        bgcolor={"#EAEAEA"}
        border="4px solid #6B747A"
        borderRadius={"32px"}
        p={"3rem"}
        maxWidth={"640px"}
        position={"relative"}
      >
        <Grid container>
          <Grid item>
            <Box position={"absolute"} top="4%" left="8%" paddingTop="10px" display={"flex"} alignItems={"center"}>
              {/* <Box mr={"1rem"}><CloudDownloadOutlinedIcon sx={{ cursor: "pointer" }} /></Box> */}
              <Box component={"div"} onClick={handleListToDialogModalClose}>
                {createGroup ? (
                  <Typography fontSize={"28px"}>
                    {" "}
                    <b>Create Group and Add Influencer</b>
                  </Typography>
                ) : (
                  <Typography fontSize={"28px"}>
                    {" "}
                    <b>Add Influencer To Group</b>
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box position={"absolute"} top="4%" right="4%" display={"flex"} alignItems={"center"}>
              {/* <Box mr={"1rem"}><CloudDownloadOutlinedIcon sx={{ cursor: "pointer" }} /></Box> */}
              <Box component={"div"} onClick={handleListToDialogModalClose}>
                <CancelOutlined sx={{ cursor: "pointer" }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />
        <Box mx="auto" textAlign={"center"} mb={"1rem"}>
          <Box
            component={"img"}
            src={picUrl}
            sx={{ width: "80px", height: "80px", borderRadius: "32px", border: "4px solid #687076" }}
          />
          <Typography mt="12px" fontSize={"26px"} color="#687076">
            {name}
          </Typography>
        </Box>
        {!showMessage && !createGroup && renderContentHandler()}
        {showMessage && (
          <div className={styles.message}>
            <p>
              Are you sure you want to add <b>{name}</b> in <b>{selectedGroup.name}</b> group?
            </p>
            <div className={styles.buttonContainer}>
              <button
                className={`${styles.button} ${styles.leftButton}`}
                onClick={() => {
                  setShowMessage(false);
                  setSelectedGroup(null);
                }}
              >
                No, go back
              </button>
              <button className={`${styles.button2} ${styles.centerButton}`} onClick={() => addToGroup()}>
                I confirm
              </button>
            </div>
          </div>
        )}

        {createGroup && (
          <>
            <Grid container item spacing={1} xs={12} style={{ paddingTop: 20 }}>
              <Grid item>
                <TextField
                  sx={{ width: "100%" }}
                  variant="filled"
                  label="Group Name"
                  placeholder="<Enter Group Name>"
                  type="text"
                  required
                  helperText="Max 48 Chars"
                  value={state.groupName}
                  onChange={(e) => setState({ ...state, groupName: e.target.value })}
                  InputProps={{
                    endAdornment: <div className={classes.counter}>{groupNameCount}/48</div>,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  sx={{ width: "100%" }}
                  variant="filled"
                  label="Group Description"
                  placeholder="<Enter Group Description>"
                  type="text"
                  required
                  helperText="Max 256 Chars"
                  value={state.groupDescription}
                  onChange={(e) => setState({ ...state, groupDescription: e.target.value })}
                  InputProps={{
                    endAdornment: <div className={classes.counter}>{groupDescriptionCount}/256</div>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="demo-select-small-label">Select a country</InputLabel>

                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="<Select a country>"
                    onChange={selectCountry}
                    value={state.country}
                    MenuProps={MenuProps}
                  >
                    {countries?.map((country) => (
                      <MenuItem key={country.id} value={country}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1} xs={12}>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start", paddingTop: "20px" }}>
                <button
                  className={`${styles.button} ${styles.leftButton}`}
                  onClick={() => {
                    setCreateGroup(false);
                  }}
                >
                  No, go back
                </button>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", paddingTop: "20px" }}>
                <button
                  disabled={!state.groupName || !state.groupDescription || !state.country}
                  className={`${styles.button2} ${styles.centerButton}`}
                  onClick={() => addGroup()}
                >
                  Create and add Influencer
                </button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default AddToListDialog;
