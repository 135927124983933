import { createSlice } from "@reduxjs/toolkit";

const MenuSlice = createSlice({
  name: "menu",
  initialState: {
    data: {},
    permissions: {},
  },
  reducers: {
    defineMenu: (state, action) => {
      state.data = action.payload;
    },
    definePermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const { defineMenu, definePermissions } = MenuSlice.actions;

export default MenuSlice.reducer;
