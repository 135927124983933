import { createSignedRequest, requestConfig } from "../services/common";
import config from "../config/main.json";

const { API_HOST } = config;

const fetchSignedRequest = async (request, user) => {
  try {
    const serRequest = {
      hostname: request.hostname,
      method: request.method,
      url: `${API_HOST}/${request.url}`,
      data: JSON.stringify(request.data),
    };
    const signedRequest = createSignedRequest(serRequest, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    return { status: response.status, response: await Promise.resolve(response.json()) };
  } catch (error) {
    return { status: false, response: error }
  }

}

export default fetchSignedRequest;