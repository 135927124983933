import { Clear, MoreHorizOutlined, Refresh, Remove, Edit } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem, Backdrop, Button } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { PropTypes } from "prop-types";
import { refreshSinglePost, removePublicationFromCampaign } from "../../../../services/publication";
import { PlatformIdMapping } from "../../../../utility/plaformIcons";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import EditPostModal from "./EditPostModal";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { StyledStaticRoundedButton, StyledStaticButtonRoundedGroup } from "../../../common/StyledComponents";

const CampaignPublicationsActionButton = ({ influencerAccount, setDataRefreshed }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentPostData, setCurrentPostData] = useState(null);
  const [removePublicationModal, setRemovePublicationModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <Refresh htmlColor="#B5B5C3" />,
      label: "Update Publication",
      name: "updatepublication",
    },
    {
      id: 2,
      icon: <Clear htmlColor="#B5B5C3" />,
      label: "Remove Publication",
      name: "removepublication",
    },
    {
      id: 3,
      icon: <Edit htmlColor="#B5B5C3" />,
      label: "Edit Post",
      name: "editPost",
    },
    // {
    //   id: 4,
    //   icon: <LabelTwoTone htmlColor="#B5B5C3" />,
    //   label: "Add/View Tag",
    //   name: "addviewtag",
    // },
    // {
    //   id: 5,
    //   icon: <PermContactCalendarOutlined htmlColor="#B5B5C3" />,
    //   label: "View Profile",
    //   name: "viewprofile",
    // },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "updatepublication": {
        updatePublication();
        break;
      }
      case "removepublication": {
        setRemovePublicationModal(true);
        break;
      }
      case "editPost": {
        openEditPostModal();
        break;
      }
      default:
        break;
    }
    handleClose();
  };

  const openEditPostModal = () => {
    const postData = {
      postId: influencerAccount.postId,
      lpId: influencerAccount.id,
      postUrl: influencerAccount.postUrl,
      influencerId: influencerAccount.infId,
      organicIds: influencerAccount.organicIds || "",
      directIds: influencerAccount.directIds || "",
      platform: influencerAccount.platform,
      postLang: influencerAccount.postLang || "English",
      groupId: influencerAccount.groupId,
    };
    setCurrentPostData(postData);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setCurrentPostData(null);
  };

  const handleUpdateSuccess = () => {
    setDataRefreshed(true);
  };

  const updatePublication = async () => {
    dispatch(
      notify({
        message: "We have started refreshing your post. Please check again in sometime",
        type: "info",
      })
    );
    const response = await refreshSinglePost(
      +params.get("id"),
      influencerAccount.groupId,
      parseInt(influencerAccount.infId),
      PlatformIdMapping[influencerAccount.platform],
      "post",
      influencerAccount.postId,
      influencerAccount.postUrl,
      user,
      dispatch
    );
    setDataRefreshed(true);

    influencerAccount.status = "Processing";
  };

  const removePublication = async () => {
    dispatch(
      notify({
        message: "We are removing your publication. Please check again in sometime",
        type: "info",
      })
    );
    const data = {
      cid: +params.get("id"),
      postIds: [influencerAccount.postId],
      isSaas: true,
      agencyId: user.agencyId,
    };
    const response = await removePublicationFromCampaign(data, user, dispatch);
    if (response.status === true) {
      setDataRefreshed(true);
      dispatch(
        notify({
          message: "Publication removed successfully",
          type: "success",
        })
      );
    } else {
      dispatch(
        notify({
          message: "Failed to remove publication.",
          type: "error",
        })
      );
    }
  };
  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={styles.menuitem}
        >
          {arrayOfOptions?.map((value) => (
            <div key={value.id}>
              <MenuItem onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < arrayOfOptions?.length && (
                <Divider key={`divider-${value.id}`} sx={{ my: 0.5 }} />
              )}
            </div>
          ))}
        </Menu>
      </Box>

      {/* Edit Post Modal */}
      {currentPostData && (
        <EditPostModal
          open={editModalOpen}
          handleClose={handleEditModalClose}
          postData={currentPostData}
          onUpdateSuccess={handleUpdateSuccess}
        />
      )}


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={removePublicationModal}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Fade in={removePublicationModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              borderRadius: "15px",
              boxShadow: 24,
              p: 4,
            }}
          >
            Are you sure you want to remove {influencerAccount.postId} publication?
            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "40px" }}>
              <Button
                variant="outlined"
                style={{
                  width: "180px",
                  height: "38px",
                  color: "#474747",
                  borderColor: "#474747",
                  borderRadius: "16px",
                  marginTop: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                onClick={() => setRemovePublicationModal(false)}
              >
                Cancel
              </Button>
              <StyledStaticButtonRoundedGroup
                color="primary"
                size="small"
                exclusive
                style={{ height: "30px", width: "200px" }}
                aria-label="Template"
              >
                <StyledStaticRoundedButton value="active" onClick={removePublication}>
                  Yes, Remove
                </StyledStaticRoundedButton>
              </StyledStaticButtonRoundedGroup>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

CampaignPublicationsActionButton.propTypes = {
  influencerAccount: PropTypes.shape({
    postId: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    postUrl: PropTypes.string.isRequired,
    infId: PropTypes.number.isRequired,
    organicIds: PropTypes.string,
    directIds: PropTypes.string,
    platform: PropTypes.string.isRequired,
    postLang: PropTypes.string,
    groupId: PropTypes.number.isRequired,
    status: PropTypes.string,
  }).isRequired,
  setDataRefreshed: PropTypes.func.isRequired,
};

export default CampaignPublicationsActionButton;
