import { Grid } from "@mui/material";
import styles from "../../../styles/internal/Campaign/CampaignList.module.css";

const tableHeaders = [
  {
    id: 1,
    label: (
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12}>
          Campaign
        </Grid>
      </Grid>
    ),
    mappedValue: "Campaign",
    field: "name",
    headeralignment: "left", 
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 2,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Publications
        </Grid>
      </Grid>
    ),
    mappedValue: "Publications",
    field: "postCount",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Influencers
        </Grid>
      </Grid>
    ),
    mappedValue: "Influencers",
    field: "influencerCount",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 4,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={12}>
        Eng. This Week
        </Grid>
      </Grid>
    ),
    mappedValue: "This Week",
    field: "week",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 5,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Days Left
        </Grid>
      </Grid>
    ),
    mappedValue: "Days Left",
    field: "endDate",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 6,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={3}>
          Groups
        </Grid>
      </Grid>
    ),
    mappedValue: "Groups",
    field: "listCount",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: false,
  },
  {
    id: 7,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Start Date
        </Grid>
      </Grid>
    ),
    mappedValue: "Start Date",
    field: "startDate",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: false,
  },
  {
    id: 8,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={3}>
          Brand
        </Grid>
      </Grid>
    ),
    mappedValue: "Brand",
    field: "brand",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: false,
  },
  {
    id: 9,
    label: (
      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          Status
        </Grid>
      </Grid>
    ),
    mappedValue: "Status",
    field: "current_status",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: true,
    show: true,
  },
  {
    id: 10,
    label: "",
    mappedValue: "",
    field: "action",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
];

const getTableContent = (content) => {
  return (
    <Grid container spacing={1} className={styles.bodycell}>
      <Grid item xs={2}></Grid>
      <Grid item xs={4} className={styles.bodycell}>
        {content}
      </Grid>
    </Grid>
  );
};

const getExtrasContent = (content) => {
  return (
    <Grid container>
      <Grid item xs={5}></Grid>
      <Grid item xs={7} className={styles.bodycell} style={{ marginTop: 5 }}>
        {content}
      </Grid>
    </Grid>
  );
};
export { tableHeaders, getTableContent, getExtrasContent };
