import React, { useEffect } from "react";
import BaseAttribution from "../../components/landing/BaseAttribution";

function Attribution(props) {
  useEffect(() => {
    setBackground();
  }, []);

  const setBackground = () => {
    document.body.style.background = "white";
  };

  return (
    <div>
      <BaseAttribution /> <br />
    </div>
  );
}

export default Attribution;
