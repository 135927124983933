import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  TextField,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../../utility/oryxUtils/utils";
import { getLocationData } from "../service/fetchDataByFilter";
import { FilterTooltips } from "../static/FiltersTooltips";

const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const CustomFilter = ({
  showFilter,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  isChanged,
  setIsChanged,
  audFilterKey = "aud_location",
  infFilterKey = "inf_location",
  filterType = "location",
  fetchDataByFilterHandler = getLocationData,
  allFiltersData,
  setAllFiltersData,
  audReqKey = "",
  infReqKey = "",
  weight = "0.05",
  isMulti = true,
  customCols,
  label = "",
  selectedPlatform,
  isInfFilter = false,
}) => {
  const [audData, setAudData] = useState([]);
  const [infData, setInfData] = useState([]);
  const [ranges, setRanges] = useState({});
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (filterType === "location") {
      getData();
    }
  }, [filterType]);

  const getData = async () => {
    const data = await fetchDataByFilterHandler("KE", "", dispatch, user);
    isInfFilter ? setInfData(data) : setAudData(data);
  };

  const handleSelection = (event, selectedValues) => {
    setSelectedLocation(selectedValues);

    let newPayload = { ...payload };
    let newCustomCols = deepClone(customCols);
    let newAllFiltersData = deepClone(allFiltersData);

    let filterKey = isInfFilter ? infFilterKey : audFilterKey;
    let reqKey = isInfFilter ? infReqKey : audReqKey;

    if (filterType === "lookalikes") {
      let username = selectedValues["username"];
      if (username !== undefined) {
        let audpayload = { value: username };
        newPayload["aud_audience_type"] = "any";
        newPayload["sort"] = "audience_relevance";
        newPayload[filterKey] = audpayload;
        newAllFiltersData = utils.addObj(newAllFiltersData, filterKey);
        newAllFiltersData[0][filterKey][0] = audpayload;
        setPayload(newPayload);
        setAllFiltersData(newAllFiltersData);
      }
    } else {
      let latestLoc = "";
      let latestId = "";
      if (!isMulti) {
        latestLoc = selectedValues["name"];
        latestId = selectedValues["id"];
      } else {
        latestLoc = selectedValues[selectedValues?.length - 1]["name"];
        latestId = selectedValues[selectedValues?.length - 1]["id"];
      }
      newCustomCols = utils.addObj(newCustomCols, filterKey);
      let filterSet = { id: latestId, name: latestLoc };
      let filtersData = utils.addObj(newAllFiltersData, filterKey);
      if (
        latestLoc !== undefined &&
        !filtersData[0][filterKey].includes(latestLoc)
      ) {
        let locationPayload = [];
        if (!isMulti) {
          let payload = {};
          payload[reqKey] = selectedValues["id"];
          payload["weight"] = weight;
          newPayload[filterKey] = payload;
          filtersData[0][filterKey][0] = filterSet;
          newCustomCols[0][filterKey][0] = latestLoc;
        } else {
          selectedValues.forEach((loc) => {
            let payload = {};
            payload[reqKey] = loc["id"];
            payload["weight"] = weight;
            locationPayload.push(payload);
            if (
              !filtersData[0][filterKey].some(
                (existingFilter) =>
                  existingFilter.id === filterSet.id &&
                  existingFilter.name === filterSet.name
              )
            ) {
              filtersData[0][filterKey].push(filterSet);
            }
          });
          newPayload[filterKey] = locationPayload;
          newCustomCols[0][filterKey].push(latestLoc);
        }
        setAllFiltersData(filtersData);
        setSelectedCol(newCustomCols);
        setCustomCols(newCustomCols);
        setPayload(newPayload);
      }
    }
  };

  const handleRangeChange = (event, locId) => {
    const newRange = event.target.value;
    setRanges((prevRanges) => ({
      ...prevRanges,
      [locId]: newRange,
    }));

    let newPayload = { ...payload };
    let filterKey = isInfFilter ? infFilterKey : audFilterKey;

    newPayload[filterKey] = newPayload[filterKey]?.map((loc) => {
      if (loc.id === locId) {
        return { ...loc, weight: newRange };
      }
      return loc;
    });

    setPayload(newPayload);

    setAllFiltersData((prevData) => {
      let updatedData = deepClone(prevData);
      updatedData[0][filterKey] = updatedData[0][filterKey]?.map((loc) => {
        if (loc.id === locId) {
          loc.weight = newRange; 
        }
        return loc;
      });
      return updatedData;
    });
  };

  const cancelHandler = (selectedValues, name) => {
    let newPayload = { ...payload };
    let newCustomCols = deepClone(customCols);
    let newAllFiltersData = deepClone(allFiltersData);
    let filterKey = isInfFilter ? infFilterKey : audFilterKey;

    let index = newAllFiltersData[0][filterKey]
      ?.map((item) => item.id)
      .indexOf(selectedValues);
    if (index !== -1) {
      const filteredLocations = newAllFiltersData[0][filterKey]?.filter(
        (_, idx) => idx !== index
      );
      newPayload[filterKey] = filteredLocations;
      setPayload(newPayload);
      newCustomCols[0][filterKey] = utils.removeElement(
        newCustomCols[0][filterKey],
        name
      );
      setCustomCols(newCustomCols);
      setSelectedCol(newCustomCols);
      if (filteredLocations?.length > 0) {
        newAllFiltersData[0][filterKey] = filteredLocations;
      } else {
        delete newAllFiltersData[0][filterKey];
      }
      setAllFiltersData(newAllFiltersData);
      setIsChanged(!isChanged);
    }
  };

  const handleLocationInput = async (event, newInputValue) => {
    setInputValue(newInputValue);
  
    if (newInputValue !== "" && fetchDataByFilterHandler) {
      let data;
      
      if (filterType === "lookalikes") {
        data = await fetchDataByFilterHandler(
          newInputValue,
          selectedPlatform,
          "lookalike"
        );
        setAudData(data);
      } else {
        const args =
          filterType === "location"
            ? [newInputValue, "", dispatch, user]
            : [newInputValue, dispatch, user];
  
        data = await fetchDataByFilterHandler(...args);
        isInfFilter ? setInfData(data) : setAudData(data);
      }
    }
  };
  

  return (
    showFilter && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Tooltip
                title={FilterTooltips[filterType]?.audTooltipMsg || ""}
                placement="right-start"
              >
                <Box>
                  <Autocomplete
                    multiple={isMulti}
                    sx={{ width: "100%" }}
                    disableClearable
                    forcePopupIcon={false}
                    limitTags={0}
                    id={`filter-${isInfFilter ? infFilterKey : audFilterKey}`}
                    size="small"
                    popupIcon={""}
                    getOptionLabel={(option) => option?.name || ""}
                    renderTags={() => null}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    options={isInfFilter ? infData : audData}
                    value={selectedLocation}
                    inputValue={inputValue}
                    onChange={handleSelection}
                    onInputChange={handleLocationInput}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={utils.capitalizeFirstLetter(
                          label === "" ? filterType : label
                        )}
                        placeholder={utils.capitalizeFirstLetter(
                          label === "" ? filterType : label
                        )}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Tooltip>
              {/* {allFiltersData !== undefined &&
                allFiltersData[0][isInfFilter ? infFilterKey : audFilterKey] !==
                  undefined &&
                allFiltersData[0][isInfFilter ? infFilterKey : audFilterKey]?.map(
                  (item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        marginBottom: "8px",
                        border: "1px solid #ddd",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                        justifyContent: "space-between",
                      }}
                    >
                      <IconButton
                        onClick={() => cancelHandler(item.id, item.name)}
                        edge="start"
                        aria-label="delete"
                        sx={{
                          padding: "4px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <Box
                        sx={{
                          flexGrow: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginRight: "10px",
                        }}
                      >
                        {item.name}
                      </Box>
                      <Select
                        value={ranges[item.id] || item.weight || weight}
                        onChange={(e) => handleRangeChange(e, item.id)}
                        size="small"
                        sx={{
                          minWidth: 120,
                          backgroundColor: "#fff",
                        }}
                      >
                        {[
                          "0.05",
                          "0.1",
                          "0.15",
                          "0.2",
                          "0.25",
                          "0.3",
                          "0.35",
                          "0.4",
                          "0.45",
                          "0.5",
                          "0.55",
                          "0.6",
                          "0.65",
                          "0.7",
                          "0.75",
                          "0.8",
                          "0.85",
                          "0.9",
                          "0.95",
                        ]?.map((value, index) => (
                          <MenuItem key={index} value={parseFloat(value)}>
                            {`>${Math.round(parseFloat(value) * 100)}%`}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )
                )} */}
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  );
  
};

CustomFilter.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  setCustomCols: PropTypes.func.isRequired,
  setSelectedCol: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  setIsChanged: PropTypes.func.isRequired,
  audFilterKey: PropTypes.string.isRequired,
  infFilterKey: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  fetchDataByFilterHandler: PropTypes.func.isRequired,
  allFiltersData: PropTypes.array.isRequired,
  setAllFiltersData: PropTypes.func.isRequired,
  customCols: PropTypes.array.isRequired,
  label: PropTypes.string,
  isInfFilter: PropTypes.bool,
};

export default CustomFilter;
