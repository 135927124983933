import React, { useEffect } from "react";
import About from "../../components/landing/About";
import TopBrands from "../../components/landing/TopBrands";

function BaseAbout(props) {
  useEffect(() => {
    setBackground();
  }, []);

  const setBackground = () => {
    document.body.style.background = "white";
  };

  return (
    <div>
      <About /> <br />
      <TopBrands />
    </div>
  );
}

export default BaseAbout;
