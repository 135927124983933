import { FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import React from "react";

const SortBy = ({ sortByColumns, setSelectedValue, selectedValue }) => {
  const chooseColumn = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">Sort By</InputLabel>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{
          minWidth: "7vw",
          marginRight: "5px",
          borderRadius: "10px",
        }}
        value={selectedValue}
        onChange={chooseColumn}
        label="Sort By"
      >
        {sortByColumns?.map((column) => {
          return (
            <MenuItem value={column.value} key={column.value}>
              <ListItemText primary={column.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SortBy;
