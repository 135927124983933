import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StyledMenu from "../StyledMenu";
import utils from "../../../utility/oryxUtils/utils";

import { FormControl, Grid, RadioGroup, Tooltip, Radio } from "@mui/material";
import CustomFormControlLabel from "./common/CustomFormControlLabel";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const formItems = [
  { value: "ANY", label: "Any" },
  { value: "30", label: "1 month" },
  { value: "90", label: "3 month" },
  { value: "180", label: "6 month" },
];

const LastPost = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, type) => {
    let newPayload = payload;
    let lastPosted = "";
    if (event.target.value !== "ANY") {
      lastPosted = parseInt(event.target.value);
      const item = formItems.find((item) => +item.value === +lastPosted);
      let filterSet = { name: item.label, value: item.value };
      allFiltersData = utils.addObj(allFiltersData, "aud_lastPosted");
      allFiltersData[0]["aud_lastPosted"][0] = filterSet;
      newPayload["aud_lastPosted"] = lastPosted;
    } else {
      delete allFiltersData[0]["aud_lastPosted"];
      delete newPayload["aud_lastPosted"];
    }
    setPayload(newPayload);
    setAllFiltersData(allFiltersData);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.lastPost}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                fontWeight: '400'
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              size="large"
              onClick={handleClick}
              fullWidth
              endIcon={open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
            >
              Last Post
            </Button>
          </Tooltip>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} style={{ height: "auto" }}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>Last Post</FormControl>
                  </Grid>
                </Grid>
                <FormControl sx={{ m: 1 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="any"
                    name="radio-buttons-group"
                    onChange={(e) => handleChange(e, "aud")}
                    size="small"
                    value={
                      allFiltersData[0]["aud_lastPosted"]?.length
                        ? allFiltersData[0]["aud_lastPosted"][0]?.value
                        : "ANY"
                    }
                  >
                    {formItems?.map((item) => (
                      <CustomFormControlLabel
                        value={item.value}
                        control={<Radio size="small" />}
                        label={item.label}
                        key={`lastPost-${item.value}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

LastPost.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default LastPost;
