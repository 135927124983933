import {
  Autocomplete,
  Box,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { snapshotHeaderIcons, textColor } from "../../utility/plaformIcons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const MilestoneDropdown = ({
  milestoneList,
  sendSelectedMilestones,
  parentSelectedMilestone,
  disabled = false,
}) => {
  const [selectedMilestone, setSelectedMilestone] = React.useState(parentSelectedMilestone);

  const selectMilestone = (value, event) => {
    const jsonObject = event?.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet)?.map(JSON.parse);
    setSelectedMilestone(uniqueArray);
    sendSelectedMilestones(uniqueArray);
  };

  React.useEffect(() => {
    setSelectedMilestone(parentSelectedMilestone);
  }, [parentSelectedMilestone, selectedMilestone]);

  const checkIfExists = (option) => {
    const ids = selectedMilestone?.map((milestone) => milestone.id);
    if (ids?.indexOf(option.id) > -1) return true;
    return false;
  };

  return (
    <Stack spacing={2}>
      <Autocomplete
        id="multiple-limit-tags"
        disableCloseOnSelect
        multiple
        limitTags={0}
        disableClearable
        options={milestoneList || []}
        onChange={(event, newValue) => {
          selectMilestone(event, newValue);
        }}
        value={selectedMilestone}
        noOptionsText="No milestones available" // Custom message when milestoneList is empty
        isOptionEqualToValue={(option, value) => option?.id === value?.id} // Custom equality test
        getOptionLabel={(option) => option?.label}
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={checkIfExists(option) || selected}
            />
            <Typography sx={textColor[option.platform]}>
              {snapshotHeaderIcons[option.platform]}
              &nbsp;&nbsp;
            </Typography>
            {option.title}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Milestones"
            placeholder="Milestones"
            sx={{
              "& .MuiOutlinedInput-root": {
                minWidth: "7vw",
                marginRight: "5px",
                borderRadius: "10px",
              },
            }}
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Stack>
  );
};

MilestoneDropdown.propTypes = {
  milestoneList: PropTypes.array.isRequired,
  sendSelectedMilestones: PropTypes.func.isRequired,
  parentSelectedMilestone: PropTypes.array.isRequired,
};

export default MilestoneDropdown;
