import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import moment from "moment";

const DateRangeDropdown = ({ defaultDateRange = "Last 6 Months", onDateChange }) => {
    const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange);

    const dateRanges = {
        Today: [moment().startOf('day'), moment().endOf('day')],
        Yesterday: [moment().subtract(1, "days").startOf('day'), moment().subtract(1, "days").endOf('day')],
        "This Week": [moment().startOf("week"), moment()],
        "This Month": [moment().startOf("month"), moment()],
        "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
        "Last 6 Months": [moment().subtract(6, "months"), moment()],
    };
    const handleDateChange = (event) => {
        const newDateRange = event.target.value;
        setSelectedDateRange(newDateRange);
        const [startDate, endDate] = dateRanges[newDateRange];
    
        if (onDateChange) {
            onDateChange({
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
            });
        }
    };

    return (
        <Select
            value={selectedDateRange}
            onChange={handleDateChange}
            size="small"
            sx={{
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6C757D',
                borderRadius: '8px',
                backgroundColor: 'transparent',
                padding: 0,
                '& .MuiSelect-select': {
                    padding: '4px 8px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            }}
            displayEmpty
        >
            {Object.keys(dateRanges).map((range) => (
                <MenuItem key={range} value={range}>
                    {range}
                </MenuItem>
            ))}
        </Select>
    );
};

export default DateRangeDropdown;
