import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import { DoughnutChart } from "../../../common/chart";
import { Button, Fade, Grid, IconButton, Modal, Stack } from "@mui/material";
import { Close, OpenWith } from "@mui/icons-material";
import React from "react";
import styles from "../../../../styles/internal/Campaign/CampaignAnalytics.module.css";

const AudiencePieChart = ({ data, chartTitle, backgroundColor, expandButtonVisible }) => {
  const [expandTable, setExpandTable] = React.useState(false);

  const renderData = (expandFlag) => {
    const topValues = expandFlag ? data : data.slice(0, 6);
    const top = expandFlag ? 10 : 90;
    const left = expandFlag ? 0 : 100
    return (
      <ReactEcharts
        option={DoughnutChart(
          "Engagement Split",
          ["#4FD1C5", "#DDE3EE", "#B4BFCC", "#98A2B2", "#6E7B91", "#4B5468"],
          "vertical",
          top,
          left,
          topValues,
          ["60%", "80%"],
          true
        )}
        style={{ height: "400px", width: "100%" }}
      />
    );
  };

  const handleTableModalClose = () => {
    setExpandTable(false);
  };
  const expandButton = () => {
    return (
      <Stack direction="row" justifyContent="end">
        <Button style={{ backgroundColor: "#BDBDBD" }} variant="contained" onClick={expandChart} endIcon={<OpenWith />}>
          Expand
        </Button>
      </Stack>
    );
  };

  const expandChart = () => {
    setExpandTable(true);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, md: 12 }}
        style={{
          backgroundColor: backgroundColor,
          padding: 10,
        }}
      >
        <Grid item xs={4} md={12}>
          <h4>
            <b className={styles.title}>{chartTitle}</b>
          </h4>
        </Grid>
        <Grid item xs={4} md={12}>
          {renderData(false)}
          {expandButtonVisible && expandButton()}
        </Grid>
      </Grid>
      <Modal className={styles.stylesModal} open={expandTable} onClose={handleTableModalClose} closeAfterTransition>
        <Fade in={expandTable}>
          <div className={styles.sylesPaper} style={{ width: "50%", overflowY: "scroll" }}>
            <div id="modal-expanded_progress" className={styles.stylesModalHeading}>
              <h4>
                <b className={styles.title}>{chartTitle}</b>
              </h4>
              <IconButton onClick={handleTableModalClose}>
                <Close />
              </IconButton>
            </div>
            <hr />
            {renderData(true)}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AudiencePieChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartTitle: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  expandButtonVisible: PropTypes.bool.isRequired,
};

export default AudiencePieChart;
