import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { StyledStaticButton, StyledStaticButtonGroup } from "../components/common/StyledComponents";
import styles from "../styles/internal/Campaign/CampaignList.module.css";

const PrivatePublication = ({ openPrivatePublicationGuide, setOpenPrivatePublicationGuide }) => {
  const handleClose = () => {
    setOpenPrivatePublicationGuide(false);
  };

  return (
    <Dialog
      open={openPrivatePublicationGuide}
      onClose={handleClose}
      PaperProps={{
        style: {
          height: 700,
        },
      }}
    >
      <DialogTitle>
        <h4
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 800,
            fontSize: "20px",
            lineHeight: "30px",
            textAlign: "center",
            color: "#1e2022",
          }}
        >
          No Engagment on the post?
        </h4>
      </DialogTitle>
      <DialogContent
        style={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "30px",

          color: "#1e2022",
        }}
      >
        1.<b>Check the post:</b> First, make sure that the post is live and visible on the platform you posted it on. Check that
        it's not hidden or deleted, and that it has been published properly.
        <br />
        <br />
        2. <b>Wait for engagement:</b> Give the post some time to see if engagement starts to come in. Our platform uses a
        sophisticated pipeline to analyze the post and provide you with accurate engagement statistics. This process can take
        some time, so please be patient while we work to provide you with the most accurate data possible. <br />
        <br />
        3. <b>Check post privacy settings:</b> Make sure that the post is set to "public" rather than "friends only" or
        "connections only," depending on the platform you posted it on. Change the privacy settings of the post. <br />
        <br />
        4. <b>Check the account:</b> Ensure that the account is not restricted, suspended, or blocked in any way. Make sure that
        you are following the rules and guidelines of the platform you are posting on. <br />
        <br />
        5. <b>Contact support:</b> If you have tried all of the above steps and you are still not seeing engagement on the post,
        it may be time to contact support. Get in touch with the customer support team of the platform you are posting on and
        explain the issue. They may be able to provide you with additional assistance or insights into the issue.
        <br /> <br />
        Remember, engagement takes time and effort to build, but if you have tried all of the above steps and are still not seeing
        engagement, don't hesitate to reach out to customer support. They are there to help you and may be able to provide you
        with additional guidance on how to improve the engagement.
      </DialogContent>
      <DialogActions>
        <StyledStaticButtonGroup size="small" exclusive aria-label="Campaign">
          <StyledStaticButton value="active" className={styles.staticButton} onClick={handleClose}>
            <b>Ok, Understood!</b>
          </StyledStaticButton>
        </StyledStaticButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default PrivatePublication;
