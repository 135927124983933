import { Signer } from "@aws-amplify/core";
import * as urlLib from "url";
import store from "../redux/store";
import mainConfig from "../config/main.json";

const createSignedRequest = (request, user) => {
  const { search, ...parsedUrl } = urlLib.parse(request.url, true, true);

  let formattedUrl = urlLib.format({
    ...parsedUrl,
    query: { ...parsedUrl.query },
  });

  request.url = formattedUrl;

  const access_info = {
    access_key: user.accessKeyId,
    secret_key: user.secretKey,
    session_token: user.sessionToken,
  };
  const service_info = {
    region: "us-east-1",
    service: "execute-api",
  };
  //use amplify sign()function to create the signed headers;
  let signedRequest = Signer.sign(request, access_info, service_info);
  return signedRequest;
};

const requestConfig = (request, signedRequest) => {
  const headers = signedRequest.headers;
  if (
    store.getState().user.loggedIn &&
    (request.url.startsWith(mainConfig.API_HOST) ||
      request.url.startsWith(mainConfig.HEIMDALL_HOST))
  ) {
    headers["email"] = store.getState().user.email;
  }

  /* const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };  
 */
  return {
    method: request.method,
    mode: "cors",
    cache: "no-cache",
    headers: headers,
    referrer: "client",
    body: signedRequest.data,
  };
};

const getTimeZone = () =>{
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timeZone
}
export { createSignedRequest, requestConfig, getTimeZone };
