import * as React from "react";
import { Box, Button, Grid, Radio, Tooltip, Typography } from "@mui/material";
import CampaignTable from "./CampaignTable";
import {
  getBrandsForAccount,
  getCampaignList,
  refreshCampaignStatus,
} from "../../../services/campaigns";
import styles from "../../../styles/internal/Campaign/CampaignList.module.css";
import Loader from "../../common/loader";
import {
  ChevronRightOutlined,
  Circle,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { campaignStatusColors } from "../../../utility/status";
import CampaignActionButton from "./CampaignActionButton";
import { IconButton } from "@mui/material";
import CampaignFilters from "./CampaignFilters";
import { snapshotHeaderIcons, textColor } from "../../../utility/plaformIcons";
import {
  getExtrasContent,
  getTableContent,
  tableHeaders,
} from "./CommonAssets";
import {
  getFormattedDate,
  convertToUserTimezone,
} from "../../../utility/momentManipulations";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  onDownloadHandle,
  downloadNotification,
} from "../../../utility/exportData";
import { notify } from "../../../redux/features/system/systemAlert";
import GeneratePptReport from "./GeneratePptReport";
import { getTimeZone } from "../../../services/common";
import FreeTrialPopup from "../../common/freeTrialPopup";
import utils from "../../../utility/oryxUtils/utils";

const CampaignList = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const [selectedValue, setSelectedValue] = React.useState(null);

  const [campaigns, setCampaigns] = React.useState([]);
  const [alignment, setAlignment] = React.useState("1"); //1 for active and 0 for archieved campaigns
  const [activeCampaigns, setActiveCampaigns] = React.useState(0);
  const [archivedCampaigns, setArchivedCampaigns] = React.useState(0);
  const [account, setAccount] = React.useState(user.agencyId);
  const [page, setPage] = React.useState(0); //page is set to 0 because material starts counter from 0 , but in backend the counter starts from 1 so we increment page no by 1 in api call
  const [rows, setRows] = React.useState(10); // by default we show 10 rows
  const [sortCol, setSortCol] = React.useState("createdAt"); //by default campaigns are sorted by startDate in decending order
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [totalRows, setTotalRows] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [headers, setHeaders] = React.useState(tableHeaders);
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [search, setSearch] = React.useState("");
  const [dataFound, setDataFound] = React.useState(false);
  const [lastRefreshTime, setLastRefreshTime] = React.useState("");
  const [mimeType, setMimeType] = React.useState("");
  const [isExport, setIsExport] = React.useState(0);
  const [pptReportId, setPptReportId] = React.useState(0);
  const isFreeTrial = user.freeTrialPopupWarning;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationSearch = location.search;
  const params = new URLSearchParams(locationSearch);
  const [profileId, setProfileId] = React.useState(
    +params.get("profileId") || null
  );
  const timeZone = getTimeZone();
  const [status, setStatus] = React.useState("");
  const [brandList, setBrandList] = React.useState([]);
  const [selectedBrand, setSelectedBrand] = React.useState("");

  React.useEffect(() => {
    setAccount(user.agencyId);
    getBrandList();
    const array = Array.apply(null, Array(rows))?.map(function () {});
    getCampaignListData(
      alignment,
      isExport,
      page,
      rows,
      sortCol,
      sortOrder,
      search,
      mimeType,
      timeZone,
      status,
      selectedBrand
    );
  }, [alignment, isExport, page, rows, sortCol, sortOrder, search, mimeType]);

  React.useEffect(() => {
    createData(campaigns);
  }, [selectedValue]);

  const getBrandList = async () => {
    const response = await getBrandsForAccount(user, dispatch);
    if (response?.response !== null) {
      setBrandList(response?.response);
    }
  };
  //get campaign details from backend
  const getCampaignListData = async (
    alignment,
    exportValue,
    page,
    rows,
    sortCol,
    sortOrder,
    searchFilter,
    mimeType,
    timeZone,
    status,
    selectedBrand
  ) => {
    const data = {
      account,
      client: user.clientId,
      path: "Campaigns",
      type: parseInt(alignment, 10),
      isExport: exportValue,
      page: page + 1,
      rows,
      SortCol: sortCol,
      SortOrder: sortOrder,
      filter: searchFilter,
      mimeType,
      timeZone,
      status,
      brand: selectedBrand,
    };
    if (exportValue === 1) {
      downloadNotification(dispatch);
      const downloadData = await getCampaignList(data, user, dispatch);
      if (downloadData.response !== undefined) {
        onDownloadHandle(
          new Blob([downloadData.response]),
          `Campaigns.${mimeType}`
        );
      } else {
        dispatch(
          notify({
            message: "No campaign to download",
            type: "error",
          })
        );
      }
      return;
    }
    setLoading(true);
    const response = await getCampaignList(data, user, dispatch);
    if (
      response?.response === null ||
      response?.response.campaignList === null ||
      response?.response.campaignList?.length === 0
    ) {
      setLoading(false);
      setDataFound(false);
      return;
    }
    //create the campaign's table
    let { campaignList, total_records, active, inactive, lastRefreshed } =
      response?.response;
    createData(campaignList);
    setTotalRows(total_records);
    setCampaigns(campaignList);
    setActiveCampaigns(active);
    setArchivedCampaigns(inactive);
    setDataFound(true);
    setLastRefreshTime(lastRefreshed);
    setLoading(false);
  };

  // Function to handle archiving and unarchiving campaigns
  const handleArchiveUnarchive = async (id, status) => {
    dispatch(
      notify({
        message: `Please wait... we are ${
          status === "Archived" ? "archiving" : "unarchiving"
        } the campaign`,
        type: "info",
      })
    );
    const data = {
      id,
      current_status: status === "Archived" ? 6 : 1, // assuming 6 is for archived and 1 for active
    };
    const response = await refreshCampaignStatus(data, user, dispatch);
    if (response.response.status === "success") {
      dispatch(
        notify({
          message: `${status} successfully`,
          type: "success",
        })
      );
      getCampaignListData(
        alignment,
        isExport,
        page,
        rows,
        sortCol,
        sortOrder,
        search,
        mimeType,
        timeZone,
        "",
        selectedBrand
      );
    } else {
      dispatch(
        notify({
          message: response.status,
          type: "error",
        })
      );
    }
  };

  //get information on page, row, order or orderby change from campaign table
  const getInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);
    }
  };

  const handleChange = (index, id) => {
    setSelectedValue(index);
    navigate(
      `${location.pathname}/influencers/groups?id=${id}${
        profileId && `&profileId=${profileId}`
      }`,
      {
        replace: true,
      }
    );
  };

  //create data to be stored in campaign table
  const createData = (campaigns) => {
    const columns = [];
    campaigns.forEach((row, index) => {
      const {
        id,
        photoUrl,
        name,
        companyName,
        platform,
        postCount,
        weekEngg,
        influencerCount,
        daysDiff,
        endDate,
        current_status,
        listCount,
        startDate,
        brandName,
      } = row;
      const formattedEndDate =
        daysDiff > 0 ? (
          `${daysDiff} Days`
        ) : (
          <p style={{ color: "#f08995" }}>Finished</p>
        );
      const formattedStartDate = getFormattedDate(startDate, "MMMM Do YYYY");
      const endDateText =
        daysDiff > 0
          ? `Ends ${getFormattedDate(
              convertToUserTimezone(endDate),
              "MMMM Do YYYY"
            )}`
          : `Ended on ${getFormattedDate(endDate, "MMMM Do YYYY")}`;
      const circleColor = campaignStatusColors[current_status];
      const platformIcons = platform?.map((plat) => (
        <Grid item key={plat.name} className={styles.bodycell}>
          <Typography sx={textColor[plat.name.toLowerCase()]}>
            {snapshotHeaderIcons[plat.name.toLowerCase()]}
          </Typography>
        </Grid>
      ));
      columns.push({
        id,
        selectedId: index,
        name: (
          <Grid container spacing={1} alignItems="center">
            {profileId != null && (
              <Grid item sx={{ marginBlock: "auto", marginRight: "0.8vw" }}>
                <Radio
                  checked={selectedValue === index}
                  onClick={() => handleChange(index, id)}
                  value={index}
                  name="radio-buttons"
                />
              </Grid>
            )}
            <Grid item>
              <p
                className={styles.campaignName}
                onClick={() =>
                  row.current_status !== "Scheduled" && openAnalytics(row)
                }
              >
                {name}
              </p>
              <p className={styles.subbodycell}>by {companyName}</p>
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                {platformIcons}
              </Grid>
            </Grid>
          </Grid>
        ),
        postCount: getTableContent(postCount),
        week: getTableContent(
          utils.convertToInternationalNumberSystem(weekEngg)
        ),
        influencerCount: getTableContent(influencerCount),
        endDate: (
          <Grid container>
            <Grid item xs={12}>
              <p className={styles.bodycell}>{formattedEndDate}</p>
            </Grid>
            <Grid item xs={12} className={styles.subbodycell}>
              {endDateText}
            </Grid>
          </Grid>
        ),
        current_status: (
          <Grid container>
            <Grid item xs={4}>
              <Circle
                htmlColor={circleColor}
                sx={{ width: "25px", height: "25px" }}
              />
            </Grid>
            <Grid item xs={8} className={styles.bodycell} sx={{ marginTop: 1 }}>
              {current_status}
            </Grid>
          </Grid>
        ),
        listCount: getExtrasContent(listCount),
        startDate: (
          <Grid container spacing={1} className={styles.bodycell}>
            {formattedStartDate}
          </Grid>
        ),
        brand: getTableContent(brandName),
        action: (
          <Grid container spacing={1}>
            <Grid item xs={7}>
              {/* <Tooltip title={row.current_status === "Scheduled" && "You can view your campaign stats only after it begans"}> */}
              <Button
                variant="contained"
                type="submit"
                size="small"
                style={{
                  background: "#e3e0e0",
                  borderRadius: "22.5px",
                  color: "#464e5f",
                  width: "80.37px",
                  height: "37.1px",
                }}
                onClick={() => openAnalytics(row)}
              >
                View
              </Button>
              {/* </Tooltip> */}
            </Grid>
            <Grid item xs={4}>
              <CampaignActionButton
                selectedRow={row}
                setPptReportId={setPptReportId}
                handleArchiveUnarchive={handleArchiveUnarchive}
              />
            </Grid>
          </Grid>
        ),
      });
    });
    setBodyColumns(columns);
  };

  //open analytics page from view button
  const openAnalytics = React.useCallback(
    (row) =>
      navigate(
        `${location.pathname}/overview?id=${row.id}&universal=${row.universal}`,
        { replace: true }
      ),
    [navigate]
  );

  // get's changed filters from child component and creates campaign list
  const sendForCampaignDetails = (event) => {
    setPage(event.page);
    setRows(event.rows);
    setSortOrder(event.sortOrder);
    setSortCol(event.sortCol);
    setAlignment(event.alignment);
    setSearch(event?.filter);
    setIsExport(event.export);
    setMimeType(event.mimeType);
    if (event.refresh) {
      getCampaignListData(
        alignment,
        isExport,
        page,
        rows,
        sortCol,
        sortOrder,
        search,
        mimeType,
        timeZone,
        status,
        selectedBrand
      );
    }
  };

  const statusFilter = (event) => {
    setStatus(event);
    getCampaignListData(
      alignment,
      isExport,
      page,
      rows,
      sortCol,
      sortOrder,
      search,
      mimeType,
      timeZone,
      event,
      selectedBrand
    );
  };

  const brandFilter = (event) => {
    setSelectedBrand(event);
    getCampaignListData(
      alignment,
      isExport,
      page,
      rows,
      sortCol,
      sortOrder,
      search,
      mimeType,
      timeZone,
      status,
      event
    );
  };

  //change alignment (active to archieved and vice versa)
  const changeAlignment = (event) => {
    setAlignment(event);
  };

  //change headers
  const changeHeaders = (event) => {
    setHeaders(event);
  };

  return (
    <>
      <Box width={"100%"}>
        {/* Campaign filters - Search, active archieved toggle, new campaign button, refresh, select columns, export */}
        <CampaignFilters
          sendForCampaignDetails={sendForCampaignDetails}
          page={page}
          rows={rows}
          sortCol={sortCol}
          sortOrder={sortOrder}
          changeHeaders={changeHeaders}
          changeAlignment={changeAlignment}
          alignment={alignment}
          activeCampaigns={activeCampaigns}
          archivedCampaigns={archivedCampaigns}
          account={account}
          lastRefreshTime={lastRefreshTime}
          search={search}
          user={user}
          statusFilter={statusFilter}
          brandList={brandList}
          brandFilter={brandFilter}
        />
        {loading ? (
          <Loader />
        ) : dataFound ? (
          <div id="campaign-table">
            {/* Campaign table */}
            <CampaignTable
              campaigns={campaigns}
              tableHeaders={headers}
              getInformation={getInformation}
              totalRows={totalRows}
              bodyColumns={bodyColumns}
              selectedRow={selectedRow}
              page={page}
              rowsPerPage={rows}
              sortOrderValue={sortOrder}
              sortOrderBy={sortCol}
            />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              {/* No campaigns found for this account */}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60vh",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                You don't have any campaigns at the moment.
              </Grid>
            </Grid>
          </>
        )}
        <br />
      </Box>
      <GeneratePptReport
        pptReportId={pptReportId}
        setPptReportId={setPptReportId}
      />
    </>
  );
};

export default CampaignList;
