import { createTheme } from "@mui/material";
import typography from "./typography";
import palette from "./palette";

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #B5B5C3",
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
